import React from "react";
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { API, Storage, Auth } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';

class PHARegistrationStep1 extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            PropertyID: -1,

            uploadW9DocumentYes: false,
            uploadW9DocumentNo: false,
            w9upload_Error: "",

            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',
        }

        this.propertyInfo = [];
        this.w9upload = null;
    }

    async componentDidMount() {
        // console.log(+this.props.match.params.id);
        // console.log(+this.props.location.state?.ID);
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();
        this.setState({ PropertyID: +formattedId });

        this.setState({ isLoading: true });

        //console.log('property details');
        this.propertyInfo = this.props.propertyInfo;
        //console.log(this.propertyInfo);

        this.setState({ isLoading: false });
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    saveForm = async () => {
        this.setState({ isLoading: true });

        document.getElementById("uploadW9MessageId").style.display = "none";
        var res = await this.UploadW9Document();
        //console.log(res);

        this.setState({ isLoading: false });
        this.setState({ openSnackbar: true, snackbarMessage: "The updates are been saved",snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000 });
    }

    validateForm = async () => {
        if (this.state.uploadW9DocumentYes === true &&
            (this.w9upload === null || this.w9upload === undefined || this.w9upload.files === null || this.w9upload.files.length === 0)) {
            this.setState({ w9upload_Error: "Please select the document to be uploaded" });
            return false;
        }
        else {
            this.setState({ w9upload_Error: "" });
        }

        document.getElementById("uploadW9MessageId").style.display = "none";

        return true;
    }

    updatePropertyRegistrationDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updatepropertyregistrationdetails';
        let myInit = {
            body: this.propertyInfo[0],
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    UploadW9Document = async () => {
        if (this.w9upload === null || this.w9upload.files === null || this.w9upload.files.length === 0) {
            // early return
            return;
        }

        this.setState({ isLoading: true });

        try {
            var key = this.propertyInfo[0]["W9DocumentPath"];
            var files = await Storage.list(key);

            // Remove the earlier files for the section for which the new files to be uploaded
            if (this.w9upload !== null && this.w9upload.files !== null && this.w9upload.files.length > 0) {
                for (var i = 0; i < files.length; i++) {
                    var key1 = files[i].key;
                    var delres = await Storage.remove(key1);
                    //console.log(delres);
                }
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
            return;
        }

        // Upload the w9 document
        if (this.state.PropertyID !== -1 &&
            this.w9upload !== null && this.w9upload.files !== null && this.w9upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/${this.w9upload.files[0].name}`,
                    this.w9upload.files[0],
                    { contentType: this.w9upload.files[0].type });
                //console.log(result);
                this.w9upload = null;

                document.getElementById("uploadW9MessageId").innerHTML = "<label>Document uploaded successfully.</label>";
                document.getElementById("uploadW9MessageId").style.display = "block";

                // Update Property details with w9 document and path
                this.propertyInfo[0]["WhatToUpdate"] = "Step1";
                this.propertyInfo[0]["W9DocumentName"] = this.w9upload.files[0].name;

                // Relative S3 path
                this.propertyInfo[0]["W9DocumentPath"] = `Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/${this.w9upload.files[0].name}`;

                var res = await this.updatePropertyRegistrationDetails();
                //console.log(res);
            }
            catch (err) {
                console.log(err);
                this.setState({ isLoading: false });
                return;
            }
        }
        this.setState({ isLoading: false });
    }

    updateW9DocumentYes = (evt) => {
        if (evt.target.checked === true) {
            document.getElementById("uploadW9Id").style.display = "block";
            document.getElementById("uploadW9NoLabelId").style.display = "none";
            this.setState({ uploadW9DocumentYes: true, uploadW9DocumentNo: false, w9upload_Error: "" });
        }
        document.getElementById("uploadW9MessageId").style.display = "none";
    }

    updateW9DocumentNo = (evt) => {
        if (evt.target.checked === true) {
            document.getElementById("uploadW9Id").style.display = "none";
            document.getElementById("uploadW9NoLabelId").style.display = "block";
            this.setState({ uploadW9DocumentYes: false, uploadW9DocumentNo: true, w9upload_Error: "" });
        }
        document.getElementById("uploadW9MessageId").style.display = "none";
    }

    render() {
        return (
            <div className="border p-3">
                <h2 className="profile-title"><i class="fas fa-info-circle mr-2"></i>Instructions for Owner</h2>
                <hr />
                <p>WelcomeHomeOC is here to help streamline the process of working with Public Housing Authorities.</p>
                <p>We are providing following documents for your review and reference only at this time.  These forms will be provided to you later by the Public Housing Authority.</p>
                <ul className="list-group list-group-flush">
                    <li className="list-group-item border-0 py-0">
                        <a href="#!" className="woc-portal-link" onClick={async () => {
                            this.setState({ isLoading: true });

                            Storage.configure({
                                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                                region: process.env.REACT_APP_Region,
                                level: 'public',
                            });

                            try {
                                var template = await Storage.get(`StaticDocs/PHA/OCHA_For_Review.pdf`);
                                //console.log(template);

                                let link = document.createElement('a');
                                link.href = template;
                                link.target = '_blank';
                                link.download = template;
                                link.click();
                            }
                            catch (e) {
                                console.log(e);
                            }

                            this.setState({ isLoading: false });
                        }}>
                            <i class="fas fa-file-alt mr-2"></i>OCHA for Review
                        </a>
                    </li>
                    <li className="list-group-item border-0 py-0">
                        <a href="#!" className="woc-portal-link" onClick={async () => {
                            this.setState({ isLoading: true });

                            Storage.configure({
                                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                                region: process.env.REACT_APP_Region,
                                level: 'public',
                            });

                            try {
                                var template = await Storage.get(`StaticDocs/PHA/SAHA_For_Review.pdf`);
                                //console.log(template);

                                let link = document.createElement('a');
                                link.href = template;
                                link.target = '_blank';
                                link.download = template;
                                link.click();
                            }
                            catch (e) {
                                console.log(e);
                            }

                            this.setState({ isLoading: false });
                        }}>
                            <i class="fas fa-file-alt mr-2"></i>SAHA for Review
                        </a>
                    </li>
                </ul>
                <p>Please complete this form to start receiving payments from the U.S. Department of Housing and Urban Development (HUD).</p>
                <p>Please feel free to reach out to Kristine Peters at (949) 263-6139 or KristineP@unitedwayoc.org with any questions.</p>

                <div style={{ display: (this.props.propertyInfo[0]["W9DocumentName"] !== "" && this.props.propertyInfo[0]["W9DocumentPath"] !== "") ? 'none' : 'block' }}>
                    <div className="row">
                        <div className="col-auto">
                            <div><label><b>Do you have a W9 document for a property?</b></label></div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="w9DocumentYes" name="w9" class="custom-control-input" onChange={this.updateW9DocumentYes} />
                                <label class="custom-control-label" for="w9DocumentYes">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" id="w9DocumentNo" name="w9" class="custom-control-input" onChange={this.updateW9DocumentNo} />
                                <label class="custom-control-label" for="w9DocumentNo">No</label>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div id="uploadW9Id" style={{ display: 'none' }}>
                                <label className="mb-0"><b>Please upload W9 document here:</b></label>
                                <input className="file-input form-control-sm form-control-file pl-0" type="file" ref={ref => (this.w9upload = ref)}
                                    onChange={e => {
                                        if (e.target.files.length > 0 && !e.target.files[0].type.match('image/jp.*') && !e.target.files[0].type.match('.pdf')) {
                                            e.target.value = '';
                                            this.setState({ snackbarMessage: "Please upload only Jpeg or PDF file.", openSnackbar: true });
                                            return;
                                        }
                                    }
                                    }
                                />
                                {/* <button className="btn btn-primary btn-black" onClick={this.UploadW9Document}>
                                        Upload
                                    </button> */}
                                <div class="alert-small-text">{this.state.w9upload_Error}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div id="uploadW9NoLabelId" style={{ display: 'none' }}>
                                <label>You can move to next step to fill registration details for Public Housing Authority.</label>
                            </div>
                            <div id="uploadW9MessageId" style={{ display: 'none' }}>
                            </div>
                        </div>
                    </div>
                </div>

                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>

                    <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                        open={this.state.openSnackbar}
                        autoHideDuration={3000}
                        severity={this.state.snackbarSeverity}
                        onClose={() => this.setState({ openSnackbar: false })}
                        message={this.state.snackbarMessage}
                        className={this.state.snackbarMessageClass}
                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                    />
                </React.Fragment>

            </div>
        );
    }
}

export default PHARegistrationStep1;
