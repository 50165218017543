import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import MUIDataTable from "mui-datatables";
import { API, Storage, Auth } from 'aws-amplify';
import PropertyOwnerCustomToolbar from "./propertyOwnerCustomToolbar";
import TableSearch from "../TableSearch";
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as cloneDeep from 'lodash/cloneDeep';
import * as find from 'lodash/find';
import PropertyOwnerProfile from "./propertyOwnerProfile";
import ReactDOM from 'react-dom';
import { NavLink } from "react-router-dom";
import {
    FormControl,
    ListItemText,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import TableViewCol from "../TableViewCol";
import Snackbar from '@material-ui/core/Snackbar';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import NotificationsIcon from "@material-ui/icons/Notifications";
import { withStyles } from '@material-ui/core/styles';
let showBkBtn = 'block';
const filter = createFilterOptions();
const headers = [
    "",    // 1st column header
    "firstName",
    "lastName",
    "company",
    "emailID",
    "phone",
    "status",
    "createdDate",
    "createdTime"
];

const useStyles = theme => ({
    myAlert: {
      backgroundColor: "#5A9BD3"
    }
});

class PropertyOwnerListing extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            // openBar: false,
            unitCount: "",
            openBar: false,
            ID: '',
            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',
            columns: [
                {
                    name: " ",  // 1st column
                    label: " ",  // 1st column
                    options: {
                        download: false,
                        display: true,
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <NavLink
                                    to={{
                                        pathname: '/welcomehomeoc/propertyownerprofile',
                                        state: { ID: value }
                                    }} onClick={(event) => this.setSessionStorageData(value)}>
                                    <div style={{
                                        width: "15px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        //textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {/* <a href="#" onClick={this.testLoad}><i class="fas fa-edit"></i></a> */}
                                        <i class="fas fa-edit"></i>
                                    </div>
                                </NavLink>
                            );
                        },
                    },
                },
                {
                    name: "FirstName",
                    label: "First Name",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "LastName",
                    label: "Last Name",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Company",
                    label: "Company",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "EmailID",
                    label: "Email",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "250px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "250px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Phone",
                    label: "Phone",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value) {
                                if (value.length > 15) {
                                    return (
                                        <Tooltip title={value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')} arrow placement="left">
                                            <div style={{
                                                width: "120px",
                                                overflow: "hidden",
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                position: 'relative',
                                            }}>
                                                <span>{value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')}</span>
                                            </div>
                                        </Tooltip>
                                    );
                                }
                                else {
                                    return (
                                        <div style={{
                                            width: "120px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            <span>{value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')}</span>
                                        </div>
                                    );
                                }
                            }
                            else
                                return (
                                    <div style={{
                                        width: "120px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Status",
                    label: "Status",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Invited'], ['Joined'], ['Active'], ['Inactive']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "120px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "120px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "CreatedDate",
                    label: "Created Date",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "CreatedTime",
                    label: "Created Time",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
            ],
            firstName: null,
            company: null,
            lastName: null,
            emailID: null,
            phoneExtension: null,
            IsParticipationAgreementSigned: false,
            hideCompleted: 1,
            msg: "",
            showDetails: "none",
            showAlert: "none",
            dataheight: null,
            notes: "",
            cardValue: "500.00",
            rowSelected: [],
            data: [["Loading Data..."]],
            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'CreatedDate',                  // default sort by column dateUserCreated in desc order
                direction: 'desc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            selectedRowFirstName: null,
            selectedRowMiddleName: null,
            selectedRowLastName: null,
            selectedRowAddress: null,
            selectedRowState: null,
            selectedRowCity: null,
            selectedRowZip: null,
            selectedRowEmail: null,
            selectedRowPhone: null,
            selectedRowWantToBeRecognized: "No",
            checkOnRowSelectPhone: false,
            errormsgFirstName: "",
            errormsgMiddleName: "",
            errormsgLastName: "",
            errormsgAddress: "",
            errormsgState: "",
            errormsgCity: "",
            errormsgPhone: "",
            //  errormsgReco: "",
            errormsgZip: "",
            errormsgWantToBeRecognized: "",
            selectedRowErrorMsgClass: "",
            errormsgAddFirstName: "",
            errormsgAddMiddleName: "",
            errormsgAddLastName: "",
            errormsgAddPhone: "",
            errormsgAddPhoneExtension: "",
            errormsgAddEmail: "",
            saveselectedFirstName: "",
            saveselectedMiddleName: "",
            saveselectedLastName: "",
            saveselectedAddress: "",
            saveselectedState: "",
            saveselectedStatus: "",
            saveselectedCity: "",
            saveselectedZip: "",
            saveselectedPhone: "",
            saveselectedWantToBeRecognized: "",
            options: {
                download: false,
                selectableRows: false,
                selectableRowsOnClick: false,
                filter: true,
                sort: true,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,

                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,
                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },
                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                },
                onFilterDialogClose: () => {
                },
                onFilterChange: (column, filterList, type) => {
                    this.setState({ filters: filterList });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        this.handleFilterSubmit(newFilters);
                    }
                },
                isDisabled: false,
                isUpdateDisabled: false,
                viewColumns: false,
                downloadCSV: false,
                pagination: true,
                count: 1,
                rowsPerPage: 50,
                rowsPerPageOptions: [10, 20, 50, 100, 200],
                sortOrder: {
                    name: 'CreatedDate',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },
                onTableChange: (action, tableState) => {
                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want
                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                    }
                },
                onViewColumnsChange: (column, action) => {
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                searchText: '',
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (
                        <TableSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            onSearchClick={this.OnSearchClick}
                        />
                    );
                },
                customToolbar: () => {
                    return (
                        <PropertyOwnerCustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} />
                    );
                },
            },
            selectedManagementCompany: null,
            managementCompanies: [],
        }

        this.showHTML = false;
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

    getData = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertyownerinfo';

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                if (`${headers[ii]}` === 'wantToBeRecognized') {
                    if (`${this.state.filters[ii]}` === 'Yes') {
                        filterdict[`${headers[ii]}`] = 1;
                    } else {

                        filterdict[`${headers[ii]}`] = 0;
                    }
                }
                else if (`${headers[ii]}` === 'phone') {
                    if (`${this.state.filters[ii]}`.startsWith('+1')) {
                    } else {

                        filterdict[`${headers[ii]}`] = '+1' + `${this.state.filters[ii]}`;
                    }
                } else {
                    filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
                }
            }
        }
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                    "hideCompleted": this.state.hideCompleted
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    postData = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    setSessionStorageData = (value) => {
        localStorage.setItem('ownerId', value)
    }

    componentDidMount = async () => {
        this.props.parentCallback("Portal User", "fas fa-user mr-2", "");
        this.setState({ isLoading: true });

        try {
            await Auth.currentAuthenticatedUser();
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
            await this.signOut();
            return;
        }
        this.showHTML = true;

        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups.length === 1) {
            showBkBtn = "none";
        }
        var height;
        if (isMobile) {
            height = "60vh";
        }
        else {
            height = "70vh"
        }
        var unitCount = await this.getViewUnitCount();
        if(unitCount && unitCount[0] && unitCount[0] > 0) {
            var count = unitCount[0];
            this.setState({ openBar: true, unitCount: count });
        }
        var res = await this.getData();
        var manaCompResponse = await this.getManagementCompanies();
        if (manaCompResponse.total > 0) {
            manaCompResponse.data.forEach(e => {
                this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] + "-" + e[3] });
            });
        }
        this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
    }

    getManagementCompanies = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getmanagementcompanylist';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getData().then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
                isLoading: false,
            });
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
            });
        });
    }

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                sortOrder: sortOrder,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    }

    testLoad = async () => {
        ReactDOM.render(<PropertyOwnerProfile />, document.getElementById("root"));
    }

    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name });
    }

    updateCardValue = (evt) => {
        var name = evt.target.value;
        this.setState({ cardValue: name });
    }

    close = () => {
        // this.props.updateFunction(2);
        this.setState({ email: "" });
        this.closeDetails();
        window.location.assign("/");
    }

    closeDetails = () => {
        document.body.classList.remove('modal-open');
        if (isMobile) {
            this.setState({ showDetails: "none", dataheight: "60vh" });
        }
        else {
            this.setState({ showDetails: "none" }); //,dataheight:"70vh"
        }
    }

    clearOnRowClick = () => {
        this.stateSelector.value = "selectstate";
        this.statusSelector.value = "selectstatus";
        this.setState({
            saveselectedFirstName: "",
            saveselectedMiddleName: "",
            saveselectedLastName: "",
            saveselectedPhone: "",
            saveselectedAddress: "",
            saveselectedCity: "",
            saveselectedState: "",
            saveselectedStatus: "",
            saveselectedZip: "",
            saveselectedWantToBeRecognized: "",
            selectedRowFirstName: "",
            selectedRowMiddleName: "",
            selectedRowLastName: "",
            selectedRowPhone: "",
            selectedRowAddress: "",
            selectedRowCity: "",
            selectedRowState: "",
            selectedRowZip: "",
            selectedRowWantToBeRecognized: "",
            errormsgFirstName: "",
            errormsgMiddleName: "",
            errormsgLastName: "",
            errormsgPhone: "",
            //  errormsgReco: "",
            errormsgAddress: "",
            errormsgCity: "",
            errormsgState: "",
            errormsgZip: "",
            selectedRowErrorMsgClass: "",
        });
        this.stateSelector.value = "";
        this.statusSelector.value = "";
        document.getElementById("divFirstName").style.display = "none";
        document.getElementById("divMiddleName").style.display = "none";
        document.getElementById("divLastName").style.display = "none";
        document.getElementById("divPhone").style.display = "none";
        //  document.getElementById("divReco").style.display = "none";
        document.getElementById("divAddress").style.display = "none";
        document.getElementById("divCity").style.display = "none";
        document.getElementById("divState").style.display = "none";
        document.getElementById("divZip").style.display = "none";
    }

    clearAndClosePopup = () => {
        document.body.classList.remove('modal-open');
        if (isMobile) {
            this.setState({ showDetails: "none", dataheight: "60vh" });
        }
        else {
            this.setState({ showDetails: "none" }); //,dataheight:"70vh"
        }
        this.stateSelector.value = "selectstate";
        this.statusSelector.value = "selectstatus";
        this.setState({
            saveselectedFirstName: "",
            saveselectedMiddleName: "",
            saveselectedLastName: "",
            saveselectedPhone: "",
            saveselectedAddress: "",
            saveselectedCity: "",
            saveselectedState: "",
            saveselectedStatus: "",
            saveselectedZip: "",
            saveselectedWantToBeRecognized: "",
            selectedRowFirstName: "",
            selectedRowMiddleName: "",
            selectedRowLastName: "",
            selectedRowPhone: "",
            selectedRowAddress: "",
            selectedRowCity: "",
            selectedRowState: "",
            selectedRowZip: "",
            selectedRowWantToBeRecognized: "",
            errormsgFirstName: "",
            errormsgMiddleName: "",
            errormsgLastName: "",
            errormsgPhone: "",
            // errormsgReco: "",
            errormsgAddress: "",
            errormsgCity: "",
            errormsgState: "",
            errormsgZip: "",
            selectedRowErrorMsgClass: "",
        });

        this.stateSelector.value = "";
        this.statusSelector.value = "";

        document.getElementById("divFirstName").style.display = "none";
        document.getElementById("divMiddleName").style.display = "none";
        document.getElementById("divLastName").style.display = "none";
        document.getElementById("divPhone").style.display = "none";
        // document.getElementById("divReco").style.display = "none";
        document.getElementById("divAddress").style.display = "none";
        document.getElementById("divCity").style.display = "none";
        document.getElementById("divState").style.display = "none";
        document.getElementById("divZip").style.display = "none";
    }

    closeMsg = () => {
        this.clearOnRowClick();
        this.setState({ showAlert: "none" });
    }

    OnSearchClick = async () => {
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        var res = await this.getData();
        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        })
    }

    OnRefreshClick = async () => {
        await this.refresh();
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        this.setState({ isLoading: true, filters: filterList });

        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    }

    OnSearchTextChange(searchQuery) {
        this.setState({ searchText: searchQuery });
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery,
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getData();
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);
        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);
        const csv = `${CSVHead}${CSVBody}`.trim();
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnDownloadClick = async () => {
        const { data, columns, options } = this.state;
        let displayData = this.state.data;
        this.setState({
            isLoading: true,
            downloadFile: 1,
        });
        var res = await this.getData();
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });
        let dataDictionary = [];
        res.data.forEach((el, index) => dataDictionary.push({ index: index, data: el }));
        let dataToDownload = cloneDeep(dataDictionary);
        let columnsToDownload = columns;
        if (options.downloadOptions && options.downloadOptions.filterOptions) {
            // check rows first:
            if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
                dataToDownload = displayData.map((row, index) => {
                    let i = -1;
                    // Help to preserve sort order in custom render columns
                    row.index = index;
                    return {
                        data: row.data.map(column => {
                            i += 1;
                            // if we have a custom render, which will appear as a react element, we must grab the actual value from data
                            // that matches the dataIndex and column
                            // TODO: Create a utility function for checking whether or not something is a react object
                            return typeof column === 'object' && column !== null && !Array.isArray(column)
                                ? find(data, d => d.index === row.dataIndex).data[i]
                                : column;
                        }),
                    };
                });
            }

            // now, check columns:
            if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
                columnsToDownload = columns.filter((_, index) => _.options.display === true);

                dataToDownload = dataToDownload.map(row => {
                    row.data = row.data.filter((_, index) => columns[index].options.display === true);
                    return row;
                });
            }
        }
        this.createCSVDownload(columnsToDownload, dataToDownload, options);
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
        }
    }

    add = async () => {
        var isAddValid = await this.validateaddpropertyowner();
        if (isAddValid) {
            this.setState({ isDisabled: true, isLoading: true });
            var response = await this.postDataRDS();

            if (response.status) {

                this.setState({ isLoading: false, isDisabled: false, snackbarMessageClass: 'Mui-filledSuccess', openSnackbar: true, snackbarSeverity: "success", snackbarMessage: "Portal user has been invited" });
                var res = await this.getData();
                this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
                if (this.state.IsParticipationAgreementSigned === false) {
                    var sendmailreponse = await this.sendEmailToPropertyOwner(response.Guid);
                }
                var issueDateTime = new Date(
                    new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
                );
                var todaysDate = [
                    issueDateTime.getFullYear(),
                    ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                    ("0" + issueDateTime.getDate()).slice(-2),
                ].join("-");

                var todaysTime = [
                    ("0" + issueDateTime.getHours()).slice(-2),
                    ("0" + issueDateTime.getMinutes()).slice(-2),
                    ("0" + issueDateTime.getSeconds()).slice(-2),
                ].join(":");

                //Insert owner as 'Title: PortalUser' in salesforce
                await this.loadFileToS3(
                    [
                        {
                            "First name": this.state.firstName, "Last name": this.state.lastName,
                            "Contact Record Type": "PeopleProp", "Email": this.state.emailID, "Phone": this.state.phone,
                            "Network Status": "Member", "Role": "Portal Account", "Title": "Portal user",
                            "SalesforceCompanyId": response.salesforceManagementCompanyId,
                            "AWS_OwnerID": response.Id, "AWS_UpdatedBy": "AWS",
                            "RecordTypeId":  process.env.REACT_APP_PeoplePropRecordTypeId,//"0124x000000yltLAAQ",
                            "PhoneExtension": this.state.phoneExtension,
                        },
                    ], todaysDate, todaysTime, 'PortalUser'
                );

                this.setState({
                    firstName: null,
                    company: null,
                    lastName: null,
                    emailID: null,
                    phone: null,
                    IsParticipationAgreementSigned: false
                });
            } else if (response.message === 'EmailId Already Exists') {
                var duplicateEmailid = this.state.emailID;
                this.setState({
                    isLoading: false,
                    isDisabled: false,
                    errormsgAddEmail: "Email already exists",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divAddEmail").style.display = "block";
                return;
            } else {
                this.setState({ isLoading: false, isDisabled: false, snackbarMessageClass: 'Mui-filledError', openSnackbar: true, snackbarSeverity: "error", snackbarMessage: "Portal user is not added due to some technical issue" });
                return;
            }
            this.clearValues();
        }
    }

    loadFileToS3 = async (items, todaysDate, todaysTime, entityName) => {
        // Convert JSON to CSV & Display CSV
        var s = this.convertToCSV(items);
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });

            var fileName = entityName === "PortalUser" ? "Contact_" + items[0].AWS_OwnerID + "_" + todaysDate + "_" + todaysTime + ".csv"
                : "ManagmentCompany_" + items[0].AWS_ManagemtnCompanyId + "_" + todaysDate + "_" + todaysTime + ".csv";
            var fullFileName = entityName === "PortalUser" ? `Salesforce/Create-Contact/${fileName}` : `Salesforce/Create-ManagmentCompany/${fileName}`;
            var result1 = await Storage.put(fullFileName, s, { contentType: "csv" });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }

    convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)

        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    postDataRDS = async () => {
        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/whocaddpropertydetails';
        let myInit = { // OPTIONAL
            body: {
                "FirstName": this.state.firstName,
                "Company": this.state.selectedManagementCompany,
                "LastName": this.state.lastName,
                "EmailID": this.state.emailID,
                "Phone": this.state.phone,
                "PhoneExtension": this.state.phoneExtension,
                "CreatedBy": adminUser,
                "IsParticipationAgreementSigned": this.state.IsParticipationAgreementSigned
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    sendEmailToPropertyOwner = async (guid) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/whocsendinvitationemail';
        let myInit = { // OPTIONAL
            body: {
                "FirstName": this.state.firstName,
                "EmailID": this.state.emailID,
                "Guid": guid,
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    validateaddpropertyowner = async () => {
        let isAddValid = true;
        var setaddnonevalue = await this.setDivMsgForAdd();
        if (this.state.firstName === null || this.state.firstName === "") {
            this.setState({
                errormsgAddFirstName: "First name is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddFirstName").style.display = "block";
            isAddValid = false;
        }

        if (this.state.lastName === null || this.state.lastName === "") {
            this.setState({
                errormsgAddLastName: "Last name is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddLastName").style.display = "block";
            isAddValid = false;
        }
        
        if (this.state.emailID === null || this.state.emailID === "") {
            this.setState({
                errormsgAddEmail: "Email is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddEmail").style.display = "block";
            isAddValid = false;
        }
        else if (this.state.emailID !== undefined) {
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern4.test(this.state.emailID)) {
                this.setState({
                    errormsgAddEmail: "Invalid email",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divAddEmail").style.display = "block";
                isAddValid = false;
            }
        }
        if (this.state.phone === null || this.state.phone === "" || this.state.phone === undefined) {
            this.setState({
                errormsgAddPhone: "Phone is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddPhone").style.display = "block";
            isAddValid = false;
        }
        else if (this.state.phone !== undefined && this.state.phone !== null && this.state.phone !== "") {
            if (this.state.phone.length < 10) {
                this.setState({
                    errormsgAddPhone: "Invalid phone number",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divAddPhone").style.display = "block";
                isAddValid = false;
            }
        }

        if (this.state.phoneExtension !== "" && this.state.phoneExtension !== null && this.state.phoneExtension !== undefined) {
            if (/^[\d\(\)\+]+$/.test(this.state.phoneExtension) !== true) {
                this.setState({
                    errormsgAddPhoneExtension: "Invalid Extension",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divAddPhoneExtension").style.display = "block";
                isAddValid = false;
                //errors["PhoneExtension"] = "Invalid Extension";
                //formIsValid = false;
            }
        }

        if (this.state.selectedManagementCompany == "" || this.state.selectedManagementCompany == null) {
            this.setState({
                errormsgAddMiddleName: "Company is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddCompany").style.display = "block";
            isAddValid = false;
        }

        // if (this.state.company !== "" && this.state.company !== null && this.state.company !== undefined) {
        //     var pattern1 = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!pattern1.test(this.state.company)) {
        //         this.setState({
        //             errormsgAddMiddleName: "Invalid Company",
        //             selectedRowErrorMsgClassalert: "alert-small-text"
        //         });
        //         document.getElementById("divAddCompany").style.display = "block";
        //         isAddValid = false;
        //     }
        // }
        return isAddValid;
    }

    setDivMsgForAdd = async () => {
        let checkdiv = ""
        this.setState({
            errormsgAddFirstName: "",
            errormsgAddMiddleName: "",
            errormsgAddLastName: "",
            errormsgAddPhone: "",
            errormsgAddPhoneExtension: "",
            errormsgAddEmail: "",
        });
        document.getElementById("divAddFirstName").style.display = "none";
        document.getElementById("divAddCompany").style.display = "none";
        document.getElementById("divAddLastName").style.display = "none";
        document.getElementById("divAddPhone").style.display = "none";
        document.getElementById("divAddPhoneExtension").style.display = "none";
        document.getElementById("divAddEmail").style.display = "none";
        return checkdiv;
    }

    update = async () => {
        /*do the validation here */
        var IsValid = await this.validatepropertyowner();
        if (IsValid) {
            this.closeDetails();
            this.setState({ isUpdateDisabled: true, isLoading: true });
            var updatedmsg = await this.updatePropertyOwner();
            if (updatedmsg === "Record updated successfully") {
                this.clearOnRowClick();
                this.setState({
                    isLoading: false,
                    isUpdateDisabled: false,
                    msg: "Property user has been updated",
                    showAlert: "block"
                });
                this.getData().then(res => {
                    this.setState({
                        isLoading: false,
                        data: res.data,
                        page: res.page,
                        count: res.total,
                    });
                });
            }
            else {
                this.setState({
                    isLoading: false,
                    isUpdateDisabled: false,
                    msg: updatedmsg,
                    showAlert: "block"
                });
                this.clearOnRowClick();
            }
        }
    }

    validatepropertyowner = async () => {
        let IsValid = true;
        var setnonevalue = await this.setdivmsg();
        if (this.state.selectedRowFirstName === null || this.state.selectedRowFirstName === "") {
            this.setState({
                errormsgFirstName: "First name is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divFirstName").style.display = "block";
            IsValid = false;
        }
        // else if (this.state.selectedRowFirstName !== undefined) {
        //     var pattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!pattern.test(this.state.selectedRowFirstName)) {
        //         this.setState({
        //             errormsgFirstName: "Please do not use special characters or digits",
        //             selectedRowErrorMsgClassalert: "alert-small-text"
        //         });
        //         document.getElementById("divFirstName").style.display = "block";
        //         IsValid = false;
        //     }
        // }

        if (this.state.selectedRowMiddleName === null || this.state.selectedRowMiddleName === "") {
            this.setState({
                errormsgMiddleName: "Middle name is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divMiddleName").style.display = "block";
            IsValid = false;
        }
        else if (this.state.selectedRowMiddleName !== undefined) {
            var pattern1 = new RegExp(/^[a-zA-Z- ]*$/);
            if (!pattern1.test(this.state.selectedRowMiddleName)) {
                this.setState({
                    errormsgMiddleName: "Please do not use special characters or digits",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divMiddleName").style.display = "block";
                IsValid = false;
            }
        }
        if (this.state.selectedRowLastName === null || this.state.selectedRowLastName === "") {
            this.setState({
                errormsgLastName: "Last name is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divLastName").style.display = "block";
            IsValid = false;
        }
        // else if (this.state.selectedRowLastName !== undefined) {
        //     var pattern2 = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!pattern2.test(this.state.selectedRowLastName)) {
        //         this.setState({
        //             errormsgLastName: "Please do not use special characters or digits",
        //             selectedRowErrorMsgClassalert: "alert-small-text"
        //         });
        //         document.getElementById("divLastName").style.display = "block";
        //         IsValid = false;
        //     }
        // }

        // if(this.state.selectedRowWantToBeRecognized === null || this.state.selectedRowWantToBeRecognized === ""){
        //     this.setState({
        //         errormsgReco: "Want to be recognized  is required",
        //         selectedRowErrorMsgClassalert: "alert-small-text"
        //     });
        //     document.getElementById("divReco").style.display = "block";
        //     IsValid = false;
        // }
        if (this.state.selectedRowPhone === null || this.state.selectedRowPhone === "") {
            this.setState({
                errormsgPhone: "Phone is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divPhone").style.display = "block";
            IsValid = false;
        }
        else if (this.state.selectedRowPhone.length < 10) {
            this.setState({
                errormsgPhone: "Invalid phone number",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divPhone").style.display = "block";
            IsValid = false;
        }
        if (this.state.selectedRowAddress === null || this.state.selectedRowAddress === "") {
            this.setState({
                errormsgAddress: "Address is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divAddress").style.display = "block";
            IsValid = false;
        }
        if (this.state.selectedRowCity === null || this.state.selectedRowCity === "") {
            this.setState({
                errormsgCity: "City is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divCity").style.display = "block";
            IsValid = false;
        }
        else if (this.state.selectedRowCity !== undefined) {
            var pattern3 = new RegExp(/^[a-zA-Z- ]*$/);
            if (!pattern3.test(this.state.selectedRowCity)) {
                this.setState({
                    errormsgCity: "Please do not use special characters or digits",
                    selectedRowErrorMsgClassalert: "alert-small-text"
                });
                document.getElementById("divCity").style.display = "block";
                IsValid = false;
            }
        }
        if (this.stateSelector.value === null || this.stateSelector.value === "" || this.stateSelector.value === "selectstate") {
            this.setState({
                errormsgState: "State is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divState").style.display = "block";
            IsValid = false;
        }
        if (this.state.selectedRowZip === null || this.state.selectedRowZip === "") {
            this.setState({
                errormsgZip: "Zip is required",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divZip").style.display = "block";
            IsValid = false;
        }
        else if (this.state.selectedRowZip.length < 5) {
            this.setState({
                errormsgZip: "Invalid zip number",
                selectedRowErrorMsgClassalert: "alert-small-text"
            });
            document.getElementById("divZip").style.display = "block";
            IsValid = false;
        }
        return IsValid;
    }

    setdivmsg = async () => {
        let checkdiv = ""
        this.setState({
            errormsgFirstName: "",
            errormsgMiddleName: "",
            errormsgLastName: "",
            errormsgPhone: "",
            //  errormsgReco: "",
            errormsgAddress: "",
            errormsgCity: "",
            errormsgState: "",
            errormsgZip: "",
            selectedRowErrorMsgClass: "",
        });

        document.getElementById("divFirstName").style.display = "none";
        document.getElementById("divMiddleName").style.display = "none";
        document.getElementById("divLastName").style.display = "none";
        document.getElementById("divPhone").style.display = "none";
        // document.getElementById("divReco").style.display = "none";
        document.getElementById("divAddress").style.display = "none";
        document.getElementById("divCity").style.display = "none";
        document.getElementById("divState").style.display = "none";
        document.getElementById("divZip").style.display = "none";

        return checkdiv;
    }

    updatePropertyOwner = async () => {
        var checkradio = false;
        if (this.state.selectedRowWantToBeRecognized === "Yes") {
            checkradio = true;
        } else {
            checkradio = false;
        }
        if (this.statusSelector.value === "selectstatus") {
            this.statusSelector.value = "";
        }
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/whocupdatepropertyowner';
        let myInit = { // OPTIONAL
            body: {
                "FirstName": this.state.selectedRowFirstName,
                "MiddleName": this.state.selectedRowMiddleName,
                "LastName": this.state.selectedRowLastName,
                "Phone": this.state.selectedRowPhone,
                "EmailID": this.state.selectedRowEmail,
                "Address": this.state.selectedRowAddress,
                "City": this.state.selectedRowCity,
                "State": this.stateSelector.value,//this.state.selectedRowState,
                "Status": this.statusSelector.value,
                "WantToBeRecognized": checkradio,
                "Zip": this.state.selectedRowZip,
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    clearValues = () => {
        this.setState({
            firstName: "",
            selectedManagementCompany: null,
            lastName: "",
            emailID: "",
            phone: "",
            phoneExtension: "",
            IsParticipationAgreementSigned: false,
            errormsgAddFirstName: "",
            errormsgAddMiddleName: "",
            errormsgAddLastName: "",
            errormsgAddPhone: "",
            errormsgAddPhoneExtension: "",
            errormsgAddEmail: "",
            isUpdateDisabled: false,
            isDisabled: false,
            isLoading: false
        });
        document.getElementById("divAddFirstName").style.display = "none";
        document.getElementById("divAddCompany").style.display = "none";
        document.getElementById("divAddLastName").style.display = "none";
        document.getElementById("divAddPhone").style.display = "none";
        document.getElementById("divAddPhoneExtension").style.display = "none";

        document.getElementById("divAddEmail").style.display = "none";
    }

    clearselectValues = () => {
        //document.getElementById("divAddEmail").value=this.state.selectedRowFirstName;
        this.setState({
            selectedRowFirstName: this.state.saveselectedFirstName,
            selectedRowMiddleName: this.state.saveselectedMiddleName,
            selectedRowLastName: this.state.saveselectedLastName,
            selectedRowPhone: this.state.saveselectedPhone,
            selectedRowAddress: this.state.saveselectedAddress,
            selectedRowCity: this.state.saveselectedCity,
            selectedRowZip: this.state.saveselectedZip,
            selectedRowWantToBeRecognized: this.state.saveselectedWantToBeRecognized,
            errormsgFirstName: "",
            errormsgMiddleName: "",
            errormsgLastName: "",
            errormsgPhone: "",
            //  errormsgReco: "",
            errormsgAddress: "",
            errormsgCity: "",
            errormsgState: "",
            errormsgZip: "",
            selectedRowErrorMsgClass: "",
            checkOnRowSelectPhone: false,
            isLoading: false
        });

        this.stateSelector.value = this.state.saveselectedState;
        this.statusSelector.value = this.state.saveselectedStatus;

        document.getElementById("divFirstName").style.display = "none";
        document.getElementById("divMiddleName").style.display = "none";
        document.getElementById("divLastName").style.display = "none";
        document.getElementById("divPhone").style.display = "none";
        //  document.getElementById("divReco").style.display = "none";
        document.getElementById("divAddress").style.display = "none";
        document.getElementById("divCity").style.display = "none";
        document.getElementById("divState").style.display = "none";
        document.getElementById("divZip").style.display = "none";
    }

    updateFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ firstName: name });
    }

    updateCompany = (evt) => {
        var name = evt.target.value;
        this.setState({ company: name });
    }

    updateLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ lastName: name });
    }

    updateEmailID = (evt) => {
        var name = evt.target.value;
        this.setState({ emailID: name });
    }

    updatePhone = (evt) => {
        var name = evt.target.value;
        var myString = name.replace(/\D/g, '');
        this.setState({ phone: myString });
    }

    updatePhoneExtension = (evt) => {
        var name = evt.target.value;
        this.setState({ phoneExtension: name });
    }

    OnRadioButtonsChanged(event) {
        const { value } = event.target;
        if (value === 'true') {
            this.setState({ IsParticipationAgreementSigned: true });
        }
        else {
            this.setState({ IsParticipationAgreementSigned: false });
        }
        //  this.setState({ propertyUnit: currentState });
    }

    /* Model text box funtion start */
    updateSelectFirstName = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowFirstName: name });
    }

    updateSelectMiddleName = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowMiddleName: name });
    }

    updateSelectLastName = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowLastName: name });
    }

    updateSelectEmailID = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowEmail: name });
    }

    updateSelectPhone = (evt) => {
        var name = evt.target.value;
        var myString = name.replace(/\D/g, '');
        this.setState({ selectedRowPhone: myString });
        // this.setState({ selectedRowPhone: myString });
        // if (this.state.checkOnRowSelectPhone === true) {
        //   this.setState({ selectedRowPhone: name });
        // } else {
        //  var myString = name.replace(/\D/g, '');
        // this.setState({ selectedRowPhone: myString });
        //}
    }

    updateSelectAddress = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowAddress: name });
    }

    updateSelectCity = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowCity: name });
    }

    updateSelectState = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowState: name });
    }

    updateSelectZip = (evt) => {
        var name = evt.target.value;
        var myString1 = name.replace(/\D/g, '');
        this.setState({ selectedRowZip: myString1 });
    }

    onValueChange = (evt) => {
        var name = evt.target.value;
        this.setState({ selectedRowWantToBeRecognized: name });
    }

    /* Model text box funtion End */
    closeMsg2 = () => {
        this.setState({ showAlert2: "none", msg: "" });
    }

    getViewUnitCount = async () => {
        this.setState({ isLoading: true });
        //var user = await Auth.currentAuthenticatedUser();
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getviewunitcount';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.get(apiName, path, myInit);
    }

    render() {
        const { classes } = this.props;
        if (this.showHTML === true) {
            this.state.options.page = this.state.page;
            this.state.options.sortOrder = this.state.sortOrder;
            this.state.options.count = this.state.count;
            this.state.options.rowsPerPage = this.state.rowsPerPage;
            this.state.options.searchText = this.state.searchText;
            document.body.classList.remove('dashboard');
            return (

                <div>
                    <MuiThemeProvider>
                     <Collapse in={this.state.openBar}>
                    <Alert 
                    className={classes.myAlert}
                    icon={<NotificationsIcon fontSize="inherit" />}
                    variant="filled" 
                    severity="info"
                    action={
                        <IconButton
                          aria-label="close"
                          color="inherit"
                          size="small"
                          onClick={() => this.setState({ openBar: false })}
                        >
                          <CloseIcon fontSize="inherit" />
                        </IconButton>
                      }>
                            {this.state.unitCount} new units added when you were away. <a class="notification-text" href="/welcomehomeoc/units">Click here </a> to view unit listing. 
                    </Alert>
                    </Collapse>
                    </MuiThemeProvider>
                    <br />
                    <h3 className="profile-title" style={{ whiteSpace: 'nowrap' }}>
                        <i class="fas fa-house-user mr-2"></i>
                        <span>Users</span>
                    </h3>
                    {/* search section START */}
                    <div className="yellow-box mb-2">
                        <div class="form-row search-area">

                            <div class="form-group col-md-2 col-xl-1">
                                <label>First Name<span style={{ color: "red" }}>*</span></label>
                                <input className="form-control" id="txtFirstName" type="text" maxlength="100" onChange={this.updateFirstName} placeholder="First Name" value={this.state.firstName}></input>
                                <div id="divAddFirstName" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddFirstName}</div>
                            </div>
                            <div class="form-group col-md-2 col-xl-1">
                                <label>Last Name<span style={{ color: "red" }}>*</span></label>
                                <input className="form-control" id="txtLastName" type="text" maxlength="100" onChange={this.updateLastName} placeholder="Last Name" value={this.state.lastName}></input>
                                <div id="divAddLastName" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddLastName}</div>
                            </div>
                            <div class="form-group col-md-2 col-xl-2">
                                <label>Company<span style={{ color: "red" }}>*</span></label>
                                {/* <input className="form-control" id="txtMiddletName" type="text" maxlength="100" onChange={this.updateCompany} placeholder="Company" value={this.state.company}></input> */}
                                <Autocomplete className="uw-autocomplete"
                                    value={this.state.selectedManagementCompany}
                                    onChange={(event, newValue1) => {
                                        if (typeof newValue1 === 'string') {
                                            this.setState({ selectedManagementCompany: newValue1 });
                                        } else if (newValue1 && newValue1.inputValue) {
                                            this.state.managementCompanies.push({ title: newValue1.inputValue, Id: "0" });
                                            this.setState({ selectedManagementCompany: { title: newValue1.inputValue, Id: "0" } });
                                        } else {
                                            this.setState({ selectedManagementCompany: newValue1 });
                                        }
                                    }}
                                    filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                                        let isFound = false;
                                        filtered.forEach(e => {
                                            if (e.title === params.inputValue) {
                                                isFound = true;
                                            }
                                        });
                                        // Suggest the creation of a new value
                                        if (params.inputValue !== '' && !isFound) {
                                            filtered.push({
                                                inputValue: params.inputValue,
                                                //title: `Add "${params.inputValue}"`,
                                                title: `+ New Company "${params.inputValue}"`,
                                            });
                                        }
                                        return filtered;
                                    }}
                                    selectOnFocus
                                    clearOnBlur
                                    handleHomeEndKeys
                                    //autoComplete={true}
                                    autoSelect={true}
                                    id="ManagementCompany"
                                    options={this.state.managementCompanies}
                                    getOptionLabel={(option) => {
                                        // Value selected with enter, right from the input
                                        if (typeof option === 'string') {
                                            return option;
                                        }
                                        // Add "xxx" option created dynamically
                                        if (option.inputValue) {
                                            return option.inputValue;
                                        }
                                        // Regular option
                                        return option.title;
                                    }}
                                    renderOption={(option) => option.title}
                                    // style={{ width: 242 }}
                                    //freeSolo
                                    renderInput={(params) => (
                                        <TextField {...params} className="form-control" />
                                    )}
                                />
                                <div id="divAddCompany" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddMiddleName}</div>
                            </div>
                            <div class="form-group col-md-2 col-xl-2">
                                <label>Email<span style={{ color: "red" }}>*</span></label>
                                <input className="form-control" id="txtEmail" type="text" maxlength="100" onChange={this.updateEmailID} placeholder="Email" value={this.state.emailID}></input>
                                <div id="divAddEmail" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddEmail}</div>
                            </div>
                            <div class="form-group col-md-2 col-xl-1">
                                <label>Phone<span style={{ color: "red" }}>*</span></label>
                                <input className="form-control" id="txtPhone" type="text" maxlength="10" onChange={this.updatePhone} placeholder="Phone" value={this.state.phone}></input>
                                <div id="divAddPhone" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddPhone}</div>
                            </div>
                            <div class="form-group col-md-2 col-xl-1">
                                <label>Extension</label>
                                <input className="form-control" id="txtExtension" type="text" maxlength="8" onChange={this.updatePhoneExtension} placeholder="Extension" value={this.state.phoneExtension}></input>
                                <div id="divAddPhoneExtension" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddPhoneExtension}</div>
                            </div>
                            <div class="form-group col-md-4 col-xl-2">
                                <label>Participation Agreement already signed?</label>
                                <div class="ml-4">
                                    <div class="custom-control-inline custom-radio whoc-radio">
                                        <input type="radio" name="IsParticipationAgreementSigned" id="IsParticipationAgreementSignedTrue" value="true" class="custom-control-input"
                                            checked={this.state.IsParticipationAgreementSigned == true}
                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                        <label class="custom-control-label" for="IsParticipationAgreementSignedTrue">Yes</label>
                                    </div>
                                    <div class="custom-control-inline custom-radio whoc-radio ml-4">
                                        <input type="radio" name="IsParticipationAgreementSigned" value="false" class="custom-control-input" id="IsParticipationAgreementSignedFalse"
                                            checked={this.state.IsParticipationAgreementSigned == false}
                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                        <label class="custom-control-label" for="IsParticipationAgreementSignedFalse">No</label>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-xl-2 pt-0 pt-xl-4">
                                <button className="btn btn-primary btn-dark mr-2" disabled={this.state.isDisabled} onClick={this.add} ><i class="fas fa-user-plus mr-1"></i>Invite</button>
                                <button className="btn btn-secondary btn-light" onClick={this.clearValues} ><i class="fas fa-undo mr-1"></i>Reset</button>
                            </div>
                        </div>
                    </div>
                    <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert }}>
                        <button className="close-panel2" onClick={this.closeMsg}>X</button>
                        <div className="alert-text">
                            {
                                this.state.msg
                            }
                        </div>
                    </div>
                    {/* search section END */}

                    {/* content area starts */}
                    <div className="search-claims-form w-100 h-100 p-0 welcome-grid">
                        <div className="data-section">
                            <div className="data-table" style={{ height: this.state.dataheight }}>
                                <React.Fragment>
                                    <Backdrop open={this.state.isLoading} >
                                        <CircularProgress />
                                    </Backdrop>

                                    {/* {this.state.isLoading && (
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                )} */}
                                    <div className="mui-table-border">
                                        <MuiThemeProvider theme={this.getMuiTheme()}>
                                            <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} components={{ TableViewCol: TableViewCol }} />
                                        </MuiThemeProvider>
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>

                        <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert }}>
                            <button className="close-panel2" onClick={this.closeMsg}>X</button>
                            <div className="alert-text">
                                {
                                    this.state.msg
                                }
                            </div>
                        </div>

                    </div>

                    <div className="process-section">
                        <div className="approve-section bg-white p-3 box-shadow text-left" style={
                            { display: this.state.showDetails }
                        }>
                            <div className="modal-backdrop-static"></div>
                            <div className="modal d-block" tabindex="-1">
                                <div className="modal-dialog modal-lg modal-dialog-centered">
                                    <div className="modal-content border-0">
                                        <div className="modal-header">
                                            <h5 className="modal-title"> <i class="fas fa-house-user mr-2"></i> Edit Property User Profile</h5>
                                            <button type="button" className="close text-white op-1" data-dismiss="modal" aria-label="Close" onClick={this.clearAndClosePopup}>
                                                <i class="fas fa-times"></i>
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <label>First Name<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.updateSelectFirstName} placeholder="First Name" value={this.state.selectedRowFirstName}></input>
                                                    <div id="divFirstName" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgFirstName}</div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Middle Name<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.updateSelectMiddleName} placeholder="Middle Name" value={this.state.selectedRowMiddleName}></input>
                                                    <div id="divMiddleName" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgMiddleName}</div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Last Name<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.updateSelectLastName} placeholder="Last Name" value={this.state.selectedRowLastName}></input>
                                                    <div id="divLastName" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgLastName}</div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Email</label>
                                                    <input className="form-control" type="text" disabled="true" maxlength="100" onChange={this.updateSelectEmailID} placeholder="Email" value={this.state.selectedRowEmail}></input>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="form-group col-md-3">
                                                    <label>Address<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.updateSelectAddress} placeholder="Address" value={this.state.selectedRowAddress}></input>
                                                    <div id="divAddress" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgAddress}</div>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>City<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.updateSelectCity} placeholder="City" value={this.state.selectedRowCity}></input>
                                                    <div id="divCity" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgCity}</div>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <div className="form-group">
                                                        <label>State<span style={{ color: "red" }}>*</span></label>
                                                        <select className="form-control" ref={(input) => this.stateSelector = input}>
                                                            <option value="selectstate">Please Select</option>
                                                            <option value="AA"> AA </option>
                                                            <option value="AE"> AE </option>
                                                            <option value="AK"> AK </option>
                                                            <option value="AL"> AL </option>
                                                            <option value="AP"> AP </option>
                                                            <option value="AR"> AR </option>
                                                            <option value="AS"> AS </option>
                                                            <option value="AZ"> AZ </option>
                                                            <option value="CA"> CA </option>
                                                            <option value="CO"> CO </option>
                                                            <option value="CT"> CT </option>
                                                            <option value="DC"> DC </option>
                                                            <option value="DE"> DE </option>
                                                            <option value="FL"> FL </option>
                                                            <option value="GA"> GA </option>
                                                            <option value="GU"> GU </option>
                                                            <option value="HI"> HI </option>
                                                            <option value="IA"> IA </option>
                                                            <option value="ID"> ID </option>
                                                            <option value="IL"> IL </option>
                                                            <option value="IN"> IN </option>
                                                            <option value="KS"> KS </option>
                                                            <option value="KY"> KY </option>
                                                            <option value="LA"> LA </option>
                                                            <option value="MA"> MA </option>
                                                            <option value="MD"> MD </option>
                                                            <option value="ME"> ME </option>
                                                            <option value="MI"> MI </option>
                                                            <option value="MN"> MN </option>
                                                            <option value="MO"> MO </option>
                                                            <option value="MP"> MP </option>
                                                            <option value="MS"> MS </option>
                                                            <option value="MT"> MT </option>
                                                            <option value="NC"> NC </option>
                                                            <option value="ND"> ND </option>
                                                            <option value="NE"> NE </option>
                                                            <option value="NH"> NH </option>
                                                            <option value="NJ"> NJ </option>
                                                            <option value="NM"> NM </option>
                                                            <option value="NV"> NV </option>
                                                            <option value="NY"> NY </option>
                                                            <option value="OH"> OH </option>
                                                            <option value="OK"> OK </option>
                                                            <option value="OR"> OR </option>
                                                            <option value="PA"> PA </option>
                                                            <option value="PR"> PR </option>
                                                            <option value="RI"> RI </option>
                                                            <option value="SC"> SC </option>
                                                            <option value="SD"> SD </option>
                                                            <option value="TN"> TN </option>
                                                            <option value="TX"> TX </option>
                                                            <option value="UT"> UT </option>
                                                            <option value="VA"> VA </option>
                                                            <option value="VI"> VI </option>
                                                            <option value="VT"> VT </option>
                                                            <option value="WA"> WA </option>
                                                            <option value="WI"> WI </option>
                                                            <option value="WV"> WV </option>
                                                            <option value="WY"> WY </option>
                                                        </select>
                                                        <div id="divState" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgState}</div>
                                                    </div>
                                                </div>
                                                <div className="form-group col-md-2">
                                                    <label>Zip<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" maxlength="5" onChange={this.updateSelectZip} placeholder="Zip" value={this.state.selectedRowZip}></input>
                                                    <div id="divZip" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgZip}</div>
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Phone<span style={{ color: "red" }}>*</span></label>
                                                    <input className="form-control" type="text" minLength="10" maxlength="10" onChange={this.updateSelectPhone} placeholder="Phone" value={this.state.selectedRowPhone}></input>
                                                    <div id="divPhone" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgPhone}</div>
                                                </div>

                                            </div>
                                            <div className="form-row">
                                                <div className="col-auto">
                                                    <legend class="col-form-label">Would you like to be recognized as a WelcomeHomeOC champion?</legend>
                                                </div>
                                                <div className="col-auto">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" id="recogniz" value="Yes" checked={this.state.selectedRowWantToBeRecognized === 'Yes'} onChange={this.onValueChange} name="recogniz" class="custom-control-input" />
                                                        <label class="custom-control-label pt-1 mt-1" for="recogniz">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" id="recogniz2" value="No" checked={this.state.selectedRowWantToBeRecognized === 'No'} onChange={this.onValueChange} name="recogniz" class="custom-control-input" />
                                                        <label class="custom-control-label pt-1 mt-1" for="recogniz2">No</label>
                                                    </div>
                                                    {/* <div id="divReco" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgReco}</div> */}
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Status</label>
                                                    <select className="form-control" ref={(input) => this.statusSelector = input}>
                                                        <option value="selectstatus">Please Select</option>
                                                        <option value="Active">Active</option>
                                                        <option value="Inactive">Inactive</option>
                                                    </select>
                                                    {/* <div id="divPhone" class={this.state.selectedRowErrorMsgClassalert}>{this.state.errormsgPhone}</div> */}
                                                </div>
                                            </div>
                                            <div className="text-right">
                                                <button className="btn btn-primary btn-dark mr-2" disabled={this.state.isUpdateDisabled} onClick={this.update} color="black">Save</button>
                                                <button className="btn btn-secondary btn-light" onClick={this.clearselectValues} >Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* content area ends */}
                    <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                        open={this.state.openSnackbar}
                        autoHideDuration={3000}
                        severity={this.state.snackbarSeverity}
                        onClose={() => this.setState({ openSnackbar: false })}
                        message={this.state.snackbarMessage}
                        className={this.state.snackbarMessageClass}
                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                    />

                    {/* <Dialog
                        open={this.state.openDialog}
                        onClose={() => this.setState({ openDialog: false })}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"New unit is Submitted"}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Let Google help apps determine location. This means sending anonymous
                                location data to Google, even when no apps are running.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.setState({ openDialog: false })} autoFocus>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog> */}
                </div>
            );
        }
        else {
            return (
                <React.Fragment>

                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}

//export default PropertyOwnerListing;
export default withStyles(useStyles)(PropertyOwnerListing);