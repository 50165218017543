import React from "react";
import { API, Auth } from 'aws-amplify';
import imgLogo from './imgs/logo-united-way.png';
import imgHomeless from './imgs/homeless.png';
import { NavLink } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import AssistocLogo from './imgs/logo-assist-oc-inner.png';

let count = 0;
let UserName = '';
const images = require.context('./imgs/', true);

class App extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            isLoading: false,

            mysession: "Press Button to load session",
            showAddUser: "none",
            showSubmitClaim: "none",
            showLogin: "block",
            showPropertyOwner: "none",
            role: [],
            applications: [],
            isPermissionsLoad: false,
            permissions: [],
        }
        this.updatePanels = this.updatePanels.bind(this);
    }

    componentDidMount = async () => {
        // avoided unnecessary calls
        // await this.getApplicationInfo();
    }

    componentDidUpdate() {
        if (this.props.authState === "signedIn" && this.state.isPermissionsLoad === false) {
            this.getApplicationInfo();
            this.setState({ isPermissionsLoad: true });
        }
    }

    getApplicationInfo = async (e) => {
        this.setState({ isLoading: true });

        var user = await Auth.currentAuthenticatedUser();
        UserName = user.attributes.name;
        localStorage.setItem("UserName", UserName);
        let roles = '';
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        this.setState({ role: cognitogroups });

        if (this.state.role && this.state.role.length > 0) {
            var res = await this.getPermissions(cognitogroups);
            this.setState({ permissions: res.permissions });
            localStorage.setItem('permissions', JSON.stringify(res.permissions));

            // if (this.state.role.length === 1) {
            //     if (this.state.role[0] === 'AssistOCAdmin') {
            //         this.updatePanels(3);
            //     } else {
            //         this.updatePanels(4);
            //     }
            // }
            if (this.state.role && this.state.role.length > 0) {
                let assistoc = false;
                if (this.state.role.includes('AssistOCAdmin') || this.state.role.includes('AssistOCHPPAdmin') || this.state.role.includes('AssistOCCEPPAdmin') || this.state.role.includes('AssistOCIrvineAdmin')
                    || this.state.role.includes('AssistOCSantaAnaAdmin') || this.state.role.includes('AssistOCCEPPUser') || this.state.role.includes('AssistOCIrvineUser') || this.state.role.includes('AssistOCSantaAnaUser')) {
                    assistoc = true;
                }
                if (assistoc == true && !this.state.role.includes('WHOCAdmin') && !this.state.role.includes('WHOCProgramAdmin')) {
                    this.updatePanels(3);
                }
                else if (assistoc == false && (this.state.role.includes('WHOCAdmin') || this.state.role.includes('WHOCProgramAdmin'))) {
                    this.updatePanels(4);
                }
                // else if((this.state.role.includes('AssistOCAdmin') || this.state.role.includes('AssistOCHPPAdmin') || this.state.role.includes('AssistOCCEPPAdmin') || this.state.role.includes('AssistOCIrvineAdmin')
                // || this.state.role.includes('AssistOCSantaAnaAdmin') || this.state.role.includes('AssistOCCEPPUser') || this.state.role.includes('AssistOCIrvineUser') || this.state.role.includes('AssistOCSantaAnaUser')) && this.state.role.includes('WHOCAdmin')) {
                //     // 2 project
                //    // this.updatePanels(2);
                // }

            }
        }
        else {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true, snackbarMessage: "Permission to access application is not provided yet. Please contact Application Administrator", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            return false;
        }
        for (let i = 0; this.state.role.length > i; i++) {
            if (i === 0) {
                roles = "'" + this.state.role[i] + "'"
            } else {
                roles = roles + ",'" + this.state.role[i] + "'"
            }
        }

        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getapplicationlist';
        let myInit = {
            body: roles,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        var res = await API.post(apiName, path, myInit);
        // if (res.length === 1) {
        //     this.updatePanels(Number(res[0].URL));
        // }
        this.setState({ applications: res });
        this.setState({ isLoading: false });
    }

    getPermissions = async (e) => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getuserpermissions';
        let myInit = {
            body: {
                "info": {
                    "groupName": e,
                }
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    signOut = async () => {
        try {
            count = 0;
            await Auth.signOut()
            this.setState({ isPermissionsLoad: false });
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    updatePanels = (n) => {
        if (n === 1) {
            window.location.assign("/adduser");
        }
        else if (n === 2) {
            window.location.assign("/");
        }
        else if (n === 3) {
            window.location.assign("/assistoc");
        }
        else if (n === 4) {
            window.location.assign("/welcomehomeoc");
        }
    }

    render() {
        if (this.props.authState === "signedIn") {
            // avoided unnecessary calls
            // if (count === 0) {
            //     this.getApplicationInfo();
            //     count = 1;
            // }
            document.body.classList.remove('login');
            document.body.classList.add('dashboard');
            return (
                <div>
                    <div style={{ display: this.state.showLogin }}>
                        <Helmet>
                            <title>UnitedWayOC</title>
                        </Helmet>
                        {/* header starts */}
                        <header>
                            <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                                <a className="navbar-brand assist-oc-logo" href="#">
                                    <img src={imgLogo} height="70" className="logo" alt="Assist OC logo" loading="lazy" />
                                </a>
                                <a className="navbar-brand" href="#">
                                    <img src={AssistocLogo} height="40" className="logo" alt="Assist OC logo" loading="lazy" />
                                </a>
                                <div className="ml-auto">
                                    <span className="text-primary">Welcome {UserName}</span>
                                    <span className="header-pipeline">|</span>
                                    <button className="btn btn-link red pr-0 pl-1" onClick={this.signOut}><i className="fas fa-power-off ml-1"></i></button>
                                    {/* {<button className="btn btn-link text-secondary" onClick={this.getSessionInfo}>Get Session</button> } */}
                                </div>
                            </nav>
                        </header>
                        {/* header ends */}

                        {/* main content starts */}
                        <main className="main pb-3">
                            <div className="container-fluid">
                                <div className="card-shadow mb-5 mb-md-0">
                                    <div className="row">
                                        <div className="col-md-6 first text-center pr-5">
                                            <div className="welcome-box">
                                                <p className="text-welcome mb-0">Welcome to Orange County United Way's</p>
                                                <h2 className="text-primary title mb-0">AssistOC Platform</h2>
                                                <hr />
                                                <p className="welcome-subtitle">Please select the program you which to access</p>
                                                <div className="pb-row">
                                                    {this.state.applications.map(item => (
                                                        item.ApplicationName == "Emergency Rental Assistance" ? 
                                                        <div className={'pb-col ' + item.CssClass} key={item}>
                                                            <NavLink to={item.URL}>
                                                                <div className="program-box">
                                                                    <div className="program-logo">
                                                                        {/* <img src={images('./' + item.Icon)} className="logo" alt={item.Title} loading="lazy" /> */}
                                                                        <h2 class="ERA-title">Emergency Rental Assistance</h2>
                                                                    </div>
                                                                    <div className="program-content">
                                                                        {/* <h3 className="title">{item.Title}</h3> */}
                                                                        <h3 className="title">{item.ApplicationName}</h3>
                                                                        <p>{item.Description}</p>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div> 
                                                        :
                                                        <div className={'pb-col ' + item.CssClass} key={item}>
                                                            <NavLink to={item.URL}>
                                                                <div className="program-box">
                                                                    <div className="program-logo">
                                                                        <img src={images('./' + item.Icon)} className="logo" alt={item.Title} loading="lazy" />
                                                                        {/* <h2>Emergency Rental Assistance</h2> */}
                                                                    </div>
                                                                    <div className="program-content">
                                                                        <h3 className="title">{item.Title}</h3>
                                                                        <h3 className="title">{item.ApplicationName}</h3>
                                                                        <p>{item.Description}</p>
                                                                    </div>
                                                                </div>
                                                            </NavLink>
                                                        </div>

                                                    
                                                        // <div className={'pb-col ' + item.CssClass} key={item}>
                                                        //     <NavLink to={item.URL}>
                                                        //         <div className="program-box">
                                                        //             <div className="program-logo">
                                                        //                 <img src={images('./' + item.Icon)} className="logo" alt={item.Title} loading="lazy" />
                                                        //                 {/* <h2>Emergency Rental Assistance</h2> */}
                                                        //             </div>
                                                        //             <div className="program-content">
                                                        //                 <h3 className="title">{item.Title}</h3>
                                                        //                 <h3 className="title">{item.ApplicationName}</h3>
                                                        //                 <p>{item.Description}</p>
                                                        //             </div>
                                                        //         </div>
                                                        //     </NavLink>
                                                        // </div>
                                                    ))}
                                                </div>
                                                <hr />
                                            </div>
                                        </div>
                                        <div className="col-md-6 order-md-first">
                                            <div className="hero-image" style={{ backgroundImage: "url(" + imgHomeless + ")" }}>
                                                <img src={imgHomeless} className="d-block d-md-none img-fluid w-100" alt="homeless person image" loading="lazy" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                        {/* main content ends */}

                    </div>
                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                    <Snackbar className="Mui-filledSuccess" anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                        open={this.state.openSnackbar}
                                        autoHideDuration={3000}
                                        severity="success"
                                        onClose={() => this.setState({ openSnackbar: false })}
                                        message={this.state.snackbarMessage}
                                        className={this.state.snackbarMessageClass}
                                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                    />
                </div>

            );
        } else {
            return null;
        }
    }
}

export default App;