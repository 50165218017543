import React from "react";
import homeBG from '../imgs/property-img.jpg';
import { API, Storage, Auth } from 'aws-amplify';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
// import FieldArraysForm from "./fieldArraysForm";
// import FieldArraysFormRepresentative from "./fieldArraysFormRepresentative";
import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
// import { Provider } from "react-redux";
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withRouter } from "react-router";
import $ from "jquery";

const reducer = combineReducers({
    form: reduxFormReducer,
});

const store = (window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore)(reducer);

const filter = createFilterOptions();
//const [value, setValue] = React.useState(null);
class EditProperty extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            propertyDetails: "",
            portalUser: "",
            propertyDocumentModels: [],
            units: [],
            managers: [],
            representatives: [],

            validationErrors: {},

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            leaseDocument: null,
            w9document: null,
            w9Uploaded: false,
            ruleDocument: null,
            supplementDocument: null,
            leadbasedpaintDocument: null,
            propertyImage: null,

            imageUploaded: 0,
            selectedFile: null,
            selectedManagementCompany: null,
            managementCompanies: [],

            //selectedPortalUserCentralContactId: '',

            selectedPropertyOwner: null,
            centralContacts: [],
            centralContactManagerUsers: [],

            selectedPropertyManager: null,
            isMangerSelected: false,
            isAddManager: false,
            managerIndex: 0,
            managerList: [],

            owner: {},
            po_FirstName: "",
            po_LastName: "",
            po_Address: "",
            po_City: "",
            po_State: "",
            po_Zip: "",
            po_EmailID: "",
            po_Phone: "",
            po_AirtablePeoplePropId: "",
            po_SalesforceContactId: "",
            po_ManagementCompanyId: "",
            po_ManagementCompanyName: "",
            po_AirtableCompanyId: "",
            po_SalesforceCompanyId: "",

            po_isPOEdited: false,

            ma_FirstName: "",
            ma_LastName: "",
            ma_EmailID: "",
            ma_Phone: "",
            ma_PhoneExtension: "",
            ma_AdditionalPhone: "",
            ma_AirtablePeoplePropId: "",
            ma_SalesforceContactId: "",
            managerValidationErrors: {},
            ma_FirstNameError: false,
            ma_LastNameError: false,
            ma_EmailIDError: false,
            ma_PhoneError: false,
            ma_PhoneExtensionError: false,
            ma_AdditionalPhoneError: false,

        }

        this.showHTML = false;
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });

        try {
            await Auth.currentAuthenticatedUser();
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
            this.signOut();
            return;
        }
        this.showHTML = true;

        var res = await this.getPropertyDetails();
        this.setState({ propertyDetails: res });
        if (res.Managers) {
            this.setState({ managers: res.Managers.filter(item => item.ContactType === 'Manager') });
            this.setState({ representatives: res.Managers.filter(item => item.ContactType === 'Represent') });
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3WHOCAdminBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });
        if (res.LeaseDocumentName !== '') {
            var file1 = await Storage.get(res.LeaseDocumentPath);
            this.setState({ leaseDocument: file1 });
        }
        if (res.W9DocumentName !== '') {
            var file1 = await Storage.get(res.W9DocumentPath);
            this.setState({ w9document: file1, w9Uploaded: true });
        }
        if (res.RuleDocumentName !== '') {
            var file1 = await Storage.get(res.RuleDocumentPath);
            this.setState({ ruleDocument: file1 });
        }
        if (res.SupplementDocumentName !== '') {
            var file1 = await Storage.get(res.SupplementDocumentPath);
            this.setState({ supplementDocument: file1 });
        }
        if (res.LeadBasedPaintDocumentName !== '') {
            var file1 = await Storage.get(res.LeadBasedPaintDocumentPath);
            this.setState({ leadbasedpaintDocument: file1 });
        }
        if (res.ImageName !== '') {
            var file1 = await Storage.get(res.ImagePath);
            // replace the relative iamge S3 path to its absolute image S3 path of the property.
            this.state.propertyDetails.ImagePath = file1;
            this.setState({ propertyImage: file1 });
        }
        //this.state.propertyDetails.PropertyOwnerSalesforceId 
        this.setState({ portalUser: res.portalUser });
        if (res.Units) {
            this.setState({ units: res.Units });
        }
        if (res.Managers && res.Managers.length > 0) {
            this.setState({ managerList: res.Managers });
        }

        // if (this.state.propertyDetails.WantToPHARegisterProperty === true) {
        await this.loadUploadedDocuments();
        console.log('res');
        console.log(res);
        // }
        var manaCompResponse = await this.getManagementCompanies();
        //var manaCompResponse = res.ManagementCompanies;
        if (manaCompResponse.total > 0) {
            manaCompResponse.data.forEach(e => {
                this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] + "-" + e[3] });
            });
        }
        this.state.propertyDetails.PreviousPropertyOwnerCentralContactId = res.PropertyOwnerCentralContactId;
        var centralContactResponse = await this.getCentralContacts();
        //var centralContactResponse = res.CentralContacts;
        if (centralContactResponse.length > 0) {
            centralContactResponse.forEach(e => {
                this.state.centralContacts.push({ title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'po_' + e['CentralContactId'] });
                this.state.centralContactManagerUsers.push({ title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'mo_' + e['CentralContactId'] });
                if (e['CentralContactId'] == res.PropertyOwnerCentralContactId) {
                    this.setState({ selectedPropertyOwner: { title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'po_' + e['CentralContactId'] } });
                    this.setState({
                        po_FirstName: res.owner['FirstName'],
                        po_LastName: res.owner['LastName'],
                        po_Address: res.owner['Address'],
                        po_City: res.owner['City'],
                        po_State: res.owner['State'],
                        po_Zip: res.owner['Zip'],
                        po_EmailID: res.owner['EmailID'],
                        po_Phone: res.owner['Phone'],
                        po_AirtablePeoplePropId: res.owner['AirtablePeoplePropId'],
                        po_SalesforceContactId: res.owner['SalesforceContactId'],
                        po_ManagementCompanyId: res.owner['ManagementCompanyId'],
                        po_ManagementCompanyName: res.owner['ManagementCompanyName'],
                        po_AirtableCompanyId: res.owner['AirtableCompanyId'],
                        po_SalesforceCompanyId: res.owner['SalesforceCompanyId'],
                        po_isPOEdited: false,
                    });
                    $('[id*=propOwner_]').attr('disabled', 'disabled');
                    var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if (this.state.po_EmailID === null || this.state.po_EmailID === "" || 
                    this.state.po_EmailID === undefined || !pattern4.test(this.state.po_EmailID)) {
                        $('#propOwner_EmailID').removeAttr('disabled');
                    }
                    if (this.state.po_LastName === null || this.state.po_LastName === "" || this.state.po_LastName === undefined) {
                        $('#propOwner_LastName').removeAttr('disabled');
                    }
                    this.setState({ po_isPOEdited: false });
                }
            });
        }
        this.setState({ selectedManagementCompany: (res.ManagementCompany ? { title: res.ManagementCompany.Title, Id: res.ManagementCompany.Id } : null) });
        $('[id*=propOwner_]').attr('disabled', 'disabled');
        this.setState({ isLoading: false });
    }

    getPropertyDetails = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertydetails';
        let testId = this.props.history.location.state?.ID ?
            this.props.history.location.state?.ID :
            localStorage.getItem('propertyId');
        let myInit = {
            body: testId.toString(),
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    editProperty = async (e) => {
        this.setState({ isLoading: true });
        e.preventDefault();
        if (!this.validateControls()) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            return;
        }


        // if (store.getState().form.FieldArrays && store.getState().form.FieldArrays.syncErrors) {
        //     this.setState({ isLoading: false });
        //     return;
        // }

        // if (store.getState().form.FieldArraysFormRepresentative && store.getState().form.FieldArraysFormRepresentative.syncErrors) {
        //     this.setState({ isLoading: false });
        //     return;
        // }

        // var managers = store.getState().form.FieldArrays.values.members;
        // var filterredManagers = managers.filter(item => item.Emailid !== "" && item.FirstName !== "" && item.LastName !== "");
        // var representatives = store.getState().form.FieldArraysFormRepresentative.values.members;
        // var filterredRepresentatives = representatives.filter(item => item.Emailid !== "" && item.FirstName !== "" && item.LastName !== "");
        // let managersNRepresentatives = [...filterredManagers];

        // for (var i = 0; this.state.propertyDetails.Managers.length > i; i++) {
        //     var toBeDeleted = managersNRepresentatives.filter(item => item.ManagerID === this.state.propertyDetails.Managers[i].ManagerID)
        //     if (toBeDeleted.length === 0) {
        //         toBeDeleted = this.state.propertyDetails.Managers[i];
        //         toBeDeleted.isDeleted = true;
        //         managersNRepresentatives.push(toBeDeleted);
        //     }
        // }

        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        if (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined) {
            this.state.propertyDetails.ManagementCompany = this.state.selectedManagementCompany;
        } else {
            this.state.propertyDetails.ManagementCompany = null;
        }
        //this.state.propertyDetails.ManagementCompany = this.state.selectedManagementCompany;
        //this.state.propertyDetails.PortalUserCentralContactId = this.state.selectedPortalUserCentralContactId.split('_')[1];
        // if (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != undefined && this.state.selectedPropertyOwner.Id != "0") {
        //     this.state.propertyDetails.PropertyOwnerCentralContactId = this.state.selectedPropertyOwner.Id.split('_')[1];
        //     //Note: this is spacial case where we are allowing user to edit owner email id.
        //     if (this.state.po_isPOEdited) {
        //         this.state.owner.po_EmailID = this.state.po_EmailID;
        //         this.state.owner.po_LastName = this.state.po_LastName;
        //         this.state.owner.po_AirtablePeoplePropId = this.state.po_AirtablePeoplePropId;
        //         this.state.owner.po_SalesforceContactId = this.state.po_SalesforceContactId;
        //         this.state.owner.po_ManagementCompanyId = this.state.po_ManagementCompanyId;
        //         this.state.owner.po_ManagementCompanyName = this.state.po_ManagementCompanyName;
        //         this.state.owner.po_AirtableCompanyId = this.state.po_AirtableCompanyId;
        //         this.state.owner.po_SalesforceCompanyId = this.state.po_SalesforceCompanyId;
        //         this.state.propertyDetails.owner = this.state.owner;
        //     } 
        //     this.state.propertyDetails.owner = this.state.po_isPOEdited ? this.state.owner : null;
            
        // } else {
        //     this.state.propertyDetails.PropertyOwnerCentralContactId = 0;
        //     if (this.state.po_FirstName || this.state.po_LastName || this.state.po_EmailID || this.state.po_Phone ||
        //         this.state.po_Address || this.state.po_City || this.state.po_State !== "--Select--" || this.state.po_Zip) {
        //         this.state.owner.po_FirstName = this.state.po_FirstName;
        //         this.state.owner.po_LastName = this.state.po_LastName;
        //         this.state.owner.po_EmailID = this.state.po_EmailID;
        //         this.state.owner.po_Phone = this.state.po_Phone;
        //         this.state.owner.po_Address = this.state.po_Address;
        //         this.state.owner.po_City = this.state.po_City;
        //         this.state.owner.po_State = this.state.po_State;
        //         this.state.owner.po_Zip = this.state.po_Zip;
        //         this.state.owner.po_AirtablePeoplePropId = this.state.po_AirtablePeoplePropId;
        //         this.state.owner.po_SalesforceContactId = this.state.po_SalesforceContactId;
        //         this.state.owner.po_ManagementCompanyId = this.state.po_ManagementCompanyId;
        //         this.state.owner.po_ManagementCompanyName = this.state.po_ManagementCompanyName;
        //         this.state.owner.po_AirtableCompanyId = this.state.po_AirtableCompanyId;
        //         this.state.owner.po_SalesforceCompanyId = this.state.po_SalesforceCompanyId;
        //         this.state.propertyDetails.owner = this.state.owner;
        //     }
        // }

        //----------Past: Start-----
        // this.state.propertyDetails.PropertyOwnerCentralContactId = (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != undefined && this.state.selectedPropertyOwner.Id != "0") ?
        //     this.state.selectedPropertyOwner.Id.split('_')[1] : 0;

        // if (this.state.po_FirstName || this.state.po_LastName || this.state.po_EmailID || this.state.po_Phone ||
        //     this.state.po_Address || this.state.po_City || this.state.po_State !== "--Select--" || this.state.po_Zip) {
        //     this.state.owner.po_FirstName = this.state.po_FirstName;
        //     this.state.owner.po_LastName = this.state.po_LastName;
        //     this.state.owner.po_EmailID = this.state.po_EmailID;
        //     this.state.owner.po_Phone = this.state.po_Phone;
        //     this.state.owner.po_Address = this.state.po_Address;
        //     this.state.owner.po_City = this.state.po_City;
        //     this.state.owner.po_State = this.state.po_State;
        //     this.state.owner.po_Zip = this.state.po_Zip;
        //     this.state.owner.po_AirtablePeoplePropId = this.state.po_AirtablePeoplePropId;
        //     this.state.owner.po_SalesforceContactId = this.state.po_SalesforceContactId;
        //     this.state.owner.po_ManagementCompanyId = this.state.po_ManagementCompanyId;
        //     this.state.owner.po_ManagementCompanyName = this.state.po_ManagementCompanyName;
        //     this.state.owner.po_AirtableCompanyId = this.state.po_AirtableCompanyId;
        //     this.state.owner.po_SalesforceCompanyId = this.state.po_SalesforceCompanyId;
        //     this.state.propertyDetails.owner = this.state.owner;
        // }
        //----------Past: End-----
        //----------New: Start-----
        this.state.propertyDetails.PropertyOwnerCentralContactId = (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != '0') ? this.state.selectedPropertyOwner.Id.split('_')[1] : '0';
        //if (this.state.po_isPOEdited) {
        this.state.owner.po_FirstName = this.state.po_FirstName;
        this.state.owner.po_LastName = this.state.po_LastName;
        this.state.owner.po_EmailID = this.state.po_EmailID;
        this.state.owner.po_Phone = this.state.po_Phone;
        this.state.owner.po_Address = this.state.po_Address;
        this.state.owner.po_City = this.state.po_City;
        this.state.owner.po_State = this.state.po_State;
        this.state.owner.po_Zip = this.state.po_Zip;
        this.state.owner.po_isPOEdited = this.state.po_isPOEdited;
        this.state.owner.po_AirtablePeoplePropId = this.state.po_AirtablePeoplePropId;
        this.state.owner.po_SalesforceContactId = this.state.po_SalesforceContactId;
        this.state.owner.po_ManagementCompanyId = this.state.po_ManagementCompanyId;
        this.state.owner.po_ManagementCompanyName = this.state.po_ManagementCompanyName;
        this.state.owner.po_AirtableCompanyId = this.state.po_AirtableCompanyId;
        this.state.owner.po_SalesforceCompanyId = this.state.po_SalesforceCompanyId;
        this.state.propertyDetails.owner = this.state.owner;
        //}

        //----------New: Start-----

        var propertyDetailsModel = this.state.propertyDetails;
        propertyDetailsModel.ModifiedBy = adminUser;

        if (this.state.propertyDetails.IsPHARegistered) {
            propertyDetailsModel.PHAStatus = "Registered";
        } else {
            propertyDetailsModel.PHAStatus = "Not Registered";
            propertyDetailsModel.WantToPHARegisterProperty = true;  // Now, or may be later
        }


        propertyDetailsModel.Phone = (this.state.propertyDetails.Phone === null || this.state.propertyDetails.Phone === undefined) ? '' :
            this.state.propertyDetails.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

        let managersNRepresentatives_Copy = [];
        for (var i = 0; this.state.managerList.length > i; i++) {
            var updatedManager = this.state.managerList[i];
            updatedManager.Phone = (updatedManager.Phone === null || updatedManager.Phone === undefined) ? '' : updatedManager.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            updatedManager.AdditionalPhone = (updatedManager.AdditionalPhone === null || updatedManager.AdditionalPhone === undefined) ? '' : updatedManager.AdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

            managersNRepresentatives_Copy.push(updatedManager);
        }
        propertyDetailsModel.Managers = managersNRepresentatives_Copy;
        propertyDetailsModel.Status = (propertyDetailsModel.IsStatusChangeInOwnership && propertyDetailsModel.Status === 'Inactive') ? 
        'Change in Ownership' : ((propertyDetailsModel.IsStatusTargetOutOfNetwork && propertyDetailsModel.Status === 'Inactive') ? 
        'Target, out-of-network' : propertyDetailsModel.Status);

        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updateproperty';
        let myInit = {
            body: propertyDetailsModel,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        let salesforceManCompId = '';
        let isSuccess = false;
        await API.post(apiName, path, myInit).then(response => {
            if (response.status) {
                salesforceManCompId = response.SalesforceManagementCompanyId;
                this.setState({ openSnackbar: true });
                this.setState({ snackbarMessage: 'Property updated successfully.' });
                this.setState({ snackbarMessageClass: 'Mui-filledSuccess' });
                isSuccess = true;
            }
        })
            .catch(error => {
                console.log(error);
                this.setState({ openSnackbar: true });
                this.setState({ snackbarMessage: 'Something went wrong, please contact administrator.' });
                this.setState({ snackbarMessageClass: 'Mui-filledError' });
            });

        if (propertyDetailsModel.SalesforceCommunityId) {
            let PHARegisteredList = [];
            if (propertyDetailsModel.IsPHARegistered) {
                if (propertyDetailsModel.IsPHAAuthorityAHA)
                    PHARegisteredList.push("AHA");
                if (propertyDetailsModel.IsPHAAuthorityOCHA)
                    PHARegisteredList.push("OCHA");
                if (propertyDetailsModel.IsPHAAuthoritySAHA)
                    PHARegisteredList.push("SAHA");
                if (propertyDetailsModel.IsPHAAuthorityGGHA)
                    PHARegisteredList.push("GGHA");
                if (propertyDetailsModel.IsPHAAuthorityOther && propertyDetailsModel.PHAAuthorityOtherTitle !== undefined)
                    PHARegisteredList.push(propertyDetailsModel.PHAAuthorityOtherTitle);
            }

        let cities = ['Aliso Viejo', 'Anaheim', 'Anaheim Hills', 'Brea', 'Buena Park', 'Costa Mesa', 'Cypress',
        'Dana Point', 'Foothill Ranch', 'Fountain Valley', 'Fullerton', 'Garden Grove', 'Huntington Beach',
        'Irvine', 'La Habra', 'La Palma', 'Ladera Ranch', 'Laguna Beach', 'Laguna Hills', 'Laguna Niguel',
        'Laguna Woods', 'Lake Forest', 'Los Alamitos', 'Midway City', 'Mission Viejo', 'Newport Beach',
        'Newport Coast', 'Orange', 'Placentia', 'Rancho Santa Margarita', 'San Clemente', 'San Juan Capistrano',
        'Santa Ana', 'Seal Beach', 'Stanton', 'Tustin', 'Villa Park', 'Westminster', 'Yorba Linda', 'Capistrano Beach',
        'Corona Del Mar', 'Coto de Caza', 'Diamond Bar', 'Silverado Canyon', 'Trabuco Canyon'];
        let isCityMatch = false;

        if (cities.indexOf(propertyDetailsModel.City) !== -1) {
            //console.log("Yes, the value exists!");
            isCityMatch = true;
        } else {
            //console.log("No, the value is absent.");
            isCityMatch = false;
        }

        await this.loadFileToS3(
            [
                {
                    "SalesforceCommunityId": propertyDetailsModel.SalesforceCommunityId, "Account Type": "Community", "PropertyName": propertyDetailsModel.PropertyName,
                    "City": isCityMatch ? propertyDetailsModel.City : "Other", "Othercity": isCityMatch ? "" : propertyDetailsModel.City,
                    "Phone": propertyDetailsModel.Phone, "PHARegisteredList": PHARegisteredList.join(';'),
                    "Address": propertyDetailsModel.Address, "Zipcode": propertyDetailsModel.Zipcode,
                    "Status": propertyDetailsModel.Status, "pyei_Name": propertyDetailsModel.pyei_Name, "pyei_Address": propertyDetailsModel.pyei_Address,
                    "pyei_City":propertyDetailsModel.pyei_City,"pyei_State":propertyDetailsModel.pyei_State,"pyei_Zip":propertyDetailsModel.pyei_Zip,
                    "W9DocumentStatus": (propertyDetailsModel.W9DocumentName != undefined && propertyDetailsModel.W9DocumentName !== '' && propertyDetailsModel.W9DocumentName !== null) ? "true" : "false",
                    //"W9DocumentStatus": propertyDetailsModel.W9DocumentStatus,
                    "ManagementCompany": propertyDetailsModel.ManagementCompany !== null ? propertyDetailsModel.ManagementCompany.title : "",
                    "AWS_PropertyID": propertyDetailsModel.PropertyID, "AWS_UpdatedBy": "AWS",
                    "SalesforceCompanyId": salesforceManCompId ? salesforceManCompId : (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined && this.state.selectedManagementCompany.Id.split('-').length === 3 ? this.state.selectedManagementCompany.Id.split('-')[2] : null),
                    "Payment_Standards__c": "Basic",
                    "Suite": propertyDetailsModel.Unit,
                    "pyei_Suite":propertyDetailsModel.pyei_Unit,
                    "RecordTypeId": process.env.REACT_APP_CommunityRecordTypeId //"0124x000000yltHAAQ"
                },
            ]
        );
        } else {
            console.log("Not found Salesforce Community id.")
        }
        
        if(isSuccess){
            this.props.history.goBack();
        }
        this.setState({ isLoading: false });
    }

    loadFileToS3 = async (items) => {
        // Convert JSON to CSV & Display CSV
        var s = this.convertToCSV(items);
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            var issueDateTime = new Date(
                new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
            );
            var todaysDate = [
                issueDateTime.getFullYear(),
                ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                ("0" + issueDateTime.getDate()).slice(-2),
            ].join("-");

            var todaysTime = [
                ("0" + issueDateTime.getHours()).slice(-2),
                ("0" + issueDateTime.getMinutes()).slice(-2),
                ("0" + issueDateTime.getSeconds()).slice(-2),
            ].join(":");
            var fileName = "Property_" + items[0].AWS_PropertyID + "_" + items[0].SalesforceCommunityId +"_" + todaysDate + "_" + todaysTime + ".csv";
            // var result1 = await Storage.put(`Salesforce/Update-Property/${fileName}`,
            //     s,
            //     { contentType: "csv" });
            //console.log(result1);
            await Storage.put(`Salesforce/Update-Property/${fileName}`,
                s,
                { contentType: "csv" });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }

    convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)

        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }
    validateControls() {
        let propertyDetailFields = this.state.propertyDetails;
        let formIsValid = true;

        let errors = {};
        if (!propertyDetailFields["PropertyName"]) {
            formIsValid = false;
            errors["PropertyName"] = "Property Name is required";
        }
        if (!propertyDetailFields["Address"]) {
            formIsValid = false;
            errors["Address"] = "Address is required";
        }
        // if (!propertyDetailFields["pyei_Address"]) {
        //     formIsValid = false;
        //     errors["pyei_Address"] = "Address is required";
        // }
        if (!propertyDetailFields["City"]) {
            formIsValid = false;
            errors["City"] = "City is required";
        }
        if (this.state.po_EmailID === null || this.state.po_EmailID === "") {
            formIsValid = false;
            errors["po_EmailID"] = "Email is required";
            $('#propOwner_EmailID').removeAttr('disabled');
        }
        else if (this.state.po_EmailID !== undefined) {
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern4.test(this.state.po_EmailID)) {
                formIsValid = false;
                errors["po_EmailID"] = "Invalid email";
                $('#propOwner_EmailID').removeAttr('disabled');
            }
        }

        // if (this.state.po_FirstName !== undefined) {
        //     var pattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!pattern.test(this.state.po_FirstName)) {
        //         formIsValid = false;
        //         errors["po_FirstName"] = "Invalid first name";
        //     }
        // }

        if (this.state.po_Phone !== undefined && this.state.po_Phone !== null && this.state.po_Phone !== "") {
            if ((this.state.po_Phone.length < 10) || !this.state.po_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                formIsValid = false;
                errors["po_Phone"] = "Invalid phone";
            }
        }

        if (this.state.po_LastName === null || this.state.po_LastName === "") {
            formIsValid = false;
            errors["po_LastName"] = "Last name is required";
            $('#propOwner_LastName').removeAttr('disabled');
        }
        // else if (this.state.po_LastName !== undefined) {
        //     var pattern2 = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!pattern2.test(this.state.po_LastName)) {
        //         formIsValid = false;
        //         errors["po_LastName"] = "Invalid last name";
        //     }
        // }

        if (this.state.po_Zip !== "" && this.state.po_Zip !== null && this.state.po_Zip !== undefined) {
            if (/^\d+$/.test(this.state.po_Zip) !== true) {
                errors["po_Zip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        if (this.state.po_City !== "" && this.state.po_City !== null && this.state.po_City !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.po_City)) {
                errors["po_City"] = "Invalid City";
                formIsValid = false;
            }
        }
        // if (!this.state.selectedPortalUserCentralContactId || this.state.selectedPortalUserCentralContactId === '' || this.state.selectedPortalUserCentralContactId === "Please Select") {
        //     formIsValid = false;
        //     errors["selectedPortalUserCentralContactId"] = "Portal user is required";
        // }
        // if (!propertyDetailFields["pyei_City"]) {
        //     formIsValid = false;
        //     errors["pyei_City"] = "City is required";
        // }
        // if (!propertyDetailFields["State"]) {
        //     formIsValid = false;
        //     errors["State"] = "State is required";
        // }
        // if (!propertyDetailFields["pyei_State"]) {
        //     formIsValid = false;
        //     errors["pyei_State"] = "State is required";
        // }
        // if (!propertyDetailFields["Zipcode"]) {
        //     formIsValid = false;
        //     errors["Zipcode"] = "Zipcode is required";
        // }
        // if (!propertyDetailFields["pyei_Zip"]) {
        //     formIsValid = false;
        //     errors["pyei_Zip"] = "Zipcode is required";
        // }
        // if (!propertyDetailFields["Email"]) {
        //     formIsValid = false;
        //     errors["Email"] = "Email is required";
        // }
        // if (!propertyDetailFields["Phone"]) {
        //     formIsValid = false;
        //     errors["Phone"] = "Phone is required";
        // }

        // if (!propertyDetailFields["pyei_Name"]) {
        //     formIsValid = false;
        //     errors["pyei_Name"] = "Payee name is required";
        // }
        // if (!propertyDetailFields["RemitToAddress"]) {
        //     formIsValid = false;
        //     errors["RemitToAddress"] = "Remit to Address is required";
        // }
        // if (!propertyDetailFields["AttentionOf"]) {
        //     formIsValid = false;
        //     errors["AttentionOf"] = "Attention of is required";
        // }

        // if (this.state.w9Uploaded === false) {  // make the w9 required in any case.
        //     formIsValid = false;
        //     errors["w9NotUploaded"] = "W9 document is required";
        // }

        if (propertyDetailFields["City"] !== "" && propertyDetailFields["City"] !== null && propertyDetailFields["City"] !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(propertyDetailFields["City"])) {
                errors["City"] = "Invalid City";
                formIsValid = false;
            }
        }
        if (propertyDetailFields["pyei_City"] !== "" && propertyDetailFields["pyei_City"] !== null && propertyDetailFields["pyei_City"] !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(propertyDetailFields["pyei_City"])) {
                errors["pyei_City"] = "Invalid City";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Zipcode"] !== "" && propertyDetailFields["Zipcode"] !== null && propertyDetailFields["Zipcode"] !== undefined) {
            if (/^\d+$/.test(propertyDetailFields["Zipcode"]) !== true) {
                errors["Zipcode"] = "Invalid Zip";
                formIsValid = false;
            }
        }
        if (propertyDetailFields["pyei_Zip"] !== "" && propertyDetailFields["pyei_Zip"] !== null && propertyDetailFields["pyei_Zip"] !== undefined) {
            if (/^\d+$/.test(propertyDetailFields["pyei_Zip"]) !== true) {
                errors["pyei_Zip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Phone"] !== "" && propertyDetailFields["Phone"] !== null && propertyDetailFields["Phone"] !== undefined) {
            if (!propertyDetailFields["Phone"].match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["Phone"] = "Invalid Phone";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["PhoneExtension"] !== "" && propertyDetailFields["PhoneExtension"] !== null && propertyDetailFields["PhoneExtension"] !== undefined) {
            if (/^[\d\(\)\+]+$/.test(propertyDetailFields["PhoneExtension"]) !== true) {
                errors["PhoneExtension"] = "Invalid Extension";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Email"] !== "" && propertyDetailFields["Email"] !== null && propertyDetailFields["Email"] !== undefined) {
            var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!secondaryEmailPattern.test(propertyDetailFields["Email"])) {
                errors["Email"] = "Invalid Email";
                formIsValid = false;
            }
        }

        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    OnFormFieldChanged(field, e) {
        const currentState = this.state.propertyDetails;
        const { value } = e.target;
        currentState[field] = value;
        this.setState({ propertyDetails: currentState });
    }
    // zzz
    OnFormPo_FirstNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_FirstName: value });
    }
    OnFormPo_LastNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_LastName: value, po_isPOEdited: true });
    }
    OnFormPo_AddressFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Address: value });
    }
    OnFormPo_CityFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_City: value });
    }
    OnFormPo_StateFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_State: value });
    }
    OnFormPo_ZipFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Zip: value });
    }
    OnFormPo_EmailIDFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_EmailID: value, po_isPOEdited: true  });
    }
    OnFormPo_PhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Phone: value });
    }
    OnFormMa_FirstNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_FirstName: value });
    }
    OnFormMa_LastNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_LastName: value });
    }
    OnFormMa_EmailIDFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_EmailID: value });
    }
    OnFormMa_PhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_Phone: value });
    }
    OnFormMa_PhoneExtensionFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_PhoneExtension: value });
    }
    OnFormMa_AdditionalPhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_AdditionalPhone: value });
    }

    getCentralContacts = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getcentralcontactlist';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    getPeoplePropContactById = async (Id) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpeoplepropcontactbyid';
        let myInit = { // OPTIONAL
            body: Id.toString(),
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    clearPropertyOwner = async (Id) => {
        this.setState({
            po_FirstName: '',
            po_LastName: '',
            po_Address: '',
            po_City: '',
            po_State: '',
            po_Zip: '',
            po_EmailID: '',
            po_Phone: '',
            po_AirtablePeoplePropId: '',
            po_SalesforceContactId: '',
            po_ManagementCompanyId: '',
            po_ManagementCompanyName: '',
            po_AirtableCompanyId: '',
            po_SalesforceCompanyId: '',
            po_isPOEdited: false,
        });
    }

    clearPropertyManager = async (Id) => {
        this.setState({
            ma_FirstName: "",
            ma_LastName: "",
            ma_EmailID: "",
            ma_Phone: "",
            ma_PhoneExtension: "",
            ma_AdditionalPhone: "",
            ma_AirtablePeoplePropId: "",
            ma_SalesforceContactId: "",
            managerValidationErrors: {},
        });
    }

    updatePropertyOwner = async (Id) => {
        this.setState({ isLoading: true });
        try {
            let res = await this.getPeoplePropContactById(Id.split('_')[1]);
            this.setState({ isLoading: false });
            this.setState({
                po_FirstName: res['FirstName'],
                po_LastName: res['LastName'],
                po_Address: res['Address'],
                po_City: res['City'],
                po_State: res['State'],
                po_Zip: res['Zip'],
                po_EmailID: res['EmailID'],
                po_Phone: res['Phone'],
                po_AirtablePeoplePropId: res['AirtablePeoplePropId'],
                po_SalesforceContactId: res['SalesforceContactId'],
                po_ManagementCompanyId: res['ManagementCompanyId'],
                po_ManagementCompanyName: res['ManagementCompanyName'],
                po_AirtableCompanyId: res['AirtableCompanyId'],
                po_SalesforceCompanyId: res['SalesforceCompanyId'],
                po_isPOEdited: false,
            });
            this.state.propertyDetails.PropertyOwnerSalesforceId = res['SalesforceContactId'];
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if ((this.state.po_EmailID === null || this.state.po_EmailID === "") || (this.state.po_EmailID !== undefined && (!pattern4.test(this.state.po_EmailID)))) {
                $('#propOwner_EmailID').removeAttr('disabled');
            }
            if (this.state.po_LastName === null || this.state.po_LastName === "") {
                $('#propOwner_LastName').removeAttr('disabled');
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true });
            this.setState({ snackbarMessage: 'Something went wrong.' });
            this.setState({ snackbarMessageClass: 'Mui-filledError' });
            console.log(e)
        }
    }

    validateManager() {

        let formIsValid = true;

        let errors = {};
        if (this.state.ma_EmailID === null || this.state.ma_EmailID === "") {
            formIsValid = false;
            errors["ma_EmailID"] = "Email is required";
            this.setState({ ma_EmailIDError: true });
        }
        else if (this.state.ma_EmailID !== undefined) {
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern4.test(this.state.ma_EmailID)) {
                errors["ma_EmailID"] = "Invalid email";
                this.setState({ ma_EmailIDError: true });
                formIsValid = false;
            }
        }
        
        if (this.state.ma_LastName === null || this.state.ma_LastName === "") {
            formIsValid = false;
            errors["ma_LastName"] = "Last name is required";
            this.setState({ ma_LastNameError: true });
        }

        if (this.state.ma_Phone !== undefined && this.state.ma_Phone !== null && this.state.ma_Phone !== "") {
            if ((this.state.ma_Phone.length < 10) || !this.state.ma_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["ma_Phone"] = "Invalid phone";
                this.setState({ ma_PhoneError: true });
                formIsValid = false;
            }
        }

        if (this.state.ma_AdditionalPhone !== undefined && this.state.ma_AdditionalPhone !== null && this.state.ma_AdditionalPhone !== "") {
            if ((this.state.ma_AdditionalPhone.length < 10) || !this.state.ma_AdditionalPhone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["ma_AdditionalPhone"] = "Invalid phone";
                this.setState({ ma_AdditionalPhoneError: true });
                formIsValid = false;
            }
        }

        if (this.state.ma_PhoneExtension !== "" && this.state.ma_PhoneExtension !== null && this.state.ma_PhoneExtension !== undefined) {
            if (/^[\d\(\)\+]+$/.test(this.state.ma_PhoneExtension) !== true) {
                errors["ma_PhoneExtension"] = "Invalid Extension";
                this.setState({ ma_PhoneExtensionError: true });
                formIsValid = false;
            }
        }
        this.setState({ managerValidationErrors: errors });
        return formIsValid;
    }

    addManager = async (e, isNew) => {
        e.preventDefault();
        if (isNew && !this.validateManager()) {
            this.setState({ isLoading: false });
            return;
        }
        if (this.state.selectedPropertyManager && this.state.selectedPropertyManager.Id != undefined && this.state.selectedPropertyManager.Id != "0") {
            this.setState({ isLoading: true });
            let managerId = this.state.selectedPropertyManager.Id.split('_')[1];
            let lst = this.state.managerList.filter(data => !data.isDeleted && data.ManagerId == managerId);
            if (lst && lst.length > 0) {
                this.setState({ isLoading: false, openSnackbar: true, snackbarMessage: 'Manager already exist.', snackbarMessageClass: 'Mui-filledError' });
                return;
            }
            let isFoundPreviouslyDeletedRecord = false;
            this.state.managerList.map((val, i) => {
                if (val.ManagerId == this.state.selectedPropertyManager.Id.split('_')[1]) {
                    val.isDeleted = false;
                    isFoundPreviouslyDeletedRecord = true;
                }
            });
            if (isFoundPreviouslyDeletedRecord) {
                let arrindex = this.state.managerList.findIndex(x => x.ManagerId == this.state.selectedPropertyManager.Id.split('_')[1]);
            } else {
                try {
                    console.log('8');
                    let res = await this.getPeoplePropContactById(this.state.selectedPropertyManager.Id.split('_')[1]);
                    this.setState({ isLoading: false });
                    //console.log('9');
                    this.setState(prevState => ({
                        managerList: [
                            {
                                OperationType: 'NewExisting',
                                ManagerIndex: this.state.managerList.length + 1,
                                ManagerId: res['CentralContactId'],
                                FirstName: res['FirstName'],
                                LastName: res['LastName'],
                                EmailID: res['EmailID'],
                                Phone: res['Phone'],
                                PhoneExtension: res['PhoneExtension'],
                                AdditionalPhone: res['AdditionalPhone'],
                                isDeleted: false,
                                AirtablePeoplePropId: res['AirtablePeoplePropId'],
                                SalesforceContactId: res['SalesforceContactId'],
                                ManagementCompanyId: res['ManagementCompanyId'],
                                ManagementCompanyName: res['ManagementCompanyName'],
                                AirtableCompanyId: res['AirtableCompanyId'],
                                SalesforceCompanyId: res['SalesforceCompanyId'],
                            }, ...prevState.managerList
                        ]
                    }));
    
                }
                catch (e) {
                    this.setState({ isLoading: false });
                    this.setState({ openSnackbar: true });
                    this.setState({ snackbarMessage: 'Something went wrong.' });
                    this.setState({ snackbarMessageClass: 'Mui-filledError' });
                    console.log(e)
                }
            }
            this.setState({ isLoading: false });
        }
        else {
            console.log('10');
            this.setState(prevState => ({
                managerList: [
                    {
                        OperationType: 'New',
                        ManagerIndex: this.state.managerList.length + 1,
                        ManagerId: 0,
                        FirstName: this.state.ma_FirstName,
                        LastName: this.state.ma_LastName,
                        EmailID: this.state.ma_EmailID,
                        Phone: this.state.ma_Phone,
                        PhoneExtension: this.state.ma_PhoneExtension,
                        AdditionalPhone: this.state.ma_AdditionalPhone,
                        isDeleted: false,
                        AirtablePeoplePropId: this.state.ma_AirtablePeoplePropId,
                        SalesforceContactId: this.state.ma_SalesforceContactId,
                        ManagementCompanyId: this.state.ma_ManagementCompanyId,
                        ManagementCompanyName: this.state.ma_ManagementCompanyName,
                        AirtableCompanyId: this.state.ma_AirtableCompanyId,
                        SalesforceCompanyId: this.state.ma_SalesforceCompanyId,
                    }, ...prevState.managerList
                ]
            }));
            this.setState({ isLoading: false });
        }
        console.log('this.state.managerList');
        console.log(this.state.managerList);
        this.setState({ selectedPropertyManager: null, isAddManager: false, isMangerSelected: false });
        this.setState({ isLoading: false });
    }

    removeManager = async (index) => {
        // for (var i = 0; this.state.managerList.length > i; i++) {
        //     var updatedManager = this.state.managerList[i];
        //     if (updatedManager.ManagerId > 0) {
        //         let managers = this.state.managerList.filter(d=>d.ManagerId == updatedManager.ManagerId);
        //         if (managers.length > 1) {

        //         }
        //         this.state.managerList.map((val, i) => {
        //             if (val.ManagerIndex == index) {
        //                 val.isDeleted = true;
        //             }
        //         });
        //     }

        //     updatedManager.Phone = (updatedManager.Phone === null || updatedManager.Phone === undefined) ? '' : updatedManager.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
        //     updatedManager.AdditionalPhone = (updatedManager.AdditionalPhone === null || updatedManager.AdditionalPhone === undefined) ? '' : updatedManager.AdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

        //     managersNRepresentatives_Copy.push(updatedManager);
        // }

        // let manager =  this.state.managerList.filter(d=>d.ManagerIndex == index);

        // if (manager.ManagerId > 0) {
        //     let count = this.state.managerList.filter(d=>d.ManagerId == manager.ManagerId && d.isDeleted).length;
        //     if(count == 0){
        //         this.state.managerList.map((val, i) => {
        //             if (val.ManagerIndex == index) {
        //                 val.isDeleted = true;
        //             }
        //         });
        //     }
            
        // } else {
        //     this.setState({managerList: this.state.managerList.filter(data => data.ManagerIndex != index)});
        // }

        this.state.managerList.map((val, i) => {
            if (val.ManagerIndex == index) {
                val.isDeleted = true;
            }
        });
        this.setState({ managerList: this.state.managerList });
    }

    OnIsPHARegisteredRadioButtonChanged(event) {
        var currentState = this.state.propertyDetails;
        var { name, value } = event.target;
        if (value === 'false') {
            currentState[name] = false;

            // reset pha authority flags and the other title
            currentState['IsPHAAuthorityAHA'] = false;
            currentState['IsPHAAuthorityOCHA'] = false;
            currentState['IsPHAAuthoritySAHA'] = false;
            currentState['IsPHAAuthorityOther'] = false;
            currentState['PHAAuthorityOtherTitle'] = "";
            currentState['PHAStatus'] = "Not Registered";
        }
        else {
            currentState[name] = true;
        }

        this.setState({ propertyDetails: currentState });
    }

    OnOtherPHANameChanged = async (event) => {
        var currentState = this.state.propertyDetails;
        var { name, value } = event.target;
        currentState['PHAAuthorityOtherTitle'] = value;
        this.setState({ propertyDetails: currentState });
    }

    OnCheckBoxChanged(event) {
        // var { propertyUnit } = { ...this.state };
        var currentState = this.state.propertyDetails;
        var { name, checked } = event.target;
        currentState[name] = checked;
        if (name === 'IsPHAAuthorityOther' && checked === 'false') {
            // reset other title
            currentState['PHAAuthorityOtherTitle'] = "";
        }
        this.setState({ propertyDetails: currentState });
    }

    OnLeaseDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'file size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            // var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/lease_document/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/lease_document/${file.name}`,    
                file,
                { contentType: file.type });
            if (result) {
                var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/lease_document/${file.name}`, { download: true });
                await this.updatePropertyDocument('LeaseDocumentStatus', file.name, result.key, response.VersionId,file.type);
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    OnW9DocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (file === undefined || file === null) {
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            return;
        }

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'file size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            return;
        }

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
           // var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/w9_document/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/w9_document/${file.name}`,
                file,{ contentType: file.type})
                if (result) {
                    var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/w9_document/${file.name}`, { download: true });
                    console.log("response is => " + response);
                    console.log(JSON.stringify(response))
                    this.setState({ w9Uploaded: true });
                    await this.updatePropertyDocument('W9DocumentStatus', file.name, result.key,response.VersionId, file.type);
                }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    OnRuleDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'file size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
          //var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/rule_document/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/rule_document/${file.name}`,  
            file,
                { contentType: file.type });
            if (result) {
                var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/rule_document/${file.name}`, { download: true });
                await this.updatePropertyDocument('RuleDocumentStatus', file.name, result.key,response.VersionId, file.type);
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    OnSupplementDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'file size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            // var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/supplement_document/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/supplement_document/${file.name}`,
                file,
                { contentType: file.type });
            if (result) {
                var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/supplement_document/${file.name}`, { download: true });
                await this.updatePropertyDocument('SupplementDocumentStatus', file.name, result.key,response.VersionId, file.type);
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    OnLeadBasedPaintDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'file size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            // var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/leadbasedpaint_document/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/leadbasedpaint_document/${file.name}`,
                file,
                { contentType: file.type });
            if (result) {
                var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/leadbasedpaint_document/${file.name}`, { download: true });
                await this.updatePropertyDocument('LeadBasedPaintDocumentStatus', file.name, result.key, response.VersionId, file.type);
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    updatePropertyDocument = async (type, name, paths, versionId, contentType) => {
        let testId = this.props.history.location.state?.ID;
        var user = await this.getCurrentUser();
        var model = {
            propertyID: +testId.toString(),
            documerntType: type,
            status: false,
            documentName: name,
            documentPath: paths,
            ModifiedBy: user.attributes.email,
            versionId: versionId,
            contentType: contentType
        }
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updatepropertydocument';
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        let result = await API.post(apiName, path, myInit);
        //console.log(result);
        if (result.status === true) {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            if (type === 'LeaseDocumentStatus') {
                this.state.propertyDetails.LeaseDocumentName = name
                this.state.propertyDetails.LeaseDocumentStatus = false;
                this.state.propertyDetails.LeaseDocumentState = 'Pending';
                this.state.propertyDetails.LeaseDocumentPath = paths;

                var file1 = await Storage.get(paths);
                this.setState({ leaseDocument: file1 });
            } else if (type === 'W9DocumentStatus') {
                this.state.propertyDetails.W9DocumentName = name;
                this.state.propertyDetails.W9DocumentStatus = false;
                this.state.propertyDetails.W9DocumentState = 'Pending';
                this.state.propertyDetails.W9DocumentPath = paths;
                var file1 = await Storage.get(paths);
                this.setState({ w9document: file1 });
            }
            else if (type === 'SupplementDocumentStatus') {
                this.state.propertyDetails.SupplementDocumentName = name;
                this.state.propertyDetails.SupplementDocumentStatus = false;
                this.state.propertyDetails.SupplementDocumentState = 'Pending';
                this.state.propertyDetails.SupplementDocumentPath = paths;
                var file1 = await Storage.get(paths);
                this.setState({ supplementDocument: file1 });
            }
            else if (type === 'LeadBasedPaintDocumentStatus') {
                this.state.propertyDetails.LeadBasedPaintDocumentName = name;
                this.state.propertyDetails.LeadBasedPaintDocumentStatus = false;
                this.state.propertyDetails.LeadBasedPaintDocumentState = 'Pending';
                this.state.propertyDetails.LeadBasedPaintDocumentPath = paths;
                var file1 = await Storage.get(paths);
                this.setState({ leadbasedpaintDocument: file1 });
            }
            else {
                this.state.propertyDetails.RuleDocumentName = name;
                this.state.propertyDetails.RuleDocumentStatus = false;
                this.state.propertyDetails.RuleDocumentState = 'Pending';
                this.state.propertyDetails.RuleDocumentPath = paths;
                var file1 = await Storage.get(paths);
                this.setState({ ruleDocument: file1 });
            }
        }
    }

    OnImageUploadChanged = async (event) => {
        var file = event.target.files[0];
        if (!file.type.match('image/jp.*')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg Image.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Image size should be less then 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            this.setState({
                selectedFile: [reader.result]
            });
        }.bind(this);
        //console.log(url); // Would see a path?

        this.setState({
            selectedFile: event.target.files[0],
            imageUploaded: 1
        });

        this.setState({ isLoading: true });
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            // var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Images/${file.name}`,
            var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Images/${file.name}`,
                file,
                { contentType: file.type });
            if (result) {
                //console.log(result);
                // update path in db
                await this.updatePropertyImage(file.name, result.key);
            }
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });
    }

    getManagementCompanies = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getmanagementcompanylist';
        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    updatePropertyImage = async (name, paths) => {
        let testId = this.props.history.location.state?.ID;
        var model = {
            propertyID: +testId.toString(),
            imageName: name,
            imagePath: paths
        }

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;

            let path = '/updatepropertyimage';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            let result = await API.post(apiName, path, myInit);
            //console.log(result);
            if (result.status === true) {
                Storage.configure({
                    bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                    region: process.env.REACT_APP_Region,
                    level: 'public',
                });
                this.state.propertyDetails.ImageName = name;
                this.state.propertyDetails.ImagePath = paths;
                var file1 = await Storage.get(paths);
                this.setState({ propertyImage: file1 });
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    updatePropertyDocumentStatus = async (type, status) => {
        this.setState({ isLoading: true });
        let testId = this.props.history.location.state?.ID;
        var model = {
            propertyID: +testId.toString(),
            documerntType: type,
            status: status
        }
        var docStatus = status ? 'Approved' : 'Not Approved';

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updatepropertydocumentstatus';
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        var result = await API.post(apiName, path, myInit);
        //console.log(result);

        if (result.status === true) {
            if (type === 'LeaseDocumentStatus') {
                this.state.propertyDetails.LeaseDocumentStatus = status;
                this.state.propertyDetails.LeaseDocumentState = docStatus;
            } else if (type === 'RuleDocumentStatus') {
                this.state.propertyDetails.RuleDocumentStatus = status;
                this.state.propertyDetails.RuleDocumentState = docStatus;
            }
            else if (type === 'SupplementDocumentStatus') {
                this.state.propertyDetails.SupplementDocumentStatus = status;
                this.state.propertyDetails.SupplementDocumentState = docStatus;
            }
            else if (type === 'LeadBasedPaintDocumentStatus') {
                this.state.propertyDetails.LeadBasedPaintDocumentStatus = status;
                this.state.propertyDetails.LeadBasedPaintDocumentState = docStatus;
            }
            else {
                this.state.propertyDetails.W9DocumentStatus = status;
                this.state.propertyDetails.W9DocumentState = docStatus;
            }
        }

        this.setState({ isLoading: false });
    }

    closeSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    renderUnits = () => {
        let rowedUnits = [];
        let children = [];

        let filterredUnits = this.state.units;
        //console.log(filterredUnits);

        // Maintain the below sorting order for the units
        let sortedUnits = filterredUnits.filter(u => u.Status === "Submitted" || u.Status === "Inbound");
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Under Rent Review" || u.Status === "Rent Review"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Rent Discussion" || u.Status === "Negotiation"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Holding Fee Agreement Needed" || u.Status === "HFA Sent"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Active"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Not Ready" ||  u.Status === "Not Yet Ready"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Matched"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Leased" || u.Status === "Leased - Lite"));
        sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Inactive" || u.Status === "Outside network" || u.Status ===  "Not Needed" || u.Status ===  "Ineligible"  || u.Status ===  "Released"));
        // sortedUnits = sortedUnits.concat(filterredUnits.filter(u => u.Status === "Inbound"));

        if (sortedUnits.length === 0) {
            rowedUnits.push(<div className="row mt-2 mb-2"><div class="col-lg-12 mb-3 mb-lg-0">No unit submitted.</div></div>);
        }
        else {
            // Outer loop to create parent
            for (let i = 0; i < sortedUnits.length; i++) {
                var unitStatus = (<span class="under-rent-review-bg">Under Rent Review</span>);

                if (sortedUnits[i]["Status"].toLowerCase() === 'under rent review' || sortedUnits[i]["Status"].toLowerCase() === 'rent review') {
                    unitStatus = (<span class="under-rent-review-bg">Under Rent Review</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'submitted' || sortedUnits[i]["Status"].toLowerCase() === 'inbound') {
                    unitStatus = (<span>Submitted</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'leased' || sortedUnits[i]["Status"].toLowerCase() === 'leased - lite') {
                    unitStatus = (<span class="leased-bg">Leased</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'inactive' || sortedUnits[i]["Status"].toLowerCase() === 'outside network' 
                || sortedUnits[i]["Status"].toLowerCase() === 'not needed' || sortedUnits[i]["Status"].toLowerCase() === 'ineligible' || sortedUnits[i]["Status"].toLowerCase() === 'released') {
                    unitStatus = (<span class="inactive-bg">Inactive</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'rent discussion' || sortedUnits[i]["Status"].toLowerCase() === 'negotiation') {
                    unitStatus = (<span class="rent-discussion-bg">Rent Discussion</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'holding fee agreement needed' || sortedUnits[i]["Status"].toLowerCase() === 'hfa sent') {
                    unitStatus = (<span class="holding-fee-agreement-needed-bg">Holding Fee Agreement Needed</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'active') {
                    unitStatus = (<span class="active-bg">Active</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'matched') {
                    unitStatus = (<span class="matched-bg">Matched</span>);
                }
                else if (sortedUnits[i]["Status"].toLowerCase() === 'not ready' || sortedUnits[i]["Status"].toLowerCase() === 'not yet ready') {
                    unitStatus = (<span class="not-ready-bg">Not Ready</span>);
                }

                var dateAvailableFrom_Formatted = sortedUnits[i]["AvailableFrom"];
                if (dateAvailableFrom_Formatted !== "" && dateAvailableFrom_Formatted !== null) {
                    var d = new Date(dateAvailableFrom_Formatted);
                    dateAvailableFrom_Formatted = [
                        ('0' + (d.getMonth() + 1)).slice(-2),
                        ('0' + d.getDate()).slice(-2),
                        d.getFullYear()
                    ].join('/');
                }

                children.push(
                    <div className="col-lg-4 mb-3 mb-lg-0" style={{ cursor: 'pointer' }} onClick={() => {
                        this.setState({ isLoading: true });
                        // window.location.assign(`/welcomehomeoc/unit/view/${sortedUnits[i]["PropertyUnitID"]}`);
                        this.props.history.push({
                            pathname: '/welcomehomeoc/unit/view',
                            state: { ID: sortedUnits[i]["PropertyUnitID"] }
                        })
                        this.setState({ isLoading: false });
                    }}>
                        <div className="property-box border-blue">
                            <div className="property-content ml-0 w-100">
                                <h3 className="profile-status d-inline">Status:{unitStatus}</h3>
                                <div class="form-row mt-3">
                                    <div class="form-group col-md-12">
                                        <label>Property with which this unit is linked to</label>
                                        <p class="form-control-static">{this.state.propertyDetails.PropertyName}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Address and Unit Number</label>
                                        <p class="form-control-static">{`${sortedUnits[i]["Address"]} ${sortedUnits[i]["Unit"]}`}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Available From</label>
                                        <p class="form-control-static">{dateAvailableFrom_Formatted}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Approved Rent</label>
                                        <p class="form-control-static">{sortedUnits[i]["ApprovedRent"]}</p>
                                    </div>
                                    <div class="form-group col-md-6">
                                        <label>Requested Rent</label>
                                        <p class="form-control-static">{sortedUnits[i]["RequestedRent"]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );

                if (children.length === 3 || (i === sortedUnits.length - 1 && children.length > 0)) {
                    rowedUnits.push(<div className="row mt-2 mb-2">{children}</div>);
                    children = [];
                }
            }
        }

        return rowedUnits;
    }

    openPHARegistrationWizard = async () => {
        this.setState({ isLoading: true });
        // window.open(`https://master.d31bbqam8i886n.amplifyapp.com/pharegistration/${this.state.propertyDetails.PropertyID}`, "_blank");
        // window.location.assign(`/welcomehomeoc/pharegistration/${this.state.propertyDetails.PropertyID}`);
        this.props.history.push({
            pathname: '/welcomehomeoc/pharegistration',
            state: { ID: this.state.propertyDetails.PropertyID }
        })
        this.setState({ isLoading: false });
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="proof_of_ownership_upload_id" /></td>`;
        document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="irs_letter_issuing_tin_ein_upload_id" /></td>`;
        document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="grant_deed_upload_id" /></td>`;
        document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="llc_upload_id" /></td>`;
        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="management_agreement_upload_id" /></td>`;
        document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="hap_contract_upload_id" /></td>`;
        document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="rent_to_owner_form_upload_id" /></td>`;
        document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="tax_id_verification_upload_id" /></td>`;
        document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_tax_bill_upload_id" /></td>`;
        document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_management_agreement_upload_id" /></td>`;
        document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="recorded_grant_deed_upload_id" /></td>`;
        document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption<td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="remove_home_owner_exemption_upload_id" /></td>`;
        document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="statements_from_property_coowners_upload_id" /></td>`;
        document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="original_voided_check_upload_id" /></td>`;
        document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="final_escrow_statement_upload_id" /></td>`;

        //get file names for s3 keys
        try {
            //var key = `Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Documents/PHA/`;
            var key = `Properties/${this.state.propertyDetails.PropertyID}/Documents/PHA/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("proof_of_ownership/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ proof_of_ownership: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_ownership}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error proof_of_ownership - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="proof_of_ownership_upload_id" /></td>`;
                    return 1;
                }
            }
            else if (files[i].key.includes("irs_letter_issuing_tin_ein/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ irs_letter_issuing_tin_ein: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.irs_letter_issuing_tin_ein}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error irs_letter_issuing_tin_ein - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="irs_letter_issuing_tin_ein_upload_id" /></td>`;
                    return 2;
                }
            }
            else if (files[i].key.includes("/grant_deed/")) {
                try {
                    var file31 = await Storage.get(files[i].key);
                    this.setState({ grant_deed: file31 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.grant_deed}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error grant_deed - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="grant_deed_upload_id" /></td>`;
                    return 3;
                }
            }
            else if (files[i].key.includes("llc/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ llc: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.llc}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error llc - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="llc_upload_id" /></td>`;
                    return 4;
                }
            }
            else if (files[i].key.includes("/management_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ management_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.management_agreement}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error management_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="management_agreement_upload_id" /></td>`;
                    return 5;
                }
            }
            else if (files[i].key.includes("hap_contract/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ hap_contract: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.hap_contract}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error hap_contract - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="hap_contract_upload_id" /></td>`;
                    return 6;
                }
            }
            else if (files[i].key.includes("rent_to_owner_form/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ rent_to_owner_form: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rent_to_owner_form}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error rent_to_owner_form - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="rent_to_owner_form_upload_id" /></td>`;
                    return 7;
                }
            }
            else if (files[i].key.includes("tax_id_verification/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tax_id_verification: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tax_id_verification}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error tax_id_verification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="tax_id_verification_upload_id" /></td>`;
                    return 8;
                }
            }
            else if (files[i].key.includes("property_tax_bill/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ property_tax_bill: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.property_tax_bill}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error property_tax_bill - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_tax_bill_upload_id" /></td>`;
                    return 9;
                }
            }
            else if (files[i].key.includes("property_management_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ property_management_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement </td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.property_management_agreement}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error property_management_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_management_agreement_upload_id" /></td>`;
                    return 10;
                }
            }
            else if (files[i].key.includes("recorded_grant_deed/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ recorded_grant_deed: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.recorded_grant_deed}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error recorded_grant_deed - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="recorded_grant_deed_upload_id" /></td>`;
                    return 11;
                }
            }
            else if (files[i].key.includes("remove_home_owner_exemption/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ remove_home_owner_exemption: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption <td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.remove_home_owner_exemption}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error remove_home_owner_exemption - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption<td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="remove_home_owner_exemption_upload_id" /></td>`;
                    return 12;
                }
            }
            else if (files[i].key.includes("statements_from_property_coowners/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ statements_from_property_coowners: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.statements_from_property_coowners}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error statements_from_property_coowners - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="statements_from_property_coowners_upload_id" /></td>`;
                    return 13;
                }
            }
            else if (files[i].key.includes("original_voided_check/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ original_voided_check: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.original_voided_check}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error original_voided_check - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="original_voided_check_upload_id" /></td>`;
                    return 14;
                }
            }
            else if (files[i].key.includes("final_escrow_statement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ final_escrow_statement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.final_escrow_statement}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error final_escrow_statement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="final_escrow_statement_upload_id" /></td>`;
                    return 15;
                }
            }

            //get signed pha documents
            else if (files[i].key.includes("signed_aha_document/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ signed_aha_document: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('signed_aha_document_id').innerHTML = `<td>Signed Anaheim Housing Authority (AHA) Document <div id="signed_aha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.signed_aha_document}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error signed_aha_document - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('signed_aha_document_id').innerHTML = `<td>Signed Anaheim Housing Authority (AHA) Document <div id="signed_aha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_aha_document_upload_id" /></td>`;
                    return 16;
                }
            }
            else if (files[i].key.includes("signed_ocha_document/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ signed_ocha_document: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('signed_ocha_document_id').innerHTML = `<td>Signed Orange County Housing Authority (OCHA) Document <div id="signed_ocha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.signed_ocha_document}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error signed_ocha_document - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('signed_ocha_document_id').innerHTML = `<td>Signed Orange County Housing Authority (OCHA) Document <div id="signed_ocha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_ocha_document_upload_id" /></td>`;
                    return 17;
                }
            }
            else if (files[i].key.includes("signed_saha_document/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ signed_saha_document: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('signed_saha_document_id').innerHTML = `<td>Signed Santa Ana Housing Authority (SAHA) Document <div id="signed_saha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.signed_saha_document}><i class="fas fa-eye mr-3"></i></a></td>`;
                }
                catch (err) {
                    console.log("Error signed_saha_document - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('signed_saha_document_id').innerHTML = `<td>Signed Santa Ana Housing Authority (SAHA) Document <div id="signed_saha_document_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_saha_document_upload_id" /></td>`;
                    return 18;
                }
            }
        }

        this.setState({ isLoading: false });

        return 0;
    }

    OnAdminPHADocumentRemarksChanged(field, e) {
        const currentState = this.state.propertyDetails;
        const { value } = e.target;
        currentState[field] = value;
        this.setState({ propertyDetails: currentState });
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
        }
    }

    render() {
        if (this.showHTML === true) {
            return (
                <div className="property-detail-main">
                    <div className="data-section">
                        <div className="" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                                <form name="detailsform" className="detailsform" onSubmit={this.editProperty.bind(this)}>
                                    <div className="row mb-4">
                                        <div className="col-md-9">
                                            <div className="h-100 box-gray">
                                                <h2 class="profile-title"><i class="fas fa-home mr-2"></i>Property Details</h2>
                                                <div className="box-details">
                                                    <div class="form-row">
                                                        <div class="form-group col-sm-3">
                                                            <label>Property Name<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "PropertyName")} value={this.state.propertyDetails.PropertyName}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["PropertyName"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-4">
                                                            <label>Physical Property Address<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "Address")} value={this.state.propertyDetails.Address}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Address"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <label>Suite<i class=""></i></label>
                                                            <input className="form-control" type="text" maxlength="40" onChange={this.OnFormFieldChanged.bind(this, "Unit")} value={this.state.propertyDetails.Unit}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Unit"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>City<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "City")} value={this.state.propertyDetails.City}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["City"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-1">
                                                            <label>State</label>
                                                            <select className="form-control" ref={(input) => this.stateSelector = input} value={this.state.propertyDetails.State} onChange={this.OnFormFieldChanged.bind(this, "State")}>
                                                                <option value="">--Select--</option>
                                                                <option value="AA"> AA </option>
                                                                <option value="AE"> AE </option>
                                                                <option value="AK"> AK </option>
                                                                <option value="AL"> AL </option>
                                                                <option value="AP"> AP </option>
                                                                <option value="AR"> AR </option>
                                                                <option value="AS"> AS </option>
                                                                <option value="AZ"> AZ </option>
                                                                <option value="CA"> CA </option>
                                                                <option value="CO"> CO </option>
                                                                <option value="CT"> CT </option>
                                                                <option value="DC"> DC </option>
                                                                <option value="DE"> DE </option>
                                                                <option value="FL"> FL </option>
                                                                <option value="GA"> GA </option>
                                                                <option value="GU"> GU </option>
                                                                <option value="HI"> HI </option>
                                                                <option value="IA"> IA </option>
                                                                <option value="ID"> ID </option>
                                                                <option value="IL"> IL </option>
                                                                <option value="IN"> IN </option>
                                                                <option value="KS"> KS </option>
                                                                <option value="KY"> KY </option>
                                                                <option value="LA"> LA </option>
                                                                <option value="MA"> MA </option>
                                                                <option value="MD"> MD </option>
                                                                <option value="ME"> ME </option>
                                                                <option value="MI"> MI </option>
                                                                <option value="MN"> MN </option>
                                                                <option value="MO"> MO </option>
                                                                <option value="MP"> MP </option>
                                                                <option value="MS"> MS </option>
                                                                <option value="MT"> MT </option>
                                                                <option value="NC"> NC </option>
                                                                <option value="ND"> ND </option>
                                                                <option value="NE"> NE </option>
                                                                <option value="NH"> NH </option>
                                                                <option value="NJ"> NJ </option>
                                                                <option value="NM"> NM </option>
                                                                <option value="NV"> NV </option>
                                                                <option value="NY"> NY </option>
                                                                <option value="OH"> OH </option>
                                                                <option value="OK"> OK </option>
                                                                <option value="OR"> OR </option>
                                                                <option value="PA"> PA </option>
                                                                <option value="PR"> PR </option>
                                                                <option value="RI"> RI </option>
                                                                <option value="SC"> SC </option>
                                                                <option value="SD"> SD </option>
                                                                <option value="TN"> TN </option>
                                                                <option value="TX"> TX </option>
                                                                <option value="UT"> UT </option>
                                                                <option value="VA"> VA </option>
                                                                <option value="VI"> VI </option>
                                                                <option value="VT"> VT </option>
                                                                <option value="WA"> WA </option>
                                                                <option value="WI"> WI </option>
                                                                <option value="WV"> WV </option>
                                                                <option value="WY"> WY </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["State"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Zip</label>
                                                            <input className="form-control" type="text" maxlength="5" onChange={this.OnFormFieldChanged.bind(this, "Zipcode")} value={this.state.propertyDetails.Zipcode}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Zipcode"]}</span>
                                                        </div>
                                                        {/* <div class="form-group col-sm-3">
                                                            <label>Email<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "Email")} value={this.state.propertyDetails.Email}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Email"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Phone<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="14" onChange={this.OnFormFieldChanged.bind(this, "Phone")} value={this.state.propertyDetails.Phone}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Phone"]}</span>
                                                        </div> */}
                                                        {/* <div class="form-group col-sm-2">
                                                            <label>Extension<i class=""></i></label>
                                                            <input className="form-control" type="text" maxlength="8" onChange={this.OnFormFieldChanged.bind(this, "PhoneExtension")} value={this.state.propertyDetails.PhoneExtension}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["PhoneExtension"]}</span>
                                                        </div> */}
                                                        <div class="form-group col-sm-2">
                                                            <label>Status</label>
                                                            {/* <input className="form-control" type="text" disabled onChange={this.OnFormFieldChanged.bind(this, "Status")} value={this.state.propertyDetails.Status}></input> */}
                                                            <select className="form-control" ref={(input) => this.statusSelector = input} value={this.state.propertyDetails.Status} onChange={this.OnFormFieldChanged.bind(this, "Status")}>
                                                                <option value="Active">Active</option>
                                                                <option value="Inactive">Inactive</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>PHA Status</label>
                                                            <select className="form-control" ref={(input) => this.statusSelector = input} value={this.state.propertyDetails.PHAStatus} onChange={this.OnFormFieldChanged.bind(this, "PHAStatus")}>
                                                                <option value="Registered">Registered</option>
                                                                <option value="Not Registered">Not Registered</option>
                                                                <option value="Under Process">Under Process</option>
                                                            </select>
                                                        </div>
                                                        <div class="form-group col-sm-5 col-lg-3">
                                                            <label>Management Company</label>
                                                            {/* <input className="form-control" type="text" maxlength="50" onChange={this.OnFormFieldChanged.bind(this, "ManagementCompany")} value={this.state.propertyDetails.ManagementCompany}></input> */}
                                                            <Autocomplete className="uw-autocomplete"
                                                                value={this.state.selectedManagementCompany}
                                                                onChange={(event, newValue1) => {
                                                                    if (typeof newValue1 === 'string') {
                                                                        this.setState({ selectedManagementCompany: newValue1 });
                                                                    } else if (newValue1 && newValue1.inputValue) {
                                                                        this.state.managementCompanies.push({ title: newValue1.inputValue, Id: "0" });
                                                                        this.setState({ selectedManagementCompany: { title: newValue1.inputValue, Id: "0" } });
                                                                    } else {
                                                                        this.setState({ selectedManagementCompany: newValue1 });
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    let isFound = false;
                                                                    filtered.forEach(e => {
                                                                        if (e.title === params.inputValue) {
                                                                            isFound = true;
                                                                        }
                                                                    });
                                                                    // Suggest the creation of a new value
                                                                    if (params.inputValue !== '' && !isFound) {
                                                                        filtered.push({
                                                                            inputValue: params.inputValue,
                                                                            //title: `Add "${params.inputValue}"`,
                                                                            title: `+ New Company "${params.inputValue}"`,
                                                                        });
                                                                    }
                                                                    return filtered;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                //autoComplete={true}
                                                                autoSelect={true}
                                                                id="ManagementCompany"
                                                                options={this.state.managementCompanies}
                                                                getOptionLabel={(option) => {
                                                                    // Value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    // Add "xxx" option created dynamically
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    // Regular option
                                                                    return option.title;
                                                                }}
                                                                renderOption={(option) => option.title}
                                                                // style={{ width: 300 }}
                                                                //freeSolo
                                                                renderInput={(params) => (
                                                                    <TextField {...params} className="form-control" />
                                                                )}
                                                            />
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Portal User</label>
                                                            <input className="form-control" type="text" maxlength="100" 
                                                            value={(this.state.portalUser ? ((this.state.portalUser.FirstName || this.state.portalUser.LastName) ? (this.state.portalUser.FirstName + " " + this.state.portalUser.LastName) : "") : "")} disabled></input>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <div className="h-100 box-gray img-box">
                                                <img src={this.state.imageUploaded == true ? this.state.selectedFile : this.state.propertyDetails.ImagePath === '' ? homeBG : this.state.propertyImage} width="150" height="150" className="rounded-circle" alt="home" loading="lazy" />
                                                <div className="custom-file-upload">
                                                    <div className="custom-upload-inner">
                                                        <label className="btn btn-primary">
                                                            Browse <input type="file" onChange={this.OnImageUploadChanged.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {this.state.propertyDetails.PHAStatus && this.state.propertyDetails.PHAStatus.toLowerCase() === 'not registered' &&
                                        <div className="pha-note wh-error mb-4" style={{ display: 'none' }}>
                                            <div className="pha-note-msg"><i class="fas fa-exclamation-circle mr-2"></i>The property is not registered under Public Housing Authority. Please click on button to register. </div>
                                            <div className="pha-note-action">
                                                <button type="button" className="btn btn-primary btn-black float-right" onClick={(e) => { this.openPHARegistrationWizard() }} >
                                                    <i class="fas fa-registered mr-1"></i>Register
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {this.state.propertyDetails.PHAStatus && this.state.propertyDetails.PHAStatus.toLowerCase() === 'registered' &&
                                        <div className="pha-note wh-success mb-4" style={{ display: 'none' }}>
                                            <div className="pha-note-msg"><i class="fas fa-check-circle mr-2"></i>PHA status is Registered</div>
                                        </div>
                                    }
                                    {this.state.propertyDetails.PHAStatus && this.state.propertyDetails.PHAStatus.toLowerCase() === 'under process' &&
                                        <div className="pha-note wh-inprogress mb-4" style={{ display: 'none' }}>
                                            <div className="pha-note-msg"><i class="fas fa-spinner mr-2"></i>The Public Housing Authority registration process for this property is in progress. Please click on button to complete the form.</div>
                                            <div className="pha-note-action">
                                                <button type="button" class="btn btn-primary btn-black float-right" onClick={(e) => { this.openPHARegistrationWizard() }} >
                                                    <i class="fas fa-registered mr-1"></i>Register
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Property Owner/Principal</h2>
                                        <div className="box-details">
                                            <div class="form-row">
                                                {/* <div class="form-group col-sm-2">
                                                    <label>Name</label>
                                                    <input className="form-control" type="text" maxlength="100" value={this.state.propertyDetails.po_Name} onChange={this.OnFormFieldChanged.bind(this, "po_Name")}></input>
                                                </div> */}
                                                <div class="form-group col-sm-3">
                                                    <label>Property Owner</label>
                                                    <Autocomplete className="uw-autocomplete"
                                                        value={this.state.selectedPropertyOwner}
                                                        onChange={(event, newValue1) => {
                                                            if (typeof newValue1 === 'string') {
                                                                //console.log('1');
                                                                this.setState({ selectedPropertyOwner: newValue1 });
                                                            } else if (newValue1 && newValue1.inputValue) {
                                                                //console.log('2');
                                                                this.state.centralContacts.push({ title: newValue1.inputValue, Id: "0" });
                                                                this.setState({ selectedPropertyOwner: { title: newValue1.inputValue, Id: "0" } });
                                                                $('[id*=propOwner_]').removeAttr('disabled');
                                                                this.clearPropertyOwner();
                                                            } else {
                                                                //console.log('3');
                                                                this.setState({ selectedPropertyOwner: newValue1 });
                                                                if (newValue1) {
                                                                    //console.log('4');
                                                                    this.updatePropertyOwner(newValue1.Id);
                                                                    $('[id*=propOwner_]').attr('disabled', 'disabled');
                                                                } else {
                                                                    //console.log('5');
                                                                    $('[id*=propOwner_]').removeAttr('disabled');
                                                                    this.clearPropertyOwner();
                                                                }
                                                            }
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            let isFound = false;
                                                            filtered.forEach(e => {
                                                                if (e.title === params.inputValue) {
                                                                    isFound = true;
                                                                }
                                                            });
                                                            // Suggest the creation of a new value
                                                            if (params.inputValue !== '' && !isFound) {
                                                                filtered.push({
                                                                    inputValue: `Please Add Info.`,
                                                                    //title: `No Match. Add New.`,
                                                                    title: `+ New Owner "${params.inputValue}"`,
                                                                });
                                                            }
                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        //autoComplete={true}
                                                        autoSelect={true}
                                                        id="PropertyOwner1"
                                                        options={this.state.centralContacts}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xyz" option created dynamically
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            // Regular option
                                                            return option.title;
                                                        }}
                                                        renderOption={(option) => option.title}
                                                        // style={{ width: 300 }}
                                                        //freeSolo
                                                        renderInput={(params) => (
                                                            <TextField {...params} className="form-control" />
                                                        )}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>First Name</label>
                                                    <input className="form-control" type="text" maxlength="100" id="propOwner_FirstName" value={this.state.po_FirstName} onChange={this.OnFormPo_FirstNameFieldChanged.bind(this, "po_FirstName")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_FirstName"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Last Name<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="100" id="propOwner_LastName" value={this.state.po_LastName} onChange={this.OnFormPo_LastNameFieldChanged.bind(this, "po_LastName")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_LastName"]}</span>
                                                </div>
                                                <div class="form-group col-sm-3">
                                                    <label>Email<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="255" id="propOwner_EmailID" value={this.state.po_EmailID} onChange={this.OnFormPo_EmailIDFieldChanged.bind(this, "po_EmailID")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_EmailID"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Phone</label>
                                                    {/* <input className="form-control" type="text" maxlength="14" value={(this.state.po_Phone === null || this.state.po_Phone === undefined) ? '' : this.state.po_Phone.replace('+1', '')} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "po_Phone")}></input> */}
                                                    <input className="form-control" type="text" maxlength="14" id="propOwner_Phone" value={(this.state.po_Phone)} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "po_Phone")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_Phone"]}</span>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-sm-3">
                                                    <label>Address</label>
                                                    <input className="form-control" type="text" maxlength="200" id="propOwner_Address" value={this.state.po_Address} onChange={this.OnFormPo_AddressFieldChanged.bind(this, "po_Address")}></input>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>City</label>
                                                    <input className="form-control" type="text" maxlength="10" id="propOwner_City" value={this.state.po_City} onChange={this.OnFormPo_CityFieldChanged.bind(this, "po_City")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_City"]}</span>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>State</label>
                                                    <select className="form-control" id="propOwner_State" value={this.state.po_State} onChange={this.OnFormPo_StateFieldChanged.bind(this, "po_State")} >
                                                        <option value="">--Select--</option>
                                                        <option value="AA"> AA </option>
                                                        <option value="AE"> AE </option>
                                                        <option value="AK"> AK </option>
                                                        <option value="AL"> AL </option>
                                                        <option value="AP"> AP </option>
                                                        <option value="AR"> AR </option>
                                                        <option value="AS"> AS </option>
                                                        <option value="AZ"> AZ </option>
                                                        <option value="CA"> CA </option>
                                                        <option value="CO"> CO </option>
                                                        <option value="CT"> CT </option>
                                                        <option value="DC"> DC </option>
                                                        <option value="DE"> DE </option>
                                                        <option value="FL"> FL </option>
                                                        <option value="GA"> GA </option>
                                                        <option value="GU"> GU </option>
                                                        <option value="HI"> HI </option>
                                                        <option value="IA"> IA </option>
                                                        <option value="ID"> ID </option>
                                                        <option value="IL"> IL </option>
                                                        <option value="IN"> IN </option>
                                                        <option value="KS"> KS </option>
                                                        <option value="KY"> KY </option>
                                                        <option value="LA"> LA </option>
                                                        <option value="MA"> MA </option>
                                                        <option value="MD"> MD </option>
                                                        <option value="ME"> ME </option>
                                                        <option value="MI"> MI </option>
                                                        <option value="MN"> MN </option>
                                                        <option value="MO"> MO </option>
                                                        <option value="MP"> MP </option>
                                                        <option value="MS"> MS </option>
                                                        <option value="MT"> MT </option>
                                                        <option value="NC"> NC </option>
                                                        <option value="ND"> ND </option>
                                                        <option value="NE"> NE </option>
                                                        <option value="NH"> NH </option>
                                                        <option value="NJ"> NJ </option>
                                                        <option value="NM"> NM </option>
                                                        <option value="NV"> NV </option>
                                                        <option value="NY"> NY </option>
                                                        <option value="OH"> OH </option>
                                                        <option value="OK"> OK </option>
                                                        <option value="OR"> OR </option>
                                                        <option value="PA"> PA </option>
                                                        <option value="PR"> PR </option>
                                                        <option value="RI"> RI </option>
                                                        <option value="SC"> SC </option>
                                                        <option value="SD"> SD </option>
                                                        <option value="TN"> TN </option>
                                                        <option value="TX"> TX </option>
                                                        <option value="UT"> UT </option>
                                                        <option value="VA"> VA </option>
                                                        <option value="VI"> VI </option>
                                                        <option value="VT"> VT </option>
                                                        <option value="WA"> WA </option>
                                                        <option value="WI"> WI </option>
                                                        <option value="WV"> WV </option>
                                                        <option value="WY"> WY </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Zip</label>
                                                    <input className="form-control" type="text" maxlength="10" id="propOwner_Zip" value={this.state.po_Zip} onChange={this.OnFormPo_ZipFieldChanged.bind(this, "po_Zip")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_Zip"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-dollar-sign mr-2"></i>Property Payment</h2>
                                        <div className="box-details">
                                            <div class="form-row">
                                                <div class="form-group col-sm-2">
                                                    <label>Payee name</label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "pyei_Name")} value={this.state.propertyDetails.pyei_Name}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Name"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Suite<i class=""></i></label>
                                                    <input className="form-control" type="text" maxlength="40" onChange={this.OnFormFieldChanged.bind(this, "pyei_Unit")} value={this.state.propertyDetails.pyei_Unit}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Unit"]}</span>
                                                </div>
                                                <div class="form-group col-sm-3">
                                                    <label>Address</label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "pyei_Address")} value={this.state.propertyDetails.pyei_Address}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Address"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>City</label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "pyei_City")} value={this.state.propertyDetails.pyei_City}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_City"]}</span>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>State</label>
                                                    <select className="form-control" ref={(input) => this.stateSelector = input} onChange={this.OnFormFieldChanged.bind(this, "pyei_State")} value={this.state.propertyDetails.pyei_State} >
                                                        <option value="">--Select--</option>
                                                        <option value="AA"> AA </option>
                                                        <option value="AE"> AE </option>
                                                        <option value="AK"> AK </option>
                                                        <option value="AL"> AL </option>
                                                        <option value="AP"> AP </option>
                                                        <option value="AR"> AR </option>
                                                        <option value="AS"> AS </option>
                                                        <option value="AZ"> AZ </option>
                                                        <option value="CA"> CA </option>
                                                        <option value="CO"> CO </option>
                                                        <option value="CT"> CT </option>
                                                        <option value="DC"> DC </option>
                                                        <option value="DE"> DE </option>
                                                        <option value="FL"> FL </option>
                                                        <option value="GA"> GA </option>
                                                        <option value="GU"> GU </option>
                                                        <option value="HI"> HI </option>
                                                        <option value="IA"> IA </option>
                                                        <option value="ID"> ID </option>
                                                        <option value="IL"> IL </option>
                                                        <option value="IN"> IN </option>
                                                        <option value="KS"> KS </option>
                                                        <option value="KY"> KY </option>
                                                        <option value="LA"> LA </option>
                                                        <option value="MA"> MA </option>
                                                        <option value="MD"> MD </option>
                                                        <option value="ME"> ME </option>
                                                        <option value="MI"> MI </option>
                                                        <option value="MN"> MN </option>
                                                        <option value="MO"> MO </option>
                                                        <option value="MP"> MP </option>
                                                        <option value="MS"> MS </option>
                                                        <option value="MT"> MT </option>
                                                        <option value="NC"> NC </option>
                                                        <option value="ND"> ND </option>
                                                        <option value="NE"> NE </option>
                                                        <option value="NH"> NH </option>
                                                        <option value="NJ"> NJ </option>
                                                        <option value="NM"> NM </option>
                                                        <option value="NV"> NV </option>
                                                        <option value="NY"> NY </option>
                                                        <option value="OH"> OH </option>
                                                        <option value="OK"> OK </option>
                                                        <option value="OR"> OR </option>
                                                        <option value="PA"> PA </option>
                                                        <option value="PR"> PR </option>
                                                        <option value="RI"> RI </option>
                                                        <option value="SC"> SC </option>
                                                        <option value="SD"> SD </option>
                                                        <option value="TN"> TN </option>
                                                        <option value="TX"> TX </option>
                                                        <option value="UT"> UT </option>
                                                        <option value="VA"> VA </option>
                                                        <option value="VI"> VI </option>
                                                        <option value="VT"> VT </option>
                                                        <option value="WA"> WA </option>
                                                        <option value="WI"> WI </option>
                                                        <option value="WV"> WV </option>
                                                        <option value="WY"> WY </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_State"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Zip</label>
                                                    <input className="form-control" type="text" maxlength="5" onChange={this.OnFormFieldChanged.bind(this, "pyei_Zip")} value={this.state.propertyDetails.pyei_Zip}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Zip"]}</span>
                                                </div>
                                                {/* <div class="form-group col-sm-4">
                                                    <label>Cheque Payable to<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "ChecksPayableTo")} value={this.state.propertyDetails.ChecksPayableTo}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["ChecksPayableTo"]}</span>
                                                </div>
                                                <div class="form-group col-sm-4">
                                                    <label>Remit to Address<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "RemitToAddress")} value={this.state.propertyDetails.RemitToAddress}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["RemitToAddress"]}</span>
                                                </div>
                                                <div class="form-group col-sm-4">
                                                    <label>Attention of<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "AttentionOf")} value={this.state.propertyDetails.AttentionOf}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["AttentionOf"]}</span>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-user-tie mr-2"></i>Property Manager</h2>
                                        <div className="box-details">
                                            <div className="bluebg-section">
                                                <div className="form-row align-items-center">
                                                    <div className="col-md-3 mb-2">
                                                        <label><b>Assign New Manager: </b></label>
                                                        <Autocomplete className="uw-autocomplete"
                                                            value={this.state.selectedPropertyManager}
                                                            onChange={(event, newValue1) => {
                                                                if (typeof newValue1 === 'string') {
                                                                    //console.log('1');
                                                                    this.setState({ selectedPropertyManager: newValue1 });
                                                                } else if (newValue1 && newValue1.inputValue) {
                                                                    //console.log('2');
                                                                    this.state.centralContactManagerUsers.push({ title: newValue1.inputValue, Id: "0" });
                                                                    this.setState({ selectedPropertyManager: { title: newValue1.inputValue, Id: "0" } });
                                                                    this.setState({ isMangerSelected: false });
                                                                    this.setState({ isAddManager: true });
                                                                    //console.log(this.state.isMangerSelected);
                                                                    //console.log(this.state.isAddManager);
                                                                    //$('[id*=propManager_]').removeAttr('disabled');
                                                                    this.clearPropertyManager();
                                                                } else {
                                                                    //console.log('3');
                                                                    this.setState({ selectedPropertyManager: newValue1 });
                                                                    if (newValue1) {
                                                                        //console.log('4');
                                                                        this.setState({ isMangerSelected: true });
                                                                        this.setState({ isAddManager: false });
                                                                        //console.log(this.state.isMangerSelected);
                                                                        //console.log(this.state.isAddManager);
                                                                        //this.updatePropertyOwner(newValue1.Id);
                                                                        //$('[id*=propManager_]').attr('disabled', 'disabled');
                                                                    } else {
                                                                        //console.log('5');
                                                                        this.setState({ isMangerSelected: false });
                                                                        this.setState({ isAddManager: false });
                                                                        //console.log(this.state.isMangerSelected);
                                                                        //console.log(this.state.isAddManager);
                                                                        // $('[id*=propManager_]').removeAttr('disabled');
                                                                        this.clearPropertyManager();
                                                                    }
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                //const filtered= [];
                                                                const filtered = filter(options, params);
                                                                let isFound = false;
                                                                filtered.forEach(e => {
                                                                    if (e.title === params.inputValue) {
                                                                        isFound = true;
                                                                    }
                                                                });
                                                                // Suggest the creation of a new value
                                                                if (params.inputValue !== '' && !isFound) {
                                                                    filtered.push({
                                                                        //inputValue: params.inputValue,
                                                                        //title: `Add "${params.inputValue}"`,
                                                                        inputValue: `Please Add Info.`,
                                                                        //title: `No Match. Add New Manager.`,
                                                                        title: `+ New Manager "${params.inputValue}"`,
                                                                    });
                                                                }
                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            //autoComplete={true}
                                                            autoSelect={true}
                                                            id="PropertyManager"
                                                            options={this.state.centralContactManagerUsers}
                                                            getOptionLabel={(option) => {
                                                                // Value selected with enter, right from the input
                                                                if (typeof option === 'string') {
                                                                    return option;
                                                                }
                                                                // Add "xyz" option created dynamically
                                                                if (option.inputValue) {
                                                                    return option.inputValue;
                                                                }
                                                                // Regular option
                                                                return option.title;
                                                            }}
                                                            renderOption={(option) => option.title}
                                                            // style={{ width: 300 }}
                                                            //freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params} className="form-control" />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-auto mt-1 mt-md-3" style={{ display: this.state.isMangerSelected ? "inline-block" : "none" }}>
                                                        <button type="button" id="addSelectedManager" name="addSelectedManager" onClick={(e) => { this.addManager(e,false) }} class="btn btn-primary btn-dark whoc-btn"><i class="fas fa-plus-circle text-white mr-1"></i>Add</button>
                                                    </div>
                                                </div>

                                                {/* <div className="property-grid form-row" style={{ display: this.state.isAddManager ? "block" : "none" }}> */}
                                                <div className="property-grid grid-text-field form-row align-items-end" style={{ display: this.state.isAddManager ? "flex" : "none" }}>
                                                
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="First Name"
                                                            id="propManager_FirstName"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_FirstName}
                                                            onChange={this.OnFormMa_FirstNameFieldChanged.bind(this, "ma_FirstName")}
                                                            error={this.state.managerValidationErrors["ma_FirstName"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_FirstName"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Last Name"
                                                            required
                                                            id="propManager_LastName"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_LastName}
                                                            onChange={this.OnFormMa_LastNameFieldChanged.bind(this, "ma_LastName")}
                                                            error={this.state.managerValidationErrors["ma_LastName"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_LastName"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Email"
                                                            required
                                                            id="propManager_EmailID"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_EmailID}
                                                            onChange={this.OnFormMa_EmailIDFieldChanged.bind(this, "ma_EmailID")}
                                                            error={this.state.managerValidationErrors["ma_EmailID"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_EmailID"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Phone"
                                                            id="propManager_Phone"
                                                            //maxlength="14"
                                                            inputProps={{ maxLength: 14 }}
                                                            value={this.state.ma_Phone}
                                                            onChange={this.OnFormMa_PhoneFieldChanged.bind(this, "ma_Phone")}
                                                            error={this.state.managerValidationErrors["ma_Phone"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_Phone"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-1">
                                                        <TextField label="Extension"
                                                            id="propManager_PhoneExtension"
                                                            //maxlength="4"
                                                            inputProps={{ maxLength: 4 }}
                                                            value={this.state.ma_PhoneExtension}
                                                            onChange={this.OnFormMa_PhoneExtensionFieldChanged.bind(this, "ma_PhoneExtension")}
                                                            error={this.state.managerValidationErrors["ma_PhoneExtension"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_PhoneExtension"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Additional Phone"
                                                            id="propManager_AdditionalPhone"
                                                            //maxlength="14"
                                                            inputProps={{ maxLength: 14 }}
                                                            value={this.state.ma_AdditionalPhone}
                                                            onChange={this.OnFormMa_AdditionalPhoneFieldChanged.bind(this, "ma_AdditionalPhone")}
                                                            error={this.state.managerValidationErrors["ma_AdditionalPhone"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_AdditionalPhone"]}
                                                        />

                                                    </div>
                                                    <div className="form-group col-md-4 col-xl-1 mt-3 mt-xl-0">
                                                        <div>
                                                            <button type="button" class="btn btn-primary btn-dark whoc-btn" onClick={(e) => { this.addManager(e,true) }} ><i class="fas fa-plus-circle text-white mr-1"></i>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='table-responsive uw-grid mt-3'>
                                                <table className={"table table-bordered doc-table " + (this.state.managerList && this.state.managerList.filter(data => !data.isDeleted).length > 3 ? "table-scroll" : "")}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th width="50%" scope="col">Index</th>
                                                            <th width="50%" scope="col">ManagerId</th> */}
                                                            <th width="15%" scope="col">First Name</th>
                                                            <th width="15%" scope="col">Last Name</th>
                                                            <th width="20%" scope="col">Email</th>
                                                            <th width="15%" scope="col">Phone</th>
                                                            <th width="10%" scope="col">Extension</th>
                                                            <th width="15%" scope="col">Additional Phone</th>
                                                            {/* <th width="50%" scope="col">AirtableId</th>
                                                            <th width="50%" scope="col">SF Id</th> */}
                                                            <th width="3%" scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.managerList && this.state.managerList.filter(data => !data.isDeleted).length > 0 ?
                                                            this.state.managerList.filter(data => !data.isDeleted).map((member, index) => (
                                                                <tr>
                                                                    {/* <td width="50%"> {member.ManagerIndex} </td> */}
                                                                    {/* <td width="50%"> {()=>{
                                                                        member.ManagerIndex = index;
                                                                        return member.ManagerIndex;
                                                                    }} </td> */}
                                                                    {/* <td width="50%"> {member.ManagerId} </td> */}
                                                                    {/* {()=>{member.ManagerIndex = index;}}  */}
                                                                    <td width="15%"> {member.FirstName} </td>
                                                                    <td width="15%"> {member.LastName} </td>
                                                                    <td width="20%"> {member.EmailID} </td>
                                                                    <td width="15%"> {member.Phone} </td>
                                                                    <td width="10%"> {member.PhoneExtension} </td>
                                                                    <td width="15%"> {member.AdditionalPhone} </td>
                                                                    {/* <td width="50%"> {member.AirtablePeoplePropId} </td>
                                                                    <td width="50%"> {member.SalesforceContactId} </td> */}
                                                                    {/* <td width="10%"><button className="border-0" type="button" title="Remove Member"><i class="fas fa-times"></i></button></td> */}
                                                                    <td width="3%"><button className="border-0" type="button" onClick={(e) => { this.removeManager(member.ManagerIndex) }} title="Remove Member" ><i class="fas fa-times"></i></button></td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colSpan="7"><p class="text-center"> No Managers assigned yet. </p></td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {this.state.managers &&
                                        <Provider store={store}>
                                            <FieldArraysForm initialValues={this.state.managers.length > 0 ? { members: this.state.managers } : { members: [] }} />
                                        </Provider>
                                    } */}

                                    {/* {this.state.representatives &&
                                        <Provider store={store}>
                                            <FieldArraysFormRepresentative initialValues={this.state.representatives.length > 0 ? { members: this.state.representatives } : { members: [] }} />
                                        </Provider>
                                    } */}

                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-file-signature mr-2"></i>PUBLIC HOUSING AUTHORITY REGISTRATION</h2>
                                        <div className="box-details whoc-radio whoc-multi-fields py-2">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label><b>Is the property already registered under Public Housing Authority (PHA)?</b></label>
                                                </div>
                                                <div className="col-auto pha-radio">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio1" name="IsPHARegistered" class="custom-control-input"
                                                            checked={this.state.propertyDetails.IsPHARegistered && this.state.propertyDetails.IsPHARegistered === true}
                                                            value="true" onChange={this.OnIsPHARegisteredRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="customRadio1">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio2" name="IsPHARegistered" class="custom-control-input"
                                                            checked={this.state.propertyDetails.IsPHARegistered === false}
                                                            value="false" onChange={this.OnIsPHARegisteredRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="customRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.propertyDetails.IsPHARegistered === false ?
                                                // (<div className="row">
                                                //     <div className="col-auto">
                                                //         <label><b>Would you like to register the property under Public Housing Authority using this portal?</b></label>
                                                //     </div>
                                                //     <div className="col-auto">
                                                //         <div class="custom-control custom-radio custom-control-inline">
                                                //             <input type="radio" id="customRadio3" name="registerPHANow" class="custom-control-input"
                                                //                 checked={this.state.registerPHANow && this.state.registerPHANow === "true"}
                                                //                 value="true" onChange={this.OnRegisterPHANowRadioButtonChanged.bind(this)} />
                                                //             <label class="custom-control-label" for="customRadio3">Yes</label>
                                                //         </div>
                                                //         <div class="custom-control custom-radio custom-control-inline">
                                                //             <input type="radio" id="customRadio4" name="registerPHANow" class="custom-control-input"
                                                //                 checked={this.state.registerPHANow && this.state.registerPHANow === "false"}
                                                //                 value="false" onChange={this.OnRegisterPHANowRadioButtonChanged.bind(this)} />
                                                //             <label class="custom-control-label" for="customRadio4">Maybe Later</label>
                                                //         </div>
                                                //     </div>
                                                // </div>)
                                                null :
                                                (<div className="row">
                                                    <div className="col-auto">
                                                        <label><b>Under which Public Housing Authority (PHA) the property is already registered?</b></label>
                                                    </div>
                                                    <div class="col-auto">
                                                        {/* <select className="form-control" onChange={this.OnFormFieldChanged.bind(this, "PHARegisteredHousingAuthority")} value={this.state.propertyDetails.PHARegisteredHousingAuthority}>
                                                            <option value="">--Select--</option>
                                                            <option value="Anaheim Housing Authority (AHA)">Anaheim Housing Authority (AHA)</option>
                                                            <option value="Orange County Housing Authority (OCHA)">Orange County Housing Authority (OCHA)</option>
                                                            <option value="Santa Ana Housing Authority (SAHA)">Santa Ana Housing Authority (SAHA)</option>
                                                            <option value="Other">Other</option>
                                                        </select> */}
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityAHA} id="IsPHAAuthorityAHA" value={this.state.propertyDetails.IsPHAAuthorityAHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityAHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityAHA">Anaheim Housing Authority (AHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityOCHA} id="IsPHAAuthorityOCHA" value={this.state.propertyDetails.IsPHAAuthorityOCHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityOCHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityOCHA">Orange County Housing Authority (OCHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthoritySAHA} id="IsPHAAuthoritySAHA" value={this.state.propertyDetails.IsPHAAuthoritySAHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthoritySAHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthoritySAHA">Santa Ana Housing Authority (SAHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityGGHA} id="IsPHAAuthorityGGHA" value={this.state.propertyDetails.IsPHAAuthorityGGHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityGGHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityGGHA">Garden Grove Housing Authority (GGHA)</label>
                                                        </div>
                                                        {/* <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityOther} id="IsPHAAuthorityOther" value={this.state.propertyDetails.IsPHAAuthorityOther} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityOther" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityOther">Other</label>
                                                        </div> */}
                                                    </div>
                                                </div>)
                                            }
                                            {/* {this.state.propertyDetails.IsPHARegistered === true && this.state.propertyDetails.IsPHAAuthorityOther ?
                                                (<div className="row">
                                                    <div className="col-auto">
                                                        <label><b>What is the name of the Public Housing Authority (PHA) under which the property is already registered?</b></label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <input className="form-control" type="text" maxlength="100" onChange={this.OnOtherPHANameChanged} value={this.state.propertyDetails.PHAAuthorityOtherTitle}></input>
                                                    </div>
                                                </div>)
                                                :
                                                null
                                            } */}
                                        </div>
                                    </div>

                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                                        <div className="box-details">
                                            <table class="table table-bordered doc-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Document</th>
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {this.state.propertyDetails.LeaseDocumentName !== '' && this.state.propertyDetails.LeaseDocumentStatus === false && this.state.propertyDetails.LeaseDocumentState === 'Pending' &&
                                                        <tr>
                                                            <td>Copy of blank lease</td>
                                                            <td>{this.state.propertyDetails.LeaseDocumentName}</td>
                                                            <td></td>
                                                            <td><span className="" >
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('LeaseDocumentStatus', true) }}>
                                                                    <Tooltip title="Approve" arrow>
                                                                        <IconButton class="fas fa-check mr-2 right-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('LeaseDocumentStatus', false) }}>
                                                                    <Tooltip title="Reject" arrow>
                                                                        <IconButton class="fas fa-times mr-2 close-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <a className="red" href={this.state.leaseDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td></tr>
                                                    }
                                                    {this.state.propertyDetails.LeaseDocumentName !== '' && this.state.propertyDetails.LeaseDocumentStatus === true &&
                                                        <tr>
                                                            <td>Copy of blank lease</td>
                                                            <td>{this.state.propertyDetails.LeaseDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.LeaseDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.LeaseDocumentState}</span></td>
                                                            <td><span className="">
                                                                <a href={this.state.leaseDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.LeaseDocumentName !== '' && this.state.propertyDetails.LeaseDocumentStatus === false && this.state.propertyDetails.LeaseDocumentState === 'Not Approved' &&
                                                        <tr>
                                                            <td></td>
                                                            <td>{this.state.propertyDetails.LeaseDocumentName}</td>
                                                            <td><p className="m-0">
                                                                <span className={" " + (this.state.propertyDetails.LeaseDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.LeaseDocumentState}</span>
                                                                <span className=""></span></p></td>
                                                            <td><a href={this.state.leaseDocument} target="_blank" download>
                                                                <Tooltip title="Download" arrow>
                                                                    <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                </Tooltip>
                                                            </a>
                                                                <input type="file" class="form-control-file" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block ml-2">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>

                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.LeaseDocumentName === '' &&
                                                        <tr>
                                                            <td>Copy of blank lease</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {/* <div class="file-upload d-inline-block">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                                <input type="file" class="form-control-file" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                            </td>
                                                        </tr>
                                                    }

                                                    {this.state.propertyDetails.W9DocumentName !== '' && this.state.propertyDetails.W9DocumentStatus === false && this.state.propertyDetails.W9DocumentState === 'Pending' &&
                                                        <tr>
                                                            <td>
                                                                W9 for payee
                                                                {/* <span class="alert-small-text">{this.state.validationErrors["w9NotUploaded"]}</span> */}
                                                            </td>
                                                            <td>{this.state.propertyDetails.W9DocumentName}</td>
                                                            <td></td>
                                                            <td><span className="">
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('W9DocumentStatus', true) }}>
                                                                    <Tooltip title="Approve" arrow>
                                                                        <IconButton class="fas fa-check mr-2 right-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('W9DocumentStatus', false) }}>
                                                                    <Tooltip title="Reject" arrow>
                                                                        <IconButton class="fas fa-times mr-2 close-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <a className="red" href={this.state.w9document} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a></span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.W9DocumentName !== '' && this.state.propertyDetails.W9DocumentStatus === true &&
                                                        <tr>
                                                            <td>
                                                                W9 for payee
                                                                {/* <span class="alert-small-text">{this.state.validationErrors["w9NotUploaded"]}</span> */}
                                                            </td>
                                                            <td>{this.state.propertyDetails.W9DocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.W9DocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.W9DocumentState}</span></td>
                                                            <td><span className="">
                                                                <a className="red" href={this.state.w9document} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a></span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.W9DocumentName !== '' && this.state.propertyDetails.W9DocumentStatus === false && this.state.propertyDetails.W9DocumentState === 'Not Approved' &&
                                                        <tr>
                                                            <td>
                                                                W9 for payee
                                                                {/* <span class="alert-small-text">{this.state.validationErrors["w9NotUploaded"]}</span> */}
                                                            </td>
                                                            <td>{this.state.propertyDetails.W9DocumentName}</td>
                                                            <td><p className="m-0">
                                                                <span className={" " + (this.state.propertyDetails.W9DocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.W9DocumentState}</span></p></td>
                                                            <td><a className="red" href={this.state.w9document} target="_blank" download>
                                                                <Tooltip title="Download" arrow>
                                                                    <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                </Tooltip>
                                                            </a>
                                                                {/* <div class="file-upload d-inline-block ml-2">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                                <input type="file" class="form-control-file" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                            </td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.W9DocumentName === '' &&
                                                        <tr>
                                                            <td>
                                                                W9 for payee
                                                                {/* <span class="alert-small-text">{this.state.validationErrors["w9NotUploaded"]}</span> */}
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                {/* <div class="file-upload">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                                <input type="file" class="form-control-file" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                            </td>
                                                        </tr>
                                                    }

                                                    {this.state.propertyDetails.RuleDocumentName !== '' && this.state.propertyDetails.RuleDocumentStatus === false && this.state.propertyDetails.RuleDocumentState === 'Pending' &&
                                                        <tr>
                                                            <td>Property/Community rules</td>
                                                            <td>{this.state.propertyDetails.RuleDocumentName}</td>
                                                            <td></td>
                                                            <td><span className="" >
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('RuleDocumentStatus', true) }}>
                                                                    <Tooltip title="Approve" arrow>
                                                                        <IconButton class="fas fa-check mr-2 right-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('RuleDocumentStatus', false) }}>
                                                                    <Tooltip title="Reject" arrow>
                                                                        <IconButton class="fas fa-times mr-2 close-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <a className="red" href={this.state.ruleDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.RuleDocumentName !== '' && this.state.propertyDetails.RuleDocumentStatus === true &&
                                                        <tr>
                                                            <td>Property/Community rules</td>
                                                            <td>{this.state.propertyDetails.RuleDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.RuleDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.RuleDocumentState}</span></td>
                                                            <td><span className="">
                                                                <a href={this.state.ruleDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.RuleDocumentName !== '' && this.state.propertyDetails.RuleDocumentStatus === false && this.state.propertyDetails.RuleDocumentState === 'Not Approved' &&
                                                        <tr>
                                                            <td>Property/Community rules</td>
                                                            <td>{this.state.propertyDetails.RuleDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.RuleDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.RuleDocumentState}</span></td>
                                                            <td><a href={this.state.ruleDocument} target="_blank" download>
                                                                <Tooltip title="Download" arrow>
                                                                    <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                </Tooltip>
                                                            </a>
                                                                <input type="file" class="form-control-file" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block ml-2">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.RuleDocumentName === '' &&
                                                        <tr>
                                                            <td>Property/Community rules</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <input type="file" class="form-control-file" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {/* supplement */}
                                                    {this.state.propertyDetails.SupplementDocumentName !== '' && this.state.propertyDetails.SupplementDocumentStatus === false && this.state.propertyDetails.SupplementDocumentState === 'Pending' &&
                                                        <tr>
                                                            <td>Supplement application documents</td>
                                                            <td>{this.state.propertyDetails.SupplementDocumentName}</td>
                                                            <td></td>
                                                            <td><span className="" >
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('SupplementDocumentStatus', true) }}>
                                                                    <Tooltip title="Approve" arrow>
                                                                        <IconButton class="fas fa-check mr-2 right-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('SupplementDocumentStatus', false) }}>
                                                                    <Tooltip title="Reject" arrow>
                                                                        <IconButton class="fas fa-times mr-2 close-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <a className="red" href={this.state.supplementDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.SupplementDocumentName !== '' && this.state.propertyDetails.SupplementDocumentStatus === true &&
                                                        <tr>
                                                            <td>Supplement application documents</td>
                                                            <td>{this.state.propertyDetails.SupplementDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.SupplementDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.SupplementDocumentState}</span></td>
                                                            <td><span className="">
                                                                <a href={this.state.supplementDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.SupplementDocumentName !== '' && this.state.propertyDetails.SupplementDocumentStatus === false && this.state.propertyDetails.SupplementDocumentState === 'Not Approved' &&
                                                        <tr>
                                                            <td>Supplement application documents</td>
                                                            <td>{this.state.propertyDetails.SupplementDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.SupplementDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.SupplementDocumentState}</span></td>
                                                            <td><a href={this.state.supplementDocument} target="_blank" download>
                                                                <Tooltip title="Download" arrow>
                                                                    <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                </Tooltip>
                                                            </a>
                                                                <input type="file" class="form-control-file" onChange={this.OnSupplementDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block ml-2">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.SupplementDocumentName === '' &&
                                                        <tr>
                                                            <td>Supplement application documents</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <input type="file" class="form-control-file" onChange={this.OnSupplementDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }

                                                    {/* lead paint disc */}
                                                    {this.state.propertyDetails.LeadBasedPaintDocumentName !== '' && this.state.propertyDetails.LeadBasedPaintDocumentStatus === false && this.state.propertyDetails.LeadBasedPaintDocumentState === 'Pending' &&
                                                        <tr>
                                                            <td>Lead based paint disclosure</td>
                                                            <td>{this.state.propertyDetails.LeadBasedPaintDocumentName}</td>
                                                            <td></td>
                                                            <td><span className="" >
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('LeadBasedPaintDocumentStatus', true) }}>
                                                                    <Tooltip title="Approve" arrow>
                                                                        <IconButton class="fas fa-check mr-2 right-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <span onClick={(e) => { this.updatePropertyDocumentStatus('LeadBasedPaintDocumentStatus', false) }}>
                                                                    <Tooltip title="Reject" arrow>
                                                                        <IconButton class="fas fa-times mr-2 close-mark border-0"></IconButton>
                                                                    </Tooltip>
                                                                </span>
                                                                <a className="red" href={this.state.leadbasedpaintDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.LeadBasedPaintDocumentName !== '' && this.state.propertyDetails.LeadBasedPaintDocumentStatus === true &&
                                                        <tr>
                                                            <td>Lead based paint disclosure</td>
                                                            <td>{this.state.propertyDetails.LeadBasedPaintDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.LeadBasedPaintDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.LeadBasedPaintDocumentState}</span></td>
                                                            <td><span className="">
                                                                <a href={this.state.leadbasedpaintDocument} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </span></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.LeadBasedPaintDocumentName !== '' && this.state.propertyDetails.LeadBasedPaintDocumentStatus === false && this.state.propertyDetails.LeadBasedPaintDocumentState === 'Not Approved' &&
                                                        <tr>
                                                            <td>Lead based paint disclosure</td>
                                                            <td>{this.state.propertyDetails.LeadBasedPaintDocumentName}</td>
                                                            <td><span className={" " + (this.state.propertyDetails.LeadBasedPaintDocumentState === "Approved" ? "green" : "red")}>{this.state.propertyDetails.LeadBasedPaintDocumentState}</span></td>
                                                            <td><a href={this.state.leadbasedpaintDocument} target="_blank" download>
                                                                <Tooltip title="Download" arrow>
                                                                    <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                </Tooltip>
                                                            </a>
                                                                <input type="file" class="form-control-file" onChange={this.OnLeadBasedPaintDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block ml-2">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.LeadBasedPaintDocumentName === '' &&
                                                        <tr>
                                                            <td>Lead based paint disclosure</td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                                <input type="file" class="form-control-file" onChange={this.OnLeadBasedPaintDocumentUploadChanged.bind(this)} />
                                                                {/* <div class="file-upload d-inline-block">
                                                                            <label for="upload" class="file-upload__label">Upload</label>
                                                                            <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                        </div> */}
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    {this.state.propertyDetails.WantToPHARegisterProperty === true &&
                                        <div className="box-gray mb-4" style={{ display: 'none' }}>
                                            <h2 class="profile-title"><i class="fas fa-file-signature mr-2"></i>PUBLIC HOUSING AUTHORITY REGISTRATION</h2>
                                            <div className="box-details">
                                                <div class="form-row">
                                                    <div class="form-group col-sm-12">
                                                        <label>Anaheim Housing Authority (AHA) Document - Admin Remarks</label>
                                                        <textarea rows="3" maxlength="1024" className="form-control h-auto" onChange={this.OnAdminPHADocumentRemarksChanged.bind(this, "AdminRemarksForAHADocument")} value={this.state.propertyDetails.AdminRemarksForAHADocument}></textarea>
                                                    </div>
                                                    <div class="form-group col-sm-12">
                                                        <label>Orange County Housing Authority (OCHA) Document - Admin Remarks</label>
                                                        <textarea rows="3" maxlength="1024" className="form-control h-auto" onChange={this.OnAdminPHADocumentRemarksChanged.bind(this, "AdminRemarksForOCHADocument")} value={this.state.propertyDetails.AdminRemarksForOCHADocument}></textarea>
                                                    </div>
                                                    <div class="form-group col-sm-12">
                                                        <label>Santa Ana Housing Authority (SAHA) Document - Admin Remarks</label>
                                                        <textarea rows="3" maxlength="1024" className="form-control h-auto" onChange={this.OnAdminPHADocumentRemarksChanged.bind(this, "AdminRemarksForSAHADocument")} value={this.state.propertyDetails.AdminRemarksForSAHADocument}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* {this.state.propertyDetails.WantToPHARegisterProperty === true && */}
                                    <div className="box-gray mb-4" style={{ display: 'none' }}>
                                        <h2 class="profile-title"><i class="fas fa-file mr-2"></i>Public Housing Authority Documents</h2>
                                        {/* <div className="box-details">
                                        <ul class="list-group list-group-flush">
                                            {this.state.propertyDocumentModels.length > 0 && this.state.propertyDocumentModels.map(item => (
                                                <li key={item} class="list-group-item">
                                                    {item.DocumentName}<span className="float-right"> <a className="red" href={item.DocumentPath} download target="_blank"><i class="fas fa-eye mr-3"></i></a></span>
                                                </li>
                                            ))}
                                            {this.state.propertyDocumentModels.length === 0 &&
                                                <li className="text-center list-group-item w-100">
                                                    No records to be displayed
                                                </li>
                                            }
                                        </ul>
                                    </div> */}

                                        <div className="table-responsive">
                                            <table class="table table-bordered table-cgreen mt-3">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Document</th>
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Uploaded Date Time</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.propertyDetails.AHAEnvelopeStatus === 'Completed' &&
                                                        <tr id="signed_aha_document_id">
                                                            <td>Signed Anaheim Housing Authority (AHA) Document <div id="signed_aha_document_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_aha_document_upload_id" /></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.OCHAEnvelopeStatus === 'Completed' &&
                                                        <tr id="signed_ocha_document_id">
                                                            <td>Signed Orange County Housing Authority (OCHA) Document <div id="signed_ocha_document_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_ocha_document_upload_id" /></td>
                                                        </tr>
                                                    }
                                                    {this.state.propertyDetails.SAHAEnvelopeStatus === 'Completed' &&
                                                        <tr id="signed_saha_document_id">
                                                            <td>Signed Santa Ana Housing Authority (SAHA) Document <div id="signed_saha_document_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="signed_saha_document_upload_id" /></td>
                                                        </tr>
                                                    }

                                                    <tr id="proof_of_ownership_id">
                                                        <td>Proof of Ownership <div id="proof_of_ownership_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="proof_of_ownership_upload_id" /></td>
                                                    </tr>
                                                    <tr id="irs_letter_issuing_tin_ein_id">
                                                        <td>IRS letter issuing TIN/EIN <div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="irs_letter_issuing_tin_ein_upload_id" /></td>
                                                    </tr>
                                                    <tr id="grant_deed_id">
                                                        <td>Grant Deed <div id="grant_deed_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="grant_deed_upload_id" /></td>
                                                    </tr>
                                                    <tr id="llc_id">
                                                        <td>LLC or LP Operating Agreement <div id="llc_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="llc_upload_id" /></td>
                                                    </tr>
                                                    <tr id="management_agreement_id">
                                                        <td>Management Agreement <div id="management_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="management_agreement_upload_id" /></td>
                                                    </tr>
                                                    <tr id="hap_contract_id">
                                                        <td>HAP Contract <div id="hap_contract_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="hap_contract_upload_id" /></td>
                                                    </tr>
                                                    <tr id="rent_to_owner_form_id">
                                                        <td>Rent To Owner Form <div id="rent_to_owner_form_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="rent_to_owner_form_upload_id" /></td>
                                                    </tr>
                                                    <tr id="tax_id_verification_id">
                                                        <td>Tax ID Verification <div id="tax_id_verification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="tax_id_verification_upload_id" /></td>
                                                    </tr>
                                                    <tr id="property_tax_bill_id">
                                                        <td>Property Tax Bill <div id="property_tax_bill_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_tax_bill_upload_id" /></td>
                                                    </tr>
                                                    <tr id="property_management_agreement_id">
                                                        <td>Property Management Agreement</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="property_management_agreement_upload_id" /></td>
                                                    </tr>
                                                    <tr id="recorded_grant_deed_id">
                                                        <td>Recorded Grant Deed <div id="recorded_grant_deed_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="recorded_grant_deed_upload_id" /></td>
                                                    </tr>
                                                    <tr id="remove_home_owner_exemption_id">
                                                        <td>Remove Home-Owner Exemption</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="remove_home_owner_exemption_upload_id" /></td>
                                                    </tr>
                                                    <tr id="statements_from_property_coowners_id">
                                                        <td>Statements from property co-owners</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="statements_from_property_coowners_upload_id" /></td>
                                                    </tr>
                                                    <tr id="original_voided_check_id">
                                                        <td>Original Voided Check <div id="original_voided_check_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="original_voided_check_upload_id" /></td>
                                                    </tr>
                                                    <tr id="final_escrow_statement_id">
                                                        <td>Final Escrow statement; or Current Property Tax Bill <div id="final_escrow_statement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td><input class="form-control-sm form-control-file pl-0" type="file" disabled id="final_escrow_statement_upload_id" /></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* } */}

                                    <div className="box-gray mb-4">
                                        <h2 className="profile-title"><i class="fas fa-building mr-2"></i>Unit Submitted</h2>
                                        {this.renderUnits()}
                                    </div>

                                    <Tooltip title="submit" arrow>
                                        <Button type="button" class="btn btn-primary whoc-float-btn" onClick={this.editProperty}>
                                            <i class="fas fa-save"></i>
                                        </Button>
                                    </Tooltip>
                                </form>
                            </React.Fragment>
                        </div>
                    </div>
                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                    <Snackbar
                        anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                        autoHideDuration={3000}
                        open={this.state.openSnackbar}
                        message={this.state.snackbarMessage}
                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                        className={this.state.snackbarMessageClass}
                        onClose={() => this.setState({ openSnackbar: false })}
                        // action={
                        //     <React.Fragment>
                        //         <IconButton aria-label="close" color="inherit" onClick={this.closeSnackbar.bind()}>
                        //             <CloseIcon />
                        //         </IconButton>
                        //     </React.Fragment>
                        // }
                    />
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}

export default withRouter(EditProperty);