import React from "react";
import { Auth, API, Storage } from "aws-amplify";
import imgLogo from "../imgs/logo-assist-oc.png";
import Tooltip from "@material-ui/core/Tooltip";
import { CircularProgress, Button } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import ReactDOM from "react-dom";
import App from "../App";

import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import imgSearchclaim from "../imgs/search-claims.svg";
import Snackbar from "@material-ui/core/Snackbar";
import $ from "jquery";
import moment from 'moment';

import { Input, OutlinedInput } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
//import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from '@material-ui/core/styles';
// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 300,
//     width: 250
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));
import {
  KmsKeyringBrowser,
  KMS,
  getClient,
  buildClient,
  CommitmentPolicy
} from "@aws-crypto/client-browser";
import * as AWS from "aws-sdk";
import * as util_utf8_node from "@aws-sdk/util-utf8-node";
import { toBase64,fromBase64 } from "@aws-sdk/util-base64-browser";
import { decryptValue } from '../utils/AESUtil';

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_ALLOW_DECRYPT
);

const useStyles = theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 320
  },
  formControlRec: {
    margin: theme.spacing(1),
    width: 253
  },
  selectStyle: {
    backgroundColor: "white"
  }
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  PaperProps: {
    style: {
      //  maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      getContentAnchorEl: null,
      variant: "menu"
    }
  }
};

const names = [
  { id: "1", value: "January" },
  { id: "2", value: "February" },
  { id: "3", value: "March" },
  { id: "4", value: "April" },
  { id: "5", value: "May" },
  { id: "6", value: "June" },
  { id: "7", value: "July" },
  { id: "8", value: "August" },
  { id: "9", value: "September" },
  { id: "10", value: "October" },
  { id: "11", value: "November" },
  { id: "12", value: "December" }
];
// const classes = useStyles();
class EditSantaAnaApplicant extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      rec_landlord_driver_license: "",
      rec_landlord_w9: "",
      rec_landlord_participationagreement: "",

      rec_isDrivingLicenceUpload: false,
      rec_isW9Upload: false,
      rec_isParticipateAgreementUpload: false,

      rec_landlord_driver_license_uploaded: false,
      rec_landlord_w9_uploaded: false,
      rec_landlord_participationagreement_uploaded: false,
      isApplicantResendShow: false,
      isLandlordResendShow: false,
      misceDocumentsList: [],
      importFilePath: null,
      importFileName: null,
      personName: ['1', '2', '4'],
      paymentMonths2021: [],
      paymentMonths2021Clone: [],
      paymentMonths2022: [],
      paymentMonths2022Clone: [],

      paymentMonthsRec2021: [],
      paymentMonthsRec2021Clone: [],
      paymentMonthsRec2022: [],
      paymentMonthsRec2022Clone: [],

      isLoading: false,
      permissions: [],
      mode: "Admin",
      authState: "loading",

      ///////////
      individualID: "0",
      fundingSource: "ERA2021",

      applicantDetails: {},
      landlordDetails: {},
      landlordpaymentDetails: {
        transactionType: "",
        createdDate: "",
        modifiedDate: "",
        createdTime: "",
        modifiedTime: "",
        modifiedBy: "",
        createdBy: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",

      },
      individualDetails: {},
      individualpaymentDetails: {
        transactionType: "",
        createdDate: "",
        modifiedDate: "",
        createdTime: "",
        modifiedTime: "",
        modifiedBy: "",
        createdBy: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },
      applicantProperty: {},
      landlordProperty: {},

      applicantDetailsClone: {},
      landlordDetailsClone: {},
      individualDetailsClone: {},
      applicantPropertyClone: {},
      landlordPropertyClone: {},
      landlordpaymentDetailsClone: {
        transactionType: "",
        createdDate: "",
        modifiedDate: "",
        createdTime: "",
        modifiedTime: "",
        modifiedBy: "",
        createdBy: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },
      individualpaymentDetailsClone: {
        transactionType: "",
        createdDate: "",
        modifiedDate: "",
        createdTime: "",
        modifiedTime: "",
        modifiedBy: "",
        createdBy: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },

      landlordEmail_Original: null,
      applicantEmail_Original: null,
      rentPaymentStatus_Original: null,
      landlordInfoRequestedStatus_Original: null,
      applicantDocUploadStatus_Original: null,
      individualPaymentStatus_Original: null,

      // new states
      transactionmodelandlord: "",
      transactionmodeindividual: "",

      // payment states
      transactionType: null,
      entityAccountNumber: null,
      entityRoutingNumber: null,
      entityBankName: null,
      entityAccountType: null,
      payeeName: null,
      fullAddress: null,
      informationStatus: null,
      checkStatus: null,
      paymentId: null,
      // ends payment states

      landloadAddress: "",
      testrecord: false, //for checkbox toggle value
      bankAccountNumber: "",
      routeingNumber: "",
      payeeName: "",

      dateImported_Formatted: null,
      rentPaymentStatus: null,

      dateEmailTriggered: null,
      timeEmailTriggered: null,

      dateRentPaymentStatusUpdated: null,
      timeRentPaymentStatusUpdated: null,

      individualCase_Original: null,

      dateLandlordInfoRequested: "0000-00-00",
      dateLandlordInfoRequested_Formatted: "0000-00-00",
      dateLandlordInfoRequestedNew_Formatted: "0000-00-00",
      dateApplicantInfoRequested_Formatted: "0000-00-00",
      //
      dateReconcilationRequestedNew_Formatted: "0000-00-00",
      dateReconcilationReminded_Formatted: "0000-00-00",
      // bank  date  requested states
      dateLandlordBankInfoRequestedNew_Formatted: "0000-00-00",
      dateIndividualBankInfoRequestedNew_Formatted: "0000-00-00",
      // end
      dateApplicantDocumentSubmitted_Formatted: "0000-00-00",
      dateLandlordDocumentSubmitted_Formatted: "0000-00-00",
      timeLandlordInfoRequested: null,
      countLandlordInfoRequested: 0,
      dateLandlordInfoReminded: "0000-00-00",
      dateLandlordInfoReminded_Formatted: "0000-00-00",
      timeLandlordInfoReminded: null,

      totalCovered: 0,
      totalCoveredClone: 0,
      totalRecords: 1,
      total1099Amount: 0,
      total1099Amount2022: 0,

      ///////////

      lengthOfTimeForAward: null,
      timestampOfRentIssued: null,

      triggerEmail: "0",

      reconciliationEditId: 0,
      deleteReconciliationId: 0,
      reconciliationMode: "",
      isChildRecord: false,

      reconciliation: {
        entityType: "",
        initiallyPaidBy: "",
        amountType: "",
        amount: "",
        memoPropertyAddress: "",
        paymentMethod: "",
        otherPaymentStatus: "",
        amountCoveredAfterRec: "",
        amountCoveredBeforeRec: "",
        transactionNo: "",
        notes: "",
        modifiedBy: "",
        createdBy: "",
        dateCreated: "",
        dateStatusIssued: null,
        timeStatusIssued: null,
        timeCreated: "",
        transactionType: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },
      reconciliationList: [],
      reconciliationListClone: [],
      reconciliationListByIndividualId: [],
      otherPaymentStatus_Original: "",
      validationErrors: {},
      isRecAccountNoOld: false,
      isRecRoutingNoOld: false,
      isRecInformationStatusOld: '',

      openSnackbar: false,
      snackbarSeverity: "",
      snackbarVertical: "top",
      snackbarHorizontal: "right",
      snackbarMessage: "",
      snackbarMessageClass: "",

      photo_identification_uploaded: false,
      proof_of_address_uploaded: false,
      proof_of_income_uploaded: false,
      proof_of_income_loss_uploaded: false,
      ach_authorization_form_uploaded: false,
      lease_agreement_uploaded: false,
      utility_gas_bill_uploaded: false,
      utility_electricity_bill_uploaded: false,
      landlord_w9_uploaded: false,
      landlord_driver_license_uploaded: false,
      verified_checkpoint_uploaded: false,
      tenant_checkPoint_id_verification_page_uploaded: false,
      era3_tenant_checkPoint_uploaded: false,
      era2021ReCert_landlord_updated_w9_uploaded: false,
      era2021ReCert_updated_program_participation_Form_uploaded: false,
      era2021ReCert_landlord_identity_uploaded: false,
      era2021ReCert_landlord_checkpoint_uploaded: false,
      era2_landlord_updated_w9_uploaded: false,
      era3_landlord_updated_w9_uploaded: false,
      era2_updated_program_participation_Form_uploaded: false,
      era3_updated_program_participation_Form_uploaded: false,
      era2_landlord_identity_uploaded: false,
      era3_landlord_identity_uploaded: false,
      era2_landlord_checkpoint_uploaded: false,
      era3_landlord_checkpoint_uploaded: false,
      tenant_payment_agreement_uploaded: false,
      landlord_payment_agreement_uploaded: false,

      speedDialOpen: false,
      speedDialActions: [
        {
          icon: <SaveIcon onClick={() => this.handleSpeedDialClick("save")} />,
          name: "Save",
        },
        {
          icon: (
            <CancelIcon onClick={() => this.handleSpeedDialClick("cancel")} />
          ),
          name: "Cancel",
        },
      ],

      /////////// Error State Variables - Starts ///////////
      firstName_Error: "",
      lastName_Error: "",
      partnerFirstName_Error: "",
      partnerLastName_Error: "",
      addressLine1_Error: "",
      addressLine2_Error: "",
      city_Error: "",
      state_Error: "",
      country_Error: "",
      zipcode_Error: "",
      clientDOB_Error: "",
      phone_Error: "",
      monthlyIncomeBeforeCovid19_Error: "",
      householdNumber_Error: "",
      age0to5_Error: "",
      age6to17_Error: "",
      age18to59_Error: "",
      age60andUp_Error: "",
      cardValue_Error: "",

      notes_Error: "",

      spaAssignmentNo_Error: "",
      householdID_Error: "",
      transactionID_Error: "",
      landlordPhoneNumber_Error: "",
      landlordEmail_Error: "",
      amountDueforRent_Error: "",
      rentAmount_Error: "",
      landlordAccountNumber_Error: "",
      landlordRoutingNumber_Error: "",
      landlordAddressLine1_Error: "",
      landlordAddressLine2_Error: "",
      landlordCity_Error: "",
      landlordState_Error: "",
      landlordZipcode_Error: "",

      currentTotalHouseholdIncome_Error: "",
      spaServiceProviderEmail_Error: "",
      noOfTimesParticipated_Error: "",

      landlordSSN_Error: "",
      landlordTaxID_Error: "",
      landlordDUNSNumber_Error: "",
      monthlyRent_Error: "",
      percentageOfMonthlyRent_Error: "",
      outstandingRentalArrearsAmount_Error: "",
      noOfMonthsRentalPayments_Error: "",

      covid_other_Error: "",
      industry_other_Error: "",

      tenantEmail_Error: "",
      dateApplied_Error: "",

      individualAccountNumber_Error: "",
      individualRoutingNumber_Error: "",
      individualPaymentAmount_Error: "",
      individualMemoPropertyAddress_Error: "",

      suffix_Error: "",
      middleName_Error: "",
      agent_Error: "",
      doYouRent_Error: "",
      receiveAnyHousingSubsidy_Error: "",
      isAnnualIncomeLess_Error: "",
      isSomeoneOnRentalOrLeaseAgreement_Error: "",
      filedCovid19FinancialStressDeclaration_Error: "",
      receivedEvictionNotice_Error: "",
      SSN_Error: "",
      socialSecurityFirstName_Error: "",
      socialSecurityLastName_Error: "",
      birthdayCode_Error: "",
      age_Error: "",
      otherPhone_Error: "",
      secondaryFirstName_Error: "",
      secondaryLastName_Error: "",
      secondaryEmail_Error: "",
      secondaryPhone_Error: "",

      reasonPaymentNotProcessedIn21Days_Error: "",

      landlordpaymentDetailsEntityRoutingNumber_Error: "",
      landlordpaymentDetailsEntityAccountNumber_Error: "",
      reconciliationEntityRoutingNumber_Error: "",
      reconciliationEntityAccountNumber_Error: "",
      uniqueID_Error: "",

      countAdult_Error: "",
      countChildren_Error: "",
      isTransactionPermission: false,
      keyring: {},
      /////////// Error State Variables - Ends ///////////
      landlordpaymentInformationStatus_Error: "",
      reclandlordpaymentInformationStatus_Error: "",
    };
    
    // preserve the initial state in a new object
    this.baseState = { ...this.state };
    this.importFile = null;
    this.rentPaymentStatusRef = React.createRef();

    /////////// Validation Control References - Ends ///////////
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.partnerFirstNameRef = React.createRef();
    this.partnerLastNameRef = React.createRef();
    this.addressLine1Ref = React.createRef();
    this.addressLine2Ref = React.createRef();
    this.cityRef = React.createRef();
    this.stateRef = React.createRef();
    this.countryRef = React.createRef();
    this.zipcodeRef = React.createRef();
    this.clientDOBRef = React.createRef();
    this.phoneRef = React.createRef();
    this.monthlyIncomeBeforeCovid19Ref = React.createRef();
    this.householdNumberRef = React.createRef();
    this.age0to5Ref = React.createRef();
    this.age6to17Ref = React.createRef();
    this.age18to59Ref = React.createRef();
    this.age60andUpRef = React.createRef();
    this.cardValueRef = React.createRef();

    this.notesRef = React.createRef();

    this.spaAssignmentNoRef = React.createRef();
    this.householdIDRef = React.createRef();
    this.transactionIDRef = React.createRef();
    this.landlordPhoneNumberRef = React.createRef();
    this.landlordEmailRef = React.createRef();
    this.amountDueforRentRef = React.createRef();
    this.rentAmountRef = React.createRef();
    this.landlordAccountNumberRef = React.createRef();
    this.landlordRoutingNumberRef = React.createRef();
    this.landlordAddressLine1Ref = React.createRef();
    this.landlordAddressLine2Ref = React.createRef();
    this.landlordCityRef = React.createRef();
    this.landlordStateRef = React.createRef();
    this.landlordZipcodeRef = React.createRef();

    this.currentTotalHouseholdIncomeRef = React.createRef();
    this.spaServiceProviderEmailRef = React.createRef();
    this.noOfTimesParticipatedRef = React.createRef();

    this.landlordSSNRef = React.createRef();
    this.landlordTaxIDRef = React.createRef();
    this.landlordDUNSNumberRef = React.createRef();
    this.monthlyRentRef = React.createRef();
    this.percentageOfMonthlyRentRef = React.createRef();
    this.outstandingRentalArrearsAmountRef = React.createRef();
    this.noOfMonthsRentalPaymentsRef = React.createRef();

    this.covid_otherRef = React.createRef();
    this.industry_otherRef = React.createRef();

    this.tenantEmailRef = React.createRef();
    this.dateAppliedRef = React.createRef();

    this.individualAccountNumberRef = React.createRef();
    this.individualRoutingNumberRef = React.createRef();
    this.individualPaymentAmountRef = React.createRef();
    this.individualMemoPropertyAddressRef = React.createRef();

    this.suffixRef = React.createRef();
    this.middleNameRef = React.createRef();
    this.agentRef = React.createRef();
    this.doYouRentRef = React.createRef();
    this.receiveAnyHousingSubsidyRef = React.createRef();
    this.isAnnualIncomeLessRef = React.createRef();
    this.isSomeoneOnRentalOrLeaseAgreementRef = React.createRef();
    this.filedCovid19FinancialStressDeclarationRef = React.createRef();
    this.receivedEvictionNoticeRef = React.createRef();
    this.SSNRef = React.createRef();
    this.socialSecurityFirstNameRef = React.createRef();
    this.socialSecurityLastNameRef = React.createRef();
    this.birthdayCodeRef = React.createRef();
    this.ageRef = React.createRef();
    this.otherPhoneRef = React.createRef();
    this.secondaryFirstNameRef = React.createRef();
    this.secondaryLastNameRef = React.createRef();
    this.secondaryEmailRef = React.createRef();
    this.secondaryPhoneRef = React.createRef();

    this.reasonPaymentNotProcessedIn21DaysRef = React.createRef();
    this.uniqueIDRef = React.createRef();

    this.countAdultRef = React.createRef();
    this.countChildrenRef = React.createRef();

    this.landlordpaymentDetailsEntityRoutingNumberRef = React.createRef();
    this.landlordpaymentDetailsEntityAccountNumberRef = React.createRef();
    this.reconciliationEntityRoutingNumberRef = React.createRef();
    this.reconciliationEntityAccountNumberRef = React.createRef();

    this.informationStatusRef = React.createRef();
    this.recInformationStatusRef = React.createRef();

    /////////// Validation Control References - Ends ///////////

    this.photo_identification_upload = null;
    this.proof_of_address_upload = null;
    this.proof_of_income_upload = null;
    this.proof_of_income_loss_upload = null;
    this.ach_authorization_form_upload = null;
    this.lease_agreement_upload = null;
    this.utility_gas_bill_upload = null;
    this.utility_electricity_bill_upload = null;
    this.landlord_w9_upload = null;
    this.landlord_driver_license_upload = null;
    this.verified_checkpoint_upload = null;
    this.tenant_checkPoint_id_verification_page_upload = null;
    this.era3_tenant_checkPoint_upload = null;
    this.era2021ReCert_landlord_updated_w9_upload = null;
    this.era2021ReCert_updated_program_participation_Form_upload = null;
    this.era2021ReCert_landlord_identity_upload = null;
    this.era2021ReCert_landlord_checkpoint_upload = null;
    this.era2_landlord_updated_w9_upload = null;
    this.era3_landlord_updated_w9_upload = null;
    this.era2_updated_program_participation_Form_upload = null;
    this.era3_updated_program_participation_Form_upload = null;
    this.era2_landlord_identity_upload = null;
    this.era3_landlord_identity_upload = null;
    this.era2_landlord_checkpoint_upload = null;
    this.era3_landlord_checkpoint_upload = null;
    this.tenant_payment_agreement_upload = null;
    this.landlord_payment_agreement_upload = null;

    this.rec_landlord_driver_license_upload = null;
    this.rec_landlord_w9_upload = null;
    this.rec_landlord_participationagreement_upload = null;

    // this.setState({ individualID: this.props.individualID });
  }

  validateControls = () => {
    var validationError = false;
    this.setState({ isLoading: true });

    ///////////////////////////   VALIDATION STARTS   ///////////////////////////

    // if (this.state.applicantDetails.firstName !== "" && this.state.applicantDetails.firstName !== null && this.state.applicantDetails.firstName !== undefined) {
    //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
    //     if (!namePattern.test(this.state.applicantDetails.firstName)) {
    //         this.setState({ firstName_Error: "Invalid First Name" });
    //         this.firstNameRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ firstName_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ firstName_Error: "" });
    // }

    // if (this.state.applicantDetails.lastName !== "" && this.state.applicantDetails.lastName !== null && this.state.applicantDetails.lastName !== undefined) {
    //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
    //     if (!namePattern.test(this.state.applicantDetails.lastName)) {
    //         this.setState({ lastName_Error: "Invalid Last Name" });
    //         this.lastNameRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ lastName_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ lastName_Error: "" });
    // }

    // if (this.state.partnerFirstName !== "" && this.state.partnerFirstName !== null && this.state.partnerFirstName !== undefined) {
    //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
    //     if (!namePattern.test(this.state.partnerFirstName)) {
    //         this.setState({ partnerFirstName_Error: "Invalid Partner First Name" });
    //         this.partnerFirstNameRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ partnerFirstName_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ partnerFirstName_Error: "" });
    // }

    // if (this.state.partnerLastName !== "" && this.state.partnerLastName !== null && this.state.partnerLastName !== undefined) {
    //     var namePattern = new RegExp(/^[A-Za-z\/\']+[A-Za-z]$/);
    //     if (!namePattern.test(this.state.partnerLastName)) {
    //         this.setState({ partnerLastName_Error: "Invalid Partner Last Name" });
    //         this.partnerLastNameRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ partnerLastName_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ partnerLastName_Error: "" });
    // }

    // addressLine1
    // addressLine2

    if (
      this.state.applicantDetails.city !== "" &&
      this.state.applicantDetails.city !== null &&
      this.state.applicantDetails.city !== undefined
    ) {
      var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
      if (!namePattern.test(this.state.applicantDetails.city)) {
        this.setState({ city_Error: "Invalid City" });
        this.cityRef.current.focus();

        validationError = true;
      } else {
        this.setState({ city_Error: "" });
      }
    } else {
      this.setState({ city_Error: "" });
    }

    // state
    // country

    if (
      this.state.applicantDetails.zipcode !== "" &&
      this.state.applicantDetails.zipcode !== null &&
      this.state.applicantDetails.zipcode !== undefined
    ) {
      if (
        this.state.applicantDetails.zipcode.length < 5 ||
        /^\d+$/.test(this.state.applicantDetails.zipcode) !== true
      ) {
        this.setState({ zipcode_Error: "Invalid Zip" });
        this.zipcodeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ zipcode_Error: "" });
      }
    } else {
      this.setState({ zipcode_Error: "" });
    }

    // clientDOB

    if (
      this.state.applicantDetails.phone !== "" &&
      this.state.applicantDetails.phone !== null &&
      this.state.applicantDetails.phone !== undefined
    ) {
      if (this.state.applicantDetails.phone.length < 10) {
        this.setState({ phone_Error: "Invalid Phone" });
        this.phoneRef.current.focus();

        validationError = true;
      } else {
        this.setState({ phone_Error: "" });
      }
    } else {
      this.setState({ phone_Error: "" });
    }

    if (
      this.state.applicantDetails.monthlyIncomeBeforeCovid19 !== "" &&
      this.state.applicantDetails.monthlyIncomeBeforeCovid19 !== null &&
      this.state.applicantDetails.monthlyIncomeBeforeCovid19 !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(
          this.state.applicantDetails.monthlyIncomeBeforeCovid19
        ) !== true
      ) {
        this.setState({ monthlyIncomeBeforeCovid19_Error: "Invalid Amount" });
        this.monthlyIncomeBeforeCovid19Ref.current.focus();

        validationError = true;
      } else if (
        this.state.applicantDetails.monthlyIncomeBeforeCovid19 > 99999.99
      ) {
        this.setState({ monthlyIncomeBeforeCovid19_Error: "Exceeds limit" });
        this.monthlyIncomeBeforeCovid19Ref.current.focus();

        validationError = true;
      } else {
        this.setState({ monthlyIncomeBeforeCovid19_Error: "" });
      }
    } else {
      this.setState({ monthlyIncomeBeforeCovid19_Error: "" });
    }

    if (
      this.state.applicantDetails.householdNumber !== "" &&
      this.state.applicantDetails.householdNumber !== null &&
      this.state.applicantDetails.householdNumber !== undefined
    ) {
      if (
        /^\d+$/.test(this.state.applicantDetails.householdNumber) !== true ||
        parseInt(this.state.applicantDetails.householdNumber) > 100
      ) {
        this.setState({ householdNumber_Error: "Invalid Number" });
        this.householdNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ householdNumber_Error: "" });
      }
    } else {
      this.setState({ householdNumber_Error: "" });
    }

    // if (this.state.age0to5 !== "" && this.state.age0to5 !== null && this.state.age0to5 !== undefined) {
    //     if (/^\d+$/.test(this.state.age0to5) !== true || parseInt(this.state.age0to5) > 100) {
    //         this.setState({ age0to5_Error: "Invalid Age 0-5" });
    //         this.age0to5Ref.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ age0to5_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ age0to5_Error: "" });
    // }

    // if (this.state.age6to17 !== "" && this.state.age6to17 !== null && this.state.age6to17 !== undefined) {
    //     if (/^\d+$/.test(this.state.age6to17) !== true || parseInt(this.state.age6to17) > 100) {
    //         this.setState({ age6to17_Error: "Invalid Number" });
    //         this.age6to17Ref.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ age6to17_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ age6to17_Error: "" });
    // }

    // if (this.state.age18to59 !== "" && this.state.age18to59 !== null && this.state.age18to59 !== undefined) {
    //     if (/^\d+$/.test(this.state.age18to59) !== true || parseInt(this.state.age18to59) > 100) {
    //         this.setState({ age18to59_Error: "Invalid Number" });
    //         this.age18to59Ref.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ age18to59_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ age18to59_Error: "" });
    // }

    // if (this.state.age60andUp !== "" && this.state.age60andUp !== null && this.state.age60andUp !== undefined) {
    //     if (/^\d+$/.test(this.state.age60andUp) !== true || parseInt(this.state.age60andUp) > 100) {
    //         this.setState({ age60andUp_Error: "Invalid Age 60+" });
    //         this.age60andUpRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ age60andUp_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ age60andUp_Error: "" });
    // }

    if (
      this.state.applicantDetails.countAdult !== "" &&
      this.state.applicantDetails.countAdult !== null &&
      this.state.applicantDetails.countAdult !== undefined
    ) {
      if (
        /^\d+$/.test(this.state.applicantDetails.countAdult) !== true ||
        parseInt(this.state.applicantDetails.countAdult) > 100
      ) {
        this.setState({ countAdult_Error: "Invalid Number" });
        this.countAdultRef.current.focus();

        validationError = true;
      } else {
        this.setState({ countAdult_Error: "" });
      }
    } else {
      this.setState({ countAdult_Error: "" });
    }

    if (
      this.state.applicantDetails.countChildren !== "" &&
      this.state.applicantDetails.countChildren !== null &&
      this.state.applicantDetails.countChildren !== undefined
    ) {
      if (
        /^\d+$/.test(this.state.applicantDetails.countChildren) !== true ||
        parseInt(this.state.applicantDetails.countChildren) > 100
      ) {
        this.setState({ countChildren_Error: "Invalid Number" });
        this.countChildrenRef.current.focus();

        validationError = true;
      } else {
        this.setState({ countChildren_Error: "" });
      }
    } else {
      this.setState({ countChildren_Error: "" });
    }

    // if (this.state.cardValue !== "" && this.state.cardValue !== null && this.state.cardValue !== undefined) {
    //     if (/^\d+$/.test(this.state.cardValue) !== true) {
    //         this.setState({ cardValue_Error: "Invalid Card Value" });
    //         this.cardValueRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ cardValue_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ cardValue_Error: "" });
    // }

    // if (this.state.spaAssignmentNo !== "" && this.state.spaAssignmentNo !== null && this.state.spaAssignmentNo !== undefined) {
    //     if (/^\d+$/.test(this.state.spaAssignmentNo) !== true) {
    //         this.setState({ spaAssignmentNo_Error: "Invalid SPA Assignment Number" });
    //         this.spaAssignmentNoRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ spaAssignmentNo_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ spaAssignmentNo_Error: "" });
    // }

    if (
      this.state.applicantDetails.transactionID !== "" &&
      this.state.applicantDetails.transactionID !== null &&
      this.state.applicantDetails.transactionID !== undefined
    ) {
      if (/^\d+$/.test(this.state.applicantDetails.transactionID) !== true) {
        this.setState({ transactionID_Error: "Invalid Transaction ID" });
        this.transactionIDRef.current.focus();

        validationError = true;
      } else {
        this.setState({ transactionID_Error: "" });
      }
    } else {
      this.setState({ transactionID_Error: "" });
    }

    if (
      this.state.applicantDetails.suffix !== "" &&
      this.state.applicantDetails.suffix !== null &&
      this.state.applicantDetails.suffix !== undefined
    ) {
      if (this.state.applicantDetails.suffix.length > 10) {
        this.setState({ suffix_Error: "Suffix too long" });
        this.suffixRef.current.focus();

        validationError = true;
      } else {
        this.setState({ suffix_Error: "" });
      }
    } else {
      this.setState({ suffix_Error: "" });
    }

    if (
      this.state.applicantDetails.middleName !== "" &&
      this.state.applicantDetails.middleName !== null &&
      this.state.applicantDetails.middleName !== undefined
    ) {
      if (this.state.applicantDetails.middleName.length > 100) {
        this.setState({ middleName_Error: "Name too long" });
        this.middleNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ middleName_Error: "" });
      }
    } else {
      this.setState({ middleName_Error: "" });
    }

    if (
      this.state.applicantDetails.agent !== "" &&
      this.state.applicantDetails.agent !== null &&
      this.state.applicantDetails.agent !== undefined
    ) {
      if (this.state.applicantDetails.agent.length > 100) {
        this.setState({ agent_Error: "Name too long" });
        this.agentRef.current.focus();

        validationError = true;
      } else {
        this.setState({ agent_Error: "" });
      }
    } else {
      this.setState({ agent_Error: "" });
    }

    if (
      this.state.applicantDetails.doYouRent !== "" &&
      this.state.applicantDetails.doYouRent !== null &&
      this.state.applicantDetails.doYouRent !== undefined
    ) {
      if (this.state.applicantDetails.doYouRent.length > 100) {
        this.setState({ doYouRent_Error: "String too long" });
        this.doYouRentRef.current.focus();

        validationError = true;
      } else {
        this.setState({ doYouRent_Error: "" });
      }
    } else {
      this.setState({ doYouRent_Error: "" });
    }

    if (
      this.state.applicantDetails.receiveAnyHousingSubsidy !== "" &&
      this.state.applicantDetails.receiveAnyHousingSubsidy !== null &&
      this.state.applicantDetails.receiveAnyHousingSubsidy !== undefined
    ) {
      if (this.state.applicantDetails.receiveAnyHousingSubsidy.length > 100) {
        this.setState({ receiveAnyHousingSubsidy_Error: "String too long" });
        this.receiveAnyHousingSubsidyRef.current.focus();

        validationError = true;
      } else {
        this.setState({ receiveAnyHousingSubsidy_Error: "" });
      }
    } else {
      this.setState({ receiveAnyHousingSubsidy_Error: "" });
    }

    // if (this.state.isAnnualIncomeLess !== "" && this.state.isAnnualIncomeLess !== null && this.state.isAnnualIncomeLess !== undefined) {
    //     if (this.state.isAnnualIncomeLess.length > 100) {
    //         this.setState({ isAnnualIncomeLess_Error: "String too long" });
    //         this.isAnnualIncomeLessRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ isAnnualIncomeLess_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ isAnnualIncomeLess_Error: "" });
    // }

    if (
      this.state.applicantDetails.isSomeoneOnRentalOrLeaseAgreement !== "" &&
      this.state.applicantDetails.isSomeoneOnRentalOrLeaseAgreement !== null &&
      this.state.applicantDetails.isSomeoneOnRentalOrLeaseAgreement !==
      undefined
    ) {
      if (
        this.state.applicantDetails.isSomeoneOnRentalOrLeaseAgreement.length >
        100
      ) {
        this.setState({
          isSomeoneOnRentalOrLeaseAgreement_Error: "String too long",
        });
        this.isSomeoneOnRentalOrLeaseAgreementRef.current.focus();

        validationError = true;
      } else {
        this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
      }
    } else {
      this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
    }

    if (
      this.state.applicantDetails.filedCovid19FinancialStressDeclaration !==
      "" &&
      this.state.applicantDetails.filedCovid19FinancialStressDeclaration !==
      null &&
      this.state.applicantDetails.filedCovid19FinancialStressDeclaration !==
      undefined
    ) {
      if (
        this.state.applicantDetails.filedCovid19FinancialStressDeclaration
          .length > 100
      ) {
        this.setState({
          filedCovid19FinancialStressDeclaration_Error: "String too long",
        });
        this.filedCovid19FinancialStressDeclarationRef.current.focus();

        validationError = true;
      } else {
        this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
      }
    } else {
      this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
    }

    if (
      this.state.applicantDetails.receivedEvictionNotice !== "" &&
      this.state.applicantDetails.receivedEvictionNotice !== null &&
      this.state.applicantDetails.receivedEvictionNotice !== undefined
    ) {
      if (this.state.applicantDetails.receivedEvictionNotice.length > 100) {
        this.setState({ receivedEvictionNotice_Error: "String too long" });
        this.receivedEvictionNoticeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ receivedEvictionNotice_Error: "" });
      }
    } else {
      this.setState({ receivedEvictionNotice_Error: "" });
    }

    if (
      this.state.applicantDetails.dateEvictionNoticeReceived !== "" &&
      this.state.applicantDetails.dateEvictionNoticeReceived !== null &&
      this.state.applicantDetails.dateEvictionNoticeReceived !== undefined
    ) {
      if (this.state.applicantDetails.dateEvictionNoticeReceived.length > 100) {
        this.setState({ dateEvictionNoticeReceived_Error: "String too long" });
        this.dateEvictionNoticeReceivedRef.current.focus();

        validationError = true;
      } else {
        this.setState({ dateEvictionNoticeReceived_Error: "" });
      }
    } else {
      this.setState({ dateEvictionNoticeReceived_Error: "" });
    }

    if (
      this.state.applicantDetails.SSN !== "" &&
      this.state.applicantDetails.SSN !== null &&
      this.state.applicantDetails.SSN !== undefined
    ) {
      if (
        this.state.applicantDetails.SSN.length < 9 ||
        /^\d+$/.test(this.state.applicantDetails.SSN) !== true
      ) {
        this.setState({ SSN_Error: "Invalid SSN Number" });
        this.SSNRef.current.focus();

        validationError = true;
      } else {
        this.setState({ SSN_Error: "" });
      }
    } else {
      this.setState({ SSN_Error: "" });
    }

    if (
      this.state.applicantDetails.socialSecurityFirstName !== "" &&
      this.state.applicantDetails.socialSecurityFirstName !== null &&
      this.state.applicantDetails.socialSecurityFirstName !== undefined
    ) {
      if (this.state.applicantDetails.socialSecurityFirstName.length > 100) {
        this.setState({ socialSecurityFirstName_Error: "String too long" });
        this.socialSecurityFirstNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ socialSecurityFirstName_Error: "" });
      }
    } else {
      this.setState({ socialSecurityFirstName_Error: "" });
    }

    if (
      this.state.applicantDetails.socialSecurityLastName !== "" &&
      this.state.applicantDetails.socialSecurityLastName !== null &&
      this.state.applicantDetails.socialSecurityLastName !== undefined
    ) {
      if (this.state.applicantDetails.socialSecurityLastName.length > 100) {
        this.setState({ socialSecurityLastName_Error: "String too long" });
        this.socialSecurityLastNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ socialSecurityLastName_Error: "" });
      }
    } else {
      this.setState({ socialSecurityLastName_Error: "" });
    }

    if (
      this.state.applicantDetails.birthdayCode !== "" &&
      this.state.applicantDetails.birthdayCode !== null &&
      this.state.applicantDetails.birthdayCode !== undefined
    ) {
      if (this.state.applicantDetails.birthdayCode.length > 255) {
        this.setState({ birthdayCode_Error: "String too long" });
        this.birthdayCodeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ birthdayCode_Error: "" });
      }
    } else {
      this.setState({ birthdayCode_Error: "" });
    }

    if (
      this.state.applicantDetails.age !== "" &&
      this.state.applicantDetails.age !== null &&
      this.state.applicantDetails.age !== undefined
    ) {
      if (/^\d+$/.test(this.state.applicantDetails.age) !== true) {
        this.setState({ age_Error: "Invalid Number" });
        this.ageRef.current.focus();

        validationError = true;
      } else {
        this.setState({ age_Error: "" });
      }
    } else {
      this.setState({ age_Error: "" });
    }

    if (
      this.state.applicantDetails.otherPhone !== "" &&
      this.state.applicantDetails.otherPhone !== null &&
      this.state.applicantDetails.otherPhone !== undefined
    ) {
      if (this.state.applicantDetails.otherPhone.length < 10) {
        this.setState({ otherPhone_Error: "Invalid Phone Number" });
        this.otherPhoneRef.current.focus();

        validationError = true;
      } else {
        this.setState({ otherPhone_Error: "" });
      }
    } else {
      this.setState({ otherPhone_Error: "" });
    }

    if (
      this.state.applicantDetails.secondaryFirstName !== "" &&
      this.state.applicantDetails.secondaryFirstName !== null &&
      this.state.applicantDetails.secondaryFirstName !== undefined
    ) {
      if (this.state.applicantDetails.secondaryFirstName.length > 100) {
        this.setState({ secondaryFirstName_Error: "String too long" });
        this.secondaryFirstNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ secondaryFirstName_Error: "" });
      }
    } else {
      this.setState({ secondaryFirstName_Error: "" });
    }

    if (
      this.state.applicantDetails.secondaryLastName !== "" &&
      this.state.applicantDetails.secondaryLastName !== null &&
      this.state.applicantDetails.secondaryLastName !== undefined
    ) {
      if (this.state.applicantDetails.secondaryLastName.length > 100) {
        this.setState({ secondaryLastName_Error: "String too long" });
        this.secondaryLastNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ secondaryLastName_Error: "" });
      }
    } else {
      this.setState({ secondaryLastName_Error: "" });
    }

    if (
      this.state.applicantDetails.secondaryEmail !== "" &&
      this.state.applicantDetails.secondaryEmail !== null &&
      this.state.applicantDetails.secondaryEmail !== undefined
    ) {
      var secondaryEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !secondaryEmailPattern.test(this.state.applicantDetails.secondaryEmail)
      ) {
        this.setState({ secondaryEmail_Error: "Invalid Email" });
        this.secondaryEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ secondaryEmail_Error: "" });
      }
    } else {
      this.setState({ secondaryEmail_Error: "" });
    }

    if (
      this.state.applicantDetails.secondaryPhone !== "" &&
      this.state.applicantDetails.secondaryPhone !== null &&
      this.state.applicantDetails.secondaryPhone !== undefined
    ) {
      if (this.state.applicantDetails.secondaryPhone.length < 10) {
        this.setState({ secondaryPhone_Error: "Invalid Phone Number" });
        this.secondaryPhoneRef.current.focus();

        validationError = true;
      } else {
        this.setState({ secondaryPhone_Error: "" });
      }
    } else {
      this.setState({ secondaryPhone_Error: "" });
    }

    if (
      this.state.applicantDetails.householdID !== "" &&
      this.state.applicantDetails.householdID !== null &&
      this.state.applicantDetails.householdID !== undefined
    ) {
      if (/^\d+$/.test(this.state.applicantDetails.householdID) !== true) {
        this.setState({ householdID_Error: "Invalid Household ID" });
        this.householdIDRef.current.focus();

        validationError = true;
      } else {
        this.setState({ householdID_Error: "" });
      }
    } else {
      this.setState({ householdID_Error: "" });
    }

    if (
      this.state.applicantDetails.landlordPhoneNumber !== "" &&
      this.state.applicantDetails.landlordPhoneNumber !== null &&
      this.state.applicantDetails.landlordPhoneNumber !== undefined
    ) {
      if (this.state.applicantDetails.landlordPhoneNumber.length < 10) {
        this.setState({ landlordPhoneNumber_Error: "Invalid Phone Number" });
        this.landlordPhoneNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordPhoneNumber_Error: "" });
      }
    } else {
      this.setState({ landlordPhoneNumber_Error: "" });
    }

    if (
      this.state.applicantDetails.landlordEmail !== "" &&
      this.state.applicantDetails.landlordEmail !== null &&
      this.state.applicantDetails.landlordEmail !== undefined
    ) {
      var landlordEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !landlordEmailPattern.test(this.state.applicantDetails.landlordEmail)
      ) {
        this.setState({ landlordEmail_Error: "Invalid Email" });
        this.landlordEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordEmail_Error: "" });
      }
    } else {
      this.setState({ landlordEmail_Error: "" });
    }

    if (
      this.state.landlordDetails.entityAmountDue !== "" &&
      this.state.landlordDetails.entityAmountDue !== null &&
      this.state.landlordDetails.entityAmountDue !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(this.state.landlordDetails.entityAmountDue) !==
        true
      ) {
        this.setState({ amountDueforRent_Error: "Invalid Amount" });
        this.amountDueforRentRef.current.focus();

        validationError = true;
      } else {
        this.setState({ amountDueforRent_Error: "" });
      }
    } else {
      this.setState({ amountDueforRent_Error: "" });
    }

    if (
      this.state.landlordDetails.entityAmount !== "" &&
      this.state.landlordDetails.entityAmount !== null &&
      this.state.landlordDetails.entityAmount !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(this.state.landlordDetails.entityAmount) !==
        true
      ) {
        this.setState({ rentAmount_Error: "Invalid Amount" });
        this.rentAmountRef.current.focus();

        validationError = true;
      } else {
        this.setState({ rentAmount_Error: "" });
      }
    } else {
      this.setState({ rentAmount_Error: "" });
    }

    if (this.state.landlordDetails.entityPaymentStatus === "N/A") {
      if (
        this.state.landlordDetails.reasonPaymentNotProcessedIn21Days === "" ||
        this.state.landlordDetails.reasonPaymentNotProcessedIn21Days === null ||
        this.state.landlordDetails.reasonPaymentNotProcessedIn21Days ===
        undefined
      ) {
        this.setState({
          reasonPaymentNotProcessedIn21Days_Error:
            "Reason required for Rent Payment Status as N/A",
        });
        this.reasonPaymentNotProcessedIn21DaysRef.current.focus();

        validationError = true;
      } else {
        this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
      }
    } else {
      this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
    }

    // if (this.state.landlordAccountNumber !== "" && this.state.landlordAccountNumber !== null && this.state.landlordAccountNumber !== undefined) {
    //     if (/^\d+$/.test(this.state.landlordAccountNumber) !== true) {
    //         this.setState({ landlordAccountNumber_Error: "Invalid Account Number" });
    //         this.landlordAccountNumberRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ landlordAccountNumber_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ landlordAccountNumber_Error: "" });
    // }

    if (
      this.state.applicantDetails.landlordCity !== "" &&
      this.state.applicantDetails.landlordCity !== null &&
      this.state.applicantDetails.landlordCity !== undefined
    ) {
      var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
      if (!namePattern.test(this.state.applicantDetails.landlordCity)) {
        this.setState({ landlordCity_Error: "Invalid City" });
        this.landlordCityRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordCity_Error: "" });
      }
    } else {
      this.setState({ landlordCity_Error: "" });
    }

    // state
    // country

    if (
      this.state.applicantDetails.landlordZipcode !== "" &&
      this.state.applicantDetails.landlordZipcode !== null &&
      this.state.applicantDetails.landlordZipcode !== undefined
    ) {
      if (
        this.state.applicantDetails.landlordZipcode.length < 5 ||
        /^\d+$/.test(this.state.applicantDetails.landlordZipcode) !== true
      ) {
        this.setState({ landlordZipcode_Error: "Invalid Zip" });
        this.landlordZipcodeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordZipcode_Error: "" });
      }
    } else {
      this.setState({ landlordZipcode_Error: "" });
    }

    if (
      this.state.applicantDetails.landlordRoutingNumber !== "" &&
      this.state.applicantDetails.landlordRoutingNumber !== null &&
      this.state.applicantDetails.landlordRoutingNumber !== undefined
    ) {
      if (
        this.state.applicantDetails.landlordRoutingNumber.length < 9 ||
        /^\d+$/.test(this.state.applicantDetails.landlordRoutingNumber) !== true
      ) {
        this.setState({
          landlordRoutingNumber_Error: "Invalid Routing Number",
        });
        this.landlordRoutingNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordRoutingNumber_Error: "" });
      }
    } else {
      this.setState({ landlordRoutingNumber_Error: "" });
    }

    if (
      this.state.applicantDetails.currentTotalHouseholdIncome !== "" &&
      this.state.applicantDetails.currentTotalHouseholdIncome !== null &&
      this.state.applicantDetails.currentTotalHouseholdIncome !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(
          this.state.applicantDetails.currentTotalHouseholdIncome
        ) !== true
      ) {
        this.setState({ currentTotalHouseholdIncome_Error: "Invalid Amount" });
        this.currentTotalHouseholdIncomeRef.current.focus();

        validationError = true;
      } else if (
        this.state.applicantDetails.currentTotalHouseholdIncome > 9999999.99
      ) {
        this.setState({ currentTotalHouseholdIncome_Error: "Exceeds limit" });
        this.currentTotalHouseholdIncomeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ currentTotalHouseholdIncome_Error: "" });
      }
    } else {
      this.setState({ currentTotalHouseholdIncome_Error: "" });
    }

    if (
      this.state.applicantDetails.spaServiceProviderEmail !== "" &&
      this.state.applicantDetails.spaServiceProviderEmail !== null &&
      this.state.applicantDetails.spaServiceProviderEmail !== undefined
    ) {
      var spaServiceProviderEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !spaServiceProviderEmailPattern.test(
          this.state.applicantDetails.spaServiceProviderEmail
        )
      ) {
        this.setState({ spaServiceProviderEmail_Error: "Invalid Email" });
        this.spaServiceProviderEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ spaServiceProviderEmail_Error: "" });
      }
    } else {
      this.setState({ spaServiceProviderEmail_Error: "" });
    }

    if (
      this.state.rentPaymentStatus_Original !==
      this.state.landlordDetails.entityPaymentStatus &&
      this.state.landlordDetails.entityPaymentStatus === "Issued"
    ) {
      // if (this.state.applicantDetails.landlordEmail === null || this.state.applicantDetails.landlordEmail === "" || this.state.applicantDetails.landlordEmail === undefined) {
      //     this.setState({ landlordEmail_Error: "Email required" });
      //     this.landlordEmailRef.current.focus();

      //     validationError = true;
      // }
      // else {
      //     this.setState({ landlordEmail_Error: "" });
      // }

      if (
        this.state.applicantDetails.spaServiceProviderEmail === null ||
        this.state.applicantDetails.spaServiceProviderEmail === "" ||
        this.state.applicantDetails.spaServiceProviderEmail === undefined
      ) {
        this.setState({ spaServiceProviderEmail_Error: "Email required" });
        this.spaServiceProviderEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ spaServiceProviderEmail_Error: "" });
      }

      if (
        this.state.applicantDetails.firstName === "" ||
        this.state.applicantDetails.firstName === null ||
        this.state.applicantDetails.firstName === undefined
      ) {
        this.setState({ firstName_Error: "First Name required" });
        this.firstNameRef.current.focus();

        validationError = true;
      } else {
        this.setState({ firstName_Error: "" });
      }

      if (
        this.state.applicantProperty.addressLine1 === "" ||
        this.state.applicantProperty.addressLine1 === null ||
        this.state.applicantProperty.addressLine1 === undefined
      ) {
        this.setState({ addressLine1_Error: "Address required" });
        this.addressLine1Ref.current.focus();

        validationError = true;
      } else {
        this.setState({ addressLine1_Error: "" });
      }

      if (
        this.state.applicantProperty.city === "" ||
        this.state.applicantProperty.city === null ||
        this.state.applicantProperty.city === undefined
      ) {
        this.setState({ city_Error: "City required" });
        this.cityRef.current.focus();

        validationError = true;
      } else {
        this.setState({ city_Error: "" });
      }

      if (
        this.state.applicantProperty.state === "" ||
        this.state.applicantProperty.state === null ||
        this.state.applicantProperty.state === undefined
      ) {
        this.setState({ state_Error: "State required" });
        this.stateRef.current.focus();

        validationError = true;
      } else {
        this.setState({ state_Error: "" });
      }

      if (
        this.state.applicantProperty.zipcode === "" ||
        this.state.applicantProperty.zipcode === null ||
        this.state.applicantProperty.zipcode === undefined
      ) {
        this.setState({ zipcode_Error: "Zip required" });
        this.zipcodeRef.current.focus();

        validationError = true;
      } else {
        this.setState({ zipcode_Error: "" });
      }

      // if (this.state.uniqueID === "" || this.state.uniqueID === null || this.state.uniqueID === undefined) {
      //     this.setState({ uniqueID_Error: "Unique ID required" });
      //     this.uniqueIDRef.current.focus();

      //     validationError = true;
      // }
      // else {
      //     this.setState({ uniqueID_Error: "" });
      // }
    }

    if (
      this.state.applicantDetails.noOfTimesParticipated !== "" &&
      this.state.applicantDetails.noOfTimesParticipated !== null &&
      this.state.applicantDetails.noOfTimesParticipated !== undefined
    ) {
      if (
        /^\d+$/.test(this.state.applicantDetails.noOfTimesParticipated) !== true
      ) {
        this.setState({ noOfTimesParticipated_Error: "Invalid Number" });
        this.noOfTimesParticipatedRef.current.focus();

        validationError = true;
      } else {
        this.setState({ noOfTimesParticipated_Error: "" });
      }
    } else {
      this.setState({ noOfTimesParticipated_Error: "" });
    }

    if (
      this.state.landlordDetails.landlordSSN !== "" &&
      this.state.landlordDetails.landlordSSN !== null &&
      this.state.landlordDetails.landlordSSN !== undefined
    ) {
      if (
        this.state.landlordDetails.landlordSSN.length < 9 ||
        /^\d+$/.test(this.state.landlordDetails.landlordSSN) !== true
      ) {
        this.setState({ landlordSSN_Error: "Invalid SSN Number" });
        this.landlordSSNRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordSSN_Error: "" });
      }
    } else {
      this.setState({ landlordSSN_Error: "" });
    }

    if (
      this.state.landlordDetails.entityTaxID !== "" &&
      this.state.landlordDetails.entityTaxID !== null &&
      this.state.landlordDetails.entityTaxID !== undefined
    ) {
      if (
        this.state.landlordDetails.entityTaxID.length < 9 ||
        /^\d+$/.test(this.state.landlordDetails.entityTaxID) !== true
      ) {
        this.setState({ landlordTaxID_Error: "Invalid Tax ID" });
        this.landlordTaxIDRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordTaxID_Error: "" });
      }
    } else {
      this.setState({ landlordTaxID_Error: "" });
    }

    if (
      this.state.landlordDetails.entityDUNSNumber !== "" &&
      this.state.landlordDetails.entityDUNSNumber !== null &&
      this.state.landlordDetails.entityDUNSNumber !== undefined
    ) {
      if (
        this.state.landlordDetails.entityDUNSNumber.length < 9 ||
        /^\d+$/.test(this.state.landlordDetails.entityDUNSNumber) !== true
      ) {
        this.setState({ landlordDUNSNumber_Error: "Invalid DUNS Number" });
        this.landlordDUNSNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordDUNSNumber_Error: "" });
      }
    } else {
      this.setState({ landlordDUNSNumber_Error: "" });
    }

    if (
      this.state.landlordDetails.entityMonthlyAmount !== "" &&
      this.state.landlordDetails.entityMonthlyAmount !== null &&
      this.state.landlordDetails.entityMonthlyAmount !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(
          this.state.landlordDetails.entityMonthlyAmount
        ) !== true
      ) {
        this.setState({ monthlyRent_Error: "Invalid Amount" });
        this.monthlyRentRef.current.focus();

        validationError = true;
      } else if (this.state.landlordDetails.entityMonthlyAmount > 99999.99) {
        this.setState({ monthlyRent_Error: "Exceeds limit" });
        this.monthlyRentRef.current.focus();

        validationError = true;
      } else {
        this.setState({ monthlyRent_Error: "" });
      }
    } else {
      this.setState({ monthlyRent_Error: "" });
    }

    // if (this.state.percentageOfMonthlyRent !== "" && this.state.percentageOfMonthlyRent !== null && this.state.percentageOfMonthlyRent !== undefined) {
    //     if (/^\d+$/.test(this.state.percentageOfMonthlyRent) !== true) {
    //         this.setState({ percentageOfMonthlyRent_Error: "Invalid Number" });
    //         this.percentageOfMonthlyRentRef.current.focus();

    //         validationError = true;
    //     }
    //     else {
    //         this.setState({ percentageOfMonthlyRent_Error: "" });
    //     }
    // }
    // else {
    //     this.setState({ percentageOfMonthlyRent_Error: "" });
    // }

    if (
      this.state.landlordDetails.entityNoOfMonthsUtilityPayments !== "" &&
      this.state.landlordDetails.entityNoOfMonthsUtilityPayments !== null &&
      this.state.landlordDetails.entityNoOfMonthsUtilityPayments !== undefined
    ) {
      if (
        /^\d+$/.test(
          this.state.landlordDetails.entityNoOfMonthsUtilityPayments
        ) !== true
      ) {
        this.setState({ noOfMonthsRentalPayments_Error: "Invalid Number" });
        this.noOfMonthsRentalPaymentsRef.current.focus();

        validationError = true;
      } else {
        this.setState({ noOfMonthsRentalPayments_Error: "" });
      }
    } else {
      this.setState({ noOfMonthsRentalPayments_Error: "" });
    }

    if (
      this.state.landlordDetails.outstandingRentalArrearsAmount !== "" &&
      this.state.landlordDetails.outstandingRentalArrearsAmount !== null &&
      this.state.landlordDetails.outstandingRentalArrearsAmount !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(
          this.state.landlordDetails.outstandingRentalArrearsAmount
        ) !== true
      ) {
        this.setState({
          outstandingRentalArrearsAmount_Error: "Invalid Amount",
        });
        this.outstandingRentalArrearsAmountRef.current.focus();

        validationError = true;
      } else if (
        this.state.landlordDetails.outstandingRentalArrearsAmount > 99999.99
      ) {
        this.setState({
          outstandingRentalArrearsAmount_Error: "Exceeds limit",
        });
        this.outstandingRentalArrearsAmountRef.current.focus();

        validationError = true;
      } else {
        this.setState({ outstandingRentalArrearsAmount_Error: "" });
      }
    } else {
      this.setState({ outstandingRentalArrearsAmount_Error: "" });
    }

    if (
      this.state.applicantDetails.effectOfCovid19Financially === "Other" &&
      this.state.applicantDetails.covid_other !== "" &&
      this.state.applicantDetails.covid_other !== null &&
      this.state.applicantDetails.covid_other !== undefined
    ) {
      if (this.state.applicantDetails.covid_other.length > 100) {
        this.setState({ covid_other_Error: "Text too long" });
        this.covid_otherRef.current.focus();

        validationError = true;
      } else {
        this.setState({ covid_other_Error: "" });
      }
    } else {
      this.setState({ covid_other_Error: "" });
    }

    if (
      this.state.applicantDetails.workIndustry === "Other" &&
      this.state.applicantDetails.industry_other !== "" &&
      this.state.applicantDetails.industry_other !== null &&
      this.state.applicantDetails.industry_other !== undefined
    ) {
      if (this.state.applicantDetails.industry_other.length > 100) {
        this.setState({ industry_other_Error: "Text too long" });
        this.industry_otherRef.current.focus();

        validationError = true;
      } else {
        this.setState({ industry_other_Error: "" });
      }
    } else {
      this.setState({ industry_other_Error: "" });
    }

    if (
      this.state.applicantDetails.tenantEmail !== "" &&
      this.state.applicantDetails.tenantEmail !== null &&
      this.state.applicantDetails.tenantEmail !== undefined
    ) {
      var tenantEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!tenantEmailPattern.test(this.state.applicantDetails.tenantEmail)) {
        this.setState({ tenantEmail_Error: "Invalid Email" });
        this.tenantEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ tenantEmail_Error: "" });
      }
    } else {
      this.setState({ tenantEmail_Error: "" });
    }

    if (
      this.state.individualDetails.entityAmount !== "" &&
      this.state.individualDetails.entityAmount !== null &&
      this.state.individualDetails.entityAmount !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(this.state.individualDetails.entityAmount) !==
        true
      ) {
        this.setState({ individualPaymentAmount_Error: "Invalid Amount" });
        this.individualPaymentAmountRef.current.focus();

        validationError = true;
      } else {
        this.setState({ individualPaymentAmount_Error: "" });
      }
    } else {
      this.setState({ individualPaymentAmount_Error: "" });
    }

    if (
      this.state.individualDetails.entityMemoPropertyAddress !== "" &&
      this.state.individualDetails.entityMemoPropertyAddress !== null &&
      this.state.individualDetails.entityMemoPropertyAddress !== undefined
    ) {
      if (this.state.individualDetails.entityMemoPropertyAddress.length > 100) {
        this.setState({
          individualMemoPropertyAddress_Error: "Address too long",
        });
        this.individualMemoPropertyAddressRef.current.focus();

        validationError = true;
      } else {
        this.setState({ individualMemoPropertyAddress_Error: "" });
      }
    } else {
      this.setState({ individualMemoPropertyAddress_Error: "" });
    }

    if (
      this.state.applicantDetails.notes !== "" &&
      this.state.applicantDetails.notes !== null &&
      this.state.applicantDetails.notes !== undefined
    ) {
      if (this.state.applicantDetails.notes.length > 15000) {
        this.setState({ notes_Error: "Notes too long" });
        this.notesRef.current.focus();

        validationError = true;
      } else {
        this.setState({ notes_Error: "" });
      }
    } else {
      this.setState({ notes_Error: "" });
    }

    // Payment section validation - Start

    if (
      this.state.landlordpaymentDetails.entityAccountNumber !== "" &&
      this.state.landlordpaymentDetails.entityAccountNumber !== null &&
      this.state.landlordpaymentDetails.entityAccountNumber !== undefined &&
      this.state.isTransactionPermission
    ) {
      if ( /^\d+$/.test(this.state.landlordpaymentDetails.entityAccountNumber) !== true ) {
        this.setState({ landlordpaymentDetailsEntityAccountNumber_Error: "Invalid bank account number" });
        this.landlordpaymentDetailsEntityAccountNumberRef.current.focus();
        validationError = true;
      } else {
        this.setState({ landlordpaymentDetailsEntityAccountNumber_Error: "" });
      }
    } else {
      this.setState({ landlordpaymentDetailsEntityAccountNumber_Error: "" });
    }

    if (
      this.state.landlordpaymentDetails.entityRoutingNumber !== "" &&
      this.state.landlordpaymentDetails.entityRoutingNumber !== null &&
      this.state.landlordpaymentDetails.entityRoutingNumber !== undefined &&
      this.state.isTransactionPermission
    ) {
      if ( /^[a-zA-Z0-9]*$/.test(this.state.landlordpaymentDetails.entityRoutingNumber) !== true || this.state.landlordpaymentDetails.entityRoutingNumber.length !== 9) {
        this.setState({ landlordpaymentDetailsEntityRoutingNumber_Error: "Invalid bank routing number" });
        this.landlordpaymentDetailsEntityRoutingNumberRef.current.focus();
        validationError = true;
      } else {
        this.setState({ landlordpaymentDetailsEntityRoutingNumber_Error: "" });
      }
    } else {
      this.setState({ landlordpaymentDetailsEntityRoutingNumber_Error: "" });
    }
    let isValuePresent = (this.state.landlordpaymentDetailsClone.entityAccountNumber !== "" && 
      this.state.landlordpaymentDetailsClone.entityAccountNumber !== null && 
      this.state.landlordpaymentDetailsClone.entityAccountNumber !== undefined) || 
      (this.state.landlordpaymentDetailsClone.entityRoutingNumber !== "" && 
      this.state.landlordpaymentDetailsClone.entityRoutingNumber !== null && 
      this.state.landlordpaymentDetailsClone.entityRoutingNumber !== undefined)

    if (this.state.landlordpaymentDetails.transactionType === 'ACH' &&
      this.state.landlordpaymentDetails.informationStatus === 'Ready' &&
      (this.state.applicantDetails.receiverCompany === "" ||
        this.state.applicantDetails.receiverCompany === null ||
        this.state.applicantDetails.receiverCompany === undefined) && isValuePresent &&
        this.state.landlordpaymentDetailsClone.informationStatus !== 'Ready'   
    ) {
      setTimeout(() => {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          //snackbarSeverity: "error",
          snackbarMessage:
            "The ACH details are not rightly verified or are pending. Please ask Landlord to add correct ACH details.",
        });
      }, 10);

      //this.setState({ landlordSSN_Error: "Invalid SSN Number" });
      this.informationStatusRef.current.focus();
      this.setState({ landlordpaymentInformationStatus_Error: "Right banking information expected" });
      validationError = true;
    } else {
      //this.setState({ landlordSSN_Error: "" });
      this.setState({ landlordpaymentInformationStatus_Error: "" });
    }

    // Payment section validation - End

    ///////////////////////////   VALIDATION ENDS   ///////////////////////////

    if (validationError === true) {
      this.setState({ isLoading: false });
      return false;
    }

    return true;
  };

  encryptData = async (data) => {
    const { result } = await encrypt(this.state.keyring, util_utf8_node.fromUtf8(data));
    return toBase64(result);
  }

  decryptData = async (data) => {
    const { plaintext, messageHeader } = await decrypt(this.state.keyring, fromBase64(data));
    return util_utf8_node.toUtf8(plaintext);
  }

  setKmsKeyring = async () => {
    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = '/getuisettings';
    let myInit = {
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
      }
    }
    let res1 = await API.get(apiName, path, myInit);
    let accessKeyId = res1 ? await decryptValue(res1.a) : '';
    let secretAccessKey = res1 ? await decryptValue(res1.b) : '';
    AWS.config.credentials = new AWS.Credentials(
      accessKeyId,
      secretAccessKey
    );
    //AWS.config.region = process.env.REACT_APP_Region;
    let AWSCreds = await AWS.config.credentials.get();
    /* The KMS keyring must be configured with the desired CMKs */
    let clientProvider = getClient(KMS, {
      AWSCreds,
      region: process.env.REACT_APP_Region
    });

    let keyring = new KmsKeyringBrowser({
      clientProvider,
      generatorKeyId: process.env.REACT_APP_AWS_KMS_KeyId
    });
    keyring.Credentials = {
      AccessKeyId: accessKeyId,
      SecretAccessKey: secretAccessKey
    };
    return keyring;
  }

  loadIndividualDetails = async (searchParam) => {
    this.resetValues();
    this.forceUpdate();

    this.setState({
      isLoading: true,
      individualID: searchParam.split("+")[0],
      fundingSource: searchParam.split("+")[1],
    });

    // check the current user when the App component is loaded
    var adminUser = "";
    Auth.currentAuthenticatedUser()
      .then((user) => {
        adminUser = user.attributes.email;
        this.setState({ authState: "signedIn" });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ authState: "signIn" });
      });

    await this.loadData();

    // Set the logged-in admin user
    this.setState({ adminUsername: adminUser });

    // Restore the Issued Payment Status if the Case is not open.
    if (
      this.state.applicantDetails.individualCase !== "Open" &&
      this.state.applicantDetails.individualCase !== "Ineligible" &&
      this.state.applicantDetails.individualCase !== "" &&
      this.state.applicantDetails.individualCase !== null &&
      this.state.applicantDetails.individualCase !== undefined
    ) {
      var rentPaymentStatusSelect = document.getElementById(
        "rentPaymentStatusId"
      );
      if (!(rentPaymentStatusSelect.options.length > 5)) {
        var rentPaymentStatusIssuedOption = document.createElement("option");
        rentPaymentStatusIssuedOption.text = "Issued";
        rentPaymentStatusIssuedOption.value = "Issued";
        rentPaymentStatusSelect.appendChild(rentPaymentStatusIssuedOption);
      }
      // bank info status
      if (this.state.landlordpaymentDetails && this.state.landlordpaymentDetails.transactionType === "Check") {
        var landlordCheckStatusSelect = document.getElementById(
          "landlordCheckPaymentStatusId"
        );
        if (!(landlordCheckStatusSelect.options.length > 5)) {
          var landlordCheckStatusIssuedOption = document.createElement("option");
          landlordCheckStatusIssuedOption.text = "Issued";
          landlordCheckStatusIssuedOption.value = "Issued";
          landlordCheckStatusSelect.appendChild(landlordCheckStatusIssuedOption);
        }
      }
      if (this.state.landlordpaymentDetails && this.state.landlordpaymentDetails.transactionType === "ACH") {
        var landlordCheckStatusSelect = document.getElementById(
          "landlordAchPaymentStatusId"
        );
        if (!(landlordCheckStatusSelect.options.length > 5)) {
          var landlordCheckStatusIssuedOption = document.createElement("option");
          landlordCheckStatusIssuedOption.text = "Issued";
          landlordCheckStatusIssuedOption.value = "Issued";
          landlordCheckStatusSelect.appendChild(landlordCheckStatusIssuedOption);
        }
      }

      if (this.state.individualpaymentDetails && this.state.individualpaymentDetails.transactionType === "Check") {
        var landlordCheckStatusSelect = document.getElementById(
          "individualCheckPaymentStatusId"
        );
        if (!(landlordCheckStatusSelect.options.length > 5)) {
          var landlordCheckStatusIssuedOption = document.createElement("option");
          landlordCheckStatusIssuedOption.text = "Issued";
          landlordCheckStatusIssuedOption.value = "Issued";
          landlordCheckStatusSelect.appendChild(landlordCheckStatusIssuedOption);
        }
      }


      var individualPaymentStatusSelect = document.getElementById(
        "individualPaymentStatusId"
      );
      if (!(individualPaymentStatusSelect.options.length > 5)) {
        var individualPaymentStatusIssuedOption =
          document.createElement("option");
        individualPaymentStatusIssuedOption.text = "Issued";
        individualPaymentStatusIssuedOption.value = "Issued";
        individualPaymentStatusSelect.appendChild(
          individualPaymentStatusIssuedOption
        );
      }
    } else if (this.state.applicantDetails.individualCase === "Open") {
      var rentPaymentStatusSelect = document.getElementById(
        "rentPaymentStatusId"
      );
      if (rentPaymentStatusSelect.options.length > 5) {
        rentPaymentStatusSelect.remove(5);
      }

      var individualPaymentStatusSelect = document.getElementById(
        "individualPaymentStatusId"
      );
      if (individualPaymentStatusSelect.options.length > 5) {
        individualPaymentStatusSelect.remove(5);
      }
    } else if (this.state.applicantDetails.individualCase === "Ineligible") {
      document.getElementById("individualCaseId").disabled = false;
    }

    this.setState({ isLoading: false });
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    this.props.parentCallback("EDIT SANTA ANA APPLICATION", "", imgSearchclaim);
    try {
      this.getCurrentUserPermissions();
    } catch (error) {
      console.log(error);
      await this.signOut();
    };
    this.setState({ keyring: await this.setKmsKeyring() });
    this.loadIndividualDetails(this.props.match.params.id);
    
    // this.setState({ isLoading: false });
  };;
  
  getMiscDocument = async () => {
    try {
      var key = `SantaAna/Case/Case_${this.state.individualID}/miscellaneous/`;
      var files = await Storage.list(key);
      let doc = [];
      this.setState({ misceDocumentsList: [] });
      if (files) {
        for (var i = 0; i < files.length; i++) {

          var file1 = await Storage.get(files[i].key);
          var shortFileName = files[i].key.replace(/^.*[\\\/]/, "").split(".")[0];
          var fileExtension = files[i].key.replace(/^.*[\\\/]/, "").split(".").pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }
          doc.push({
            file: file1,
            shortFileName: shortFileName,
            lastModified: lastModified
          });
        }
        this.setState({ misceDocumentsList: doc });
      }
    } catch (err) {
      this.setState({ misceDocumentsList: [] });
    }
    
  };

  getCurrentUserPermissions = async () => {
    const { permissions } = this.props;
    if (permissions != null && permissions.length > 0) {
      if (
        permissions.includes("SantaAnaClaimUser") &&
        permissions.indexOf("SantaAnaClaim") == -1
      ) {
        document.getElementById("myset").disabled = true;
        this.setState({ mode: "User" });
      }
      if (permissions.includes('SantaAnaTransaction')) {
        this.setState({ isTransactionPermission: true });
      }
    }
  };

  loadData = async () => {
    try {
      var res = await this.adminGetIndividual();
      if (res.length === 0) {
        console.log(
          "ERROR: IndividualID is not available in System." +
          this.state.individualID
        );
        return;
      }

      let applicantData = res.body.data;
      if (res.body && res.body.paymentMonths) {
        const variableOne = res.body.paymentMonths.filter(itemInArray => itemInArray.years === '2021' && itemInArray.recId == null);
        if (variableOne && variableOne.length > 0) {
          let ids = [];
          variableOne.forEach((item, i) => {
            ids.push(item.months.toString())
          });
          this.setState({ paymentMonths2021: ids });
          this.setState({ paymentMonths2021Clone: ids });
        }
        // 2022
        const variableTwo = res.body.paymentMonths.filter(itemInArray => itemInArray.years === '2022' && itemInArray.recId == null);
        if (variableTwo && variableTwo.length > 0) {
          let ids = [];
          variableTwo.forEach((item, i) => {
            ids.push(item.months.toString())
          });
          this.setState({ paymentMonths2022: ids });
          this.setState({ paymentMonths2022Clone: ids });
        }
      }
      
      if (this.state.isTransactionPermission && applicantData && applicantData.landlordpaymentDetails) {
        let accno = applicantData.landlordpaymentDetails["entityAccountNumber"];
        let rouno = applicantData.landlordpaymentDetails["entityRoutingNumber"];
        applicantData.landlordpaymentDetails["entityAccountNumber"] = accno !== null && accno !== undefined && accno !== "" ? await this.decryptData(accno) : applicantData.landlordpaymentDetails["entityAccountNumber"];
        applicantData.landlordpaymentDetails["entityRoutingNumber"] = rouno !== null && rouno !== undefined && rouno !== "" ? await this.decryptData(rouno) : applicantData.landlordpaymentDetails["entityRoutingNumber"];
      }
      
      var recList = await this.getReconciliationList();
      if (recList && recList.length > 0) {
        this.updateAccountNoRoutingNoData(recList).then((isGetResult) => {
          if (isGetResult) {
            let dispList = recList.filter(
              (x) => x.fundingSource === this.state.fundingSource
            );
            this.setState({
              reconciliationList: dispList,
              reconciliationListByIndividualId: recList,
              reconciliationListClone: JSON.parse(JSON.stringify(dispList)),
            });
           
            this.calculateTotalFund();
            this.calculateTotal1099();
            this.calculateTotal10992022();
         
            if(this.state.reconciliationList && this.state.reconciliationList.length > 0) {
              let isLandlordResendShow = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.requestedBankInfo == 1);
              if(isLandlordResendShow.length > 0) {
                this.setState({ isLandlordResendShow: true });
              }
              let isApplicantResendShow = this.state.reconciliationList.filter(x => x.entityType == "Individual" && x.requestedBankInfo == 1);
              if(isApplicantResendShow.length > 0) {
                this.setState({ isApplicantResendShow: true });
              }
            }
          }
        });
      } 
      

      this.setState({ applicantDetails: applicantData.applicantDetails });
      this.setState({ landlordDetails: applicantData.landlordDetails });
      this.setState({ individualDetails: applicantData.individualDetails });
      this.setState({ applicantProperty: applicantData.applicantProperty });
      this.setState({ landlordProperty: applicantData.landlordProperty });

      if (applicantData.landlordpaymentDetails) {
        // let data = {...applicantData.landlordpaymentDetails,fundingSource:this.state.fundingSource,individualID:this.state.individualID }
        this.setState({
          landlordpaymentDetails: applicantData.landlordpaymentDetails,
        });
      }
      if (applicantData.individualpaymentDetails) {
        // let data={...applicantData.individualpaymentDetails,fundingSource:this.state.fundingSource,individualID:this.state.individualID }
        this.setState({
          individualpaymentDetails: applicantData.individualpaymentDetails,
        });
      }
      if (applicantData.landlordpaymentDetails) {
        // let data = {...applicantData.landlordpaymentDetails,fundingSource:this.state.fundingSource,individualID:this.state.individualID }
        this.setState({
          landlordpaymentDetailsClone: applicantData.landlordpaymentDetails,
        });
      }
      if (applicantData.individualpaymentDetails) {
        // let data={...applicantData.individualpaymentDetails,fundingSource:this.state.fundingSource,individualID:this.state.individualID }
        this.setState({
          individualpaymentDetailsClone: applicantData.individualpaymentDetails,
        });
      }
      this.setState((prevState) => ({
        landlordpaymentDetails: {
          ...prevState.landlordpaymentDetails,
          fundingSource: this.state.fundingSource,
          individualID: this.state.individualID,
          paymentId: applicantData.landlordDetails.paymentId,
        },
      }));
      this.setState((prevState) => ({
        individualpaymentDetails: {
          ...prevState.individualpaymentDetails,
          fundingSource: this.state.fundingSource,
          individualID: this.state.individualID,
          paymentId: applicantData.individualDetails.paymentId,
        },
      }));
      this.setState((prevState) => ({
        landlordpaymentDetails: {
          ...prevState.landlordpaymentDetails,
          fullAddress:
            applicantData.landlordpaymentDetails &&
              applicantData.landlordpaymentDetails.fullAddress &&
              applicantData.landlordpaymentDetails.fullAddress != ""
              ? applicantData.landlordpaymentDetails.fullAddress
              : `${this.state.landlordProperty.addressLine1},${this.state.landlordProperty.addressLine2},${this.state.landlordProperty.state},${this.state.landlordProperty.zipcode}`,
        },
      }));
      this.setState((prevState) => ({
        landlordpaymentDetailsClone: {
          ...prevState.landlordpaymentDetailsClone,
          fullAddress:
            applicantData.landlordpaymentDetails &&
              applicantData.landlordpaymentDetails.fullAddress &&
              applicantData.landlordpaymentDetails.fullAddress != ""
              ? applicantData.landlordpaymentDetails.fullAddress
              : `${this.state.landlordProperty.addressLine1},${this.state.landlordProperty.addressLine2},${this.state.landlordProperty.state},${this.state.landlordProperty.zipcode}`,
        },
      }));

      this.setState({ applicantDetailsClone: applicantData.applicantDetails });
      this.setState({ landlordDetailsClone: applicantData.landlordDetails });
      this.setState({
        individualDetailsClone: applicantData.individualDetails,
      });
      this.setState({
        applicantPropertyClone: applicantData.applicantProperty,
      });
      this.setState({ landlordPropertyClone: applicantData.landlordProperty });

      this.setState((prevState) => ({
        landlordpaymentDetailsClone: {
          ...prevState.landlordpaymentDetailsClone,
          fundingSource: this.state.fundingSource,
          individualID: this.state.individualID,
          paymentId: applicantData.landlordDetails.paymentId,
        },
      }));
      this.setState((prevState) => ({
        individualpaymentDetailsClone: {
          ...prevState.individualpaymentDetailsClone,
          fundingSource: this.state.fundingSource,
          individualID: this.state.individualID,
          paymentId: applicantData.individualDetails.paymentId,
        },
      }));
  

      this.setState({
        rentPaymentStatus_Original:
          applicantData.landlordDetails.entityPaymentStatus,
      });

      this.setState({
        landlordInfoRequestedStatus_Original:
          applicantData.landlordDetails.landlordInfoRequestedStatus,
      });

      this.setState({
        applicantDocUploadStatus_Original:
          applicantData.applicantDetails.applicantDocUploadStatus,
      });

      this.setState({
        individualPaymentStatus_Original:
          applicantData.individualDetails.entityPaymentStatus,
      });
      this.setState({
        individualCase_Original: applicantData.applicantDetails.individualCase,
      });

      this.setState({
        landlordEmail_Original: applicantData.applicantDetails.landlordEmail,
      });

      this.setState({
        applicantEmail_Original: applicantData.applicantDetails.tenantEmail,
      });

      this.setState({
        totalCovered: applicantData.applicantDetails.totalFund,
        totalCoveredClone: applicantData.applicantDetails.totalFund,
      });
     
      this.setState((prevState) => ({
        applicantDetails: {
          ...prevState.applicantDetails,
          isLandlordEmailChanged: false,
        },
      }));

      this.setState((prevState) => ({
        applicantDetailsClone: {
          ...prevState.applicantDetailsClone,
          isLandlordEmailChanged: false,
        },
      })); 

      this.calculateTotal1099();
      this.calculateTotal10992022();
      this.calculateTotalFund();
      // Update the case dropdown after loading the data
      this.onPaymentStatusChange();

      // Update following fields based on the existing fields
      this.setState({
        timestampOfRentIssued: "",
        lengthOfTimeForAward: "0",
      });

      // this.state.landlorddetail.requestland
      if (this.state.landlordDetails.landlordInfoRequestedStatus === "Ready") {
        // landlordInfoRequestedStatus
        if (
          this.state.landlordDetails.dateLandlordDocumentSubmitted !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordDocumentSubmitted !==
          "0000-00-00"
        ) {
          // dateLandlordDocumentSubmitted
          var d = new Date(
            this.state.landlordDetails.dateLandlordDocumentSubmitted
          );
          var landlordSubmitted = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          this.setState({
            dateLandlordDocumentSubmitted_Formatted: landlordSubmitted,
          });
        }
      }

      if (this.state.applicantDetails.applicantDocUploadStatus === "Ready") {
        // applicantDocUploadStatus
        if (
          this.state.applicantDetails.dateApplicantDocumentSubmitted !==
          "1000-01-01" &&
          this.state.applicantDetails.dateApplicantDocumentSubmitted !==
          "0000-00-00"
        ) {
          // dateApplicantDocumentSubmitted

          var d = new Date(
            this.state.applicantDetails.dateApplicantDocumentSubmitted
          );
          var applicantSubmitted = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          this.setState({
            dateApplicantDocumentSubmitted_Formatted: applicantSubmitted,
          });
        }
      }
      if (
        this.state.landlordDetails.requestedLandlordInfo === true ||
        this.state.landlordDetails.requestedLandlordInfo === 1 ||
        this.state.landlordDetails.requestedLandlordInfo === "1"
      ) {
        // requestedLandlordInfo
        if (
          this.state.landlordDetails.dateLandlordInfoReminded !== null &&
          this.state.landlordDetails.timeLandlordInfoReminded !== null &&
          this.state.landlordDetails.dateLandlordInfoReminded !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordInfoReminded !== "0000-00-00"
        ) {
          // dateLandlordInfoReminded, timeLandlordInfoReminded

          var d = new Date(this.state.landlordDetails.dateLandlordInfoReminded);
          var infoRemindedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          //   this.setState((prevState) => ({
          //     landlordDetails: {
          //       ...prevState.landlordDetails,
          //       dateLandlordInfoReminded_Formatted: infoRemindedDate,
          //     },
          //   }));

          this.setState({
            dateLandlordInfoReminded_Formatted: infoRemindedDate,
          });
          // var regExTimeArr = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/.exec(res[0][228]);
          // var hhmmss = '00:00:00';
          // if (regExTimeArr != null && regExTimeArr.length === 4) {
          //     hhmmss = ('0' + regExTimeArr[1]).slice(-2) + ':' + ('0' + regExTimeArr[2]).slice(-2) + ':' + ('0' + regExTimeArr[3]).slice(-2);
          // }
          // var infoRemindedTimestamp = new Date(res[0][227] + 'T' + hhmmss + '.000Z');   //"2021-06-01T10:54:54.000"
          // this.setState({ dateLandlordInfoReminded_Formatted: new Date(infoRemindedTimestamp.toUTCString()).toLocaleString('en-US',
          //     {
          //         //timeZone: 'Canada/Pacific',
          //         month: '2-digit',
          //         day: '2-digit',
          //         year: 'numeric',
          //         hour: '2-digit',
          //         minute:'2-digit',
          //         second:'2-digit',
          //         hour12: false
          //     }).replace(",", "") });

          // //console.log(theDate.toLocaleString('en-US', { timeZone: 'America/Tijuana' }));
          // //America/Tijuana
        }

        if (
          this.state.landlordDetails.dateLandlordInfoRequested !== null &&
          this.state.landlordDetails.timeLandlordInfoRequested !== null &&
          this.state.landlordDetails.dateLandlordInfoRequested !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordInfoRequested !== "0000-00-00"
        ) {
          // dateLandlordInfoRequested, timeLandlordInfoRequested
          var d = new Date(
            this.state.landlordDetails.dateLandlordInfoRequested
          );
          var infoRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          //   this.setState((prevState) => ({
          //     landlordDetails: {
          //       ...prevState.landlordDetails,
          //       dateLandlordInfoRequested_Formatted: infoRequestedDate,
          //     },
          //   }));

          this.setState({
            dateLandlordInfoRequested_Formatted: infoRequestedDate,
          });

          // applicant info date forma

          var applicantDate = new Date(
            this.state.applicantDetails.dateApplicantInfoRequested
          );
          var infoApplicantRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            applicantDate.getFullYear(),
          ].join("/");

          //   this.setState((prevState) => ({
          //     landlordDetails: {
          //       ...prevState.landlordDetails,
          //       dateLandlordInfoRequested_Formatted: infoRequestedDate,
          //     },
          //   }));

          this.setState({
            dateApplicantInfoRequested_Formatted: infoApplicantRequestedDate,
          });

          // var regExTimeArr = /([0-9]?[0-9]):([0-9][0-9]):([0-9][0-9])/.exec(res[0][225]);
          // var hhmmss = '00:00:00';
          // if (regExTimeArr != null && regExTimeArr.length === 4) {
          //     hhmmss = ('0' + regExTimeArr[1]).slice(-2) + ':' + ('0' + regExTimeArr[2]).slice(-2) + ':' + ('0' + regExTimeArr[3]).slice(-2);
          // }

          // var infoRequestedTimestamp = new Date(res[0][224] + 'T' + hhmmss + '.000Z');   //"2021-06-01T10:54:54.000"
          // this.setState({ dateLandlordInfoRequested_Formatted: new Date(infoRequestedTimestamp.toUTCString()).toLocaleString('en-US',
          //     {
          //         //timeZone: 'Canada/Pacific',
          //         month: '2-digit',
          //         day: '2-digit',
          //         year: 'numeric',
          //         hour: '2-digit',
          //         minute:'2-digit',
          //         second:'2-digit',
          //         hour12: false
          //     }).replace(",", "") });

          // //console.log(theDate.toLocaleString('en-US', { timeZone: 'America/Tijuana' }));
          // //America/Tijuana
        }
      }

      // payment  section of landlord  bank starts
      if (
        this.state.landlordDetails.requestedBankInfo === true ||
        this.state.landlordDetails.requestedBankInfo === 1 ||
        this.state.landlordDetails.requestedBankInfo === "1"
      ) {
        // requestedLandlordInfo
        if (
          this.state.landlordDetails.dateBankInfoReminded !== null &&
          this.state.landlordDetails.timeBankInfoReminded !== null &&
          this.state.landlordDetails.dateBankInfoReminded !== "1000-01-01" &&
          this.state.landlordDetails.dateBankInfoReminded !== "0000-00-00"
        ) {
          // dateLandlordInfoReminded, timeLandlordInfoReminded

          var d = new Date();
          var infoRemindedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          this.setState({
            dateLandlordBankInfoRequestedNew_Formatted: infoRemindedDate,
          });
        }

        if (
          this.state.landlordDetails.dateBankInfoRequested !== null &&
          this.state.landlordDetails.timeBankInfoRequested !== null &&
          this.state.landlordDetails.dateBankInfoRequested !== "1000-01-01" &&
          this.state.landlordDetails.dateBankInfoRequested !== "0000-00-00"
        ) {
          // dateLandlordInfoRequested, timeLandlordInfoRequested
          var d = new Date(this.state.landlordDetails.dateBankInfoRequested);
          var infoRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            dateLandlordBankInfoRequestedNew_Formatted: infoRequestedDate,
          });
        }
      }

      //payment  section  of  landlord   bank ends

      //payment  section of individual bank  starts
      if (
        this.state.individualDetails.requestedBankInfo === true ||
        this.state.individualDetails.requestedBankInfo === 1 ||
        this.state.individualDetails.requestedBankInfo === "1"
      ) {
        // requestedLandlordInfo
        if (
          this.state.individualDetails.dateBankInfoReminded !== null &&
          this.state.individualDetails.timeBankInfoReminded !== null &&
          this.state.individualDetails.dateBankInfoReminded !== "1000-01-01" &&
          this.state.individualDetails.dateBankInfoReminded !== "0000-00-00"
        ) {
          // dateLandlordInfoReminded, timeLandlordInfoReminded

          var d = new Date();
          var infoRemindedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          this.setState({
            dateIndividualBankInfoRequestedNew_Formatted: infoRemindedDate,
          });
        }

        if (
          this.state.individualDetails.dateBankInfoRequested !== null &&
          this.state.individualDetails.timeBankInfoRequested !== null &&
          this.state.individualDetails.dateBankInfoRequested !== "1000-01-01" &&
          this.state.individualDetails.dateBankInfoRequested !== "0000-00-00"
        ) {
          // dateLandlordInfoRequested, timeLandlordInfoRequested
          var d = new Date(this.state.individualDetails.dateBankInfoRequested);
          var infoRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            dateIndividualBankInfoRequestedNew_Formatted: infoRequestedDate,
          });
        }
      }

      // payment  section  of  individual  bank ends

      // new landlord info req

      if (
        this.state.landlordDetails.requestedLandlordInfoNew === true ||
        this.state.landlordDetails.requestedLandlordInfoNew === 1 ||
        this.state.landlordDetails.requestedLandlordInfoNew === "1"
      ) {
        // requestedLandlordInfo
        if (
          this.state.landlordDetails.dateLandlordInfoReminded !== null &&
          this.state.landlordDetails.timeLandlordInfoReminded !== null &&
          this.state.landlordDetails.dateLandlordInfoReminded !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordInfoReminded !== "0000-00-00"
        ) {
          // dateLandlordInfoReminded, timeLandlordInfoReminded

          var d = new Date(this.state.landlordDetails.dateLandlordInfoReminded);
          var infoRemindedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            dateLandlordInfoReminded_Formatted: infoRemindedDate,
          });
        }

        if (
          this.state.landlordDetails.dateLandlordInfoRequested !== null &&
          this.state.landlordDetails.timeLandlordInfoRequested !== null &&
          this.state.landlordDetails.dateLandlordInfoRequested !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordInfoRequested !== "0000-00-00"
        ) {
          // dateLandlordInfoRequested, timeLandlordInfoRequested
          var d = new Date(
            this.state.landlordDetails.dateLandlordInfoRequested
          );
          var infoRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            dateLandlordInfoRequested_Formatted: infoRequestedDate,
          });
        }

        //new
        if (
          this.state.landlordDetails.dateLandlordInfoRequestedNew !== null &&
          this.state.landlordDetails.timeLandlordInfoRequestedNew !== null &&
          this.state.landlordDetails.dateLandlordInfoRequestedNew !==
          "1000-01-01" &&
          this.state.landlordDetails.dateLandlordInfoRequestedNew !==
          "0000-00-00"
        ) {
          // dateLandlordInfoRequested, timeLandlordInfoRequested
          var d = new Date(
            this.state.landlordDetails.dateLandlordInfoRequestedNew
          );
          var infoRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            dateLandlordInfoRequestedNew_Formatted: infoRequestedDate,
          });
        }
      }

      if (
        this.state.applicantDetails.requestedApplicantInfo === true ||
        this.state.applicantDetails.requestedApplicantInfo === 1 ||
        this.state.applicantDetails.requestedApplicantInfo === "1"
      ) {
        // applicant info date forma
        if (
          this.state.applicantDetails.dateApplicantInfoRequested !== null &&
          this.state.applicantDetails.timeApplicantInfoRequested !== null &&
          this.state.applicantDetails.dateApplicantInfoRequested !==
          "1000-01-01" &&
          this.state.applicantDetails.dateApplicantInfoRequested !==
          "0000-00-00"
        ) {
          var applicantDate = new Date(
            this.state.applicantDetails.dateApplicantInfoRequested
          );
          var infoApplicantRequestedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            applicantDate.getFullYear(),
          ].join("/");

          this.setState({
            dateApplicantInfoRequested_Formatted: infoApplicantRequestedDate,
          });
        }
      }
      if (
        this.state.applicantDetails.dateImported !== "" &&
        this.state.applicantDetails.dateImported !== null
      ) {
        var d = new Date(this.state.applicantDetails.dateImported);
        var formattedDate = [
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2),
          d.getFullYear(),
        ].join("/");
        // this.setState((prevState) => ({
        //   applicantDetails: {
        //     ...prevState.applicantDetails,
        //     dateImported_Formatted: formattedDate,
        //   },
        // }));

        this.setState({
          dateImported_Formatted: formattedDate,
        });
      }

      if (this.state.rentPaymentStatus_Original === "Issued") {
        if (
          this.state.landlordDetails.entityDatePaymentStatusUpdated !== "" &&
          this.state.landlordDetails.entityDatePaymentStatusUpdated !== null &&
          this.state.landlordDetails.entityDatePaymentStatusUpdated !==
          undefined &&
          this.state.landlordDetails.entityTimePaymentStatusUpdated !== "" &&
          this.state.landlordDetails.entityTimePaymentStatusUpdated !== null &&
          this.state.landlordDetails.entityTimePaymentStatusUpdated !==
          undefined
        ) {
          var d = new Date(
            this.state.landlordDetails.entityDatePaymentStatusUpdated
          );
          var statusUpdatedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");

          this.setState({
            timestampOfRentIssued:
              statusUpdatedDate +
              " " +
              this.state.landlordDetails.entityTimePaymentStatusUpdated,
          });

          if (
            this.state.applicantDetails.dateApplied !== "" &&
            this.state.applicantDetails.dateApplied !== null &&
            this.state.applicantDetails.dateApplied !== undefined
          ) {
            this.setState({
              lengthOfTimeForAward: this.date_diff_in_days(
                this.state.applicantDetails.dateApplied,
                this.state.landlordDetails.entityDatePaymentStatusUpdated
              ),
            });
          }
        }
      }

      if (
        this.state.applicantDetails.programTitle === "" ||
        this.state.applicantDetails.programTitle === null ||
        this.state.applicantDetails.programTitle === undefined
      ) {
        // this.setState({ programTitle: "ERA-Santa Ana" });
        this.setState((prevState) => ({
          applicantDetails: {
            ...prevState.applicantDetails,
            programTitle: "ERA-Santa Ana",
          },
        }));
        this.setState((prevState) => ({
          applicantDetailsClone: {
            ...prevState.applicantDetailsClone,
            programTitle: "ERA-Santa Ana",
          },
        }));
      }

      if (
        this.state.landlordDetails.entityACHReceived === "1" ||
        this.state.landlordDetails.entityACHReceived === 1
      ) {
        document.getElementById("landlordACHReceivedId").checked = true;
      } else {
        document.getElementById("landlordACHReceivedId").checked = false;
      }

      if (
        this.state.individualDetails.entityACHReceived === "1" ||
        this.state.individualDetails.entityACHReceived === 1
      ) {
        document.getElementById("individualACHReceivedId").checked = true;
      } else {
        document.getElementById("individualACHReceivedId").checked = false;
      }

      res = await this.loadUploadedDocuments();

      var doc = await this.getMiscDocument();
    } catch (err) {
      console.log(err);
      console.log("ERROR: Failed to load the individual info.");
      return;
    }
  };

  adminGetIndividual = async () => {
    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/getsantaanaapplicantbyindividualid";
    let myInit = {
      // OPTIONAL
      body: {
        info: {
          individualID: this.state.individualID,
          fundingSource: this.state.fundingSource,
          isTransactionPermission: this.state.isTransactionPermission,
        },
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    };
    return await API.post(apiName, path, myInit);
  };

  getReconciliationList = async () => {
    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/reconciliationbyindividualid";
    let myInit = {
      // OPTIONAL
      body: {
        info: {
          individualID: this.state.individualID,
          fundingSource: this.state.fundingSource,
          program: "santana",
          isTransactionPermission: this.state.isTransactionPermission,
        },
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    };
    
    return  await API.post(apiName, path, myInit);
  };

  updateAccountNoRoutingNoData = async (res) => {
    return new Promise(async (resolve, reject) => {
      if (this.state.isTransactionPermission) {
        let len = res.length;
        let isAccNoComplete = false;
        let isRoutingNoComplete = false;
        for (let index = 0; index < len; index++) {
          let accno = res[index]['entityAccountNumber'];
          let rauno = res[index]['entityRoutingNumber'];
          if (accno && accno !== null && accno !== undefined && accno !== "") {
            await this.decryptData(accno).then((isGetResult) => {
              res[index]['entityAccountNumber'] = isGetResult;
              isAccNoComplete = (len - 1) == index ? true : false;
            });
          } else {
            isAccNoComplete = (len - 1) == index ? true : false;
          }
          if (rauno && rauno !== null && rauno !== undefined && rauno !== "") {
            await this.decryptData(rauno).then((isGetResult1) => {
              res[index]['entityRoutingNumber'] = isGetResult1;
              isRoutingNoComplete = (len - 1) == index ? true : false;
            });
          } else {
            isRoutingNoComplete = (len - 1) == index ? true : false;
          }
          if (isAccNoComplete && isRoutingNoComplete)
            resolve(true);
        }
      } else {
        resolve(true);
      }
    });
  }

  textExtractW9Delete = async (key, fundingSource) => {
    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/textextracterasantaana";
    let myInit = {
      // OPTIONAL
      body: {
        info: {
          individualID: this.state.individualID,
          fundingSource: fundingSource,
          modifiedBy: user.attributes.email,
          key: key,
        },
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    };
    return await API.post(apiName, path, myInit);
  };

  resetValues = () => {
    this.setState({
      individualID: "0",

      firstName: "",
      lastName: "",
      partnerFirstName: "",
      partnerLastName: "",
      addressLine1: "",
      addressLine2: "",
      city: "",
      state: "",
      country: "",
      zipcode: "",
      clientDOB: "",
      phone: "",
      legallyAbleToWork: "",
      eligibleForStimulusFunds: "",
      veteran: "",
      monthlyIncomeBeforeCovid19: "",
      workIndustry: "",
      employmentStatus: "",
      effectOfCovid19Financially: "",
      householdNumber: "0",
      //Payment states reset
      transactionType: "",
      entityAccountNumber: "",
      entityRoutingNumber: "",
      entityBankName: "",
      entityAccountType: "",
      payeeName: "",
      fullAddress: "",
      informationStatus: "",
      checkStatus: "",
      paymentId: "",
      ////ends
      age0to5: "0",
      age6to17: "0",
      age18to59: "0",
      age60andUp: "0",
      cardValue: "",
      participateInSurvey: "",
      claimStatus: "",
      dateImported: "",
      timeImported: "",
      dateFromHIMS: "",
      timeFromHIMS: "",
      dateClaimModified: "",
      timeClaimModified: "",
      notes: "",
      adminUsername: "",
      originator: "",
      programTitle: "",
      status: "",
      transactionmodelandlord: "",
      transactionmodeindividual: "",

      testrecord: false,
      spaAssignmentNo: "",
      hmisApproval: "",
      householdID: "0",
      transactionID: "0",
      landlordPhoneNumber: "",
      landlordEmail: "",
      amountDueforRent: "",
      payee: "",
      rentAmount: "",
      landlordBankName: "",
      landlordAccountNumber: "",
      landlordRoutingNumber: "",
      memoPropertyAddress: "",
      rentPaymentStatus: "",
      rentPaymentStatus_Original: "",
      applicantDocUploadStatus_Original: "",
      electricProvider: "",
      electricUtilityAccountNo: "",
      amountDueforElectricity: "",
      custElectricBillingAccountNo: "",
      electricAmount: "",
      electricBankName: "",
      electricUtilityProviderAccountNo: "",
      electricRoutingNumber: "",
      electricMemoAccountNo: "",
      electricPaymentStatus: "",
      electricPaymentStatus_Original: "",
      gasProvider: "",
      gasUtilityAccountNo: "",
      amountDueforGas: "",
      custGasBillingAccountNo: "",
      gasBankName: "",
      gasAmount: "",
      gasUtilityProviderAccountNo: "",
      gasRoutingNumber: "",
      gasMemoAccountNo: "",
      gasPaymentStatus: "",
      gasPaymentStatus_Original: "",
      otherPaymentAmount: "",
      otherPaymentStatus: "",
      otherPaymentStatus_Original: "",

      dateEmailTriggered: "",
      timeEmailTriggered: "",
      dateRentPaymentStatusUpdated: "",
      timeRentPaymentStatusUpdated: "",
      dateElectricPaymentStatusUpdated: "",
      timeElectricPaymentStatusUpdated: "",
      dateGasPaymentStatusUpdated: "",
      timeGasPaymentStatusUpdated: "",
      dateOtherPaymentStatusUpdated: "",
      timeOtherPaymentStatusUpdated: "",

      currentTotalHouseholdIncome: "",
      openToFollowup: "",
      gender: "",
      race: "",
      ethnicity: "",
      spaServiceProviderEmail: "",
      noOfTimesParticipated: "",

      landlordSSN: "",
      landlordTaxID: "",
      landlordDUNSNumber: "",
      rentTypeOfAssistance: "",
      monthlyRent: "",
      percentageOfMonthlyRent: "",
      outstandingRentalArrearsAmount: "",
      noOfMonthsRentalPayments: "",

      electricUtilityTaxID: "",
      electricUtilityDUNSNumber: "",
      monthlyElectricBillAmount: "",
      percentageOfElectricBill: "",
      noOfMonthsElectricUtilityPayments: "",
      electricTypeOfAssistance: "",

      gasUtilityTaxID: "",
      gasUtilityDUNSNumber: "",
      monthlyGasBillAmount: "",
      percentageOfGasBill: "",
      noOfMonthsGasUtilityPayments: "",
      gasTypeOfAssistance: "",

      covid_other: "",
      industry_other: "",

      tenantEmail: "",
      dateApplied: "",
      individualBankName: "",
      individualAccountNumber: "",
      individualRoutingNumber: "",
      individualMemoPropertyAddress: "",
      individualPaymentReason: "",
      individualPaymentStatus: "",
      individualPaymentStatus_Original: "",
      dateIndividualPaymentStatusUpdated: "",
      timeIndividualPaymentStatusUpdated: "",
      individualPaymentAmount: "",
      modifiedBy: "", // for user reset

      suffix: "",
      middleName: "",
      agent: "",
      doYouRent: "",
      receiveAnyHousingSubsidy: "",
      isAnnualIncomeLess: "",
      isSomeoneOnRentalOrLeaseAgreement: "",
      filedCovid19FinancialStressDeclaration: "",
      receivedEvictionNotice: "",
      dateEvictionNoticeReceived: "",
      SSN: "",
      socialSecurityFirstName: "",
      socialSecurityLastName: "",
      birthdayCode: "",
      age: "",
      otherPhone: "",
      secondaryFirstName: "",
      secondaryLastName: "",
      secondaryEmail: "",
      secondaryPhone: "",

      belowThirtyPercAMI: "",
      thirtyToFiftyPercAMI: "",
      fiftyToEightyPercAMI: "",
      boardDistrict: "",
      landlordFirstAttempt: "",
      landlordResponse: "",

      individualCase: "",
      individualCase_Original: "",
      reasonPaymentNotProcessedIn21Days: "",

      uniqueID: "",

      countAdult: "",
      countChildren: "",
      householdApprovedForUnitedWay: "",
      landlordACHReceived: "",
      individualACHReceived: "",

      landlordAddressLine1: "",
      landlordAddressLine2: "",
      landlordCity: "",
      landlordState: "",
      landlordZipcode: "",

      requestedLandlordInfo: false,
      dateLandlordInfoRequested: "0000-00-00",
      dateLandlordInfoRequested_Formatted: "0000-00-00",
      dateLandlordInfoRequestedNew_Formatted: "0000-00-00",
      //bank date  landlord
      dateLandlordBankInfoRequestedNew_Formatted: "0000-00-00",
      dateIndividualBankInfoRequestedNew_Formatted: "0000-00-00",
      // end
      timeLandlordInfoRequested: null,
      dateReconcilationReminded_Formatted: "0000-00-00",
      countLandlordInfoRequested: 0,
      dateLandlordInfoReminded: "0000-00-00",
      dateLandlordInfoReminded_Formatted: "0000-00-00",
      timeLandlordInfoReminded: null,

      lengthOfTimeForAward: "",
      timestampOfRentIssued: "",

      fundingSource: "ERA2021",
      verified: 0,

      totalCovered: 0,
      totalRecords: 1,
      totalCoveredClone: 0,

      triggerEmail: "0",
    });
  };

  shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  };

  getCurrentUser = async () => {
    try {
      var user = await Auth.currentAuthenticatedUser();
      return user;
    }
    catch (e) {
      this.setState({ isLoading: false });
      if (e === "not authenticated") {
        await this.signOut();
      }
      // return;
    }
  }

  updateIndividualDetails = async () => {
    var user = await this.getCurrentUser();

    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/modifysantaanaapplicant";
    let IsEqualapplicantDetails = this.shallowEqual(
      this.state.applicantDetails,
      this.state.applicantDetailsClone
    );
    let IsEqualapplicantProperty = this.shallowEqual(
      this.state.applicantProperty,
      this.state.applicantPropertyClone
    );
    let IsEqualindividualDetails = this.shallowEqual(
      this.state.individualDetails,
      this.state.individualDetailsClone
    );
    let IsEquallandlordDetails = this.shallowEqual(
      this.state.landlordDetails,
      this.state.landlordDetailsClone
    );
    let IsEquallandlordProperty = this.shallowEqual(
      this.state.landlordProperty,
      this.state.landlordPropertyClone
    );
    let IsEquallandlordpaymentDetails = this.shallowEqual(
      this.state.landlordpaymentDetails,
      this.state.landlordpaymentDetailsClone
    );
    let IsEqualindividualpaymentDetails = this.shallowEqual(
      this.state.individualpaymentDetails,
      this.state.individualpaymentDetailsClone
    );

    let IsEqualPaymentMonth2021 = true;
    let IsEqualPaymentMonth2022 = true;
    // let IsOnlyDelete2021 = false;
    // let IsOnlyDelete2022 = false;
    if (this.state.paymentMonths2021 && this.state.paymentMonths2021.length > 0) {
      let clone = this.state.paymentMonths2021Clone;
      IsEqualPaymentMonth2021 = this.state.paymentMonths2021.length == this.state.paymentMonths2021Clone.length && this.state.paymentMonths2021.every(function (element, index) {
        return element === clone[index];
      });
    }
    if (this.state.paymentMonths2021 && this.state.paymentMonths2021.length == 0 && this.state.paymentMonths2021Clone.length > 0) {
      // this.setState({paymentMonths2021 : 0 });
      IsEqualPaymentMonth2021 = false;
    }

    if (this.state.paymentMonths2022 && this.state.paymentMonths2022.length > 0) {
      let clone = this.state.paymentMonths2022Clone;
      IsEqualPaymentMonth2022 = this.state.paymentMonths2022.length == this.state.paymentMonths2022Clone.length && this.state.paymentMonths2022.every(function (element, index) {
        return element === clone[index];
      });
    }

// date claim modified issue
    var d = new Date(
      new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
    );
    var todaysDate = [
      d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-");

    var todaysTime = [
      ("0" + d.getHours()).slice(-2),
      ("0" + d.getMinutes()).slice(-2),
      ("0" + d.getSeconds()).slice(-2),
    ].join(":");

    let tramodify = this.state.adminUsername;
    if(!IsEqualapplicantDetails || !IsEquallandlordDetails || !IsEqualindividualDetails || !IsEqualapplicantProperty || !IsEquallandlordProperty) {
      IsEqualapplicantDetails = false;
      this.setState((prevState) => ({
        applicantDetails: {
          ...prevState.applicantDetails,
          dateClaimModified: todaysDate,
          timeClaimModified: todaysTime,
          adminUsername: tramodify,
        },
      }));
    } 
// end

    if (this.state.paymentMonths2022 && this.state.paymentMonths2022.length == 0 && this.state.paymentMonths2022Clone.length > 0) {
      IsEqualPaymentMonth2022 = false;
    }
    if (this.state.isTransactionPermission) {
      if (!IsEquallandlordpaymentDetails) {
        let accno = this.state.landlordpaymentDetails["entityAccountNumber"];
        let rouno = this.state.landlordpaymentDetails["entityRoutingNumber"];
        this.state.landlordpaymentDetails["entityAccountNumber"] = accno !== null && accno !== undefined && accno !== "" ? await this.encryptData(accno) : this.state.landlordpaymentDetails["entityAccountNumber"];
        this.state.landlordpaymentDetails["entityRoutingNumber"] = rouno !== null && rouno !== undefined && rouno !== "" ? await this.encryptData(rouno) : this.state.landlordpaymentDetails["entityRoutingNumber"];
      }
    }
    else {
      this.state.landlordpaymentDetails["entityAccountNumber"] = '';
      this.state.landlordpaymentDetails["entityRoutingNumber"] = '';
    }

    let myInit = {
      // OPTIONAL
      body: {
        info: {
          individualID: this.state.individualID,
          fundingSource: this.state.fundingSource,
          applicantDetails: !IsEqualapplicantDetails
            ? this.state.applicantDetails
            : undefined,

          applicantProperty: !IsEqualapplicantProperty
            ? this.state.applicantProperty
            : undefined,

          individualDetails: !IsEqualindividualDetails
            ? this.state.individualDetails
            : undefined,

          landlordDetails: !IsEquallandlordDetails
            ? this.state.landlordDetails
            : undefined,

          landlordProperty: !IsEquallandlordProperty
            ? this.state.landlordProperty
            : undefined,

          landlordpaymentDetails: !IsEquallandlordpaymentDetails
            ? this.state.landlordpaymentDetails
            : undefined,
          individualpaymentDetails: !IsEqualindividualpaymentDetails
            ? this.state.individualpaymentDetails
            : undefined,
          paymentMonths2021: !IsEqualPaymentMonth2021 ? this.state.paymentMonths2021 : undefined,
          paymentMonths2022: !IsEqualPaymentMonth2022 ? this.state.paymentMonths2022 : undefined,
          isTransactionPermission: this.state.isTransactionPermission,
        },
      }, // replace this with attributes you need

      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    };

    return await API.post(apiName, path, myInit);
  };

  UploadIndividualDocuments = async () => {
    if (
      this.state.individualDetails.individualID === -1 ||
      this.state.individualDetails.individualID === 0
    ) {
      // early return
      return;
    }

    Storage.configure({
      bucket: process.env.REACT_APP_S3FrontendBucket,
      region: process.env.REACT_APP_Region,
      level: "public",
    });

    this.tenant_payment_agreement_upload = document.getElementById(
      "tenant_payment_agreement_upload_id"
    );
    if (
      this.tenant_payment_agreement_upload !== null &&
      this.tenant_payment_agreement_upload.files != null &&
      this.tenant_payment_agreement_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
          this.tenant_payment_agreement_upload.files[0],
          { contentType: this.tenant_payment_agreement_upload.files[0].type }
        );
        this.tenant_payment_agreement_upload = null;

        this.setState({ tenant_payment_agreement_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    this.landlord_payment_agreement_upload = document.getElementById(
      "landlord_payment_agreement_upload_id"
    );
    if (
      this.landlord_payment_agreement_upload !== null &&
      this.landlord_payment_agreement_upload.files != null &&
      this.landlord_payment_agreement_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/landlord_payment_agreement/${this.landlord_payment_agreement_upload.files[0].name}`,
          this.landlord_payment_agreement_upload.files[0],
          { contentType: this.landlord_payment_agreement_upload.files[0].type }
        );
        //console.log(result);
        this.landlord_payment_agreement_upload = null;

        this.setState({ landlord_payment_agreement_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
      // early return. don't allow rest of the documents to upload if individual case is set to other than open.
      return;
    }

    this.photo_identification_upload = document.getElementById(
      "photo_identification_upload_id"
    );
    this.proof_of_address_upload = document.getElementById(
      "proof_of_address_upload_id"
    );
    this.proof_of_income_upload = document.getElementById(
      "proof_of_income_upload_id"
    );
    this.proof_of_income_loss_upload = document.getElementById(
      "proof_of_income_loss_upload_id"
    );
    this.ach_authorization_form_upload = document.getElementById(
      "ach_authorization_form_upload_id"
    );
    this.lease_agreement_upload = document.getElementById(
      "lease_agreement_upload_id"
    );
    this.utility_gas_bill_upload = document.getElementById(
      "utility_gas_bill_upload_id"
    );
    this.utility_electricity_bill_upload = document.getElementById(
      "utility_electricity_bill_upload_id"
    );
    this.landlord_w9_upload = document.getElementById("landlord_w9_upload_id");
    this.landlord_driver_license_upload = document.getElementById(
      "landlord_driver_license_upload_id"
    );
    this.verified_checkpoint_upload = document.getElementById(
      "verified_checkpoint_upload_id"
    );
    this.tenant_checkPoint_id_verification_page_upload =
      document.getElementById(
        "tenant_checkPoint_id_verification_page_upload_id"
      );
    this.era3_tenant_checkPoint_upload =
      document.getElementById(
        "era3_tenant_checkPoint_upload_id"
      );
    this.era2021ReCert_landlord_updated_w9_upload = document.getElementById(
      "era2021ReCert_landlord_updated_w9_upload_id"
    );
    this.era2021ReCert_updated_program_participation_Form_upload =
      document.getElementById(
        "era2021ReCert_updated_program_participation_Form_upload_id"
      );
    this.era2021ReCert_landlord_identity_upload = document.getElementById(
      "era2021ReCert_landlord_identity_upload_id"
    );
    this.era2021ReCert_landlord_checkpoint_upload = document.getElementById(
      "era2021ReCert_landlord_checkpoint_upload_id"
    );
    this.era2_landlord_updated_w9_upload = document.getElementById(
      "era2_landlord_updated_w9_upload_id"
    );
    this.era3_landlord_updated_w9_upload = document.getElementById(
      "era3_landlord_updated_w9_upload_id"
    );
    this.era2_updated_program_participation_Form_upload =
      document.getElementById(
        "era2_updated_program_participation_Form_upload_id"
      );
    this.era3_updated_program_participation_Form_upload =
      document.getElementById(
        "era3_updated_program_participation_Form_upload_id"
      );
    this.era2_landlord_identity_upload = document.getElementById(
      "era2_landlord_identity_upload_id"
    );
    this.era3_landlord_identity_upload = document.getElementById(
      "era3_landlord_identity_upload_id"
    );
    this.era2_landlord_checkpoint_upload = document.getElementById(
      "era2_landlord_checkpoint_upload_id"
    );
    this.era3_landlord_checkpoint_upload = document.getElementById(
      "era3_landlord_checkpoint_upload_id"
    );

    // Upload the new documents to be uploaded `Individuals/Individual_${this.state.individualID}/`
    if (
      this.photo_identification_upload !== null &&
      this.photo_identification_upload.files != null &&
      this.photo_identification_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/photo_identification/${this.photo_identification_upload.files[0].name}`,
          this.photo_identification_upload.files[0],
          { contentType: this.photo_identification_upload.files[0].type }
        );
        //console.log(result);
        this.photo_identification_upload = null;

        this.setState({ photo_identification_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.proof_of_address_upload !== null &&
      this.proof_of_address_upload.files != null &&
      this.proof_of_address_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/proof_of_address/${this.proof_of_address_upload.files[0].name}`,
          this.proof_of_address_upload.files[0],
          { contentType: this.proof_of_address_upload.files[0].type }
        );
        //console.log(result);
        this.proof_of_address_upload = null;

        this.setState({ proof_of_address_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.proof_of_income_upload !== null &&
      this.proof_of_income_upload.files != null &&
      this.proof_of_income_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/proof_of_income/${this.proof_of_income_upload.files[0].name}`,
          this.proof_of_income_upload.files[0],
          { contentType: this.proof_of_income_upload.files[0].type }
        );
        //console.log(result);
        this.proof_of_income_upload = null;

        this.setState({ proof_of_income_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.proof_of_income_loss_upload !== null &&
      this.proof_of_income_loss_upload.files != null &&
      this.proof_of_income_loss_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/proof_of_income_loss/${this.proof_of_income_loss_upload.files[0].name}`,
          this.proof_of_income_loss_upload.files[0],
          { contentType: this.proof_of_income_loss_upload.files[0].type }
        );
        //console.log(result);
        this.proof_of_income_loss_upload = null;

        this.setState({ proof_of_income_loss_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.ach_authorization_form_upload !== null &&
      this.ach_authorization_form_upload.files != null &&
      this.ach_authorization_form_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/ach_authorization_form/${this.ach_authorization_form_upload.files[0].name}`,
          this.ach_authorization_form_upload.files[0],
          { contentType: this.ach_authorization_form_upload.files[0].type }
        );
        //console.log(result);
        this.ach_authorization_form_upload = null;

        this.setState({ ach_authorization_form_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.lease_agreement_upload !== null &&
      this.lease_agreement_upload.files != null &&
      this.lease_agreement_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/lease_agreement/${this.lease_agreement_upload.files[0].name}`,
          this.lease_agreement_upload.files[0],
          { contentType: this.lease_agreement_upload.files[0].type }
        );
        //console.log(result);
        this.lease_agreement_upload = null;

        this.setState({ lease_agreement_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.utility_gas_bill_upload !== null &&
      this.utility_gas_bill_upload.files != null &&
      this.utility_gas_bill_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/utility_gas_bill/${this.utility_gas_bill_upload.files[0].name}`,
          this.utility_gas_bill_upload.files[0],
          { contentType: this.utility_gas_bill_upload.files[0].type }
        );
        //console.log(result);
        this.utility_gas_bill_upload = null;

        this.setState({ utility_gas_bill_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.utility_electricity_bill_upload !== null &&
      this.utility_electricity_bill_upload.files != null &&
      this.utility_electricity_bill_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/utility_electricity_bill/${this.utility_electricity_bill_upload.files[0].name}`,
          this.utility_electricity_bill_upload.files[0],
          { contentType: this.utility_electricity_bill_upload.files[0].type }
        );
        //console.log(result);
        this.utility_electricity_bill_upload = null;

        this.setState({ utility_electricity_bill_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.landlord_w9_upload !== null &&
      this.landlord_w9_upload.files != null &&
      this.landlord_w9_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/landlord_w9/${this.landlord_w9_upload.files[0].name}`,
          this.landlord_w9_upload.files[0],
          { contentType: this.landlord_w9_upload.files[0].type }
        );

        var resultW9 = await Storage.put(
          `SantaAna/W9_TextExtract/${this.state.individualID}_ERA2021_W9.` +
          this.landlord_w9_upload.files[0].name.split(".").pop(),
          this.landlord_w9_upload.files[0],
          { contentType: this.landlord_w9_upload.files[0].type }
        );

        this.landlord_w9_upload = null;
        this.setState({ landlord_w9_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.landlord_driver_license_upload !== null &&
      this.landlord_driver_license_upload.files != null &&
      this.landlord_driver_license_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/landlord_driver_license/${this.landlord_driver_license_upload.files[0].name}`,
          this.landlord_driver_license_upload.files[0],
          { contentType: this.landlord_driver_license_upload.files[0].type }
        );
        this.landlord_driver_license_upload = null;

        this.setState({ landlord_driver_license_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.verified_checkpoint_upload !== null &&
      this.verified_checkpoint_upload.files != null &&
      this.verified_checkpoint_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/verified_checkpoint/${this.verified_checkpoint_upload.files[0].name}`,
          this.verified_checkpoint_upload.files[0],
          { contentType: this.verified_checkpoint_upload.files[0].type }
        );
        //console.log(result);
        this.verified_checkpoint_upload = null;

        this.setState({ verified_checkpoint_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.tenant_checkPoint_id_verification_page_upload !== null &&
      this.tenant_checkPoint_id_verification_page_upload.files != null &&
      this.tenant_checkPoint_id_verification_page_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/tenant_checkPoint_id_verification_page/${this.tenant_checkPoint_id_verification_page_upload.files[0].name}`,
          this.tenant_checkPoint_id_verification_page_upload.files[0],
          {
            contentType:
              this.tenant_checkPoint_id_verification_page_upload.files[0].type,
          }
        );
        //console.log(result);
        this.tenant_checkPoint_id_verification_page_upload = null;

        this.setState({
          tenant_checkPoint_id_verification_page_uploaded: true,
        });
      } catch (err) {
        console.log(err);
        return;
      }
    }
    // era3 
    if (
      this.era3_tenant_checkPoint_upload !== null &&
      this.era3_tenant_checkPoint_upload.files != null &&
      this.era3_tenant_checkPoint_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era3_tenant_checkPoint/${this.era3_tenant_checkPoint_upload.files[0].name}`,
          this.era3_tenant_checkPoint_upload.files[0],
          {
            contentType:
              this.era3_tenant_checkPoint_upload.files[0].type,
          }
        );
        //console.log(result);
        this.era3_tenant_checkPoint_upload = null;

        this.setState({
          era3_tenant_checkPoint_uploaded: true,
        });
      } catch (err) {
        console.log(err);
        return;
      }
    }
    //end
    if (
      this.era2021ReCert_landlord_updated_w9_upload !== null &&
      this.era2021ReCert_landlord_updated_w9_upload.files != null &&
      this.era2021ReCert_landlord_updated_w9_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2021ReCert_landlord_updated_w9/${this.era2021ReCert_landlord_updated_w9_upload.files[0].name}`,
          this.era2021ReCert_landlord_updated_w9_upload.files[0],
          {
            contentType:
              this.era2021ReCert_landlord_updated_w9_upload.files[0].type,
          }
        );

        var resultW9 = await Storage.put(
          `SantaAna/W9_TextExtract/${this.state.individualID}_ERA2021ReCert_W9.` +
          this.era2021ReCert_landlord_updated_w9_upload.files[0].name
            .split(".")
            .pop(),
          this.era2021ReCert_landlord_updated_w9_upload.files[0],
          {
            contentType:
              this.era2021ReCert_landlord_updated_w9_upload.files[0].type,
          }
        );

        //console.log(result);
        this.era2021ReCert_landlord_updated_w9_upload = null;

        this.setState({ era2021ReCert_landlord_updated_w9_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2021ReCert_updated_program_participation_Form_upload !== null &&
      this.era2021ReCert_updated_program_participation_Form_upload.files !=
      null &&
      this.era2021ReCert_updated_program_participation_Form_upload.files
        .length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2021ReCert_updated_program_participation_Form/${this.era2021ReCert_updated_program_participation_Form_upload.files[0].name}`,
          this.era2021ReCert_updated_program_participation_Form_upload.files[0],
          {
            contentType:
              this.era2021ReCert_updated_program_participation_Form_upload
                .files[0].type,
          }
        );
        //console.log(result);
        this.era2021ReCert_updated_program_participation_Form_upload = null;

        this.setState({
          era2021ReCert_updated_program_participation_Form_uploaded: true,
        });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2021ReCert_landlord_identity_upload !== null &&
      this.era2021ReCert_landlord_identity_upload.files != null &&
      this.era2021ReCert_landlord_identity_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2021ReCert_landlord_identity/${this.era2021ReCert_landlord_identity_upload.files[0].name}`,
          this.era2021ReCert_landlord_identity_upload.files[0],
          {
            contentType:
              this.era2021ReCert_landlord_identity_upload.files[0].type,
          }
        );
        //console.log(result);
        this.era2021ReCert_landlord_identity_upload = null;

        this.setState({ era2021ReCert_landlord_identity_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2021ReCert_landlord_checkpoint_upload !== null &&
      this.era2021ReCert_landlord_checkpoint_upload.files != null &&
      this.era2021ReCert_landlord_checkpoint_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2021ReCert_landlord_checkpoint/${this.era2021ReCert_landlord_checkpoint_upload.files[0].name}`,
          this.era2021ReCert_landlord_checkpoint_upload.files[0],
          {
            contentType:
              this.era2021ReCert_landlord_checkpoint_upload.files[0].type,
          }
        );
        //console.log(result);
        this.era2021ReCert_landlord_checkpoint_upload = null;

        this.setState({ era2021ReCert_landlord_checkpoint_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    // era2
    if (
      this.era2_landlord_updated_w9_upload !== null &&
      this.era2_landlord_updated_w9_upload.files != null &&
      this.era2_landlord_updated_w9_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2_landlord_updated_w9/${this.era2_landlord_updated_w9_upload.files[0].name}`,
          this.era2_landlord_updated_w9_upload.files[0],
          { contentType: this.era2_landlord_updated_w9_upload.files[0].type }
        );

        var resultW9 = await Storage.put(
          `SantaAna/W9_TextExtract/${this.state.individualID}_ERA2_W9.` +
          this.era2_landlord_updated_w9_upload.files[0].name.split(".").pop(),
          this.era2_landlord_updated_w9_upload.files[0],
          { contentType: this.era2_landlord_updated_w9_upload.files[0].type }
        );
        //console.log(result);
        this.era2_landlord_updated_w9_upload = null;

        this.setState({ era2_landlord_updated_w9_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2_updated_program_participation_Form_upload !== null &&
      this.era2_updated_program_participation_Form_upload.files != null &&
      this.era2_updated_program_participation_Form_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2_updated_program_participation_Form/${this.era2_updated_program_participation_Form_upload.files[0].name}`,
          this.era2_updated_program_participation_Form_upload.files[0],
          {
            contentType:
              this.era2_updated_program_participation_Form_upload.files[0].type,
          }
        );
        //console.log(result);
        this.era2_updated_program_participation_Form_upload = null;

        this.setState({
          era2_updated_program_participation_Form_uploaded: true,
        });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2_landlord_identity_upload !== null &&
      this.era2_landlord_identity_upload.files != null &&
      this.era2_landlord_identity_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2_landlord_identity/${this.era2_landlord_identity_upload.files[0].name}`,
          this.era2_landlord_identity_upload.files[0],
          { contentType: this.era2_landlord_identity_upload.files[0].type }
        );
        //console.log(result);
        this.era2_landlord_identity_upload = null;

        this.setState({ era2_landlord_identity_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era2_landlord_checkpoint_upload !== null &&
      this.era2_landlord_checkpoint_upload.files != null &&
      this.era2_landlord_checkpoint_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era2_landlord_checkpoint/${this.era2_landlord_checkpoint_upload.files[0].name}`,
          this.era2_landlord_checkpoint_upload.files[0],
          { contentType: this.era2_landlord_checkpoint_upload.files[0].type }
        );
        //console.log(result);
        this.era2_landlord_checkpoint_upload = null;

        this.setState({ era2_landlord_checkpoint_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era3_landlord_updated_w9_upload !== null &&
      this.era3_landlord_updated_w9_upload.files != null &&
      this.era3_landlord_updated_w9_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era3_landlord_updated_w9/${this.era3_landlord_updated_w9_upload.files[0].name}`,
          this.era3_landlord_updated_w9_upload.files[0],
          { contentType: this.era3_landlord_updated_w9_upload.files[0].type }
        );

        var resultW9 = await Storage.put(
          `SantaAna/W9_TextExtract/${this.state.individualID}_ERA2_W9.` +
          this.era3_landlord_updated_w9_upload.files[0].name.split(".").pop(),
          this.era3_landlord_updated_w9_upload.files[0],
          { contentType: this.era3_landlord_updated_w9_upload.files[0].type }
        );
        //console.log(result);
        this.era3_landlord_updated_w9_upload = null;

        this.setState({ era3_landlord_updated_w9_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era3_landlord_identity_upload !== null &&
      this.era3_landlord_identity_upload.files != null &&
      this.era3_landlord_identity_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era3_landlord_identity/${this.era3_landlord_identity_upload.files[0].name}`,
          this.era3_landlord_identity_upload.files[0],
          { contentType: this.era3_landlord_identity_upload.files[0].type }
        );
        //console.log(result);
        this.era3_landlord_identity_upload = null;

        this.setState({ era3_landlord_identity_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era3_landlord_checkpoint_upload !== null &&
      this.era3_landlord_checkpoint_upload.files != null &&
      this.era3_landlord_checkpoint_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era3_landlord_checkpoint/${this.era3_landlord_checkpoint_upload.files[0].name}`,
          this.era3_landlord_checkpoint_upload.files[0],
          { contentType: this.era3_landlord_checkpoint_upload.files[0].type }
        );
        //console.log(result);
        this.era3_landlord_checkpoint_upload = null;

        this.setState({ era3_landlord_checkpoint_uploaded: true });
      } catch (err) {
        console.log(err);
        return;
      }
    }

    if (
      this.era3_updated_program_participation_Form_upload !== null &&
      this.era3_updated_program_participation_Form_upload.files != null &&
      this.era3_updated_program_participation_Form_upload.files.length > 0
    ) {
      try {
        var result = await Storage.put(
          `SantaAna/Case/Case_${this.state.individualID}/era3_updated_program_participation_Form/${this.era3_updated_program_participation_Form_upload.files[0].name}`,
          this.era3_updated_program_participation_Form_upload.files[0],
          {
            contentType:
              this.era3_updated_program_participation_Form_upload.files[0].type,
          }
        );
        //console.log(result);
        this.era3_updated_program_participation_Form_upload = null;

        this.setState({
          era3_updated_program_participation_Form_uploaded: true,
        });
      } catch (err) {
        console.log(err);
        return;
      }
    }
  };

  lockDocuments = async () => {
    if (this.state.individualCase_Original !== "Open") {
      this.photo_identification_upload = document.getElementById(
        "photo_identification_upload_id"
      );
      this.proof_of_address_upload = document.getElementById(
        "proof_of_address_upload_id"
      );
      this.proof_of_income_upload = document.getElementById(
        "proof_of_income_upload_id"
      );
      this.proof_of_income_loss_upload = document.getElementById(
        "proof_of_income_loss_upload_id"
      );
      this.ach_authorization_form_upload = document.getElementById(
        "ach_authorization_form_upload_id"
      );
      this.lease_agreement_upload = document.getElementById(
        "lease_agreement_upload_id"
      );
      this.utility_gas_bill_upload = document.getElementById(
        "utility_gas_bill_upload_id"
      );
      this.utility_electricity_bill_upload = document.getElementById(
        "utility_electricity_bill_upload_id"
      );
      this.landlord_w9_upload = document.getElementById(
        "landlord_w9_upload_id"
      );
      this.landlord_driver_license_upload = document.getElementById(
        "landlord_driver_license_upload_id"
      );
      this.verified_checkpoint_upload = document.getElementById(
        "verified_checkpoint_upload_id"
      );
      this.tenant_checkPoint_id_verification_page_upload =
        document.getElementById(
          "tenant_checkPoint_id_verification_page_upload_id"
        );
      this.era3_tenant_checkPoint_upload =
        document.getElementById(
          "era3_tenant_checkPoint_upload_id"
        );
      this.era2021ReCert_landlord_updated_w9_upload = document.getElementById(
        "era2021ReCert_landlord_updated_w9_upload_id"
      );
      this.era2021ReCert_updated_program_participation_Form_upload =
        document.getElementById(
          "era2021ReCert_updated_program_participation_Form_upload_id"
        );
      this.era2021ReCert_landlord_identity_upload = document.getElementById(
        "era2021ReCert_landlord_identity_upload_id"
      );
      this.era2021ReCert_landlord_checkpoint_upload = document.getElementById(
        "era2021ReCert_landlord_checkpoint_upload_id"
      );
      this.era2_landlord_updated_w9_upload = document.getElementById(
        "era2_landlord_updated_w9_upload_id"
      );
      this.era3_landlord_updated_w9_upload = document.getElementById(
        "era3_landlord_updated_w9_upload_id"
      );
      this.era2_updated_program_participation_Form_upload =
        document.getElementById(
          "era2_updated_program_participation_Form_upload_id"
        );
      this.era3_updated_program_participation_Form_upload =
        document.getElementById(
          "era3_updated_program_participation_Form_upload_id"
        );
      this.era2_landlord_identity_upload = document.getElementById(
        "era2_landlord_identity_upload_id"
      );
      this.era3_landlord_identity_upload = document.getElementById(
        "era3_landlord_identity_upload_id"
      );
      this.era2_landlord_checkpoint_upload = document.getElementById(
        "era2_landlord_checkpoint_upload_id"
      );
      this.era3_landlord_checkpoint_upload = document.getElementById(
        "era3_landlord_checkpoint_upload_id"
      );
      // this.tenant_payment_agreement_upload = document.getElementById("tenant_payment_agreement_upload_id");
      // this.landlord_payment_agreement_upload = document.getElementById("landlord_payment_agreement_upload_id");

      if (this.photo_identification_upload !== null) {
        this.photo_identification_upload.disabled = true;
      }
      if (this.proof_of_address_upload !== null) {
        this.proof_of_address_upload.disabled = true;
      }
      if (this.proof_of_income_upload !== null) {
        this.proof_of_income_upload.disabled = true;
      }
      if (this.proof_of_income_loss_upload !== null) {
        this.proof_of_income_loss_upload.disabled = true;
      }
      if (this.ach_authorization_form_upload !== null) {
        this.ach_authorization_form_upload.disabled = true;
      }
      if (this.lease_agreement_upload !== null) {
        this.lease_agreement_upload.disabled = true;
      }
      if (this.utility_gas_bill_upload !== null) {
        this.utility_gas_bill_upload.disabled = true;
      }
      if (this.utility_electricity_bill_upload !== null) {
        this.utility_electricity_bill_upload.disabled = true;
      }
      if (this.landlord_w9_upload !== null) {
        this.landlord_w9_upload.disabled = true;
      }
      if (this.landlord_driver_license_upload !== null) {
        this.landlord_driver_license_upload.disabled = true;
      }
      if (this.verified_checkpoint_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.verified_checkpoint_upload.disabled = true;
      }
      if (this.tenant_checkPoint_id_verification_page_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.tenant_checkPoint_id_verification_page_upload.disabled = true;
      }
      if (this.era3_tenant_checkPoint_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.era3_tenant_checkPoint_upload.disabled = true;
      }

      if (this.era2021ReCert_landlord_updated_w9_upload !== null) {
        this.era2021ReCert_landlord_updated_w9_upload.disabled = true;
      }
      if (
        this.era2021ReCert_updated_program_participation_Form_upload !== null
      ) {
        this.era2021ReCert_updated_program_participation_Form_upload.disabled = true;
      }
      if (this.era2021ReCert_landlord_identity_upload !== null) {
        this.era2021ReCert_landlord_identity_upload.disabled = true;
      }
      if (this.era2021ReCert_landlord_checkpoint_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.era2021ReCert_landlord_checkpoint_upload.disabled = true;
      }

      if (this.era2_landlord_updated_w9_upload !== null) {
        this.era2_landlord_updated_w9_upload.disabled = true;
      }
      if (this.era3_landlord_updated_w9_upload !== null) {
        this.era3_landlord_updated_w9_upload.disabled = true;
      }

      if (this.era2_updated_program_participation_Form_upload !== null) {
        this.era2_updated_program_participation_Form_upload.disabled = true;
      }
      if (this.era3_updated_program_participation_Form_upload !== null) {
        this.era3_updated_program_participation_Form_upload.disabled = true;
      }

      if (this.era2_landlord_identity_upload !== null) {
        this.era2_landlord_identity_upload.disabled = true;
      }
      if (this.era3_landlord_identity_upload !== null) {
        this.era3_landlord_identity_upload.disabled = true;
      }

      if (this.era2_landlord_checkpoint_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.era2_landlord_checkpoint_upload.disabled = true;
      }
      if (this.era3_landlord_checkpoint_upload !== null && this.state.individualCase_Original !== "Additional Payment") {
        this.era3_landlord_checkpoint_upload.disabled = true;
      }
      // if (this.tenant_payment_agreement_upload !== null) {
      //     this.tenant_payment_agreement_upload.disabled = true;
      // }
      // if (this.landlord_payment_agreement_upload !== null) {
      //     this.landlord_payment_agreement_upload.disabled = true;
      // }
    }
  };

  loadUploadedDocuments = async () => {
    this.setState({ isLoading: true });

    document.getElementById(
      "photo_identification_id"
    ).innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
    document.getElementById(
      "proof_of_address_id"
    ).innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
    document.getElementById(
      "proof_of_income_id"
    ).innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
    document.getElementById(
      "proof_of_income_loss_id"
    ).innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
    document.getElementById(
      "ach_authorization_form_id"
    ).innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
    document.getElementById(
      "lease_agreement_id"
    ).innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
    document.getElementById(
      "utility_gas_bill_id"
    ).innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
    document.getElementById(
      "utility_electricity_bill_id"
    ).innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
    document.getElementById(
      "landlord_w9_id"
    ).innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
    document.getElementById(
      "landlord_driver_license_id"
    ).innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
    document.getElementById(
      "verified_checkpoint_id"
    ).innerHTML = `<td>Landlord CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
    document.getElementById(
      "tenant_checkPoint_id_verification_page_id"
    ).innerHTML = `<td>Tenant CheckPoint ID Verification Page<div id="tenant_checkPoint_id_verification_page_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_checkPoint_id_verification_page_upload_id" /></td>`;
    document.getElementById(
      "era3_tenant_checkPoint_id"
    ).innerHTML = `<td>ERA 3 Tenant CheckPoint ID<div id="era3_tenant_checkPoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_tenant_checkPoint_upload_id" /></td>`;
    document.getElementById(
      "era2021ReCert_landlord_updated_w9_id"
    ).innerHTML = `<td>Recert updated Landlord W9<div id="era2021ReCert_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_updated_w9_upload_id" /></td>`;
    document.getElementById(
      "era2021ReCert_updated_program_participation_Form_id"
    ).innerHTML = `<td>Recert updated Program Participation Form<div id="era2021ReCert_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_updated_program_participation_Form_upload_id" /></td>`;
    document.getElementById(
      "era2021ReCert_landlord_identity_id"
    ).innerHTML = `<td>Recert Landlord Identity<div id="era2021ReCert_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_identity_upload_id" /></td>`;
    document.getElementById(
      "era2021ReCert_landlord_checkpoint_id"
    ).innerHTML = `<td>Recert Landlord checkpoint ID Verification<div id="era2021ReCert_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_checkpoint_upload_id" /></td>`;
    document.getElementById(
      "era2_landlord_updated_w9_id"
    ).innerHTML = `<td>ERA2 updated Landlord W9<div id="era2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_updated_w9_upload_id" /></td>`;
    document.getElementById(
      "era3_landlord_updated_w9_id"
    ).innerHTML = `<td>ERA3 updated Landlord W9<div id="era3_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_updated_w9_upload_id" /></td>`;
    document.getElementById(
      "era2_updated_program_participation_Form_id"
    ).innerHTML = `<td>ERA2 updated Program Participation Form<div id="era2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_updated_program_participation_Form_upload_id" /></td>`;
    document.getElementById(
      "era3_updated_program_participation_Form_id"
    ).innerHTML = `<td>ERA3 updated Program Participation Form<div id="era3_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_updated_program_participation_Form_upload_id" /></td>`;
    document.getElementById(
      "era2_landlord_identity_id"
    ).innerHTML = `<td>ERA2 Landlord Identity<div id="era2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_identity_upload_id" /></td>`;
    document.getElementById(
      "era3_landlord_identity_id"
    ).innerHTML = `<td>ERA3 Landlord Identity<div id="era3_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_identity_upload_id" /></td>`;
    document.getElementById(
      "era2_landlord_checkpoint_id"
    ).innerHTML = `<td>ERA2 Landlord checkpoint ID Verification<div id="era2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_checkpoint_upload_id" /></td>`;
    document.getElementById(
      "era3_landlord_checkpoint_id"
    ).innerHTML = `<td>ERA3 Landlord checkpoint ID Verification<div id="era3_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_checkpoint_upload_id" /></td>`;
    document.getElementById(
      "tenant_payment_agreement_id"
    ).innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
    document.getElementById(
      "landlord_payment_agreement_id"
    ).innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;

    // Lock documents if individual case is set to other than 'Open' and/or 'Additional Payment'
    this.lockDocuments();

    // Reset all the flags
    this.setState({
      photo_identification_uploaded: false,
      proof_of_address_uploaded: false,
      proof_of_income_uploaded: false,
      proof_of_income_loss_uploaded: false,
      ach_authorization_form_uploaded: false,
      lease_agreement_uploaded: false,
      utility_gas_bill_uploaded: false,
      utility_electricity_bill_uploaded: false,
      landlord_w9_uploaded: false,
      landlord_driver_license_uploaded: false,
      verified_checkpoint_uploaded: false,
      tenant_checkPoint_id_verification_page_uploaded: false,
      era3_tenant_checkPoint_uploaded: false,
      era2021ReCert_landlord_updated_w9_uploaded: false,
      era2021ReCert_updated_program_participation_Form_uploaded: false,
      era2021ReCert_landlord_identity_uploaded: false,
      era2021ReCert_landlord_checkpoint_uploaded: false,
      era2_landlord_updated_w9_uploaded: false,
      era3_landlord_updated_w9_uploaded: false,
      era2_updated_program_participation_Form_uploaded: false,
      era3_updated_program_participation_Form_uploaded: false,
      era2_landlord_identity_uploaded: false,
      era3_landlord_identity_uploaded: false,
      era2_landlord_checkpoint_uploaded: false,
      era3_landlord_checkpoint_uploaded: false,
      tenant_payment_agreement_uploaded: false,
      landlord_payment_agreement_uploaded: false,
    });

    Storage.configure({
      bucket: process.env.REACT_APP_S3FrontendBucket,
      region: process.env.REACT_APP_Region,
      level: "public",
    });

    //get file names for s3 keys
    try {
      var key = `SantaAna/Case/Case_${this.state.individualID}/`;
      var files = await Storage.list(key);
      //console.log(files);
    } catch (err) {
      console.log("Error Files - " + err);
      this.setState({ isLoading: false });
      return 1;
    }

    for (var i = 0; i < files.length; i++) {
      if (files[i].key.includes("/photo_identification/")) {
        try {
          var file1 = await Storage.get(files[i].key);
          this.setState({ photo_identification: file1 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "photo_identification_id"
            ).innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "photo_identification_id"
            ).innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.photo_identification
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag1 = document.getElementById("deleteS3File1");
            if (aTag1 !== null && aTag1 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag1.style.visibility = "hidden";
              } else {
                if (aTag1.addEventListener) {
                  aTag1.addEventListener("click", () => {
                    this.deleteS3File(1);
                  });
                } else if (aTag1.attachEvent) {
                  aTag1.attachEvent("click", () => {
                    this.deleteS3File(1);
                  });
                }
              }
            }
          }
          this.setState({ photo_identification_uploaded: true });
        } catch (err) {
          console.log("Error photo_identification - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "photo_identification_id"
          ).innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
          return 1;
        }
      } else if (files[i].key.includes("/proof_of_address/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ proof_of_address: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "proof_of_address_id"
            ).innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_address
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "proof_of_address_id"
            ).innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_address
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File2");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(2);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(2);
                  });
                }
              }
            }
          }
          this.setState({ proof_of_address_uploaded: true });
        } catch (err) {
          console.log("Error proof_of_address - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "proof_of_address_id"
          ).innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
          return 2;
        }
      } else if (files[i].key.includes("/proof_of_income/")) {
        try {
          var file31 = await Storage.get(files[i].key);
          this.setState({ proof_of_income: file31 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "proof_of_income_id"
            ).innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "proof_of_income_id"
            ).innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag31 = document.getElementById("deleteS3File3");
            if (aTag31 !== null && aTag31 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag31.style.visibility = "hidden";
              } else {
                if (aTag31.addEventListener) {
                  aTag31.addEventListener("click", () => {
                    this.deleteS3File(3);
                  });
                } else if (aTag31.attachEvent) {
                  aTag31.attachEvent("click", () => {
                    this.deleteS3File(3);
                  });
                }
              }
            }
          }
          this.setState({ proof_of_income_uploaded: true });
        } catch (err) {
          console.log("Error proof_of_income - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "proof_of_income_id"
          ).innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
          return 3;
        }
      } else if (files[i].key.includes("/proof_of_income_loss/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ proof_of_income_loss: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "proof_of_income_loss_id"
            ).innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "proof_of_income_loss_id"
            ).innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_income_loss
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File4");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(4);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(4);
                  });
                }
              }
            }
          }
          this.setState({ proof_of_income_loss_uploaded: true });
        } catch (err) {
          console.log("Error proof_of_income_loss - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "proof_of_income_loss_id"
          ).innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
          return 4;
        }
      } else if (files[i].key.includes("/ach_authorization_form/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ ach_authorization_form: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "ach_authorization_form_id"
            ).innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ach_authorization_form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "ach_authorization_form_id"
            ).innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.ach_authorization_form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File5");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(5);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(5);
                  });
                }
              }
            }
          }

          this.setState({ ach_authorization_form_uploaded: true });
        } catch (err) {
          console.log("Error ach_authorization_form - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "ach_authorization_form_id"
          ).innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
          return 5;
        }
      } else if (files[i].key.includes("/lease_agreement/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ lease_agreement: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "lease_agreement_id"
            ).innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "lease_agreement_id"
            ).innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.lease_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File6");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(6);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(6);
                  });
                }
              }
            }
          }
          this.setState({ lease_agreement_uploaded: true });
        } catch (err) {
          console.log("Error lease_agreement - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "lease_agreement_id"
          ).innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
          return 6;
        }
      } else if (files[i].key.includes("/utility_gas_bill/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ utility_gas_bill: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "utility_gas_bill_id"
            ).innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_gas_bill
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "utility_gas_bill_id"
            ).innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_gas_bill
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File7");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(7);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(7);
                  });
                }
              }
            }
          }
          this.setState({ utility_gas_bill_uploaded: true });
        } catch (err) {
          console.log("Error utility_gas_bill - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "utility_gas_bill_id"
          ).innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
          return 7;
        }
      } else if (files[i].key.includes("/utility_electricity_bill/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ utility_electricity_bill: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "utility_electricity_bill_id"
            ).innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_electricity_bill
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "utility_electricity_bill_id"
            ).innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.utility_electricity_bill
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File8");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(8);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(8);
                  });
                }
              }
            }
          }
          this.setState({ utility_electricity_bill_uploaded: true });
        } catch (err) {
          console.log("Error utility_electricity_bill - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "utility_electricity_bill_id"
          ).innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
          return 8;
        }
      } else if (files[i].key.includes("/landlord_w9/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ landlord_w9: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "landlord_w9_id"
            ).innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "landlord_w9_id"
            ).innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File9");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(9);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(9);
                  });
                }
              }
            }
          }
          this.setState({ landlord_w9_uploaded: true });
        } catch (err) {
          console.log("Error landlord_w9 - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "landlord_w9_id"
          ).innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
          return 9;
        }
      } else if (files[i].key.includes("/landlord_driver_license/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ landlord_driver_license: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "landlord_driver_license_id"
            ).innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "landlord_driver_license_id"
            ).innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File10");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(10);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(10);
                  });
                }
              }
            }
          }
          this.setState({ landlord_driver_license_uploaded: true });
        } catch (err) {
          console.log("Error landlord_driver_license - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "landlord_driver_license_id"
          ).innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
          return 10;
        }
      } else if (files[i].key.includes("/verified_checkpoint/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ verified_checkpoint: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "verified_checkpoint_id"
            ).innerHTML = `<td>Landlord CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "verified_checkpoint_id"
            ).innerHTML = `<td>Landlord CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.verified_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File11");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(11);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(11);
                  });
                }
              }
            }
          }
          this.setState({ verified_checkpoint_uploaded: true });
        } catch (err) {
          console.log("Error verified_checkpoint - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "verified_checkpoint_id"
          ).innerHTML = `<td>Landlord CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
          return 11;
        }
      } else if (files[i].key.includes("/era2021ReCert_landlord_updated_w9/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2021ReCert_landlord_updated_w9: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2021ReCert_landlord_updated_w9_id"
            ).innerHTML = `<td>Recert updated Landlord W9<div id="era2021ReCert_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2021ReCert_landlord_updated_w9_id"
            ).innerHTML = `<td>Recert updated Landlord W9<div id="era2021ReCert_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File12");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(12);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(12);
                  });
                }
              }
            }
          }
          this.setState({ era2021ReCert_landlord_updated_w9_uploaded: true });
        } catch (err) {
          console.log("Error era2021ReCert_landlord_updated_w9 - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2021ReCert_landlord_updated_w9_id"
          ).innerHTML = `<td>Recert updated Landlord W9<div id="era2021ReCert_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_updated_w9_upload_id" /></td>`;
          return 12;
        }
      } else if (
        files[i].key.includes(
          "/era2021ReCert_updated_program_participation_Form/"
        )
      ) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({
            era2021ReCert_updated_program_participation_Form: file2,
          });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2021ReCert_updated_program_participation_Form_id"
            ).innerHTML = `<td>Recert updated Program Participation Form<div id="era2021ReCert_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2021ReCert_updated_program_participation_Form_id"
            ).innerHTML = `<td>Recert updated Program Participation Form<div id="era2021ReCert_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File13");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(13);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(13);
                  });
                }
              }
            }
          }
          this.setState({
            era2021ReCert_updated_program_participation_Form_uploaded: true,
          });
        } catch (err) {
          console.log(
            "Error era2021ReCert_updated_program_participation_Form - " + err
          );
          this.setState({ isLoading: false });
          document.getElementById(
            "era2021ReCert_updated_program_participation_Form_id"
          ).innerHTML = `<td>Recert updated Program Participation Form<div id="era2021ReCert_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_updated_program_participation_Form_upload_id" /></td>`;
          return 13;
        }
      } else if (files[i].key.includes("/era2021ReCert_landlord_identity/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2021ReCert_landlord_identity: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2021ReCert_landlord_identity_id"
            ).innerHTML = `<td>Recert Landlord Identity<div id="era2021ReCert_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2021ReCert_landlord_identity_id"
            ).innerHTML = `<td>Recert Landlord Identity<div id="era2021ReCert_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File14");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(14);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(14);
                  });
                }
              }
            }
          }
          this.setState({ era2021ReCert_landlord_identity_uploaded: true });
        } catch (err) {
          console.log("Error era2021ReCert_landlord_identity - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2021ReCert_landlord_identity_id"
          ).innerHTML = `<td>Recert Landlord Identity<div id="era2021ReCert_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_identity_upload_id" /></td>`;
          return 14;
        }
      } else if (files[i].key.includes("/era2021ReCert_landlord_checkpoint/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2021ReCert_landlord_checkpoint: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2021ReCert_landlord_checkpoint_id"
            ).innerHTML = `<td>Recert Landlord checkpoint ID Verification<div id="era2021ReCert_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2021ReCert_landlord_checkpoint_id"
            ).innerHTML = `<td>Recert Landlord checkpoint ID Verification<div id="era2021ReCert_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2021ReCert_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File15");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(15);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(15);
                  });
                }
              }
            }
          }
          this.setState({ era2021ReCert_landlord_checkpoint_uploaded: true });
        } catch (err) {
          console.log("Error era2021ReCert_landlord_checkpoint - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2021ReCert_landlord_checkpoint_id"
          ).innerHTML = `<td>Recert Landlord checkpoint ID Verification<div id="era2021ReCert_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_checkpoint_upload_id" /></td>`;
          return 15;
        }
      }
      //era 2
      else if (files[i].key.includes("/era2_landlord_updated_w9/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2_landlord_updated_w9: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA2 updated Landlord W9<div id="era2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA2 updated Landlord W9<div id="era2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File16"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File16");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(16);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(16);
                  });
                }
              }
            }
          }
          this.setState({ era2_landlord_updated_w9_uploaded: true });
        } catch (err) {
          console.log("Error era2_landlord_updated_w9 - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2_landlord_updated_w9_id"
          ).innerHTML = `<td>ERA2 updated Landlord W9<div id="era2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_updated_w9_upload_id" /></td>`;
          return 16;
        }
      } else if (
        files[i].key.includes("/era2_updated_program_participation_Form/")
      ) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2_updated_program_participation_Form: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA2 updated Program Participation Form<div id="era2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA2 updated Program Participation Form<div id="era2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File17"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File17");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(17);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(17);
                  });
                }
              }
            }
          }
          this.setState({
            era2_updated_program_participation_Form_uploaded: true,
          });
        } catch (err) {
          console.log("Error era2_updated_program_participation_Form - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2_updated_program_participation_Form_id"
          ).innerHTML = `<td>ERA2 updated Program Participation Form<div id="era2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_updated_program_participation_Form_upload_id" /></td>`;
          return 17;
        }
      } else if (files[i].key.includes("/era2_landlord_identity/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2_landlord_identity: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2_landlord_identity_id"
            ).innerHTML = `<td>ERA2 Landlord Identity<div id="era2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File18"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2_landlord_identity_id"
            ).innerHTML = `<td>ERA2 Landlord Identity<div id="era2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File18"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File18");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(18);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(18);
                  });
                }
              }
            }
          }
          this.setState({ era2_landlord_identity_uploaded: true });
        } catch (err) {
          console.log("Error era2_landlord_identity - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2_landlord_identity_id"
          ).innerHTML = `<td>ERA2 Landlord Identity<div id="era2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_identity_upload_id" /></td>`;
          return 18;
        }
      } else if (files[i].key.includes("/era2_landlord_checkpoint/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era2_landlord_checkpoint: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era2_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA2 Landlord checkpoint ID Verification<div id="era2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File19"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era2_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA2 Landlord checkpoint ID Verification<div id="era2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era2_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File19"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File19");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(19);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(19);
                  });
                }
              }
            }
          }
          this.setState({ era2_landlord_checkpoint_uploaded: true });
        } catch (err) {
          console.log("Error era2_landlord_checkpoint - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era2_landlord_checkpoint_id"
          ).innerHTML = `<td>ERA2 Landlord checkpoint ID Verification<div id="era2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_checkpoint_upload_id" /></td>`;
          return 19;
        }
      } else if (files[i].key.includes("/tenant_payment_agreement/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ tenant_payment_agreement: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "tenant_payment_agreement_id"
            ).innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File20"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "tenant_payment_agreement_id"
            ).innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_payment_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File20"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File20");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(20);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(20);
                  });
                }
              }
            }
          }
          this.setState({ tenant_payment_agreement_uploaded: true });
        } catch (err) {
          console.log("Error tenant_payment_agreement - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "tenant_payment_agreement_id"
          ).innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
          return 20;
        }
      } else if (files[i].key.includes("/landlord_payment_agreement/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ landlord_payment_agreement: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "landlord_payment_agreement_id"
            ).innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File21"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "landlord_payment_agreement_id"
            ).innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_payment_agreement
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File21"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File21");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(21);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(21);
                  });
                }
              }
            }
          }
          this.setState({ landlord_payment_agreement_uploaded: true });
        } catch (err) {
          console.log("Error landlord_payment_agreement - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "landlord_payment_agreement_id"
          ).innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
          return 21;
        }
      } else if (
        files[i].key.includes("tenant_checkPoint_id_verification_page/")
      ) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ tenant_checkPoint_id_verification_page: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "tenant_checkPoint_id_verification_page_id"
            ).innerHTML = `<td>Tenant CheckPoint ID Verification Page<div id="tenant_checkPoint_id_verification_page_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_checkPoint_id_verification_page
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File22"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "tenant_checkPoint_id_verification_page_id"
            ).innerHTML = `<td>Tenant CheckPoint ID Verification Page<div id="tenant_checkPoint_id_verification_page_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tenant_checkPoint_id_verification_page
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File22"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File22");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(22);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(22);
                  });
                }
              }
            }
          }
          this.setState({
            tenant_checkPoint_id_verification_page_uploaded: true,
          });
        } catch (err) {
          console.log("Error tenant_checkPoint_id_verification_page - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "tenant_checkPoint_id_verification_page_id"
          ).innerHTML = `<td>Tenant CheckPoint ID Verification Page<div id="tenant_checkPoint_id_verification_page_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_checkPoint_id_verification_page_upload_id" /></td>`;
          return 22;
        }
      }
      //checkpoint era3
      else if (
        files[i].key.includes("era3_tenant_checkPoint/")
      ) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era3_tenant_checkPoint: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era3_tenant_checkPoint_id"
            ).innerHTML = `<td>ERA 3 Tenant CheckPoint ID<div id="era3_tenant_checkPoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_tenant_checkPoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File27"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era3_tenant_checkPoint_id"
            ).innerHTML = `<td>ERA 3 Tenant CheckPoint ID<div id="era3_tenant_checkPoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_tenant_checkPoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File27"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File27");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(27);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(27);
                  });
                }
              }
            }
          }
          this.setState({
            era3_tenant_checkPoint_uploaded: true,
          });
        } catch (err) {
          console.log("Error era3_tenant_checkPoint - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era3_tenant_checkPoint_id"
          ).innerHTML = `<td>ERA 3 Tenant CheckPoint<div id="era3_tenant_checkPoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_tenant_checkPoint_upload_id" /></td>`;
          return 27;
        }
      }
      // era3
      else if (files[i].key.includes("/era3_landlord_updated_w9/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era3_landlord_updated_w9: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era3_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA3 updated Landlord W9<div id="era3_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File23"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era3_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA3 updated Landlord W9<div id="era3_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_updated_w9
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File23"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File23");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(23);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(23);
                  });
                }
              }
            }
          }
          this.setState({ era3_landlord_updated_w9_uploaded: true });
        } catch (err) {
          console.log("Error era3_landlord_updated_w9 - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era3_landlord_updated_w9_id"
          ).innerHTML = `<td>ERA3 updated Landlord W9<div id="era3_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_updated_w9_upload_id" /></td>`;
          return 23;
        }
      } else if (files[i].key.includes("/era3_landlord_identity/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era3_landlord_identity: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era3_landlord_identity_id"
            ).innerHTML = `<td>ERA3 Landlord Identity<div id="era3_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File24"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era3_landlord_identity_id"
            ).innerHTML = `<td>ERA3 Landlord Identity<div id="era3_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_identity
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File24"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File24");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(24);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(24);
                  });
                }
              }
            }
          }
          this.setState({ era3_landlord_identity_uploaded: true });
        } catch (err) {
          console.log("Error era3_landlord_identity - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era3_landlord_identity_id"
          ).innerHTML = `<td>ERA3 Landlord Identity<div id="era3_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_identity_upload_id" /></td>`;
          return 24;
        }
      } else if (files[i].key.includes("/era3_landlord_checkpoint/")) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era3_landlord_checkpoint: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era3_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA3 Landlord checkpoint ID Verification<div id="era3_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File25"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era3_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA3 Landlord checkpoint ID Verification<div id="era3_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_landlord_checkpoint
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File25"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File25");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open" && this.state.individualCase_Original !== "Additional Payment") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(25);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(25);
                  });
                }
              }
            }
          }
          this.setState({ era3_landlord_checkpoint_uploaded: true });
        } catch (err) {
          console.log("Error era3_landlord_checkpoint - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era3_landlord_checkpoint_id"
          ).innerHTML = `<td>ERA3 Landlord checkpoint ID Verification<div id="era3_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_checkpoint_upload_id" /></td>`;
          return 25;
        }
      } else if (
        files[i].key.includes("/era3_updated_program_participation_Form/")
      ) {
        try {
          var file2 = await Storage.get(files[i].key);
          this.setState({ era3_updated_program_participation_Form: file2 });

          var shortFileName = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")[0];
          var fileExtension = files[i].key
            .replace(/^.*[\\\/]/, "")
            .split(".")
            .pop();
          if (shortFileName.length > 35) {
            shortFileName = shortFileName.substring(0, 35) + "...";
          }
          shortFileName = shortFileName + "." + fileExtension;

          var lastModified = `${files[i].lastModified}`;
          if (
            lastModified !== null &&
            lastModified !== undefined &&
            lastModified.indexOf(" (") !== -1
          ) {
            lastModified = lastModified.substring(
              0,
              lastModified.indexOf(" (")
            );
          }

          if (this.state.mode && this.state.mode == "User") {
            document.getElementById(
              "era3_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA3 updated Program Participation Form<div id="era3_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File26"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
          } else {
            document.getElementById(
              "era3_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA3 updated Program Participation Form<div id="era3_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[
              i
            ].key.replace(/^.*[\\\/]/, "")}'><i class='${this.getFileIconType(
              files[i].key
            )} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.era3_updated_program_participation_Form
              }><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File26"><i class="fas fa-trash-alt red"></i></a></td>`;
            var aTag2 = document.getElementById("deleteS3File26");
            if (aTag2 !== null && aTag2 !== undefined) {
              if (this.state.individualCase_Original !== "Open") {
                aTag2.style.visibility = "hidden";
              } else {
                if (aTag2.addEventListener) {
                  aTag2.addEventListener("click", () => {
                    this.deleteS3File(26);
                  });
                } else if (aTag2.attachEvent) {
                  aTag2.attachEvent("click", () => {
                    this.deleteS3File(26);
                  });
                }
              }
            }
          }
          this.setState({
            era3_updated_program_participation_Form_uploaded: true,
          });
        } catch (err) {
          console.log("Error era3_updated_program_participation_Form - " + err);
          this.setState({ isLoading: false });
          document.getElementById(
            "era3_updated_program_participation_Form_id"
          ).innerHTML = `<td>ERA3 updated Program Participation Form<div id="era3_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_updated_program_participation_Form_upload_id" /></td>`;
          return 26;
        }
      }
    }

    this.setState({ isLoading: false });

    return 0;
  };
  
  deleteS3FileMicDoc = async (fileToDelete) => {
    var result = window.confirm(
      "Are you sure you want to delete selected document?"
    );
    if (result === false) {
      return;
    }
    Storage.configure({
      bucket: process.env.REACT_APP_S3FrontendBucket,
      region: process.env.REACT_APP_Region,
      level: "public",
    });

    try {
      var key = `SantaAna/Case/Case_${this.state.individualID}/miscellaneous/`;
      var files = await Storage.list(key);
      this.setState({ isLoading: true });
      for (var i = 0; i < files.length; i++) {
        if (files[i].key.includes(fileToDelete)) {
          var key1 = files[i].key;
          var res = await Storage.remove(key1);
          console.log(res);
        }
      }
      await this.getMiscDocument();
    } catch (err) {
      console.log(err);
      this.setState({ msg: "Failed to delete file" });
      this.setState({ isLoading: false });
      return;
    }

    this.setState({ isLoading: false });
  };

  
  deleteS3File = async (fileToDelete) => {
    var result = window.confirm(
      "Are you sure you want to delete selected document?"
    );
    if (result === false) {
      return;
    }

    Storage.configure({
      bucket: process.env.REACT_APP_S3FrontendBucket,
      region: process.env.REACT_APP_Region,
      level: "public",
    });

    this.setState({ isLoading: true });
    try {
      var key = `SantaAna/Case/Case_${this.state.individualID}/`;
      var files = await Storage.list(key);
      //console.log(files);

      // Remove the earlier files for the section for which the new files to be uploaded

      switch (fileToDelete) {
        case 1:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/photo_identification/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "photo_identification_id"
            ).innerHTML = `<td>Photo Identification<div id="photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="photo_identification_upload_id" /></td>`;
            this.setState({ photo_identification: "" });
          }
          break;
        case 2:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/proof_of_address/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "proof_of_address_id"
            ).innerHTML = `<td>Proof of Address<div id="proof_of_address_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_address_upload_id" /></td>`;
            this.setState({ proof_of_address: "" });
          }
          break;
        case 3:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/proof_of_income/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "proof_of_income_id"
            ).innerHTML = `<td>Proof of Income<div id="proof_of_income_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_upload_id" /></td>`;
            this.setState({ proof_of_income: "" });
          }
          break;
        case 4:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/proof_of_income_loss/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "proof_of_income_loss_id"
            ).innerHTML = `<td>Proof of Income loss<div id="proof_of_income_loss_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_income_loss_upload_id" /></td>`;
            this.setState({ proof_of_income_loss: "" });
          }
          break;
        case 5:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/ach_authorization_form/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "ach_authorization_form_id"
            ).innerHTML = `<td>Program Participation Form<div id="ach_authorization_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="ach_authorization_form_upload_id" /></td>`;
            this.setState({ ach_authorization_form: "" });
          }
          break;
        case 6:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/lease_agreement/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "lease_agreement_id"
            ).innerHTML = `<td>Lease Agreement<div id="lease_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="lease_agreement_upload_id" /></td>`;
            this.setState({ lease_agreement: "" });
          }
          break;
        case 7:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/utility_gas_bill/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "utility_gas_bill_id"
            ).innerHTML = `<td>Utility Gas Bill<div id="utility_gas_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_gas_bill_upload_id" /></td>`;
            this.setState({ utility_gas_bill: "" });
          }
          break;
        case 8:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/utility_electricity_bill/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "utility_electricity_bill_id"
            ).innerHTML = `<td>Utility Electricity Bill<div id="utility_electricity_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="utility_electricity_bill_upload_id" /></td>`;
            this.setState({ utility_electricity_bill: "" });
          }
          break;
        case 9:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/landlord_w9/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
                await this.textExtractW9Delete(files[i].key, "ERA2021");
              }
            }
            document.getElementById(
              "landlord_w9_id"
            ).innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_w9_upload_id" /></td>`;
            this.setState({ landlord_w9: "" });
          }
          break;
        case 10:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/landlord_driver_license/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "landlord_driver_license_id"
            ).innerHTML = `<td>Landlord Identification<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_driver_license_upload_id" /></td>`;
            this.setState({ landlord_driver_license: "" });
          }
          break;
        case 11:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/verified_checkpoint/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "verified_checkpoint_id"
            ).innerHTML = `<td>Landlord CheckPoint ID Verification Page<div id="verified_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="verified_checkpoint_upload_id" /></td>`;
            this.setState({ verified_checkpoint: "" });
          }
          break;
        case 12:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2021ReCert_landlord_updated_w9/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
                await this.textExtractW9Delete(files[i].key, "ERA2021ReCert");
              }
            }
            document.getElementById(
              "era2021ReCert_landlord_updated_w9_id"
            ).innerHTML = `<td>Recert updated Landlord W9<div id="era2021ReCert_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_updated_w9_upload_id" /></td>`;
            this.setState({ era2021ReCert_landlord_updated_w9: "" });
          }
          break;
        case 13:
          {
            for (var i = 0; i < files.length; i++) {
              if (
                files[i].key.includes(
                  "/era2021ReCert_updated_program_participation_Form/"
                )
              ) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2021ReCert_updated_program_participation_Form_id"
            ).innerHTML = `<td>Recert updated Program Participation Form<div id="era2021ReCert_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_updated_program_participation_Form_upload_id" /></td>`;
            this.setState({
              era2021ReCert_updated_program_participation_Form: "",
            });
          }
          break;
        case 14:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2021ReCert_landlord_identity/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2021ReCert_landlord_identity_id"
            ).innerHTML = `<td>Recert Landlord Identity<div id="era2021ReCert_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_identity_upload_id" /></td>`;
            this.setState({ era2021ReCert_landlord_identity: "" });
          }
          break;
        case 15:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2021ReCert_landlord_checkpoint/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2021ReCert_landlord_checkpoint_id"
            ).innerHTML = `<td>Recert Landlord checkpoint ID Verification<div id="era2021ReCert_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2021ReCert_landlord_checkpoint_upload_id" /></td>`;
            this.setState({ era2021ReCert_landlord_checkpoint: "" });
          }
          break;
        case 16:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2_landlord_updated_w9/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
                await this.textExtractW9Delete(files[i].key, "ERA2");
              }
            }
            document.getElementById(
              "era2_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA2 updated Landlord W9<div id="era2_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_updated_w9_upload_id" /></td>`;
            this.setState({ era2_landlord_updated_w9: "" });
          }
          break;
        case 17:
          {
            for (var i = 0; i < files.length; i++) {
              if (
                files[i].key.includes(
                  "/era2_updated_program_participation_Form/"
                )
              ) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA2 updated Program Participation Form<div id="era2_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_updated_program_participation_Form_upload_id" /></td>`;
            this.setState({ era2_updated_program_participation_Form: "" });
          }
          break;
        case 18:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2_landlord_identity/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2_landlord_identity_id"
            ).innerHTML = `<td>ERA2 Landlord Identity<div id="era2_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_identity_upload_id" /></td>`;
            this.setState({ era2_landlord_identity: "" });
          }
          break;
        case 19:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era2_landlord_checkpoint/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era2_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA2 Landlord checkpoint ID Verification<div id="era2_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era2_landlord_checkpoint_upload_id" /></td>`;
            this.setState({ era2_landlord_checkpoint: "" });
          }
          break;
        case 20:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/tenant_payment_agreement/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "tenant_payment_agreement_id"
            ).innerHTML = `<td>Tenant Payment Agreement<div id="tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_payment_agreement_upload_id" /></td>`;
            this.setState({ tenant_payment_agreement: "" });
          }
          break;
        case 21:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/landlord_payment_agreement/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "landlord_payment_agreement_id"
            ).innerHTML = `<td>Landlord Payment Agreement<div id="landlord_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="landlord_payment_agreement_upload_id" /></td>`;
            this.setState({ landlord_payment_agreement: "" });
          }
          break;
        case 22:
          {
            for (var i = 0; i < files.length; i++) {
              if (
                files[i].key.includes("tenant_checkPoint_id_verification_page/")
              ) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "tenant_checkPoint_id_verification_page_id"
            ).innerHTML = `<td>Tenant CheckPoint ID Verification Page<div id="tenant_checkPoint_id_verification_page_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tenant_checkPoint_id_verification_page_upload_id" /></td>`;
            this.setState({ tenant_checkPoint_id_verification_page: "" });
          }
          break;
        case 23:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era3_landlord_updated_w9/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
                await this.textExtractW9Delete(files[i].key, "ERA3");
              }
            }
            document.getElementById(
              "era3_landlord_updated_w9_id"
            ).innerHTML = `<td>ERA3 updated Landlord W9<div id="era3_landlord_updated_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_updated_w9_upload_id" /></td>`;
            this.setState({ era3_landlord_updated_w9: "" });
          }
          break;
        case 24:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era3_landlord_identity/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era3_landlord_identity_id"
            ).innerHTML = `<td>ERA3 Landlord Identity<div id="era3_landlord_identity_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_identity_upload_id" /></td>`;
            this.setState({ era3_landlord_identity: "" });
          }
          break;
        case 25:
          {
            for (var i = 0; i < files.length; i++) {
              if (files[i].key.includes("/era3_landlord_checkpoint/")) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era3_landlord_checkpoint_id"
            ).innerHTML = `<td>ERA3 Landlord checkpoint ID Verification<div id="era3_landlord_checkpoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_landlord_checkpoint_upload_id" /></td>`;
            this.setState({ era3_landlord_checkpoint: "" });
          }
          break;
        case 26:
          {
            for (var i = 0; i < files.length; i++) {
              if (
                files[i].key.includes(
                  "/era3_updated_program_participation_Form/"
                )
              ) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era3_updated_program_participation_Form_id"
            ).innerHTML = `<td>ERA3 updated Program Participation Form<div id="era3_updated_program_participation_Form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_updated_program_participation_Form_upload_id" /></td>`;
            this.setState({ era3_updated_program_participation_Form: "" });
          }
          break;
        case 27:
          {
            for (var i = 0; i < files.length; i++) {
              if (
                files[i].key.includes("/era3_tenant_checkPoint/")
              ) {
                var key1 = files[i].key;
                var res = await Storage.remove(key1);
                //console.log(res);
              }
            }
            document.getElementById(
              "era3_tenant_checkPoint_id"
            ).innerHTML = `<td>ERA 3 Tenant CheckPoint ID<div id="era3_tenant_checkPoint_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="era3_tenant_checkPoint_upload_id" /></td>`;
            this.setState({ era3_tenant_checkPoint: "" });
          }
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
      this.setState({ msg: "Failed to delete file" });
      this.setState({ isLoading: false });
      return;
    }

    this.setState({ isLoading: false });

    var safeload = await this.loadUploadedDocuments();
  };

  getFileIconType(filename) {
    let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

    switch (extension) {
      case "pdf":
        return "fas fa-file-pdf";
      case "jpeg":
      case "jpg":
      case "png":
      case "gif":
      case "bmp":
        return "fas fa-file-image";
      case "txt":
        return "fas fa-file-alt";
      case "doc":
      case "docx":
        return "fas fa-file-word";
      case "xls":
      case "xlsx":
        return "fas fa-file-excel";
      case "ppt":
      case "pptx":
        return "fas fa-file-powerpoint";
      default:
        // any other file
        return "fas fa-file";
    }
  }

  handleSpeedDialClick = (action) => {
    switch (action) {
      case "save":
        this.handleUpdate();
        break;
      case "cancel":
        this.handleBack();
        break;
      default:
        break;
    }
  };

  handleSpeedDialClose = () => {
    this.setState({ speedDialOpen: false });
  };

  handleSpeedDialOpen = () => {
    this.setState({ speedDialOpen: true });
  };

  handleUpdate = async () => {
    this.setState({ isLoading: true });

    // Check input field validations if any
    if (this.validateControls() === false) {
      // Validation Failed - early return.
      this.setState({ isLoading: false });
      return;
    }
    try {
      var user = await Auth.currentAuthenticatedUser();
      // return user;
    }
    catch (e) {
      this.setState({ isLoading: false });
      if (e === "not authenticated") {
        await this.signOut();
        return;
      }
    }

    // if(this.state.paymentMonths2021 && this.state.paymentMonths2021.length > 0) {
    //   console.log("if called in save");
    //   console.log("payment value = ");
    //   console.log(this.state.paymentMonths2021);
    //   console.log("payment clone value = ")
    //   console.log(this.state.paymentMonths2021Clone)
    //   let clone  = this.state.paymentMonths2021Clone;
    //   var is_same = this.state.paymentMonths2021.length == this.state.paymentMonths2021Clone.length && this.state.paymentMonths2021.every(function(element, index) {
    //     return element === clone[index]; 
    //   });
    //   console.log(is_same);
    // }
    await this.calculateTotalIssued();
    var d = new Date(
      new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
    );
    var todaysDate = [
      d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-");

    var todaysTime = [
      ("0" + d.getHours()).slice(-2),
      ("0" + d.getMinutes()).slice(-2),
      ("0" + d.getSeconds()).slice(-2),
    ].join(":");


    let tramodify = this.state.adminUsername;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        createdDate: todaysDate,
        modifiedDate: todaysDate,
        createdTime: todaysTime,
        modifiedTime: todaysTime,
        modifiedBy: tramodify,
        createdBy: tramodify,
      },
    }));
    this.setState((prevState) => ({
      individualpaymentDetailsClone: {
        ...prevState.individualpaymentDetailsClone,
        createdDate: todaysDate,
        modifiedDate: todaysDate,
        createdTime: todaysTime,
        modifiedTime: todaysTime,
        modifiedBy: tramodify,
        createdBy: tramodify,
      },
    }));
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        createdDate: todaysDate,
        modifiedDate: todaysDate,
        createdTime: todaysTime,
        modifiedTime: todaysTime,
        modifiedBy: tramodify,
        createdBy: tramodify,
      },
    }));
    this.setState((prevState) => ({
      landlordpaymentDetailsClone: {
        ...prevState.landlordpaymentDetailsClone,
        createdDate: todaysDate,
        modifiedDate: todaysDate,
        createdTime: todaysTime,
        modifiedTime: todaysTime,
        modifiedBy: tramodify,
        createdBy: tramodify,
      },
    }));

    if (
      this.state.rentPaymentStatus_Original !==
      this.state.landlordDetails.entityPaymentStatus
    ) {
      if (
        this.state.landlordDetails.entityPaymentStatus === "Issued" &&
        this.state.applicantDetails.spaServiceProviderEmail !== null &&
        this.state.applicantDetails.spaServiceProviderEmail !== ""
      ) {
        let tempEmail = this.state.applicantDetails.landlordEmail;
        if (
          this.state.applicantDetails.landlordEmail === null ||
          this.state.applicantDetails.landlordEmail === "" ||
          this.state.applicantDetails.landlordEmail === undefined
        ) {
          tempEmail = "JennO@UnitedWayOC.org";
        }

        this.setState((prevState) => ({
          landlordpaymentDetails: {
            ...prevState.landlordpaymentDetails,
            informationStatus: "Issued"
          },
        }));

        var result = window.confirm(
          `You have updated rent status to Issued. This will trigger an email to landlord on ${tempEmail} and service provider on ${this.state.applicantDetails.spaServiceProviderEmail}. Please click Ok to confirm.`
        );
        if (result !== false) {
          this.setState((prevState) => ({
            landlordDetails: {
              ...prevState.landlordDetails,
              dateEmailTriggered: todaysDate,
              timeEmailTriggered: todaysTime,
              entityDatePaymentStatusUpdated: todaysDate,
              entityTimePaymentStatusUpdated: todaysTime,
            },
          }));

          this.setState({
            triggerEmail: "1",
          });
        } else {
          this.rentPaymentStatusRef.current.focus();

          this.setState({ isLoading: false, triggerEmail: "0" });
          return;
        }
      } else {
        this.setState({
          triggerEmail: "0",
          // dateRentPaymentStatusUpdated: todaysDate,
          // timeRentPaymentStatusUpdated: todaysTime
        });
        this.setState((prevState) => ({
          landlordDetails: {
            ...prevState.landlordDetails,
            dateRentPaymentStatusUpdated: todaysDate,
            timeRentPaymentStatusUpdated: todaysTime,
          },
        }));
      }
    }

    if (
      this.state.individualPaymentStatus_Original !==
      this.state.individualDetails.entityPaymentStatus
    ) {
      // this.setState({
      //     dateIndividualPaymentStatusUpdated: todaysDate,
      //     timeIndividualPaymentStatusUpdated: todaysTime
      // });
      this.setState((prevState) => ({
        individualDetails: {
          ...prevState.individualDetails,
          dateIndividualPaymentStatusUpdated: todaysDate,
          timeIndividualPaymentStatusUpdated: todaysTime,
        },
      }));
      if (this.state.individualDetails.entityPaymentStatus === "Issued") {
        this.setState((prevState) => ({
          individualpaymentDetails: {
            ...prevState.individualpaymentDetails,
            informationStatus: "Issued"
          },
        }));
      }
    }

    if (
      this.state.applicantEmail_Original !==
      this.state.applicantDetails.tenantEmail
    ) {
      // this.setState({
      //     requestedLandlordInfo: false,
      //     countLandlordInfoRequested: 0,
      //     dateLandlordInfoRequested: '0000-00-00',
      //     timeLandlordInfoRequested: '00-00-00',
      //     dateLandlordInfoReminded: '0000-00-00',
      //     timeLandlordInfoReminded: '00-00-00',
      // });
      this.setState((prevState) => ({
        applicantDetails: {
          ...prevState.applicantDetails,
          requestedApplicantInfo: false,
          countApplicantInfoRequested: 0,
          dateApplicantInfoRequested: null,
          timeApplicantInfoRequested: null,
        },
      }));
    }

    if (
      this.state.landlordEmail_Original !==
      this.state.applicantDetails.landlordEmail
    ) {
      // this.setState({
      //     requestedLandlordInfo: false,
      //     countLandlordInfoRequested: 0,
      //     dateLandlordInfoRequested: '0000-00-00',
      //     timeLandlordInfoRequested: '00-00-00',
      //     dateLandlordInfoReminded: '0000-00-00',
      //     timeLandlordInfoReminded: '00-00-00',
      // });
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          requestedLandlordInfo: false,
          requestedLandlordInfoNew: false,
          countLandlordInfoRequested: 0,
          countLandlordInfoRequestedNew: 0,
          dateLandlordInfoRequested: null,
          dateLandlordInfoRequestedNew: null,
          timeLandlordInfoRequested: null,
          timeLandlordInfoRequestedNew: null,
          dateLandlordInfoReminded: null,
          timeLandlordInfoReminded: null,
        },
      }));
    }

    this.setState({ isLoading: true });
    try {
      var res = await this.updateIndividualDetails();
      //console.log(res);
      //console.log("Individual Updated Successful.");

      if (
        this.state.landlordEmail_Original !==
        this.state.applicantDetails.landlordEmail
      ) {
        console.log("email changed");
        //  redirectAfterSave = false; // don't redirect after save in case landlord email is updated
        try {
          var user = await this.getCurrentUser();
          let apiName = process.env.REACT_APP_APIGatewayName;
          let path = "/adminremovesantaanauserincognito";
          let myInit = {
            // OPTIONAL
            body: {
              info: {
                oldemail: this.state.landlordEmail_Original,
                individualid: this.state.individualID,
                fundingSource: this.state.fundingSource,
                type: "Landlord",
              },
            }, // replace this with attributes you need
            headers: {
              Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            },
          };

          await API.post(apiName, path, myInit);
          // if (res.message === 0)
        } catch (err) {
          console.log(err);
        }
      }

      if (
        this.state.applicantEmail_Original !==
        this.state.applicantDetails.tenantEmail
      ) {
        //  redirectAfterSave = false; // don't redirect after save in case landlord email is updated
        try {
          var user = await this.getCurrentUser();
          let apiName = process.env.REACT_APP_APIGatewayName;
          let path = "/adminremovesantaanauserincognito";
          let myInit = {
            // OPTIONAL
            body: {
              info: {
                oldemail: this.state.applicantEmail_Original,
                individualid: this.state.individualID,
                fundingSource: this.state.fundingSource,
                type: "Applicant",
              },
            }, // replace this with attributes you need
            headers: {
              Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            },
          };

          await API.post(apiName, path, myInit);
          // if (res.message === 0)
        } catch (err) {
          console.log(err);
        }
      }

      if (this.state.triggerEmail === "1") {
        // Trigger rent payment issued email
        await this.triggerRentPaymentIssuedEmail(false);
      }

      res = await this.UploadIndividualDocuments();
      //console.log(res);

      alert("The changes have been saved successfully.");

      //await this.loadData(); // load the updated data again.
      await this.handleBack();
    } catch (err) {
      console.log("ERROR: Failed to Update the individual.");
      console.log(err);
      alert("ERROR: Failed to Update the individual.");
    }

    this.setState({ isLoading: false });
  };

  triggerRentPaymentIssuedEmail = async (isReconciliation) => {
    this.setState({ isLoading: true });

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/erasantaanasendrentpaymentemail";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            firstName: this.state.applicantDetails.firstName,
            lastName: this.state.applicantDetails.lastName,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            spaServiceProviderEmail:
              this.state.applicantDetails.spaServiceProviderEmail,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            payee: this.state.applicantDetails.payee,
            rentAmount: isReconciliation ? this.state.reconciliation.amount : this.state.landlordDetails.entityAmount,
            addressLine1: this.state.applicantProperty.addressLine1,
            addressLine2: this.state.applicantProperty.addressLine2,
            city: this.state.applicantProperty.city,
            state: this.state.applicantProperty.state,
            zipcode: this.state.applicantProperty.zipcode,
            individualID: this.state.individualID,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      await API.post(apiName, path, myInit);
      // if (res.message === 0)
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  //onclick   of  both  payment section andd  individual payment details
  requestApplicantInfo = async () => {
    this.setState({ isLoading: true });
    //  await this.getCurrentUser();
    try {
      var user = await Auth.currentAuthenticatedUser();
      // return user;
    }
    catch (e) {
      this.setState({ isLoading: false });
      if (e === "not authenticated") {
        await this.signOut();
        return;
      }
    }
    var validationError = false;
    if (
      this.state.applicantDetails.phone !== "" &&
      this.state.applicantDetails.phone !== null &&
      this.state.applicantDetails.phone !== undefined
    ) {
      if (this.state.applicantDetails.phone.length < 10) {
        this.setState({ phone_Error: "Invalid Phone" });
        this.phoneRef.current.focus();

        validationError = true;
      } else {
        this.setState({ phone_Error: "" });
      }
    } else {
      this.setState({ phone_Error: "" });
    }

    if (
      this.state.applicantDetails.tenantEmail == "" ||
      this.state.applicantDetails.tenantEmail == null ||
      this.state.applicantDetails.tenantEmail == undefined
    ) {
      this.setState({ tenantEmail_Error: "Email is required" });
      this.tenantEmailRef.current.focus();
      validationError = true;
    }
    else if (
      this.state.applicantDetails.tenantEmail !== "" &&
      this.state.applicantDetails.tenantEmail !== null &&
      this.state.applicantDetails.tenantEmail !== undefined
    ) {
      var tenantEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!tenantEmailPattern.test(this.state.applicantDetails.tenantEmail)) {
        this.setState({ tenantEmail_Error: "Invalid Email" });
        this.tenantEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ tenantEmail_Error: "" });
      }
    } else {
      this.setState({ tenantEmail_Error: "" });
    }

    if (
      this.state.individualDetails.entityAmount == "" ||
      this.state.individualDetails.entityAmount == null ||
      this.state.individualDetails.entityAmount == undefined
    ) {
      this.setState({ individualPaymentAmount_Error: "Amount is required" });
      this.individualPaymentAmountRef.current.focus();
      validationError = true;
    }
    else if (
      this.state.individualDetails.entityAmount !== "" &&
      this.state.individualDetails.entityAmount !== null &&
      this.state.individualDetails.entityAmount !== undefined
    ) {
      if (
        /^\d+(\.\d{1,2})?$/.test(this.state.individualDetails.entityAmount) !==
        true
      ) {
        this.setState({ individualPaymentAmount_Error: "Invalid Amount" });
        this.individualPaymentAmountRef.current.focus();

        validationError = true;
      } else {
        this.setState({ individualPaymentAmount_Error: "" });
      }
    } else {
      this.setState({ individualPaymentAmount_Error: "" });
    }

    if (validationError === true) {
      this.setState({ isLoading: false });
      return;
    }

    var confirm = window.confirm(
      `This action will trigger an email to tenant on ${this.state.applicantDetails.tenantEmail}. Please click Ok to confirm.`
    );
    if (confirm === false) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/adminaddsantaanauserincognito";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.applicantDetails.individualID,
            fundingSource: this.state.applicantDetails.fundingSource,
            tenantFirstName: this.state.applicantDetails.firstName,
            tenantLastName: this.state.applicantDetails.lastName,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            tenantPhone: this.state.applicantDetails.phone,
            tenantPhoneCognito: this.state.applicantDetails.phone.replace(
              /\D/g,
              ""
            ),
            tenantAddressLine1: this.state.applicantProperty.addressLine1,
            tenantAddressLine2: this.state.applicantProperty.addressLine2,
            tenantCity: this.state.applicantProperty.city,
            tenantState: this.state.applicantProperty.state,
            tenantZipcode: this.state.applicantProperty.zipcode,
            landlordFullName: this.state.applicantDetails.payee,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            landlordPhone: this.state.applicantDetails.landlordPhoneNumber,
            groupName: "SantaAnaApplicants", //"IrvineApplicants"
            createType: "Document",
            resetPassword: "0",
            rentAmount: this.state.individualDetails.entityAmount
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);
      console.log(res.message);
      console.log(res.message);
      if (res.message === 0 || res.message === "UsernameExistsException") {
        //

        if (res.message === "UsernameExistsException") {
          try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = "/santaanasendlandlordemailmultipleapplicant";
            let myInit = {
              // OPTIONAL
              body: {
                info: {
                  name: this.state.applicantDetails.payee,
                  email: this.state.applicantDetails.landlordEmail,
                  tenantEmail: this.state.applicantDetails.tenantEmail,
                  phone: this.state.applicantDetails.landlordPhoneNumber,
                  tenantFirstName: this.state.applicantDetails.firstName,
                  tenantLastName: this.state.applicantDetails.lastName,
                  addressLine1: this.state.applicantProperty.addressLine1,
                  addressLine2: this.state.applicantProperty.addressLine2,
                  city: this.state.applicantProperty.city,
                  state: this.state.applicantProperty.state,
                  zipcode: this.state.applicantProperty.zipcode,
                  individualID: this.state.applicantDetails.individualID,
                  fundingSource: this.state.applicantDetails.fundingSource,
                  entityType: "Applicant",
                  createType: "Document",
                  rentAmount: this.state.individualDetails.entityAmount
                },
              }, // replace this with attributes you need
              headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
              },
            };

            await API.post(apiName, path, myInit);
            // if (res.message === 0)
          } catch (err) {
            console.log(err);
          }
        }

        var d = new Date(
          new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
        );
        var todaysDate = [
          d.getFullYear(),
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2),
        ].join("-");

        var todaysTime = [
          ("0" + d.getHours()).slice(-2),
          ("0" + d.getMinutes()).slice(-2),
          ("0" + d.getSeconds()).slice(-2),
        ].join(":");

        // let requestedStatus = "Info Requested";
        // let paymentStatus = "Ready"
        // this.setState((prevState) => ({
        //   individualpaymentDetails: {
        //     ...prevState.individualpaymentDetails,
        //     informationStatus: paymentStatus,
        //     transactionType: "Check"
        //   },
        // }));
        let requestedStatus = "Info Requested";
        let tramodify = this.state.adminUsername;
        let paymentStatus = "Ready";
        let payeeName = this.state.individualpaymentDetails.payeeName;
        let firstName = this.state.applicantDetails.firstName;
        let lastName = this.state.applicantDetails.lastName;
        this.setState((prevState) => ({
          individualpaymentDetails: {
            ...prevState.individualpaymentDetails,
            informationStatus: paymentStatus,
            transactionType: "Check",
            payeeName: (payeeName == '' || payeeName == undefined) ? firstName + " " + lastName : '',
            createdDate: todaysDate,
            createdTime: todaysTime,
            createdBy: tramodify
          },
        }));

        // if (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1 || this.state.requestedLandlordInfo === "1") {
        //     requestedStatus = "Reminder Sent";
        //     this.setState({
        //         dateLandlordInfoReminded: todaysDate,
        //         timeLandlordInfoReminded: todaysTime
        //     });
        // }
        // else {
        //     this.setState({
        //         dateLandlordInfoRequested: todaysDate,
        //         timeLandlordInfoRequested: todaysTime
        //     });
        // }

        // this.setState({
        //     requestedLandlordInfo: true,
        //     countLandlordInfoRequested: this.state.countLandlordInfoRequested + 1,
        //     landlordInfoRequestedStatus: requestedStatus,
        //     dateLandlordInfoRequested: todaysDate,
        //     timeLandlordInfoRequested: todaysTime
        // },
        this.setState(
          (prevState) => ({
            applicantDetails: {
              ...prevState.applicantDetails,
              requestedApplicantInfo: true,
              countApplicantInfoRequested:
                this.state.applicantDetails.countApplicantInfoRequested + 1,
              applicantDocUploadStatus: requestedStatus,
              dateApplicantInfoRequested: todaysDate,
              timeApplicantInfoRequested: todaysTime,
            },
          }),
          () => {
            this.updateLandlordApplicantInfo(false);

            var d = new Date(
              this.state.applicantDetails.dateApplicantInfoRequested
            );
            var infoRequestedDate = [
              ("0" + (d.getMonth() + 1)).slice(-2),
              ("0" + d.getDate()).slice(-2),
              d.getFullYear(),
            ].join("/");
            // this.setState({ dateLandlordInfoRequestedNew_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus });
            this.setState({
              dateApplicantInfoRequested_Formatted: infoRequestedDate,
              applicantDocUploadStatus_Original: requestedStatus,
            });
            //  this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
          }
        );
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  resendApplicantCredentials = async () => {
    await this.resendCredentials(true);
  };

  resendLandlordCredentials = async () => {
    await this.resendCredentials(false);
  };

  resendCredentials = async (toApplicant) => {
    this.setState({ isLoading: true });

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/adminaddsantaanauserincognito";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.applicantDetails.individualID,
            fundingSource: this.state.applicantDetails.fundingSource,
            tenantFirstName: this.state.applicantDetails.firstName,
            tenantLastName: this.state.applicantDetails.lastName,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            tenantPhone: this.state.applicantDetails.phone,
            tenantAddressLine1: this.state.applicantProperty.addressLine1,
            tenantAddressLine2: this.state.applicantProperty.addressLine2,
            tenantCity: this.state.applicantProperty.city,
            tenantState: this.state.applicantProperty.state,
            tenantZipcode: this.state.applicantProperty.zipcode,
            landlordFullName: this.state.applicantDetails.payee,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            landlordPhone: this.state.applicantDetails.landlordPhoneNumber,
            landlordPhoneCognito:
              this.state.applicantDetails.landlordPhoneNumber.replace(
                /\D/g,
                ""
              ),
            tenantPhoneCognito: this.state.applicantDetails.phone.replace(
              /\D/g,
              ""
            ),
            groupName:
              toApplicant === true ? "SantaAnaApplicants" : "SantaAnaLandlords",
            createType: "Document",
            resetPassword: "1",
            rentAmount: toApplicant === true ? this.state.individualDetails.entityAmount : this.state.landlordDetails.entityAmount,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);

      if (toApplicant === true) {
        if (res.message === 0) {
          // Success
          alert(
            "Password has been reset and welcome mail has been resent successfully to applicant."
          );
        } else if (res.message === "UnsupportedUserStateException") {
          // "Resend not possible. e9a24799-3b79-460f-8906-35640ce5d7dd status is not FORCE_CHANGE_PASSWORD"
          alert(
            "Password can not be reset as applicant might have already updated the earlier sent temporary password."
          );
        } else if (res.message === "UserNotFoundException") {
          // "User does not exist."
          alert(
            "User does not exist in user pool. Please contact portal administrator."
          );
        } else {
          // Unknown error
          alert(
            "Unknown error occurred while resending the password. Please contact portal administrator."
          );
        }
      } else {
        if (res.message === 0) {
          // Success
          alert(
            "Password has been reset and welcome mail has been resent successfully to landlord."
          );
        } else if (res.message === "UnsupportedUserStateException") {
          // "Resend not possible. e9a24799-3b79-460f-8906-35640ce5d7dd status is not FORCE_CHANGE_PASSWORD"
          alert(
            "Password can not be reset as landlord might have already updated the earlier sent temporary password."
          );
        } else if (res.message === "UserNotFoundException") {
          // "User does not exist."
          alert(
            "User does not exist in user pool. Please contact portal administrator."
          );
        } else {
          // Unknown error
          alert(
            "Unknown error occurred while resending the password. Please contact portal administrator."
          );
        }
      }
    } catch (err) {
      console.log(err);
      // Unexpected error
      alert(
        "Unexpected error occurred while resending the password. Please contact portal administrator."
      );
    }

    this.setState({ isLoading: false });
  };

  // request landlord bank info
  // requestLandlordBankInfo = async () => {

  //   //  if (this.state.landlordDetails.requestedLandlordInfoNew == false || this.state.landlordDetails.requestedLandlordInfoNew == 0) {
  //        try {
  //         var user = await Auth.currentAuthenticatedUser();
  //         let apiName = process.env.REACT_APP_APIGatewayName;
  //         let path = '/adminaddsantaanauserincognito';
  //         let myInit = { // OPTIONAL
  //             body: {
  //                 "info": {
  //                     "individualID": this.state.applicantDetails.individualID,
  //                     "fundingSource": this.state.applicantDetails.fundingSource,
  //                     "tenantFirstName": this.state.applicantDetails.firstName,
  //                     "tenantLastName": this.state.applicantDetails.lastName,
  //                     "tenantEmail": this.state.applicantDetails.tenantEmail,
  //                     "tenantPhone": this.state.applicantDetails.phone,
  //                     "tenantAddressLine1": this.state.applicantProperty.addressLine1,
  //                     "tenantAddressLine2": this.state.applicantProperty.addressLine2,
  //                     "tenantCity": this.state.applicantProperty.city,
  //                     "tenantState": this.state.applicantProperty.state,
  //                     "tenantZipcode": this.state.applicantProperty.zipcode,
  //                     "landlordFullName": this.state.applicantDetails.payee,
  //                     "landlordEmail": this.state.applicantDetails.landlordEmail,
  //                     "landlordPhone": this.state.applicantDetails.landlordPhoneNumber,
  //                     "landlordPhoneCognito": this.state.applicantDetails.landlordPhoneNumber.replace(/\D/g, ""),
  //                     "groupName": "SantaAnaLandlords", //"IrvineApplicants"
  //                     "createType": "Payment",
  //                     "resetPassword": "0",
  //                 }
  //             }, // replace this with attributes you need
  //             headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
  //         }

  //         var res = await API.post(apiName, path, myInit);
  //         console.log(res.message);
  //         if (res.message === 0 || res.message === "UsernameExistsException") {
  //           if (res.message === "UsernameExistsException") {
  //           try {
  //               var user = await Auth.currentAuthenticatedUser();
  //               let apiName = process.env.REACT_APP_APIGatewayName;
  //               let path = '/santaanasendlandlordemailmultipleapplicant';
  //               let myInit = { // OPTIONAL
  //                   body: {
  //                       "info": {
  //                           "name": this.state.applicantDetails.payee,
  //                           "email": this.state.applicantDetails.landlordEmail,
  //                           "phone": this.state.applicantDetails.landlordPhoneNumber,
  //                           "tenantFirstName": this.state.applicantDetails.firstName,
  //                           "tenantLastName": this.state.applicantDetails.lastName,
  //                           "addressLine1": this.state.applicantProperty.addressLine1,
  //                           "addressLine2": this.state.applicantProperty.addressLine2,
  //                           "city": this.state.applicantProperty.city,
  //                           "state": this.state.applicantProperty.state,
  //                           "zipcode": this.state.applicantProperty.zipcode,
  //                           "individualID": this.state.applicantDetails.individualID,
  //                           "fundingSource": this.state.applicantDetails.fundingSource,
  //                           "entityType": "Landlord",
  //                           "createType": "Payment",
  //                       }
  //                   }, // replace this with attributes you need
  //                   headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
  //               }

  //               await API.post(apiName, path, myInit);
  //               // if (res.message === 0)
  //           }
  //           catch (err) {
  //               console.log(err)
  //           }
  //           }

  //           var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
  //     var todaysDate = [
  //         d.getFullYear(),
  //         ('0' + (d.getMonth() + 1)).slice(-2),
  //         ('0' + d.getDate()).slice(-2)
  //     ].join('-');

  //     var todaysTime = [
  //         ('0' + (d.getHours())).slice(-2),
  //         ('0' + d.getMinutes()).slice(-2),
  //         ('0' + d.getSeconds()).slice(-2),
  //     ].join(':');

  //     let requestedStatus = 'Info Requested';
  //     this.setState((prevState) => ({
  //       landlordpaymentDetails: {
  //           ...prevState.landlordpaymentDetails,
  //           informationStatus: requestedStatus,

  //       },
  //   }));

  //     this.setState((prevState) => ({
  //         landlordDetails: {
  //             ...prevState.landlordDetails,
  //             //requestedLandlordInfo: true,
  //             countBankInfoRequested: this.state.landlordDetails.countBankInfoRequested + 1,
  //             // landlordInfoRequestedStatus: requestedStatus,
  //             requestedBankInfo: true,
  //             dateBankInfoRequested: todaysDate,
  //             timeBankInfoRequested: todaysTime
  //             //dateLandlordInfoRequested: todaysDate,
  //             //timeLandlordInfoRequested: todaysTime
  //         },
  //     }),

  //         () => {
  //             this.updateLandlordApplicantBankInfo(true);

  //             var d = new Date(this.state.landlordDetails.dateBankInfoRequested);
  //             var infoRequestedDate = [
  //                 ('0' + (d.getMonth() + 1)).slice(-2),
  //                 ('0' + d.getDate()).slice(-2),
  //                 d.getFullYear()
  //             ].join('/');
  //             this.setState({ dateLandlordBankInfoRequestedNew_Formatted: infoRequestedDate });
  //             //  this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
  //         });

  //       }
  //     }
  //     catch (err) {
  //       console.log(err)
  //   }

  // // }

  //     // // catch (err) {
  //     // // console.log("catch called");
  //     // // console.log(err);
  //     // // }
  //     this.setState({ isLoading: false });
  // }
  //ends

  //request individual bank info starts
  //   requestIndividualBankInfo = async () => {
  //       // if (this.state.individualDetails.requestApplicantInfo == false || this.state.individualDetails.requestApplicantInfo == 0) {
  //       //     await this.requestApplicantInfo();

  //       // ////for email send to bank info
  //       try {
  //         var user = await Auth.currentAuthenticatedUser();
  //         let apiName = process.env.REACT_APP_APIGatewayName;
  //         let path = '/adminaddsantaanauserincognito';
  //         let myInit = { // OPTIONAL
  //             body: {
  //                 "info": {
  //                     "individualID": this.state.applicantDetails.individualID,
  //                     "fundingSource": this.state.applicantDetails.fundingSource,
  //                     "tenantFirstName": this.state.applicantDetails.firstName,
  //                     "tenantLastName": this.state.applicantDetails.lastName,
  //                     "tenantEmail": this.state.applicantDetails.tenantEmail,
  //                     "tenantPhone": this.state.applicantDetails.phone,
  //                     "tenantPhoneCognito": this.state.applicantDetails.phone.replace(/\D/g, ""),
  //                     "tenantAddressLine1": this.state.applicantProperty.addressLine1,
  //                     "tenantAddressLine2": this.state.applicantProperty.addressLine2,
  //                     "tenantCity": this.state.applicantProperty.city,
  //                     "tenantState": this.state.applicantProperty.state,
  //                     "tenantZipcode": this.state.applicantProperty.zipcode,
  //                     "landlordFullName": this.state.applicantDetails.payee,
  //                     "landlordEmail": this.state.applicantDetails.landlordEmail,
  //                     "landlordPhone": this.state.applicantDetails.landlordPhoneNumber,
  //                     "groupName": "SantaAnaApplicants", //"IrvineApplicants"
  //                     "createType": "Payment",
  //                     "resetPassword": "0",
  //                 }
  //             }, // replace this with attributes you need
  //             headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
  //         }

  //         var res = await API.post(apiName, path, myInit);
  //         console.log(res.message);
  //         console.log(res.message);
  //         if (res.message === 0 || res.message === "UsernameExistsException") {  //

  //             if (res.message === "UsernameExistsException") {
  //                 try {
  //                     var user = await Auth.currentAuthenticatedUser();
  //                     let apiName = process.env.REACT_APP_APIGatewayName;
  //                     let path = '/santaanasendlandlordemailmultipleapplicant';
  //                     let myInit = { // OPTIONAL
  //                         body: {
  //                             "info": {
  //                                 "name": this.state.applicantDetails.payee,
  //                                 "email": this.state.applicantDetails.landlordEmail,
  //                                 "tenantEmail": this.state.applicantDetails.tenantEmail,
  //                                 "phone": this.state.applicantDetails.landlordPhoneNumber,
  //                                 "tenantFirstName": this.state.applicantDetails.firstName,
  //                                 "tenantLastName": this.state.applicantDetails.lastName,
  //                                 "addressLine1": this.state.applicantProperty.addressLine1,
  //                                 "addressLine2": this.state.applicantProperty.addressLine2,
  //                                 "city": this.state.applicantProperty.city,
  //                                 "state": this.state.applicantProperty.state,
  //                                 "zipcode": this.state.applicantProperty.zipcode,
  //                                 "individualID": this.state.applicantDetails.individualID,
  //                                 "fundingSource": this.state.applicantDetails.fundingSource,
  //                                 "createType": "Payment",
  //                                 "entityType": "Individual"
  //                             }
  //                         }, // replace this with attributes you need
  //                         headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
  //                     }

  //                     await API.post(apiName, path, myInit);
  //                     // if (res.message === 0)
  //                 }
  //                 catch (err) {
  //                     console.log(err)
  //                 }
  //             }

  //             var d = new Date(new Date().toLocaleString('en-US', { timeZone: 'US/Pacific' }));
  //             var todaysDate = [
  //                 d.getFullYear(),
  //                 ('0' + (d.getMonth() + 1)).slice(-2),
  //                 ('0' + d.getDate()).slice(-2)
  //             ].join('-');

  //             var todaysTime = [
  //                 ('0' + (d.getHours())).slice(-2),
  //                 ('0' + d.getMinutes()).slice(-2),
  //                 ('0' + d.getSeconds()).slice(-2),
  //             ].join(':');

  //             let requestedStatus = 'Info Requested';
  //             this.setState((prevState) => ({
  //               individualpaymentDetails: {
  //                   ...prevState.individualpaymentDetails,
  //                   informationStatus: requestedStatus,
  //               },
  //           }));

  //             this.setState((prevState) => ({
  //               individualDetails: {
  //                     ...prevState.individualDetails,

  //                     countApplicantInfoRequested: this.state.individualDetails.countLandlordInfoRequested + 1,
  //                     // applicantDocUploadStatus: requestedStatus,
  //                     requestedBankInfo: true,
  //                     dateBankInfoRequested: todaysDate,
  //                     timeBankInfoRequested: todaysTime
  //                 },
  //             }),
  //                 () => {
  //                     this.updateLandlordApplicantInfo(false);

  //                     var d = new Date(this.state.applicantDetails.dateBankInfoRequested);
  //                     var infoRequestedDate = [
  //                         ('0' + (d.getMonth() + 1)).slice(-2),
  //                         ('0' + d.getDate()).slice(-2),
  //                         d.getFullYear()
  //                     ].join('/');
  //                     // this.setState({ dateLandlordInfoRequestedNew_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus });
  //                     this.setState({ dateIndividualBankInfoRequestedNew_Formatted: infoRequestedDate });

  //                     // this.setState({ dateApplicantInfoRequested_Formatted: infoRequestedDate, applicantDocUploadStatus_Original: requestedStatus });
  //                     //  this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
  //                 });

  //         }
  //     }
  //     catch (err) {
  //       console.log(err)
  //   }

  //     this.setState({ isLoading: false });
  // }

  //ends

  requestLandlordInfo = async () => {
    //await this.sendEmailToLandlord(0);
    // await this.sendEmailToLandlord(0);
    this.setState({ isLoading: true });

    // await this.getCurrentUser();
    try {
      var user = await Auth.currentAuthenticatedUser();
      // return user;
    }
    catch (e) {
      this.setState({ isLoading: false });
      if (e === "not authenticated") {
        await this.signOut();
        return;
      }
    }
    var validationError = false;
    if (
      this.state.applicantDetails.landlordEmail !== "" &&
      this.state.applicantDetails.landlordEmail !== null &&
      this.state.applicantDetails.landlordEmail !== undefined
    ) {
      var landlordEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !landlordEmailPattern.test(this.state.applicantDetails.landlordEmail)
      ) {
        this.setState({ landlordEmail_Error: "Invalid Email" });
        this.landlordEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordEmail_Error: "" });
      }
    } else {
      this.setState({ landlordEmail_Error: "" });
    }

    if (
      this.state.applicantDetails.landlordPhoneNumber !== "" &&
      this.state.applicantDetails.landlordPhoneNumber !== null &&
      this.state.applicantDetails.landlordPhoneNumber !== undefined
    ) {
      if (this.state.applicantDetails.landlordPhoneNumber.length < 10) {
        this.setState({ landlordPhoneNumber_Error: "Invalid Phone Number" });
        this.landlordPhoneNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordPhoneNumber_Error: "" });
      }
    } else {
      this.setState({ landlordPhoneNumber_Error: "" });
    }

    //  if (this.state.applicantDetails.landlordPhoneNumber === "" || this.state.applicantDetails.landlordPhoneNumber === null || this.state.applicantDetails.landlordPhoneNumber === undefined) {
    //      this.setState({ landlordPhoneNumber_Error: "Phone is required" });
    //      this.landlordPhoneNumberRef.current.focus();

    //      validationError = true;
    //  }
    //  else {
    //      if (this.state.applicantDetails.landlordPhoneNumber.length < 10 || /^[0-9]*$/.test(this.state.applicantDetails.landlordPhoneNumber) !== true) {
    //          this.setState({ landlordPhoneNumber_Error: "Invalid Phone Number" });
    //          this.landlordPhoneNumberRef.current.focus();

    //          validationError = true;
    //      }
    //      else {
    //          this.setState({ landlordPhoneNumber_Error: "" });
    //      }
    //  }

    if (validationError === true) {
      this.setState({ isLoading: false });
      return;
    }

    var confirm = window.confirm(
      `This action will trigger an email to landlord on ${this.state.applicantDetails.landlordEmail}. Please click Ok to confirm.`
    );
    if (confirm === false) {
      this.setState({ isLoading: false });
      return;
    }

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/adminaddsantaanauserincognito";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.applicantDetails.individualID,
            fundingSource: this.state.applicantDetails.fundingSource,
            tenantFirstName: this.state.applicantDetails.firstName,
            tenantLastName: this.state.applicantDetails.lastName,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            tenantPhone: this.state.applicantDetails.phone,
            tenantAddressLine1: this.state.applicantProperty.addressLine1,
            tenantAddressLine2: this.state.applicantProperty.addressLine2,
            tenantCity: this.state.applicantProperty.city,
            tenantState: this.state.applicantProperty.state,
            tenantZipcode: this.state.applicantProperty.zipcode,
            landlordFullName: this.state.applicantDetails.payee,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            landlordPhone: this.state.applicantDetails.landlordPhoneNumber,
            landlordPhoneCognito:
              this.state.applicantDetails.landlordPhoneNumber.replace(
                /\D/g,
                ""
              ),
            groupName: "SantaAnaLandlords", //"IrvineApplicants"
            createType: "Document",
            resetPassword: "0",
            rentAmount: this.state.landlordDetails.entityAmount
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);
      if (res.message === 0 || res.message === "UsernameExistsException") {
        //

        if (res.message === "UsernameExistsException") {
          try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = "/santaanasendlandlordemailmultipleapplicant";
            let myInit = {
              // OPTIONAL
              body: {
                info: {
                  name: this.state.applicantDetails.payee,
                  email: this.state.applicantDetails.landlordEmail,
                  phone: this.state.applicantDetails.landlordPhoneNumber,
                  tenantFirstName: this.state.applicantDetails.firstName,
                  tenantLastName: this.state.applicantDetails.lastName,
                  addressLine1: this.state.applicantProperty.addressLine1,
                  addressLine2: this.state.applicantProperty.addressLine2,
                  city: this.state.applicantProperty.city,
                  state: this.state.applicantProperty.state,
                  zipcode: this.state.applicantProperty.zipcode,
                  individualID: this.state.applicantDetails.individualID,
                  fundingSource: this.state.applicantDetails.fundingSource,
                  entityType: "Landlord",
                  createType: "Document",
                  rentAmount: this.state.landlordDetails.entityAmount
                },
              }, // replace this with attributes you need
              headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
              },
            };

            await API.post(apiName, path, myInit);
            // if (res.message === 0)
          } catch (err) {
            console.log(err);
          }
        }

        var d = new Date(
          new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
        );
        var todaysDate = [
          d.getFullYear(),
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2),
        ].join("-");

        var todaysTime = [
          ("0" + d.getHours()).slice(-2),
          ("0" + d.getMinutes()).slice(-2),
          ("0" + d.getSeconds()).slice(-2),
        ].join(":");

        let requestedStatus = "Info Requested";
        let tramodify = this.state.adminUsername;

        this.setState((prevState) => ({
          landlordpaymentDetails: {
            ...prevState.landlordpaymentDetails,
            informationStatus: requestedStatus,
            createdDate: todaysDate,
            createdTime: todaysTime,
            createdBy: tramodify
          },
        }));
        // if (this.state.requestedLandlordInfo === true || this.state.requestedLandlordInfo === 1 || this.state.requestedLandlordInfo === "1") {
        //     requestedStatus = "Reminder Sent";
        //     this.setState({
        //         dateLandlordInfoReminded: todaysDate,
        //         timeLandlordInfoReminded: todaysTime
        //     });
        // }
        // else {
        //     this.setState({
        //         dateLandlordInfoRequested: todaysDate,
        //         timeLandlordInfoRequested: todaysTime
        //     });
        // }

        // this.setState({
        //     requestedLandlordInfo: true,
        //     countLandlordInfoRequested: this.state.countLandlordInfoRequested + 1,
        //     landlordInfoRequestedStatus: requestedStatus,
        //     dateLandlordInfoRequested: todaysDate,
        //     timeLandlordInfoRequested: todaysTime
        // },
        this.setState(
          (prevState) => ({
            landlordDetails: {
              ...prevState.landlordDetails,
              //requestedLandlordInfo: true,
              countLandlordInfoRequestedNew:
                this.state.landlordDetails.countLandlordInfoRequestedNew + 1,
              landlordInfoRequestedStatus: requestedStatus,
              requestedLandlordInfoNew: true,
              dateLandlordInfoRequestedNew: todaysDate,
              timeLandlordInfoRequestedNew: todaysTime,
              //dateLandlordInfoRequested: todaysDate,
              //timeLandlordInfoRequested: todaysTime
            },
          }),
          () => {
            this.updateLandlordApplicantInfo(true);

            var d = new Date(
              this.state.landlordDetails.dateLandlordInfoRequestedNew
            );
            var infoRequestedDate = [
              ("0" + (d.getMonth() + 1)).slice(-2),
              ("0" + d.getDate()).slice(-2),
              d.getFullYear(),
            ].join("/");
            this.setState({
              dateLandlordInfoRequestedNew_Formatted: infoRequestedDate,
            });
            //  this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
          }
        );
      }
    } catch (err) {
      console.log(err);
    }

    this.setState({ isLoading: false });
  };

  updateLandlordApplicantInfo = async (isLandlord) => {
    this.setState({ isLoading: true });
    if (isLandlord) {
      this.setState((prevState) => ({
        landlordpaymentDetails: {
          ...prevState.landlordpaymentDetails,
          isChange: true,
        },
      }));
    } else {
      this.setState((prevState) => ({
        individualpaymentDetails: {
          ...prevState.individualpaymentDetails,
          isChange: true,
        },
      }));
    }
    var res = await this.updateIndividualDetails();

    if (isLandlord) {
      document.getElementById("requestLandlordInfoId").style.display = "none";
      document.getElementById("requestDateNewId").style.display = "block";
      document.getElementById("sendReminderToLandlordId").style.display =
        "block";
    } else {
      document.getElementById("requestApplicantInfoId").style.display = "none";
      document.getElementById("requestApplicantDateId").style.display = "block";
      document.getElementById("sendReminderToLandlordId").style.display =
        "block";
    }
    this.setState({ isLoading: false });
  };

  updateLandlordApplicantBankInfo = async (isLandlord) => {
    this.setState({ isLoading: true });
    var res = await this.updateIndividualDetails();

    if (isLandlord) {
      document.getElementById("requestLandlordBankInfoId").style.display =
        "none";
      document.getElementById("requestBankDateNewId").style.display = "block";
      document.getElementById("sendBankReminderToLandlordId").style.display =
        "block";
    } else {
      document.getElementById("requestBankApplicantInfoId").style.display =
        "none";
      document.getElementById("requestBankApplicantDateId").style.display =
        "block";
      document.getElementById("sendBankReminderToIndividualId").style.display =
        "block";
    }
    this.setState({ isLoading: false });
  };

  sendReminderToLandlord = async () => {
    await this.sendEmailToLandlord(1);
  };
  sendReminderToreconciliation = async (id) => {
    await this.sendEmailToreconciliation(id, 1);
  };
  sendReminderToLandlordBankInfo = async () => {
    await this.sendEmailToLandlordBankInfo(1);
  };

  sendEmailToreconciliation = async (id, remind) => {
    this.setState({ isLoading: true });



    var confirm = window.confirm(
      `This action will trigger an email to landlord on ${this.state.applicantDetails.landlordEmail}. Please click Ok to confirm.`
    );
    if (confirm === false) {
      this.setState({ isLoading: false });
      return;
    }
    else {
      this.setState({ isLoading: true });
      $("#exampleModal").modal("hide");
    }

    var originalStatus = this.state.landlordDetails.landlordInfoRequestedStatus;
    //  this.setState({ landlordInfoRequestedStatus: 'Reminder Sent' });

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/santaanatriggerdocumentrequestemailtolandlordmanually";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            isReconciliation: 1,
            reconciliationId: id,
            paymentId: this.state.landlordDetails.paymentId,
            individualID: this.state.applicantDetails.individualID,
            uniqueID: this.state.applicantDetails.uniqueID,
            payee: this.state.applicantDetails.payee,
            firstName: this.state.applicantDetails.firstName,
            lastName: this.state.applicantDetails.lastName,
            addressLine1: this.state.applicantProperty.addressLine1,
            addressLine2: this.state.applicantProperty.addressLine2,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            entityType: this.state.reconciliation.entityType,
            rentAmount: this.state.landlordDetails.entityAmount,
            fundingSource: this.state.landlordDetails.fundingSource,
            countBankInfoRequested:
              this.state.reconciliation.countBankInfoRequested,
            reminder: remind,
            totalRecords: this.state.applicantDetails.totalRecords,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);

      var d = new Date(
        new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
      );



      // reload the individual data
      //   await this.loadData();
      let getList = await this.getReconciliationList();
      if (getList && getList.length > 0) {
        this.updateAccountNoRoutingNoData(getList).then((isGetResult) => {
          if (isGetResult) {
            this.setState({ isLoading: false });
            let dispList = getList.filter(
              (x) => x.fundingSource == this.state.fundingSource
            );
            this.setState({
              reconciliationList: dispList,
              reconciliationListByIndividualId: getList,
              reconciliationListClone: JSON.parse(JSON.stringify(dispList)),
            });
            this.calculateTotal1099();
            this.calculateTotal10992022();
            this.calculateTotalFund();
           // this.calculateTotalIssued();
          }
        });
      }
    } catch (err) {
      this.setState({ isLoading: false });
      console.log(err);
      this.setState({ landlordInfoRequestedStatus_Original: originalStatus });
    }
    this.setState({ isLoading: false });
  };
  sendEmailToLandlord = async (remind) => {
    this.setState({ isLoading: true });

    this.setState({ isLoading: true });

    var validationError = false;
    if (
      this.state.applicantDetails.landlordEmail !== "" &&
      this.state.applicantDetails.landlordEmail !== null &&
      this.state.applicantDetails.landlordEmail !== undefined
    ) {
      var landlordEmailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (
        !landlordEmailPattern.test(this.state.applicantDetails.landlordEmail)
      ) {
        this.setState({ landlordEmail_Error: "Invalid Email" });
        this.landlordEmailRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordEmail_Error: "" });
      }
    } else {
      this.setState({ landlordEmail_Error: "" });
    }

    if (
      this.state.applicantDetails.landlordPhoneNumber !== "" &&
      this.state.applicantDetails.landlordPhoneNumber !== null &&
      this.state.applicantDetails.landlordPhoneNumber !== undefined
    ) {
      if (this.state.applicantDetails.landlordPhoneNumber.length < 10) {
        this.setState({ landlordPhoneNumber_Error: "Invalid Phone Number" });
        this.landlordPhoneNumberRef.current.focus();

        validationError = true;
      } else {
        this.setState({ landlordPhoneNumber_Error: "" });
      }
    } else {
      this.setState({ landlordPhoneNumber_Error: "" });
    }

    if (validationError === true) {
      this.setState({ isLoading: false });
      return;
    }

    var confirm = window.confirm(
      `This action will trigger an email to landlord on ${this.state.applicantDetails.landlordEmail}. Please click Ok to confirm.`
    );
    if (confirm === false) {
      this.setState({ isLoading: false });
      return;
    }

    var originalStatus = this.state.landlordDetails.landlordInfoRequestedStatus;
    //  this.setState({ landlordInfoRequestedStatus: 'Reminder Sent' });

    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/santaanatriggerdocumentrequestemailtolandlordmanually";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            isReconciliation: 0,
            paymentId: this.state.landlordDetails.paymentId,
            individualID: this.state.applicantDetails.individualID,
            uniqueID: this.state.applicantDetails.uniqueID,
            payee: this.state.applicantDetails.payee,
            firstName: this.state.applicantDetails.firstName,
            lastName: this.state.applicantDetails.lastName,
            addressLine1: this.state.applicantProperty.addressLine1,
            addressLine2: this.state.applicantProperty.addressLine2,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            entityType: this.state.landlordDetails.entityType,
            rentAmount: this.state.landlordDetails.entityAmount,
            fundingSource: this.state.landlordDetails.fundingSource,
            countLandlordInfoRequested:
              this.state.landlordDetails.countLandlordInfoRequestedNew,
            reminder: remind,
            totalRecords: this.state.applicantDetails.totalRecords,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);

      var d = new Date(
        new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
      );
      var todaysDate = [
        d.getFullYear(),
        ("0" + (d.getMonth() + 1)).slice(-2),
        ("0" + d.getDate()).slice(-2),
      ].join("-");

      var todaysTime = [
        ("0" + d.getHours()).slice(-2),
        ("0" + d.getMinutes()).slice(-2),
        ("0" + d.getSeconds()).slice(-2),
      ].join(":");

      var infoRemindedDate = [
        ("0" + (d.getMonth() + 1)).slice(-2),
        ("0" + d.getDate()).slice(-2),
        d.getFullYear(),
      ].join("/");

      // let requestedStatus = '';

      this.setState(
        (prevState) => ({
          landlordDetails: {
            ...prevState.landlordDetails,
            //requestedLandlordInfo: true,
            countLandlordInfoRequestedNew:
              this.state.landlordDetails.countLandlordInfoRequestedNew + 1,
            landlordInfoRequestedStatus: "Reminder Sent",
            requestedLandlordInfoNew: true,
            dateLandlordInfoReminded: todaysDate,
            timeLandlordInfoReminded: todaysTime,

            dateLandlordInfoReminded_Formatted: infoRemindedDate,
            //dateLandlordInfoRequested: todaysDate,
            //timeLandlordInfoRequested: todaysTime
          },
        }),
        () => {
          this.updateLandlordApplicantInfo(true);

          var d = new Date(this.state.landlordDetails.dateLandlordInfoReminded);
          var infoRemindedDate = [
            ("0" + (d.getMonth() + 1)).slice(-2),
            ("0" + d.getDate()).slice(-2),
            d.getFullYear(),
          ].join("/");
          this.setState({
            dateLandlordInfoReminded_Formatted: infoRemindedDate,
            landlordInfoRequestedStatus_Original: "Reminder Sent",
          });
          // var d = new Date(this.state.landlordDetails.dateLandlordInfoRequestedNew);
          // var infoRequestedDate = [
          //     ('0' + (d.getMonth() + 1)).slice(-2),
          //     ('0' + d.getDate()).slice(-2),
          //     d.getFullYear()
          // ].join('/');
          // this.setState({ dateLandlordInfoRequestedNew_Formatted: infoRequestedDate });
          //  this.setState({ dateLandlordInfoRequested_Formatted: infoRequestedDate, landlordInfoRequestedStatus_Original: requestedStatus, });
        }
      );
      // reload the individual data
      // await this.loadData();
    } catch (err) {
      console.log(err);
      this.setState({ landlordInfoRequestedStatus_Original: originalStatus });
    }

    this.setState({ isLoading: false });
  };

  sendEmailToLandlordBankInfo = async (remind) => {
    // this.setState({ isLoading: true });
    // try {
    //     var user = await Auth.currentAuthenticatedUser();
    //     let apiName = process.env.REACT_APP_APIGatewayName;
    //     let path = "/santaanatriggerdocumentrequestemailtolandlordmanually";
    //     let myInit = {
    //         // OPTIONAL
    //         body: {
    //             info: {
    //                 paymentId: this.state.landlordDetails.paymentId,
    //                 individualID: this.state.applicantDetails.individualID,
    //                 uniqueID: this.state.applicantDetails.uniqueID,
    //                 payee: this.state.applicantDetails.payee,
    //                 firstName: this.state.applicantDetails.firstName,
    //                 lastName: this.state.applicantDetails.lastName,
    //                 addressLine1: this.state.applicantProperty.addressLine1,
    //                 addressLine2: this.state.applicantProperty.addressLine2,
    //                 landlordEmail: this.state.applicantDetails.landlordEmail,
    //                 rentAmount: this.state.landlordDetails.entityAmount,
    //                 fundingSource: this.state.landlordDetails.fundingSource,
    //                 countLandlordInfoRequested: this.state.landlordDetails.countLandlordInfoRequested,
    //                 reminder: remind,
    //                 totalRecords: this.state.applicantDetails.totalRecords,
    //             },
    //         }, // replace this with attributes you need
    //         headers: {
    //             Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
    //         },
    //     };
    //     var res = await API.post(apiName, path, myInit);
    //     // reload the individual data
    //     await this.loadData();
    // } catch (err) {
    //     console.log(err);
    // }
    // this.setState({ isLoading: false });
  };

  sendReminderToIndividualBankInfo = async () => {
    await this.sendEmailToIndividualBankInfo(1);
  };

  sendEmailToIndividualBankInfo = async (remind) => {
    // this.setState({ isLoading: true });
    // try {
    //     var user = await Auth.currentAuthenticatedUser();
    //     let apiName = process.env.REACT_APP_APIGatewayName;
    //     let path = "/santaanatriggerdocumentrequestemailtolandlordmanually";
    //     let myInit = {
    //         // OPTIONAL
    //         body: {
    //             info: {
    //                 paymentId: this.state.landlordDetails.paymentId,
    //                 individualID: this.state.applicantDetails.individualID,
    //                 uniqueID: this.state.applicantDetails.uniqueID,
    //                 payee: this.state.applicantDetails.payee,
    //                 firstName: this.state.applicantDetails.firstName,
    //                 lastName: this.state.applicantDetails.lastName,
    //                 addressLine1: this.state.applicantProperty.addressLine1,
    //                 addressLine2: this.state.applicantProperty.addressLine2,
    //                 landlordEmail: this.state.applicantDetails.landlordEmail,
    //                 rentAmount: this.state.landlordDetails.entityAmount,
    //                 fundingSource: this.state.landlordDetails.fundingSource,
    //                 countLandlordInfoRequested: this.state.landlordDetails.countLandlordInfoRequested,
    //                 reminder: remind,
    //                 totalRecords: this.state.applicantDetails.totalRecords,
    //             },
    //         }, // replace this with attributes you need
    //         headers: {
    //             Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
    //         },
    //     };
    //     var res = await API.post(apiName, path, myInit);
    //     // reload the individual data
    //     await this.loadData();
    // } catch (err) {
    //     console.log(err);
    // }
    // this.setState({ isLoading: false });
  };

  handleBack = async () => {
    // // check the current user when the App component is loaded
    // Auth.currentAuthenticatedUser().then(user => {
    //     this.setState({ authState: 'signedIn' });
    // }).catch(e => {
    //     console.log(e);
    //     this.setState({ authState: 'signIn' });
    // });

    // ReactDOM.render(<App showCeppClaims={'block'} authState={this.state.authState} />, document.getElementById("root"));

    // reset the state
    this.resetValues();
    this.setState(
      {
        applicantDetails: {},
        landlordDetails: {},
        individualDetails: {},
        applicantProperty: {},
        landlordProperty: {},
        individualpaymentDetails: {
          transactionType: "",
          entityRoutingNumber: "",
          entityAccountNumber: "",
          payeeName: "",
          informationStatus: "",
          checkStatus: "",
          fullAddress: "",
          individualID: "",
          fundingSource: "",
          entityBankName: "",
          entityAccountType: "",
          paymentId: "",
        },
        landlordpaymentDetails: {
          transactionType: "",
          entityRoutingNumber: "",
          entityAccountNumber: "",
          payeeName: "",
          informationStatus: "",
          checkStatus: "",
          fullAddress: "",
          individualID: "",
          fundingSource: "",
          entityBankName: "",
          entityAccountType: "",
          paymentId: "",
        },
        applicantDetailsClone: {},
        landlordDetailsClone: {},
        individualDetailsClone: {},
        applicantPropertyClone: {},
        landlordPropertyClone: {},
        landlordpaymentDetailsClone: {
          transactionType: "",
          entityRoutingNumber: "",
          entityAccountNumber: "",
          payeeName: "",
          informationStatus: "",
          checkStatus: "",
          fullAddress: "",
          individualID: "",
          fundingSource: "",
          entityBankName: "",
          entityAccountType: "",
          paymentId: "",
        },
        individualpaymentDetailsClone: {
          transactionType: "",
          entityRoutingNumber: "",
          entityAccountNumber: "",
          payeeName: "",
          informationStatus: "",
          checkStatus: "",
          fullAddress: "",
          individualID: "",
          fundingSource: "",
          entityBankName: "",
          entityAccountType: "",
          paymentId: "",
        },
        rentPaymentStatus_Original: null,
        applicantDocUploadStatus_Original: null,
        individualPaymentStatus_Original: null,

        individualID: "0",

        firstName: "",
        lastName: "",
        partnerFirstName: "",
        partnerLastName: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        zipcode: "",
        clientDOB: "",
        phone: "",
        legallyAbleToWork: "",
        eligibleForStimulusFunds: "",
        veteran: "",
        monthlyIncomeBeforeCovid19: "",
        workIndustry: "",
        employmentStatus: "",
        effectOfCovid19Financially: "",
        householdNumber: "0",
        //payment section state reset
        transactionType: "",
        entityAccountNumber: "",
        entityRoutingNumber: "",
        payeeName: "",
        fullAddress: "",
        informationStatus: "",
        checkStatus: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
        //
        age0to5: "0",
        age6to17: "0",
        age18to59: "0",
        age60andUp: "0",
        cardValue: "",
        participateInSurvey: "",
        claimStatus: "",
        dateImported: "",
        timeImported: "",
        dateFromHIMS: "",
        timeFromHIMS: "",
        dateClaimModified: "",
        timeClaimModified: "",
        notes: "",
        adminUsername: "",
        originator: "",
        programTitle: "",
        status: "",
        transactionmodelandlord: "",
        transactionmodeindividual: "",
        testrecord: false,
        spaAssignmentNo: "",
        hmisApproval: "",
        householdID: "0",
        transactionID: "0",
        landlordPhoneNumber: "",
        landlordEmail: "",
        amountDueforRent: "",
        payee: "",
        rentAmount: "",
        landlordBankName: "",
        landlordAccountNumber: "",
        landlordRoutingNumber: "",
        memoPropertyAddress: "",
        rentPaymentStatus: "",
        rentPaymentStatus_Original: "",
        applicantDocUploadStatus_Original: "",
        amountDueforElectricity: "",
        custElectricBillingAccountNo: "",
        electricAmount: "",
        electricBankName: "",
        electricUtilityProviderAccountNo: "",
        electricRoutingNumber: "",
        electricMemoAccountNo: "",
        electricPaymentStatus: "",
        electricPaymentStatus_Original: "",
        gasProvider: "",
        gasUtilityAccountNo: "",
        amountDueforGas: "",
        custGasBillingAccountNo: "",
        gasBankName: "",
        gasAmount: "",
        gasUtilityProviderAccountNo: "",
        gasRoutingNumber: "",
        gasMemoAccountNo: "",
        gasPaymentStatus: "",
        gasPaymentStatus_Original: "",
        otherPaymentAmount: "",
        otherPaymentStatus: "",
        otherPaymentStatus_Original: "",

        dateEmailTriggered: "",
        timeEmailTriggered: "",
        dateRentPaymentStatusUpdated: "",
        timeRentPaymentStatusUpdated: "",
        dateElectricPaymentStatusUpdated: "",
        timeElectricPaymentStatusUpdated: "",
        dateGasPaymentStatusUpdated: "",
        timeGasPaymentStatusUpdated: "",
        dateOtherPaymentStatusUpdated: "",
        timeOtherPaymentStatusUpdated: "",

        currentTotalHouseholdIncome: "",
        openToFollowup: "",
        gender: "",
        race: "",
        ethnicity: "",
        spaServiceProviderEmail: "",
        noOfTimesParticipated: "",

        landlordSSN: "",
        landlordTaxID: "",
        landlordDUNSNumber: "",
        rentTypeOfAssistance: "",
        monthlyRent: "",
        percentageOfMonthlyRent: "",
        outstandingRentalArrearsAmount: "",
        noOfMonthsRentalPayments: "",

        electricUtilityTaxID: "",
        electricUtilityDUNSNumber: "",
        monthlyElectricBillAmount: "",
        percentageOfElectricBill: "",
        noOfMonthsElectricUtilityPayments: "",
        electricTypeOfAssistance: "",

        gasUtilityTaxID: "",
        gasUtilityDUNSNumber: "",
        monthlyGasBillAmount: "",
        percentageOfGasBill: "",
        noOfMonthsGasUtilityPayments: "",
        gasTypeOfAssistance: "",

        covid_other: "",
        industry_other: "",

        tenantEmail: "",
        dateApplied: "",
        individualBankName: "",
        individualAccountNumber: "",
        individualRoutingNumber: "",
        individualMemoPropertyAddress: "",
        individualPaymentReason: "",
        individualPaymentStatus: "",
        individualPaymentStatus_Original: "",
        dateIndividualPaymentStatusUpdated: "",
        timeIndividualPaymentStatusUpdated: "",
        individualPaymentAmount: "",

        suffix: "",
        middleName: "",
        agent: "",
        doYouRent: "",
        receiveAnyHousingSubsidy: "",
        isAnnualIncomeLess: "",
        isSomeoneOnRentalOrLeaseAgreement: "",
        filedCovid19FinancialStressDeclaration: "",
        receivedEvictionNotice: "",
        dateEvictionNoticeReceived: "",
        SSN: "",
        socialSecurityFirstName: "",
        socialSecurityLastName: "",
        birthdayCode: "",
        age: "",
        otherPhone: "",
        secondaryFirstName: "",
        secondaryLastName: "",
        secondaryEmail: "",
        secondaryPhone: "",

        belowThirtyPercAMI: "",
        thirtyToFiftyPercAMI: "",
        fiftyToEightyPercAMI: "",
        boardDistrict: "",
        landlordFirstAttempt: "",
        landlordResponse: "",

        individualCase: "",
        individualCase_Original: "",
        reasonPaymentNotProcessedIn21Days: "",

        uniqueID: "",

        countAdult: "",
        countChildren: "",
        householdApprovedForUnitedWay: "",
        landlordACHReceived: "",
        individualACHReceived: "",

        landlordAddressLine1: "",
        landlordAddressLine2: "",
        landlordCity: "",
        landlordState: "",
        landlordZipcode: "",

        requestedLandlordInfo: false,
        dateLandlordInfoRequested: "0000-00-00",
        dateReconcilationRequestedNew_Formatted: "0000-00-00",
        dateLandlordInfoRequested_Formatted: "0000-00-00",
        dateLandlordInfoRequestedNew_Formatted: "0000-00-00",
        // Bank landlord  date  requested
        dateLandlordBankInfoRequestedNew_Formatted: "0000-00-00",
        dateIndividualBankInfoRequestedNew_Formatted: "0000-00-00",
        dateReconcilationReminded_Formatted: "0000-00-00",
        //end
        timeLandlordInfoRequested: null,
        countLandlordInfoRequested: 0,
        dateLandlordInfoReminded: "0000-00-00",
        dateLandlordInfoReminded_Formatted: "0000-00-00",
        timeLandlordInfoReminded: null,

        lengthOfTimeForAward: "",
        timestampOfRentIssued: "",

        fundingSource: "ERA2021",
        verified: 0,

        totalCovered: 0,
        totalRecords: 1,

        triggerEmail: "0",
      },
      () => {
        this.forceUpdate();
        this.showCeppGrid();
      }
    );
  };

  showCeppGrid = async () => {
    window.location.assign("/assistoc/santaanaclaim");
    //this.props.updateFunction(5);
    //await this.props.refreshCeppGrid();
  };

  signOut = async () => {
    try {
      await Auth.signOut();
      // signout success
      this.setState({ authState: "signIn" });
      window.location.assign("/");
      // return;
    } catch (e) {
      console.log(e);
    }
  };

  renderPage = (page) => {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser()
      .then((user) => {
        this.setState({ authState: "signedIn" });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ authState: "signIn" });
      });

    switch (page) {
      case 1:
        ReactDOM.render(
          <App showAddUser={"block"} authState={this.state.authState} />,
          document.getElementById("root")
        );
        break;
      case 3:
        ReactDOM.render(
          <App showSubmitClaim={"block"} authState={this.state.authState} />,
          document.getElementById("root")
        );
        break;
      case 5:
        ReactDOM.render(
          <App showCeppClaims={"block"} authState={this.state.authState} />,
          document.getElementById("root")
        );
        break;
      default:
        break;
    }
  };

  date_diff_in_days = (date1, date2) => {
    var dt1 = new Date(date1);
    var dt2 = new Date(date2);
    // Math.floor
    return Math.abs(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
  };

  onPaymentStatusChange = () => {
    if (
      (this.state.landlordDetails.entityPaymentStatus ===
        "Approved for Payment" ||
        this.state.landlordDetails.entityPaymentStatus === "N/A" ||
        this.state.landlordDetails.entityPaymentStatus === "Issued") &&
      (this.state.individualDetails.entityPaymentStatus ===
        "Approved for Payment" ||
        this.state.individualDetails.entityPaymentStatus === "N/A" ||
        this.state.individualDetails.entityPaymentStatus === "Issued")
    ) {
      document.getElementById("individualCaseId").disabled = false;
    } else {
      document.getElementById("individualCaseId").disabled = true;
    }
  };

  handlecheckboxtoggle = (evt) => {
    let isChecked = evt.target.checked == true ? 1 : 0;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        isTestRecord: isChecked,
      },
    }));
  };

  handleSelectChange = (evt) => {
    // const { target: { value }} = evt;
    let value = evt.target.value;
    let name = typeof value === "string" ? value.split(",") : value;
    debugger
    this.setState({ paymentMonths2021: name });
  };

  handleSelect2022Change = (evt) => {
    // const { target: { value }} = evt;
    let value = evt.target.value;
    let name = typeof value === "string" ? value.split(",") : value;
    debugger
    this.setState({ paymentMonths2022: name });
  };

  handleSelectRecChange = (evt) => {
    // const { target: { value }} = evt;
    let value = evt.target.value;
    let name = typeof value === "string" ? value.split(",") : value;
    debugger
    this.setState({ paymentMonthsRec2021: name });
  };

  handleSelect2022RecChange = (evt) => {
    // const { target: { value }} = evt;
    let value = evt.target.value;
    let name = typeof value === "string" ? value.split(",") : value;
    debugger
    this.setState({ paymentMonthsRec2022: name });
  };

  updateIndividualCase = (evt) => {
    var name = evt.target.value;
    if(name == 'Close' && this.state.reconciliationList && this.state.reconciliationList.length > 0) {
     let re = this.state.reconciliationList.filter(X => X.otherPaymentStatus == "" || X.otherPaymentStatus == "Pending");
     if(re.length > 0) {
      setTimeout(() => {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          snackbarSeverity: "error",
          snackbarMessage:
            "Please update additional payment / reconciliation record before Closing Case",
        });
      }, 10);
     }
     else {
      this.setState((prevState) => ({
        applicantDetails: {
          ...prevState.applicantDetails,
          individualCase: name,
        },
      }));
     }
    }
    else {
      this.setState((prevState) => ({
        applicantDetails: {
          ...prevState.applicantDetails,
          individualCase: name,
        },
      }));
    }
    // this.setState({ individualCase: name });
  };

  updateHouseholdApprovedForUnitedWay = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        householdApprovedForUnitedWay: name,
      },
    }));
    // this.setState({ householdApprovedForUnitedWay: name });
  };

  updateFirstName = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        firstName: name,
      },
    }));
    this.setState({ firstName_Error: "" });
  };
  updateLastName = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        lastName: name,
      },
    }));

    this.setState({ lastName_Error: "" });
  };
  updateFirstNameSpouse = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        partnerFirstName: name,
      },
    }));

    this.setState({ partnerFirstName_Error: "" });
  };
  updateLastNameSpouse = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        partnerLastName: name,
      },
    }));

    this.setState({ partnerLastName_Error: "" });
  };

  updateAddress = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantProperty: {
        ...prevState.applicantProperty,
        addressLine1: name,
      },
    }));
    this.setState({ addressLine1_Error: "" });
  };

  updateAddress2 = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantProperty: {
        ...prevState.applicantProperty,
        addressLine2: name,
      },
    }));
    this.setState({ addressLine2_Error: "" });
  };
  updateZip = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantProperty: {
        ...prevState.applicantProperty,
        zipcode: name,
      },
    }));
    this.setState({ zipcode_Error: "" });
  };
  updateCity = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantProperty: {
        ...prevState.applicantProperty,
        city: name,
      },
    }));
    this.setState({ city_Error: "" });
  };
  updateState = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantProperty: {
        ...prevState.applicantProperty,
        state: name,
      },
    }));
    this.setState({ state_Error: "" });
  };

  updatelandlordAddress1 = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordProperty: {
        ...prevState.landlordProperty,
        addressLine1: name,
      },
    }));
    this.setState({ landlordAddressLine1_Error: "" });
  };

  updatelandlordAddress2 = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordProperty: {
        ...prevState.landlordProperty,
        addressLine2: name,
      },
    }));

    this.setState({ landlordAddressLine2_Error: "" });
  };
  updatelandlordZipcode = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      landlordProperty: {
        ...prevState.landlordProperty,
        zipcode: name,
      },
    }));

    this.setState({ landlordZipcode_Error: "" });
  };
  updatelandlordCity = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordProperty: {
        ...prevState.landlordProperty,
        city: name,
      },
    }));

    this.setState({ landlordCity_Error: "" });
  };
  updatelandlordState = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordProperty: {
        ...prevState.landlordProperty,
        state: name,
      },
    }));

    this.setState({ landlordState_Error: "" });
  };

  updatePhone = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        phone: name,
      },
    }));
    this.setState({ phone_Error: "" });
  };

  updateHouseHold = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        householdNumber: name,
      },
    }));
    this.setState({ householdNumber_Error: "" });
  };
  // updateHouseHold1 = (evt) => {
  //     var name = evt.target.value;
  //     name = name.replace(/\D/g, '');
  //     this.setState({ age0to5: name, age0to5_Error: "" });
  // }
  // updateHouseHold2 = (evt) => {
  //     var name = evt.target.value;
  //     name = name.replace(/\D/g, '');
  //     this.setState({ age6to17: name, age6to17_Error: "" });
  // }
  // updateHouseHold3 = (evt) => {
  //     var name = evt.target.value;
  //     name = name.replace(/\D/g, '');
  //     this.setState({ age18to59: name, age18to59_Error: "" });
  // }
  // updateHouseHold4 = (evt) => {
  //     var name = evt.target.value;
  //     name = name.replace(/\D/g, '');
  //     this.setState({ age60andUp: name, age60andUp_Error: "" });
  // }
  updateCountAdult = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        countAdult: name,
      },
    }));

    this.setState({ countAdult_Error: "" });
  };
  updateCountChildren = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        countChildren: name,
      },
    }));
    this.setState({ countChildren_Error: "" });
  };

  updateDOB = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        clientDOB: name,
      },
    }));
    this.setState({ clientDOB: name });
  };

  updateCovidOther = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        covid_other: name,
      },
    }));

    this.setState({ covid_other_Error: "" });
  };

  updateLegallyAbleToWork = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        legallyAbleToWork: name,
      },
    }));
    this.setState({ legallyAbleToWork: name });
  };

  updateWorkIndustry = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        workIndustry: name,
      },
    }));

    this.setState({ workIndustry: name });
  };

  updateIndustryOther = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        industry_other: name,
      },
    }));
    this.setState({ industry_other: name, industry_other_Error: "" });
  };

  updateVeteran = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        veteran: name,
      },
    }));

    this.setState({ veteran: name });
  };

  updateStimulus = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        eligibleForStimulusFunds: name,
      },
    }));
    this.setState({ eligibleForStimulusFunds: name });
  };

  updateEmploymentStatus = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        employmentStatus: name,
      },
    }));
    this.setState({ employmentStatus: name });
  };

  updateIncome = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        monthlyIncomeBeforeCovid19: name,
      },
    }));

    this.setState({ monthlyIncomeBeforeCovid19_Error: "" });
  };

  updateFinancialEffect = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        effectOfCovid19Financially: name,
      },
    }));
    this.setState({ effectOfCovid19Financially: name });
  };

  updateProgram = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        programTitle: name,
      },
    }));
    // this.setState({ programTitle: name });
  };
  updateSPAAssignmentNo = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        spaAssignmentNo: name,
      },
    }));
    this.setState({ spaAssignmentNo_Error: "" });
  };
  updateHIMSAproval = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        hmisApproval: name,
      },
    }));
    // this.setState({ hmisApproval: name });
  };
  updateHouseholdID = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        householdID: name,
      },
    }));
    this.setState({ householdID_Error: "" });
  };
  updateTransactionID = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        transactionID: name,
      },
    }));

    this.setState({ transactionID_Error: "" });
  };
  updateIndividualID = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        individualID: name,
      },
    }));
    // this.setState({ individualID: name });
  };
  updateUniqueID = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        uniqueID: name,
      },
    }));
    this.setState({ uniqueID_Error: "" });
  };
  updateCurrentTotalHouseholdIncome = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        currentTotalHouseholdIncome: name,
      },
    }));

    this.setState({ currentTotalHouseholdIncome_Error: "" });
  };
  updateOpenToFollowUp = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        openToFollowup: name,
      },
    }));

    this.setState({ openToFollowup: name });
  };
  updateGender = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        gender: name,
      },
    }));
    this.setState({ gender_Error: "" });
  };
  updateRace = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        race: name,
      },
    }));
    this.setState({ race_Error: "" });
  };
  updateEthnicity = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        ethnicity: name,
      },
    }));
    this.setState({ ethnicity_Error: "" });
  };
  updateSPAServiceProviderEmail = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        spaServiceProviderEmail: name,
      },
    }));
    this.setState({ spaServiceProviderEmail_Error: "" });
  };
  updateNoOfTimesParticipated = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        noOfTimesParticipated: name,
      },
    }));

    this.setState({ noOfTimesParticipated_Error: "" });
  };

  updateSuffix = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        suffix: name,
      },
    }));

    this.setState({ suffix_Error: "" });
  };
  updateMiddleName = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        middleName: name,
      },
    }));

    this.setState({ middleName_Error: "" });
  };
  updateAgent = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        agent: name,
      },
    }));
    this.setState({ agent_Error: "" });
  };
  updateDoYouRent = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        doYouRent: name,
      },
    }));

    this.setState({ doYouRent_Error: "" });
  };
  updateReceiveAnyHousingSubsidy = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        receiveAnyHousingSubsidy: name,
      },
    }));

    this.setState({ receiveAnyHousingSubsidy_Error: "" });
  };
  // updateIsAnnualIncomeLess = (evt) => {
  //     var name = evt.target.value;
  //     this.setState({ isAnnualIncomeLess: name, isAnnualIncomeLess_Error: "" });
  // }
  updateIsSomeoneOnRentalOrLeaseAgreement = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        isSomeoneOnRentalOrLeaseAgreement: name,
      },
    }));

    this.setState({ isSomeoneOnRentalOrLeaseAgreement_Error: "" });
  };
  updateFiledCovid19FinancialStressDeclaration = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        filedCovid19FinancialStressDeclaration: name,
      },
    }));

    this.setState({ filedCovid19FinancialStressDeclaration_Error: "" });
  };
  updateReceivedEvictionNotice = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        receivedEvictionNotice: name,
      },
    }));

    this.setState({ receivedEvictionNotice_Error: "" });
  };
  updateDateEvictionNoticeReceived = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        dateEvictionNoticeReceived: name,
      },
    }));

    this.setState({ dateEvictionNoticeReceived_Error: "" });
  };
  updateSSN = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        SSN: name,
      },
    }));
    this.setState({ SSN: name, SSN_Error: "" });
  };
  updateSocialSecurityFirstName = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        socialSecurityFirstName: name,
      },
    }));
    this.setState({ socialSecurityFirstName_Error: "" });
  };
  updateSocialSecurityLastName = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        socialSecurityLastName: name,
      },
    }));

    this.setState({ socialSecurityLastName_Error: "" });
  };
  updateBirthdayCode = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        birthdayCode: name,
      },
    }));
    this.setState({ birthdayCode_Error: "" });
  };
  updateAge = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        age: name,
      },
    }));
    this.setState({ age_Error: "" });
  };
  updateOtherPhone = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        otherPhone: name,
      },
    }));

    this.setState({ otherPhone_Error: "" });
  };
  updateSecondaryFirstName = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        secondaryFirstName: name,
      },
    }));

    this.setState({ secondaryFirstName_Error: "" });
  };
  updateSecondaryLastName = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        secondaryLastName: name,
      },
    }));

    this.setState({ secondaryLastName_Error: "" });
  };
  updateSecondaryEmail = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        secondaryEmail: name,
      },
    }));

    this.setState({ secondaryEmail_Error: "" });
  };
  updateSecondaryPhone = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        secondaryPhone: name,
      },
    }));
    this.setState({ secondaryPhone_Error: "" });
  };

  updateBelowThirtyPercAMI = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        belowThirtyPercAMI: name,
      },
    }));

    this.setState({ belowThirtyPercAMI: name });
  };
  updateThirtyToFiftyPercAMI = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        thirtyToFiftyPercAMI: name,
      },
    }));

    this.setState({ thirtyToFiftyPercAMI: name });
  };
  updateFiftyToEightyPercAMI = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        fiftyToEightyPercAMI: name,
      },
    }));
    this.setState({ fiftyToEightyPercAMI: name });
  };
  updateBoardDistrict = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        boardDistrict: name,
      },
    }));
  };
  updateLengthOfTimeForAward = (evt) => {
    var name = evt.target.value;
    this.setState({ lengthOfTimeForAward: name });
  };

  updateSPVerified = (evt) => {
    var name = +evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        verified: name,
      },
    }));
  };

  ///
  updateLandlordPhone = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        landlordPhoneNumber: name,
      },
    }));

    this.setState({ landlordPhoneNumber_Error: "" });
  };

  updateLandlordEmail = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        landlordEmail: name,
        isLandlordEmailChanged: this.state.landlordEmail_Original !== name ? true : false,
      },
    }));

    this.setState({ landlordEmail_Error: "" });
  };

  ///
  updateDollarAmountDueForRent = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityAmountDue: name,
      },
    }));

    this.setState({ amountDueforRent_Error: "" });

    if (
      name !== 0 &&
      name !== "0.00" &&
      name !== "" &&
      name !== null &&
      name !== undefined &&
      this.state.landlordDetails.entityAmount !== 0 &&
      this.state.landlordDetails.entityAmount !== "0.00" &&
      this.state.landlordDetails.entityAmount !== "" &&
      this.state.landlordDetails.entityAmount !== null &&
      this.state.landlordDetails.entityAmount !== undefined
    ) {
      // this.setState({
      //   percentageOfMonthlyRent: (
      //     (this.state.landlordDetails.entityAmount * 100) /
      //     name
      //   ).toFixed(2),
      // });
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          entityPercentageOfMonthlyBill: ((this.state.landlordDetails.entityAmount * 100) / name).toFixed(2),
        },
      }));
    } else {
      // this.setState({ percentageOfMonthlyRent: "" });
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          entityPercentageOfMonthlyBill: "",
        },
      }));
    }
  };

  onLandlordACHReceivedChange = (evt) => {
    if (evt.target.checked === true) {
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          entityACHReceived: 1,
        },
      }));
    } else {
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          entityACHReceived: 0,
        },
      }));
    }
  };

  //payment details section function of landlord starts
  handleTransectionModeLandlord = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        transactionType: name,
      },
    }));
  };

  onRoutingNumberChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        entityRoutingNumber: name,
      },
    }));
    this.setState({ landlordpaymentDetailsEntityRoutingNumber_Error: "" });
  };
  onentityAccountNymberChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        entityAccountNumber: name,
      },
    }));
    this.setState({ landlordpaymentDetailsEntityAccountNumber_Error: "" });
  };
  oneLandlordPayeeNameChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        payeeName: name,
      },
    }));
  };

  onLandlordentityBankNameChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        entityBankName: name,
      },
    }));
  };

  onLandlordentityBankAccountTypeChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        entityAccountType: name,
      },
    }));
  };
  oneLandlordInformationStatusChange = (evt) => {
    
      let isValuePresent = (this.state.landlordpaymentDetailsClone.entityAccountNumber !== "" && 
      this.state.landlordpaymentDetailsClone.entityAccountNumber !== null && 
      this.state.landlordpaymentDetailsClone.entityAccountNumber !== undefined) || 
      (this.state.landlordpaymentDetailsClone.entityRoutingNumber !== "" && 
      this.state.landlordpaymentDetailsClone.entityRoutingNumber !== null && 
      this.state.landlordpaymentDetailsClone.entityRoutingNumber !== undefined)


    if (this.state.landlordpaymentDetails.transactionType === 'ACH' && 
      evt.target.value === 'Ready' 
      && (this.state.applicantDetails.receiverCompany === "" 
      || this.state.applicantDetails.receiverCompany === null 
      || this.state.applicantDetails.receiverCompany === undefined ) && isValuePresent &&
      this.state.landlordpaymentDetailsClone.informationStatus !== 'Ready' ) {
      this.setState({ landlordpaymentInformationStatus_Error: "Right banking information expected" });
      setTimeout(() => {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          //snackbarSeverity: "error",
          snackbarMessage:
            "The ACH details are not rightly verified or are pending. Please ask Landlord to add correct ACH details.",
        });
      }, 10);
    } else {
      var name = evt.target.value;
      this.setState((prevState) => ({
        landlordpaymentDetails: {
          ...prevState.landlordpaymentDetails,
          informationStatus: name,
        },
      }));
      this.setState({ landlordpaymentInformationStatus_Error: "" });
    }
  };

  oneLandlordcheckStatusStatusChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        checkStatus: name,
      },
    }));
  };

  oneLandlordFullAddressChange = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordpaymentDetails: {
        ...prevState.landlordpaymentDetails,
        fullAddress: name,
      },
    }));
  };

  // oneLandlordCheckStatusChange = (evt) => {
  //     var name = evt.target.value;
  //     this.setState((prevState) => ({
  //         landlordpaymentDetails: {
  //             ...prevState.landlordpaymentDetails,
  //             checkStatus: name,
  //         },
  //     }));
  // }

  //////payment details section function of landlord ends

  updatePayee = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        payee: name,
      },
    }));
    this.setState({ payee: name });
  };
  updateAmount = (evt) => {
    var name = evt.target.value;
    // this.setState({ rentAmount: name, total1099Amount: +this.state.otherPaymentAmount + +name, rentAmount_Error: "" });
    // this.setState({ rentAmount: name, rentAmount_Error: "" });

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityAmount: name,
      },
    }));

    this.setState({ rentAmount_Error: "" }, function () {
      this.calculateTotal1099();
      this.calculateTotal10992022();
     // this.calculateTotalIssued();
    });

    if (
      this.state.landlordDetails.entityAmountDue !== 0 &&
      this.state.landlordDetails.entityAmountDue !== "0.00" &&
      this.state.landlordDetails.entityAmountDue !== "" &&
      this.state.landlordDetails.entityAmountDue !== null &&
      this.state.landlordDetails.entityAmountDue !== undefined &&
      name !== 0 &&
      name !== "0.00" &&
      name !== "" &&
      name !== null &&
      name !== undefined
    ) {
      this.setState({
        percentageOfMonthlyRent: (
          (name * 100) /
          this.state.landlordDetails.entityAmountDue
        ).toFixed(2),
      });
    } else {
      this.setState({ percentageOfMonthlyRent: "" });
    }
  };
  updateLandlordBankName = (evt) => {
    var name = evt.target.value;

    this.setState({ landlordBankName: name });
  };
  updateLandlordAccountNumber = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');
    this.setState({
      landlordAccountNumber: name,
      landlordAccountNumber_Error: "",
    });
  };
  updateLandlordRoutingNumber = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState({
      landlordRoutingNumber: name,
      landlordRoutingNumber_Error: "",
    });
  };
  updatePropertyAddress = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityMemoPropertyAddress: name,
      },
    }));

    this.setState({ entityMemoPropertyAddress: name });
  };
  updateRentPaymentStatus = (evt) => {
    var name = evt.target.value;

    if(name  === "Approved for Payment") {
      //if(!this.state.landlordpaymentDetails && (this.state.landlordpaymentDetails.informationStatus == "Ready" || this.state.landlordpaymentDetails.informationStatus == "Issued")) {
       if(this.state.landlordpaymentDetails && (this.state.landlordpaymentDetails.informationStatus == "Ready" || this.state.landlordpaymentDetails.informationStatus == "Issued")) {
        this.setState((prevState) => ({
          landlordDetails: {
            ...prevState.landlordDetails,
            entityPaymentStatus: name,
          },
        }));
        this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" }, function () {
          this.calculateTotal1099();
          this.calculateTotal10992022();
         // this.calculateTotalIssued();
        });
        // need to change
        if (
          (name === "Approved for Payment" ||
            name === "N/A" ||
            name === "Issued") &&
          (this.state.individualDetails.entityPaymentStatus ===
            "Approved for Payment" ||
            this.state.individualDetails.entityPaymentStatus === "N/A" ||
            this.state.individualDetails.entityPaymentStatus === "Issued")
          // &&
          // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
        ) {
          document.getElementById("individualCaseId").disabled = false;
        } else {
          document.getElementById("individualCaseId").disabled = true;
        }
       }
       else {
          //Bank information is not yet in ready state.
          setTimeout(() => {
            this.setState({
              isLoading: false,
              openSnackbar: true,
              snackbarMessageClass: "Mui-filledError",
              snackbarSeverity: "error",
              snackbarMessage:
                "Bank information is not yet in ready state.",
            });
          }, 10);
       }
    }
    else {
      this.setState((prevState) => ({
        landlordDetails: {
          ...prevState.landlordDetails,
          entityPaymentStatus: name,
        },
      }));
      // this.setState({ rentPaymentStatus: name, reasonPaymentNotProcessedIn21Days_Error: "" });
      this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" }, function () {
        this.calculateTotal1099();
        this.calculateTotal10992022();
       // this.calculateTotalIssued();
      });
      // need to change
      if (
        (name === "Approved for Payment" ||
          name === "N/A" ||
          name === "Issued") &&
        (this.state.individualDetails.entityPaymentStatus ===
          "Approved for Payment" ||
          this.state.individualDetails.entityPaymentStatus === "N/A" ||
          this.state.individualDetails.entityPaymentStatus === "Issued")
        // &&
        // (this.state.otherPaymentStatus === 'Approved for Payment' || this.state.otherPaymentStatus === 'N/A' || this.state.otherPaymentStatus === 'Issued')
      ) {
        document.getElementById("individualCaseId").disabled = false;
      } else {
        document.getElementById("individualCaseId").disabled = true;
      }
    }
  };
  updateLandlordSSN = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        landlordSSN: name,
      },
    }));
    this.setState({ landlordSSN_Error: "" });
  };
  updateLandlordTaxID = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityTaxID: name,
      },
    }));

    this.setState({ landlordTaxID_Error: "" });
  };
  updateLandlordDUNSNumber = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityDUNSNumber: name,
      },
    }));

    this.setState({ landlordDUNSNumber_Error: "" });
  };
  updateRentTypeOfAssistance = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        rentTypeOfAssistance: name,
      },
    }));
  };
  updateMonthlyRent = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityMonthlyAmount: name,
      },
    }));
    this.setState({ monthlyRent_Error: "" });
  };
  updatePercentageOfMonthlyRent = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");
    this.setState({
      percentageOfMonthlyRent_Error: "",
    });
    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityPercentageOfMonthlyBill: name,
      },
    }));
  };
  updateOutstandingRentalArrears = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        outstandingRentalArrearsAmount: name,
      },
    }));

    this.setState({ outstandingRentalArrearsAmount_Error: "" });
  };
  updateNoOfMonthsRentalPayments = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        entityNoOfMonthsUtilityPayments: name,
      },
    }));

    this.setState({ noOfMonthsRentalPayments_Error: "" });
  };
  updateTimestampOfRentIssued = (evt) => {
    var name = evt.target.value;
    this.setState({ timestampOfRentIssued: name });
  };
  updateLandlordFirstAttempt = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        landlordFirstAttempt: name,
      },
    }));
  };
  updateReasonPaymentNotProcessedIn21Days = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        reasonPaymentNotProcessedIn21Days: name,
      },
    }));

    this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
  };

  updateReceiverCompany = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        receiverCompany: name,
      },
    }));

    // this.setState({ reasonPaymentNotProcessedIn21Days_Error: "" });
  };
  updateTenantDocumentStatus = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        applicantDocUploadStatus: name,
      },
    }));
    // this.setState({ applicantDocUploadStatus: name });
  };

  updateLandlordInfoRequestedStatus = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      landlordDetails: {
        ...prevState.landlordDetails,
        landlordInfoRequestedStatus: name,
      },
    }));
    // this.setState({ landlordInfoRequestedStatus: name });
  };

  updateNotes = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        notes: name,
      },
    }));

    this.setState({ notes_Error: "" });
  };

  updateDateApplied = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        dateApplied: name,
      },
    }));
    this.setState({ dateApplied_Error: "" });
  };

  updateTenantEmail = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        tenantEmail: name,
      },
    }));

    this.setState({ tenantEmail_Error: "" });
  };

  updateIndividualBankName = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        individualBankName: name,
      },
    }));
  };

  updateIndividualAccountNumber = (evt) => {
    var name = evt.target.value;
    //name = name.replace(/\D/g, '');

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        individualAccountNumber: name,
      },
    }));
    this.setState({
      individualAccountNumber_Error: "",
    });
  };

  updateIndividualRoutingNumber = (evt) => {
    var name = evt.target.value;
    name = name.replace(/\D/g, "");

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        individualRoutingNumber: name,
      },
    }));
    this.setState({
      individualRoutingNumber_Error: "",
    });
  };

  updateIndividualPaymentAmount = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        entityAmount: name,
      },
    }));

     this.setState({ individualPaymentAmount_Error: "" });
    // this.setState({ individualPaymentAmount_Error: "" }, function () {
    //   this.calculateTotalIssued();
    // });
  };

  updateIndividualPaymentStatus = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        entityPaymentStatus: name,
      },
    }));

    // need to change
    if (
      (this.state.landlordDetails.entityPaymentStatus ===
        "Approved for Payment" ||
        this.state.landlordDetails.entityPaymentStatus === "N/A" ||
        this.state.landlordDetails.entityPaymentStatus === "Issued") &&
      (name === "Approved for Payment" || name === "N/A" || name === "Issued")
    ) {
      document.getElementById("individualCaseId").disabled = false;
    } else {
      document.getElementById("individualCaseId").disabled = true;
    }

  };

  updateIndividualMemoPropertyAddress = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        entityMemoPropertyAddress: name,
      },
    }));
    this.setState({ individualMemoPropertyAddress_Error: "" });
  };

  onIndividualACHReceivedChange = (evt) => {
    if (evt.target.checked === true) {
      this.setState((prevState) => ({
        individualDetails: {
          ...prevState.individualDetails,
          individualACHReceived: 1,
        },
      }));
    } else {
      this.setState((prevState) => ({
        individualDetails: {
          ...prevState.individualDetails,
          individualACHReceived: 0,
        },
      }));
    }
  };
  ////Individual payment section starts

  handleTransectionModeIndividual = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        transactionType: name,
      },
    }));
  };
  onIndividualRoutingNumberChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        entityRoutingNumber: name,
      },
    }));
  };

  onIndividualBankAccountNumberChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        entityAccountNumber: name,
      },
    }));
  };
  onIndividualPayeeNameChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        payeeName: name,
      },
    }));
  };

  onindividualentityBankNameChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        entityBankName: name,
      },
    }));
  };

  onindividualentityBankAccountTypeChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        entityAccountType: name,
      },
    }));
  };

  onIndividualInformationStatusChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        informationStatus: name,
      },
    }));
  };
  onIndividualFullAddressChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        fullAddress: name,
      },
    }));
  };
  onIndividualPayeeNamesChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        payeeName: name,
      },
    }));
  };
  onIndividualinformationStatusChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        informationStatus: name,
      },
    }));
  };
  onIndividualCheckStatusChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualpaymentDetails: {
        ...prevState.individualpaymentDetails,
        checkStatus: name,
      },
    }));
  };
  //individual Payment detail section function ends

  //reconcillation  payment  details starts

  handleTransectionModeReconcillation = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        transactionType: name,
      },
    }));
  };
  onReconcillationRoutingNumberChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        entityRoutingNumber: name,
      },
    }));
    this.setState({ reconciliationEntityRoutingNumber_Error: "" });
  };

  onReconcillationBankAccountNumberChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        entityAccountNumber: name,
      },
    }));
    this.setState({ reconciliationEntityAccountNumber_Error: "" });
  };
  onReconcillationPayeeNameChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        payeeName: name,
      },
    }));
  };

  onReconcillationentityBankNameChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        entityBankName: name,
      },
    }));
  };

  onReconcillationentityBankAccountTypeChange = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        entityAccountType: name,
      },
    }));
  };

  onReconcillationInformationStatusChanges = (evt) => {

    if (this.state.reconciliation.transactionType === 'ACH'
      && evt.target.value === 'Ready' 
      && (this.state.applicantDetails.receiverCompany === "" 
      || this.state.applicantDetails.receiverCompany === null 
      || this.state.applicantDetails.receiverCompany === undefined)  && 
      (this.state.isRecAccountNoOld || this.state.isRecRoutingNoOld) &&
      this.state.isRecInformationStatusOld !== 'Ready') {
      this.setState({ reclandlordpaymentInformationStatus_Error: "Right banking information expected" });
      setTimeout(() => {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          //snackbarSeverity: "error",
          snackbarMessage:
            "The ACH details are not rightly verified or are pending. Please ask Landlord to add correct ACH details.",
        });
      }, 10);
    } else {
      var name = evt.target.value;
      this.setState((prevState) => ({
        reconciliation: {
          ...prevState.reconciliation,
          informationStatus: name,
        },
      }));
      this.setState({ reclandlordpaymentInformationStatus_Error: "" });
    }
  };

  onReconcillationDocumentStatusChanges = (evt) => {
    var name = evt.target.value;
    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        documentStatus: name,
      },
    }));
  };

  onReconcillationFullAddressChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        fullAddress: name,
      },
    }));
  };
  onReconcillationPayeeNamesChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        payeeName: name,
      },
    }));
  };
  onReconcillationinformationStatusChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        informationStatus: name,
      },
    }));
  };
  onReconcillationCheckStatusChanges = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      reconciliation: {
        ...prevState.reconciliation,
        checkStatus: name,
      },
    }));
  };

  //reconcillation  payment details ends
  updateIndividualPaymentReason = (evt) => {
    var name = evt.target.value;

    this.setState((prevState) => ({
      individualDetails: {
        ...prevState.individualDetails,
        entityPaymentReason: name,
      },
    }));
  };

  openAddPropertyPopup = async () => {
    document.getElementById("recform").reset();
    this.setState({
      reconciliation: {
        entityType: "",
        initiallyPaidBy: "",
        amountType: "",
        amount: "",
        memoPropertyAddress: "",
        paymentMethod: "",
        otherPaymentStatus: "Pending",
        amountCoveredAfterRec: "",
        amountCoveredBeforeRec: "",
        transactionNo: "",
        notes: "",
        modifiedBy: "",
        dateCreated: "",
        timeStatusIssued: "",
        dateStatusIssued: "",
        timeCreated: "",
        transactionType: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },
      reconciliationMode: "add",
      isChildRecord: false,
      paymentMonthsRec2021: [],
      paymentMonthsRec2021Clone: [],
      paymentMonthsRec2022: [],
      paymentMonthsRec2022Clone: []
    });

    var rentPaymentStatusSelect = document.getElementById(
      "otherPaymentStatusIdRec"
    );
    if (rentPaymentStatusSelect.options.length > 4) {
      rentPaymentStatusSelect.remove(4);
    }

    this.setState({ isRecRoutingNoOld: false, isRecAccountNoOld: false, isRecInformationStatusOld: '' });

    // this.setState({ : [] });
    // this.setState({ : [] });
    setTimeout(() => {
      $("#exampleModal").modal("show");
    }, 100);
  };

  openPopup = async () => {

    setTimeout(() => {
      $("#addDocumentsModal").modal("show");
    }, 100);
  };

   editReconciliation(id) {
    this.setState({ validationErrors: {} , reconciliationMode: "edit"});
    // let editRec = this.state.reconciliationListClone.filter(
    //   (x) => x.id == id
    // )[0];
  let deepClone = JSON.parse(JSON.stringify(this.state.reconciliationListClone));  
  let editRec = deepClone.filter(x => x.id == id)[0];
  let test = editRec;
    let entity = this.state.reconciliationList.find(
      (x) => x.id == id
    ).entityType;
    let rec = this.state.reconciliationList.filter(
      (x) => x.entityType == entity
    );
    if (rec && rec.length > 0) {
      if (rec.pop().id != id) {
        this.setState({
          isChildRecord: true,
        });
      } else {
        this.setState({
          isChildRecord: false,
        });
      }
    }
    var d = new Date(editRec.dateBankInfoRequested);
    var infoRequestedDate = [
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
      d.getFullYear(),
    ].join("/");
    var dr = new Date(editRec.dateBankInfoReminded);
    var infoRemindedDate = [
      ("0" + (dr.getMonth() + 1)).slice(-2),
      ("0" + dr.getDate()).slice(-2),
      dr.getFullYear(),
    ].join("/");


    this.setState({
      dateReconcilationRequestedNew_Formatted: infoRequestedDate,
      dateReconcilationReminded_Formatted: infoRemindedDate,
    });
    if (test && test.entityType == "Individual" && (test.informationStatus == null || test.informationStatus == "")) {
      test.informationStatus = "Ready";
      test.transactionType = "Check";
    }

    if(test && test.otherPaymentStatus == "") 
    {
      test.otherPaymentStatus = "Pending";
    }

    if (test.paymentMonth2021 != null && test.paymentMonth2021 != "") {
      var month = test.paymentMonth2021.split(',');
      this.setState({ paymentMonthsRec2021: month });
      this.setState({ paymentMonthsRec2021Clone: month });
    }
    else {
      this.setState({ paymentMonthsRec2021: [] });
      this.setState({ paymentMonthsRec2021Clone: [] });
    }

    if (test.paymentMonth2022 != null && test.paymentMonth2022 != "") {
      var month = test.paymentMonth2022.split(',');
      this.setState({ paymentMonthsRec2022: month });
      this.setState({ paymentMonthsRec2022Clone: month });
    }
    else {
      this.setState({ paymentMonthsRec2022: [] });
      this.setState({ paymentMonthsRec2022Clone: [] });
    }

    this.setState({
      reconciliation: test,
      reconciliationMode: "edit",
      reconciliationEditId: id,
      otherPaymentStatus_Original: test.otherPaymentStatus,
    });

    var rentPaymentStatusSelect = document.getElementById(
      "otherPaymentStatusIdRec"
    );
    if(test.requestedBankInfo == true) {
      if (!(rentPaymentStatusSelect.options.length > 4)) {
        var rentPaymentStatusIssuedOption = document.createElement("option");
        rentPaymentStatusIssuedOption.text = "Issued";
        rentPaymentStatusIssuedOption.value = "Issued";
        rentPaymentStatusSelect.appendChild(rentPaymentStatusIssuedOption);
      }
    }
    else {
      if (rentPaymentStatusSelect.options.length > 4) {
        rentPaymentStatusSelect.remove(4);
      }
    }

    this.state.isRecRoutingNoOld = (test.entityRoutingNumber !== "" && test.entityRoutingNumber !== null && test.entityRoutingNumber !== undefined) ? true : false;
    this.state.isRecAccountNoOld = (test.entityAccountNumber !== "" && test.entityAccountNumber !== null && test.entityAccountNumber !== undefined) ? true : false;
    this.state.isRecInformationStatusOld = test.informationStatus;
    setTimeout(() => {
      var res = this.recloadUploadedDocuments();
    $("#exampleModal").modal("show");
    }, 10);
 
    // let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
    // let path = '/getpropertyownerlist';
    // var res = await API.get(apiName, path);
  }

  handleReconciliationChanges(field, e) {
    if (
      field == "entityType" ||
      field == "initiallyPaidBy" ||
      field == "amountType"
    ) {
      const { reconciliation } = { ...this.state };
      const currentState = reconciliation;
      currentState[field] = e.target.value;
      if (field == "entityType" && e.target.value == "Individual") {
        currentState["transactionType"] = "Check";
        currentState["informationStatus"] = "Ready";
      }
      if (field == "entityType" && e.target.value == "Landlord") {
        currentState["transactionType"] = "";
        currentState["informationStatus"] = "";
      }
      this.setState({ reconciliation: currentState });
      if (
        field == "amountType" ||
        (field == "entityType" &&
          this.state.reconciliation.entityType &&
          this.state.reconciliation.entityType !== "" &&
          this.state.reconciliation.amountType &&
          this.state.reconciliation.amountType !== "" &&
          this.state.reconciliation.amount &&
          this.state.reconciliation.amount !== "")
      ) {
        this.calculateAmountCoveredAfterRec(
          this.state.reconciliation.entityType
        );
      }
    } else if (field == "amount" || field == "amountCoveredAfterRec") {
      let { value } = e.target;
      //  if(!value.isNaN()) {
      let intValue = value.replace(/[^0-9.]/g, "");
      const { reconciliation } = { ...this.state };
      const currentState = reconciliation;
      currentState[field] = intValue;
      //   this.setState({ reconciliation: currentState });
      this.setState({ reconciliation: currentState });
      if (
        field == "amount" &&
        this.state.reconciliation.entityType &&
        this.state.reconciliation.entityType !== "" &&
        this.state.reconciliation.amountType &&
        this.state.reconciliation.amountType !== "" &&
        this.state.reconciliation.amount &&
        this.state.reconciliation.amount !== ""
      ) {
        this.calculateAmountCoveredAfterRec(
          this.state.reconciliation.entityType
        );
      }
      // }
    } 
    else if (field == "otherPaymentStatus") {
      const { value } = e.target;
      if(value  === "Approved for Payment") {
        if (this.state.reconciliation.informationStatus == "Ready" || this.state.reconciliation.informationStatus == "Issued") {
          const { reconciliation } = { ...this.state };
          const currentState = reconciliation;
          currentState[field] = value;
          if (value == "Issued") {
            currentState["informationStatus"] = "Issued";
          }
          //  this.setState({ reconciliation: currentState });
          this.setState({ reconciliation: currentState });
        }
        else {
          setTimeout(() => {
            this.setState({
              isLoading: false,
              openSnackbar: true,
              snackbarMessageClass: "Mui-filledError",
              snackbarSeverity: "error",
              snackbarMessage:
                "Bank information is not yet in ready state.",
            });
          }, 10);
        }
      
      }
      else {
        const { value } = e.target;
        const { reconciliation } = { ...this.state };
        const currentState = reconciliation;
        currentState[field] = value;
        if (value == "Issued") {
          currentState["informationStatus"] = "Issued";
        }
        //  this.setState({ reconciliation: currentState });
        this.setState({ reconciliation: currentState });
      }
    }
    else {
      const { value } = e.target;
      const { reconciliation } = { ...this.state };
      const currentState = reconciliation;
      currentState[field] = value;
      if (field == "otherPaymentStatus" && value == "Issued") {
        currentState["informationStatus"] = "Issued";
      }
      //  this.setState({ reconciliation: currentState });
      this.setState({ reconciliation: currentState });


    }

    // this.handlePropertyOnchangeValidation(field);
  }
  calculateAmountCoveredAfterRec(entityType) {
    let findRecord = this.state.reconciliationList.filter(
      (x) => x.entityType == entityType
    );
    let amountCoveredAfterRec = 0;
    let amountCoveredBeforeRec = 0;
    let rentAmountorReco = 0;
    // first check record exists
    if (findRecord && findRecord.length > 0) {
      let oldRecord = findRecord.pop();
      if (this.state.reconciliationMode == "add") {
        rentAmountorReco = oldRecord.amountCoveredAfterRec;
        amountCoveredBeforeRec = oldRecord.amountCoveredAfterRec;
      } else {
        let findRec = this.state.reconciliationList.find(
          (x) => x.id == this.state.reconciliationEditId
        );
        rentAmountorReco = findRec.amountCoveredBeforeRec;
      }
    } else {
      if (entityType == "Individual") {
        rentAmountorReco =
          this.state.individualDetails.entityAmount == null
            ? 0
            : this.state.individualDetails.entityAmount;
        amountCoveredBeforeRec =
          this.state.individualDetails.entityAmount == null
            ? 0
            : this.state.individualDetails.entityAmount;
      }

      //   else if (entityType == "AB832") {
      //     rentAmountorReco =
      //       this.state.otherPaymentAmount == null
      //         ? 0
      //         : this.state.otherPaymentAmount;
      //     amountCoveredBeforeRec =
      //       this.state.otherPaymentAmount == null
      //         ? 0
      //         : this.state.otherPaymentAmount;
      //   }
      else {
        rentAmountorReco =
          this.state.landlordDetails.entityAmount == null
            ? 0
            : this.state.landlordDetails.entityAmount;
        amountCoveredBeforeRec =
          this.state.landlordDetails.entityAmount == null
            ? 0
            : this.state.landlordDetails.entityAmount;
      }
    }

    if (
      this.state.reconciliation.amountType == "Paid" ||
      this.state.reconciliation.amountType == "Adjustment(Positive)"
    ) {
      amountCoveredAfterRec =
        Number(rentAmountorReco) + Number(this.state.reconciliation.amount);
    } else {
      amountCoveredAfterRec =
        Number(rentAmountorReco) - Number(this.state.reconciliation.amount);
    }
    const { reconciliation } = { ...this.state };
    const currentState = reconciliation;
    currentState["amountCoveredAfterRec"] = amountCoveredAfterRec.toFixed(2);
    if (this.state.reconciliationMode == "add") {
      currentState["amountCoveredBeforeRec"] = amountCoveredBeforeRec;
    }
    this.setState({ reconciliation: currentState });
  }
  // calculateAmountCoveredAfterRec() {
  //     if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.amount) {
  //         let amountCoveredAfterRec = 0;
  //         if (this.state.reconciliation.amountType == "Paid") {
  //             amountCoveredAfterRec = Number(this.state.rentAmount) + Number(this.state.reconciliation.amount);
  //         }
  //         else {
  //             amountCoveredAfterRec = Number(this.state.rentAmount) - Number(this.state.reconciliation.amount);
  //         }
  //         const { reconciliation } = { ...this.state };
  //         const currentState = reconciliation;
  //         currentState['amountCoveredAfterRec'] = amountCoveredAfterRec;
  //         // this.setState({ reconciliation: currentState });
  //         this.setState({ reconciliation: currentState });
  //     }
  //     if (this.state.reconciliation.entityType == "Individual" && this.state.reconciliation.amount) {
  //         let amountCoveredAfterRec = 0;
  //         if (this.state.reconciliation.amountType == "Paid") {
  //             amountCoveredAfterRec = Number(this.state.individualPaymentAmount) + Number(this.state.reconciliation.amount);
  //         }
  //         else {
  //             amountCoveredAfterRec = Number(this.state.individualPaymentAmount) - Number(this.state.reconciliation.amount);
  //         }
  //         const { reconciliation } = { ...this.state };
  //         const currentState = reconciliation;
  //         currentState['amountCoveredAfterRec'] = amountCoveredAfterRec;
  //         // this.setState({ reconciliation: currentState });
  //         this.setState({ reconciliation: currentState });
  //     }
  // }
  addReconciliation = async () => {
    let dateStatusIssued
    let timeStatusIssued
    if (this.state.otherPaymentStatus_Original != this.state.reconciliation.otherPaymentStatus && this.state.reconciliation.otherPaymentStatus == "Issued") {

      var issueDateTime = new Date(
        new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
      );
      var todaysDate = [
        issueDateTime.getFullYear(),
        ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
        ("0" + issueDateTime.getDate()).slice(-2),
      ].join("-");

      var todaysTime = [
        ("0" + issueDateTime.getHours()).slice(-2),
        ("0" + issueDateTime.getMinutes()).slice(-2),
        ("0" + issueDateTime.getSeconds()).slice(-2),
      ].join(":");


      dateStatusIssued = todaysDate;
      timeStatusIssued = todaysTime;
      // add email to landlord
      if(this.state.reconciliation.entityType == "Landlord") {
        var result = window.confirm(
          `You have updated additional payment status to Issued. This will trigger an email to landlord on ${this.state.applicantDetails.landlordEmail} and service provider on ${this.state.applicantDetails.spaServiceProviderEmail}. Please click Ok to confirm.`
        );
        if (result !== false) {
          await this.triggerRentPaymentIssuedEmail(true);
          $("#exampleModal").modal("hide");
        } else {
       //   this.rentPaymentStatusRef.current.focus();
          this.setState({ isLoading: false });
          return false;
        }
      }
    } else {
      dateStatusIssued = this.state.reconciliation.dateStatusIssued;
      timeStatusIssued = this.state.reconciliation.timeStatusIssued;
    }
    // month changes
    let isEqualPaymentMonthRec2021 = true;
    let isEqualPaymentMonthRec2022 = true;
    if (this.state.paymentMonthsRec2021 && this.state.paymentMonthsRec2021.length > 0) {
      let clone = this.state.paymentMonthsRec2021Clone;
      isEqualPaymentMonthRec2021 = this.state.paymentMonthsRec2021.length == this.state.paymentMonthsRec2021Clone.length && this.state.paymentMonthsRec2021.every(function (element, index) {
        return element === clone[index];
      });
    }

    if (this.state.paymentMonthsRec2021 && this.state.paymentMonthsRec2021.length == 0 && this.state.paymentMonthsRec2021Clone.length > 0) {
      // this.setState({paymentMonths2021 : 0 });
      isEqualPaymentMonthRec2021 = false;
    }

    if (this.state.paymentMonthsRec2022 && this.state.paymentMonthsRec2022.length > 0) {
      let clone = this.state.paymentMonthsRec2022Clone;
      isEqualPaymentMonthRec2022 = this.state.paymentMonthsRec2022.length == this.state.paymentMonthsRec2022Clone.length && this.state.paymentMonthsRec2022.every(function (element, index) {
        return element === clone[index];
      });
    }

    if (this.state.paymentMonthsRec2022 && this.state.paymentMonthsRec2022.length == 0 && this.state.paymentMonthsRec2022Clone.length > 0) {
      isEqualPaymentMonthRec2022 = false;
    }
    //
    var d = new Date(
      new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
    );
    var todaysDate = [
      d.getFullYear(),
      ("0" + (d.getMonth() + 1)).slice(-2),
      ("0" + d.getDate()).slice(-2),
    ].join("-");

    var todaysTime = [
      ("0" + d.getHours()).slice(-2),
      ("0" + d.getMinutes()).slice(-2),
      ("0" + d.getSeconds()).slice(-2),
    ].join(":");



    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/addreconciliation";
    console.log(
      "this.state.individualID",
      this.state.individualID,
      this.state.fundingSource
    );
    let rouno = this.state.reconciliation.entityRoutingNumber;
    let accno = this.state.reconciliation.entityAccountNumber;
    if (this.state.isTransactionPermission) {
      accno = accno !== null && accno !== undefined && accno !== "" ? await this.encryptData(accno) : '';
      rouno = rouno !== null && rouno !== undefined && rouno !== "" ? await this.encryptData(rouno) : '';
    }
    else {
      accno = '';
      rouno = '';
    }
    let myInit
    if (this.state.reconciliation.requestedBankInfo === true || this.state.reconciliation.requestedBankInfo === 1) {
      myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.individualID,
            fundingSource: this.state.fundingSource,
            amount: this.state.reconciliation.amount,
            amountCoveredAfterRec:
              this.state.reconciliation.amountCoveredAfterRec,
            amountCoveredBeforeRec:
              this.state.reconciliation.amountCoveredBeforeRec,
            paymentMethod: this.state.reconciliation.amountType,
            amountType: this.state.reconciliation.amountType,
            entityType: this.state.reconciliation.entityType,
            initiallyPaidBy: this.state.reconciliation.initiallyPaidBy,
            notes: this.state.reconciliation.notes,
            transactionNo: this.state.reconciliation.transactionNo,
            isTransection: 1,
            requestedBankInfo: this.state.reconciliation.requestedBankInfo != null && this.state.reconciliation.requestedBankInfo != "" ? this.state.reconciliation.requestedBankInfo : 0,
            dateBankInfoRequested: todaysDate,
            timeBankInfoRequested: todaysTime,
            countBankInfoRequested:
              this.state.reconciliation.countBankInfoRequested == null
                ? 1
                : this.state.reconciliation.countBankInfoRequested + 1,

            mode: this.state.reconciliationMode,
            memoPropertyAddress: this.state.reconciliation.memoPropertyAddress,
            otherPaymentStatus: this.state.reconciliation.otherPaymentStatus,
            id: this.state.reconciliationEditId,
            modifiedBy: this.state.adminUsername,
            dateCreated: this.state.reconciliation.dateCreated,
            timeCreated: this.state.reconciliation.timeCreated,
            dateStatusIssued: dateStatusIssued,
            timeStatusIssued: timeStatusIssued,
            program: "santana",
            createdBy: this.state.adminUsername,
            // modifiedBy: this.state.applicantDetails.adminUsername,
            entityAccountType: this.state.reconciliation.entityAccountType,
            transactionType: this.state.reconciliation.transactionType,
            entityRoutingNumber: rouno,
            entityAccountNumber: accno,
            entityBankName: this.state.reconciliation.entityBankName,
            payeeName: this.state.reconciliation.payeeName,
            informationStatus: this.state.reconciliation.informationStatus,
            checkStatus: this.state.reconciliation.checkStatus,
            fullAddress: this.state.reconciliation.fullAddress,            //condition for api to be other payment status
            createdDate: todaysDate,
            modifiedDate: todaysDate,
            createdTime: todaysTime,
            modifiedTime: todaysTime,
            traModifiedBy: this.state.adminUsername,
            tracreatedBy: this.state.adminUsername,
            paymentMonthsRec2021: !isEqualPaymentMonthRec2021 ? this.state.paymentMonthsRec2021 : undefined,
            paymentMonthsRec2022: !isEqualPaymentMonthRec2022 ? this.state.paymentMonthsRec2022 : undefined,
            isTransactionPermission: this.state.isTransactionPermission,
            documentStatus: this.state.reconciliation.documentStatus
            // modifiedBy: this.state.applicantDetails.adminUsername,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };
    } else {
      myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.individualID,
            fundingSource: this.state.fundingSource,
            amount: this.state.reconciliation.amount,
            amountCoveredAfterRec:
              this.state.reconciliation.amountCoveredAfterRec,
            amountCoveredBeforeRec:
              this.state.reconciliation.amountCoveredBeforeRec,
            paymentMethod: this.state.reconciliation.amountType,
            amountType: this.state.reconciliation.amountType,
            entityType: this.state.reconciliation.entityType,
            initiallyPaidBy: this.state.reconciliation.initiallyPaidBy,
            notes: this.state.reconciliation.notes,
            transactionNo: this.state.reconciliation.transactionNo,
            isTransection: 0,
            requestedBankInfo: this.state.reconciliation.requestedBankInfo != null && this.state.reconciliation.requestedBankInfo != "" ? this.state.reconciliation.requestedBankInfo : 0,
            dateBankInfoRequested: todaysDate,
            timeBankInfoRequested: todaysTime,
            countBankInfoRequested:
              this.state.reconciliation.countBankInfoRequested == null
                ? 1
                : this.state.reconciliation.countBankInfoRequested + 1,

            mode: this.state.reconciliationMode,
            memoPropertyAddress: this.state.reconciliation.memoPropertyAddress,
            otherPaymentStatus: this.state.reconciliation.otherPaymentStatus,
            id: this.state.reconciliationEditId,
            modifiedBy:  this.state.adminUsername,
            dateCreated: this.state.reconciliation.dateCreated,
            timeCreated: this.state.reconciliation.timeCreated,
            // condition for other payment status 

            dateStatusIssued: dateStatusIssued,
            timeStatusIssued: timeStatusIssued,

            program: "santana",
            createdBy: this.state.adminUsername,
            paymentMonthsRec2021: !isEqualPaymentMonthRec2021 ? this.state.paymentMonthsRec2021 : undefined,
            paymentMonthsRec2022: !isEqualPaymentMonthRec2022 ? this.state.paymentMonthsRec2022 : undefined,
            documentStatus: this.state.reconciliation.documentStatus
            // modifiedBy: this.state.applicantDetails.adminUsername,
            // entityAccountType:this.state.reconciliation.entityAccountType,
            // transactionType: this.state.reconciliation.transactionType,
            // entityRoutingNumber: this.state.reconciliation.entityRoutingNumber,
            // entityAccountNumber: this.state.reconciliation.entityAccountNumber,
            // payeeName: this.state.reconciliation.payeeName,
            // informationStatus: this.state.reconciliation.informationStatus,
            // checkStatus: this.state.reconciliation.checkStatus,
            // fullAddress: this.state.reconciliation.fullAddress,
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };
    }
    return API.post(apiName, path, myInit);

  };
  
  addpaymentReconciliation = async () => {
    // cognito
    try {
      var user = await this.getCurrentUser();
      let apiName = process.env.REACT_APP_APIGatewayName;
      let path = "/adminaddsantaanauserincognito";
      let myInit = {
        // OPTIONAL
        body: {
          info: {
            individualID: this.state.applicantDetails.individualID,
            fundingSource: this.state.applicantDetails.fundingSource,
            tenantFirstName: this.state.applicantDetails.firstName,
            tenantLastName: this.state.applicantDetails.lastName,
            tenantEmail: this.state.applicantDetails.tenantEmail,
            tenantPhone: this.state.applicantDetails.phone,
            tenantAddressLine1: this.state.applicantProperty.addressLine1,
            tenantAddressLine2: this.state.applicantProperty.addressLine2,
            tenantCity: this.state.applicantProperty.city,
            tenantState: this.state.applicantProperty.state,
            tenantZipcode: this.state.applicantProperty.zipcode,
            landlordFullName: this.state.applicantDetails.payee,
            landlordEmail: this.state.applicantDetails.landlordEmail,
            landlordPhone: this.state.applicantDetails.landlordPhoneNumber,
            landlordPhoneCognito:
              this.state.applicantDetails.landlordPhoneNumber.replace(
                /\D/g,
                ""
              ),
            tenantPhoneCognito: this.state.applicantDetails.phone.replace(
                /\D/g,
                ""
              ),
            groupName:  this.state.reconciliation.entityType == "Landlord" ? "SantaAnaLandlords" : "SantaAnaApplicants",
            createType: "Reconciliation",
            resetPassword: "0",
            rentAmount: this.state.reconciliation.amount
          },
        }, // replace this with attributes you need
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      };

      var res = await API.post(apiName, path, myInit);
      //console.log(res.message);

      if (res.message === 0 || res.message === "UsernameExistsException") {
        //

        if (res.message === "UsernameExistsException") {
          try {

            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayName;
            let path = "/santaanatriggerdocumentrequestemailtolandlordmanually";
            let myInit = {
              // OPTIONAL
              body: {
                info: {
                  isReconciliation: 1,
                  //   reconciliationId: this.state.reconciliationEditId,
                  paymentId: this.state.landlordDetails.paymentId,
                  individualID: this.state.applicantDetails.individualID,
                  uniqueID: this.state.applicantDetails.uniqueID,
                  payee: this.state.applicantDetails.payee,
                  firstName: this.state.applicantDetails.firstName,
                  lastName: this.state.applicantDetails.lastName,
                  addressLine1: this.state.applicantProperty.addressLine1,
                  addressLine2: this.state.applicantProperty.addressLine2,
                  landlordEmail: this.state.applicantDetails.landlordEmail,
                  tenantEmail: this.state.applicantDetails.tenantEmail,
                  entityType: this.state.reconciliation.entityType,
                  rentAmount: this.state.reconciliation.amount,
                  fundingSource: this.state.landlordDetails.fundingSource,
                  countBankInfoRequested:
                    this.state.reconciliation.countBankInfoRequested,
                  reminder: 0,
                  totalRecords: this.state.applicantDetails.totalRecords,
                },
              }, // replace this with attributes you need
              headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
              },
            };
      
            var res = await API.post(apiName, path, myInit);
      
          } catch (err) {
            console.log(err);
            // this.setState({ landlordInfoRequestedStatus_Original: originalStatus });
          }
        }
        //change position
        let dateStatusIssued
        let timeStatusIssued
        if (this.state.otherPaymentStatus_Original != this.state.reconciliation.otherPaymentStatus && this.state.reconciliation.otherPaymentStatus == "Issued") {
    
          var issueDateTime = new Date(
            new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
          );
          var todaysDate = [
            issueDateTime.getFullYear(),
            ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
            ("0" + issueDateTime.getDate()).slice(-2),
          ].join("-");
    
          var todaysTime = [
            ("0" + issueDateTime.getHours()).slice(-2),
            ("0" + issueDateTime.getMinutes()).slice(-2),
            ("0" + issueDateTime.getSeconds()).slice(-2),
          ].join(":");
    
    
          dateStatusIssued = todaysDate;
          timeStatusIssued = todaysTime;
    
        }
        // else {
        //     console.log("this.state.reconciliation.dateStatusIssued",this.state.reconciliation.dateStatusIssued);
        //      dateStatusIssued =  this.state.reconciliation.dateStatusIssued == "" ;
        //      timeStatusIssued  =  this.state.reconciliation.timeStatusIssued  ;
        // }
    
        var d = new Date(
          new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
        );
        var todaysDate = [
          d.getFullYear(),
          ("0" + (d.getMonth() + 1)).slice(-2),
          ("0" + d.getDate()).slice(-2),
        ].join("-");
    
        var todaysTime = [
          ("0" + d.getHours()).slice(-2),
          ("0" + d.getMinutes()).slice(-2),
          ("0" + d.getSeconds()).slice(-2),
        ].join(":");
        // month
        // month changes
        let isEqualPaymentMonthRec2021 = true;
        let isEqualPaymentMonthRec2022 = true;
        if (this.state.paymentMonthsRec2021 && this.state.paymentMonthsRec2021.length > 0) {
          let clone = this.state.paymentMonthsRec2021Clone;
          isEqualPaymentMonthRec2021 = this.state.paymentMonthsRec2021.length == this.state.paymentMonthsRec2021Clone.length && this.state.paymentMonthsRec2021.every(function (element, index) {
            return element === clone[index];
          });
        }
    
        if (this.state.paymentMonthsRec2021 && this.state.paymentMonthsRec2021.length == 0 && this.state.paymentMonthsRec2021Clone.length > 0) {
          isEqualPaymentMonthRec2021 = false;
        }
    
        if (this.state.paymentMonthsRec2022 && this.state.paymentMonthsRec2022.length > 0) {
          let clone = this.state.paymentMonthsRec2022Clone;
          isEqualPaymentMonthRec2022 = this.state.paymentMonthsRec2022.length == this.state.paymentMonthsRec2022Clone.length && this.state.paymentMonthsRec2022.every(function (element, index) {
            return element === clone[index];
          });
        }
    
        if (this.state.paymentMonthsRec2022 && this.state.paymentMonthsRec2022.length == 0 && this.state.paymentMonthsRec2022Clone.length > 0) {
          isEqualPaymentMonthRec2022 = false;
        }
        // end
        let rouno = this.state.reconciliation.entityRoutingNumber;
        let accno = this.state.reconciliation.entityAccountNumber;
        if (this.state.isTransactionPermission) {
          accno = accno !== null && accno !== undefined && accno !== "" ? await this.encryptData(accno) : '';
          rouno = rouno !== null && rouno !== undefined && rouno !== "" ? await this.encryptData(rouno) : '';
        }
        else {
          accno = '';
          rouno = '';
        }
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = "/addreconciliation";
        let myInit = {
          // OPTIONAL
          body: {
            info: {
              // id: this.state.reconciliation.id,
              individualID: this.state.individualID,
              fundingSource: this.state.fundingSource,
              amount: this.state.reconciliation.amount,
              amountCoveredAfterRec:
                this.state.reconciliation.amountCoveredAfterRec,
              amountCoveredBeforeRec:
                this.state.reconciliation.amountCoveredBeforeRec,
              paymentMethod: this.state.reconciliation.amountType,
              amountType: this.state.reconciliation.amountType,
              entityType: this.state.reconciliation.entityType,
              initiallyPaidBy: this.state.reconciliation.initiallyPaidBy,
              notes: this.state.reconciliation.notes,
              transactionNo: this.state.reconciliation.transactionNo,
              entityAccountType: this.state.reconciliation.entityAccountType,
              transactionType: this.state.reconciliation.transactionType,
              entityRoutingNumber: rouno,
              entityAccountNumber: accno,
              entityBankName: this.state.reconciliation.entityBankName,
              payeeName: this.state.reconciliation.payeeName,
              informationStatus: this.state.reconciliation.informationStatus,
              checkStatus: this.state.reconciliation.checkStatus,
              fullAddress: this.state.reconciliation.fullAddress,
              isTransection: 1,
              requestedBankInfo: true,
              dateBankInfoRequested: todaysDate,
              timeBankInfoRequested: todaysTime,
              countBankInfoRequested:
                this.state.reconciliation.countBankInfoRequested == null
                  ? 1
                  : this.state.reconciliation.countBankInfoRequested + 1,
    
              mode: this.state.reconciliationMode,
              memoPropertyAddress: this.state.reconciliation.memoPropertyAddress,
              otherPaymentStatus: this.state.reconciliation.otherPaymentStatus,
              id: this.state.reconciliationEditId,
              modifiedBy: this.state.reconciliation.modifiedBy,
              dateCreated: this.state.reconciliation.dateCreated,
              timeCreated: this.state.reconciliation.timeCreated,
              //condition for api to be other payment status
              dateStatusIssued: dateStatusIssued,
              timeStatusIssued: timeStatusIssued,
              createdDate: todaysDate,
              modifiedDate: todaysDate,
              createdTime: todaysTime,
              modifiedTime: todaysTime,
              traModifiedBy: this.state.adminUsername,
              tracreatedBy: this.state.adminUsername,
              program: "santana",
              createdBy: this.state.adminUsername,
              paymentMonthsRec2021: !isEqualPaymentMonthRec2021 ? this.state.paymentMonthsRec2021 : undefined,
              paymentMonthsRec2022: !isEqualPaymentMonthRec2022 ? this.state.paymentMonthsRec2022 : undefined,
              // modifiedBy: this.state.applicantDetails.adminUsername,
              isTransactionPermission: this.state.isTransactionPermission,
              documentStatus: this.state.reconciliation.documentStatus
            },
          }, // replace this with attributes you need
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          },
        };
        // await API.post(apiName, path, myInit);
    
        return await API.post(apiName, path, myInit);
        //change position end
      }
    }
    catch (err) {
      console.log(err);
    }
      // end
  };

  calculateTotal1099() {


    // total 1099 logic
    let sum = 0;
    let substact = 0;
    // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
    let rec = this.state.reconciliationList.filter(
      (x) => x.entityType == "Landlord" && x.initiallyPaidBy == "OCUW" && moment(x.dateCreated).format('YYYY') == 2021
    );
    if (rec.length > 0) {
      let arr = [];
      let returnArr = [];
      let paidRec = rec.filter(
        (x) => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)"
      );
      //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
      if (paidRec && paidRec.length > 0) {
        for (let i = 0; i < paidRec.length; i++) {
          arr.push(Number(paidRec[i].amount));
        }
        for (var i = 0; i < arr.length; i++) {
          sum += parseFloat(arr[i]) || 0;
        }
      }
      // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

      let returnRec = rec.filter(
        (x) =>
          x.amountType == "Return" || x.amountType == "Adjustment(Negative)"
      );

      if (returnRec && returnRec.length > 0) {
        for (let i = 0; i < returnRec.length; i++) {
          returnArr.push(Number(returnRec[i].amount));
        }
        for (var i = 0; i < returnArr.length; i++) {
          substact += parseFloat(returnArr[i]) || 0;
        }
      }
    }
    let total1099 = 0;
    // if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
    //     total1099 = Number(this.state.rentAmount) + sum - substact;
    //     total1099 = total1099.toFixed(2);
    // }
    // else {
    //     total1099 = Number(this.state.rentAmount) + sum - substact;
    //     total1099 = total1099.toFixed(2);
    // }
    // need to change
    if (this.state.landlordDetails.entityPaymentStatus == "N/A") {
      total1099 = sum - substact;
    } else {
      total1099 =
        ((this.state.landlordDetails.entityDatePaymentStatusUpdated != "" && this.state.landlordDetails.entityDatePaymentStatusUpdated != null && this.state.landlordDetails.entityDatePaymentStatusUpdated != "0000-00-00" && moment(this.state.landlordDetails.entityDatePaymentStatusUpdated).format('YYYY') < moment().format('YYYY') ?
          Number(this.state.landlordDetails.entityAmount) : 0) + sum - substact);
    }
    // total1099 = Number(this.state.rentAmount) + sum - substact;
    total1099 = total1099.toFixed(2);

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        total1099Amount: total1099,
      },
    }));
    // }
  }
 
  calculateTotalIssued() {

    let sum = 0;
    let substact = 0;
    let totalIssued = 0;
    let rec = this.state.reconciliationList.filter(
      (x) =>  x.initiallyPaidBy == "OCUW" && x.otherPaymentStatus == "Issued"
    );

    if (rec.length > 0) {
      let arr = [];
      let returnArr = [];
      let paidRec = rec.filter(
        (x) => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)"
      );
      if (paidRec && paidRec.length > 0) {
        for (let i = 0; i < paidRec.length; i++) {
          arr.push(Number(paidRec[i].amount));
        }
        for (var i = 0; i < arr.length; i++) {
          sum += parseFloat(arr[i]) || 0;
        }
      }

      let returnRec = rec.filter(
        (x) =>
          x.amountType == "Return" || x.amountType == "Adjustment(Negative)"
      );

      if (returnRec && returnRec.length > 0) {
        for (let i = 0; i < returnRec.length; i++) {
          returnArr.push(Number(returnRec[i].amount));
        }
        for (var i = 0; i < returnArr.length; i++) {
          substact += parseFloat(returnArr[i]) || 0;
        }
      }
    }

    if (this.state.landlordDetails.entityPaymentStatus == "Issued" && this.state.individualDetails.entityPaymentStatus == "Issued") {
        totalIssued = Number(this.state.landlordDetails.entityAmount) +  Number(this.state.individualDetails.entityAmount) + sum - substact;
    }
    else if(this.state.landlordDetails.entityPaymentStatus == "Issued") {
      totalIssued = Number(this.state.landlordDetails.entityAmount) + sum - substact;
    }
    else if(this.state.individualDetails.entityPaymentStatus == "Issued") {
      totalIssued = Number(this.state.individualDetails.entityAmount) + sum - substact;
    }
    else {
      totalIssued = sum - substact;
    }

    totalIssued = totalIssued.toFixed(2);

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        totalIssuedAmount: totalIssued,
      },
    }));
  }

  calculateTotal10992022() {


    // total 1099 logic
    let sum = 0;
    let substact = 0;
    // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
    let rec = this.state.reconciliationList.filter(
      (x) => x.entityType == "Landlord" && x.initiallyPaidBy == "OCUW" && moment(x.dateCreated).format('YYYY') == 2022
    );
    if (rec.length > 0) {
      let arr = [];
      let returnArr = [];
      let paidRec = rec.filter(
        (x) => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)"
      );
      //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
      if (paidRec && paidRec.length > 0) {
        for (let i = 0; i < paidRec.length; i++) {
          arr.push(Number(paidRec[i].amount));
        }
        for (var i = 0; i < arr.length; i++) {
          sum += parseFloat(arr[i]) || 0;
        }
      }
      // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

      let returnRec = rec.filter(
        (x) =>
          x.amountType == "Return" || x.amountType == "Adjustment(Negative)"
      );

      if (returnRec && returnRec.length > 0) {
        for (let i = 0; i < returnRec.length; i++) {
          returnArr.push(Number(returnRec[i].amount));
        }
        for (var i = 0; i < returnArr.length; i++) {
          substact += parseFloat(returnArr[i]) || 0;
        }
      }
    }
    let total10992022 = 0;
    // if (this.state.reconciliation.amountType == "Paid" || this.state.reconciliation.amountType == "Adjustment(Positive)") {
    //     total1099 = Number(this.state.rentAmount) + sum - substact;
    //     total1099 = total1099.toFixed(2);
    // }
    // else {
    //     total1099 = Number(this.state.rentAmount) + sum - substact;
    //     total1099 = total1099.toFixed(2);
    // }
    // need to change
    if (this.state.landlordDetails.entityPaymentStatus == "N/A") {
      total10992022 = sum - substact;
    } else {
      total10992022 =
        ((this.state.landlordDetails.entityDatePaymentStatusUpdated != "" && this.state.landlordDetails.entityDatePaymentStatusUpdated != null && this.state.landlordDetails.entityDatePaymentStatusUpdated != "0000-00-00" && moment(this.state.landlordDetails.entityDatePaymentStatusUpdated).format('YYYY') == moment().format('YYYY') ?
          Number(this.state.landlordDetails.entityAmount) : 0) + sum - substact);

    }
    // total1099 = Number(this.state.rentAmount) + sum - substact;
    total10992022 = total10992022.toFixed(2);

    this.setState((prevState) => ({
      applicantDetails: {
        ...prevState.applicantDetails,
        total1099Amount2022: total10992022,
      },
    }));
    // }
  }
  calculateTotalFund() {
    // total 1099 logic
    let sum = 0;
    let substact = 0;
    // if (this.state.reconciliation.entityType == "Landlord" && this.state.reconciliation.initiallyPaidBy == "OCUW") {
    if (
      this.state.reconciliationListByIndividualId &&
      this.state.reconciliationListByIndividualId.length > 0
    ) {
      let arr = [];
      let returnArr = [];

      let paidRec = this.state.reconciliationListByIndividualId.filter(
        (x) => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)"
      );
      //  let paidRec = rec.filter(x => x.amountType == "Paid" || x.amountType == "Adjustment(Positive)");
      if (paidRec && paidRec.length > 0) {
        for (let i = 0; i < paidRec.length; i++) {
          arr.push(Number(paidRec[i].amount));
        }
        for (var i = 0; i < arr.length; i++) {
          sum += parseFloat(arr[i]) || 0;
        }
      }
      // let returnRec = rec.filter(x => x.amountType == "Return" || x.amountType == "Adjustment(Negative)");

      let returnRec = this.state.reconciliationListByIndividualId.filter(
        (x) =>
          x.amountType == "Return" || x.amountType == "Adjustment(Negative)"
      );

      if (returnRec && returnRec.length > 0) {
        for (let i = 0; i < returnRec.length; i++) {
          returnArr.push(Number(returnRec[i].amount));
        }
        for (var i = 0; i < returnArr.length; i++) {
          substact += parseFloat(returnArr[i]) || 0;
        }
      }
    }

    let totalFund = 0;
    totalFund = Number(this.state.totalCoveredClone) + sum - substact;
    totalFund = totalFund.toFixed(2);

    this.setState({ totalCovered: totalFund });
    // }
  }

  showReconciliationSuccessFailurePopup = async (e) => {
    $("#exampleModal").modal("hide");
    if (
      this.state.reconciliationMode &&
      this.state.reconciliationMode == "add"
    ) {
      this.setState({
        isLoading: false,
        snackbarMessageClass: "Mui-filledSuccess",
        openSnackbar: true,
        snackbarSeverity: "success",
        snackbarMessage: "Record Inserted Successfully",
      });
    } else {
      this.setState({
        isLoading: false,
        snackbarMessageClass: "Mui-filledSuccess",
        openSnackbar: true,
        snackbarSeverity: "success",
        snackbarMessage: "Record Updated Successfully",
      });
    }
  }

  saveReconciliation = async (e) => {
    e.preventDefault();
    let isvalid = this.handleReconciliationValidation();
    if (isvalid) {
      this.setState({ isLoading: true });
    
      const { reconciliation } = { ...this.state };
      const currentState = reconciliation;
      currentState["individualID"] = this.state.applicantDetails.individualID;
      currentState["fundingSource"] = this.state.applicantDetails.fundingSource;
      
      let res = await this.addReconciliation();
      if(res != false) {
        
        let getList = await this.getReconciliationList();
        if (getList && getList.length > 0) {
          this.updateAccountNoRoutingNoData(getList).then((isGetResult) => {
            if (isGetResult) {
              this.setState({ isLoading: false });
              // this.setState({ reconciliationList: getList, reconciliationListClone: JSON.parse(JSON.stringify(getList)) });
              let dispList = getList.filter(
                (x) => x.fundingSource == this.state.fundingSource
              );
              this.setState({
                reconciliationList: dispList,
                reconciliationListByIndividualId: getList,
                reconciliationListClone: JSON.parse(JSON.stringify(dispList)),
              });
              if(this.state.reconciliationList && this.state.reconciliationList.length > 0) {
                let isLandlordResendShow = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.requestedBankInfo == 1);
                if(isLandlordResendShow.length > 0) {
                  this.setState({ isLandlordResendShow: true });
                }
                let isApplicantResendShow = this.state.reconciliationList.filter(x => x.entityType == "Individual" && x.requestedBankInfo == 1);
                if(isApplicantResendShow.length > 0) {
                  this.setState({ isApplicantResendShow: true });
                }
              }
              this.showReconciliationSuccessFailurePopup();
              if (
                this.state.reconciliationMode &&
                this.state.reconciliationMode == "add"
              ) {
                this.calculateTotal10992022();
              } else {
                this.calculateTotal1099();
              }
              this.calculateTotalFund();
             // this.calculateTotalIssued();
            }
          });
        } else {
          this.setState({ isLoading: false });
          this.showReconciliationSuccessFailurePopup();
        }
        
      }
    }
  };
  savePaymentTransectionReconciliation = async (e) => {
    e.preventDefault();
    try {
      var user = await Auth.currentAuthenticatedUser();
      // return user;
    }
    catch (e) {
      this.setState({ isLoading: false });
      if (e === "not authenticated") {
        await this.signOut();
        return;
      }
    }
    if (this.state.reconciliation.entityType == "Landlord") {
      var confirm = window.confirm(
        `This action will trigger an email to landlord on ${this.state.applicantDetails.landlordEmail}. Please click Ok to confirm.`
      );
    }
    else {
      var confirm = window.confirm(
        `This action will trigger an email to individual on ${this.state.applicantDetails.tenantEmail}. Please click Ok to confirm.`
      );
    }

    if (confirm === false) {
      this.setState({ isLoading: false });
      return;
    }
    let isvalid = this.handleReconciliationValidation();

    if (isvalid) {
      this.setState({ isLoading: true });
      $("#exampleModal").modal("hide");
      const { reconciliation } = { ...this.state };
      const currentState = reconciliation;
      currentState["individualID"] = this.state.applicantDetails.individualID;
      currentState["fundingSource"] = this.state.applicantDetails.fundingSource;

      var d = new Date(
        new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
      );
      var todaysDate = [
        d.getFullYear(),
        ("0" + (d.getMonth() + 1)).slice(-2),
        ("0" + d.getDate()).slice(-2),
      ].join("-");

      var todaysTime = [
        ("0" + d.getHours()).slice(-2),
        ("0" + d.getMinutes()).slice(-2),
        ("0" + d.getSeconds()).slice(-2),
      ].join(":");

      let requestedstatus = "Info Requested";
      let readyStatus = "Ready";
      //   payeeName
      let payeeName = this.state.reconciliation.payeeName;
      let firstName = this.state.applicantDetails.firstName;
      let lastName = this.state.applicantDetails.lastName;
      if (this.state.reconciliation.entityType == "Landlord") {
        this.setState((prevState) => ({
          reconciliation: {
            ...prevState.reconciliation,
            informationStatus: requestedstatus,
            documentStatus: requestedstatus
          },
        }));
      }
      else {
        this.setState((prevState) => ({
          reconciliation: {
            ...prevState.reconciliation,
            informationStatus: readyStatus,
            payeeName: (payeeName == '' || payeeName == undefined) ? firstName + " " + lastName : '',
            documentStatus: requestedstatus
          },
        }));
      }

      //   this.setState((prevState) => ({
      //     reconciliation: {
      //         ...prevState.reconciliation,
      //         requestedBankInfo: false,
      //         countBankInfoRequested: 0,
      //         dateBankInfoRequested: null,
      //         timeBankInfoRequested: null,
      //     },
      // }));

      let res = await this.addpaymentReconciliation();
      if (
        this.state.reconciliationMode &&
        this.state.reconciliationMode == "add"
      ) {
        this.setState({
          isLoading: false,
          snackbarMessageClass: "Mui-filledSuccess",
          openSnackbar: true,
          snackbarSeverity: "success",
          snackbarMessage: "Record Inserted Successfully",
        });
      } else {
        this.setState({
          isLoading: false,
          snackbarMessageClass: "Mui-filledSuccess",
          openSnackbar: true,
          snackbarSeverity: "success",
          snackbarMessage: "Record Updated Successfully",
        });
      }
      let getList = await this.getReconciliationList();
      if (getList && getList.length > 0) {
        this.updateAccountNoRoutingNoData(getList).then((isGetResult) => {
          if (isGetResult) {
            this.setState({ isLoading: false });
            // this.setState({ reconciliationList: getList, reconciliationListClone: JSON.parse(JSON.stringify(getList)) });
            let dispList = getList.filter(
              (x) => x.fundingSource == this.state.fundingSource
            );
            this.setState({
              reconciliationList: dispList,
              reconciliationListByIndividualId: getList,
              reconciliationListClone: JSON.parse(JSON.stringify(dispList)),
            });
            if(this.state.reconciliationList && this.state.reconciliationList.length > 0) {
              let isLandlordResendShow = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.requestedBankInfo == 1);
              if(isLandlordResendShow.length > 0) {
                this.setState({ isLandlordResendShow: true });
              }
              let isApplicantResendShow = this.state.reconciliationList.filter(x => x.entityType == "Individual" && x.requestedBankInfo == 1);
              if(isApplicantResendShow.length > 0) {
                this.setState({ isApplicantResendShow: true });
              }
            }
            if (
              this.state.reconciliationMode &&
              this.state.reconciliationMode == "add"
            ) {
              this.calculateTotal10992022();
              this.calculateTotal1099();
            } else {
              this.calculateTotal10992022();
              this.calculateTotal1099();
            }
            this.calculateTotalFund();
          }
        });
      }
    }
  };

  handleReconciliationValidation() {
    let errors = {};
    let formIsValid = true;

    if (
      !this.state.reconciliation.amount ||
      this.state.reconciliation.amount == ""
    ) {
      formIsValid = false;
      errors["amount"] = "Amount (in $) is required";
    }
    if (
      !this.state.reconciliation.entityType ||
      this.state.reconciliation.entityType == ""
    ) {
      formIsValid = false;
      errors["entityType"] = "Initial Receiver is required";
    }
    if (
      !this.state.reconciliation.initiallyPaidBy ||
      this.state.reconciliation.initiallyPaidBy == ""
    ) {
      formIsValid = false;
      errors["initiallyPaidBy"] = "Initially Paid By is required";
    }
    if (
      !this.state.reconciliation.amountType ||
      this.state.reconciliation.amountType == ""
    ) {
      formIsValid = false;
      errors["amountType"] = "Amount is required";
    }
    // Payment section validation - start
    if (
      this.state.reconciliation.entityAccountNumber !== "" &&
      this.state.reconciliation.entityAccountNumber !== null &&
      this.state.reconciliation.entityAccountNumber !== undefined &&
      this.state.isTransactionPermission
    ) {
      if ( /^\d+$/.test(this.state.reconciliation.entityAccountNumber) !== true ) {
        this.setState({ reconciliationEntityAccountNumber_Error: "Invalid bank account number" });
        this.reconciliationEntityAccountNumberRef.current.focus();
        formIsValid = false;
      } else {
        this.setState({ reconciliationEntityAccountNumber_Error: "" });
      }
    } else {
      this.setState({ reconciliationEntityAccountNumber_Error: "" });
    }

    if (
      this.state.reconciliation.entityRoutingNumber !== "" &&
      this.state.reconciliation.entityRoutingNumber !== null &&
      this.state.reconciliation.entityRoutingNumber !== undefined &&
      this.state.isTransactionPermission
    ) {
      if ( /^[a-zA-Z0-9]*$/.test(this.state.reconciliation.entityRoutingNumber) !== true || this.state.reconciliation.entityRoutingNumber.length !== 9 ) {
        this.setState({ reconciliationEntityRoutingNumber_Error: "Invalid bank routing number" });
        this.reconciliationEntityRoutingNumberRef.current.focus();
        formIsValid = false;
      } else {
        this.setState({ reconciliationEntityRoutingNumber_Error: "" });
      }
    } else {
      this.setState({ reconciliationEntityRoutingNumber_Error: "" });
    }

    if (this.state.reconciliation.transactionType === 'ACH' &&
      this.state.reconciliation.informationStatus === 'Ready' &&
      (this.state.applicantDetails.receiverCompany === "" ||
        this.state.applicantDetails.receiverCompany === null ||
        this.state.applicantDetails.receiverCompany === undefined) && 
        (this.state.isRecAccountNoOld || this.state.isRecRoutingNoOld) &&
        this.state.isRecInformationStatusOld !== 'Ready'
    ) {
      setTimeout(() => {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          //snackbarSeverity: "error",
          snackbarMessage:
            "The ACH details are not rightly verified or are pending. Please ask Landlord to add correct ACH details.",
        });
      }, 10);

      //this.setState({ landlordSSN_Error: "Invalid SSN Number" });
      this.recInformationStatusRef.current.focus();
      this.setState({ reclandlordpaymentInformationStatus_Error: "Right banking information expected" });
      formIsValid = false;
    } else {
      this.setState({ reclandlordpaymentInformationStatus_Error: "" });
    }
    
    // Payment section validation - end

    this.setState({ validationErrors: errors });
    return formIsValid;
  }

  closeMsg = () => {
    $("#deleteModal").modal("hide");
  };

  deleteRec = async (id) => {
    let entity = this.state.reconciliationList.find(
      (x) => x.id == id
    ).entityType;
    let rec = this.state.reconciliationList.filter(
      (x) => x.entityType == entity
    );
    if (rec && rec.length > 0) {
      if (rec.pop().id == id) {
        $("#deleteModal").modal("show");
        this.setState({
          deleteReconciliationId: id,
        });
      } else {
        this.setState({
          isLoading: false,
          openSnackbar: true,
          snackbarMessageClass: "Mui-filledError",
          snackbarSeverity: "error",
          snackbarMessage:
            "You can not delete this record as there is another latest reconciliation record for same receiver.",
        });
      }
    }
  };

  performDelete = async () => {
    this.setState({ isLoading: true });
    $("#deleteModal").modal("hide");
    let res = await this.deleteReconciliationList();
    this.setState({
      deleteReconciliationId: 0,
      snackbarMessageClass: "Mui-filledSuccess",
      openSnackbar: true,
      snackbarSeverity: "success",
      snackbarMessage: "Record Deleted Successfully",
    });
    var recList = await this.getReconciliationList();
    if (recList && recList.length > 0) {
      this.updateAccountNoRoutingNoData(recList).then((isGetResult) => {
        if (isGetResult) {
          let dispList = recList.filter(
            (x) => x.fundingSource == this.state.applicantDetails.fundingSource
          );
          this.setState({
            reconciliationList: dispList,
            reconciliationListByIndividualId: recList,
            reconciliationListClone: JSON.parse(JSON.stringify(dispList)),
          });
          if(this.state.reconciliationList && this.state.reconciliationList.length > 0) {
            let isLandlordResendShow = this.state.reconciliationList.filter(x => x.entityType == "Landlord" && x.requestedBankInfo == 1);
            if(isLandlordResendShow.length > 0) {
              this.setState({ isLandlordResendShow: true });
            }
            let isApplicantResendShow = this.state.reconciliationList.filter(x => x.entityType == "Individual" && x.requestedBankInfo == 1);
            if(isApplicantResendShow.length > 0) {
              this.setState({ isApplicantResendShow: true });
            }
          }
          this.calculateTotal1099();
          this.calculateTotal10992022();
          this.calculateTotalFund();
          // this.calculateTotalIssued();
        }
        this.setState({ isLoading: false });
      });
    }
    else {
        this.setState({ isLoading: false });
    }
    //        this.setState({ reconciliationList: recList, reconciliationListClone: JSON.parse(JSON.stringify(recList)) });
  };

  deleteReconciliationList = async () => {
    var user = await this.getCurrentUser();
    let apiName = process.env.REACT_APP_APIGatewayName;
    let path = "/deletereconciliation";
    let myInit = {
      // OPTIONAL
      body: {
        info: {
          id: this.state.deleteReconciliationId,
          program: "santana",
          modifiedBy: this.state.adminUsername,
        },
      }, // replace this with attributes you need
      headers: {
        Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
      },
    };
    return await API.post(apiName, path, myInit);
  };

  misceDocumentSubmit = async () => {
    this.setState({ isLoading: true });
    $("#addDocumentsModal").modal("hide");
   // await this.getCurrentUser();
   try {
    var user = await Auth.currentAuthenticatedUser();
   // return user;
  }
  catch(e){
    this.setState({ isLoading: false });
    if(e === "not authenticated") {
      await this.signOut();
      return;
    }
  }

    Storage.configure({
        bucket: process.env.REACT_APP_S3FrontendBucket,
        region: process.env.REACT_APP_Region,
        level: 'public',
    });

    try {
     // var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
            var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/miscellaneous/${this.importFile.files[0].name}`,
                this.importFile.files[0],
                { contentType: this.importFile.files[0].type });
        

        this.importFile.value = '';
        this.setState({
          isLoading: false,
          snackbarMessageClass: "Mui-filledSuccess",
          openSnackbar: true,
          snackbarSeverity: "success",
          snackbarMessage: "File has been uploaded successfully",
        });
       await this.getMiscDocument();
      //  alert('File has been uploaded successfully. It will be processed in background.');
    }
    catch (err) {
        this.importFile.value = '';
        console.log(err)
        alert('File could not be uploaded for some reason. Please upload file again.');
        //return;
    }

    this.setState({ isLoading: false });
}

resetRec = async () => {
    document.getElementById("recform").reset();

  this.setState({
      reconciliation: {
        entityType: "",
        initiallyPaidBy: "",
        amountType: "",
        amount: "",
        memoPropertyAddress: "",
        paymentMethod: "",
        otherPaymentStatus: "",
        amountCoveredAfterRec: "",
        amountCoveredBeforeRec: "",
        transactionNo: "",
        notes: "",
        modifiedBy: "",
        dateCreated: "",
        timeStatusIssued: "",
        dateStatusIssued: "",
        timeCreated: "",
        transactionType: "",
        entityRoutingNumber: "",
        entityAccountNumber: "",
        payeeName: "",
        informationStatus: "",
        checkStatus: "",
        fullAddress: "",
        individualID: "",
        fundingSource: "",
        entityBankName: "",
        entityAccountType: "",
        paymentId: "",
      },
      reconciliationEntityRoutingNumber_Error: "",
      reconciliationEntityAccountNumber_Error: "", 
    });
}

recloadUploadedDocuments = async () => {
  this.setState({ isLoading: true });

  if(this.state.reconciliation.entityType == "Individual") {
    document.getElementById('rec_photo_identification_id').innerHTML = `<td>Photo Identification <div id="rec_photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_photo_identification_upload_id" /><div id="rec_photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
    document.getElementById('rec_tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <div id="rec_tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_tenant_payment_agreement_upload_id" /><div id="rec_tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
    
    this.reclockDocuments();

    var fileUploadTag = document.getElementById("rec_photo_identification_upload_id");
    if (fileUploadTag !== null && fileUploadTag !== undefined) {
        if (fileUploadTag.addEventListener) {
            fileUploadTag.addEventListener("change", (e) => { this.recfileUploadOnChange(e, 4) });
        }
        else if (fileUploadTag.attachEvent) {
            fileUploadTag.attachEvent("change", (e) => { this.recfileUploadOnChange(e, 4) });
        }
    }

    fileUploadTag = document.getElementById("rec_tenant_payment_agreement_upload_id");
    if (fileUploadTag !== null && fileUploadTag !== undefined) {
        if (fileUploadTag.addEventListener) {
            fileUploadTag.addEventListener("change", (e) => { this.recfileUploadOnChange(e, 5) });
        }
        else if (fileUploadTag.attachEvent) {
            fileUploadTag.attachEvent("change", (e) => { this.recfileUploadOnChange(e, 5) });
        }
    }

    this.setState({
      rec_photo_identification_uploaded: false,
      rec_tenant_payment_agreement_uploaded: false,
    });
  }
  else {
  var w9folderName = "";
  var identityfolderName = "";
  var participationfolderName = "";

  if (this.state.fundingSource == "ERA2021") {
      w9folderName = "rec_landlord_w9/";
      participationfolderName = "rec_ach_authorization_form/";
      identityfolderName = "rec_landlord_driver_license/";
  }
  else if (this.state.fundingSource == "ERA2021ReCert") {
      w9folderName = "rec_era2021ReCert_landlord_updated_w9/";
      participationfolderName = "rec_era2021ReCert_updated_program_participation_Form/";
      identityfolderName = "rec_era2021ReCert_landlord_identity/";
  }
  else if (this.state.fundingSource == "ERA2") {
      w9folderName = "rec_era2_landlord_updated_w9/";
      participationfolderName = "rec_era2_updated_program_participation_Form/";
      identityfolderName = "rec_era2_landlord_identity/";
  }
  else {
      w9folderName = "rec_era3_landlord_updated_w9";
      participationfolderName = "rec_era3_updated_program_participation_Form";
      identityfolderName = "rec_era3_landlord_identity";
      // w9folderName = "era2_landlord_updated_w9/";
      // participationfolderName = "era2_updated_program_participation_Form/";
      // identityfolderName = "era2_landlord_identity/";
  }
  document.getElementById('rec_landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_driver_license_upload_id" /><div id="rec_landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
  document.getElementById('rec_landlord_w9_id').innerHTML = `<td>Landlord W9 </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_w9_upload_id" /><div id="rec_landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
  document.getElementById('rec_landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_participationagreement_upload_id" /><div id="rec_landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
  
  // Lock documents if individual case is set to other than open
  this.reclockDocuments();

//  if (this.state.individualCase !== 'Open') {
      var fileUploadTag = document.getElementById("rec_landlord_driver_license_upload_id");
      if (fileUploadTag !== null && fileUploadTag !== undefined) {
          if (fileUploadTag.addEventListener) {
              fileUploadTag.addEventListener("change", (e) => { this.recfileUploadOnChange(e, 1) });
          }
          else if (fileUploadTag.attachEvent) {
              fileUploadTag.attachEvent("change", (e) => { this.recfileUploadOnChange(e, 1) });
          }
      }

      fileUploadTag = document.getElementById("rec_landlord_w9_upload_id");
      if (fileUploadTag !== null && fileUploadTag !== undefined) {
          if (fileUploadTag.addEventListener) {
              fileUploadTag.addEventListener("change", (e) => { this.recfileUploadOnChange(e, 2) });
          }
          else if (fileUploadTag.attachEvent) {
              fileUploadTag.attachEvent("change", (e) => { this.recfileUploadOnChange(e, 2) });
          }
      }

      fileUploadTag = document.getElementById("rec_landlord_participationagreement_upload_id");
      if (fileUploadTag !== null && fileUploadTag !== undefined) {
          if (fileUploadTag.addEventListener) {
              fileUploadTag.addEventListener("change", (e) => { this.recfileUploadOnChange(e, 3) });
          }
          else if (fileUploadTag.attachEvent) {
              fileUploadTag.attachEvent("change", (e) => { this.recfileUploadOnChange(e, 3) });
          }
      }
//  }

  // Reset all the flags
  this.setState({
      rec_landlord_driver_license_uploaded: false,
      rec_landlord_w9_uploaded: false,
      rec_landlord_participationagreement_uploaded: false,
  });
  }
  //get file names for s3 keys
  try {
      // var key = `ERA-IRVINE/${this.state.email}/`;
      var key = `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/`;
      var files = await Storage.list(key);
  }
  catch (err) {
      console.log("Error Files - " + err);
      this.setState({ isLoading: false });
      return 1;
  }
  if(this.state.reconciliation.entityType == "Individual") {
    for (var i = 0; i < files.length; i++) {
      if (files[i].key.includes("rec_photo_identification/")) {
          try {
              var file1 = await Storage.get(files[i].key);
              this.setState({ rec_photo_identification: file1 });

              var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
              var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
              if (shortFileName.length > 35) {
                  shortFileName = shortFileName.substring(0, 35) + '...';
              }
              shortFileName = shortFileName + '.' + fileExtension;

              var lastModified = `${files[i].lastModified}`;
              if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                  lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
              }

              document.getElementById('rec_photo_identification_id').innerHTML = `<td>Photo Identification <div id="rec_photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rec_photo_identification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="recdeleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
              var aTag1 = document.getElementById('recdeleteS3File4');
              if (aTag1 !== null && aTag1 !== undefined) {
                  //if (this.state.individualCase !== 'Open') {
                    if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
                      aTag1.style.visibility = "hidden";
                  }
                  else {
                      if (aTag1.addEventListener) {
                          aTag1.addEventListener("click", () => { this.recdeleteS3File(4) });
                      }
                      else if (aTag1.attachEvent) {
                          aTag1.attachEvent("click", () => { this.recdeleteS3File(4) });
                      }
                  }
              }
              
              this.setState({ rec_photo_identification_uploaded: true });
          }
          catch (err) {
              console.log("Error photo_identification - " + err);
              this.setState({ isLoading: false });
              document.getElementById('rec_photo_identification_id').innerHTML = `<td>Photo Identification <div id="rec_photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_photo_identification_upload_id" /><div id="rec_photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
              return 4;
          }
      }
      
      else if (files[i].key.includes("rec_tenant_payment_agreement/")) {
          try {
              var file2 = await Storage.get(files[i].key);
              this.setState({ rec_tenant_payment_agreement: file2 });

              var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
              var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
              if (shortFileName.length > 35) {
                  shortFileName = shortFileName.substring(0, 35) + '...';
              }
              shortFileName = shortFileName + '.' + fileExtension;

              var lastModified = `${files[i].lastModified}`;
              if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                  lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
              }

             
              document.getElementById('rec_tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <div id="rec_tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rec_tenant_payment_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="recdeleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
              var aTag2 = document.getElementById('recdeleteS3File5');
              if (aTag2 !== null && aTag2 !== undefined) {
                  //if (this.state.individualCase !== 'Open') {
                  if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
                      aTag2.style.visibility = "hidden";
                  }
                  else {
                      if (aTag2.addEventListener) {
                          aTag2.addEventListener("click", () => { this.recdeleteS3File(5) });
                      }
                      else if (aTag2.attachEvent) {
                          aTag2.attachEvent("click", () => { this.recdeleteS3File(5) });
                      }
                  }
              }
             
              this.setState({ rec_tenant_payment_agreement_uploaded: true });
          }
          catch (err) {
              console.log("Error tenant_payment_agreement - " + err);
              this.setState({ isLoading: false });
              document.getElementById('rec_tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <div id="rec_tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_tenant_payment_agreement_upload_id" /><div id="rec_tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
              return 5;
          }
      }
  }
  }
  else {
    for (var i = 0; i < files.length; i++) {
      if (files[i].key.includes(identityfolderName)) {
          try {
              var file1 = await Storage.get(files[i].key);
              this.setState({ rec_landlord_driver_license: file1 });

              var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
              var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
              if (shortFileName.length > 35) {
                  shortFileName = shortFileName.substring(0, 35) + '...';
              }
              shortFileName = shortFileName + '.' + fileExtension;

              var lastModified = `${files[i].lastModified}`;
              if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                  lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
              }

              // if(this.state.mode && this.state.mode == "User"){
              //     document.getElementById('landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID<div id="landlord_driver_license_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
              // }
              // else {
              document.getElementById('rec_landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID </td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rec_landlord_driver_license}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="recdeleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
              var aTag1 = document.getElementById('recdeleteS3File1');
              if (aTag1 !== null && aTag1 !== undefined) {
                  console.log("Other payment status = " + this.state.reconciliation.otherPaymentStatus);
                 // || this.state.reconciliation.otherPaymentStatus !== "Issued"
                  if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
                      aTag1.style.visibility = "hidden";
                  }
                  else {
                      if (aTag1.addEventListener) {
                          aTag1.addEventListener("click", () => { this.recdeleteS3File(1) });
                      }
                      else if (aTag1.attachEvent) {
                          aTag1.attachEvent("click", () => { this.recdeleteS3File(1) });
                      }
                  }
              }
              // }
              this.setState({ rec_landlord_driver_license_uploaded: true, rec_isDrivingLicenceUpload: true });
          }
          catch (err) {
              console.log("Error landlord_driver_license - " + err);
              this.setState({ isLoading: false });
              document.getElementById('rec_landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_driver_license_upload_id" /><div id="rec_landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
              return 1;
          }
      }
      else if (files[i].key.includes(w9folderName)) {
          try {
              var file2 = await Storage.get(files[i].key);
              this.setState({ rec_landlord_w9: file2 });

              var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
              var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
              if (shortFileName.length > 35) {
                  shortFileName = shortFileName.substring(0, 35) + '...';
              }
              shortFileName = shortFileName + '.' + fileExtension;

              var lastModified = `${files[i].lastModified}`;
              if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                  lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
              }

              // if(this.state.mode && this.state.mode == "User"){
              //     document.getElementById('landlord_w9_id').innerHTML = `<td>Landlord W9<div id="landlord_w9_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
              // }
              // else{
              document.getElementById('rec_landlord_w9_id').innerHTML = `<td>Landlord W9 </td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rec_landlord_w9}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="recdeleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
              var aTag2 = document.getElementById('recdeleteS3File2');
              if (aTag2 !== null && aTag2 !== undefined) {
                  //if (this.state.applicantDetails.individualCase !== 'Open' || this.state.reconciliation.otherPaymentStatus == "Issued") {
                  if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {  
                    aTag2.style.visibility = "hidden";
                  }
                  else {
                      if (aTag2.addEventListener) {
                          aTag2.addEventListener("click", () => { this.recdeleteS3File(2) });
                      }
                      else if (aTag2.attachEvent) {
                          aTag2.attachEvent("click", () => { this.recdeleteS3File(2) });
                      }
                  }
              }
              // }
              this.setState({ rec_landlord_w9_uploaded: true, rec_isW9Upload: true });
          }
          catch (err) {
              console.log("Error landlord_w9 - " + err);
              this.setState({ isLoading: false });
              document.getElementById('rec_landlord_w9_id').innerHTML = `<td>Landlord W9 </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_w9_upload_id"  /><div id="rec_landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
              return 2;
          }
      }
      else if (files[i].key.includes(participationfolderName)) {
          try {
              var file31 = await Storage.get(files[i].key);
              this.setState({ rec_landlord_participationagreement: file31 });

              var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
              var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
              if (shortFileName.length > 35) {
                  shortFileName = shortFileName.substring(0, 35) + '...';
              }
              shortFileName = shortFileName + '.' + fileExtension;

              var lastModified = `${files[i].lastModified}`;
              if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                  lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
              }

              // if(this.state.mode && this.state.mode == "User"){
              //     document.getElementById('landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement<a class="sample-pdf" target="_blank" href="https://bit.ocuw.org/3n2o8Cp">Download Form</a><div id="landlord_participationagreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red icon-disabled"></i></a></td>`;
              // }
              // else{
              document.getElementById('rec_landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement </td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static text-primary'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rec_landlord_participationagreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="recdeleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
              var aTag31 = document.getElementById('recdeleteS3File3');
              if (aTag31 !== null && aTag31 !== undefined) {
                //if (this.state.applicantDetails.individualCase !== 'Open' || this.state.reconciliation.otherPaymentStatus == "Issued") {
                  if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
                      aTag31.style.visibility = "hidden";
                  }
                  else {
                      if (aTag31.addEventListener) {
                          aTag31.addEventListener("click", () => { this.recdeleteS3File(3) });
                      }
                      else if (aTag31.attachEvent) {
                          aTag31.attachEvent("click", () => { this.recdeleteS3File(3) });
                      }
                  }
              }
              //}
              this.setState({ rec_landlord_participationagreement_uploaded: true, rec_isParticipateAgreementUpload: true });
          }
          catch (err) {
              console.log("Error landlord_participationagreement - " + err);
              this.setState({ isLoading: false });
              document.getElementById('rec_landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_participationagreement_upload_id" /><div id="rec_landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
              return 3;
          }
      }
  }
  }
  
  this.setState({ isLoading: false });

  return 0;
}

reclockDocuments = async () => {
  //if (this.state.applicantDetails.individualCase !== 'Open' || this.state.reconciliation.otherPaymentStatus == "Issued") {
    if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
      if(this.state.reconciliation.entityType == "Individual") { 
        this.rec_photo_identification_upload = document.getElementById("rec_photo_identification_upload_id");
        this.rec_tenant_payment_agreement_upload = document.getElementById("rec_tenant_payment_agreement_upload_id");

        if (this.rec_photo_identification_upload !== null) {
            this.rec_photo_identification_upload.disabled = true;
        }
        if (this.rec_tenant_payment_agreement_upload !== null) {
            this.rec_tenant_payment_agreement_upload.disabled = true;
        }
      }
      else {
        this.rec_landlord_driver_license_upload = document.getElementById("rec_landlord_driver_license_upload_id");
        this.rec_landlord_w9_upload = document.getElementById("rec_landlord_w9_upload_id");
        this.rec_landlord_participationagreement_upload = document.getElementById("rec_landlord_participationagreement_upload_id");

        if (this.rec_landlord_driver_license_upload !== null) {
            this.rec_landlord_driver_license_upload.disabled = true;
        }
        if (this.rec_landlord_w9_upload !== null) {
            this.rec_landlord_w9_upload.disabled = true;
        }
        if (this.rec_landlord_participationagreement_upload !== null) {
            this.rec_landlord_participationagreement_upload.disabled = true;
        }
    }
  }
}

recfileUploadOnChange = async (e, fileChanged) => {
  this.setState({ isLoading: true });

  let maxAllowedSize = 5 * 1024 * 1024;   // 5 MB

  switch (fileChanged) {
      case 1:
          {
              this.rec_landlord_driver_license_upload = document.getElementById("rec_landlord_driver_license_upload_id");

              // Validate the file size which is being uploaded
              if (this.rec_landlord_driver_license_upload !== null && this.rec_landlord_driver_license_upload.files != null && this.rec_landlord_driver_license_upload.files.length > 0) {
                  if (this.rec_landlord_driver_license_upload.files[0].size > maxAllowedSize) {
                      document.getElementById("rec_landlord_driver_license_size_validation_id").style.display = "block";
                      this.rec_landlord_driver_license_upload = null;
                  }
                  else {
                      document.getElementById("rec_landlord_driver_license_size_validation_id").style.display = "none";
                      try {
                          var res = await this.recUploadIndividualDocuments();
                          res = await this.recloadUploadedDocuments();
                      }
                      catch (err) {
                          this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                          console.log(err)
                      }
                  }
              }
          }
          break;
      case 2:
          {
              this.rec_landlord_w9_upload = document.getElementById("rec_landlord_w9_upload_id");

              // Validate the file size which is being uploaded
              if (this.rec_landlord_w9_upload !== null && this.rec_landlord_w9_upload.files != null && this.rec_landlord_w9_upload.files.length > 0) {
                  if (this.rec_landlord_w9_upload.files[0].size > maxAllowedSize) {
                      document.getElementById("rec_landlord_w9_size_validation_id").style.display = "block";
                      this.rec_landlord_w9_upload = null;
                  }
                  else {
                      document.getElementById("rec_landlord_w9_size_validation_id").style.display = "none";
                      try {
                          var res = await this.recUploadIndividualDocuments();
                          res = await this.recloadUploadedDocuments();
                      }
                      catch (err) {
                          this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                          console.log(err)
                      }
                  }
              }
          }
          break;
      case 3:
          {
              this.rec_landlord_participationagreement_upload = document.getElementById("rec_landlord_participationagreement_upload_id");

              // Validate the file size which is being uploaded
              if (this.rec_landlord_participationagreement_upload !== null && this.rec_landlord_participationagreement_upload.files != null && this.rec_landlord_participationagreement_upload.files.length > 0) {
                  if (this.rec_landlord_participationagreement_upload.files[0].size > maxAllowedSize) {
                      document.getElementById("rec_landlord_participationagreement_size_validation_id").style.display = "block";
                      this.rec_landlord_participationagreement_upload = null;
                  }
                  else {
                      document.getElementById("rec_landlord_participationagreement_size_validation_id").style.display = "none";
                      try {
                          var res = await this.recUploadIndividualDocuments();
                          res = await this.recloadUploadedDocuments();
                      }
                      catch (err) {
                          this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                          console.log(err)
                      }
                  }
              }
          }
          break;
        case 4:
            {
                this.rec_photo_identification_upload = document.getElementById("rec_photo_identification_upload_id");

                // Validate the file size which is being uploaded
                if (this.rec_photo_identification_upload !== null && this.rec_photo_identification_upload.files != null && this.rec_photo_identification_upload.files.length > 0) {
                    if (this.rec_photo_identification_upload.files[0].size > maxAllowedSize) {
                        document.getElementById("rec_photo_identification_size_validation_id").style.display = "block";
                        this.rec_photo_identification_upload = null;
                    }
                    else {
                        document.getElementById("rec_photo_identification_size_validation_id").style.display = "none";
                        try {
                          var res = await this.recUploadIndividualDocuments();
                          res = await this.recloadUploadedDocuments();
                      }
                      catch (err) {
                          this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                          console.log(err)
                      }
                    }
                }
            }
            break;
          case 5:
              {
                  this.rec_tenant_payment_agreement_upload = document.getElementById("rec_tenant_payment_agreement_upload_id");

                  // Validate the file size which is being uploaded
                  if (this.rec_tenant_payment_agreement_upload !== null && this.rec_tenant_payment_agreement_upload.files != null && this.rec_tenant_payment_agreement_upload.files.length > 0) {
                      if (this.rec_tenant_payment_agreement_upload.files[0].size > maxAllowedSize) {
                          document.getElementById("rec_tenant_payment_agreement_size_validation_id").style.display = "block";
                          this.rec_tenant_payment_agreement_upload = null;
                      }
                      else {
                          document.getElementById("rec_tenant_payment_agreement_size_validation_id").style.display = "none";
                          try {
                            var res = await this.recUploadIndividualDocuments();
                            res = await this.recloadUploadedDocuments();
                        }
                        catch (err) {
                            this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                            console.log(err)
                        }
                      }
                  }
              }
              break;
      default:
          break;
  }

  this.setState({ isLoading: false });
}

recUploadIndividualDocuments = async () => {
  var w9folderName = "";
  var identityfolderName = "";
  var participationfolderName = "";

  if (this.state.fundingSource == "ERA2021") {
      w9folderName = "rec_landlord_w9";
      participationfolderName = "rec_ach_authorization_form";
      identityfolderName = "rec_landlord_driver_license";
  }
  else if (this.state.fundingSource == "ERA2021ReCert") {
      w9folderName = "rec_era2021ReCert_landlord_updated_w9";
      participationfolderName = "rec_era2021ReCert_updated_program_participation_Form";
      identityfolderName = "rec_era2021ReCert_landlord_identity";
  }
  else if (this.state.fundingSource == "ERA2") {
      w9folderName = "rec_era2_landlord_updated_w9";
      participationfolderName = "rec_era2_updated_program_participation_Form";
      identityfolderName = "rec_era2_landlord_identity";
  }
  else {
      w9folderName = "rec_era3_landlord_updated_w9";
      participationfolderName = "rec_era3_updated_program_participation_Form";
      identityfolderName = "rec_era3_landlord_identity";
      // w9folderName = "era2_landlord_updated_w9";
      // participationfolderName = "era2_updated_program_participation_Form";
      // identityfolderName = "era2_landlord_identity";
  }
  // if (this.state.individualID === -1 || this.state.individualID === 0 || this.state.individualID === null || this.state.individualID === 'IRV000000000000') {
  //     // early return
  //     return;
  // }
  // || this.state.reconciliation.otherPaymentStatus !== "Issued"
 // if (this.state.applicantDetails.individualCase !== 'Open' || this.state.reconciliation.otherPaymentStatus == "Issued") {
  if ((this.state.applicantDetails.individualCase !== 'Open' && this.state.applicantDetails.individualCase !== 'Additional Payment') || this.state.reconciliation.otherPaymentStatus == "Issued") {
      // early return. don't allow to upload the documents if individual case is set to other than open.
      return;
  }


  if(this.state.reconciliation.entityType == "Individual") { 

    this.rec_photo_identification_upload = document.getElementById("rec_photo_identification_upload_id");
    this.rec_tenant_payment_agreement_upload = document.getElementById("rec_tenant_payment_agreement_upload_id");

        // Upload the new documents to be uploaded `ERA-IRVINE/${this.state.email}/`
        if (this.rec_photo_identification_upload !== null && this.rec_photo_identification_upload.files != null && this.rec_photo_identification_upload.files.length > 0) {
            try {
               // var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/photo_identification/${this.photo_identification_upload.files[0].name}`,
              ///AdditionalPayment/${this.state.reconciliation.id}/
               var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/rec_photo_identification/${this.rec_photo_identification_upload.files[0].name}`,
                    this.rec_photo_identification_upload.files[0],
                    { contentType: this.rec_photo_identification_upload.files[0].type });
                this.rec_photo_identification_upload = null;

                this.setState({ rec_photo_identification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.rec_tenant_payment_agreement_upload !== null && this.rec_tenant_payment_agreement_upload.files != null && this.rec_tenant_payment_agreement_upload.files.length > 0) {
            try {
               // result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/tenant_payment_agreement/${this.tenant_payment_agreement_upload.files[0].name}`,
               var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/rec_tenant_payment_agreement/${this.rec_tenant_payment_agreement_upload.files[0].name}`,
                    this.rec_tenant_payment_agreement_upload.files[0],
                    { contentType: this.rec_tenant_payment_agreement_upload.files[0].type });
                this.rec_tenant_payment_agreement_upload = null;

                this.setState({ rec_tenant_payment_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
  }
  else {
    this.rec_landlord_driver_license_upload = document.getElementById("rec_landlord_driver_license_upload_id");
    this.rec_landlord_w9_upload = document.getElementById("rec_landlord_w9_upload_id");
    this.rec_landlord_participationagreement_upload = document.getElementById("rec_landlord_participationagreement_upload_id");
    // this.rental_agreement_upload = document.getElementById("rental_agreement_upload_id");
  
    // Upload the new documents to be uploaded `ERA-IRVINE/${this.state.email}/`
    if (this.rec_landlord_driver_license_upload !== null && this.rec_landlord_driver_license_upload.files != null && this.rec_landlord_driver_license_upload.files.length > 0) {
        try {
            // var result = await Storage.put(`ERA-IRVINE/${this.state.email}/landlord_driver_license/${this.landlord_driver_license_upload.files[0].name}`,
            //     this.landlord_driver_license_upload.files[0],
            //     { contentType: this.landlord_driver_license_upload.files[0].type });
            var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/${identityfolderName}/${this.rec_landlord_driver_license_upload.files[0].name}`,
            this.rec_landlord_driver_license_upload.files[0],
            { contentType: this.rec_landlord_driver_license_upload.files[0].type });
  
            this.rec_landlord_driver_license_upload = null;
  
            this.setState({ rec_landlord_driver_license_uploaded: true, rec_isDrivingLicenceUpload: true });
        }
        catch (err) {
            console.log(err)
            return;
        }
    }
  
    if (this.rec_landlord_w9_upload !== null && this.rec_landlord_w9_upload.files != null && this.rec_landlord_w9_upload.files.length > 0) {
        try {
            var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/${w9folderName}/${this.rec_landlord_w9_upload.files[0].name}`,
                this.rec_landlord_w9_upload.files[0],
                { contentType: this.rec_landlord_w9_upload.files[0].type });

                this.rec_landlord_w9_upload = null;
  
            this.setState({ rec_landlord_w9_uploaded: true, rec_isW9Upload: true });
        }
        catch (err) {
            console.log(err)
            return;
        }
    }
  
    if (this.rec_landlord_participationagreement_upload !== null && this.rec_landlord_participationagreement_upload.files != null && this.rec_landlord_participationagreement_upload.files.length > 0) {
        try {
            var result = await Storage.put(`SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/${participationfolderName}/${this.rec_landlord_participationagreement_upload.files[0].name}`,
                this.rec_landlord_participationagreement_upload.files[0],
                { contentType: this.rec_landlord_participationagreement_upload.files[0].type });
            this.rec_landlord_participationagreement_upload = null;
  
            this.setState({ rec_landlord_participationagreement_uploaded: true, rec_isParticipateAgreementUpload: true });
        }
        catch (err) {
            console.log(err)
            return;
        }
    }
  }
  
}

recdeleteS3File = async (fileToDelete) => {
  var result = window.confirm("Are you sure you want to delete selected document?");
  if (result === false) {
      return;
  }

  this.setState({ isLoading: true });
  var w9folderName = "";
  var identityfolderName = "";
  var participationfolderName = "";

  if (this.state.fundingSource == "ERA2021") {
      w9folderName = "rec_landlord_w9/";
      participationfolderName = "rec_ach_authorization_form/";
      identityfolderName = "rec_landlord_driver_license/";
  }
  else if (this.state.fundingSource == "ERA2021ReCert") {
      w9folderName = "rec_era2021ReCert_landlord_updated_w9/";
      participationfolderName = "rec_era2021ReCert_updated_program_participation_Form/";
      identityfolderName = "rec_era2021ReCert_landlord_identity/";
  }
  else if (this.state.fundingSource == "ERA2") {
      w9folderName = "rec_era2_landlord_updated_w9/";
      participationfolderName = "rec_era2_updated_program_participation_Form/";
      identityfolderName = "rec_era2_landlord_identity/";
  }
  else {
      w9folderName = "rec_era3_landlord_updated_w9";
      participationfolderName = "rec_era3_updated_program_participation_Form";
      identityfolderName = "rec_era3_landlord_identity";
      // w9folderName = "era2_landlord_updated_w9/";
      // participationfolderName = "era2_updated_program_participation_Form/";
      // identityfolderName = "era2_landlord_identity/";
  }
  try {

     // var key = `ERA-IRVINE/${this.state.email}/`;
     var key = `SantaAna/Case/Case_${this.state.individualID}/AdditionalPayment/${this.state.reconciliation.id}/`;
     var files = await Storage.list(key);

      // Remove the earlier files for the section for which the new files to be uploaded

      switch (fileToDelete) {
          case 1:
              {
                  for (var i = 0; i < files.length; i++) {
                      if (files[i].key.includes(identityfolderName)) {
                          var key1 = files[i].key;
                          var res = await Storage.remove(key1);
                      }
                  }
                  document.getElementById('rec_landlord_driver_license_id').innerHTML = `<td>Driver’s License or other government issued photo ID </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_driver_license_upload_id" /><div id="rec_landlord_driver_license_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                  this.setState({ rec_landlord_driver_license: "", rec_isDrivingLicenceUpload: false });
              }
              break;
          case 2:
              {
                  for (var i = 0; i < files.length; i++) {
                      if (files[i].key.includes(w9folderName)) {
                          var key1 = files[i].key;
                          var res = await Storage.remove(key1);
                      }
                  }
                  document.getElementById('rec_landlord_w9_id').innerHTML = `<td>Landlord W9 </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_w9_upload_id"  /><div id="rec_landlord_w9_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                  this.setState({ rec_landlord_w9: "", rec_isW9Upload: false });
              }
              break;
          case 3:
              {
                  for (var i = 0; i < files.length; i++) {
                      if (files[i].key.includes(participationfolderName)) {
                          var key1 = files[i].key;
                          var res = await Storage.remove(key1);
                      }
                  }
                  document.getElementById('rec_landlord_participationagreement_id').innerHTML = `<td>Landlord Participation Agreement </td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_participationagreement_upload_id" /><div id="rec_landlord_participationagreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                  this.setState({ rec_landlord_participationagreement: "", rec_isParticipateAgreementUpload: false });
              }
              break;
              case 4:
                {
                    for (var i = 0; i < files.length; i++) {
                        if (files[i].key.includes("rec_photo_identification/")) {
                            var key1 = files[i].key;
                            var res = await Storage.remove(key1);
                        }
                    }
                    document.getElementById('rec_photo_identification_id').innerHTML = `<td>Photo Identification <div id="rec_photo_identification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_photo_identification_upload_id" /><div id="rec_photo_identification_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    this.setState({ rec_photo_identification: "" });
                }
                break;
            case 5:
                {
                    for (var i = 0; i < files.length; i++) {
                        if (files[i].key.includes("rec_tenant_payment_agreement/")) {
                            var key1 = files[i].key;
                            var res = await Storage.remove(key1);
                        }
                    }
                    document.getElementById('rec_tenant_payment_agreement_id').innerHTML = `<td>Tenant Payment Agreement <div id="rec_tenant_payment_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_tenant_payment_agreement_upload_id" /><div id="rec_tenant_payment_agreement_size_validation_id" class="alert-small-text" style="display:none" >File too big, please select a file less than 5 MB</div></td>`;
                    this.setState({ rec_tenant_payment_agreement: "" });
                }
                break;
          default:
              break;
      }
  }
  catch (err) {
      console.log(err);
      this.setState({ msg: "Failed to delete file" });
      this.setState({ isLoading: false });
      return;
  }

  this.setState({ isLoading: false });

  var safeload = await this.recloadUploadedDocuments();
}

  render() {
    // const paymentSectionStyle = {
    //   borderTop: "4px solid gray",
    //   marginBottom: "0px!important",
    // };

    const headerStyle = {
      fontSize: "1.2rem",
      color: "#005191",
      textTransform: "uppercase",
      fontWeight: "700",
      borderTop: "1px solid #cdcbcb ",
      paddingTop: "16px",
    };
    const parantDiv = {
      alignitems: "center",
      display: "flex",
      justifycontent: "",
    };

    const paymentreconcilationstyle = {
      fontSize: "1.2rem",
      color: "#005191",
      textTransform: "uppercase",
      fontWeight: "700",
      float: "right",
    };
    const { classes } = this.props;
    if (
      this.state.authState === "signedIn" ||
      this.state.authState === "loading"
    ) {
      return (
        <div className="d-flex flex-column sticky-footer-wrapper">
          {/* main content starts */}
          {/* <main className="main flex-fill mt-2 mt-md-3">
                        <div className="container-fluid"> */}

          {/* content area starts */}
          <div className="box-shadow details-page">
            <div className="record-detail-main validation-text">
              <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none">
                <i class="fas fa-edit"></i> EDIT SANTA ANA APPLICATION
              </h4>
              <div className="data-section">
                <fieldset id="myset">
                  <div className="border p-3">
                    <React.Fragment>
                      <p
                        className="top-note"
                        style={{
                          display:
                            this.state.applicantDetails.totalRecords > 1
                              ? "block"
                              : "none",
                        }}
                      >
                        {" "}
                        There is another record for same Tenant under ERA-Santa
                        Ana program.
                      </p>

                      <div className="box-gray mb-4">
                        {/* <h2 className="profile-title"><i class="fas fa-file-alt mr-2"></i>Other Details</h2> */}
                        <h2 className="profile-title d-md-flex align-items-center justify-content-between">
                          <span></span>
                          <span class="property-grid-add d-flex">
                            <button
                              type="button"
                              className="btn btn-primary btn-sm my-2 my-md-0"
                              onClick={this.resendApplicantCredentials}
                              style={{
                                display:
                                  this.state.applicantDetails
                                    .requestedApplicantInfo === true ||
                                    this.state.applicantDetails
                                      .requestedApplicantInfo === 1 || 
                                      this.state.isApplicantResendShow === true
                                    ? "block"
                                    : "none",
                              }}
                            >
                              Resend Applicant Password
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-sm ml-2 my-2 my-md-0"
                              onClick={this.resendLandlordCredentials}
                              style={{
                                display:
                                  this.state.landlordDetails
                                    .requestedLandlordInfoNew === true ||
                                    this.state.landlordDetails
                                      .requestedLandlordInfoNew === 1 || 
                                    this.state.isLandlordResendShow === true
                                    ? "block"
                                    : "none",
                              }}
                            >
                              Resend Landlord Password
                            </button>
                          </span>
                        </h2>

                        <div className="box-details">
                          <div className="form-row">
                            <div className="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Case </label>
                              <select
                                className="form-control"
                                id="individualCaseId"
                                onChange={this.updateIndividualCase}
                                value={
                                  this.state.applicantDetails.individualCase
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Open" selected>
                                  Open
                                </option>
                                <option value="Close">Close</option>
                                <option value="Paid">Paid</option>
                                <option value="Ineligible">Ineligible</option>
                                <option value="Re-open">Re-open</option>
                                <option value="Ready for Finance">
                                  Ready for Finance
                                </option>
                                <option value="Additional Payment">Additional Payment</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Case Status</label>
                              <select
                                className="form-control"
                                ref={(input) =>
                                  (this.hmisApprovalSelector = input)
                                }
                                onChange={this.updateHIMSAproval}
                                value={this.state.applicantDetails.hmisApproval}
                              >
                                <option value="">-- select --</option>
                                <option value="Approved">Approved</option>
                                <option value="On Hold">On Hold</option>
                                {/* <option value="Payment Requested">Payment Requested</option> */}
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>
                                Has the household been approved for United Way?{" "}
                              </label>
                              <select
                                className="form-control"
                                onChange={
                                  this.updateHouseholdApprovedForUnitedWay
                                }
                                value={
                                  this.state.applicantDetails
                                    .householdApprovedForUnitedWay
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-1">
                              <label>Funding Source</label>
                              <input
                                className="form-control"
                                readOnly
                                value={
                                  this.state.applicantDetails.fundingSource
                                }
                              ></input>
                            </div>
                            <div className="form-group col-md-6 col-xl-1">
                              <label>Total Fund</label>
                              <input
                                className="form-control"
                                readOnly
                                value={this.state.totalCovered}
                              ></input>
                            </div>
                            <div className="form-group col-md-6 col-xl-1">
                              <label>Total Issued</label>
                              <Tooltip title="Total amount issued under current funding source for current case #">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                readOnly
                                value={this.state.applicantDetails.totalIssuedAmount}
                              ></input>
                            </div>
                            <div
                              className="form-group col-md-6 col-xl-2"
                              style={{
                                display:
                                  this.state.dateImported_Formatted !== "" &&
                                    this.state.dateImported_Formatted !== null
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <label>Date Imported</label>
                              <input
                                className="form-control"
                                readOnly
                                value={this.state.dateImported_Formatted}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p className="wh-note">
                        <span>Note:</span> After updating details, please click
                        on 'Save' button to push changes to database.
                      </p>

                      <div className="box-gray mb-4">
                        <h2 className="profile-title">
                          <i class="fas fa-user-circle mr-2"></i>APPLICANT
                          DETAILS
                        </h2>
                        <div className="box-details">
                          <div className="form-row">
                            <div class="form-group col-md-6 col-xl-1 field-hightlight">
                              <label>Individual ID </label>
                              <input
                                className="form-control"
                                readOnly
                                onChange={this.updateIndividualID}
                                value={this.state.applicantDetails.individualID}
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Unique ID </label>
                              <input
                                className="form-control"
                                ref={this.uniqueIDRef}
                                onChange={this.updateUniqueID}
                                value={this.state.applicantDetails.uniqueID}
                                maxlength="50"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.uniqueID_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Program </label>
                              <select
                                disabled
                                className="form-control"
                                ref={(input) => (this.program = input)}
                                onChange={this.updateProgram}
                                value={this.state.applicantDetails.programTitle}
                              >
                                <option value="">-- select --</option>
                                <option value="ERA-Santa Ana">
                                  ERA-Santa Ana
                                </option>
                                <option value="ERA-OC">ERA-OC</option>
                                <option value="Pandemic HPP">
                                  Pandemic HPP
                                </option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Date Applied </label>
                              <Tooltip title="Date on which applicant applied for program">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="date"
                                ref={this.dateAppliedRef}
                                onChange={this.updateDateApplied}
                                value={this.state.applicantDetails.dateApplied}
                              ></input>
                              <div className="alert-small-text">
                                {this.state.dateApplied_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Agent</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.agentRef}
                                onChange={this.updateAgent}
                                maxlength="100"
                                value={this.state.applicantDetails.agent}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.agent_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1">
                              <label>Household ID </label>
                              <input
                                className="form-control"
                                ref={this.householdIDRef}
                                onChange={this.updateHouseholdID}
                                value={this.state.applicantDetails.householdID}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.householdID_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Transaction ID </label>
                              <input
                                className="form-control"
                                ref={this.transactionIDRef}
                                onChange={this.updateTransactionID}
                                value={
                                  this.state.applicantDetails.transactionID
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.transactionID_Error}
                              </div>
                            </div>

                            <div className="form-group col-md-6 col-xl-1">
                              <label>Suffix </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.suffixRef}
                                onChange={this.updateSuffix}
                                maxlength="10"
                                value={this.state.applicantDetails.suffix}
                              ></input>
                              <div className="alert-small-text">
                                {this.state.suffix_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>First Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.firstNameRef}
                                onChange={this.updateFirstName}
                                value={this.state.applicantDetails.firstName}
                                maxlength="100"
                              ></input>
                              <div className="alert-small-text">
                                {this.state.firstName_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Middle Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.middleNameRef}
                                onChange={this.updateMiddleName}
                                value={this.state.applicantDetails.middleName}
                                maxlength="100"
                              ></input>
                              <div className="alert-small-text">
                                {this.state.middleName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Last Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.lastNameRef}
                                onChange={this.updateLastName}
                                value={this.state.applicantDetails.lastName}
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.lastName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Tenant Email </label>
                              <div className="input-group-append">
                                <input
                                  type="text"
                                  className="form-control"
                                  ref={this.tenantEmailRef}
                                  onChange={this.updateTenantEmail}
                                  maxlength="100"
                                  value={
                                    this.state.applicantDetails.tenantEmail
                                  }
                                ></input>
                              </div>
                              <div class="alert-small-text">
                                {this.state.tenantEmail_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Phone Number</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.phoneRef}
                                onChange={this.updatePhone}
                                maxlength="20"
                                value={this.state.applicantDetails.phone}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.phone_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1">
                              <label>Age </label>
                              <input
                                className="form-control"
                                type="number"
                                name="household"
                                min="0"
                                max="100"
                                ref={this.ageRef}
                                onChange={this.updateAge}
                                value={this.state.applicantDetails.age}
                              />
                              <div class="alert-small-text">
                                {this.state.age_Error}
                              </div>
                            </div>

                            <div className="form-group col-md-6 col-xl-3 field-hightlight">
                              <label>Address Line 1 </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.addressLine1Ref}
                                onChange={this.updateAddress}
                                maxlength="255"
                                value={
                                  this.state.applicantProperty.addressLine1
                                }
                              ></input>
                              <div className="alert-small-text">
                                {this.state.addressLine1_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Address Line 2 </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.addressLine2Ref}
                                onChange={this.updateAddress2}
                                maxlength="255"
                                value={
                                  this.state.applicantProperty.addressLine2
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.addressLine2_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1 field-hightlight">
                              <label>City </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.cityRef}
                                onChange={this.updateCity}
                                maxlength="30"
                                value={this.state.applicantProperty.city}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.city_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1 field-hightlight">
                              <label>State </label>
                              <input
                                className="form-control"
                                ref={this.stateRef}
                                onChange={this.updateState}
                                value={this.state.applicantProperty.state}
                                maxlength="50"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.state_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1 field-hightlight">
                              <label>Zip </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.zipcodeRef}
                                onChange={this.updateZip}
                                maxlength="5"
                                value={this.state.applicantProperty.zipcode}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.zipcode_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Birthday Code </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.birthdayCodeRef}
                                onChange={this.updateBirthdayCode}
                                value={this.state.applicantDetails.birthdayCode}
                                maxlength="255"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.birthdayCode_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Date of Birth </label>
                              <input
                                className="form-control"
                                type="date"
                                ref={this.clientDOBRef}
                                onChange={this.updateDOB}
                                value={this.state.applicantDetails.clientDOB}
                              ></input>
                              <div className="alert-small-text">{""}</div>
                            </div>

                            <div className="form-group col-md-6 col-xl-1">
                              <label>Gender </label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={this.updateGender}
                                maxlength="10"
                                value={this.state.applicantDetails.gender}
                              ></input>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1">
                              <label>Race</label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={this.updateRace}
                                maxlength="100"
                                value={this.state.applicantDetails.race}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.race_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Ethnicity</label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={this.updateEthnicity}
                                maxlength="100"
                                value={this.state.applicantDetails.ethnicity}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.ethnicity_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Social Security Number</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.SSNRef}
                                onChange={this.updateSSN}
                                maxlength="10"
                                value={this.state.applicantDetails.SSN}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.SSN_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Social Security First Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.socialSecurityFirstNameRef}
                                onChange={this.updateSocialSecurityFirstName}
                                value={
                                  this.state.applicantDetails
                                    .socialSecurityFirstName
                                }
                                maxlength="100"
                              ></input>
                              <div className="alert-small-text">
                                {this.state.socialSecurityFirstName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Social Security Last Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.socialSecurityLastNameRef}
                                onChange={this.updateSocialSecurityLastName}
                                value={
                                  this.state.applicantDetails
                                    .socialSecurityLastName
                                }
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.socialSecurityLastName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Other Phone </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.otherPhoneRef}
                                onChange={this.updateOtherPhone}
                                value={this.state.applicantDetails.otherPhone}
                                maxlength="20"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.otherPhone_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>SPA Assignment </label>
                              <select
                                className="form-control"
                                ref={this.spaAssignmentNoRef}
                                onChange={this.updateSPAAssignmentNo}
                                value={
                                  this.state.applicantDetails.spaAssignmentNo
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Latino Health">
                                  Latino Health
                                </option>
                                <option value="Families Forward">
                                  Families Forward
                                </option>
                                <option value="Salvation Army">
                                  Salvation Army
                                </option>
                                <option value="Catholic Charities ">
                                  Catholic Charities{" "}
                                </option>
                                <option value="Lutheran Social Services">
                                  Lutheran Social Services
                                </option>
                                <option value="Community Action Partnership of Orange County">
                                  Community Action Partnership of Orange County
                                </option>
                                <option value="Families Together of Orange County">
                                  Families Together of Orange County
                                </option>
                                <option value="South County Outreach">
                                  South County Outreach
                                </option>
                              </select>
                              <div class="alert-small-text">
                                {this.state.spaAssignmentNo_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>SPA Service Provider Email </label>
                              <input
                                className="form-control"
                                maxlength="255"
                                ref={this.spaServiceProviderEmailRef}
                                onChange={this.updateSPAServiceProviderEmail}
                                value={
                                  this.state.applicantDetails
                                    .spaServiceProviderEmail
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.spaServiceProviderEmail_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Partner First Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.partnerFirstNameRef}
                                onChange={this.updateFirstNameSpouse}
                                maxlength="100"
                                value={
                                  this.state.applicantDetails.partnerFirstName
                                }
                              ></input>
                              <div className="alert-small-text">
                                {this.state.partnerFirstName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Partner Last Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.partnerLastNameRef}
                                onChange={this.updateLastNameSpouse}
                                maxlength="100"
                                value={
                                  this.state.applicantDetails.partnerLastName
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.partnerLastName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-1">
                              <label>Do you rent?</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.doYouRentRef}
                                onChange={this.updateDoYouRent}
                                maxlength="100"
                                value={this.state.applicantDetails.doYouRent}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.doYouRent_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Monthly Household Income</label>
                              <Tooltip title="Monthly Household Income before COVID-19. Maximum allowed value is 99999.99.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.monthlyIncomeBeforeCovid19Ref}
                                onChange={this.updateIncome}
                                maxlength="8"
                                max="99999.99"
                                value={
                                  this.state.applicantDetails
                                    .monthlyIncomeBeforeCovid19
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.monthlyIncomeBeforeCovid19_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Current Total Household Income</label>
                              <Tooltip title="Maximum allowed value is 9999999.99">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.currentTotalHouseholdIncomeRef}
                                onChange={
                                  this.updateCurrentTotalHouseholdIncome
                                }
                                maxlength="10"
                                value={
                                  this.state.applicantDetails
                                    .currentTotalHouseholdIncome
                                }
                                max="9999999.99"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.currentTotalHouseholdIncome_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>
                                Is the Individual Open to Follow-up?{" "}
                              </label>
                              <select
                                className="form-control"
                                ref={(input) =>
                                  (this.openToFollowUpSelector = input)
                                }
                                onChange={this.updateOpenToFollowUp}
                                value={
                                  this.state.applicantDetails.openToFollowup
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Number of Times Participated</label>
                              <Tooltip title="Number of Times Applicant has Participated in Program">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.noOfTimesParticipatedRef}
                                onChange={this.updateNoOfTimesParticipated}
                                maxlength="2"
                                value={
                                  this.state.applicantDetails
                                    .noOfTimesParticipated
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.noOfTimesParticipated_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Do you receive any housing subsidy?</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.receiveAnyHousingSubsidyRef}
                                onChange={this.updateReceiveAnyHousingSubsidy}
                                maxlength="100"
                                value={
                                  this.state.applicantDetails
                                    .receiveAnyHousingSubsidy
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.receiveAnyHousingSubsidy_Error}
                              </div>
                            </div>
                            {/* <div class="form-group col-md-6 col-xl-3">
                                                                <label>Is annual income less?</label>
                                                                <Tooltip title="Is annual income less than the maximum according to the size of your household?">
                                                                    <i className="fa fa-question-circle ml-1"></i>
                                                                </Tooltip>
                                                                <input className="form-control" type="text" ref={this.isAnnualIncomeLessRef} onChange={this.updateIsAnnualIncomeLess} maxlength="100" value={this.state.isAnnualIncomeLess}></input>
                                                                <div class="alert-small-text">{this.state.isAnnualIncomeLess_Error}</div>
                                                            </div> */}
                            <div class="form-group col-md-6 col-xl-3">
                              <label>
                                Is someone on rental/lease agreement?
                              </label>
                              <Tooltip title="Is someone in your household on the rental or lease agreement?">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.isSomeoneOnRentalOrLeaseAgreementRef}
                                onChange={
                                  this.updateIsSomeoneOnRentalOrLeaseAgreement
                                }
                                maxlength="100"
                                value={
                                  this.state.applicantDetails
                                    .isSomeoneOnRentalOrLeaseAgreement
                                }
                              ></input>
                              <div class="alert-small-text">
                                {
                                  this.state
                                    .isSomeoneOnRentalOrLeaseAgreement_Error
                                }
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Filed a declaration of COVID-19?</label>
                              <Tooltip title="Have you filed a declaration of COVID-19 related financial stress?">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={
                                  this.filedCovid19FinancialStressDeclarationRef
                                }
                                onChange={
                                  this
                                    .updateFiledCovid19FinancialStressDeclaration
                                }
                                maxlength="100"
                                value={
                                  this.state.applicantDetails
                                    .filedCovid19FinancialStressDeclaration
                                }
                              ></input>
                              <div class="alert-small-text">
                                {
                                  this.state
                                    .filedCovid19FinancialStressDeclaration_Error
                                }
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Received an eviction notice?</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.receivedEvictionNoticeRef}
                                onChange={this.updateReceivedEvictionNotice}
                                maxlength="100"
                                value={
                                  this.state.applicantDetails
                                    .receivedEvictionNotice
                                }
                              ></input>
                              <div class="alert-small-text">
                                {this.state.receivedEvictionNotice_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>Eviction notice received date </label>
                              <input
                                className="form-control"
                                type="date"
                                ref={this.dateEvictionNoticeReceivedRef}
                                onChange={this.updateDateEvictionNoticeReceived}
                                value={
                                  this.state.applicantDetails
                                    .dateEvictionNoticeReceived
                                }
                              ></input>
                              <div className="alert-small-text">
                                {this.state.dateEvictionNoticeReceived_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>Secondary First Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.secondaryFirstNameRef}
                                onChange={this.updateSecondaryFirstName}
                                value={
                                  this.state.applicantDetails.secondaryFirstName
                                }
                                maxlength="100"
                              ></input>
                              <div className="alert-small-text">
                                {this.state.secondaryFirstName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Secondary Last Name </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.secondaryLastNameRef}
                                onChange={this.updateSecondaryLastName}
                                value={
                                  this.state.applicantDetails.secondaryLastName
                                }
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.secondaryLastName_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Secondary Email</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.secondaryEmailRef}
                                onChange={this.updateSecondaryEmail}
                                value={
                                  this.state.applicantDetails.secondaryEmail
                                }
                                maxlength="50"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.secondaryEmail_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Secondary Phone </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.secondaryPhoneRef}
                                onChange={this.updateSecondaryPhone}
                                value={
                                  this.state.applicantDetails.secondaryPhone
                                }
                                maxlength="20"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.secondaryPhone_Error}
                              </div>
                            </div>

                            <div class="form-group col-md-6 col-xl-2">
                              <label>Below 30% AMI </label>
                              <select
                                className="form-control"
                                onChange={this.updateBelowThirtyPercAMI}
                                value={
                                  this.state.applicantDetails.belowThirtyPercAMI
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>30%-50% AMI </label>
                              <select
                                className="form-control"
                                onChange={this.updateThirtyToFiftyPercAMI}
                                value={
                                  this.state.applicantDetails
                                    .thirtyToFiftyPercAMI
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>50%-80% AMI </label>
                              <select
                                className="form-control"
                                onChange={this.updateFiftyToEightyPercAMI}
                                value={
                                  this.state.applicantDetails
                                    .fiftyToEightyPercAMI
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Board District </label>
                              <select
                                className="form-control"
                                onChange={this.updateBoardDistrict}
                                value={
                                  this.state.applicantDetails.boardDistrict
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Length of time for Award </label>
                              <Tooltip title="Calculated based on Date Applied and Date on which the rent payment status updated to Issued">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                onChange={this.updateLengthOfTimeForAward}
                                value={this.state.lengthOfTimeForAward}
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>SP Verified </label>
                              <select
                                className="form-control"
                                onChange={this.updateSPVerified}
                                value={this.state.applicantDetails.verified}
                              >
                                <option value="">-- select --</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>Payment Month 2021</label>
                              {/* className={classes.formControl} */}
                              <FormControl className={classes.formControl}>
                                <Select className={classes.selectStyle}
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.paymentMonths2021}
                                  name="first"
                                  // onChange={handleSelectChange}
                                  onChange={(e) => this.handleSelectChange(e)}
                                  //  input={<OutlinedInput label="Tag" />}
                                  MenuProps={MenuProps}
                                  renderValue={(selected) =>
                                    selected.map((obj) => names[obj - 1].value).join(", ")
                                  }
                                >
                                  {names.map((name) => (
                                    <MenuItem key={name.id} value={name.id}>
                                      <Checkbox checked={this.state.paymentMonths2021 && this.state.paymentMonths2021.indexOf(name.id) > -1} />
                                      <ListItemText primary={name.value} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                            {/* style={{ display: 'none' }} */}
                            <div className="form-group col-md-6 col-xl-3" >
                              <label>Payment Month 2022</label>
                              {/* className={classes.formControl} */}
                              <FormControl className={classes.formControl}>
                                <Select className={classes.selectStyle}
                                  labelId="demo-mutiple-checkbox-label"
                                  id="demo-mutiple-checkbox"
                                  multiple
                                  value={this.state.paymentMonths2022}
                                  name="first"
                                  // onChange={handleSelectChange}
                                  onChange={(e) => this.handleSelect2022Change(e)}
                                  //  input={<OutlinedInput label="Tag" />}
                                  MenuProps={MenuProps}
                                  renderValue={(selected) =>
                                    selected.map((obj) => names[obj - 1].value).join(", ")
                                  }
                                >
                                  {names.map((name) => (
                                    <MenuItem key={name.id} value={name.id}>
                                      <Checkbox checked={this.state.paymentMonths2022 && this.state.paymentMonths2022.indexOf(name.id) > -1} />
                                      <ListItemText primary={name.value} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-gray mb-4">
                        <h2 className="profile-title">
                          <i class="fas fa-file-alt mr-2"></i>Other Details
                        </h2>
                        <div className="box-details">
                          <div className="form-row">
                            <div className="form-group col-md-6 col-xl-3">
                              <label>Financial effect </label>
                              <Tooltip title="How did COVID-19 financially affect you or your household?">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <select
                                className="form-control"
                                // onChange={this.checkaffect}
                                ref={(input) => (this.effected = input)}
                                onChange={this.updateFinancialEffect}
                                value={
                                  this.state.applicantDetails
                                    .effectOfCovid19Financially
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Furloughed due to pandemic">
                                  Furloughed due to pandemic
                                </option>
                                <option value="Laid off due to pandemic">
                                  Laid off due to pandemic
                                </option>
                                <option value="Underemployed or significant reduction in work hours">
                                  Underemployed or significant reduction in work
                                  hours
                                </option>
                                <option value="Not Affected">
                                  Not Affected
                                </option>
                                <option value="Other">Other</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div
                              class="form-group col-md-6 col-xl-2"
                              style={{
                                display:
                                  this.state.applicantDetails
                                    .effectOfCovid19Financially === "Other"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <label>Enter the other financial effect</label>
                              <input
                                className="form-control"
                                ref={this.covid_otherRef}
                                onChange={this.updateCovidOther}
                                value={this.state.applicantDetails.covid_other}
                              ></input>
                              <div class="alert-small-text">
                                {this.state.covid_other_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>
                                Are you eligible to legally work in the US?{" "}
                              </label>
                              <select
                                className="form-control"
                                ref={(input) => (this.workSelector = input)}
                                onChange={this.updateLegallyAbleToWork}
                                value={
                                  this.state.applicantDetails.legallyAbleToWork
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Work Industry? </label>
                              <select
                                className="form-control"
                                ref={(input) => (this.industrySelector = input)}
                                onChange={this.updateWorkIndustry}
                                value={this.state.applicantDetails.workIndustry}
                              >
                                <option value="">-- select --</option>
                                <option value="Food Service (traditional food service & food delivery)">
                                  Food Service (traditional food service & food
                                  delivery)
                                </option>
                                <option value="Hospitality">Hospitality</option>
                                <option value="Amusement Parks">
                                  Amusement Parks
                                </option>
                                <option value="Retail">Retail</option>
                                <option value="Self-employed (free lance, uber/lyft drivers, etc.)">
                                  Self-employed (free lance, uber/lyft drivers,
                                  etc.)
                                </option>
                                <option value="Other">Other</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div
                              className="form-group col-md-6 col-xl-2"
                              style={{
                                display:
                                  this.state.applicantDetails.workIndustry ===
                                    "Other"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <label>Enter the other industry</label>
                              <input
                                className="form-control"
                                ref={this.industry_otherRef}
                                onChange={this.updateIndustryOther}
                                value={
                                  this.state.applicantDetails.industry_other
                                }
                              ></input>
                              <div className="alert-small-text">
                                {this.state.industry_other_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-4">
                              <label>
                                Are you or anyone in your household a veteran?{" "}
                              </label>
                              <select
                                className="form-control"
                                ref={(input) => (this.veteranSelector = input)}
                                onChange={this.updateVeteran}
                                value={this.state.applicantDetails.veteran}
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                                <option value="Client refused">
                                  Client refused
                                </option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>
                                Are You eligible for stimulus funds?{" "}
                              </label>
                              <select
                                className="form-control"
                                ref={(input) => (this.stimulusSelector = input)}
                                onChange={this.updateStimulus}
                                value={
                                  this.state.applicantDetails
                                    .eligibleForStimulusFunds
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              <div className="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Employment Status? </label>
                              <select
                                className="form-control"
                                ref={(input) =>
                                  (this.employmentSelector = input)
                                }
                                onChange={this.updateEmploymentStatus}
                                value={
                                  this.state.applicantDetails.employmentStatus
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Employed">Employed</option>
                                <option value="Unemployed">Unemployed</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Household Size? </label>
                              <input
                                className="form-control"
                                type="number"
                                name="household"
                                min="1"
                                max="100"
                                ref={this.householdNumberRef}
                                onChange={this.updateHouseHold}
                                value={
                                  this.state.applicantDetails.householdNumber
                                }
                              />
                              <div class="alert-small-text">
                                {this.state.householdNumber_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Count Adults </label>
                              <input
                                className="form-control"
                                type="number"
                                name="household"
                                min="0"
                                max="100"
                                ref={this.countAdultRef}
                                onChange={this.updateCountAdult}
                                value={this.state.applicantDetails.countAdult}
                              />
                              <div class="alert-small-text">
                                {this.state.countAdult_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Count Children </label>
                              <input
                                className="form-control"
                                type="number"
                                name="household"
                                min="0"
                                max="100"
                                ref={this.countChildrenRef}
                                onChange={this.updateCountChildren}
                                value={
                                  this.state.applicantDetails.countChildren
                                }
                              />
                              <div class="alert-small-text">
                                {this.state.countChildren_Error}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-gray mb-4">
                        {/* <h2 className="profile-title"><i class="fas fa-house-user mr-2"></i>Landlord Details</h2> */}

                        <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-house-user mr-2"></i>Landlord
                            Details
                          </span>

                          {this.state.landlordDetails
                            .landlordInfoRequestedStatus !== "Ready" && this.state.landlordDetails.entityPaymentStatus !== "Issued" ? (
                            <span class="property-grid-add d-flex">
                              <label
                                class="my-2 mr-2 pt-2 ml-3"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textTransform: "initial",
                                  fontWeight: "500",
                                }}
                                style={{
                                  display:
                                    this.state.landlordDetails
                                      .requestedLandlordInfo === true ||
                                      this.state.landlordDetails
                                        .requestedLandlordInfo === 1
                                      ? "block"
                                      : "none",
                                }}
                                id="requestDateId"
                              >
                                Date Requested old:{" "}
                                {this.state
                                  .dateLandlordInfoRequested_Formatted +
                                  " " +
                                  this.state.landlordDetails
                                    .timeLandlordInfoRequested}
                              </label>
                              <label
                                class="my-2 pt-2 ml-3"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textTransform: "initial",
                                  fontWeight: "500",
                                }}
                                style={{
                                  display:
                                    this.state.landlordDetails
                                      .requestedLandlordInfoNew === true ||
                                      this.state.landlordDetails
                                        .requestedLandlordInfoNew === 1
                                      ? "block"
                                      : "none",
                                }}
                                id="requestDateNewId"
                              >
                                Date Requested new:{" "}
                                {this.state
                                  .dateLandlordInfoRequestedNew_Formatted +
                                  " " +
                                  this.state.landlordDetails
                                    .timeLandlordInfoRequestedNew}
                              </label>
                              <label
                                class="my-2 ml-3 mr-2 pt-2"
                                // style={{
                                //   fontSize: "14px",
                                //   color: "#000",
                                //   textTransform: "initial",
                                //   fontWeight: "500",
                                // }}
                                style={{
                                  // fontSize: "14px",
                                  // color: "#000",
                                  // textTransform: "initial",
                                  // fontWeight: "500",
                                  display:
                                    (this.state.landlordDetails
                                      .requestedLandlordInfoNew === true ||
                                      this.state.landlordDetails
                                        .requestedLandlordInfoNew === 1) &&
                                      this.state.dateLandlordInfoReminded_Formatted !=
                                      "0000-00-00" &&
                                      this.state.dateLandlordInfoReminded_Formatted !=
                                      "1000-01-01" &&
                                      this.state.landlordDetails
                                        .timeLandlordInfoReminded != null &&
                                      this.state.landlordDetails.landlordInfoRequestedStatus === "Reminder Sent"
                                      ? "block"
                                      : "none",
                                }}
                                id="remindedDateId"
                              >
                                Date Reminded:{" "}
                                {this.state.dateLandlordInfoReminded_Formatted +
                                  " " +
                                  this.state.landlordDetails
                                    .timeLandlordInfoReminded}
                              </label>
                              <button
                                type="button"
                                class="btn btn-primary btn-sm my-2 my-md-0"
                                onClick={this.requestLandlordInfo}
                                style={{
                                  display:
                                    this.state.landlordDetails
                                      .requestedLandlordInfoNew === true ||
                                      this.state.landlordDetails
                                        .requestedLandlordInfoNew === 1
                                      ? "none"
                                      : "block",
                                }}
                                // style={{
                                //   display:
                                //     this.state.landlordDetails
                                //       .requestedLandlordInfo === true ||
                                //     this.state.landlordDetails
                                //       .requestedLandlordInfo === 1
                                //       ? "none"
                                //       : "block",
                                // }}
                                id="requestLandlordInfoId"
                              >
                                Request information
                              </button>
                              <button
                                type="button"
                                class="btn btn-primary btn-sm ml-2 my-2 my-md-0"
                                onClick={this.sendReminderToLandlord}
                                style={{
                                  display:
                                    this.state.landlordDetails
                                      .requestedLandlordInfoNew === true ||
                                      this.state.landlordDetails
                                        .requestedLandlordInfoNew === 1
                                      ? "block"
                                      : "none",
                                }}
                                id="sendReminderToLandlordId"
                              >
                                Send Reminder
                              </button>
                            </span>
                          ) : (
                            // Nothing to show here
                            <span></span>
                          )}
                        </h2>

                        <div className="box-details">
                          <div className="form-row">
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord Payee Full Name</label>
                              <Tooltip title="please fillout name on bank account (landlord or property manager)">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.payeeRef}
                                onChange={this.updatePayee}
                                value={this.state.applicantDetails.payee}
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.payee_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord Email</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordEmailRef}
                                onChange={this.updateLandlordEmail}
                                value={
                                  this.state.applicantDetails.landlordEmail
                                }
                                maxlength="50"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordEmail_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord Phone Number </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordPhoneNumberRef}
                                onChange={this.updateLandlordPhone}
                                value={
                                  this.state.applicantDetails
                                    .landlordPhoneNumber
                                }
                                maxlength="20"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordPhoneNumber_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Amount Due for Rent</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.amountDueforRentRef}
                                onChange={this.updateDollarAmountDueForRent}
                                value={
                                  this.state.landlordDetails.entityAmountDue
                                }
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.amountDueforRent_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Rent Amount Covered</label>
                              <Tooltip title="$ amount approved for payout.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.rentAmountRef}
                                onChange={this.updateAmount}
                                value={this.state.landlordDetails.entityAmount}
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.rentAmount_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Award % of total rent due</label>
                              <Tooltip title="Calculated based on Amount Due for Rent and Rent Amount Covered">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.percentageOfMonthlyRentRef}
                                onChange={this.updatePercentageOfMonthlyRent}
                                value={
                                  this.state.landlordDetails
                                    .entityPercentageOfMonthlyBill
                                }
                                readOnly
                                maxlength="2"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.percentageOfMonthlyRent_Error}
                              </div>
                            </div>

                            <div class="form-group col-md-6 col-xl-3 field-hightlight">
                              <label>Landlord Address Line 1</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordAddressLine1Ref}
                                onChange={this.updatelandlordAddress1}
                                value={this.state.landlordProperty.addressLine1}
                                maxlength="255"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordAddressLine1_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3 field-hightlight">
                              <label>Landlord Address Line 2</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordAddressLine2Ref}
                                onChange={this.updatelandlordAddress2}
                                value={this.state.landlordProperty.addressLine2}
                                maxlength="255"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordAddressLine2_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord City</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordCityRef}
                                onChange={this.updatelandlordCity}
                                value={this.state.landlordProperty.city}
                                maxlength="30"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordCity_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord State</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordStateRef}
                                onChange={this.updatelandlordState}
                                value={this.state.landlordProperty.state}
                                maxlength="50"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordState_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Landlord Zip</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordZipcodeRef}
                                onChange={this.updatelandlordZipcode}
                                value={this.state.landlordProperty.zipcode}
                                maxlength="5"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordZipcode_Error}
                              </div>
                            </div>

                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord Bank Name</label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={this.updateLandlordBankName}
                                value={this.state.landlordBankName}
                                maxlength="100"
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord Account Number</label>
                              <Tooltip title="If applicable: Bank account number. Monies will be deposited into this account.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordAccountNumberRef}
                                onChange={this.updateLandlordAccountNumber}
                                value={this.state.landlordAccountNumber}
                                maxlength="20"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordAccountNumber_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord Routing Number</label>
                              <Tooltip title="If applicable: Bank electronic routing number">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordRoutingNumberRef}
                                onChange={this.updateLandlordRoutingNumber}
                                value={this.state.landlordRoutingNumber}
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordRoutingNumber_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord SSN</label>
                              <Tooltip title="If applicable: SSN is a nine-digit number issued to U.S. citizens, permanent residents, and temporary residents.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordSSNRef}
                                onChange={this.updateLandlordSSN}
                                value={this.state.landlordDetails.landlordSSN}
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordSSN_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord Tax I.D.</label>
                              <Tooltip title="Must Include if Applicable. A Tax Identification Number is a nine-digit number, can be a social security number or employer identification number. Typically used if landlord is a sole proprietor.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordTaxIDRef}
                                onChange={this.updateLandlordTaxID}
                                value={this.state.landlordDetails.entityTaxID}
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordTaxID_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Landlord DUNS Number</label>
                              <Tooltip title="Must Include if Applicable. The Dun & Bradstreet DUNS Number is a unique nine-digit identifier for businesses.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.landlordDUNSNumberRef}
                                onChange={this.updateLandlordDUNSNumber}
                                value={
                                  this.state.landlordDetails.entityDUNSNumber
                                }
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.landlordDUNSNumber_Error}
                              </div>
                            </div>

                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Monthly Rent</label>
                              <Tooltip title="Maximum allowed value is 99999.99">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.monthlyRentRef}
                                onChange={this.updateMonthlyRent}
                                value={
                                  this.state.landlordDetails.entityMonthlyAmount
                                }
                                maxlength="8"
                                max="99999.99"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.monthlyRent_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label># of Month of Rental Payment</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.noOfMonthsRentalPaymentsRef}
                                onChange={this.updateNoOfMonthsRentalPayments}
                                value={
                                  this.state.landlordDetails
                                    .entityNoOfMonthsUtilityPayments
                                }
                                maxlength="5"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.noOfMonthsRentalPayments_Error}
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Memo -·Property Address</label>
                              <input
                                className="form-control"
                                type="text"
                                onChange={this.updatePropertyAddress}
                                value={
                                  this.state.landlordDetails
                                    .entityMemoPropertyAddress
                                }
                                maxlength="255"
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Rent Type of Assistance </label>
                              <select
                                className="form-control"
                                ref={(input) =>
                                  (this.rentTypeAssistanceSelector = input)
                                }
                                onChange={this.updateRentTypeOfAssistance}
                                value={
                                  this.state.landlordDetails
                                    .rentTypeOfAssistance
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Rent">Rent</option>
                                <option value="Rental Arrears">
                                  Rental Arrears
                                </option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Arrear Amount</label>
                              <Tooltip title="Outstanding Rental Arrears Amount. Maximum allowed value is 99999.99.">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.outstandingRentalArrearsAmountRef}
                                onChange={this.updateOutstandingRentalArrears}
                                value={
                                  this.state.landlordDetails
                                    .outstandingRentalArrearsAmount
                                }
                                maxlength="8"
                                max="99999.99"
                              ></input>
                              <div class="alert-small-text">
                                {
                                  this.state
                                    .outstandingRentalArrearsAmount_Error
                                }
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Rent Payment Status </label>
                              <select
                                className="form-control"
                                id="rentPaymentStatusId"
                                ref={this.rentPaymentStatusRef}
                                onChange={this.updateRentPaymentStatus}
                                value={
                                  this.state.landlordDetails.entityPaymentStatus
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Pending">Pending</option>
                                <option value="In-Review">In-Review</option>
                                <option value="Approved for Payment">
                                  Approved for Payment
                                </option>
                                <option value="N/A">N/A</option>
                                {/* <option value="Issued">Issued</option> */}
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>

                            <div
                              class="form-group col-md-6 col-xl-3"
                              style={{
                                display:
                                  this.state.rentPaymentStatus_Original ===
                                    "Issued"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <label>Timestamp of Issued Notification</label>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                onChange={this.updateTimestampOfRentIssued}
                                value={this.state.timestampOfRentIssued}
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Landlord First Attempt (21 Days)</label>
                              <input
                                className="form-control"
                                type="date"
                                onChange={this.updateLandlordFirstAttempt}
                                value={
                                  this.state.landlordDetails
                                    .landlordFirstAttempt
                                }
                              ></input>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            {/* <div class="form-group col-md-6 col-xl-3">
                                                                <label>Landlord Response (21 Days)</label>
                                                                <input className="form-control" type="date" onChange={this.updateLandlordResponse} value={this.state.landlordResponse}></input>
                                                                <div class="alert-small-text">{""}</div>
                                                            </div> */}
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Reason Rent Payment Status N/A </label>
                              <Tooltip title="Reason of payment not processed in 21 days">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <select
                                className="form-control"
                                onChange={
                                  this.updateReasonPaymentNotProcessedIn21Days
                                }
                                ref={this.reasonPaymentNotProcessedIn21DaysRef}
                                value={
                                  this.state.landlordDetails
                                    .reasonPaymentNotProcessedIn21Days
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Refused to accept">
                                  Refused to accept
                                </option>
                                <option value="No payment requested">
                                  No payment requested
                                </option>
                                <option value="No response in 21 days from landlord">
                                  No response in 21 days from landlord
                                </option>
                                <option value="Tenant Request to Withdraw">
                                  Tenant Request to Withdraw
                                </option>
                                <option value="Anonymous file transfer">
                                  Anonymous file transfer
                                </option>
                                <option value="Duplication of Benefits">
                                  Duplication of Benefits
                                </option>
                                <option value="State Funded">
                                  State Funded
                                </option>
                                <option value="Pending service provider response">
                                  Pending service provider response
                                </option>
                                <option value="No response in 7 days from landlord">
                                  No response in 7 days from landlord
                                </option>
                                <option value="Application cancelled by Service Provider">
                                  Application cancelled by Service Provider
                                </option>
                              </select>
                              <div class="alert-small-text">
                                {
                                  this.state
                                    .reasonPaymentNotProcessedIn21Days_Error
                                }
                              </div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Receiver Company </label>
                              <select
                                className="form-control"
                                onChange={
                                  this.updateReceiverCompany
                                }
                                value={
                                  this.state.applicantDetails
                                    .receiverCompany
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Rose: AMC">
                                  Rose: AMC
                                </option>
                                <option value="Ronnie Sandoval">
                                  Ronnie Sandoval
                                </option>
                              </select>
                            </div>
                            <div className="form-group col-md-6 col-xl-2">
                              <label>1099 Total</label>
                              <Tooltip title="Sum of 'Rent Amount Covered' and 'reconciliation Amount'">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={
                                  this.state.applicantDetails.total1099Amount
                                }
                              ></input>
                              {/* <div class="alert-small-text">{this.state.total1099Amount_Error}</div> */}
                            </div>

                            <div className="form-group col-md-6 col-xl-2">
                              <label>1099 Total 2022</label>
                              <Tooltip title="Sum of 'Rent Amount Covered' and 'reconciliation Amount'">
                                <i className="fa fa-question-circle ml-1"></i>
                              </Tooltip>
                              <input
                                className="form-control"
                                type="text"
                                readOnly
                                value={
                                  this.state.applicantDetails.total1099Amount2022
                                }
                              ></input>
                              {/* <div class="alert-small-text">{this.state.total1099Amount_Error}</div> */}
                            </div>

                            <div class="form-group col-md-6 col-xl-2">
                              <label></label>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="landlordACHReceivedId"
                                  class="custom-control-input"
                                  onChange={this.onLandlordACHReceivedChange}
                                />
                                <label
                                  class="custom-control-label"
                                  for="landlordACHReceivedId"
                                >
                                  ACH Received
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="box-details px-3">
                          <div class="form-row">
                            <div class="form-group col-md-2 field-hightlight">
                              <label>Transaction Mode </label>
                              <select
                                className="form-control"
                                value={
                                  this.state.landlordpaymentDetails
                                    .transactionType
                                }
                                onChange={(e) =>
                                  this.handleTransectionModeLandlord(e)
                                }
                                disabled={!this.state.landlordDetails.requestedLandlordInfoNew} 
                              >
                                <option value="">-- select --</option>
                                <option value="ACH">ACH</option>
                                <option value="Check">Check</option>
                              </select>
                            </div>
                          </div>
                          {this.state.landlordpaymentDetails
                            .transactionType === "ACH" || this.state.landlordpaymentDetails
                              .transactionType === "Check" ? (
                            <h2
                              class=" d-md-flex align-items-center justify-content-between"
                              style={headerStyle}
                            >
                              <span>
                                <i class="fas fa-money-check-alt mr-2"></i>Payment
                                Details Section
                              </span>
                            </h2>
                          ) : null}
                          {/*  Transaction mode starts*/}
                          <div className="box-details">
                            <div className="form-row">

                              {this.state.landlordpaymentDetails
                                .transactionType === "ACH" ? (
                                <>
                                  <div class="form-group col-md-6 col-xl-2">
                                    <label>Landlord Routing number </label>
                                    <input
                                      maxlength="9"
                                      className="form-control"
                                      type="text"
                                      // disabled={!this.state.isTransactionPermission || this.state.landlordpaymentDetails.informationStatus == "Ready"}
                                      disabled={this.state.isTransactionPermission ? (this.state.landlordpaymentDetailsClone.entityRoutingNumber !== "" && this.state.landlordpaymentDetailsClone.entityRoutingNumber !== null && this.state.landlordpaymentDetailsClone.entityRoutingNumber !== undefined) : true}
                                      name="routeingNumber"
                                      ref={this.landlordpaymentDetailsEntityRoutingNumberRef}
                                      onChange={this.onRoutingNumberChange}
                                      value={
                                        this.state.landlordpaymentDetails
                                          .entityRoutingNumber != "" &&
                                          this.state.landlordpaymentDetails
                                            .entityRoutingNumber != null
                                          ? (!this.state.isTransactionPermission ? `*******${this.state.landlordpaymentDetails.entityRoutingNumber}` : `${this.state.landlordpaymentDetails.entityRoutingNumber}`)
                                          : ""
                                      }
                                    ></input>
                                    <div className="alert-small-text">
                                        {this.state.landlordpaymentDetailsEntityRoutingNumber_Error}
                                    </div>
                                  </div>
                                  {/* yyy1 */}
                                  <div class="form-group col-md-6 col-xl-2">
                                    <label>Landlord account number </label>
                                    <input
                                      maxlength="17"
                                      className="form-control"
                                      type="text"
                                      //disabled={!this.state.isTransactionPermission || this.state.landlordpaymentDetails.informationStatus == "Ready"}
                                      disabled={this.state.isTransactionPermission ? (this.state.landlordpaymentDetailsClone.entityAccountNumber !== "" && this.state.landlordpaymentDetailsClone.entityAccountNumber !== null && this.state.landlordpaymentDetailsClone.entityAccountNumber !== undefined) : true}
                                      name="bankAccountNumber"
                                      ref={this.landlordpaymentDetailsEntityAccountNumberRef}
                                      onChange={
                                        this.onentityAccountNymberChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .entityAccountNumber != "" &&
                                          this.state.landlordpaymentDetails
                                            .entityAccountNumber != null
                                          ? (!this.state.isTransactionPermission ? `*******${this.state.landlordpaymentDetails.entityAccountNumber}` : `${this.state.landlordpaymentDetails.entityAccountNumber}`) 
                                          : ""
                                      }
                                    ></input>
                                      <div className="alert-small-text">
                                        {this.state.landlordpaymentDetailsEntityAccountNumber_Error}
                                      </div>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-2">
                                    <label>Payee name </label>

                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="50"
                                      name="payeeName"
                                      onChange={this.oneLandlordPayeeNameChange}
                                      value={
                                        this.state.landlordpaymentDetails
                                          .payeeName
                                      }
                                    ></input>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-2">
                                    <label>Bank Name </label>
                                    <input
                                      className="form-control"
                                      maxlength="50"
                                      type="text"
                                      //disabled={!this.state.isTransactionPermission || this.state.landlordpaymentDetails.informationStatus == "Ready"}
                                      disabled={this.state.isTransactionPermission ? (this.state.landlordpaymentDetailsClone.entityAccountNumber !== "" && this.state.landlordpaymentDetailsClone.entityAccountNumber !== null && this.state.landlordpaymentDetailsClone.entityAccountNumber !== undefined) : true}
                                      name="bankName"
                                      onChange={
                                        this.onLandlordentityBankNameChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .entityBankName
                                      }
                                    ></input>
                                  </div>
                                  <div class="form-group col-sm-6 col-xl-2">
                                    <label>Bank Account Type</label>
                                    <select
                                      className="form-control"
                                      onChange={
                                        this
                                          .onLandlordentityBankAccountTypeChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .entityAccountType
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="Organization">
                                        Organization
                                      </option>
                                      <option value="Personal">Personal </option>
                                    </select>
                                  </div>
                                  <div class="form-group col-sm-6 col-xl-2">
                                    <label>Bank Information Status</label>
                                    <select
                                      className="form-control"
                                      id="landlordAchPaymentStatusId"
                                      ref={this.informationStatusRef}
                                      onChange={
                                        this.oneLandlordInformationStatusChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .informationStatus
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Info Requested">
                                        Info-Requested
                                      </option>
                                      <option value="Reminder Sent">
                                        Reminder Sent
                                      </option>
                                      <option value="Ready">Ready</option>

                                    </select>
                                    <div className="alert-small-text">
                                        {this.state.landlordpaymentInformationStatus_Error}
                                    </div>
                                  </div>
                                </>
                              ) : null}
                              {this.state.landlordpaymentDetails
                                .transactionType === "Check" ? (
                                <>
                                  <div className="form-group col-md-6 col-xl-4">
                                    <label>Address</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      value={
                                        this.state.landlordpaymentDetails
                                          .fullAddress
                                      }
                                      onChange={
                                        this.oneLandlordFullAddressChange
                                      }
                                      maxlength="855"
                                    ></input>
                                  </div>
                                  <div className="form-group col-md-6 col-xl-2">
                                    <label>Payee name </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="100"
                                      onChange={this.oneLandlordPayeeNameChange}
                                      value={
                                        this.state.landlordpaymentDetails
                                          .payeeName
                                      }
                                    ></input>
                                  </div>
                                  <div class="form-group col-sm-6 col-xl-3">
                                    <label>Check Information Status</label>
                                    <select
                                      className="form-control"
                                      id="landlordCheckPaymentStatusId"
                                      onChange={
                                        this.oneLandlordInformationStatusChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .informationStatus
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Info Requested">
                                        Info-Requested
                                      </option>
                                      <option value="Reminder Sent">
                                        Reminder Sent
                                      </option>
                                      <option value="Ready">Ready</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Status </label>
                                    <select
                                      className="form-control"
                                      onChange={
                                        this.oneLandlordcheckStatusStatusChange
                                      }
                                      value={
                                        this.state.landlordpaymentDetails
                                          .checkStatus
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="issued">issued</option>
                                      <option value="bounced">bounced</option>
                                    </select>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-gray mb-4">
                        {/* <h2 className="profile-title"><i class="fas fa-money-check-alt mr-2"></i>Individual Payment Details</h2> */}

                        <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-money-check-alt mr-2"></i>
                            Individual Details
                          </span>
                          {this.state.applicantDetails.tenantEmail !== "" &&
                            this.state.applicantDetails.tenantEmail !== null &&
                            this.state.applicantDetails.tenantEmail !==
                            undefined &&
                            this.state.applicantDetails.applicantDocUploadStatus !== "Ready" ? (
                            <span class="property-grid-add d-flex">
                              <button
                                type="button"
                                class="btn btn-primary btn-sm my-2 my-md-0"
                                onClick={this.requestApplicantInfo}
                                style={{
                                  display:
                                    this.state.applicantDetails
                                      .requestedApplicantInfo === true ||
                                      this.state.applicantDetails
                                        .requestedApplicantInfo === 1
                                      ? "none"
                                      : "block",
                                }}
                                id="requestApplicantInfoId"
                              >
                                Request information Applicant
                              </button>
                              <label
                                class="my-2 pt-2"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textTransform: "initial",
                                  fontWeight: "500",
                                }}
                                style={{
                                  display:
                                    this.state.applicantDetails
                                      .requestedApplicantInfo === true ||
                                      this.state.applicantDetails
                                        .requestedApplicantInfo === 1
                                      ? "block"
                                      : "none",
                                }}
                                id="requestApplicantDateId"
                              >
                                Date Requested:{" "}
                                {this.state
                                  .dateApplicantInfoRequested_Formatted +
                                  " " +
                                  this.state.applicantDetails
                                    .timeApplicantInfoRequested}
                              </label>
                            </span>
                          ) : (
                            // Nothing to show here
                            <span></span>
                          )}
                        </h2>

                        <div className="box-details">
                          <div className="form-row">
                            <div class="form-group col-md-6 col-xl-2">
                              <label>Payment Reason</label>
                              <select
                                className="form-control"
                                ref={(input) =>
                                  (this.individualPaymentReasonSelector = input)
                                }
                                onChange={this.updateIndividualPaymentReason}
                                value={
                                  this.state.individualDetails
                                    .entityPaymentReason
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Rent">Rent</option>
                                <option value="Electric">Electric</option>
                                <option value="Gas">Gas</option>
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-3">
                              <label>Individual Payment Amount</label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.individualPaymentAmountRef}
                                onChange={this.updateIndividualPaymentAmount}
                                value={
                                  this.state.individualDetails.entityAmount
                                }
                                maxlength="10"
                              ></input>
                              <div class="alert-small-text">
                                {this.state.individualPaymentAmount_Error}
                              </div>
                            </div>
                            <div className="form-group col-md-6 col-xl-3">
                              <label>Memo - Property Address </label>
                              <input
                                className="form-control"
                                type="text"
                                ref={this.individualMemoPropertyAddressRef}
                                onChange={
                                  this.updateIndividualMemoPropertyAddress
                                }
                                maxlength="100"
                                value={
                                  this.state.individualDetails
                                    .entityMemoPropertyAddress
                                }
                              ></input>
                              <div className="alert-small-text">
                                {this.state.individualMemoPropertyAddress_Error}
                              </div>
                            </div>

                            <div class="form-group col-md-6 col-xl-2 field-hightlight">
                              <label>Individual Payment Status</label>
                              <select
                                className="form-control"
                                id="individualPaymentStatusId"
                                ref={(input) =>
                                  (this.individualPaymentStatusSelector = input)
                                }
                                onChange={this.updateIndividualPaymentStatus}
                                value={
                                  this.state.individualDetails
                                    .entityPaymentStatus
                                }
                              >
                                <option value="">-- select --</option>
                                <option value="Pending">Pending</option>
                                <option value="In-Review">In-Review</option>
                                <option value="Approved for Payment">
                                  Approved for Payment
                                </option>
                                <option value="N/A">N/A</option>
                                {/* <option value="Issued">Issued</option> */}
                              </select>
                              <div class="alert-small-text">{""}</div>
                            </div>
                            <div class="form-group col-md-6 col-xl-2">
                              <label></label>
                              <div class="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  id="individualACHReceivedId"
                                  class="custom-control-input"
                                  onChange={this.onIndividualACHReceivedChange}
                                />
                                <label
                                  class="custom-control-label"
                                  for="individualACHReceivedId"
                                >
                                  ACH Received
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* start payment details section */}
                        <div className="px-3 box-details">
                          <div class="form-row">
                            <div class="form-group col-md-2 field-hightlight">
                              <label>Transaction Mode </label>
                              <select
                                className="form-control"
                                value={
                                  this.state.individualpaymentDetails
                                    .transactionType
                                }
                                onChange={(e) =>
                                  this.handleTransectionModeIndividual(e)
                                }
                              >
                                <option value="">-- select --</option>
                                {/* <option value="ACH">ACH</option> */}
                                <option value="Check">Check</option>
                              </select>
                            </div>
                          </div>
                          {this.state.individualpaymentDetails
                            .transactionType === "Check" ? (
                            <h2
                              class=" d-md-flex align-items-center justify-content-between"
                              style={headerStyle}
                            >
                              <span>
                                <i class="fas fa-money-check-alt mr-2"></i>Payment
                                Details Section
                              </span>
                            </h2>
                          ) : null}
                          {/*  Transaction mode starts*/}
                          <div className="box-details">
                            <div className="form-row">

                              {this.state.individualpaymentDetails
                                .transactionType === "Check" ? (
                                <>
                                  <div className="form-group col-md-6 col-xl-3">
                                    <label>Address</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={
                                        this.onIndividualFullAddressChanges
                                      }
                                      value={
                                        this.state.individualpaymentDetails
                                          .fullAddress
                                      }
                                      maxlength="855"
                                    ></input>
                                  </div>
                                  <div className="form-group col-md-6 col-xl-2">
                                    <label>Payee name </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="100"
                                      onChange={
                                        this.onIndividualPayeeNameChanges
                                      }
                                      value={
                                        this.state.individualpaymentDetails
                                          .payeeName
                                      }
                                    ></input>
                                  </div>
                                  <div class="form-group col-sm-6 col-lg-4">
                                    <label>Check Information Status</label>
                                    <select
                                      className="form-control"
                                      id="individualCheckPaymentStatusId"
                                      onChange={
                                        this
                                          .onIndividualinformationStatusChanges
                                      }
                                      value={
                                        this.state.individualpaymentDetails
                                          .informationStatus
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="Pending">Pending</option>
                                      <option value="Ready">Ready</option>
                                    </select>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Status </label>
                                    <select
                                      className="form-control"
                                      onChange={
                                        this.onIndividualCheckStatusChanges
                                      }
                                      value={
                                        this.state.individualpaymentDetails
                                          .checkStatus
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="issued">issued</option>
                                      <option value="bounced">bounced</option>
                                    </select>
                                  </div>
                                </>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* reconci section start */}
                      <div className="box-gray mb-4">
                        <h2 class="profile-title d-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-handshake mr-2"></i>Manage
                            Reconciliation
                          </span>
                          <span className="property-grid-add">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={this.openAddPropertyPopup.bind(this)}
                            >
                              <i class="fas fa-plus-circle text-white mr-1"></i>
                              Add
                            </button>
                          </span>
                        </h2>
                        <div className="box-details">
                          <div className="property-grid table-responsive">
                            <table
                              className={
                                "recTable table " +
                                (this.state.reconciliationList &&
                                  this.state.reconciliationList.length > 3
                                  ? "table-scroll"
                                  : "")
                              }
                            >
                              <thead>
                                <tr>
                                  <th width="6%" scope="col">
                                    Id
                                  </th>
                                  <th width="10%" scope="col">
                                    Initial Receiver
                                  </th>
                                  <th width="7%" scope="col">
                                    Paid By
                                  </th>
                                  <th width="12%" scope="col">
                                    Transaction Type
                                  </th>
                                  <th width="10%" scope="col">
                                    Before reconciliation
                                  </th>
                                  <th width="7%" scope="col">
                                    Amount (in $)
                                  </th>
                                  <th width="10%" scope="col">
                                    After reconciliation
                                  </th>
                                  <th width="15%" scope="col">
                                    Other Payment Status
                                  </th>
                                  <th width="15%" scope="col">
                                    Bank Information Status
                                  </th>
                                  <th width="10%" scope="col">
                                    Payment Mode
                                  </th>
                                  <th width="10%" scope="col">
                                    Date
                                  </th>
                                  <th width="7%" scope="col">
                                    Time
                                  </th>
                                  <th width="19%" scope="col">
                                    User
                                  </th>

                                  <th width="7%"></th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.reconciliationList.length == 0 ? (
                                  <p class="text-center"> No record </p>
                                ) : null}
                                {this.state.reconciliationList &&
                                  this.state.reconciliationList.map(
                                    (member, index) => (
                                      <tr>
                                        <td width="6%">{member.id}</td>
                                        <td width="10%">{member.entityType}</td>
                                        <td width="7%">
                                          {member.initiallyPaidBy}
                                        </td>
                                        <td width="12%">{member.amountType}</td>
                                        <td width="10%">
                                          {member.amountCoveredBeforeRec}
                                        </td>
                                        <td width="7%">{member.amount}</td>
                                        <td width="10%">
                                          {member.amountCoveredAfterRec}
                                        </td>
                                        <td width="15%">
                                          {member.otherPaymentStatus}
                                        </td>
                                        <td width="15%">
                                          {member.informationStatus}
                                        </td>
                                        <td width="10%">
                                          {member.transactionType}
                                        </td>
                                        <td width="10%">
                                          {member.dateCreated}
                                        </td>
                                        <td width="7%">
                                          {member.timeCreated}
                                        </td>
                                        <td width="19%">
                                          {member.modifiedBy &&
                                            member.modifiedBy != ""
                                            ? member.modifiedBy
                                            : member.createdBy}
                                        </td>

                                        <td width="7%">
                                          <a
                                            className="border-0"
                                            onClick={this.editReconciliation.bind(
                                              this,
                                              member.id
                                            )}
                                          >
                                            <i class="fas fa-edit mr-2"></i>
                                          </a>
                                          <a
                                            className="border-0"
                                            onClick={this.deleteRec.bind(
                                              this,
                                              member.id
                                            )}
                                          >
                                            <i class="fas fa-trash-alt red"></i>
                                          </a>
                                        </td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* rec section end */}

                      <div className="box-gray mb-4">
                        {/* <h2 className="profile-title"><i class="fas fa-file mr-2"></i>Documents</h2> */}
                        <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-file mr-2"></i>Tenant Documents
                          </span>
                          <div class="property-grid-add">
                            <div class="row align-items-baseline">
                              <label
                                class="col-auto ml-3 pt-2"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textTransform: "initial",
                                  fontWeight: "500",
                                }}
                                style={{
                                  display:
                                    this.state
                                      .applicantDocUploadStatus_Original ===
                                      "Ready"
                                      ? "block"
                                      : "none",
                                }}
                              >
                                Information Received:{" "}
                                {this.state
                                  .dateApplicantDocumentSubmitted_Formatted +
                                  " " +
                                  this.state.applicantDetails
                                    .timeApplicantDocumentSubmitted}
                              </label>
                              <label class="col-auto pt-2 px-0">
                                Document Status{" "}
                              </label>
                              <div class="col-auto">
                                <select
                                  class="form-control w-100"
                                  ref={(input) =>
                                    (this.documentStatusSelector = input)
                                  }
                                  onChange={this.updateTenantDocumentStatus}
                                  value={
                                    this.state.applicantDetails
                                      .applicantDocUploadStatus
                                  }
                                >
                                  <option value="">-- select --</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Info Requested">
                                    Info Requested
                                  </option>
                                  <option value="Ready">Ready</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </h2>

                        <div className="box-details">
                          <div className="form-row">
                            <div className="table-responsive">
                              <table class="table table-bordered table-cgreen mt-3">
                                <thead>
                                  <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Uploaded Date Time</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr id="photo_identification_id">
                                    <td>
                                      Photo Identification
                                      <div
                                        id="photo_identification_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="photo_identification_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="proof_of_address_id">
                                    <td>
                                      Proof of Address
                                      <div
                                        id="proof_of_address_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="proof_of_address_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="proof_of_income_id">
                                    <td>
                                      Proof of Income
                                      <div
                                        id="proof_of_income_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="proof_of_income_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="proof_of_income_loss_id">
                                    <td>
                                      Proof of Income loss
                                      <div
                                        id="proof_of_income_loss_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="proof_of_income_loss_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="lease_agreement_id">
                                    <td>
                                      Lease Agreement
                                      <div
                                        id="lease_agreement_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="lease_agreement_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="utility_gas_bill_id">
                                    <td>
                                      Utility Gas Bill
                                      <div
                                        id="utility_gas_bill_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="utility_gas_bill_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="utility_electricity_bill_id">
                                    <td>
                                      Utility Electricity Bill
                                      <div
                                        id="utility_electricity_bill_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="utility_electricity_bill_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="tenant_checkPoint_id_verification_page_id">
                                    <td>
                                      Tenant CheckPoint ID Verification Page{" "}
                                      <div
                                        id="tenant_checkPoint_id_verification_page_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="tenant_checkPoint_id_verification_page_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era3_tenant_checkPoint_id">
                                    <td>
                                      ERA 3 Tenant CheckPoint ID {" "}
                                      <div
                                        id="era3_tenant_checkPoint_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era3_tenant_checkPoint_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="tenant_payment_agreement_id">
                                    <td>
                                      Tenant Payment Agreement{" "}
                                      <div
                                        id="tenant_payment_agreement_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="tenant_payment_agreement_upload_id"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="box-gray mb-4">
                        {/* <h2 className="profile-title"><i class="fas fa-file mr-2"></i>Documents</h2> */}
                        <h2 class="profile-title d-md-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-file mr-2"></i>Landlord Documents
                          </span>
                          <div class="property-grid-add">
                            <div class="row">
                              <label
                                class="col-auto ml-3 pt-2"
                                style={{
                                  fontSize: "14px",
                                  color: "#000",
                                  textTransform: "initial",
                                  fontWeight: "500",
                                }}
                                style={{
                                  display:
                                    this.state
                                      .landlordInfoRequestedStatus_Original ===
                                      "Ready"
                                      ? "block"
                                      : "none",
                                }}
                                id="landlordSubmittedDateId"
                              >
                                Information Received:{" "}
                                {this.state
                                  .dateLandlordDocumentSubmitted_Formatted +
                                  " " +
                                  this.state.landlordDetails
                                    .timeLandlordDocumentSubmitted}
                              </label>
                              <label class="col-auto pt-2 px-0">
                                Document Status{" "}
                              </label>
                              <div class="col-auto">
                                <select
                                  class="form-control w-100"
                                  ref={(input) =>
                                  (this.landlordDocumentStatusSelector =
                                    input)
                                  }
                                  onChange={
                                    this.updateLandlordInfoRequestedStatus
                                  }
                                  value={
                                    this.state.landlordDetails
                                      .landlordInfoRequestedStatus
                                  }
                                >
                                  <option value="">-- select --</option>
                                  <option value="Pending">Pending</option>
                                  <option value="Info Requested">
                                    Info Requested
                                  </option>
                                  <option value="Reminder Sent">
                                    Reminder Sent
                                  </option>
                                  <option value="Ready">Ready</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </h2>
                        <div className="box-details">
                          <div className="form-row">
                            <div className="table-responsive">
                              <table class="table table-bordered table-cgreen mt-3">
                                <thead>
                                  <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Uploaded Date Time</th>
                                    <th scope="col">Action</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr id="landlord_w9_id">
                                    <td>
                                      Landlord W9
                                      <div
                                        id="landlord_w9_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="landlord_w9_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="landlord_driver_license_id">
                                    <td>
                                      Landlord Identification
                                      <div
                                        id="landlord_driver_license_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="landlord_driver_license_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="verified_checkpoint_id">
                                    <td>
                                      Landlord CheckPoint ID Verification Page{" "}
                                      <div
                                        id="verified_checkpoint_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="verified_checkpoint_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="ach_authorization_form_id">
                                    <td>
                                      Program Participation Form
                                      <div
                                        id="ach_authorization_form_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="ach_authorization_form_upload_id"
                                      />
                                    </td>
                                  </tr>

                                  <tr id="era2021ReCert_landlord_updated_w9_id">
                                    <td>
                                      Recert updated Landlord W9{" "}
                                      <div
                                        id="era2021ReCert_landlord_updated_w9_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2021ReCert_landlord_updated_w9_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2021ReCert_updated_program_participation_Form_id">
                                    <td>
                                      Recert updated Program Participation Form{" "}
                                      <div
                                        id="era2021ReCert_updated_program_participation_Form_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2021ReCert_updated_program_participation_Form_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2021ReCert_landlord_identity_id">
                                    <td>
                                      Recert Landlord Identity{" "}
                                      <div
                                        id="era2021ReCert_landlord_identity_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2021ReCert_landlord_identity_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2021ReCert_landlord_checkpoint_id">
                                    <td>
                                      Recert Landlord checkpoint ID Verification{" "}
                                      <div
                                        id="era2021ReCert_landlord_checkpoint_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2021ReCert_landlord_checkpoint_upload_id"
                                      />
                                    </td>
                                  </tr>

                                  <tr id="era2_landlord_updated_w9_id">
                                    <td>
                                      ERA2 updated Landlord W9{" "}
                                      <div
                                        id="era2_landlord_updated_w9_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2_landlord_updated_w9_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era3_landlord_updated_w9_id">
                                    <td>
                                      ERA3 updated Landlord W9{" "}
                                      <div
                                        id="era3_landlord_updated_w9_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era3_landlord_updated_w9_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2_landlord_identity_id">
                                    <td>
                                      ERA2 Landlord Identity{" "}
                                      <div
                                        id="era2_landlord_identity_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2_landlord_identity_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era3_landlord_identity_id">
                                    <td>
                                      ERA3 Landlord Identity{" "}
                                      <div
                                        id="era3_landlord_identity_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era3_landlord_identity_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2_landlord_checkpoint_id">
                                    <td>
                                      ERA2 Landlord checkpoint ID Verification{" "}
                                      <div
                                        id="era2_landlord_checkpoint_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2_landlord_checkpoint_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era3_landlord_checkpoint_id">
                                    <td>
                                      ERA3 Landlord checkpoint ID Verification{" "}
                                      <div
                                        id="era3_landlord_checkpoint_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era3_landlord_checkpoint_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era2_updated_program_participation_Form_id">
                                    <td>
                                      ERA2 updated Program Participation Form{" "}
                                      <div
                                        id="era2_updated_program_participation_Form_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era2_updated_program_participation_Form_upload_id"
                                      />
                                    </td>
                                  </tr>
                                  <tr id="era3_updated_program_participation_Form_id">
                                    <td>
                                      ERA3 updated Program Participation Form{" "}
                                      <div
                                        id="era3_updated_program_participation_Form_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="era3_updated_program_participation_Form_upload_id"
                                      />
                                    </td>
                                  </tr>

                                  <tr id="landlord_payment_agreement_id">
                                    <td>
                                      Landlord Payment Agreement{" "}
                                      <div
                                        id="landlord_payment_agreement_validation_id"
                                        class="alert-small-text"
                                        style={{ display: "none" }}
                                      >
                                        Document is required
                                      </div>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                      <input
                                        class="form-control-sm form-control-file pl-0"
                                        type="file"
                                        id="landlord_payment_agreement_upload_id"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* miss section start */}
                      <div className="box-gray mb-4">
                        <h2 class="profile-title d-flex align-items-center justify-content-between">
                          <span>
                            <i class="fas fa-file mr-2"></i>Miscellaneous Document
                          </span>
                          <span className="property-grid-add">
                            <button
                              type="button"
                              class="btn btn-primary"
                              onClick={this.openPopup.bind(this)}
                            >
                              <i class="fas fa-plus-circle text-white mr-1"></i>
                              Add
                            </button>
                          </span>
                        </h2>
                        <div className="box-details">
                          <div className="table-responsive">
                            <table 
                              className={
                                "table table-bordered table-cgreen mt-3 " +
                                (this.state.misceDocumentsList &&
                                  this.state.misceDocumentsList.length > 3
                                  ? "table-scroll"
                                  : "")
                              }
                            >
                              <thead>
                                <tr>
                                  <th width="25%" scope="col">
                                   Document
                                  </th>
                                  <th width="25%" scope="col">
                                    Uploaded Date Time
                                  </th>
                                  <th width="10%">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.misceDocumentsList && this.state.misceDocumentsList.length !== 0 ?
                                    (
                                      this.state.misceDocumentsList && this.state.misceDocumentsList.map(
                                        (member, index) => (
                                          <tr>
                                            <td width="25%">{member.shortFileName}</td>
                                            <td width="25%">{member.lastModified}</td>
                                            <td width="10%">
                                              {member.file ?
                                                <span><a target="_blank"
                                                  href={member.file}
                                                >
                                                  <i class="fas fa-eye mr-3"></i>
                                                </a>
                                                  <a href="javascript:void(0)" id={member.shortFileName} onClick={this.deleteS3FileMicDoc.bind(
                                                    this,
                                                    member.shortFileName
                                                  )} >
                                                    <i class="fas fa-trash-alt red"></i>
                                                  </a> </span> : ""}
                                            </td>
                                          </tr>
                                        )
                                      )) : (
                                    <p> No record </p>
                                  )
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* miss section end */}
                      <div className="box-gray mb-4">
                        <h2 className="profile-title">
                          <i class="fas fa-sticky-note mr-2"></i>Notes
                        </h2>
                        <div className="box-details">
                          <div className="form-row">
                            <div class="form-group col-md-6 col-xl-12">
                              <textarea
                                rows="20"
                                id="notesTextArea"
                                className="form-control h-auto"
                                defaultValue={this.state.applicantDetails.notes}
                                onChange={this.updateNotes}
                                value={this.state.applicantDetails.notes}
                              ></textarea>
                              <div class="alert-small-text">
                                {this.state.notes_Error}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          onChange={(e) => this.handlecheckboxtoggle(e)}
                          id="testRecordId"
                          checked={
                            this.state.applicantDetails.isTestRecord == 1
                              ? true
                              : false
                          }
                        />
                        <label class="custom-control-label" for="testRecordId">
                          Test Record
                        </label>
                      </div>

                      <div className="assistoc-speeddial">
                        {this.state.mode && this.state.mode == "Admin" ? (
                          <Tooltip title="Cancel" arrow>
                            <Button
                              type="button"
                              onClick={this.handleBack}
                              className="btn btn-primary whoc-float-btn"
                            >
                              <i className="fas fa-times"></i>
                            </Button>
                          </Tooltip>
                        ) : null}
                        {this.state.mode && this.state.mode == "Admin" ? (
                          <Tooltip title="Save" arrow>
                            <Button
                              type="button"
                              onClick={this.handleUpdate}
                              className="btn btn-secondary ml-2 whoc-float-btn"
                            >
                              <i className="fas fa-save"></i>
                            </Button>
                          </Tooltip>
                        ) : null}
                      </div>

                      <Backdrop
                        className="MuiBackdrop-root"
                        open={this.state.isLoading}
                      >
                        <CircularProgress />
                      </Backdrop>
                      <Snackbar
                        anchorOrigin={{
                          vertical: this.state.snackbarVertical,
                          horizontal: this.state.snackbarHorizontal,
                        }}
                        open={this.state.openSnackbar}
                        autoHideDuration={3000}
                        severity={this.state.snackbarSeverity}
                        onClose={() => this.setState({ openSnackbar: false })}
                        message={this.state.snackbarMessage}
                        className={this.state.snackbarMessageClass}
                        key={
                          this.state.snackbarVertical +
                          this.state.snackbarHorizontal
                        }
                      />
                      {/* tabindex="-1" remove payment month issue */}
                      <div
                        class="modal fade"
                        id="exampleModal"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <div class="modal-dialog modal-xl">
                          <div class="modal-content">
                            <div class="modal-header bg-primary">
                              <h5
                                class="modal-title text-white"
                                id="exampleModalLabel"
                              >
                                Manage reconciliation
                              </h5>
                              <button
                                type="button"
                                class="close text-white op-1"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={this.resetRec}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="modal-body">
                              <form id="recform">
                                <div class="form-row">
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Initial Receiver </label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        this.state.reconciliationMode == "edit"
                                          ? true
                                          : false
                                      }
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "entityType"
                                      )}
                                      value={
                                        this.state.reconciliation.entityType
                                      }

                                    >
                                      <option value="">-- select --</option>
                                      <option value="Landlord">Landlord</option>
                                      <option value="Individual">
                                        Individual
                                      </option>
                                    </select>
                                    <div
                                      class="alert-small-text"
                                      id="valEntityType"
                                    >
                                      {
                                        this.state.validationErrors[
                                        "entityType"
                                        ]
                                      }
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Paid By </label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        this.state.isChildRecord ? true : false
                                      }
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "initiallyPaidBy"
                                      )}
                                      value={
                                        this.state.reconciliation
                                          .initiallyPaidBy
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="OCUW">OCUW</option>
                                      <option value="3rd Party">
                                        3rd Party
                                      </option>
                                    </select>
                                    <div
                                      class="alert-small-text"
                                      id="valInitiallyPaidBy"
                                    >
                                      {
                                        this.state.validationErrors[
                                        "initiallyPaidBy"
                                        ]
                                      }
                                    </div>
                                  </div>

                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Transaction Type </label>
                                    <select
                                      className="form-control"
                                      disabled={
                                        this.state.isChildRecord ? true : false
                                      }
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "amountType"
                                      )}
                                      value={
                                        this.state.reconciliation.amountType
                                      }
                                    >
                                      <option value="">-- select --</option>
                                      <option value="Paid">Paid</option>
                                      <option value="Adjustment(Positive)">
                                        Adjustment (Positive)
                                      </option>
                                      <option value="Return">Return</option>
                                      <option value="Adjustment(Negative)">
                                        Adjustment (Negative)
                                      </option>
                                    </select>
                                    <div class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "amountType"
                                        ]
                                      }
                                    </div>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>
                                      Amount paid before reconciliation
                                    </label>
                                    <input
                                      disabled
                                      className="form-control"
                                      type="text"
                                      maxlength="100"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "amountCoveredBeforeRec"
                                      )}
                                      value={
                                        this.state.reconciliation
                                          .amountCoveredBeforeRec
                                      }
                                    ></input>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "amountCoveredBeforeRec"
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Amount (in $)</label>
                                    <input
                                      className="form-control"
                                      disabled={
                                        this.state.isChildRecord ? true : false
                                      }
                                      type="text"
                                      maxlength="100"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "amount"
                                      )}
                                      value={this.state.reconciliation.amount}
                                    ></input>
                                    <span class="alert-small-text">
                                      {this.state.validationErrors["amount"]}
                                    </span>
                                  </div>

                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>
                                      Amount paid after reconciliation
                                    </label>
                                    <input
                                      disabled
                                      className="form-control"
                                      type="text"
                                      maxlength="100"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "amountCoveredAfterRec"
                                      )}
                                      value={
                                        this.state.reconciliation
                                          .amountCoveredAfterRec
                                      }
                                    ></input>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "amountCoveredAfterRec"
                                        ]
                                      }
                                    </span>
                                  </div>

                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Payment Method </label>
                                    <input
                                      className="form-control"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "paymentMethod"
                                      )}
                                      value={
                                        this.state.reconciliation.paymentMethod
                                      }
                                      maxlength="100"
                                    ></input>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "paymentMethod"
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div className="form-group col-md-6 col-xl-3">
                                    <label>Memo - Property Address </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "memoPropertyAddress"
                                      )}
                                      maxlength="100"
                                      value={
                                        this.state.reconciliation
                                          .memoPropertyAddress
                                      }
                                    ></input>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "memoPropertyAddress"
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Other Payment Status</label>
                                    <select
                                      className="form-control"
                                      id="otherPaymentStatusIdRec"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "otherPaymentStatus"
                                      )}
                                      value={
                                        this.state.reconciliation
                                          .otherPaymentStatus
                                      }
                                    >
                                      {/* <option value="">-- select --</option> */}
                                      <option value="Pending">Pending</option>
                                      <option value="In-Review">
                                        In-Review
                                      </option>
                                      <option value="Approved for Payment">
                                        Approved for Payment
                                      </option>
                                      <option value="N/A">N/A</option>
                                      {/* <option value="Issued">Issued</option> */}
                                    </select>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "otherPaymentStatus"
                                        ]
                                      }
                                    </span>
                                  </div>
                                  <div class="form-group col-md-6 col-xl-3">
                                    <label>Transaction ID</label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      maxlength="100"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "transactionNo"
                                      )}
                                      value={
                                        this.state.reconciliation.transactionNo
                                      }
                                    ></input>
                                    <span class="alert-small-text">
                                      {
                                        this.state.validationErrors[
                                        "transactionNo"
                                        ]
                                      }
                                    </span>
                                  </div>

                                  {this.state.reconciliationMode == "edit" ? (
                                    <>
                                      <div class="form-group col-md-6 col-xl-3">
                                        <label>Date</label>
                                        <input
                                          disabled
                                          className="form-control"
                                          type="text"
                                          maxlength="100"
                                          value={
                                            this.state.reconciliation
                                              .dateCreated
                                          }
                                        ></input>
                                      </div>
                                      <div class="form-group col-md-6 col-xl-3">
                                        <label> Time</label>
                                        <input
                                          disabled
                                          className="form-control"
                                          type="text"
                                          maxlength="100"
                                          value={
                                            this.state.reconciliation
                                              .timeCreated
                                          }
                                        ></input>
                                      </div>
                                      <div class="form-group col-md-6 col-xl-3">
                                        <label>User</label>
                                        <input
                                          disabled
                                          className="form-control"
                                          type="text"
                                          maxlength="100"
                                          value={
                                            this.state.reconciliation
                                              .modifiedBy &&
                                              this.state.reconciliation
                                                .modifiedBy != ""
                                              ? this.state.reconciliation
                                                .modifiedBy
                                              : this.state.reconciliation
                                                .createdBy
                                          }
                                        ></input>
                                      </div>

                                      {this.state.reconciliation
                                        .otherPaymentStatus == this.state.otherPaymentStatus_Original && this.state.reconciliation
                                          .otherPaymentStatus == "Issued" ? (

                                        <>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>Date Issued</label>
                                            <input
                                              disabled
                                              className="form-control"
                                              type="text"
                                              maxlength="100"
                                              value={
                                                this.state.reconciliation
                                                  .dateStatusIssued
                                              }
                                            ></input>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label> Time Issued</label>
                                            <input
                                              disabled
                                              className="form-control"
                                              type="text"
                                              maxlength="100"
                                              value={
                                                this.state.reconciliation
                                                  .timeStatusIssued
                                              }
                                            ></input>
                                          </div>
                                        </>) : null}


                                    </>
                                  ) : null}
                                  <div className="form-group col-md-6 col-xl-3">
                                    <label>Payment Month 2021</label>
                                    {/* className={classes.formControl} */}
                                    <FormControl className={classes.formControlRec}>
                                      <Select className={classes.selectStyle}
                                        labelId="demo-mutiple-checkbox-label-rec"
                                        id="demo-mutiple-checkbox-rec"
                                        multiple
                                        value={this.state.paymentMonthsRec2021}
                                        name="first"
                                        // onChange={handleSelectChange}
                                        onChange={(e) => this.handleSelectRecChange(e)}
                                        //  input={<OutlinedInput label="Tag" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) =>
                                          selected.map((obj) => names[obj - 1].value).join(", ")
                                        }
                                      >
                                        {names.map((name) => (
                                          <MenuItem key={name.id} value={name.id}>
                                            <Checkbox checked={this.state.paymentMonthsRec2021 && this.state.paymentMonthsRec2021.indexOf(name.id) > -1} />
                                            <ListItemText primary={name.value} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  <div className="form-group col-md-6 col-xl-3">
                                    <label>Payment Month 2022</label>
                                    {/* className={classes.formControl} */}
                                    <FormControl className={classes.formControlRec}>
                                      <Select className={classes.selectStyle}
                                        labelId="demo-mutiple-checkbox-label-rec"
                                        id="demo-mutiple-checkbox-rec"
                                        multiple
                                        value={this.state.paymentMonthsRec2022}
                                        name="first"
                                        // onChange={handleSelectChange}
                                        onChange={(e) => this.handleSelect2022RecChange(e)}
                                        //  input={<OutlinedInput label="Tag" />}
                                        MenuProps={MenuProps}
                                        renderValue={(selected) =>
                                          selected.map((obj) => names[obj - 1].value).join(", ")
                                        }
                                      >
                                        {names.map((name) => (
                                          <MenuItem key={name.id} value={name.id}>
                                            <Checkbox checked={this.state.paymentMonthsRec2022 && this.state.paymentMonthsRec2022.indexOf(name.id) > -1} />
                                            <ListItemText primary={name.value} />
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </div>
                                  {this.state.reconciliationMode == "edit"  ? (           
                                  <div class="form-group col-md-6 col-xl-3">
                                            <label>
                                              Document Status
                                            </label>
                                            <select
                                              className="form-control"
                                              id="docStatusId"
                                              onChange={
                                                this
                                                  .onReconcillationDocumentStatusChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .documentStatus
                                              }
                                            >
                                              <option value="Pending">
                                                Pending
                                              </option>
                                              <option value="Info Requested">
                                                Info-Requested
                                              </option>
                                              <option value="Reminder Sent">
                                                Reminder Sent
                                              </option>
                                              <option value="Ready">
                                                Ready
                                              </option>
                                            </select>
                                          </div>
                                  ) : null }
                                  {/* payment of  recconcillation of  starts */}
                                  <div class="col-md-12">
                                    <hr />
                                  </div>
                                  {/* button of request bank */}
                                  {/*add note section */}
                                  {this.state.reconciliation.otherPaymentStatus !== "Issued" ? (
                                    <div className="col-md-12 assistoc-note">
                                      <p><i><span>Note:</span> Please always click 'Request Information' button after creating reconciliation record to notify recipient and create a transaction record.</i></p>
                                    </div>
                                  ) : (
                                    // Nothing to show here
                                    <span></span>
                                  )}
                                  <div class="form-group col-12">
                                    {this.state.reconciliation.otherPaymentStatus !== "Issued" ? (
                                      <div class="form-row">
                                        <div class="col-12 ">
                                          <div class="d-lg-flex align-items-center justify-content-end">
                                            <div class="d-lg-flex">
                                              {this.state.reconciliationMode ==
                                                "edit" ? (
                                                <>
                                                  <div>
                                                    <label
                                                      class=""
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#005191",
                                                        textTransform:
                                                          "initial",
                                                        fontWeight:
                                                          "700",
                                                        display:
                                                          this.state.reconciliation
                                                            .requestedBankInfo ===
                                                            true ||
                                                            this.state.reconciliation
                                                              .requestedBankInfo === 1
                                                            ? "block"
                                                            : "none",
                                                      }}

                                                      id="requestDateNewId"
                                                    >
                                                      Date Requested:{" "}
                                                      {this.state
                                                        .dateReconcilationRequestedNew_Formatted +
                                                        " " +
                                                        this.state.reconciliation
                                                          .timeBankInfoRequested}
                                                    </label>
                                                  </div>
                                                  <span class="mx-lg-2" style={{
                                                    display:
                                                      (this.state.reconciliation
                                                        .requestedBankInfo ===
                                                        true ||
                                                        this.state
                                                          .reconciliation
                                                          .requestedBankInfo ===
                                                        1) &&
                                                        this.state.reconciliation
                                                          .dateBankInfoReminded !=
                                                        "0000-00-00" &&
                                                        this.state.reconciliation
                                                          .dateBankInfoReminded !=
                                                        "1000-01-01" &&
                                                        this.state.reconciliation.entityType != "Individual" &&
                                                        this.state.reconciliation
                                                          .timeBankInfoReminded !=
                                                        null
                                                        ? "block"
                                                        : "none"
                                                  }}>|</span>
                                                  <div>
                                                    <label
                                                      class=""
                                                      style={{
                                                        fontSize: "14px",
                                                        color: "#005191",
                                                        textTransform:
                                                          "initial",
                                                        fontWeight:
                                                          "700",
                                                        display:
                                                          (this.state.reconciliation
                                                            .requestedBankInfo ===
                                                            true ||
                                                            this.state
                                                              .reconciliation
                                                              .requestedBankInfo ===
                                                            1) &&
                                                            this.state.reconciliation
                                                              .dateBankInfoReminded !=
                                                            "0000-00-00" &&
                                                            this.state.reconciliation
                                                              .dateBankInfoReminded !=
                                                            "1000-01-01" &&
                                                            this.state.reconciliation.entityType != "Individual" &&
                                                            this.state.reconciliation
                                                              .timeBankInfoReminded !=
                                                            null
                                                            ? "block"
                                                            : "none",
                                                      }}

                                                    // id="remindedDateId"
                                                    >
                                                      Date Reminded:{" "}
                                                      {this.state
                                                        .dateReconcilationReminded_Formatted +
                                                        " " +
                                                        this.state.reconciliation
                                                          .timeBankInfoReminded}
                                                    </label>
                                                  </div>
                                                </>
                                              ) : null}
                                            </div>
                                            <h2
                                              class=" d-md-flex align-items-center justify-content-between"
                                            // style={paymentreconcilationstyle}
                                            >
                                              <span className="property-grid-add">
                                                <button
                                                  type="button"
                                                  class="btn btn-primary"
                                                  onClick={this.savePaymentTransectionReconciliation.bind(
                                                    this
                                                  )}
                                                  style={{
                                                    display:
                                                      this.state.reconciliation
                                                        .requestedBankInfo ===
                                                        true ||
                                                        this.state.reconciliation
                                                          .requestedBankInfo === 1
                                                        ? "none"
                                                        : "block",
                                                  }}
                                                  id="requestLandlordBankInfoId"
                                                  disabled={
                                                    this.state.reconciliation
                                                      .entityType == "" ||
                                                    this.state.reconciliation
                                                      .initiallyPaidBy == "" ||
                                                    this.state.reconciliation
                                                      .amountType == "" ||
                                                    this.state.reconciliation
                                                      .amount == ""
                                                  }
                                                >
                                                  Request Information
                                                </button>

                                                {this.state.reconciliationMode ==
                                                  "edit" ? (
                                                  <button
                                                    type="button"
                                                    class="btn btn-primary btn-sm ml-lg-2 my-2 my-md-0"
                                                    onClick={this.sendReminderToreconciliation.bind(
                                                      this,
                                                      this.state.reconciliation.id
                                                    )}
                                                    style={{
                                                      display:
                                                        (this.state.reconciliation
                                                          .requestedBankInfo ===
                                                          true ||
                                                          this.state.reconciliation
                                                            .requestedBankInfo === 1) &&
                                                          this.state.reconciliation.entityType != "Individual"
                                                          ? "block"
                                                          : "none",
                                                    }}
                                                    id="sendReminderToLandlordId"
                                                  >
                                                    Send Reminder
                                                  </button>
                                                ) : null}
                                              </span>
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      // Nothing to show here
                                      <span></span>
                                    )}
                                    
                                    {/* button ends  request bank */}
                                    <div className="form-row">
                                      <div class="form-group col-md-6 col-xl-3 field-hightlight">
                                        <label>Payment Type </label>
                                        <select
                                          className="form-control"
                                          value={
                                            this.state.reconciliation
                                              .transactionType
                                          }
                                          onChange={(e) =>
                                            this.handleTransectionModeReconcillation(
                                              e
                                            )
                                          }
                                        >

                                          <option value="">-- select --</option>
                                          {this.state.reconciliation.entityType != "Individual" ?
                                            <option value="ACH">ACH</option> : null}

                                          <option value="Check">Check</option>
                                        </select>
                                      </div>
                                      {this.state.reconciliation
                                        .transactionType === "ACH" ? (
                                        <>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>
                                              Landlord Routing number{" "}
                                            </label>
                                            <input
                                              className="form-control"
                                              maxlength="9"
                                              type="text"
                                              //disabled={!this.state.isTransactionPermission || this.state.reconciliation.informationStatus == "Ready" || this.state.reconciliation.informationStatus == "Issued"}
                                              disabled={this.state.isTransactionPermission ? this.state.isRecRoutingNoOld : true}
                                              name="routeingNumber"
                                              ref={this.reconciliationEntityRoutingNumberRef}
                                              onChange={
                                                this
                                                  .onReconcillationRoutingNumberChange
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .entityRoutingNumber != "" &&
                                                  this.state.reconciliation
                                                    .entityRoutingNumber != null
                                                  ? (!this.state.isTransactionPermission ? `*******${this.state.reconciliation.entityRoutingNumber}` : `${this.state.reconciliation.entityRoutingNumber}`) 
                                                  : ""
                                              }
                                            ></input>
                                              <div className="alert-small-text">
                                                {this.state.reconciliationEntityRoutingNumber_Error}
                                              </div>
                                          </div>
                                          {/* yyy1 */}
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>
                                              Landlord account number{" "}
                                            </label>
                                            <input
                                              maxlength="17"
                                              className="form-control"
                                              type="text"
                                              //disabled={!this.state.isTransactionPermission || this.state.reconciliation.informationStatus == "Ready" || this.state.reconciliation.informationStatus == "Issued"}
                                              disabled={this.state.isTransactionPermission ? this.state.isRecAccountNoOld : true}
                                              name="bankAccountNumber"
                                              ref={this.reconciliationEntityAccountNumberRef}
                                              onChange={
                                                this
                                                  .onReconcillationBankAccountNumberChange
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .entityAccountNumber != "" &&
                                                  this.state.reconciliation
                                                    .entityAccountNumber != null
                                                  ? (!this.state.isTransactionPermission ? `*******${this.state.reconciliation.entityAccountNumber}` : `${this.state.reconciliation.entityAccountNumber}`) 
                                                  : ""
                                              }
                                            ></input>
                                              <div className="alert-small-text">
                                                {this.state.reconciliationEntityAccountNumber_Error}
                                              </div>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>Payee name </label>

                                            <input
                                              className="form-control"
                                              type="text"
                                              maxlength="50"
                                              name="payeeName"
                                              onChange={
                                                this
                                                  .onReconcillationPayeeNameChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .payeeName
                                              }
                                            ></input>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>Bank Name </label>
                                            <input
                                              className="form-control"
                                              maxlength="50"
                                              type="text"
                                              //disabled={!this.state.isTransactionPermission || this.state.reconciliation.informationStatus == "Ready" || this.state.reconciliation.informationStatus == "Issued"}
                                              disabled={this.state.isTransactionPermission ? this.state.isRecAccountNoOld : true}
                                              name="bankAccountName"
                                              onChange={
                                                this
                                                  .onReconcillationentityBankNameChange
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .entityBankName
                                              }
                                            ></input>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>Bank Account Type</label>
                                            <select
                                              className="form-control"
                                              onChange={
                                                this
                                                  .onReconcillationentityBankAccountTypeChange
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .entityAccountType
                                              }
                                            >
                                              <option value="">
                                                -- select --
                                              </option>
                                              <option value="Organization">
                                                Organization
                                              </option>
                                              <option value="Personal">
                                                Personal{" "}
                                              </option>
                                            </select>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>
                                              Bank Information Status
                                            </label>
                                            <select
                                              className="form-control"
                                              id="otherPaymentStatusId"
                                              ref={this.recInformationStatusRef}
                                              onChange={
                                                this
                                                  .onReconcillationInformationStatusChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .informationStatus
                                              }
                                            >
                                              <option value="">
                                                -- select --
                                              </option>
                                              <option value="Pending">
                                                Pending
                                              </option>
                                              <option value="Info Requested">
                                                Info-Requested
                                              </option>
                                              <option value="Reminder Sent">
                                                Reminder Sent
                                              </option>
                                              <option value="Ready">
                                                Ready
                                              </option>
                                              <option value="Issued">Issued</option>
                                            </select>
                                            <div className="alert-small-text">
                                              {this.state.reclandlordpaymentInformationStatus_Error}
                                            </div>
                                          </div>
                                        </>
                                      ) : null}
                                      {this.state.reconciliation
                                        .transactionType === "Check" ? (
                                        <>
                                          <div className="form-group col-md-6 col-xl-3">
                                            <label>Address</label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              value={
                                                this.state.reconciliation
                                                  .fullAddress
                                              }
                                              onChange={
                                                this
                                                  .onReconcillationFullAddressChanges
                                              }
                                              maxlength="855"
                                            ></input>
                                          </div>
                                          <div className="form-group col-md-6 col-xl-3">
                                            <label>Payee name </label>
                                            <input
                                              className="form-control"
                                              type="text"
                                              maxlength="100"
                                              onChange={
                                                this
                                                  .onReconcillationPayeeNamesChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .payeeName
                                              }
                                            ></input>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>
                                              Check Information Status
                                            </label>
                                            <select
                                              className="form-control"
                                              id="otherPaymentStatusId"
                                              onChange={
                                                this
                                                  .onReconcillationinformationStatusChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .informationStatus
                                              }
                                            >
                                              <option value="">
                                                -- select --
                                              </option>
                                              <option value="Pending">
                                                Pending
                                              </option>
                                              <option value="Ready">
                                                Ready
                                              </option>
                                              <option value="Issued">
                                                Issued
                                              </option>
                                            </select>
                                          </div>
                                          <div class="form-group col-md-6 col-xl-3">
                                            <label>Status </label>
                                            <select
                                              className="form-control"
                                              onChange={
                                                this
                                                  .onReconcillationCheckStatusChanges
                                              }
                                              value={
                                                this.state.reconciliation
                                                  .checkStatus
                                              }
                                            >
                                              <option value="">
                                                -- select --
                                              </option>
                                              <option value="issued">
                                                issued
                                              </option>
                                              <option value="bounced">
                                                bounced
                                              </option>
                                            </select>
                                          </div>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                  {/* payment  of reconcillation */}
                                  {/* additional document start*/}
                                  {/* &&  this.state.reconciliation.entityType != "Individual" */}
                                  {this.state.reconciliationMode == "edit" &&  this.state.reconciliation.entityType == "Landlord"  ? (
                                  <div className="table-responsive">
                                    <table class="table table-bordered table-cgreen mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Document</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Uploaded Date Time</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="rec_landlord_driver_license_id">
                                                <td>Driver’s License or other government issued photo ID </td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_driver_license_upload_id" />
                                                    <div id="rec_landlord_driver_license_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            <tr id="rec_landlord_w9_id">
                                                <td>Landlord W9 </td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_w9_upload_id" />
                                                    <div id="rec_landlord_w9_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            <tr id="rec_landlord_participationagreement_id">
                                                <td>Landlord Participation Agreement </td>
                                                <td></td>
                                                <td></td>
                                                <td><input class="form-control-sm form-control-file pl-0" type="file" id="rec_landlord_participationagreement_upload_id" />
                                                    <div id="rec_landlord_participationagreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> ) : null }
                                { this.state.reconciliationMode == "edit" &&  this.state.reconciliation.entityType != "Landlord"  ? (        
                                <div className="table-responsive">
                                    <table class="table table-bordered table-cgreen mt-3">
                                        <thead>
                                            <tr>
                                                <th scope="col">Document</th>
                                                <th scope="col">File Name</th>
                                                <th scope="col">Uploaded Date Time</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr id="rec_photo_identification_id">
                                                <td>Photo Identification <div id="rec_photo_identification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="rec_photo_identification_upload_id" />
                                                    <div id="rec_photo_identification_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                            
                                            <tr id="rec_tenant_payment_agreement_id">
                                                <td>Tenant Payment Agreement <div id="rec_tenant_payment_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                                <td></td>
                                                <td></td>
                                                <td>
                                                    <input class="form-control-sm form-control-file pl-0" type="file" id="rec_tenant_payment_agreement_upload_id" />
                                                    <div id="rec_tenant_payment_agreement_size_validation_id" class="alert-small-text" style={{ display: 'none' }} >File too big, please select a file less than 5 MB</div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>) : null }
                                         {/* additional document end*/}     
                                  <div class="form-group col-md-6 col-xl-12">
                                    <label>Notes</label>
                                    <textarea
                                      rows="8"
                                      id="notesTextArea"
                                      className="form-control h-auto"
                                      onChange={this.handleReconciliationChanges.bind(
                                        this,
                                        "notes"
                                      )}
                                      value={this.state.reconciliation.notes}
                                    ></textarea>
                                    <div class="alert-small-text">
                                      {this.state.validationErrors["notes"]}
                                    </div>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="modal-footer">
                              <button
                                type="button"
                                class="btn btn-primary"
                                onClick={this.saveReconciliation.bind(this)}
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* misce document*/}
                      <div
                        class="modal fade"
                        id="addDocumentsModal"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <div class="modal-dialog modal-xs modal-dialog-centered">
                          <div class="modal-content">
                            <div class="modal-header bg-primary">
                              <h5
                                class="modal-title text-white"
                                id="exampleModalLabel"
                              >
                                Miscellaneous Document
                              </h5>
                              <button
                                type="button"
                                class="close text-white op-1"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">

                              <div className="row">
                                <div className="col-12">
                                  
                                  <div class="row align-items-center">
                                    <label class="col-3 pr-0 col-form-label">File:</label>
                                    <div class="col-9">
                                      <input className="form-control-file form-control-sm pl-0 ml-0" type="file" ref={ref => (this.importFile = ref)} onChange={e => {
                                        if (this.importFile.files.length > 0) {
                                          this.setState({
                                            importFilePath: this.importFile.files[0],
                                            importFileName: this.importFile.files[0].name
                                          });
                                        }
                                        else {
                                          this.setState({
                                            importFilePath: null,
                                            importFileName: null
                                          });
                                        }
                                      }
                                      } />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="modal-footer">
                              <button type="button" className="btn btn-primary btn-sm" onClick={this.misceDocumentSubmit} disabled={this.importFile === null || this.importFile.files === null || this.importFile.files === undefined || this.importFile.files.length === 0} ><i class="fas fa-check mr-1"></i> Submit</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          {/* content area ends */}

          {/* </div>
                    </main> */}
          {/* main content ends */}
          <div
            class="modal fade"
            id="deleteModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header bg-primary">
                  <h5 class="modal-title text-white" id="exampleModalLabel">
                    Delete reconciliation
                  </h5>
                  <button
                    type="button"
                    class="close text-white op-1"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  Are you sure you want to delete this reconciliation record?
                </div>
                <div class="modal-footer">
                  {/* <button type="button" class="btn btn-secondary btn-light" data-dismiss="modal">Close</button> */}
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.performDelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={this.closeMsg}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

// export default EditSantaAnaApplicant;
export default withStyles(useStyles)(EditSantaAnaApplicant)