import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import Tooltip from '@material-ui/core/Tooltip';
import MUIDataTable from "mui-datatables";
import { API, Auth } from 'aws-amplify';
import TableSearch from "../TableSearch";
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as cloneDeep from 'lodash/cloneDeep';
import * as find from 'lodash/find';
import TableViewCol from "../TableViewCol";
import Moment from 'react-moment';
import {
    FormControl,
    ListItemText,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import { NavLink } from "react-router-dom";
import PropertyOwnerCustomToolbar from './propertyOwnerCustomToolbar';
import Backdrop from "@material-ui/core/Backdrop";

const headers = [
    "",
    "P.PropertyName",
    "OwnerName",
    "P.City",
    "P.State",
    "P.Zipcode",
    "Units",
    "P.Status",
    "P.PHAStatus",
    "P.ManagementCompany",
    "P.CreatedDate"
];

let showBkBtn = 'block';

class PropertyListing extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ID:'',
            columns: [
                {
                    name: " ",  // 1st column
                    label: " ",  // 1st column
                    options: {
                        download: false,
                        display: true,
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            let storageData = sessionStorage.setItem('ID', value);
                            return (
                                <NavLink
                                to={{ pathname: '/welcomehomeoc/property/edit',
                                state: { ID: value}}} onClick={(event) => this.setSessionStorageData(value)} >
                                    <div style={{
                                        width: "15px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        //textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {/* <a href=""><i class="fas fa-file-invoice-dollar text-primary"></i></a> */}
                                        <i class="fas fa-edit"></i>
                                    </div>
                                </NavLink>
                            );
                        },
                    },
                },
                {
                    name: "PropertyName",
                    label: "Property Name",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "OwnerName",
                    label: "Portal User",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "City",
                    label: "City",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "80px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "80px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "State",
                    label: "State",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['AL'], ['AK'], ['AR'], ['AZ'], ['CA'], ['CO'], ['CT'], ['DC'], ['DE'], ['FL'], ['GA'], ['HI'], ['IA'], ['ID'], ['IL'], ['IN'], ['KS'], ['KY'], ['LA'], ['MA'], ['MD'], ['ME'], ['MI'], ['MN'], ['MO'], ['MS'], ['MT'], ['NC'], ['NE'], ['NH'], ['NJ'], ['NM'], ['NV'], ['NY'], ['ND'], ['OH'], ['OK'], ['OR'], ['PA'], ['RI'], ['SC'], ['SD'], ['TN'], ['TX'], ['UT'], ['VT'], ['VA'], ['WA'], ['WI'], ['WV'], ['WY']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            State
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "40px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "40px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Zip",
                    label: "Zip",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Units",
                    label: "# Units",
                    options: {
                        download: true,
                        display: true,
                        filter: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "Status",
                    label: "Status",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Active'], ['Inactive']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "90px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "90px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "PHAStatus",
                    label: "PHA Status",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Registered'], ['Not registered'], ['In Progress']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            PHA Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "ManagementCompany",
                    label: "Property Management Company",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "120px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "CreatedDate",
                    label: "Created Date",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "145px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            <Moment format="MM/DD/YYYY HH:mm:ss">{value}</Moment>
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "145px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        <Moment format="MM/DD/YYYY HH:mm:ss">{value}</Moment>
                                    </div>
                                );
                        },
                    },
                },
            ],
            hideCompleted: 1,
            msg: "",
            email: null,
            showDetails: "none",
            showAlert: "none",
            dataheight: null,
            notes: "",
            cardValue: "500.00",
            rowSelected: [],
            data: [["Loading Data..."]],
            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'CreatedDate',                  // default sort by column dateUserCreated in desc order
                direction: 'desc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            selectedRowFirstName: '',
            selectedRowLastName: '',
            options: {
                download: false,
                downloadOptions: {
                    filename: 'propert.csv',
                    separator: ',',
                    filterOptions: {
                        useDisplayedColumnsOnly: true,          // downloads only columns that are visible (e.g. after filtering)
                        useDisplayedRowsOnly: false              // downloads only rows that are visible (e.g. after filtering)
                    }                                         // default false - downloads all data
                },
                filter: true,
                sort: true,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,

                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,
                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    //console.log('customFilterDialogFooter');
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },
                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    //console.log('onFilterConfirm');
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                    //console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    //console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    //console.log('onFilterChange');
                    //console.log("column: " + column);
                    //console.log("filterList: " + filterList);
                    //console.log("type: " + type);
                    this.setState({ filters: filterList });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        //console.log('updating filters via chip');
                        this.handleFilterSubmit(newFilters);
                    }
                },
                viewColumns: false,
                downloadCSV: false,
                pagination: true,
                selectableRows: false,
                count: 1,
                rowsPerPage: 50,
                rowsPerPageOptions: [10, 20, 50, 100, 200],
                sortOrder: {
                    name: 'CreatedDate',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },
                onTableChange: (action, tableState) => {

                    //console.log(action, tableState);

                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want

                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                        //console.log('action not handled - ' + action);
                    }
                },
                onViewColumnsChange: (column, action) => {
                    //console.log(column, action);
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                customToolbar: () => {
                    return (
                        <PropertyOwnerCustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} />
                    );
                },
                searchText: '',
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    //console.log('onSearchClose - ' + this.state.searchTimerID);
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (
                        <TableSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            onSearchClick={this.OnSearchClick}
                        />
                    );
                },
            }
        }

        this.showHTML = false;
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    // // target any icon
                    // "& > span:nth-child(4), & > button:nth-child(4)": {
                    //   order: 2
                    // }
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    //width:'200px'
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

    setSessionStorageData = (value) => {
        // event.preventDefault();
        console.log(value);
        localStorage.setItem('propertyId', value)
    }

    getData = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertylist';

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
            }
        }

        //console.log("JSON filterdict: " + JSON.stringify(filterdict));
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                    "hideCompleted": this.state.hideCompleted
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    postData = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    };

    componentDidMount = async () => {
        this.props.parentCallback("Property", "fas fa-home mr-2", "");
        this.setState({ isLoading: true });

        try {
            await Auth.currentAuthenticatedUser();
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
            this.signOut();
            return;
        }
        this.showHTML = true;

        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups.length === 1) {
            showBkBtn = "none";
        }
        // var height;
        // if (isMobile) {
        //     height = "60vh";
        // }
        // else {
        //     height = "70vh"
        // }
        var res = await this.getData();
        //console.log("this is from after check all",res);
        this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
    }

    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getData().then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
                isLoading: false,
            });
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
            });
        });
    }

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                sortOrder: sortOrder,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    }

    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name });
    }

    updateCardValue = (evt) => {
        var name = evt.target.value;
        this.setState({ cardValue: name });
    }

    close = () => {
        window.location.assign("/");
    }

    closeDetails = () => {
        document.body.classList.remove('modal-open');
        if (isMobile) {
            this.setState({ showDetails: "none", dataheight: "60vh", email: "", selectedRowFirstName: "", selectedRowLastName: "" });
        }
        else {
            this.setState({ showDetails: "none", email: "", selectedRowFirstName: "", selectedRowLastName: "" }); //,dataheight:"70vh"
        }
    }

    closeMsg = () => {
        this.setState({ showAlert: "none" });
    }

    OnSearchClick = async () => {
        //console.log("OnSearchClick!!! - " + this.state.searchText);
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        //console.log("Refresh!!!");
        var res = await this.getData();
        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        })
    }

    OnRefreshClick = async () => {
        //console.log("OnRefreshClick!!!");
        await this.refresh();
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        //console.log('handleFilterSubmit');
        //console.log('filterList: ' + filterList);
        this.setState({ isLoading: true, filters: filterList });

        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    }

    OnSearchTextChange(searchQuery) {
        //console.log('OnSearchTextChange - ' + searchQuery);

        this.setState({ searchText: searchQuery });

        // if (searchQuery !== null && searchQuery !== 'undefined') {
        //   clearTimeout(this.state.searchTimerID);
        //   this.setState({ searchTimerID: setTimeout(this.OnSearchIdleTimeout, 2000, searchQuery) });
        // }
        // else {
        //   this.setState({ searchText: '' });
        // }
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            //console.log('OnSearchIdleTimeout - ' + searchQuery);

            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery,
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getData();

            //console.log('SearchResult');
            //console.log(res.data);

            this.setState({
                isLoading: false,
                //handleSearch: 0,
                //searchText: '',
                data: res.data,
                page: res.page,
                count: res.total,
            });
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);

        //console.log('buildCSV');
        //console.log(columns);

        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);

        // const csv = options.onDownload
        //   ? options.onDownload(buildHead, buildBody, columns, data)
        //   : `${CSVHead}${CSVBody}`.trim();

        const csv = `${CSVHead}${CSVBody}`.trim();

        //console.log('csv');
        //console.log(csv);
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnDownloadClick = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
        });

        var res = await this.getData();
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });

        let dataDictionary = [];
        res.data.forEach((el, index) => dataDictionary.push({ index: index, data: el }));

        let dataToDownload = cloneDeep(dataDictionary);
        let columnsToDownload = columns;

        if (options.downloadOptions && options.downloadOptions.filterOptions) {
            // check rows first:
            if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
                dataToDownload = displayData.map((row, index) => {
                    let i = -1;

                    // Help to preserve sort order in custom render columns
                    row.index = index;

                    return {
                        data: row.data.map(column => {
                            i += 1;

                            // if we have a custom render, which will appear as a react element, we must grab the actual value from data
                            // that matches the dataIndex and column
                            // TODO: Create a utility function for checking whether or not something is a react object
                            return typeof column === 'object' && column !== null && !Array.isArray(column)
                                ? find(data, d => d.index === row.dataIndex).data[i]
                                : column;
                        }),
                    };
                });
            }

            // now, check columns:
            if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
                columnsToDownload = columns.filter((_, index) => _.options.display === true);

                dataToDownload = dataToDownload.map(row => {
                    row.data = row.data.filter((_, index) => columns[index].options.display === true);
                    return row;
                });
            }
        }
        this.createCSVDownload(columnsToDownload, dataToDownload, options);
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
        }
    }

    render() {
        if (this.showHTML === true) {
            this.state.options.page = this.state.page;
            this.state.options.sortOrder = this.state.sortOrder;
            this.state.options.count = this.state.count;
            this.state.options.rowsPerPage = this.state.rowsPerPage;
            this.state.options.searchText = this.state.searchText;

            return (
                <div className="data-section">
                    {/* <h3 className="page-title font-weight-bold text-uppercase text-primary" style={{ whiteSpace: 'nowrap' }}>
                    {this.state.pageimg && <img src={this.state.pageimg} alt="{this.state.pagetitle}" loading="lazy" />}
                    {this.state.pageicon && <i className={this.state.pageicon}></i>}
                    <span className="header-title">PROPERTY</span>
                </h3> */}
                    <h3 className="profile-title" style={{ whiteSpace: 'nowrap' }}>
                        <i class="fas fa-home mr-2"></i>
                        <span>PROPERTIES</span>
                        <div class="property-grid-add">
                            <a href="/welcomehomeoc/property/add" class="btn btn-primary btn-dark whoc-btn">
                                {/* <span type="button" class="btn btn-primary btn-dark whoc-btn"> */}
                                <i class="fas fa-plus-circle text-white mr-1"></i>Add</a></div>
                    </h3>
                    <div className="search-claims-form w-100 h-100 p-0 welcome-grid">
                        <div className="data-section">
                            <div className="data-table" style={{ height: this.state.dataheight }}>
                                <React.Fragment>
                                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                                        <CircularProgress />
                                    </Backdrop>
                                    {/* {this.state.isLoading && (
                                    <div className="yellow-loader" style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                                )} */}
                                    <div className="mui-table-border">
                                        <MuiThemeProvider theme={this.getMuiTheme()}>
                                            <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} components={{ TableViewCol: TableViewCol }} />
                                        </MuiThemeProvider>
                                    </div>
                                </React.Fragment>
                            </div>
                        </div>
                    </div>

                </div >
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}
export default PropertyListing;