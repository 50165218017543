import React from "react";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(
    (theme) => ({
        root: {
            padding: "16px 24px 16px 24px",
            fontFamily: "Roboto"
        },
        title: {
            marginLeft: "-7px",
            marginRight: "24px",
            fontSize: "14px",
            color: theme.palette.text.secondary,
            textAlign: "left",
            fontWeight: 500
        },
        formGroup: {
            marginTop: "8px"
        },
        formControl: {},
        checkbox: {
            padding: "0px",
            width: "32px",
            height: "32px"
        },
        checkboxRoot: {},
        checked: {},
        label: {
            fontSize: "15px",
            marginLeft: "8px",
            color: theme.palette.text.primary
        }
    }),
    { name: "MUIDataTableViewCol" }
);

const TableViewCol = ({
    columns,
    options,
    components = {},
    onColumnUpdate,
    updateColumns
}) => {
    const classes = useStyles();
    const textLabels = options.textLabels.viewColumns;
    const CheckboxComponent = components.Checkbox || Checkbox;

    const handleColChange = (index) => {
        onColumnUpdate(index);
    };

    const selectAll = (event) => {
        let checked = event.target.checked;
        var newColumns = columns.map((col) => {
            var newCol = Object.assign({}, col);
            if (checked) newCol.display = "true";
            else newCol.display = "false";
            return newCol;
        });
        updateColumns(newColumns);
    };

    return (
        <FormControl
            component={"fieldset"}
            className={classes.root}
            aria-label={textLabels.titleAria}
        >
            {/* <Typography variant="caption" className={classes.title}>
        {textLabels.title}
      </Typography> */}
            <FormControlLabel
                classes={{
                    root: classes.formControl,
                    label: classes.label
                }}
                control={
                    <CheckboxComponent
                        color="primary"
                        data-description="table-view-col"
                        className={classes.checkbox}
                        classes={{
                            root: classes.checkboxRoot,
                            //checked: classes.checked
                        }}
                        checked={columns.filter(c => c.display === "false").length === 0}
                        onChange={selectAll}
                    />
                }
                label={<strong>Select All</strong>}
            />
            <FormGroup className={classes.formGroup}>
                {/* <Button onClick={selectAll}>Show All</Button> */}
                {columns.map((column, index) => {
                    return (
                        column.display !== "excluded" &&
                        column.viewColumns !== false && (
                            <FormControlLabel
                                key={index}
                                classes={{
                                    root: classes.formControl,
                                    label: classes.label
                                }}
                                control={
                                    <CheckboxComponent
                                        color="primary"
                                        className={classes.checkbox}
                                        classes={{
                                            root: classes.checkboxRoot,
                                            checked: classes.checked
                                        }}
                                        onChange={() => handleColChange(index)}
                                        checked={column.display === "true"}
                                        value={column.name}
                                    />
                                }
                                label={column.label}
                            />
                        )
                    );
                })}
            </FormGroup>
        </FormControl>
    );
};

TableViewCol.propTypes = {
    /** Columns used to describe table */
    columns: PropTypes.array.isRequired,
    /** Options used to describe table */
    options: PropTypes.object.isRequired,
    /** Callback to trigger View column update */
    onColumnUpdate: PropTypes.func,
    /** Extend the style applied to components */
    classes: PropTypes.object
};

export default TableViewCol;
