import React from "react";
import { Auth, API } from 'aws-amplify';
import imgLogo from '../imgs/logo-assist-oc.png';
import imgHomeless from '../imgs/homeless.png';
import {
    Route,
    NavLink,
} from "react-router-dom";

class LandingPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            permissionsLoaded: false,
            permissions: [],
        }
    }

    componentDidMount = async () => {
        this.props.parentCallback("", "", "");
        if (this.state.permissionsLoaded === false) {
            try {
                await this.getCurrentUserPermissions();
                this.setState({ permissionsLoaded: true });
              } catch (error) {
                console.log(error);
                await this.signOut();
              }


            // var local = localStorage.getItem('permissions');
            // var permission = JSON.parse(local);
            // this.setState({ permissions: permission });

            // this.setState({ permissionsLoaded: true });
        }
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (this.state.permissionsLoaded === false) {
    //         var local = localStorage.getItem('permissions');
    //         var permission = JSON.parse(local);
    //         this.setState({ permissions: permission });

    //         this.setState({ permissionsLoaded: true });
    //     }
    // }

    getPermissions = async (e) => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = "/getuserpermissions";
        let myInit = {
          body: {
            info: {
              groupName: e,
            },
          },
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          },
        };
        return await API.post(apiName, path, myInit);
      };
    
      getCurrentUserPermissions = async () => {
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload["cognito:groups"];
        var res = await this.getPermissions(cognitogroups);
        this.setState({ permissions: res.permissions });
      };

    signOut = async () => {
        try {
            await Auth.signOut()
            this.setState({ permissions: [], permissionsLoaded: false });
            localStorage.removeItem('permissions');
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    selectProgram() {
        if (this.refs.programType) {
            if (this.refs.programType.value === "HPP") {
                window.location.assign("/assistoc/searchclaim");
            }
            else if (this.refs.programType.value === "Irvine") {
                window.location.assign("/assistoc/irvineclaim");
            }
            else if (this.refs.programType.value === "Santa") {
                window.location.assign("/assistoc/santaanaclaim");
            }
            else {
                window.location.assign("/assistoc/ceppclaim");
            }
        }
        else {
            window.location.assign("/assistoc/searchclaim");
        }
    }

    render() {
        document.body.classList.remove('dashboard');

        if (this.state.permissionsLoaded === true) {
            return (
                <div className="card-shadow mb-5 mb-md-0">
                    <div className="row">
                        <div className="col-md-6 first text-center">
                            <div className="welcome-box">
                                <p className="text-welcome">Welcome Admins!</p>
                                <h2 className="text-primary title mb-0">COVID-19 Homeless Prevention Program</h2>
                                <hr />
                                <div class="row">
                                    <div className="col-12">
                                        {/* {this.state.permissions && this.state.permissions.includes('HPPClaim') ?
                                            <div class="form-group  mb-3">
                                                <button className="btn btn-secondary landing-btn"><NavLink to="/assistoc/registeruser">Register Assist OC Users<i className="fas fa-user-plus ml-2"></i></NavLink></button>
                                            </div>
                                            : null
                                        } */}
                                        <div class="form-group landing-btn">
                                            <select class="form-control" ref="programType">
                                                {/* {this.state.permissions && this.state.permissions.includes('HPPClaim') ?
                                                    <option value="HPP">Search Claim HPP</option>
                                                    : null
                                                } */}
                                                {this.state.permissions && (this.state.permissions.includes('CEPPClaim') || this.state.permissions.includes('CEPPClaimUser')) ?
                                                    <option value="CEPP">Search Claim ERA-OC</option>
                                                    : null
                                                }
                                                {this.state.permissions && (this.state.permissions.includes('IrvineClaim') || this.state.permissions.includes('IrvineClaimUser')) ?
                                                    <option value="Irvine">Search Claim Irvine</option>
                                                    : null
                                                }
                                                {this.state.permissions && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ?
                                                    <option value="Santa">Search Claim Santa Ana</option>
                                                    : null
                                                }
                                            </select>
                                            <button className="btn btn-primary mt-2" onClick={this.selectProgram.bind(this)}>Go <i class="fas fa-arrow-right ml-2"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <p className="text-primary">Powered by Orange County United Way</p>
                            </div>
                        </div>
                        <div className="col-md-6 order-md-first">
                            <div className="hero-image" style={{ backgroundImage: "url(" + imgHomeless + ")" }}>
                                <img src={imgHomeless} className="d-block d-md-none img-fluid w-100" alt="homeless person image" loading="lazy" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return null;
        }
    }
}

export default LandingPage;
