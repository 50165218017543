import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from '@material-ui/icons/Refresh';
import { withStyles } from "@material-ui/core/styles";
// import ToggleOnIcon from '@material-ui/icons/ToggleOn';
// import ToggleOffIcon from '@material-ui/icons/ToggleOff';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class PropertyOwnerCustomToolbar extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: true,
        }
    }

    handleRefreshClick = async () => {
        //console.log("clicked on refresh icon!");
        this.props.refreshCallback();
    }

    // handleInvitePropertyOwnerSubmit = async () => {
    //   //console.log("clicked on refresh icon!");
    //   this.props.handleInvitePropertyOwnerCallback();
    // }

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Tooltip title={"Refresh"}>
                    <IconButton className={classes.iconButton} onClick={this.handleRefreshClick}>
                        <RefreshIcon className={classes.deleteIcon} />
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title={"Invite Property Owner"}>
          <Button  className={classes.Button} onClick={() => this.handleInvitePropertyOwnerSubmit()}>Invite Property Owner</Button>
        </Tooltip> */}
            </React.Fragment>
        );
    }
}

export default withStyles(defaultToolbarStyles, { name: "PropertyOwnerCustomToolbar" })(PropertyOwnerCustomToolbar);
