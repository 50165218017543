import React from "react";
import homeBG from '../imgs/property-img.jpg';
import { Button } from '@material-ui/core';
import { API, Storage, Auth } from 'aws-amplify';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createStore, combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { withRouter } from "react-router";
import $ from "jquery";
const reducer = combineReducers({
    form: reduxFormReducer,
});

const store = (window.devToolsExtension
    ? window.devToolsExtension()(createStore)
    : createStore)(reducer);
const filter = createFilterOptions();
class AddProperty extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            propertyDetails: {},
            managers: [],
            representatives: [{ "ContactType": "Represent", "Emailid": "", "FirstName": "", "LastName": "", "ManagerID": 0, "Phone": "", "PhoneExtension": "", "Title": "", "isDeleted": false }],
            
            isPHARegistered: "false",
            registerPHANow: "false",

            formFields: {},
            validationErrors: {},

            w9Uploaded: false,
            otherPHAName: "",

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            imageUploaded: 0,
            selectedFile: null,
            selectedManagementCompany: null,
            managementCompanies: [],

            selectedPortalUserCentralContactId: '',

            selectedPropertyOwner: null,
            centralContacts: [],
            centralContactPortalUsers: [],
            centralContactManagerUsers: [],

            selectedPropertyManager: null,
            isMangerSelected: false,
            isAddManager: false,
            managerIndex: 0,
            managerList: [],
            
            owner: {},
            po_FirstName: "",
            po_LastName: "",
            po_Address: "",
            po_City: "",
            po_State: "",
            po_Zip: "",
            po_EmailID: "",
            po_Phone: "",
            po_AirtablePeoplePropId: "",
            po_SalesforceContactId: "",
            po_ManagementCompanyId: "",
            po_ManagementCompanyName: "",
            po_AirtableCompanyId: "",
            po_SalesforceCompanyId: "",

            po_isPOEdited: false,

            ma_FirstName: "",
            ma_LastName: "",
            ma_EmailID: "",
            ma_Phone: "",
            ma_PhoneExtension: "",
            ma_AdditionalPhone: "",
            ma_AirtablePeoplePropId: "",
            ma_SalesforceContactId: "",
            managerValidationErrors: {},
            ma_FirstNameError: false,
            ma_LastNameError: false,
            ma_EmailIDError: false,
            ma_PhoneError: false,
            ma_PhoneExtensionError: false,
            ma_AdditionalPhoneError: false,
        }

        this.propertyImage_Upload = null;

        this.leaseDocument_Upload = null;
        this.w9Document_Upload = null;
        this.ruleDocument_Upload = null;
        this.supplementDocument_Upload = null;
        this.leadbasedpaintDocument_Upload = null;

        this.showHTML = false;
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        this.showHTML = true;
        var manaCompResponse = await this.getManagementCompanies();
        if (manaCompResponse.total > 0) {
            manaCompResponse.data.forEach(e => {
                this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] + "-" + e[3] });
            });
        }
        var centralContactResponse = await this.getCentralContacts();
        if (centralContactResponse.length > 0) {
            centralContactResponse.forEach(e => {
                this.state.centralContacts.push({title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'po_'+ e['CentralContactId']});
                this.state.centralContactManagerUsers.push({title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'mo_'+ e['CentralContactId']});
                
            });
            centralContactResponse.filter(item => item['IsPortalUser']).forEach(e => {
                this.state.centralContactPortalUsers.push({title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: e['CentralContactId'], SalesforceContactId: e['SalesforceContactId']});
            });
        }
        $('[id*=propOwner_]').attr('disabled', 'disabled');
        this.setState({ isLoading: false });
    }

    addProperty = async (e) => {
        this.setState({ isLoading: true });
        e.preventDefault();
        if (!this.validateControls()) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            return;
        }
        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        if (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined) {
            this.state.propertyDetails.ManagementCompany = this.state.selectedManagementCompany;
        } else {
            this.state.propertyDetails.ManagementCompany = null;
        }
        this.state.propertyDetails.PortalUserCentralContactId = this.state.selectedPortalUserCentralContactId.split('_')[1];
        this.state.propertyDetails.PortalUserSalesforceContactId = this.state.selectedPortalUserCentralContactId.split('_')[0];

        //----------New: Start-----
        this.state.propertyDetails.PropertyOwnerCentralContactId = (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != '0') ? this.state.selectedPropertyOwner.Id.split('_')[1] : '0';
        if (this.state.po_isPOEdited) {
            this.state.owner.po_FirstName = this.state.po_FirstName;
            this.state.owner.po_LastName = this.state.po_LastName;
            this.state.owner.po_EmailID = this.state.po_EmailID;
            this.state.owner.po_Phone = this.state.po_Phone;
            this.state.owner.po_Address = this.state.po_Address;
            this.state.owner.po_City = this.state.po_City;
            this.state.owner.po_State = this.state.po_State;
            this.state.owner.po_Zip = this.state.po_Zip;
            this.state.owner.po_isPOEdited = this.state.po_isPOEdited;
            this.state.owner.po_AirtablePeoplePropId = this.state.po_AirtablePeoplePropId;
            this.state.owner.po_SalesforceContactId = this.state.po_SalesforceContactId;
            this.state.owner.po_ManagementCompanyId = this.state.po_ManagementCompanyId;
            this.state.owner.po_ManagementCompanyName = this.state.po_ManagementCompanyName;
            this.state.owner.po_AirtableCompanyId = this.state.po_AirtableCompanyId;
            this.state.owner.po_SalesforceCompanyId = this.state.po_SalesforceCompanyId;
            this.state.propertyDetails.owner = this.state.owner;
        }

        var propertyDetailsModel = this.state.propertyDetails;
        propertyDetailsModel.ModifiedBy = adminUser;
        propertyDetailsModel.CreatedBy = adminUser;
        propertyDetailsModel.Status = 'Active';   // Make it Active by default.
        propertyDetailsModel.IsPHARegistered = this.state.isPHARegistered === "true" ? true : false;
        if (this.state.isPHARegistered === "true") {
            if (propertyDetailsModel.IsPHAAuthorityOther == true && this.state.otherPHAName !== "") {
               propertyDetailsModel.PHAAuthorityOtherTitle = this.state.otherPHAName;
            }
            propertyDetailsModel.PHAStatus = "Registered";
        } else {
            propertyDetailsModel.PHAStatus = "Not Registered";
            propertyDetailsModel.WantToPHARegisterProperty = true;  // Now, or may be later
        }
        propertyDetailsModel.Phone = (this.state.propertyDetails.Phone === null || this.state.propertyDetails.Phone === undefined) ? '' :
            this.state.propertyDetails.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

        let managersNRepresentatives_Copy = [];
        for (var i = 0; this.state.managerList.length > i; i++) {
            var updatedManager = this.state.managerList[i];
            updatedManager.Phone = (updatedManager.Phone === null || updatedManager.Phone === undefined) ? '' : updatedManager.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');
            updatedManager.AdditionalPhone = (updatedManager.AdditionalPhone === null || updatedManager.AdditionalPhone === undefined) ? '' : updatedManager.AdditionalPhone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '');

            managersNRepresentatives_Copy.push(updatedManager);
        }
        propertyDetailsModel.Managers = managersNRepresentatives_Copy;
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/addproperty';
        let myInit = {
            body: propertyDetailsModel,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        //await API.post(apiName, path, myInit);
        let isSuccess = false;
        let salesforceManCompId = '';
        let salesforcePropOwnId = '';

        await API.post(apiName, path, myInit).then(result => {
            if (result.status === true) {
                isSuccess = true;
                salesforceManCompId = result.SalesforceManagementCompanyId;
                this.state.propertyDetails.PropertyID = +result.message;
                this.setState({ snackbarMessage: 'Property added successfully.', openSnackbar: true, snackbarMessageClass: 'Mui-filledSuccess' });
                
            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError'});
            }
        })
            .catch(error => {
                console.log(error);
                this.setState({ openSnackbar: true, snackbarMessage: 'Something went wrong, please contact Administrator.', snackbarMessageClass: 'Mui-filledError'});
            });

        if (isSuccess) {
            //upload document
            if (this.state.propertyDetails.LeaseDocumentName) {
                await this.uploadPropertyDocument('LeaseDocumentStatus');
            }
            if (this.state.propertyDetails.RuleDocumentName) {
                await this.uploadPropertyDocument('RuleDocumentStatus');
            }
            if (this.state.propertyDetails.SupplementDocumentName) {
                await this.uploadPropertyDocument('SupplementDocumentStatus');
            }
            if (this.state.propertyDetails.LeadBasedPaintDocumentName) {
                await this.uploadPropertyDocument('LeadBasedPaintDocumentStatus');
            }
            if (this.state.propertyDetails.W9DocumentName) {
                await this.uploadPropertyDocument('W9DocumentStatus');
            }
            if (this.state.propertyDetails.ImageName) {
                await this.uploadPropertyImage();
            }
            //this.setState({ isLoading: false });
            let PHARegisteredList = [];
            if (propertyDetailsModel.IsPHARegistered)
            {
                if (propertyDetailsModel.IsPHAAuthorityAHA)
                    PHARegisteredList.push("AHA");
                if (propertyDetailsModel.IsPHAAuthorityOCHA)
                    PHARegisteredList.push("OCHA");
                if (propertyDetailsModel.IsPHAAuthoritySAHA)
                    PHARegisteredList.push("SAHA");
                if (propertyDetailsModel.IsPHAAuthorityGGHA)
                    PHARegisteredList.push("GGHA");
                if (propertyDetailsModel.IsPHAAuthorityOther && propertyDetailsModel.PHAAuthorityOtherTitle !== undefined)
                    PHARegisteredList.push(propertyDetailsModel.PHAAuthorityOtherTitle);
            }
            
            let cities = ['Aliso Viejo', 'Anaheim', 'Anaheim Hills', 'Brea', 'Buena Park', 'Costa Mesa', 'Cypress',
            'Dana Point', 'Foothill Ranch', 'Fountain Valley', 'Fullerton', 'Garden Grove', 'Huntington Beach',
            'Irvine', 'La Habra', 'La Palma', 'Ladera Ranch', 'Laguna Beach', 'Laguna Hills', 'Laguna Niguel',
            'Laguna Woods', 'Lake Forest', 'Los Alamitos', 'Midway City', 'Mission Viejo', 'Newport Beach',
            'Newport Coast', 'Orange', 'Placentia', 'Rancho Santa Margarita', 'San Clemente', 'San Juan Capistrano',
            'Santa Ana', 'Seal Beach', 'Stanton', 'Tustin', 'Villa Park', 'Westminster', 'Yorba Linda', 'Capistrano Beach',
            'Corona Del Mar', 'Coto de Caza', 'Diamond Bar', 'Silverado Canyon', 'Trabuco Canyon'];
            let isCityMatch = false;

            if (cities.indexOf(propertyDetailsModel.City) !== -1) {
                isCityMatch = true;
            } else {
                isCityMatch = false;
            }
            await this.loadFileToS3(
                [
                    {
                        "Account Type": "Community", "PropertyName": propertyDetailsModel.PropertyName, 
                        "City": isCityMatch ? propertyDetailsModel.City : "Other", "Othercity": isCityMatch ? "" : propertyDetailsModel.City,
                        "PHARegisteredList": PHARegisteredList.join(';'), "Address": propertyDetailsModel.Address,
                        "Zipcode": propertyDetailsModel.Zipcode, "Status": propertyDetailsModel.Status,
                        "pyei_Name": propertyDetailsModel.pyei_Name, "pyei_Address": propertyDetailsModel.pyei_Address,
                        "pyei_City":propertyDetailsModel.pyei_City,"pyei_State":propertyDetailsModel.pyei_State,"pyei_Zip":propertyDetailsModel.pyei_Zip,
                        "W9DocumentStatus": (propertyDetailsModel.W9DocumentName != undefined && propertyDetailsModel.W9DocumentName !== '' && propertyDetailsModel.W9DocumentName !== null) ? "true" : "false",
                        "ManagementCompany": propertyDetailsModel.ManagementCompany !== null ? propertyDetailsModel.ManagementCompany.title : "",
                        "AWS_PropertyID": propertyDetailsModel.PropertyID, "AWS_UpdatedBy": "AWS", 
                        "SalesforceCompanyId" : salesforceManCompId ? salesforceManCompId : (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined && this.state.selectedManagementCompany.Id.split('-').length === 3 ? this.state.selectedManagementCompany.Id.split('-')[2] : null),
                        "Payment_Standards__c": "Basic",
                        "Suite": propertyDetailsModel.Unit,
                        "pyei_Suite":propertyDetailsModel.pyei_Unit,
                        "RecordTypeId": process.env.REACT_APP_CommunityRecordTypeId //"0124x000000yltHAAQ"
                    },
                ]
            );

            if (this.state.isPHARegistered !== "true" && this.state.registerPHANow === "true") {
                // window.location.assign(`/welcomehomeoc/pharegistration/${this.state.propertyDetails.PropertyID}`);
                this.props.history.push({
                    pathname: '/welcomehomeoc/pharegistration',
                    state: { ID: this.state.propertyDetails.PropertyID }
                })
            } else {
                window.location.assign("/welcomehomeoc/properties");
            }

        }
        this.setState({ isLoading: false });
    }

    loadFileToS3 = async(items) => {

      // Convert JSON to CSV & Display CSV
      var s = this.convertToCSV(items);
      try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            var issueDateTime = new Date(
                new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
              );
            var todaysDate = [
                issueDateTime.getFullYear(),
                ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                ("0" + issueDateTime.getDate()).slice(-2),
              ].join("-");
        
              var todaysTime = [
                ("0" + issueDateTime.getHours()).slice(-2),
                ("0" + issueDateTime.getMinutes()).slice(-2),
                ("0" + issueDateTime.getSeconds()).slice(-2),
              ].join(":");
            var fileName = "Property_"+ items[0].AWS_PropertyID + "_"+ todaysDate + "_" +todaysTime + ".csv";
            var result1 = await Storage.put(`Salesforce/Create-Property/${fileName}`,
            s,
            { contentType: "csv" });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }

    convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)

        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    validateControls() {
        let propertyDetailFields = this.state.propertyDetails;
        let formIsValid = true;
        
        let errors = {};
        if (!propertyDetailFields["PropertyName"]) {
            formIsValid = false;
            errors["PropertyName"] = "Property Name is required";
        }
        if (!propertyDetailFields["Address"]) {
            formIsValid = false;
            errors["Address"] = "Address is required";
        }
        if (this.state.po_EmailID === null || this.state.po_EmailID === "") {
            formIsValid = false;
            errors["po_EmailID"] = "Email is required";
            $('#propOwner_EmailID').removeAttr('disabled');
        }
        else if (this.state.po_EmailID !== undefined) {
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern4.test(this.state.po_EmailID)) {
                formIsValid = false;
                errors["po_EmailID"] = "Invalid email";
                $('#propOwner_EmailID').removeAttr('disabled');
            }
        }

        if (this.state.po_Phone !== undefined && this.state.po_Phone !== null && this.state.po_Phone !== "") {
            if ((this.state.po_Phone.length < 10)  || !this.state.po_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                formIsValid = false;
                errors["po_Phone"] = "Invalid phone";
            }
        }

        if (this.state.po_LastName === null || this.state.po_LastName === "") {
            formIsValid = false;
            errors["po_LastName"] = "Last name is required";
            $('#propOwner_LastName').removeAttr('disabled');
        }

        if (this.state.po_Zip !== "" && this.state.po_Zip !== null && this.state.po_Zip !== undefined) {
            if (/^\d+$/.test(this.state.po_Zip) !== true) {
                errors["po_Zip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        if (this.state.po_City !== "" && this.state.po_City !== null && this.state.po_City !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.po_City)) {
                errors["po_City"] = "Invalid City";
                formIsValid = false;
            }
        }
        
        if (!propertyDetailFields["City"]) {
            formIsValid = false;
            errors["City"] = "City is required";
        }
       

        if (!this.state.selectedPortalUserCentralContactId || this.state.selectedPortalUserCentralContactId === '' || this.state.selectedPortalUserCentralContactId === "Please Select") {
            formIsValid = false;
            errors["selectedPortalUserCentralContactId"] = "Portal user is required";
        }

        if (propertyDetailFields["City"] !== "" && propertyDetailFields["City"] !== null && propertyDetailFields["City"] !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(propertyDetailFields["City"])) {
                errors["City"] = "Invalid City";
                formIsValid = false;
            }
        }
        if (propertyDetailFields["pyei_City"] !== "" && propertyDetailFields["pyei_City"] !== null && propertyDetailFields["pyei_City"] !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(propertyDetailFields["pyei_City"])) {
                errors["pyei_City"] = "Invalid City";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Zipcode"] !== "" && propertyDetailFields["Zipcode"] !== null && propertyDetailFields["Zipcode"] !== undefined) {
            if (/^\d+$/.test(propertyDetailFields["Zipcode"]) !== true) {
                errors["Zipcode"] = "Invalid Zip";
                formIsValid = false;
            }
        }
        if (propertyDetailFields["pyei_Zip"] !== "" && propertyDetailFields["pyei_Zip"] !== null && propertyDetailFields["pyei_Zip"] !== undefined) {
            if (/^\d+$/.test(propertyDetailFields["pyei_Zip"]) !== true) {
                errors["pyei_Zip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Phone"] !== "" && propertyDetailFields["Phone"] !== null && propertyDetailFields["Phone"] !== undefined) {
            if (!propertyDetailFields["Phone"].match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["Phone"] = "Invalid Phone";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["PhoneExtension"] !== "" && propertyDetailFields["PhoneExtension"] !== null && propertyDetailFields["PhoneExtension"] !== undefined) {
            if (/^[\d\(\)\+]+$/.test(propertyDetailFields["PhoneExtension"]) !== true) {
                errors["PhoneExtension"] = "Invalid Extension";
                formIsValid = false;
            }
        }

        if (propertyDetailFields["Email"] !== "" && propertyDetailFields["Email"] !== null && propertyDetailFields["Email"] !== undefined) {
            var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!secondaryEmailPattern.test(propertyDetailFields["Email"])) {
                errors["Email"] = "Invalid Email";
                formIsValid = false;
            }
        }
        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    uploadPropertyDocument = async (type) => {
        var file = null;
        var documentFolder = "";
        if (type === 'RuleDocumentStatus' && this.ruleDocument_Upload !== null) {
            file = this.ruleDocument_Upload;
            documentFolder = "rule_document";
        }
        else if (type === 'SupplementDocumentStatus' && this.supplementDocument_Upload !== null) {
            file = this.supplementDocument_Upload;
            documentFolder = "supplement_document";
        }
        else if (type === 'LeadBasedPaintDocumentStatus' && this.leadbasedpaintDocument_Upload !== null) {
            file = this.leadbasedpaintDocument_Upload;
            documentFolder = "leadbasedpaint_document";
        }
        else if (type === 'LeaseDocumentStatus' && this.leaseDocument_Upload !== null) {
            file = this.leaseDocument_Upload;
            documentFolder = "lease_document";
        }
        else if (type === 'W9DocumentStatus' && this.w9Document_Upload !== null) {
            file = this.w9Document_Upload;
            documentFolder = "w9_document";
        }

        if (file !== null) {
            try {
                Storage.configure({
                    bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                    region: process.env.REACT_APP_Region,
                    level: 'public',
                });

                var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Documents/${documentFolder}/${file.name}`,   
                    file,
                    { contentType: file.type });
                if (result) {
                    // update path in db
                    var response = await Storage.get(`Properties/${this.state.propertyDetails.PropertyID}/Documents/${documentFolder}/${file.name}`, { download: true });
                    await this.updatePropertyDocument(type, file.name, result.key, response.VersionId, file.type);
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    }

    updatePropertyDocument = async (type, name, docPath, versionId, contentType) => {
        try {
            var user = await this.getCurrentUser();
            var model = {
                propertyID: this.state.propertyDetails.PropertyID,
                documerntType: type,
                status: false,
                documentName: name,
                documentPath: docPath,
                ModifiedBy: user.attributes.email,
                versionId: versionId,
                contentType: contentType
            }
            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
            let path = '/updatepropertydocument';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            let result = await API.post(apiName, path, myInit);
            if (result.status === true) {
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    uploadPropertyImage = async () => {
        if (this.propertyImage_Upload !== null) {
            var file = this.propertyImage_Upload;
            try {
                Storage.configure({
                    bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                    region: process.env.REACT_APP_Region,
                    level: 'public',
                });

                var result = await Storage.put(`Properties/${this.state.propertyDetails.PropertyID}/Images/${file.name}`,    
                file,
                    { contentType: file.type });
                if (result) {
                    // update path in db
                    await this.updatePropertyImage(file.name, result.key);
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    updatePropertyImage = async (name, path) => {
        var model = {
            propertyID: this.state.propertyDetails.PropertyID,
            imageName: name,
            imagePath: path
        }

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;

            let path = '/updatepropertyimage';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            let result = await API.post(apiName, path, myInit);
            if (result.status === true) {
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    closeSnackbar = () => {
        this.setState({ openSnackbar: false });
    }

    OnIsPHARegisteredRadioButtonChanged(event) {
        let fields = this.state.formFields;
        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields, isPHARegistered: event.target.value });
    }

    OnRegisterPHANowRadioButtonChanged(event) {
        let fields = this.state.formFields;
        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields, registerPHANow: event.target.value });
    }

    OnFormFieldChanged(field, e) {
        const currentState = this.state.propertyDetails;
        const { value } = e.target;
        currentState[field] = value;
        this.setState({ propertyDetails: currentState });
    }
    // zzz
    OnFormPo_FirstNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_FirstName: value });
    }
    OnFormPo_LastNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_LastName: value, po_isPOEdited: true  });
    }
    OnFormPo_AddressFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Address: value });
    }
    OnFormPo_CityFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_City: value });
    }
    OnFormPo_StateFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_State: value });
    }
    OnFormPo_ZipFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Zip: value });
    }
    OnFormPo_EmailIDFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_EmailID: value, po_isPOEdited: true });
    }
    OnFormPo_PhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ po_Phone: value });
    }
    OnFormMa_FirstNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_FirstName: value });
    }
    OnFormMa_LastNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_LastName: value});
    }
    OnFormMa_EmailIDFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_EmailID: value });
    }
    OnFormMa_PhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_Phone: value });
    }
    OnFormMa_PhoneExtensionFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_PhoneExtension: value });
    }
    OnFormMa_AdditionalPhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ ma_AdditionalPhone: value });
    }
    
    OnCheckBoxChanged(event) {
        var currentState = this.state.propertyDetails;
        var { name, checked } = event.target;
        currentState[name] = checked;
        this.setState({ propertyDetails: currentState });
    }

    OnLeaseDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'File size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.state.propertyDetails.LeaseDocumentName = file.name;
        this.leaseDocument_Upload = file;
    }

    OnW9DocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (file === undefined || file === null) {
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            this.w9Document_Upload = null;
            return;
        }

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            this.w9Document_Upload = null;
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'File size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            this.setState({ w9Uploaded: false });
            this.state.propertyDetails.W9DocumentName = "";
            this.w9Document_Upload = null;
            return;
        }

        this.setState({ w9Uploaded: true });
        this.state.propertyDetails.W9DocumentName = file.name;
        this.w9Document_Upload = file;
    }

    OnRuleDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'File size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.state.propertyDetails.RuleDocumentName = file.name;
        this.ruleDocument_Upload = file;
    }

    OnSupplementDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'File size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.state.propertyDetails.SupplementDocumentName = file.name;
        this.supplementDocument_Upload = file;
    }

    OnLeadBasedPaintDocumentUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*') && !file.type.match('.pdf')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg or PDF file.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'File size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        this.state.propertyDetails.LeadBasedPaintDocumentName = file.name;
        this.leadbasedpaintDocument_Upload = file;
    }

    OnImageUploadChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg Image.' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Image size should be less than 5MB.' });
            this.setState({ openSnackbar: true });
            return;
        }

        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            this.setState({
                selectedFile: [reader.result]
            });
        }.bind(this);

        this.setState({
            selectedFile: event.target.files[0],
            imageUploaded: 1
        });

        this.state.propertyDetails.ImageName = file.name;
        this.propertyImage_Upload = file;
    }

    OnOtherPHANameChanged = async (event) => {
        this.setState({ otherPHAName: event.target.value });
    }

    

    OnPortalUserChanged = async (event) => {
        this.setState({ selectedPortalUserCentralContactId: event.target.value });
    }

    getManagementCompanies = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getmanagementcompanylist';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    getCentralContacts = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getcentralcontactlist';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    getPeoplePropContactById = async (Id) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpeoplepropcontactbyid';

        let myInit = { // OPTIONAL
            body: Id.toString(),
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    clearPropertyOwner =  async (Id) => {
        this.setState({ 
            po_FirstName: '',
            po_LastName: '',
            po_Address: '',
            po_City: '',
            po_State: '',
            po_Zip: '',
            po_EmailID: '',
            po_Phone: '',
            po_AirtablePeoplePropId: '',
            po_SalesforceContactId: '',
            po_ManagementCompanyId: '',
            po_ManagementCompanyName: '',
            po_AirtableCompanyId: '',
            po_SalesforceCompanyId: '',
            po_isPOEdited: false,
         });
    }

    clearPropertyManager =  async (Id) => {
        this.setState({ 
            ma_FirstName: "",
            ma_LastName: "",
            ma_EmailID: "",
            ma_Phone: "",
            ma_PhoneExtension: "",
            ma_AdditionalPhone: "",
            ma_AirtablePeoplePropId: "",
            ma_SalesforceContactId: "",
            managerValidationErrors: {},
         });
    }

    
    // zzz
    updatePropertyOwner = async (Id) => {
        this.setState({ isLoading: true });
        try {
            let res = await this.getPeoplePropContactById(Id.split('_')[1]);
            this.setState({ isLoading: false });
            this.setState({ 
                po_FirstName: res['FirstName'],
                po_LastName: res['LastName'],
                po_Address: res['Address'],
                po_City: res['City'],
                po_State: res['State'],
                po_Zip: res['Zip'],
                po_EmailID: res['EmailID'],
                po_Phone: res['Phone'],
                po_AirtablePeoplePropId: res['AirtablePeoplePropId'],
                po_SalesforceContactId: res['SalesforceContactId'],
                po_ManagementCompanyId: res['ManagementCompanyId'],
                po_ManagementCompanyName: res['ManagementCompanyName'],
                po_AirtableCompanyId: res['AirtableCompanyId'],
                po_SalesforceCompanyId: res['SalesforceCompanyId'],
                po_isPOEdited: false,
             });
            this.state.propertyDetails.PropertyOwnerSalesforceId = res['SalesforceContactId'];
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if ((this.state.po_EmailID === null || this.state.po_EmailID === "") || (this.state.po_EmailID !== undefined && (!pattern4.test(this.state.po_EmailID)))) {
                $('#propOwner_EmailID').removeAttr('disabled');
            }
            if (this.state.po_LastName === null || this.state.po_LastName === "") {
                $('#propOwner_LastName').removeAttr('disabled');
            }
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true });
            this.setState({ snackbarMessage: 'Something went wrong.' });
            this.setState({ snackbarMessageClass: 'Mui-filledError' });
            console.log(e)
        }
    } 

    validateManager() {
        
        let formIsValid = true;
        
        let errors = {};
        if (this.state.ma_EmailID === null || this.state.ma_EmailID === "") {
            formIsValid = false;
            errors["ma_EmailID"] = "Email is required";
            this.setState({ ma_EmailIDError: true });
        }
        else if (this.state.ma_EmailID !== undefined) {
            var pattern4 = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern4.test(this.state.ma_EmailID)) {
                errors["ma_EmailID"] = "Invalid email";
                this.setState({ ma_EmailIDError: true });
                formIsValid = false;
            }
        }

        if (this.state.ma_LastName === null || this.state.ma_LastName === "") {
            formIsValid = false;
            errors["ma_LastName"] = "Last name is required";
            this.setState({ ma_LastNameError: true });
        }

        if (this.state.ma_Phone !== undefined && this.state.ma_Phone !== null && this.state.ma_Phone !== "") {
            if ((this.state.ma_Phone.length < 10) || !this.state.ma_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["ma_Phone"] = "Invalid phone";
                this.setState({ ma_PhoneError: true });
                formIsValid = false;
            }
        }

        if (this.state.ma_AdditionalPhone !== undefined && this.state.ma_AdditionalPhone !== null && this.state.ma_AdditionalPhone !== "") {
            if ((this.state.ma_AdditionalPhone.length < 10) || !this.state.ma_AdditionalPhone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                errors["ma_AdditionalPhone"] = "Invalid phone";
                this.setState({ ma_AdditionalPhoneError: true });
                formIsValid = false;
            }
        }

        if (this.state.ma_PhoneExtension !== "" && this.state.ma_PhoneExtension !== null && this.state.ma_PhoneExtension !== undefined) {
            if (/^[\d\(\)\+]+$/.test(this.state.ma_PhoneExtension) !== true) {
                errors["ma_PhoneExtension"] = "Invalid Extension";
                this.setState({ ma_PhoneExtensionError: true });
                formIsValid = false;
            }
        }
        this.setState({ managerValidationErrors: errors });
        return formIsValid;
    }

    addManager = async (e, isNew) => {
        e.preventDefault();
        if (isNew && !this.validateManager()) {
            this.setState({ isLoading: false });
            return;
        }
        if(this.state.selectedPropertyManager && this.state.selectedPropertyManager.Id != undefined 
            && this.state.selectedPropertyManager.Id != "0"){
            this.setState({ isLoading: true });
            let managerId = this.state.selectedPropertyManager.Id.split('_')[1];
            let lst = this.state.managerList.filter(data => data.ManagerId == managerId);
            if(lst && lst.length > 0){
                this.setState({isLoading: false, openSnackbar: true, snackbarMessage: 'Manager already exist.', snackbarMessageClass: 'Mui-filledError' });
                return;
            }
            try {
                let res = await this.getPeoplePropContactById(this.state.selectedPropertyManager.Id.split('_')[1]);
                this.setState({ isLoading: false });
                this.setState(prevState => ({
                    managerList: [...prevState.managerList, 
                        {
                            ManagerIndex: this.state.managerList.length + 1,
                            ManagerId: res['CentralContactId'],
                            FirstName: res['FirstName'],
                            LastName: res['LastName'],
                            EmailID: res['EmailID'],
                            Phone: res['Phone'],
                            PhoneExtension: res['PhoneExtension'],
                            AdditionalPhone: res['AdditionalPhone'],
                            isDeleted: false,
                            AirtablePeoplePropId: res['AirtablePeoplePropId'],
                            SalesforceContactId: res['SalesforceContactId'],
                        }
                    ]
                  }));
                
            }
            catch (e) {
                this.setState({ isLoading: false });
                this.setState({ openSnackbar: true });
                this.setState({ snackbarMessage: 'Something went wrong.' });
                this.setState({ snackbarMessageClass: 'Mui-filledError' });
                console.log(e)
            }
            this.setState({ isLoading: false });
        } 
        else {
            this.setState(prevState => ({
                managerList: [...prevState.managerList,
                {
                    ManagerIndex: this.state.managerList.length + 1,
                    ManagerId: 0,
                    FirstName: this.state.ma_FirstName,
                    LastName: this.state.ma_LastName,
                    EmailID: this.state.ma_EmailID,
                    Phone: this.state.ma_Phone,
                    PhoneExtension: this.state.ma_PhoneExtension,
                    AdditionalPhone: this.state.ma_AdditionalPhone,
                    isDeleted: false,
                    AirtablePeoplePropId: this.state.ma_AirtablePeoplePropId,
                    SalesforceContactId: this.state.ma_SalesforceContactId,
                }
                ]
            }));
            this.setState({ isLoading: false });
        } 
        this.setState({ selectedPropertyManager: null, isAddManager: false, isMangerSelected: false });
        this.setState({ isLoading: false });
    }

    removeManager = async (index) => {
        this.setState({managerList: this.state.managerList.filter(data => data.ManagerIndex != index)});
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
           await this.signOut();
          }
        }
    }

    render() {
        if (this.showHTML === true) {
            return (
                <div className="property-detail-main">
                    <div className="data-section">
                        <div className="" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                                <form name="detailsform" className="detailsform" onSubmit={this.addProperty.bind(this)}>
                                    <div className="row mb-4">
                                        <div className="col-md-9">
                                            <div className="h-100 box-gray">
                                                <h2 class="profile-title"><i class="fas fa-home mr-2"></i>Property Details</h2>
                                                <div className="box-details">
                                                    <div class="form-row">
                                                        <div class="form-group col-sm-3">
                                                            <label>Property Name<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "PropertyName")} value={this.state.propertyDetails.PropertyName}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["PropertyName"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-4">
                                                            <label>Physical Property Address<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "Address")} value={this.state.propertyDetails.Address}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Address"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <label>Suite<i class=""></i></label>
                                                            <input className="form-control" type="text" maxlength="40" onChange={this.OnFormFieldChanged.bind(this, "Unit")} value={this.state.propertyDetails.Unit}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Unit"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>City<i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "City")} value={this.state.propertyDetails.City}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["City"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-1">
                                                            <label>State</label>
                                                            <select className="form-control" ref={(input) => this.stateSelector = input} onChange={this.OnFormFieldChanged.bind(this, "State")} value={this.state.propertyDetails.State} >
                                                                <option value="">--Select--</option>
                                                                <option value="AA"> AA </option>
                                                                <option value="AE"> AE </option>
                                                                <option value="AK"> AK </option>
                                                                <option value="AL"> AL </option>
                                                                <option value="AP"> AP </option>
                                                                <option value="AR"> AR </option>
                                                                <option value="AS"> AS </option>
                                                                <option value="AZ"> AZ </option>
                                                                <option value="CA"> CA </option>
                                                                <option value="CO"> CO </option>
                                                                <option value="CT"> CT </option>
                                                                <option value="DC"> DC </option>
                                                                <option value="DE"> DE </option>
                                                                <option value="FL"> FL </option>
                                                                <option value="GA"> GA </option>
                                                                <option value="GU"> GU </option>
                                                                <option value="HI"> HI </option>
                                                                <option value="IA"> IA </option>
                                                                <option value="ID"> ID </option>
                                                                <option value="IL"> IL </option>
                                                                <option value="IN"> IN </option>
                                                                <option value="KS"> KS </option>
                                                                <option value="KY"> KY </option>
                                                                <option value="LA"> LA </option>
                                                                <option value="MA"> MA </option>
                                                                <option value="MD"> MD </option>
                                                                <option value="ME"> ME </option>
                                                                <option value="MI"> MI </option>
                                                                <option value="MN"> MN </option>
                                                                <option value="MO"> MO </option>
                                                                <option value="MP"> MP </option>
                                                                <option value="MS"> MS </option>
                                                                <option value="MT"> MT </option>
                                                                <option value="NC"> NC </option>
                                                                <option value="ND"> ND </option>
                                                                <option value="NE"> NE </option>
                                                                <option value="NH"> NH </option>
                                                                <option value="NJ"> NJ </option>
                                                                <option value="NM"> NM </option>
                                                                <option value="NV"> NV </option>
                                                                <option value="NY"> NY </option>
                                                                <option value="OH"> OH </option>
                                                                <option value="OK"> OK </option>
                                                                <option value="OR"> OR </option>
                                                                <option value="PA"> PA </option>
                                                                <option value="PR"> PR </option>
                                                                <option value="RI"> RI </option>
                                                                <option value="SC"> SC </option>
                                                                <option value="SD"> SD </option>
                                                                <option value="TN"> TN </option>
                                                                <option value="TX"> TX </option>
                                                                <option value="UT"> UT </option>
                                                                <option value="VA"> VA </option>
                                                                <option value="VI"> VI </option>
                                                                <option value="VT"> VT </option>
                                                                <option value="WA"> WA </option>
                                                                <option value="WI"> WI </option>
                                                                <option value="WV"> WV </option>
                                                                <option value="WY"> WY </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["State"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Zip</label>
                                                            <input className="form-control" type="text" maxlength="5" onChange={this.OnFormFieldChanged.bind(this, "Zipcode")} value={this.state.propertyDetails.Zipcode}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Zipcode"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-4">
                                                            <label>Management Company</label>
                                                            <Autocomplete className="uw-autocomplete"
                                                                value={this.state.selectedManagementCompany}
                                                                onChange={(event, newValue1) => {
                                                                    if (typeof newValue1 === 'string') {
                                                                        this.setState({ selectedManagementCompany: newValue1 });
                                                                    } else if (newValue1 && newValue1.inputValue) {
                                                                        this.state.managementCompanies.push({title: newValue1.inputValue, Id: "0"});
                                                                        this.setState({ selectedManagementCompany: {title: newValue1.inputValue, Id: "0"} });
                                                                    } else {
                                                                        this.setState({ selectedManagementCompany: newValue1 });
                                                                    }
                                                                  }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    let isFound = false;
                                                                    filtered.forEach(e => {
                                                                        if (e.title === params.inputValue) {
                                                                            isFound = true;
                                                                        }
                                                                    });
                                                                    // Suggest the creation of a new value
                                                                    if (params.inputValue !== '' && !isFound) {
                                                                      filtered.push({
                                                                        inputValue: params.inputValue,
                                                                        //title: `Add "${params.inputValue}"`,
                                                                        title: `+ New Company "${params.inputValue}"`,
                                                                      });
                                                                    }
                                                                    return filtered;
                                                                  }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                //autoComplete={true}
                                                                autoSelect={true}
                                                                id="ManagementCompany"
                                                                options={this.state.managementCompanies}
                                                                getOptionLabel= {(option) => {
                                                                    // Value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                      return option;
                                                                    }
                                                                    // Add "xyz" option created dynamically
                                                                    if (option.inputValue) {
                                                                      return option.inputValue;
                                                                    }
                                                                    // Regular option
                                                                    return option.title;
                                                                  }}
                                                                renderOption={(option) => option.title}
                                                                // style={{ width: 300 }}
                                                                //freeSolo
                                                                renderInput={(params) => (
                                                                    <TextField {...params}  className="form-control"/>
                                                                )}
                                                            />
                                                        </div>
                                                        {/* zzz */}
                                                        <div class="form-group col-sm-2">
                                                            <label>Portal User<i class="fas fa-asterisk asterisk"></i></label>
                                                            <select class="form-control" value={this.state.selectedPortalUserCentralContactId} name='PortalUser' id="PortalUser" onChange={this.OnPortalUserChanged.bind(this)}>
                                                            {/* <select className="form-control" id="propOwner_State" value={this.state.po_State} onChange={this.OnFormPo_StateFieldChanged.bind(this, "po_State")} ></select> */}

                                                                <option>Please Select</option>

                                                                {this.state.centralContactPortalUsers.map((member, index) => (
                                                                    <option value={member.SalesforceContactId + '_' + member.Id}>{member.title}</option>
                                                                ))}

                                                                {/* {this.renderPortalUserOptions()} */}
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["selectedPortalUserCentralContactId"]}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <div className="h-100 box-gray img-box">
                                                <img src={this.state.imageUploaded == true ? this.state.selectedFile : homeBG} width="150" height="150" className="rounded-circle" alt="home" loading="lazy" />
                                                <div className="custom-file-upload">
                                                    <div className="custom-upload-inner">
                                                        <label className="btn btn-primary">
                                                            Browse <input type="file" onChange={this.OnImageUploadChanged.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Property Owner/Principal</h2>
                                        <div className="box-details">
                                            <div class="form-row">
                                                <div class="form-group col-sm-3">
                                                    <label>Property Owner</label>
                                                    <Autocomplete className="uw-autocomplete"
                                                        value={this.state.selectedPropertyOwner}
                                                        onChange={(event, newValue1) => {
                                                            if (typeof newValue1 === 'string') {
                                                                this.setState({ selectedPropertyOwner: newValue1 });
                                                            } else if (newValue1 && newValue1.inputValue) {
                                                                this.state.centralContacts.push({ title: newValue1.inputValue, Id: "0" });
                                                                this.setState({ selectedPropertyOwner: { title: newValue1.inputValue, Id: "0" } });
                                                                $('[id*=propOwner_]').removeAttr('disabled');
                                                                this.clearPropertyOwner();
                                                            } else {
                                                                this.setState({ selectedPropertyOwner: newValue1 });
                                                                if (newValue1) {
                                                                    this.updatePropertyOwner(newValue1.Id);
                                                                    $('[id*=propOwner_]').attr('disabled', 'disabled');
                                                                } else {
                                                                    $('[id*=propOwner_]').removeAttr('disabled');
                                                                    this.clearPropertyOwner();
                                                                } 
                                                            }
                                                        }}
                                                        filterOptions={(options, params) => {
                                                            const filtered = filter(options, params);
                                                            let isFound = false;
                                                            filtered.forEach(e => {
                                                                if (e.title === params.inputValue) {
                                                                    isFound = true;
                                                                }
                                                            });
                                                            // Suggest the creation of a new value
                                                            if (params.inputValue !== '' && !isFound) {
                                                                filtered.push({
                                                                    inputValue: `Please Add Info.`,
                                                                    //title: `No Match. Add New.`,
                                                                    title: `+ New Owner "${params.inputValue}"`,
                                                                });
                                                            }
                                                            return filtered;
                                                        }}
                                                        selectOnFocus
                                                        clearOnBlur
                                                        handleHomeEndKeys
                                                        //autoComplete={true}
                                                        autoSelect={true}
                                                        id="PropertyOwner"
                                                        options={this.state.centralContacts}
                                                        getOptionLabel={(option) => {
                                                            // Value selected with enter, right from the input
                                                            if (typeof option === 'string') {
                                                                return option;
                                                            }
                                                            // Add "xyz" option created dynamically
                                                            if (option.inputValue) {
                                                                return option.inputValue;
                                                            }
                                                            // Regular option
                                                            return option.title;
                                                        }}
                                                        renderOption={(option) => option.title}
                                                        // style={{ width: 300 }}
                                                        //freeSolo
                                                        renderInput={(params) => (
                                                            <TextField {...params} className="form-control" />
                                                        )}
                                                    />
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>First Name</label>
                                                    <input className="form-control" type="text" maxlength="100" id="propOwner_FirstName" value={this.state.po_FirstName} onChange={this.OnFormPo_FirstNameFieldChanged.bind(this, "po_FirstName")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_FirstName"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Last Name<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="100" id="propOwner_LastName" value={this.state.po_LastName} onChange={this.OnFormPo_LastNameFieldChanged.bind(this, "po_LastName")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_LastName"]}</span>
                                                </div>
                                                <div class="form-group col-sm-3">
                                                    <label>Email<i class="fas fa-asterisk asterisk"></i></label>
                                                    <input className="form-control" type="text" maxlength="255" id="propOwner_EmailID" value={this.state.po_EmailID} onChange={this.OnFormPo_EmailIDFieldChanged.bind(this, "po_EmailID")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_EmailID"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Phone</label>
                                                    {/* <input className="form-control" type="text" maxlength="14" value={(this.state.po_Phone === null || this.state.po_Phone === undefined) ? '' : this.state.po_Phone.replace('+1', '')} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "po_Phone")}></input> */}
                                                    <input className="form-control" type="text" maxlength="14" id="propOwner_Phone" value={(this.state.po_Phone)} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "po_Phone")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_Phone"]}</span>
                                                </div>
                                            </div>
                                            <div class="form-row">
                                                <div class="form-group col-sm-3">
                                                    <label>Address</label>
                                                    <input className="form-control" type="text" maxlength="200" id="propOwner_Address" value={this.state.po_Address} onChange={this.OnFormPo_AddressFieldChanged.bind(this, "po_Address")}></input>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>City</label>
                                                    <input className="form-control" type="text" maxlength="10" id="propOwner_City" value={this.state.po_City} onChange={this.OnFormPo_CityFieldChanged.bind(this, "po_City")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_City"]}</span>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>State</label>
                                                    <select className="form-control" id="propOwner_State" value={this.state.po_State} onChange={this.OnFormPo_StateFieldChanged.bind(this, "po_State")} >
                                                        <option value="">--Select--</option>
                                                        <option value="AA"> AA </option>
                                                        <option value="AE"> AE </option>
                                                        <option value="AK"> AK </option>
                                                        <option value="AL"> AL </option>
                                                        <option value="AP"> AP </option>
                                                        <option value="AR"> AR </option>
                                                        <option value="AS"> AS </option>
                                                        <option value="AZ"> AZ </option>
                                                        <option value="CA"> CA </option>
                                                        <option value="CO"> CO </option>
                                                        <option value="CT"> CT </option>
                                                        <option value="DC"> DC </option>
                                                        <option value="DE"> DE </option>
                                                        <option value="FL"> FL </option>
                                                        <option value="GA"> GA </option>
                                                        <option value="GU"> GU </option>
                                                        <option value="HI"> HI </option>
                                                        <option value="IA"> IA </option>
                                                        <option value="ID"> ID </option>
                                                        <option value="IL"> IL </option>
                                                        <option value="IN"> IN </option>
                                                        <option value="KS"> KS </option>
                                                        <option value="KY"> KY </option>
                                                        <option value="LA"> LA </option>
                                                        <option value="MA"> MA </option>
                                                        <option value="MD"> MD </option>
                                                        <option value="ME"> ME </option>
                                                        <option value="MI"> MI </option>
                                                        <option value="MN"> MN </option>
                                                        <option value="MO"> MO </option>
                                                        <option value="MP"> MP </option>
                                                        <option value="MS"> MS </option>
                                                        <option value="MT"> MT </option>
                                                        <option value="NC"> NC </option>
                                                        <option value="ND"> ND </option>
                                                        <option value="NE"> NE </option>
                                                        <option value="NH"> NH </option>
                                                        <option value="NJ"> NJ </option>
                                                        <option value="NM"> NM </option>
                                                        <option value="NV"> NV </option>
                                                        <option value="NY"> NY </option>
                                                        <option value="OH"> OH </option>
                                                        <option value="OK"> OK </option>
                                                        <option value="OR"> OR </option>
                                                        <option value="PA"> PA </option>
                                                        <option value="PR"> PR </option>
                                                        <option value="RI"> RI </option>
                                                        <option value="SC"> SC </option>
                                                        <option value="SD"> SD </option>
                                                        <option value="TN"> TN </option>
                                                        <option value="TX"> TX </option>
                                                        <option value="UT"> UT </option>
                                                        <option value="VA"> VA </option>
                                                        <option value="VI"> VI </option>
                                                        <option value="VT"> VT </option>
                                                        <option value="WA"> WA </option>
                                                        <option value="WI"> WI </option>
                                                        <option value="WV"> WV </option>
                                                        <option value="WY"> WY </option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>Zip</label>
                                                    <input className="form-control" type="text" maxlength="5" id="propOwner_Zip" value={this.state.po_Zip} onChange={this.OnFormPo_ZipFieldChanged.bind(this, "po_Zip")}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["po_Zip"]}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-dollar-sign mr-2"></i>Property Payment</h2>
                                        <div className="box-details">
                                            <div class="form-row">
                                                <div class="form-group col-sm-2">
                                                    <label>Payee name</label>
                                                    <input className="form-control" type="text" maxlength="255" onChange={this.OnFormFieldChanged.bind(this, "pyei_Name")} value={this.state.propertyDetails.pyei_Name}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Name"]}</span>
                                                </div>
                                                <div class="form-group col-sm-3">
                                                    <label>Address</label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "pyei_Address")} value={this.state.propertyDetails.pyei_Address}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Address"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Suite<i class=""></i></label>
                                                    <input className="form-control" type="text" maxlength="40" onChange={this.OnFormFieldChanged.bind(this, "pyei_Unit")} value={this.state.propertyDetails.pyei_Unit}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Unit"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>City</label>
                                                    <input className="form-control" type="text" maxlength="100" onChange={this.OnFormFieldChanged.bind(this, "pyei_City")} value={this.state.propertyDetails.pyei_City}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_City"]}</span>
                                                </div>
                                                <div class="form-group col-sm-1">
                                                    <label>State</label>
                                                    <select className="form-control" ref={(input) => this.stateSelector = input} onChange={this.OnFormFieldChanged.bind(this, "pyei_State")} value={this.state.propertyDetails.pyei_State} >
                                                        <option value="">--Select--</option>
                                                        <option value="AA"> AA </option>
                                                        <option value="AE"> AE </option>
                                                        <option value="AK"> AK </option>
                                                        <option value="AL"> AL </option>
                                                        <option value="AP"> AP </option>
                                                        <option value="AR"> AR </option>
                                                        <option value="AS"> AS </option>
                                                        <option value="AZ"> AZ </option>
                                                        <option value="CA"> CA </option>
                                                        <option value="CO"> CO </option>
                                                        <option value="CT"> CT </option>
                                                        <option value="DC"> DC </option>
                                                        <option value="DE"> DE </option>
                                                        <option value="FL"> FL </option>
                                                        <option value="GA"> GA </option>
                                                        <option value="GU"> GU </option>
                                                        <option value="HI"> HI </option>
                                                        <option value="IA"> IA </option>
                                                        <option value="ID"> ID </option>
                                                        <option value="IL"> IL </option>
                                                        <option value="IN"> IN </option>
                                                        <option value="KS"> KS </option>
                                                        <option value="KY"> KY </option>
                                                        <option value="LA"> LA </option>
                                                        <option value="MA"> MA </option>
                                                        <option value="MD"> MD </option>
                                                        <option value="ME"> ME </option>
                                                        <option value="MI"> MI </option>
                                                        <option value="MN"> MN </option>
                                                        <option value="MO"> MO </option>
                                                        <option value="MP"> MP </option>
                                                        <option value="MS"> MS </option>
                                                        <option value="MT"> MT </option>
                                                        <option value="NC"> NC </option>
                                                        <option value="ND"> ND </option>
                                                        <option value="NE"> NE </option>
                                                        <option value="NH"> NH </option>
                                                        <option value="NJ"> NJ </option>
                                                        <option value="NM"> NM </option>
                                                        <option value="NV"> NV </option>
                                                        <option value="NY"> NY </option>
                                                        <option value="OH"> OH </option>
                                                        <option value="OK"> OK </option>
                                                        <option value="OR"> OR </option>
                                                        <option value="PA"> PA </option>
                                                        <option value="PR"> PR </option>
                                                        <option value="RI"> RI </option>
                                                        <option value="SC"> SC </option>
                                                        <option value="SD"> SD </option>
                                                        <option value="TN"> TN </option>
                                                        <option value="TX"> TX </option>
                                                        <option value="UT"> UT </option>
                                                        <option value="VA"> VA </option>
                                                        <option value="VI"> VI </option>
                                                        <option value="VT"> VT </option>
                                                        <option value="WA"> WA </option>
                                                        <option value="WI"> WI </option>
                                                        <option value="WV"> WV </option>
                                                        <option value="WY"> WY </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_State"]}</span>
                                                </div>
                                                <div class="form-group col-sm-2">
                                                    <label>Zip</label>
                                                    <input className="form-control" type="text" maxlength="5" onChange={this.OnFormFieldChanged.bind(this, "pyei_Zip")} value={this.state.propertyDetails.pyei_Zip}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["pyei_Zip"]}</span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-user-tie mr-2"></i>Property Manager</h2>
                                        <div className="box-details">
                                            <div className="bluebg-section">
                                                <div className="form-row align-items-center">
                                                    <div className="col-md-3 mb-2">
                                                    <label><b>Assign New Manager: </b></label>
                                                        <Autocomplete className="uw-autocomplete"
                                                            value={this.state.selectedPropertyManager}
                                                            onChange={(event, newValue1) => {
                                                                if (typeof newValue1 === 'string') {
                                                                    this.setState({ selectedPropertyManager: newValue1 });
                                                                } else if (newValue1 && newValue1.inputValue) {
                                                                    this.state.centralContactManagerUsers.push({ title: newValue1.inputValue, Id: "0" });
                                                                    this.setState({ selectedPropertyManager: { title: newValue1.inputValue, Id: "0" } });
                                                                    this.setState({ isMangerSelected: false });
                                                                    this.setState({ isAddManager: true });
                                                                    this.clearPropertyManager();
                                                                } else {
                                                                    this.setState({ selectedPropertyManager: newValue1 });
                                                                    if (newValue1) {
                                                                        this.setState({ isMangerSelected: true });
                                                                        this.setState({ isAddManager: false });
                                                                    } else {
                                                                        this.setState({ isMangerSelected: false });
                                                                        this.setState({ isAddManager: false });
                                                                        this.clearPropertyManager();
                                                                    }
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filter(options, params);
                                                                let isFound = false;
                                                                filtered.forEach(e => {
                                                                    if (e.title === params.inputValue) {
                                                                        isFound = true;
                                                                    }
                                                                });
                                                                // Suggest the creation of a new value
                                                                if (params.inputValue !== '' && !isFound) {
                                                                    filtered.push({
                                                                        //inputValue: params.inputValue,
                                                                        //title: `Add "${params.inputValue}"`,
                                                                        inputValue: `Please Add Info.`,
                                                                        //title: `No Match. Add New Manager.`,
                                                                        title: `+ New Manager "${params.inputValue}"`,
                                                                    });
                                                                }
                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            //autoComplete={true}
                                                            autoSelect={true}
                                                            id="PropertyManager"
                                                            options={this.state.centralContactManagerUsers}
                                                            getOptionLabel={(option) => {
                                                                // Value selected with enter, right from the input
                                                                if (typeof option === 'string') {
                                                                    return option;
                                                                }
                                                                // Add "xyz" option created dynamically
                                                                if (option.inputValue) {
                                                                    return option.inputValue;
                                                                }
                                                                // Regular option
                                                                return option.title;
                                                            }}
                                                            renderOption={(option) => option.title}
                                                            // style={{ width: 300 }}
                                                            //freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params} className="form-control" />
                                                            )}
                                                        />
                                                    </div>
                                                    <div className="col-auto mt-1 mt-md-3" style={{ display: this.state.isMangerSelected ? "inline-block" : "none" }}>
                                                        <button type="button" id="addSelectedManager" name="addSelectedManager" onClick={(e) => { this.addManager(e,false) }} class="btn btn-primary btn-dark whoc-btn"><i class="fas fa-plus-circle text-white mr-1"></i>Add</button>
                                                    </div>
                                                </div>

                                                {/* <div className="property-grid form-row" style={{ display: this.state.isAddManager ? "block" : "none" }}> */}
                                                <div className="property-grid grid-text-field form-row align-items-end" style={{ display: this.state.isAddManager ? "flex" : "none" }}>
                                                
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="First Name"
                                                            id="propManager_FirstName"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_FirstName}
                                                            onChange={this.OnFormMa_FirstNameFieldChanged.bind(this, "ma_FirstName")}
                                                            error={this.state.managerValidationErrors["ma_FirstName"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_FirstName"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Last Name"
                                                            required
                                                            id="propManager_LastName"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_LastName}
                                                            onChange={this.OnFormMa_LastNameFieldChanged.bind(this, "ma_LastName")}
                                                            error={this.state.managerValidationErrors["ma_LastName"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_LastName"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Email"
                                                            required
                                                            id="propManager_EmailID"
                                                            //maxlength="100"
                                                            inputProps={{ maxLength: 100 }}
                                                            value={this.state.ma_EmailID}
                                                            onChange={this.OnFormMa_EmailIDFieldChanged.bind(this, "ma_EmailID")}
                                                            error={this.state.managerValidationErrors["ma_EmailID"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_EmailID"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Phone"
                                                            id="propManager_Phone"
                                                            inputProps={{ maxLength: 14 }}
                                                            // maxlength="14"
                                                            value={this.state.ma_Phone}
                                                            onChange={this.OnFormMa_PhoneFieldChanged.bind(this, "ma_Phone")}
                                                            error={this.state.managerValidationErrors["ma_Phone"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_Phone"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-1">
                                                        <TextField label="Extension"
                                                            id="propManager_PhoneExtension"
                                                            //maxlength="4"
                                                            inputProps={{ maxLength: 4 }}
                                                            value={this.state.ma_PhoneExtension}
                                                            onChange={this.OnFormMa_PhoneExtensionFieldChanged.bind(this, "ma_PhoneExtension")}
                                                            error={this.state.managerValidationErrors["ma_PhoneExtension"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_PhoneExtension"]}
                                                        />
                                                    </div>
                                                    <div class="form-group col-md-4 col-xl-2">
                                                        <TextField label="Additional Phone"
                                                            id="propManager_AdditionalPhone"
                                                            inputProps={{ maxLength: 14 }}
                                                            value={this.state.ma_AdditionalPhone}
                                                            onChange={this.OnFormMa_AdditionalPhoneFieldChanged.bind(this, "ma_AdditionalPhone")}
                                                            error={this.state.managerValidationErrors["ma_AdditionalPhone"] ? true : false}
                                                            helperText={this.state.managerValidationErrors["ma_AdditionalPhone"]}
                                                        />

                                                    </div>
                                                    <div className="form-group col-md-4 col-xl-1 mt-3 mt-xl-0">
                                                        <div>
                                                            <button type="button" class="btn btn-primary btn-dark whoc-btn" onClick={(e) => { this.addManager(e,true) }} ><i class="fas fa-plus-circle text-white mr-1"></i>Add</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='table-responsive uw-grid mt-3'>
                                                <table className={"table table-bordered doc-table " + (this.state.managerList && this.state.managerList.length > 3 ? "table-scroll" : "")}>
                                                    <thead>
                                                        <tr>
                                                            {/* <th width="50%" scope="col">Index</th>
                                                            <th width="50%" scope="col">ManagerId</th> */}
                                                            <th width="15%" scope="col">First Name</th>
                                                            <th width="15%" scope="col">Last Name</th>
                                                            <th width="20%" scope="col">Email</th>
                                                            <th width="15%" scope="col">Phone</th>
                                                            <th width="10%" scope="col">Extension</th>
                                                            <th width="15%" scope="col">Additional Phone</th>
                                                            {/* <th width="50%" scope="col">AirtableId</th>
                                                            <th width="50%" scope="col">SF Id</th> */}
                                                            <th width="3%" scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.managerList && this.state.managerList.length > 0 ?
                                                            this.state.managerList.map((member, index) => (
                                                                <tr>
                                                                    <td width="15%"> {member.FirstName} </td>
                                                                    <td width="15%"> {member.LastName} </td>
                                                                    <td width="20%"> {member.EmailID} </td>
                                                                    <td width="15%"> {member.Phone} </td>
                                                                    <td width="10%"> {member.PhoneExtension} </td>
                                                                    <td width="15%"> {member.AdditionalPhone} </td>
                                                                    <td width="3%"><button className="border-0" type="button" onClick={(e) => { this.removeManager(member.ManagerIndex) }} title="Remove Member" ><i class="fas fa-times"></i></button></td>
                                                                </tr>
                                                            ))
                                                            : <tr><td colSpan="7"><p class="text-center"> No Managers assigned yet. </p></td></tr>}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-file-signature mr-2"></i>PUBLIC HOUSING AUTHORITY REGISTRATION</h2>
                                        <div className="box-details whoc-radio whoc-multi-fields py-2">
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label><b>Is the property already registered under Public Housing Authority (PHA)?</b></label>
                                                </div>
                                                <div className="col-auto pha-radio">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio1" name="isPHARegistered" class="custom-control-input"
                                                            checked={this.state.isPHARegistered && this.state.isPHARegistered === "true"}
                                                            value="true" onChange={this.OnIsPHARegisteredRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="customRadio1">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="customRadio2" name="isPHARegistered" class="custom-control-input"
                                                            checked={this.state.isPHARegistered && this.state.isPHARegistered === "false"}
                                                            value="false" onChange={this.OnIsPHARegisteredRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="customRadio2">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.isPHARegistered === "false" ?
                                                // (<div className="row">
                                                //     <div className="col-auto">
                                                //         <label><b>Would you like to register the property under Public Housing Authority using this portal?</b></label>
                                                //     </div>
                                                //     <div className="col-auto">
                                                //         <div class="custom-control custom-radio custom-control-inline">
                                                //             <input type="radio" id="customRadio3" name="registerPHANow" class="custom-control-input"
                                                //                 checked={this.state.registerPHANow && this.state.registerPHANow === "true"}
                                                //                 value="true" onChange={this.OnRegisterPHANowRadioButtonChanged.bind(this)} />
                                                //             <label class="custom-control-label" for="customRadio3">Yes</label>
                                                //         </div>
                                                //         <div class="custom-control custom-radio custom-control-inline">
                                                //             <input type="radio" id="customRadio4" name="registerPHANow" class="custom-control-input"
                                                //                 checked={this.state.registerPHANow && this.state.registerPHANow === "false"}
                                                //                 value="false" onChange={this.OnRegisterPHANowRadioButtonChanged.bind(this)} />
                                                //             <label class="custom-control-label" for="customRadio4">Maybe Later</label>
                                                //         </div>
                                                //     </div>
                                                // </div>)
                                                null :
                                                (<div className="row">
                                                    <div className="col-auto">
                                                        <label><b>Under which Public Housing Authority (PHA) the property is already registered?</b></label>
                                                    </div>
                                                    <div class="col-auto">
                                                        {/* <select className="form-control" onChange={this.OnFormFieldChanged.bind(this, "PHARegisteredHousingAuthority")} value={this.state.propertyDetails.PHARegisteredHousingAuthority}>
                                                            <option value="">--Select--</option>
                                                            <option value="Anaheim Housing Authority (AHA)">Anaheim Housing Authority (AHA)</option>
                                                            <option value="Orange County Housing Authority (OCHA)">Orange County Housing Authority (OCHA)</option>
                                                            <option value="Santa Ana Housing Authority (SAHA)">Santa Ana Housing Authority (SAHA)</option>
                                                            <option value="Other">Other</option>
                                                        </select> */}
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox"  class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityAHA} id="IsPHAAuthorityAHA" value={this.state.propertyDetails.IsPHAAuthorityAHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityAHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityAHA">Anaheim Housing Authority (AHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox"  class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityOCHA} id="IsPHAAuthorityOCHA" value={this.state.propertyDetails.IsPHAAuthorityOCHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityOCHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityOCHA">Orange County Housing Authority (OCHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox"  class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthoritySAHA} id="IsPHAAuthoritySAHA" value={this.state.propertyDetails.IsPHAAuthoritySAHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthoritySAHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthoritySAHA">Santa Ana Housing Authority (SAHA)</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox"  class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityGGHA} id="IsPHAAuthorityGGHA" value={this.state.propertyDetails.IsPHAAuthorityGGHA} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityGGHA" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityGGHA">Garden Grove Housing Authority (GGHA)</label>
                                                        </div>
                                                        {/* <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox"  class="custom-control-input" defaultChecked={this.state.propertyDetails.IsPHAAuthorityOther} id="IsPHAAuthorityOther" value={this.state.propertyDetails.IsPHAAuthorityOther} onChange={this.OnCheckBoxChanged.bind(this)} name="IsPHAAuthorityOther" />
                                                            <label class="custom-control-label" for="IsPHAAuthorityOther">Other</label>
                                                        </div> */}
                                                    </div>
                                                </div>)
                                            }
                                            {/* {this.state.isPHARegistered === "true" && this.state.propertyDetails.IsPHAAuthorityOther ?
                                                (<div className="row">
                                                    <div className="col-auto">
                                                        <label><b>What is the name of the Public Housing Authority (PHA) under which the property is already registered?</b></label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <input className="form-control" type="text" maxlength="100" onChange={this.OnOtherPHANameChanged} value={this.state.otherPHAName}></input>
                                                    </div>
                                                </div>)
                                                :
                                                null
                                            } */}
                                        </div>
                                    </div>

                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                                        <div className="box-details">
                                            <table class="table table-bordered doc-table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Document</th>
                                                        <th scope="col">File Name</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>Copy of blank lease</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <input type="file" class="form-control-file" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                            {/* <div class="file-upload d-inline-block">
                                                                        <label for="upload" class="file-upload__label">Upload</label>
                                                                        <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnLeaseDocumentUploadChanged.bind(this)} />
                                                                    </div> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            W9 for payee<br />
                                                            {/* <span class="alert-small-text">{this.state.validationErrors["w9NotUploaded"]}</span> */}
                                                        </td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <input type="file" class="form-control-file" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                            {/* <div class="file-upload d-inline-block">
                                                                        <label for="upload" class="file-upload__label">Upload</label>
                                                                        <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnW9DocumentUploadChanged.bind(this)} />
                                                                    </div> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Property/Community rules</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <input type="file" class="form-control-file" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                            {/* <div class="file-upload d-inline-block">
                                                                        <label for="upload" class="file-upload__label">Upload</label>
                                                                        <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                    </div> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Supplement application documents</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <input type="file" class="form-control-file" onChange={this.OnSupplementDocumentUploadChanged.bind(this)} />
                                                            {/* <div class="file-upload d-inline-block">
                                                                        <label for="upload" class="file-upload__label">Upload</label>
                                                                        <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                    </div> */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Lead based paint disclosure</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td>
                                                            <input type="file" class="form-control-file" onChange={this.OnLeadBasedPaintDocumentUploadChanged.bind(this)} />
                                                            {/* <div class="file-upload d-inline-block">
                                                                        <label for="upload" class="file-upload__label">Upload</label>
                                                                        <input id="upload" class="file-upload__input" type="file" name="file-upload" onChange={this.OnRuleDocumentUploadChanged.bind(this)} />
                                                                    </div> */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                    <Tooltip title="submit" arrow>
                                        <Button type="button" class="btn btn-primary whoc-float-btn" onClick={this.addProperty}>
                                            <i class="fas fa-save"></i>
                                        </Button>
                                    </Tooltip>
                                </form>
                            </React.Fragment>

                        </div>
                    </div>
                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                    <Snackbar
                        anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                        autoHideDuration={3000}
                        open={this.state.openSnackbar}
                        message={this.state.snackbarMessage}
                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                        className={this.state.snackbarMessageClass}
                        onClose={() => this.setState({ openSnackbar: false })}
                        // action={
                        //     <React.Fragment>
                        //         <IconButton aria-label="close" color="inherit" onClick={this.closeSnackbar.bind()}>
                        //             <CloseIcon />
                        //         </IconButton>
                        //     </React.Fragment>
                        // }
                    />
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}
export default withRouter(AddProperty);