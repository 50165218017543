
import React from "react";
import homeBG from '../imgs/property-img.jpg';
import { Button, CircularProgress } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { API, Auth, Storage } from 'aws-amplify';
import moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import IconButton from '@material-ui/core/IconButton';
import { Redirect } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import $ from 'jquery';
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import { ConsoleView } from "react-device-detect";
const filter = createFilterOptions();

class AddUnit extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            isLoading: false,
            SalesforceContactId: "",
            Role: "",
            IsSignatoryEmailUpdated: false,
            isReadOnly: false,
            orignalStatus: "",

            propertyID: 0,
            submittedUnitDocumentS3Path: null,
            unitSubmissionFileName: null,
            submittedHFADocumentS3Path: null,
            hfaFileName: null,

            propertyOwner: "",
            propertyDetails: {},
            activeProperty: {},
            propertyList: "",
            //propertyOwnerList: "",
            centralContactPortalUsers: [],
            propertyUnit: {},
            unitImagePath: "",
            redirectToUnitPage: null,

            managers: [],
            formFields: {},
            validationErrors: {},
            validationPropertyErrors: {},

            heatType: {},
            heatTypecheckCount: 0,
            heatTypeCheckboxValid: false,
            // subsidyCheckCount: 0,
            // subsidyCheckboxValid: true,
            subsidy: {},
            coolingType: {},
            coolingTypecheckCount: 0,
            coolingTypecheckboxValid: false,
            indoorcheckCount: 0,
            indoorcheckboxValid: false,
            outdoorcheckCount: 0,
            outdoorcheckboxValid: false,
            exteriorcheckCount: 0,
            exteriorcheckboxValid: false,
            parkingcheckCount: 0,
            parkingcheckboxValid: false,
            subsidycheckCount: 0,
            subsidycheckboxValid: false,
            outdoor: {},
            indoor: {},
            laundryType: {},
            laundryTypecheckCount: 0,
            laundryTypeTypecheckboxValid: false,
            kitchen: {},
            kitchenCheckCount: 0,
            kitchenCheckboxValid: false,
            propertyUnitcheckCount: 0,
            propertyUnitcheckboxValid: false,
            leadBasedcheckCount: 0,
            leadBasedcheckboxValid: false,
            exterior: {},
            parking: {},

            openSnackbar: false,
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: '',
            snackbarMessageClass: '',

            imageUploaded: 0,
            selectedFile: null,

            selectedManagementCompany: null,
            managementCompanies: [],
            centralContacts: [],
            centralContactsFullResponse: [],
            selectedAuthorizedSignatory: null,

            signatory_FirstName: "",
            signatory_LastName: "",
            // po_Address: "",
            // po_City: "",
            // po_State: "",
            // po_Zip: "",
            signatory_EmailID: "",
            signatory_Phone: "",
            authorizedSignatory: {},
            IsAuthorizedSignatoryChanged: false,
        }

        this.unitImage_Upload = null;

        this.showHTML = false;
    }

    componentDidMount = async () => {
        let testUnitId = localStorage.getItem('unitId');
        this.setState({ isLoading: true });

        try {
            await Auth.currentAuthenticatedUser();
        }
        catch (err) {
            console.log(err);
            this.setState({ isLoading: false });
            this.signOut();
            return;
        }
        this.showHTML = true;

        var properites = await this.getPropertyDropdownValues();
        this.setState({ propertyList: properites });

        var centralContactResponse = await this.getCentralContacts();
        if (centralContactResponse.length > 0) {
            this.state.centralContactsFullResponse = centralContactResponse;
            centralContactResponse.forEach(e => {
                this.state.centralContacts.push({ title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'po_' + e['CentralContactId'] });
            });
            centralContactResponse.filter(item => item['IsPortalUser']).forEach(e => {
                this.state.centralContactPortalUsers.push({ title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: e['CentralContactId'] });
            });
        }
        if (this.props.history && this.props.history.location.state?.ID || testUnitId !== null) {
            this.setState({ isReadOnly: true });

            var res = await this.getUnitDetails();
            if(res.PropertyUnit.IsUnitView == false) {
                var notification =  await this.updateViewUnitDetails(res.PropertyUnit.PropertyUnitID);
             }
            var r = await this.setUnitProperties(res);
            this.setState({ isLoading: false });
        } else {
            //Note: for new unit status will be 'Submitted' by default.
            this.state.propertyUnit.Status = 'Submitted';
            let date_create = moment().format("YYYY-MM-DD");
            this.state.propertyUnit.SubmitedFrom = date_create;
        }

        try {
            var manaCompResponse = await this.getManagementCompanies();
            if (manaCompResponse.total > 0) {
                manaCompResponse.data.forEach(e => {
                    this.state.managementCompanies.push({ title: e[1], Id: e[0] + "-" + e[2] + "-" + e[3] });
                });
            }
        }
        catch (err) {
            console.log("Man. Com. Error - " + err);
        }


        // this.state.propertyUnit.State = "CA";
        // this.state.propertyUnit.ImagePath = "";
        this.setState({ isLoading: false });
    }

    updateViewUnitDetails = async (propertyUnitID) => {
        this.setState({ isLoading: true });
    
        try {
          var user = await this.getCurrentUser();
          var adminUser = user.attributes.email;
          let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
          let path = "/updateviewunitdetails";
          let myInit = {
            // OPTIONAL
            body: {
              info: {
                PropertyUnitId: propertyUnitID,
                UserName: adminUser
              },
            }, // replace this with attributes you need
            headers: {
              Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
            },
          };
    
          await API.post(apiName, path, myInit);
          // if (res.message === 0)
        } catch (err) {
          console.log(err);
        }
    
        this.setState({ isLoading: false });
    };

    setUnitProperties = async (res) => {
        this.setState({ isLoading: true });
        this.setState({ orignalStatus: res.PropertyUnit.Status });

        if (res.Subsidy) {
            var subCount = 0;

            for (var prop in res.Subsidy) {
                if (res.Subsidy[prop] === true) {
                    subCount++;
                }
            }

            if (subCount > 0) {
                this.setState({ subsidycheckboxValid: true, subsidycheckCount: subCount });
            }
        }
        var leadCount = 0;

        if (res.PropertyUnit.LeadBasedPaintSection1 == true) {
            leadCount++;
        }
        if (res.PropertyUnit.LeadBasedPaintSection2 == true) {
            leadCount++;
        }
        if (res.PropertyUnit.LeadBasedPaintSection3 == true) {
            leadCount++;
        }
        if (leadCount > 0) {
            this.setState({ leadBasedcheckboxValid: true, leadBasedcheckCount: leadCount });
        }
        // subdidy validation end
        if (res.PropertyUnit.HFACommencementDate && res.PropertyUnit.HFACommencementDate !== "0001-01-01T00:00:00") {
            res.PropertyUnit.HFACommencementDate = res.PropertyUnit.HFACommencementDate.split('T')[0];
        }
        if (res.PropertyUnit.AvailableFrom && res.PropertyUnit.AvailableFrom !== null) {
            res.PropertyUnit.AvailableFrom = res.PropertyUnit.AvailableFrom.split('T')[0];
        }
        res.PropertyUnit.SubmitedFrom = res.PropertyUnit.SubmitedFrom.split('T')[0];

        if (res.PropertyUnit.ApprovedRent == 0 || res.PropertyUnit.ApprovedRent == "0") {
            res.PropertyUnit.ApprovedRent = "";
        }
        if (res.PropertyUnit.OCRentedDateOne !== "0001-01-01T00:00:00") {
            res.PropertyUnit.OCRentedDateOne = res.PropertyUnit.OCRentedDateOne.split('T')[0];
        }
        if (res.PropertyUnit.OCRentedDateTwo !== "0001-01-01T00:00:00") {
            res.PropertyUnit.OCRentedDateTwo = res.PropertyUnit.OCRentedDateTwo.split('T')[0];
        }
        if (res.PropertyUnit.OCRentedDateThree !== "0001-01-01T00:00:00") {
            res.PropertyUnit.OCRentedDateThree = res.PropertyUnit.OCRentedDateThree.split('T')[0];
        }

        Storage.configure({
            bucket: process.env.REACT_APP_S3WHOCAdminBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //if (res.PropertyUnit.EnvelopId !== null && res.PropertyUnit.EnvelopId !== "") {
        //res.PropertyUnit.UnitSubmissionFileName != ""
        if ((res.PropertyUnit.EnvelopId !== null && res.PropertyUnit.EnvelopId !== "") || res.PropertyUnit.MigratedFromAirtable == true || res.PropertyUnit.USFStatus == "Completed") {
            try {
               // var unitSubmitted = await Storage.get(`Owner-${res.PropertyDetails.PortalUserCentralContactId}/Properties/Property-${res.PropertyUnit.PropertyID}/Units/Unit-${res.PropertyUnit.PropertyUnitID}/${res.PropertyUnit.UnitSubmissionFileName}`);
                var unitSubmitted = await Storage.get(`Units/${res.PropertyUnit.PropertyUnitID}/Documents/${res.PropertyUnit.UnitSubmissionFileName}`);
                this.setState({ submittedUnitDocumentS3Path: unitSubmitted, unitSubmissionFileName: res.PropertyUnit.UnitSubmissionFileName });
            }
            catch (err) {
                console.log("Error - " + err);
            }
        }
        // res.PropertyUnit.HFAFileName != ""
        if ((res.PropertyUnit.HFAEnvelopId !== null && res.PropertyUnit.HFAEnvelopId !== "") || res.PropertyUnit.MigratedFromAirtable == true || res.PropertyUnit.HFAStatus == "Signed") {
            try {
                //var hfaSubmitted = await Storage.get(`Owner-${res.PropertyDetails.PortalUserCentralContactId}/Properties/Property-${res.PropertyUnit.PropertyID}/Units/Unit-${res.PropertyUnit.PropertyUnitID}/${res.PropertyUnit.HFAFileName}`);
                var hfaSubmitted = await Storage.get(`Units/${res.PropertyUnit.PropertyUnitID}/Documents/${res.PropertyUnit.HFAFileName}`);
                this.setState({ submittedHFADocumentS3Path: hfaSubmitted, hfaFileName: res.PropertyUnit.HFAFileName });
            }
            catch (err) {
                console.log("Error - " + err);
            }
        }

        try {
            if (res.PropertyUnit.ImagePath && res.PropertyUnit.ImagePath !== "") {
                var imageURL = await Storage.get(res.PropertyUnit.ImagePath);
                this.setState({ unitImagePath: imageURL });
            }
        }
        catch (err) {
            console.log("Error - " + err);
        }

        if (res.PropertyUnit.Status == "Inbound") {
            res.PropertyUnit.Status = "Submitted";
        }
        if (res.PropertyUnit.Status == "Leased - Lite") {
            res.PropertyUnit.Status = "Leased";
        }
        if (res.PropertyUnit.Status == "Rent Review") {
            res.PropertyUnit.Status = "Under Rent Review";
        }
        if (res.PropertyUnit.Status == "Negotiation") {
            res.PropertyUnit.Status = "Rent Discussion";
        }
        if (res.PropertyUnit.Status == "Not Yet Ready") {
            res.PropertyUnit.Status = "Not Ready";
        }
        if (res.PropertyUnit.Status == "HFA Sent") {
            res.PropertyUnit.Status = "Holding Fee Agreement Needed";
        }
        if (res.PropertyUnit.Status == "Active-Affiliate") {
            res.PropertyUnit.Status = "Active";
        }
        if (res.PropertyUnit.Status == "Matched-Affiliate") {
            res.PropertyUnit.Status = "Matched";
        }
        if (res.PropertyUnit.Status == "Leased-Affiliate") {
            res.PropertyUnit.Status = "Leased";
        }
        if (res.PropertyUnit.Status == "Outside network" || res.PropertyUnit.Status == "Inactive" || res.PropertyUnit.Status == "Released-Affiliate"
            || res.PropertyUnit.Status == "Not Needed" || res.PropertyUnit.Status == "Released" || res.PropertyUnit.Status == "Ineligible") {
            res.PropertyUnit.Status = "Inactive";
        }


        this.setState({ propertyUnit: res.PropertyUnit, heatType: res.HeatType, coolingType: res.CoolingType, exterior: res.Exterior, indoor: res.Indoor, kitchen: res.Kitchen, laundryType: res.LaundryType, outdoor: res.Outdoor, parking: res.Parking, subsidy: res.Subsidy });

        var propDetails = await this.getPropertyDetails(res.PropertyUnit.PropertyID);
        const { propertyUnit } = { ...this.state };
        const currentState = propertyUnit;
        // var propSalesforceCompanyId = "";
        var portalSalesforceCompanyId = "";
        
        if (propDetails.portalUser) {
            portalSalesforceCompanyId = propDetails.portalUser.SalesforceCompanyId;
        }
        if (portalSalesforceCompanyId != "") {
            currentState["SalesforcePortalUserCompanyId"] = portalSalesforceCompanyId;
        }
        else {
            currentState["SalesforcePortalUserCompanyId"] =  process.env.REACT_APP_DefaultCompany; //"0010400001FNwGJAA1";
        }
        // currentState["SalesforcePortalUserCompanyId"] = propDetails.portalUser.SalesforceCompanyId;
        this.setState({ propertyUnit: currentState });
        this.setState({ propertyDetails: propDetails, propertyOwner: propDetails.portalUser, managers: propDetails.Managers, isLoading: false });
       
        //this is for disable authority signature
        if (res.PropertyUnit.AuthorizedSignatoryContact && res.PropertyUnit.AuthorizedSignatoryContact != null) {
            // var e = this.state.centralContactsFullResponse.filter (x=>x.CentralContactId == res.PropertyUnit.AuthorizedSignatoryCentralContactId);
            var e = res.PropertyUnit.AuthorizedSignatoryContact;
            this.setState({ selectedAuthorizedSignatory: { title: e['EmailId'] ? e['Name'] + ' (' + e['EmailId'] + ')' : e['Name'], Id: 'po_' + e['CentralContactId'] } });
            this.setState({ signatory_FirstName: e["FirstName"], signatory_LastName: e["LastName"], signatory_EmailID: e["EmailID"], signatory_Phone: e["Phone"] });
            this.setState({ SalesforceContactId: res.PropertyUnit.AuthorizedSignatoryContact.SalesforceContactId });
            setTimeout(() => {
                //  $('[id*=signatory_]').attr('disabled', 'disabled');
                if (e["EmailID"] != "") {
                    console.log(document.getElementById('signatory_EmailID'));
                    if(document.getElementById('signatory_EmailID') != null) {
                        document.getElementById('signatory_EmailID').disabled = true;
                    }
                }
                document.getElementById('signatory_FirstName').disabled = true;
                document.getElementById('signatory_LastName').disabled = true;
                document.getElementById('signatory_Phone').disabled = true;

            }, 100);
            //this.state.selectedAuthorizedSignatory.push({title: e[0]['EmailId'] ? e[0]['Name'] + ' (' + e[0]['EmailId'] + ')' : e[0]['Name'], Id: 'po_'+ e[0]['CentralContactId']});
            // await this.updateAuthorizedSignatory(res.PropertyUnit.AuthorizedSignatoryCentralContactId);
        }
    }

    getManagementCompanies = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getmanagementcompanylist';
        // get owner specific companies
        let testId = this.props.history.location.state?.ID;
        let filteredTestId = testId ? testId.toString() : null;
        let PortalUserCentralContactId = (this.props.readOnly === false) ? filteredTestId : null;
        let myInit = { // OPTIONAL
            body: PortalUserCentralContactId ? PortalUserCentralContactId.toString() : null,
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    getPropertyDropdownValues = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertiesdropdown';

        let myInit = {
            body: "0", // Get all properties
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getUnitDetails = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getunitbyunitid';
        let model = {
            PropertyUnitID: this.props.history.location.state?.ID ?
                this.props.history.location.state?.ID :
                parseInt(localStorage.getItem('unitId')),
        }
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getPropertyDetails = async (propertyId) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertydetails';
        let propId = propertyId.toString();

        let myInit = {
            body: propId,
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    openAddPropertyPopup = async () => {
        $('#exampleModal').modal('show');

        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertyownerlist';
        let myInit = {
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        let res = await API.get(apiName, path, myInit);
        this.setState({ propertyOwnerList: res });
    }

    generateUSF = async () => {

       // this.state.propertyUnit.ApprovedRent = parseFloat(this.state.propertyUnit.ApprovedRent) || 0;
        if (this.validateFormFields()) {
            var msg = "";

            if(this.state.propertyUnit.USFStatus == "Sent for Review") {
                msg = "There is already a Unit Submission Form generated. By re-generating Unit Submission Form, the past version would be overridden. Please click OK, to re-generate new Unit Submission Form";
            }
            if(this.state.propertyUnit.USFStatus == "Completed") {
                msg = "There is already a Unit Submission Form generated and signed by Authorized Signatory. By re-generating Unit Submission Form, the past version would be overridden. Please click OK, to re-generate new Unit Submission Form";
            }

            if(this.state.propertyUnit.USFStatus == "Sent for Review" || this.state.propertyUnit.USFStatus == "Completed") {
                var confirm = window.confirm(
                    msg
                  );
                  if (confirm === false) {
                    this.setState({ isLoading: false });
                    return;
                  }
            }
            
            this.setState({ isLoading: true });
            var respUpdate = await this.updateUnitDetails();
            //if (respUpdate === "True" || respUpdate == 'True') {
            if (respUpdate && respUpdate.Success) {
                this.setState({ isLoading: true });
                for (var i = 0; i < 5; i++) {
                    try {
                        var res = await this.invokeDocuSignProcess();
                        if (res.message === "Endpoint request timed out") {
                            console.log("Error: Endpoint request timed out. Retrying attempt: " + i);
                            continue;
                        }
                        else {
                            this.setState({ openSnackbar: true, snackbarMessage: "Property Unit PDF generated.", snackbarMessageClass: 'Mui-filledSuccess' });
                            var res = await this.getUnitDetails();
                            await this.setUnitProperties(res);
                            if(this.state.propertyUnit.SalesforceUnitId == null || this.state.propertyUnit.SalesforceUnitId == "")
                            {
                                var unitResult = await this.getUnitSalesforceId(this.state.propertyUnit.PropertyUnitID);
                                const { propertyUnit } = { ...this.state };
                                const currentState = propertyUnit;
                                currentState["SalesforceUnitId"] = unitResult[0];
                                this.setState({ propertyUnit: currentState });

                            }
                            await this.loadFileToS3(false);
                            // await this.sendUnitSubmissionEmailToAdmin();
                            // alert("Thank you for submitting information. You must have received email to sign unit submission form.")
                            break;
                        }
                    }
                    catch (err) {
                        console.log('Error Response');
                        console.log(err.response);
                        if (err.response && err.response.status === 504) {
                            console.log("Error: Endpoint request timed out. Retrying attempt: " + (i + 1));
                            continue;
                        }
                        console.log(err);
                    }
                }

            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            }
        }
        else {
            this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }
        this.setState({ isLoading: false });
        // else {
        //     this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        // }
    }

    generateHFA = async () => {
        if (this.state.propertyUnit.HFACommencementDate === undefined || this.state.propertyUnit.HFACommencementDate == 'undefined' || this.state.propertyUnit.HFACommencementDate == "" || this.state.propertyUnit.HFACommencementDate == "0001-01-01T00:00:00") {
            this.setState({ openSnackbar: true, snackbarMessage: "Please select HFA Commencement Date", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }
        else if (this.state.propertyUnit.ApprovedRent == 0 || this.state.propertyUnit.ApprovedRent === "" || this.state.propertyUnit.ApprovedRent === null) {
            this.setState({ openSnackbar: true, snackbarMessage: "Please enter Approved Rent", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }
        // else if (this.state.propertyUnit.EnvelopStatus !== "Completed") {
        //     this.setState({ openSnackbar: true, snackbarMessage: "Unit submission form is yet to be signed", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        // }
        else {
            var msg = "";
            if(this.state.propertyUnit.HFAStatus == "Sent") {
                msg = "There is already a Holding Fees Form generated. By re-generating Holding Fees Form, the past version would be overridden. Please click OK, to re-generate new Holding Fees Form";
            }
            if(this.state.propertyUnit.HFAStatus == "Signed") {
                msg = "There is already a Holding Fees Form generated and signed by Authorized Signatory. By re-generating Holding Fees Form, the past version would be overridden. Please click OK, to re-generate new Holding Fees Form";
            }

            if(this.state.propertyUnit.HFAStatus == "Sent" || this.state.propertyUnit.HFAStatus == "Signed") {
                var confirm = window.confirm(
                    msg
                  );
                  if (confirm === false) {
                    this.setState({ isLoading: false });
                    return;
                  }
            }
            this.state.propertyUnit.ApprovedRent = parseFloat(this.state.propertyUnit.ApprovedRent);
            var respUpdate = await this.updateUnitDetails();
            // if (respUpdate === "True" || respUpdate == 'True') {
            if (respUpdate && respUpdate.Success) {
                this.setState({ isLoading: true });
                for (var i = 0; i < 5; i++) {
                    try {
                        var res = await this.invokeDocuSignProcessHFA();
                        if (res.message === "Endpoint request timed out") {
                            console.log("Error: Endpoint request timed out. Retrying attempt: " + i);
                            continue;
                        }
                        else {
                            this.setState({ openSnackbar: true, snackbarMessage: "HFA PDF generated.", snackbarMessageClass: 'Mui-filledSuccess' });
                            var res = await this.getUnitDetails();
                            // this.setUnitProperties(res);
                            
                            await this.setUnitProperties(res);
                            if(this.state.propertyUnit.SalesforceUnitId == null || this.state.propertyUnit.SalesforceUnitId == "")
                            {
                                var unitResult = await this.getUnitSalesforceId(this.state.propertyUnit.PropertyUnitID);
                                const { propertyUnit } = { ...this.state };
                                const currentState = propertyUnit;
                                currentState["SalesforceUnitId"] = unitResult[0];
                                this.setState({ propertyUnit: currentState });

                            }
                            await this.loadFileToS3(false);
                            break;
                        }
                    }
                    catch (err) {
                        console.log('Error Response');
                        console.log(err.response);
                        if (err.response && err.response.status === 504) {
                            console.log("Error: Endpoint request timed out. Retrying attempt: " + (i + 1));
                            continue;
                        }
                        console.log(err);
                    }
                }
            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            }

        }
    }

    OnDropdownValueChanged = async (event) => {
        let fields = this.state.formFields;
        let errors = {};

        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields });

        if (event.target.name === "Paidby") {
            let paidBy = event.target.value;
            const { coolingType } = { ...this.state };
            const currentState = coolingType;
            currentState[event.target.name] = paidBy;
            this.setState({ coolingType: currentState });
            if (paidBy !== "Please Select") {
                errors = { ...this.state.validationErrors }
                errors[event.target.name] = "";
                this.setState({ validationErrors: errors })
            }
        }
        else {
            let paidBy = event.target.value;
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            currentState[event.target.name] = paidBy;
            this.setState({ propertyUnit: currentState });
            if (paidBy !== "Please Select") {
                errors = { ...this.state.validationErrors }
                errors[event.target.name] = "";
                this.setState({ validationErrors: errors })
            }
        }
    }

    OnPropertyChanged = async (event) => {
        this.setState({ isLoading: true });

        let fields = this.state.formFields;
        let errors = {};

        fields["PropertyID"] = event.target.value;
        this.setState({ formFields: fields });
        const { propertyUnit } = { ...this.state };
        const currentState = propertyUnit;
        currentState["PropertyID"] = event.target.value;
        this.setState({ propertyUnit: currentState });

        if (event.target.value !== "Please Select") {
            errors = { ...this.state.validationErrors }
            errors["PropertyID"] = "";
            this.setState({ validationErrors: errors })
            const query = new URLSearchParams(this.props.location.search);
            var propDetails = await this.getPropertyDetails(event.target.value);
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            // currentState["Unit"] = propDetails.Unit;
            currentState["Address"] = propDetails.Address;
            currentState["City"] = propDetails.City;
            currentState["State"] = propDetails.State;
            currentState["Zipcode"] = propDetails.Zipcode;
            // currentState["AuthorizedSignatoryCentralContactId"] = propDetails.PortalUserCentralContactId;
            // currentState["SalesforcePortalUserCompanyId"] = propDetails.SalesforcePortalUserCompanyId;
            if(propDetails.portalUser && propDetails.portalUser.CentralContactId) {
                currentState["AuthorizedSignatoryCentralContactId"] = propDetails.portalUser.CentralContactId;
            }
            //currentState["SalesforcePortalUserCompanyId"] = propDetails.portalUser.SalesforceCompanyId;
            // var propSalesforceCompanyId = "";
            var portalSalesforceCompanyId = "";
            if (propDetails.portalUser) {
                portalSalesforceCompanyId = propDetails.portalUser.SalesforceCompanyId;
            }
            if (portalSalesforceCompanyId != "") {
                currentState["SalesforcePortalUserCompanyId"] = portalSalesforceCompanyId;
            }
            else {
                currentState["SalesforcePortalUserCompanyId"] = process.env.REACT_APP_DefaultCompany; //"0010400001FNwGJAA1";
            }
            // add this line - if propery dont have portal user and try to create unit - need SF community id
            this.setState({ propertyUnit: currentState, propertyDetails: propDetails });
            if (propDetails && propDetails.portalUser) {
                this.setState({ propertyOwner: propDetails.portalUser });
                this.setState({ managers: propDetails.Managers });
            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: "Associated property owner not found.", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
            }
        }

        this.setState({ isLoading: false });
    }

    OnRadioButtonChanged(event) {
        let fields = this.state.formFields;
        let errors = {};

        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields });
        let text = event.target.getAttribute("data-text");

        if (event.target.name === "PHAStatus") {
            const { activeProperty } = { ...this.state };
            const currentState = activeProperty;
            const { name } = event.target;
            currentState[name] = text;
            this.setState({ activeProperty: currentState });
        }
        else {
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            const { name } = event.target;
            currentState[name] = text;
            this.setState({ propertyUnit: currentState });
        }
    }

    OnRadioButtonsChanged(event) {
        let fields = this.state.formFields;
        let errors = {};

        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields });
        const { propertyUnit } = { ...this.state };
        const currentState = propertyUnit;
        const { name, value } = event.target;
        if (this.state.isReadOnly == false && name == "IsAuthorizedSignatoryDifferent" && value === "true") {

            this.setState({ signatory_FirstName: "", signatory_LastName: "", signatory_EmailID: "", signatory_Phone: "" })
        }
        
        if (this.state.isReadOnly == true && name == "IsAuthorizedSignatoryDifferent" && value === "true") {
            setTimeout(() => {
                if (this.state.signatory_EmailID == "") {
                    document.getElementById('signatory_EmailID').disabled = false;
                }
                else {
                    document.getElementById('signatory_EmailID').disabled = true;
                }
                document.getElementById('signatory_FirstName').disabled = true;
                document.getElementById('signatory_LastName').disabled = true;
                document.getElementById('signatory_Phone').disabled = true;
            }, 100);
        }
        if (value === 'true') {
            currentState[name] = true;
        }
        else {
            currentState[name] = false;
        }

        errors = { ...this.state.validationErrors }
        errors[event.target.name] = "";
        this.setState({ validationErrors: errors })

        this.setState({ propertyUnit: currentState });
    }

    OnRadioButtonsChangedHeatType(name, event) {
        let fields = this.state.formFields;
        let errors = {};

        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields });
        // const { heatType } = { ...this.state };
        let currentState = {};
        const { value } = event.target;
        if (value === 'true') {
            currentState[name] = true;
        }
        else {
            currentState[name] = false;
        }

        errors = { ...this.state.validationErrors }
        errors[event.target.name] = "";
        this.setState({ validationErrors: errors })

        this.setState({ heatType: currentState });
    }

    OnRadioButtonsChangedColingType(name, event) {
        let fields = this.state.formFields;
        let errors = {};

        fields[event.target.name] = event.target.value;
        this.setState({ formFields: fields });
        const { coolingType } = { ...this.state };
        let currentState = {};
        const { value } = event.target;
        if (value === 'true') {
            currentState[name] = true;
        }
        else {
            currentState[name] = false;
        }
        if (coolingType && coolingType.Paidby) {
            currentState["Paidby"] = coolingType.Paidby;
        }
        errors = { ...this.state.validationErrors }
        errors[event.target.name] = "";
        this.setState({ validationErrors: errors })

        this.setState({ coolingType: currentState });
    }

    OnFormFieldChanged(section, event) {
        // event.preventDefault();
        let fields = this.state.formFields;
        let errors = {};

        fields[section] = event.target.checked;
        this.setState({ formFields: fields });

        if (section === 'Subsidy') {
            const { subsidy } = { ...this.state };
            const currentState = subsidy;
            const { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ subsidy: currentState });
        }
        else if (section === "Kitchen") {
            var { kitchen } = { ...this.state };
            var currentState = kitchen;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ kitchen: currentState });
        }
        else if (section === "Indoor") {
            var { indoor } = { ...this.state };
            var currentState = indoor;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ indoor: currentState });
        }
        else if (section === 'Outdoor') {
            const { outdoor } = { ...this.state };
            const currentState = outdoor;
            const { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ outdoor: currentState });
        }
        else if (section === 'Exterior') {
            const { exterior } = { ...this.state };
            const currentState = exterior;
            const { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ exterior: currentState });
        }
        else if (section === "LaundryType") {
            var { laundryType } = { ...this.state };
            var currentState = laundryType;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ laundryType: currentState });
        }
        else if (section === "Parking") {
            var { parking } = { ...this.state };
            var currentState = parking;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ parking: currentState });
        }
        else if (section === "PropertyUnit") {
            var { propertyUnit } = { ...this.state };
            var currentState = propertyUnit;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ propertyUnit: currentState });
        }
        else if (section === "HandiCap") {
            var { propertyUnit } = { ...this.state };
            var currentState = propertyUnit;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ propertyUnit: currentState });
        }
        else if (section === "LeadBasedPaint") {
            // if (event.target.checked) {
            //     this.setState((prevState) => ({ leadBasedcheckCount: prevState.leadBasedcheckCount + 1, leadBasedcheckboxValid: true }))
            //     errors = { ...this.state.validationErrors }
            //     errors.LeadBasedPaint = "";
            //     this.setState({ validationErrors: errors })
            // }
            // else {
            //     this.setState((prevState) => ({ leadBasedcheckCount: prevState.leadBasedcheckCount - 1 }), () => {
            //         if (this.state.leadBasedcheckCount === 0) {
            //             errors = { ...this.state.validationErrors }
            //             errors.LeadBasedPaint = "Please make a selection";
            //             this.setState({ validationErrors: errors })
            //             this.setState({ leadBasedcheckboxValid: false })
            //         }
            //     })
            // }
            var { propertyUnit } = { ...this.state };
            var currentState = propertyUnit;
            var { name, checked } = event.target;
            currentState[name] = checked;
            this.setState({ propertyUnit: currentState });
        }
    }

    handlePropertyChanges(field, e) {
        if (field === "PortalUserCentralContactId" || field === "State") {
            const { activeProperty } = { ...this.state };
            const currentState = activeProperty;
            currentState[field] = e.target.value;
            this.setState({ activeProperty: currentState });
        }
        else {
            const { activeProperty } = { ...this.state };
            const currentState = activeProperty;
            const { value } = e.target;
            currentState[field] = value;
            this.setState({ activeProperty: currentState });
        }

        this.OnPropertyFieldChanged(field);
    }

    OnSubsidyChanged(field, e) {
        const { subsidy } = { ...this.state };
        const currentState = subsidy;
        currentState[field] = e.target.value;
        this.setState({ subsidy: currentState });
    }

    OnPropertyFieldChanged(field) {
        let errors = {};
        if (field === "PropertyName") {
            if (this.state.activeProperty.Address === "") {
                errors = { ...this.state.validationPropertyErrors }
                errors["PropPropertyName"] = "Property Name is required";
                this.setState({ validationPropertyErrors: errors })
            }
            else {
                errors = { ...this.state.validationPropertyErrors }
                errors.PropPropertyName = "";
                this.setState({ validationPropertyErrors: errors })
            }
        }
        // if (field === "pyei_Name") {
        //     if (this.state.activeProperty.pyei_Name === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_Name"] = "Payee Name is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.pyei_Name = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "pyei_Address") {
        //     if (this.state.activeProperty.pyei_Address === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_Address"] = "Payee Address is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.pyei_Address = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "pyei_City") {
        //     if (this.state.activeProperty.pyei_City === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_City"] = "Payee City is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.pyei_City = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "pyei_State") {
        //     if (this.state.activeProperty.pyei_State === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_State"] = "Payee State is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_State"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "pyei_Zip") {
        //     if (this.state.activeProperty.pyei_Zip === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_Zip"] = "Payee Zip is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["pyei_Zip"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        if (field === "Address") {
            if (this.state.activeProperty.Address === "") {
                errors = { ...this.state.validationPropertyErrors }
                errors["PropAddress"] = "Address is required";
                this.setState({ validationPropertyErrors: errors })
            }
            else {
                errors = { ...this.state.validationPropertyErrors }
                errors.PropAddress = "";
                this.setState({ validationPropertyErrors: errors })
            }
        }
        if (field === "City") {
            if (this.state.activeProperty.City === "") {
                errors = { ...this.state.validationPropertyErrors }
                errors["PropCity"] = "City is required";
                this.setState({ validationPropertyErrors: errors })
            }
            else {
                errors = { ...this.state.validationPropertyErrors }
                errors["PropCity"] = "";
                this.setState({ validationPropertyErrors: errors })
            }
        }
        // if (field === "State") {
        //     if (this.state.activeProperty.State === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropState"] = "State is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropState"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }

        // if (field === "Zipcode") {
        //     if (this.state.activeProperty.Zipcode === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropZipcode"] = "Zip is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropZipcode"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "Email") {
        //     if (this.state.activeProperty.Email === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropEmail"] = "Email is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.PropEmail = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "Phone") {
        //     if (this.state.activeProperty.Phone === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropPhone"] = "Phone is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.PropPhone = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "PhoneExtension") {
        //     if (this.state.activeProperty.PhoneExtension !== "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropPhoneExtension"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }

        // if (field === "ManagementCompany") {
        //     if (this.state.activeProperty.ManagementCompany === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropManagementCompany"] = "Management Company is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.PropManagementCompany = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
        // if (field === "PortalUserCentralContactId") {
        //     if (this.state.activeProperty.PortalUserCentralContactId === "" || this.state.activeProperty.PortalUserCentralContactId === "Please Select") {
        //         errors = { ...this.state.validationErrors }
        //         errors["PropPortalUserCentralContactId"] = "Portal User is required.";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors.PropPortalUserCentralContactId = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
    }

    OnOtherFormFiledChanged(field, e) {
        let errors = {};

        const re = /^[0-9\b]+$/;
        if (field === "NumberofBedrooms" || field === "BathRoom") {
            //  if (e.target.value === '' || e.target.validity.valid) {
            let { value } = e.target;
            let intValue = value.replace(/\D/g, '');
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            if (intValue === "") {
                currentState[field] = intValue;
            }
            else {
                currentState[field] = parseInt(intValue);
            }

            this.setState({ propertyUnit: currentState });
            //   }
        }
        else if (field === "Zipcode" || field === "YearConstructed") {
            let { value } = e.target;
            let intValue = value.replace(/\D/g, '');
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            currentState[field] = intValue;

            this.setState({ propertyUnit: currentState });
        }
        else if (field === "RequestedRent" || field === "SquireFoot" || field === "ApplicationFee" || field === "PetsDepositAmount" || field === "PetsMonthlyAmount" || field === "OCRentalAmountOne" || field === "OCRentalAmountOne" || field === "OCRentalAmountTwo" || field === "OCRentalAmountThree" || field === "ApprovedRent") {
            let { value } = e.target;
            //  if(!value.isNaN()) {
            let intValue = value.replace(/[^0-9.]/g, "");
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            currentState[field] = intValue;
            this.setState({ propertyUnit: currentState });
            // }
        }
        else if (field === "UpdatesInclude") {
            const { indoor } = { ...this.state };
            const currentState = indoor;
            const { value } = e.target;
            currentState[field] = value;
            this.setState({ indoor: currentState });
        }
        else {
            const { propertyUnit } = { ...this.state };
            const currentState = propertyUnit;
            const { value } = e.target;
            currentState[field] = value;
            this.setState({ propertyUnit: currentState });
        }

        let fields = this.state.formFields;
        fields[field] = e.target.value;
        this.setState({ formFields: fields });

        // if (field === "AvailableFrom" && (this.state.propertyUnit.AvailableFrom === "" || this.state.propertyUnit.AvailableFrom == "0001-01-01T00:00:00")) {
        //     errors = { ...this.state.validationErrors }
        //     errors["AvailableFrom"] = "Available Date is required";
        //     this.setState({ validationErrors: errors })
        // }
        // else {
        //     errors = { ...this.state.validationErrors }
        //     errors.AvailableFrom = "";
        //     this.setState({ validationErrors: errors })
        // }

        if (field === "Address" && this.state.propertyUnit.Address === "") {
            errors = { ...this.state.validationErrors }
            errors["Address"] = "Address is required";
            this.setState({ validationErrors: errors })
        }
        else {
            errors = { ...this.state.validationErrors }
            errors.Address = "";
            this.setState({ validationErrors: errors })
        }
        // city
        if (field === "City") {
            if (this.state.propertyUnit.City === "") {
                errors = { ...this.state.validationErrors }
                errors["City"] = "City is required";
                this.setState({ validationErrors: errors })
            }
            else {
                errors = { ...this.state.validationErrors }
                errors["City"] = "";
                this.setState({ validationErrors: errors })
            }
        }

        // if (field === "Zipcode") {
        //     if (this.state.propertyUnit.Zipcode === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["Zipcode"] = "Zip is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["Zipcode"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }

        // if (field === "YearConstructed") {
        //     if (this.state.propertyUnit.YearConstructed === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["YearConstructed"] = "Year constructed is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["YearConstructed"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }

        // if (field === "SquireFoot") {
        //     if (this.state.propertyUnit.SquireFoot === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["SquireFoot"] = "Square footage is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["SquireFoot"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }

        if (field === "RequestedRent") {
            // if (this.state.propertyUnit.RequestedRent === "") {
            //     errors = { ...this.state.validationErrors }
            //     errors["RequestedRent"] = "Proposed rent is required";
            //     this.setState({ validationErrors: errors })
            // }
            //else
            if (field === "RequestedRent" && this.state.propertyUnit.RequestedRent && this.state.propertyUnit.RequestedRent !== "") {
                var ex = /^[0-9]+\.?[0-9]*$/;
                var x = parseFloat(this.state.propertyUnit.RequestedRent);
                if (this.countDecimals(x) > 2) {
                    errors["RequestedRent"] = "only 2 decimal";
                }
                else {
                    errors = { ...this.state.validationErrors }
                    errors["RequestedRent"] = "";
                    this.setState({ validationErrors: errors })
                }
            }
            else {
                errors = { ...this.state.validationErrors }
                errors["RequestedRent"] = "";
                this.setState({ validationErrors: errors })
            }
        }
        if (field === "Status") {
            if (this.state.propertyUnit.Status === "") {
                errors = { ...this.state.validationErrors }
                errors["Status"] = "Status fees is required";
                this.setState({ validationErrors: errors })
            }
            else {
                errors = { ...this.state.validationErrors }
                errors["Status"] = "";
                this.setState({ validationErrors: errors })
            }
        }

        // if (field === "State") {
        //     if (this.state.propertyUnit.State === "") {
        //         errors = { ...this.state.validationErrors }
        //         errors["State"] = "State is required";
        //         this.setState({ validationErrors: errors })
        //     }
        //     else {
        //         errors = { ...this.state.validationErrors }
        //         errors["State"] = "";
        //         this.setState({ validationErrors: errors })
        //     }
        // }
    }

    resetOwnerCertificationFields = (section, e) => {
        // e.preventDefault();
        const { propertyUnit } = { ...this.state };
        if (section === 1) {
            //  this.state.propertyUnit.OCAddressOne = "";
            let currentState = propertyUnit;
            currentState["OCAddressOne"] = "";
            currentState["OCUnitNoOne"] = "";
            currentState["OCRentalAmountOne"] = "";
            currentState["OCRentedDateOne"] = "";
            this.setState({ propertyUnit: currentState });
        }
        if (section === 2) {
            //  this.state.propertyUnit.OCAddressOne = "";
            let currentState = propertyUnit;
            currentState["OCAddressTwo"] = "";
            currentState["OCUnitNoTwo"] = "";
            currentState["OCRentalAmountTwo"] = "";
            currentState["OCRentedDateTwo"] = "";
            this.setState({ propertyUnit: currentState });
        }
        if (section === 3) {
            //  this.state.propertyUnit.OCAddressOne = "";
            let currentState = propertyUnit;
            currentState["OCAddressThree"] = "";
            currentState["OCUnitNoThree"] = "";
            currentState["OCRentalAmountThree"] = "";
            currentState["OCRentedDateThree"] = "";
            this.setState({ propertyUnit: currentState });
        }
    }

    OnUploadImageChanged = async (event) => {
        var file = event.target.files[0];

        if (!file.type.match('image/jp.*')) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Please upload only Jpeg Image.', snackbarMessageClass: 'Mui-filledSuccess' });
            this.setState({ openSnackbar: true });
            return;
        }

        if (file.size >= 5 * 1024 * 1024) {
            event.target.value = null;
            this.setState({ snackbarMessage: 'Image size should be less then 5MB.', snackbarMessageClass: 'Mui-filledSuccess' });
            this.setState({ openSnackbar: true });
            return;
        }

        const reader = new FileReader();
        var url = reader.readAsDataURL(file);

        reader.onloadend = function (e) {
            this.setState({
                selectedFile: [reader.result]
            });
        }.bind(this);
        console.log(url); // Would see a path?

        this.setState({
            selectedFile: event.target.files[0],
            imageUploaded: 1
        });

        this.unitImage_Upload = file;
    }

    resetPropertyPopup = async () => {

        const { activeProperty } = { ...this.state };
        const currentState = activeProperty;
        currentState["PropertyName"] = "";
        currentState["Address"] = "";
        currentState["Unit"] = "";
        currentState["City"] = "";
        currentState["Zipcode"] = "";
        currentState["PHAStatus"] = "";
        currentState["pyei_Name"] = "";
        currentState["pyei_Address"] = "";
        currentState["pyei_Unit"] = "";
        currentState["pyei_City"] = "";
        currentState["pyei_State"] = "";
        currentState["pyei_Zip"] = "";
        currentState["PortalUserCentralContactId"] = undefined;
        currentState["State"] = "";
        this.setState({ activeProperty: currentState });
        this.setState({ selectedManagementCompany: null });
        this.setState({ validationPropertyErrors: {} });
    }

    addProperty = async (e) => {
        this.setState({ isLoading: true });

        e.preventDefault();
        if (this.validatePropertyFormFields()) {
            $('#exampleModal').modal('hide');
            this.state.activeProperty.PortalUserCentralContactId = parseInt(this.state.activeProperty.PortalUserCentralContactId);
            this.state.activeProperty.PortalUserSalesforceContactId = this.state.centralContactsFullResponse.filter(item => item.CentralContactId === this.state.activeProperty.PortalUserCentralContactId)[0].SalesforceContactId;
            var model = this.state.activeProperty;
            if (model.PHAStatus == "Registered") {
                model.IsPHARegistered = true;
            }
            else {
                model.IsPHARegistered = false;
            }
            var user = await this.getCurrentUser();
            var adminUser = user.attributes.email;
            model.CreatedBy = adminUser;
            model.ModifiedBy = adminUser;
            model.Status = 'Active';
            if (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined) {
                model.ManagementCompany = this.state.selectedManagementCompany;
            } else {
                model.ManagementCompany = null;
            }
            model.Managers = [];
            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
            let path = '/addproperty';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            var response = await API.post(apiName, path, myInit);
            let salesforceManCompId = '';
            if (response.status === true) {
                let newlyAddedPropId = parseInt(response.message);
                this.setState({ propertyID: newlyAddedPropId, openSnackbar: true, snackbarMessage: "Property Added", snackbarMessageClass: 'Mui-filledSuccess' });
                var res = await this.getPropertyDropdownValues();

                var propDetails = await this.getPropertyDetails(newlyAddedPropId);
                const { propertyUnit } = { ...this.state };
                const currentState = propertyUnit;
                currentState["PropertyID"] = newlyAddedPropId;
                currentState["Address"] = propDetails.Address;
                currentState["City"] = propDetails.City;
                currentState["State"] = propDetails.State;
                currentState["Zipcode"] = propDetails.Zipcode;
                // currentState["AuthorizedSignatoryCentralContactId"] = propDetails.PortalUserCentralContactId;
                // currentState["SalesforcePortalUserCompanyId"] = propDetails.SalesforcePortalUserCompanyId;
                currentState["AuthorizedSignatoryCentralContactId"] = propDetails.portalUser.CentralContactId;
                // var propSalesforceCompanyId = "";
                var portalSalesforceCompanyId = "";
                if (propDetails.portalUser) {
                    portalSalesforceCompanyId = propDetails.portalUser.SalesforceCompanyId;
                }

                if (portalSalesforceCompanyId != "") {
                    currentState["SalesforcePortalUserCompanyId"] = portalSalesforceCompanyId;
                }
                else {
                    currentState["SalesforcePortalUserCompanyId"] = "0010400001FNwGJAA1";
                }

                this.setState({ propertyUnit: currentState, propertyList: res, propertyDetails: propDetails, propertyOwner: propDetails.portalUser, managers: propDetails.Managers });
                salesforceManCompId = response.SalesforceManagementCompanyId;
                let cities = ['Aliso Viejo', 'Anaheim', 'Anaheim Hills', 'Brea', 'Buena Park', 'Costa Mesa', 'Cypress',
                'Dana Point', 'Foothill Ranch', 'Fountain Valley', 'Fullerton', 'Garden Grove', 'Huntington Beach',
                'Irvine', 'La Habra', 'La Palma', 'Ladera Ranch', 'Laguna Beach', 'Laguna Hills', 'Laguna Niguel',
                'Laguna Woods', 'Lake Forest', 'Los Alamitos', 'Midway City', 'Mission Viejo', 'Newport Beach',
                'Newport Coast', 'Orange', 'Placentia', 'Rancho Santa Margarita', 'San Clemente', 'San Juan Capistrano',
                'Santa Ana', 'Seal Beach', 'Stanton', 'Tustin', 'Villa Park', 'Westminster', 'Yorba Linda', 'Capistrano Beach',
                'Corona Del Mar', 'Coto de Caza', 'Diamond Bar', 'Silverado Canyon', 'Trabuco Canyon'];
                let isCityMatch = false;
    
                if (cities.indexOf(model.City) !== -1) {
                    isCityMatch = true;
                } else {
                    isCityMatch = false;
                }
                await this.loadPropertyFileToS3(
                    [
                        {
                            "Account Type": "Community", "PropertyName": model.PropertyName, 
                            "City": isCityMatch ? model.City : "Other", "Othercity": isCityMatch ? "" : model.City,           
                            "PHARegisteredList": "", "Address": model.Address,
                            "Zipcode": model.Zipcode, "Status": model.Status,
                            "pyei_Name": model.pyei_Name, "pyei_Address": model.pyei_Address,
                            "pyei_City":model.pyei_City,"pyei_State":model.pyei_State,"pyei_Zip":model.pyei_Zip,
                            "W9DocumentStatus": "false",
                            "ManagementCompany": model.ManagementCompany !== null ? model.ManagementCompany.title : "",
                            "AWS_PropertyID": newlyAddedPropId, "AWS_UpdatedBy": "AWS",
                            "SalesforceCompanyId": salesforceManCompId ? salesforceManCompId : (this.state.selectedManagementCompany && this.state.selectedManagementCompany.Id != undefined && this.state.selectedManagementCompany.Id.split('-').length === 3 ? this.state.selectedManagementCompany.Id.split('-')[2] : null),
                            "RecordTypeId": process.env.REACT_APP_CommunityRecordTypeId //"0124x000000yltHAAQ"
                        },
                    ]
                );
            } else {
                this.setState({ openSnackbar: true });
                this.setState({ snackbarMessage: 'Something went wrong, please contact Administrator.' });
                this.setState({ snackbarMessageClass: 'Mui-filledError' });
            }
        }

        this.setState({ isLoading: false });
    }

    loadPropertyFileToS3 = async (items) => {
        // Convert JSON to CSV & Display CSV
        var s = this.convertToCSV(items);
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            var issueDateTime = new Date(
                new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
            );
            var todaysDate = [
                issueDateTime.getFullYear(),
                ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                ("0" + issueDateTime.getDate()).slice(-2),
            ].join("-");

            var todaysTime = [
                ("0" + issueDateTime.getHours()).slice(-2),
                ("0" + issueDateTime.getMinutes()).slice(-2),
                ("0" + issueDateTime.getSeconds()).slice(-2),
            ].join(":");
            var fileName = "Property_" + items[0].AWS_PropertyID + "_" + todaysDate + "_" + todaysTime + ".csv";
            var result1 = await Storage.put(`Salesforce/Create-Property/${fileName}`, s, { contentType: "csv" });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }

    addUnit = async (e) => {
        this.setState({ isLoading: true });

        e.preventDefault();
        if (this.validateFormFields()) {
            var user = await this.getCurrentUser();
            var adminUser = user.attributes.email;
            this.state.propertyUnit.CreatedBy = adminUser;
            this.state.propertyUnit.HFAStatus = "Pending";
            this.state.propertyUnit.USFStatus = "Pending";
            this.state.propertyUnit.NumberofBedrooms = this.state.propertyUnit.NumberofBedrooms;
            // this.state.propertyUnit.BathRoom = parseInt(this.state.propertyUnit.BathRoom);
            this.state.propertyUnit.BathRoom = this.state.propertyUnit.BathRoom;
           // this.state.propertyUnit.SquireFoot = parseInt(this.state.propertyUnit.SquireFoot);
            this.state.propertyUnit.SquireFoot = !this.state.propertyUnit.SquireFoot || this.state.propertyUnit.SquireFoot == null || isNaN(this.state.propertyUnit.SquireFoot) ? null : parseInt(this.state.propertyUnit.SquireFoot);
            this.state.propertyUnit.ApplicationFee = !this.state.propertyUnit.ApplicationFee || this.state.propertyUnit.ApplicationFee == null || isNaN(this.state.propertyUnit.ApplicationFee) ? null : parseFloat(this.state.propertyUnit.ApplicationFee);
           // this.state.propertyUnit.RequestedRent = parseFloat(this.state.propertyUnit.RequestedRent);
            this.state.propertyUnit.RequestedRent = !this.state.propertyUnit.RequestedRent || this.state.propertyUnit.RequestedRent == null || isNaN(this.state.propertyUnit.RequestedRent) ? null : parseFloat(this.state.propertyUnit.RequestedRent);
            this.state.propertyUnit.YearConstructed = !this.state.propertyUnit.YearConstructed || this.state.propertyUnit.YearConstructed == null || isNaN(this.state.propertyUnit.YearConstructed) ? null : parseInt(this.state.propertyUnit.YearConstructed);
            
            this.state.propertyUnit.PropertyID = parseInt(this.state.propertyUnit.PropertyID);
            //this.state.propertyUnit.Status = "Submitted";
            if (this.state.propertyUnit.IsAuthorizedSignatoryDifferent && this.state.propertyUnit.IsAuthorizedSignatoryDifferent === true) {
                // if (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != undefined) {
                if (this.state.selectedAuthorizedSignatory && this.state.selectedAuthorizedSignatory.Id != undefined && this.state.selectedAuthorizedSignatory.Id != '0') {
                    var contactId = this.state.selectedAuthorizedSignatory.Id.split('_')[1];
                    this.state.propertyUnit.AuthorizedSignatoryCentralContactId = parseInt(contactId);
                    this.state.propertyUnit.Role = this.state.Role;
                    this.state.propertyUnit.SalesforceContactId = this.state.SalesforceContactId;
                    this.state.propertyUnit.IsSignatoryEmailUpdated = this.state.IsSignatoryEmailUpdated;
                    this.state.propertyUnit.AuthorizedSignatoryUpdatedEmail = this.state.signatory_EmailID;
                }
                else {
                    this.state.propertyUnit.AuthorizedSignatoryCentralContactId = null;
                    this.state.authorizedSignatory.FirstName = this.state.signatory_FirstName;
                    this.state.authorizedSignatory.LastName = this.state.signatory_LastName;
                    this.state.authorizedSignatory.EmailID = this.state.signatory_EmailID;
                    this.state.authorizedSignatory.Phone = this.state.signatory_Phone;
                    this.state.propertyUnit.AuthorizedSignatory = this.state.authorizedSignatory;
                }
            }
            else {
                // AuthorizedSignatory is a portal user
                if (this.state.propertyDetails.portalUser && this.state.propertyDetails.portalUser.CentralContactId) {
                    this.state.propertyUnit.AuthorizedSignatoryCentralContactId = this.state.propertyDetails.portalUser.CentralContactId;
                    this.state.propertyUnit.Role = this.state.propertyDetails.portalUser.Role;
                    this.state.propertyUnit.SalesforceContactId = this.state.propertyDetails.portalUser.SalesforceContactId;
                    this.state.propertyUnit.IsAuthorizedSignatory = this.state.propertyDetails.portalUser.IsAuthorizedSignatory;
                    this.state.propertyUnit.AuthorizedSignatoryUpdatedEmail = this.state.propertyOwner.EmailID;
                }
            }
            // this.state.propertyUnit.AuthorizedSignatoryCentralContactId = this.state.selectedAuthorizedSignatory;
            if (this.state.propertyUnit.PetsDepositAmount && this.state.propertyUnit.PetsDepositAmount !== null && this.state.propertyUnit.PetsDepositAmount !== "") {
                this.state.propertyUnit.PetsDepositAmount = parseFloat(this.state.propertyUnit.PetsDepositAmount);
            }
            if (this.state.propertyUnit.PetsMonthlyAmount && this.state.propertyUnit.PetsMonthlyAmount !== null && this.state.propertyUnit.PetsMonthlyAmount !== "") {
                this.state.propertyUnit.PetsMonthlyAmount = parseFloat(this.state.propertyUnit.PetsMonthlyAmount);
            }

            this.state.propertyUnit.OCRentalAmountOne = !this.state.propertyUnit.OCRentalAmountOne || this.state.propertyUnit.OCRentalAmountOne == "" || this.state.propertyUnit.OCRentalAmountOne == null || isNaN(this.state.propertyUnit.OCRentalAmountOne) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountOne);
            this.state.propertyUnit.OCRentalAmountTwo = !this.state.propertyUnit.OCRentalAmountTwo || this.state.propertyUnit.OCRentalAmountTwo == "" || this.state.propertyUnit.OCRentalAmountTwo == null || isNaN(this.state.propertyUnit.OCRentalAmountTwo) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountTwo);
            this.state.propertyUnit.OCRentalAmountThree = !this.state.propertyUnit.OCRentalAmountThree || this.state.propertyUnit.OCRentalAmountThree == "" || this.state.propertyUnit.OCRentalAmountThree == null || isNaN(this.state.propertyUnit.OCRentalAmountThree) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountThree);

            if (this.state.propertyUnit.OCRentedDateOne == "") {
                this.state.propertyUnit.OCRentedDateOne = "0001-01-01T00:00:00";
            }
            if (this.state.propertyUnit.OCRentedDateTwo == "") {
                this.state.propertyUnit.OCRentedDateTwo = "0001-01-01T00:00:00";
            }
            if (this.state.propertyUnit.OCRentedDateThree == "") {
                this.state.propertyUnit.OCRentedDateThree = "0001-01-01T00:00:00";
            }

            var model = {
                PropertyDetails: this.state.propertyDetails,
                PropertyUnit: this.state.propertyUnit,
                HeatType: this.state.heatType,
                CoolingType: this.state.coolingType,
                Exterior: this.state.exterior,
                Indoor: this.state.indoor,
                Kitchen: this.state.kitchen,
                LaundryType: this.state.laundryType,
                Outdoor: this.state.outdoor,
                Parking: this.state.parking,
                Subsidy: this.state.subsidy
            }


            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
            let path = '/addunit';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            var result = await API.post(apiName, path, myInit);
            //if (result == "True" || result.Success === true) {
            if (result && result.Success) {
                this.setState({ openSnackbar: true, snackbarMessage: "Property Unit Added", snackbarMessageClass: 'Mui-filledSuccess' });

                const { propertyUnit } = { ...this.state };
                const currentState = propertyUnit;
                currentState["PropertyUnitID"] = result.UnitId;
                if (result.SalesforceContactId != "" && result.SalesforceContactId != null) {
                    this.setState({ SalesforceContactId: result.SalesforceContactId });
                    //currentState["AuthorizedSignatoryContact"]["SalesforceContactId"] = result.SalesforceContactId;
                }
                else {
                    //  this.state.propertyUnit.SalesforceContactId
                    this.setState({ SalesforceContactId: currentState["SalesforceContactId"] });
                }
                this.setState({ propertyUnit: currentState });
                await this.uploadUnitImage();
                const sleep = (milliseconds = 1200) => new Promise(resolve => setTimeout(resolve, milliseconds))

                if (this.state.propertyDetails.SalesforceCommunityId == "") {
                    for (let index = 0; index < 15; index++) {
                        if (this.state.propertyDetails.SalesforceCommunityId == "") {
                            //  setTimeout(() => {
                            var propDetails = await this.getPropertyDetails(this.state.propertyUnit.PropertyID);
                            this.setState({ propertyDetails: propDetails });
                            await sleep(1200)
                            // }, 10);
                        }
                        else {
                            break;
                        }
                    }
                }

                await this.loadFileToS3(true);

                setTimeout(
                    () => {
                        // this.setState({ redirectToUnitPage: "/welcomehomeoc/unit/view/" + this.state.propertyUnit.PropertyUnitID });
                        // window.location.assign(`/welcomehomeoc/unit/view/${this.state.propertyUnit.PropertyUnitID}`);
                        this.props.history.push({
                            pathname: '/welcomehomeoc/units',
                            state: {ID: this.state.propertyUnit.PropertyUnitID }
                          });
                          window.location.reload();
                    },
                    2000
                );


            }
        }
        else {
            this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }

        this.setState({ isLoading: false });
    }

    uploadUnitImage = async () => {
        if (this.unitImage_Upload !== null) {
            var file = this.unitImage_Upload;
            try {
                Storage.configure({
                    bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                    region: process.env.REACT_APP_Region,
                    level: 'public',
                });

                //var result = await Storage.put(`Owner-${this.state.propertyDetails.PortalUserCentralContactId}/Properties/Property-${this.state.propertyDetails.PropertyID}/Units/Unit-${this.state.propertyUnit.PropertyUnitID}/Images/${file.name}`,
                var result = await Storage.put(`Units/${this.state.propertyUnit.PropertyUnitID}/Images/${file.name}`,
                    file,
                    { contentType: file.type });

                console.log(result);
                if (result) {
                    // update path in db
                    await this.updateUnitImage(file.name, result.key);

                    const { propertyUnit } = { ...this.state };
                    const currentState = propertyUnit;
                    currentState["ImageName"] = file.name;
                    currentState["ImagePath"] = `Units/${this.state.propertyUnit.PropertyUnitID}/Images/${file.name}`;
                    this.setState({ propertyUnit: currentState });
                }
            }
            catch (err) {
                console.log(err)
            }
        }
    }

    updateUnitImage = async (name, path) => {
        var model = {
            propertyUnitID: this.state.propertyUnit.PropertyUnitID,
            imageName: name,
            imagePath: path
        }

        try {
            var user = await this.getCurrentUser();
            let apiName = process.env.REACT_APP_APIGatewayNameWHOC;

            let path = '/updateunitimage';
            let myInit = {
                body: model,
                headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
            }
            let result = await API.post(apiName, path, myInit);
            if (result.status === true) {
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    updateUnit = async (e) => {
        // e.preventDefault();
        // if (this.validateFormFields()) {
        if (this.validateFormFields()) {
            this.setState({ isLoading: true });
            var result = await this.updateUnitDetails();
            // if (result == "True") {
            if (result && result.Success) {
                if (result.SalesforceContactId != "" && result.SalesforceContactId != null) {
                    this.setState({ SalesforceContactId: result.SalesforceContactId });
                    //currentState["AuthorizedSignatoryContact"]["SalesforceContactId"] = result.SalesforceContactId;
                }
                else {
                    var id = this.state.propertyUnit.SalesforceContactId;
                    this.setState({ SalesforceContactId: id });
                }
                // get salesforce if
                if(this.state.propertyUnit.SalesforceUnitId == null || this.state.propertyUnit.SalesforceUnitId == "")
                {
                    var unitResult = await this.getUnitSalesforceId(this.state.propertyUnit.PropertyUnitID);
                    const { propertyUnit } = { ...this.state };
                    const currentState = propertyUnit;
                    currentState["SalesforceUnitId"] = unitResult[0];
                    this.setState({ propertyUnit: currentState });

                }
                await this.loadFileToS3(false);
                this.setState({ openSnackbar: true, snackbarMessage: "Property Unit Updated", snackbarMessageClass: 'Mui-filledSuccess' });
                var res = await this.getUnitDetails();
                this.setUnitProperties(res);
                await this.uploadUnitImage();
            }
            else {
                this.setState({ openSnackbar: true, snackbarMessage: "Something went wrong", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
                console.log(result.ErrorMessage);
            }
            this.setState({ isLoading: false });
            return result;
        }
        else {
            this.setState({ openSnackbar: true, snackbarMessage: "Please fill all required fields", snackbarMessageClass: 'Mui-filledError', hideDuration: 3000 });
        }


    }

    updateUnitDetails = async () => {
        this.setState({ isLoading: true });
        if (this.state.propertyUnit.IsAuthorizedSignatoryDifferent && this.state.propertyUnit.IsAuthorizedSignatoryDifferent === true) {
            // if (this.state.selectedPropertyOwner && this.state.selectedPropertyOwner.Id != undefined) {
            if (this.state.selectedAuthorizedSignatory && this.state.selectedAuthorizedSignatory.Id != undefined && this.state.selectedAuthorizedSignatory.Id != '0') {
                var contactId = this.state.selectedAuthorizedSignatory.Id.split('_')[1];
                this.state.propertyUnit.AuthorizedSignatoryCentralContactId = parseInt(contactId); 
                this.state.propertyUnit.Role = this.state.Role;
                this.state.propertyUnit.SalesforceContactId = this.state.SalesforceContactId;
                this.state.propertyUnit.IsSignatoryEmailUpdated = this.state.IsSignatoryEmailUpdated;
                this.state.propertyUnit.AuthorizedSignatoryUpdatedEmail = this.state.signatory_EmailID;
            }
            else {
                this.state.propertyUnit.AuthorizedSignatoryCentralContactId = null;
                this.state.authorizedSignatory.FirstName = this.state.signatory_FirstName;
                this.state.authorizedSignatory.LastName = this.state.signatory_LastName;
                this.state.authorizedSignatory.EmailID = this.state.signatory_EmailID;
                this.state.authorizedSignatory.Phone = this.state.signatory_Phone;
                this.state.propertyUnit.AuthorizedSignatory = this.state.authorizedSignatory;
            }
        }
        else {
            // AuthorizedSignatory is a portal user
            if (this.state.propertyDetails.portalUser && this.state.propertyDetails.portalUser.CentralContactId) {
                this.state.propertyUnit.AuthorizedSignatoryCentralContactId = this.state.propertyDetails.portalUser.CentralContactId;
                this.state.propertyUnit.Role = this.state.propertyDetails.portalUser.Role;
                this.state.propertyUnit.SalesforceContactId = this.state.propertyDetails.portalUser.SalesforceContactId;
                this.state.propertyUnit.IsAuthorizedSignatory = this.state.propertyDetails.portalUser.IsAuthorizedSignatory;
                this.state.propertyUnit.AuthorizedSignatoryUpdatedEmail = this.state.propertyOwner.EmailID;
            }
            else {
                this.state.propertyUnit.AuthorizedSignatoryCentralContactId = null;
            }
        }
        //end
        this.state.propertyUnit.NumberofBedrooms = this.state.propertyUnit.NumberofBedrooms;
        //this.state.propertyUnit.BathRoom = parseInt(this.state.propertyUnit.BathRoom);
        this.state.propertyUnit.BathRoom = this.state.propertyUnit.BathRoom;
        // this.state.propertyUnit.SquireFoot = parseInt(this.state.propertyUnit.SquireFoot);
        // this.state.propertyUnit.ApplicationFee = parseFloat(this.state.propertyUnit.ApplicationFee);
        //this.state.propertyUnit.ApplicationFee = !this.state.propertyUnit.ApplicationFee || isNaN(this.state.propertyUnit.ApplicationFee) ? null : parseFloat(this.state.propertyUnit.ApplicationFee);
        //this.state.propertyUnit.RequestedRent = parseFloat(this.state.propertyUnit.RequestedRent);
       
        this.state.propertyUnit.SquireFoot = !this.state.propertyUnit.SquireFoot || this.state.propertyUnit.SquireFoot == null || isNaN(this.state.propertyUnit.SquireFoot) ? null : parseInt(this.state.propertyUnit.SquireFoot);
        this.state.propertyUnit.ApplicationFee = !this.state.propertyUnit.ApplicationFee || this.state.propertyUnit.ApplicationFee == null || isNaN(this.state.propertyUnit.ApplicationFee) ? null : parseFloat(this.state.propertyUnit.ApplicationFee);
        this.state.propertyUnit.RequestedRent = !this.state.propertyUnit.RequestedRent || this.state.propertyUnit.RequestedRent == null || isNaN(this.state.propertyUnit.RequestedRent) ? null : parseFloat(this.state.propertyUnit.RequestedRent);
        this.state.propertyUnit.YearConstructed = !this.state.propertyUnit.YearConstructed || this.state.propertyUnit.YearConstructed == null || isNaN(this.state.propertyUnit.YearConstructed) ? null : parseInt(this.state.propertyUnit.YearConstructed);
        this.state.propertyUnit.ApprovedRent = !this.state.propertyUnit.ApprovedRent || this.state.propertyUnit.ApprovedRent == null || isNaN(this.state.propertyUnit.ApprovedRent) ? null : parseFloat(this.state.propertyUnit.ApprovedRent);
        // this.state.propertyUnit.ApprovedRent = parseFloat(this.state.propertyUnit.ApprovedRent) || 0;
        this.state.propertyUnit.PropertyID = parseInt(this.state.propertyUnit.PropertyID);

        //test
            
        //test
        if (this.state.propertyUnit.PetsDepositAmount && this.state.propertyUnit.PetsDepositAmount !== "") {
            this.state.propertyUnit.PetsDepositAmount = parseFloat(this.state.propertyUnit.PetsDepositAmount);
        }
        if (this.state.propertyUnit.PetsMonthlyAmount && this.state.propertyUnit.PetsMonthlyAmount !== "") {
            this.state.propertyUnit.PetsMonthlyAmount = parseFloat(this.state.propertyUnit.PetsMonthlyAmount);
        }

        // this.state.propertyUnit.AuthorizedSignatoryCentralContactId = this.state.selectedAuthorizedSignatory;

        let status = "Submitted"
        let backUpStatus = this.state.propertyUnit.Status;
        if (this.state.propertyUnit.Status) {
            if (this.state.propertyUnit.Status == "Submitted") {
                if (this.state.orignalStatus == "Inbound") {
                    status = "Inbound";
                }
                else {
                    status = "Submitted";
                }
            }
            
            else if (this.state.propertyUnit.Status == "Under Rent Review") {
                if (this.state.orignalStatus == "Rent Review") {
                    status = "Rent Review";
                }
                else {
                    status = "Under Rent Review";
                }
            }
            else if (this.state.propertyUnit.Status == "Rent Discussion") {
                if (this.state.orignalStatus == "Negotiation") {
                    status = "Negotiation";
                }
                else {
                    status = "Rent Discussion";
                }
            }
            else if (this.state.propertyUnit.Status == "Holding Fee Agreement Needed") {
                if (this.state.orignalStatus == "HFA Sent") {
                    status = "HFA Sent";
                }
                else {
                    status = "Holding Fee Agreement Needed";
                }
            }
            // pending
            else if (this.state.propertyUnit.Status == "Active") {
                if (this.state.orignalStatus == "Active-Affiliate") {
                    status = "Active-Affiliate";
                }
                else {
                    status = "Active";
                }
            }
            else if (this.state.propertyUnit.Status == "Matched") {
                if (this.state.orignalStatus == "Matched-Affiliate") {
                    status = "Matched-Affiliate";
                }
                else {
                    status = "Matched";
                }
            }
            else if (this.state.propertyUnit.Status == "Leased") {
                if (this.state.orignalStatus == "Leased - Lite") {
                    status = "Leased - Lite";
                }
                else if (this.state.orignalStatus == "") {
                    status = "Leased-Affiliate";
                }
                else {
                    status = "Leased";
                }

            }
            else if (this.state.propertyUnit.Status == "Inactive") {
                if (this.state.orignalStatus == "Not Needed") {
                    status = "Not Needed";
                }
                else if (this.state.orignalStatus == "Released") {
                    status = "Released";
                }
                else if (this.state.orignalStatus == "Ineligible") {
                    status = "Ineligible";
                }
                else if (this.state.orignalStatus == "Outside network") {
                    status = "Outside network";
                }
                else if(this.state.orignalStatus == "Released-Affiliate") {
                    status = "Released-Affiliate";
                }
                else {
                    status = "Inactive";
                }
            }
            else if (this.state.propertyUnit.Status == "Not Ready") {
                if (this.state.orignalStatus == "Not Yet Ready") {
                    status = "Not Yet Ready";
                }
                else {
                    status = "Not Ready";
                }
            }
        }

        this.state.propertyUnit.Status = status;
        // if (this.state.propertyUnit.OCRentalAmountOne && this.state.propertyUnit.OCRentalAmountOne !== "") {
        //     this.state.propertyUnit.OCRentalAmountOne = parseFloat(this.state.propertyUnit.OCRentalAmountOne);
        // }
        // if (this.state.propertyUnit.OCRentalAmountTwo && this.state.propertyUnit.OCRentalAmountTwo !== "") {
        //     this.state.propertyUnit.OCRentalAmountTwo = parseFloat(this.state.propertyUnit.OCRentalAmountTwo);
        // }
        // if (this.state.propertyUnit.OCRentalAmountThree && this.state.propertyUnit.OCRentalAmountThree !== "") {
        //     this.state.propertyUnit.OCRentalAmountThree = parseFloat(this.state.propertyUnit.OCRentalAmountThree);
        // }

        // this.state.propertyUnit.OCRentalAmountOne = parseFloat(this.state.propertyUnit.OCRentalAmountOne) || 0;
        // this.state.propertyUnit.OCRentalAmountTwo = parseFloat(this.state.propertyUnit.OCRentalAmountTwo) || 0;
        // this.state.propertyUnit.OCRentalAmountThree = parseFloat(this.state.propertyUnit.OCRentalAmountThree) || 0;
        this.state.propertyUnit.OCRentalAmountOne = !this.state.propertyUnit.OCRentalAmountOne || this.state.propertyUnit.OCRentalAmountOne == null || this.state.propertyUnit.OCRentalAmountOne == "" || isNaN(this.state.propertyUnit.OCRentalAmountOne) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountOne);
        this.state.propertyUnit.OCRentalAmountTwo = !this.state.propertyUnit.OCRentalAmountTwo || this.state.propertyUnit.OCRentalAmountTwo == null || this.state.propertyUnit.OCRentalAmountTwo == "" || isNaN(this.state.propertyUnit.OCRentalAmountTwo) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountTwo);
        this.state.propertyUnit.OCRentalAmountThree = !this.state.propertyUnit.OCRentalAmountThree || this.state.propertyUnit.OCRentalAmountThree == null || this.state.propertyUnit.OCRentalAmountThree == "" || isNaN(this.state.propertyUnit.OCRentalAmountThree) ? null : parseFloat(this.state.propertyUnit.OCRentalAmountThree);

        if (this.state.propertyUnit.OCRentedDateOne == "") {
            this.state.propertyUnit.OCRentedDateOne = "0001-01-01T00:00:00";
        }
        if (this.state.propertyUnit.OCRentedDateTwo == "") {
            this.state.propertyUnit.OCRentedDateTwo = "0001-01-01T00:00:00";
        }
        if (this.state.propertyUnit.OCRentedDateThree == "") {
            this.state.propertyUnit.OCRentedDateThree = "0001-01-01T00:00:00";
        }
        if (this.state.propertyUnit.HFACommencementDate == "") {
            this.state.propertyUnit.HFACommencementDate = "0001-01-01T00:00:00";
        }

        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        // this.state.propertyUnit.CreatedBy = adminUser;
        this.state.propertyUnit.ModifiedBy = adminUser;
        this.state.propertyUnit.CreatedBy = "";
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/updateunit';

        // this.state.propertyUnit.ApprovedRent = parseFloat(this.state.propertyUnit.ApprovedRent) || 0;

        var model = {
            PropertyUnit: this.state.propertyUnit,
            HeatType: this.state.heatType,
            CoolingType: this.state.coolingType,
            Exterior: this.state.exterior,
            Indoor: this.state.indoor,
            Kitchen: this.state.kitchen,
            LaundryType: this.state.laundryType,
            Outdoor: this.state.outdoor,
            Parking: this.state.parking,
            Subsidy: this.state.subsidy
        }
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        var result = await API.post(apiName, path, myInit);
        console.log(result);
        //addcode
        const { propertyUnit } = { ...this.state };
        const currentState = propertyUnit;
        currentState["Status"] = backUpStatus;
        this.setState({ isLoading: false, propertyUnit: currentState });
        return result;
    }

    sendUnitSubmissionEmailToAdmin = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/sendunitsubmissionemailtoadmin';

        var model = {
            OwnerName: this.state.propertyOwner.FirstName + ' ' + this.state.propertyOwner.LastName,
            OwnerEmailID: this.state.propertyOwner.EmailID,
            PropertyName: this.state.activeProperty.PropertyName,
        }
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }

        return await API.post(apiName, path, myInit);
    }

    validatePropertyFormFields() {
        let errors = {};
        let formIsValid = true;

        if (!this.state.activeProperty.PropertyName || this.state.activeProperty.PropertyName === "") {
            formIsValid = false;
            errors["PropPropertyName"] = "Property Name is required";
        }
        // if (!this.state.activeProperty.pyei_Name || this.state.activeProperty.pyei_Name === "") {
        //     formIsValid = false;
        //     errors["pyei_Name"] = "Payee Name is required";
        // }

        if (!this.state.activeProperty.Address || this.state.activeProperty.Address === "") {
            formIsValid = false;
            errors["PropAddress"] = "Address is required";
        }
        // if (!this.state.activeProperty.pyei_Address || this.state.activeProperty.pyei_Address === "") {
        //     formIsValid = false;
        //     errors["pyei_Address"] = "Payee Address is required";
        // }
        if (!this.state.activeProperty.City || this.state.activeProperty.City === "") {
            formIsValid = false;
            errors["PropCity"] = "City is required";
        }
        else if (this.state.activeProperty.City !== "" && this.state.activeProperty.City !== null && this.state.activeProperty.City !== undefined) {
            var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
            if (!namePattern.test(this.state.activeProperty.City)) {
                errors["PropCity"] = "Invalid City";
                formIsValid = false;
            }
        }

        // if (!this.state.activeProperty.pyei_City || this.state.activeProperty.pyei_City === "") {
        //     formIsValid = false;
        //     errors["pyei_City"] = "Payee City is required";
        // }
        // else if (this.state.activeProperty.pyei_City !== "" && this.state.activeProperty.pyei_City !== null && this.state.activeProperty.pyei_City !== undefined) {
        //     var namePattern = new RegExp(/^[a-zA-Z- ]*$/);
        //     if (!namePattern.test(this.state.activeProperty.pyei_City)) {
        //         errors["pyei_City"] = "Invalid City";
        //         formIsValid = false;
        //     }
        // }

        // if (!this.state.activeProperty.State || this.state.activeProperty.State === "") {
        //     formIsValid = false;
        //     errors["PropState"] = "State is required";
        // }

        // if (!this.state.activeProperty.pyei_State || this.state.activeProperty.pyei_State === "") {
        //     formIsValid = false;
        //     errors["pyei_State"] = "Payee State is required";
        // }
        // if (!this.state.activeProperty.Zipcode || this.state.activeProperty.Zipcode === "") {
        //     formIsValid = false;
        //     errors["PropZipcode"] = "Zip is required";
        // }
        // else
        if (this.state.activeProperty.Zipcode !== "" && this.state.activeProperty.Zipcode !== null && this.state.activeProperty.Zipcode !== undefined) {
            if (/^\d+$/.test(this.state.activeProperty.Zipcode) !== true) {
                errors["PropZipcode"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        // if (!this.state.activeProperty.pyei_Zip || this.state.activeProperty.pyei_Zip === "") {
        //     formIsValid = false;
        //     errors["pyei_Zip"] = "Payee Zip is required";
        // }
        // else 
        if (this.state.activeProperty.pyei_Zip !== "" && this.state.activeProperty.pyei_Zip !== null && this.state.activeProperty.pyei_Zip !== undefined) {
            if (/^\d+$/.test(this.state.activeProperty.pyei_Zip) !== true) {
                errors["pyei_Zip"] = "Invalid Zip";
                formIsValid = false;
            }
        }

        // if (!this.state.activeProperty.Email || this.state.activeProperty.Email === "") {
        //     formIsValid = false;
        //     errors["PropEmail"] = "Email is required";
        // }
        // else if (this.state.activeProperty.Email !== "" && this.state.activeProperty.Email !== null && this.state.activeProperty.Email !== undefined) {
        //     var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!secondaryEmailPattern.test(this.state.activeProperty.Email)) {
        //         errors["PropEmail"] = "Invalid Email";
        //         formIsValid = false;
        //     }
        // }

        // if (!this.state.activeProperty.Phone || this.state.activeProperty.Phone === "") {
        //     formIsValid = false;
        //     errors["PropPhone"] = "Phone is required";
        // }
        // else if (this.state.activeProperty.Phone !== "" && this.state.activeProperty.Phone !== null && this.state.activeProperty.Phone !== undefined) {
        //     if (!this.state.activeProperty.Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
        //         errors["PropPhone"] = "Invalid Phone";
        //         formIsValid = false;
        //     }
        // }

        // if (this.state.activeProperty.PhoneExtension !== "" && this.state.activeProperty.PhoneExtension !== null && this.state.activeProperty.PhoneExtension !== undefined) {
        //     if (/^[\d\(\)\+]+$/.test(this.state.activeProperty.PhoneExtension) !== true) {
        //         errors["PropPhoneExtension"] = "Invalid Extension";
        //         formIsValid = false;
        //     }
        // }


        // if (!this.state.activeProperty.ManagementCompany || this.state.activeProperty.ManagementCompany === "") {
        //     formIsValid = false;
        //     errors["PropManagementCompany"] = "Management Company is required";
        // }
        if (!this.state.activeProperty.PortalUserCentralContactId || this.state.activeProperty.PortalUserCentralContactId === "") {
            formIsValid = false;
            errors["PropPortalUserCentralContactId"] = "Portal User is required";
        }
        // if (!this.state.activeProperty.PHAStatus || this.state.activeProperty.PHAStatus === "") {
        //     formIsValid = false;
        //     errors["PHAStatus"] = "PHA status is required";
        // }

        this.setState({ validationPropertyErrors: errors });
        return formIsValid;
    }

    validateFormFields() {
        let fields = this.state.formFields;
        let errors = {};
        let formIsValid = true;

        //Name
        if (!this.state.propertyUnit.Address || this.state.propertyUnit.Address === "") {
            formIsValid = false;
            errors["Address"] = "Address is required";
        }
        if (!this.state.propertyUnit.City || this.state.propertyUnit.City === "") {
            formIsValid = false;
            errors["City"] = "City is required";
        }
        // if (this.state.propertyUnit.AvailableFrom == undefined || this.state.propertyUnit.AvailableFrom === "" || this.state.propertyUnit.AvailableFrom == "0001-01-01T00:00:00") {
        //     formIsValid = false;
        //     errors["AvailableFrom"] = "Available date is required";
        // }
        // if (!this.state.propertyUnit.Zipcode || this.state.propertyUnit.Zipcode === "") {
        //     formIsValid = false;
        //     errors["Zipcode"] = "Zip is required";
        // }
        // if (!this.state.propertyUnit.YearConstructed || this.state.propertyUnit.YearConstructed === "") {
        //     formIsValid = false;
        //     errors["YearConstructed"] = "Year constructed is required";
        // }
        // if (!this.state.propertyUnit.RequestedRent || this.state.propertyUnit.RequestedRent === "") {
        //     formIsValid = false;
        //     errors["RequestedRent"] = "Proposed rent is required";
        // }
        if (this.state.propertyUnit.RequestedRent && this.state.propertyUnit.RequestedRent !== "") {
            var ex = /^[0-9]+\.?[0-9]*$/;
            var x = parseFloat(this.state.propertyUnit.RequestedRent);
            if (this.countDecimals(x) > 2) {
                formIsValid = false;
                errors["RequestedRent"] = "only 2";
            }
        }

        if (!this.state.propertyUnit.NumberofBedrooms || this.state.propertyUnit.NumberofBedrooms === '' || this.state.propertyUnit.NumberofBedrooms === "Please Select") {
            formIsValid = false;
            errors["NumberofBedrooms"] = "Please select one option";
        }

        // if (!this.state.propertyUnit.BathRoom || this.state.propertyUnit.BathRoom === '' || this.state.propertyUnit.BathRoom === "Please Select") {
        //     formIsValid = false;
        //     errors["BathRoom"] = "Please select one option";
        // }

        // if (!this.state.propertyUnit.SquireFoot || this.state.propertyUnit.SquireFoot === "") {
        //     formIsValid = false;
        //     errors["SquireFoot"] = "Square footage is required";
        // }

        if (!this.state.propertyUnit.Status || this.state.propertyUnit.Status === "") {
            formIsValid = false;
            errors["Status"] = "Status is required";
        }

        // if (!this.state.propertyUnit.State || this.state.propertyUnit.State === "") {
        //     formIsValid = false;
        //     errors["State"] = "State is required";
        // }

        // radio button start
        if (!this.state.isReadOnly) {
            // if (!fields["IsUnitHaveHandicapAccessibility"]) {
            //     formIsValid = false;
            //     errors["IsUnitHaveHandicapAccessibility"] = "Please select one option";
            // }
            // if (!fields["IsUnitHaveElevator"]) {
            //     formIsValid = false;
            //     errors["IsUnitHaveElevator"] = "Please select one option";
            // }

            // if (!fields["HeatType"]) {
            //     formIsValid = false;
            //     errors["HeatType"] = "Please select one option";
            // }
            // if (!fields["CoolingType"]) {
            //     formIsValid = false;
            //     errors["CoolingType"] = "Please select one option";
            // }

            // if (!fields["Smoking"]) {
            //     formIsValid = false;
            //     errors["Smoking"] = "Please select one option";
            // }
            // if (!fields["UnitLevel"]) {
            //     formIsValid = false;
            //     errors["UnitLevel"] = "Please select one option";
            // }
            // if (!fields["StructureType"]) {
            //     formIsValid = false;
            //     errors["StructureType"] = "Please select one option";
            // }

            // if (!fields["IsRentersInsuranceRequired"]) {
            //     formIsValid = false;
            //     errors["IsRentersInsuranceRequired"] = "Please select one option";
            // }
            // if (!fields["IsUnitHaveWaterHeater"]) {
            //     formIsValid = false;
            //     errors["IsUnitHaveWaterHeater"] = "Please select one option";
            // }
            // if (!fields["PetsAllowed"]) {
            //     formIsValid = false;
            //     errors["PetsAllowed"] = "Please select one option";
            // }

            // if (!fields["HeatingFuelType"]) {
            //     formIsValid = false;
            //     errors["HeatingFuelType"] = "Please select one option";
            // }

            // if (!fields["CookingFuelType"]) {
            //     formIsValid = false;
            //     errors["CookingFuelType"] = "Please select one option";
            // }

            // if (!fields["WaterHeatingFuelType"]) {
            //     formIsValid = false;
            //     errors["WaterHeatingFuelType"] = "Please select one option";
            // }
        }
        //radio button end
        // if (!this.state.propertyUnit.HeatingPaidBy || this.state.propertyUnit.HeatingPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["HeatingPaidBy"] = "Please select one option";
        // }
        // if (!this.state.coolingType.Paidby || this.state.coolingType.Paidby === "Please Select" || this.state.coolingType.Paidby === "Please Sel" || this.state.coolingType.Paidby === "") {
        //     if (this.state.coolingType.None !== true) {
        //         formIsValid = false;
        //         errors["Paidby"] = "Please select one option";
        //     }
        // }
        // if (!this.state.propertyUnit.CookingPaidBy || this.state.propertyUnit.CookingPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["CookingPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.WaterHeatingPaidBy || this.state.propertyUnit.WaterHeatingPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["WaterHeatingPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.OtherElectricPaidBy || this.state.propertyUnit.OtherElectricPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["OtherElectricPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.WaterPaidBy || this.state.propertyUnit.WaterPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["WaterPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.SewerPaidBy || this.state.propertyUnit.SewerPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["SewerPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.TrashCollectionPaidBy || this.state.propertyUnit.TrashCollectionPaidBy === "Please Select") {
        //     formIsValid = false;
        //     errors["TrashCollectionPaidBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.RefrigeratorProvidedBy || this.state.propertyUnit.RefrigeratorProvidedBy === "Please Select") {
        //     formIsValid = false;
        //     errors["RefrigeratorProvidedBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.RangeMicrowaveProvidedBy || this.state.propertyUnit.RangeMicrowaveProvidedBy === "Please Select") {
        //     formIsValid = false;
        //     errors["RangeMicrowaveProvidedBy"] = "Please select one option";
        // }
        // if (!this.state.propertyUnit.MicrowaveProvidedBy || this.state.propertyUnit.MicrowaveProvidedBy === "Please Select") {
        //     formIsValid = false;
        //     errors["MicrowaveProvidedBy"] = "Please select one option";
        // }

        // if (this.state.subsidycheckboxValid === false) {
        //     formIsValid = false;
        //     errors["Subsidy"] = "Please make a selection";
        // }
        // if (this.state.leadBasedcheckboxValid === false) {
        //     formIsValid = false;
        //     errors["LeadBasedPaint"] = "Please make a selection";
        // }

        if (this.state.propertyUnit.IsAuthorizedSignatoryDifferent === true || this.state.propertyUnit.IsAuthorizedSignatoryDifferent === 'true') {
            // if (this.state.signatory_FirstName === "" || this.state.signatory_FirstName === null || this.state.signatory_FirstName === undefined) {
            //     errors["signatory_FirstName"] = "Please enter the name";
            //     formIsValid = false;
            // }
            if (this.state.signatory_LastName === "" || this.state.signatory_LastName === null || this.state.signatory_LastName === undefined) {
                errors["signatory_LastName"] = "Please enter the name";
                formIsValid = false;
            }

            if (this.state.signatory_EmailID === "" || this.state.signatory_EmailID === null || this.state.signatory_EmailID === undefined) {
                errors["signatory_EmailID"] = "Please enter the Email";
                formIsValid = false;
            }
            else if (this.state.signatory_EmailID !== "" && this.state.signatory_EmailID !== null && this.state.signatory_EmailID !== undefined) {
                var secondaryEmailPattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                if (!secondaryEmailPattern.test(this.state.signatory_EmailID)) {
                    errors["signatory_EmailID"] = "Invalid Email";
                    formIsValid = false;
                }
            }

            // if (this.state.signatory_Phone === "" || this.state.signatory_Phone === null || this.state.signatory_Phone === undefined) {
            //     errors["signatory_Phone"] = "Please enter the Phone";
            //     formIsValid = false;
            // }
            // else 
            if (this.state.signatory_Phone !== "" && this.state.signatory_Phone !== null && this.state.signatory_Phone !== undefined) {
                if (!this.state.signatory_Phone.match(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)) {
                    errors["signatory_Phone"] = "Invalid Phone";
                    formIsValid = false;
                }
            }
        }

        if (!this.state.propertyUnit.PropertyID || this.state.propertyUnit.PropertyID === "Please Select") {
            formIsValid = false;
            errors["PropertyID"] = "Please select one option";
        }

        if (this.state.propertyUnit.OCAddressOne && this.state.propertyUnit.OCAddressOne !== "") {
            if (!this.state.propertyUnit.OCRentalAmountOne || this.state.propertyUnit.OCRentalAmountOne === "") {
                formIsValid = false;
                errors["OCRentalAmountOne"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateOne || this.state.propertyUnit.OCRentedDateOne === "") {
                formIsValid = false;
                errors["OCRentedDateOne"] = "Date is required";
            }
        }

        if (this.state.propertyUnit.OCUnitNoOne && this.state.propertyUnit.OCUnitNoOne !== "") {
            if (!this.state.propertyUnit.OCRentalAmountOne || this.state.propertyUnit.OCRentalAmountOne === "") {
                formIsValid = false;
                errors["OCRentalAmountOne"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateOne || this.state.propertyUnit.OCRentedDateOne === "") {
                formIsValid = false;
                errors["OCRentedDateOne"] = "Date is required";
            }
        }

        //address 2
        if (this.state.propertyUnit.OCAddressTwo && this.state.propertyUnit.OCAddressTwo !== "") {
            if (!this.state.propertyUnit.OCRentalAmountTwo || this.state.propertyUnit.OCRentalAmountTwo === "") {
                formIsValid = false;
                errors["OCRentalAmountTwo"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateTwo || this.state.propertyUnit.OCRentedDateTwo === "") {
                formIsValid = false;
                errors["OCRentedDateTwo"] = "Date is required";
            }
        }

        if (this.state.propertyUnit.OCUnitNoTwo && this.state.propertyUnit.OCUnitNoTwo !== "") {
            if (!this.state.propertyUnit.OCRentalAmountTwo || this.state.propertyUnit.OCRentalAmountTwo === "") {
                formIsValid = false;
                errors["OCRentalAmountTwo"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateTwo || this.state.propertyUnit.OCRentedDateTwo === "") {
                formIsValid = false;
                errors["OCRentedDateTwo"] = "Date is required";
            }
        }
        // address 3
        if (this.state.propertyUnit.OCAddressThree && this.state.propertyUnit.OCAddressThree !== "") {
            if (!this.state.propertyUnit.OCRentalAmountThree || this.state.propertyUnit.OCRentalAmountThree === "") {
                formIsValid = false;
                errors["OCRentalAmountThree"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateThree || this.state.propertyUnit.OCRentedDateThree === "") {
                formIsValid = false;
                errors["OCRentedDateThree"] = "Date is required";
            }
        }

        if (this.state.propertyUnit.OCUnitNoThree && this.state.propertyUnit.OCUnitNoThree !== "") {
            if (!this.state.propertyUnit.OCRentalAmountThree || this.state.propertyUnit.OCRentalAmountThree === "") {
                formIsValid = false;
                errors["OCRentalAmountThree"] = "Amount is required";
            }
            if (!this.state.propertyUnit.OCRentedDateThree || this.state.propertyUnit.OCRentedDateThree === "") {
                formIsValid = false;
                errors["OCRentedDateThree"] = "Date is required";
            }
        }
        this.setState({ validationErrors: errors });
        return formIsValid;
    }

    invokeDocuSignProcess = async () => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/requestdocusignunitadd';
        var model = {
            PropertyUnit: this.state.propertyUnit,
            HeatType: this.state.heatType,
            CoolingType: this.state.coolingType,
            Exterior: this.state.exterior,
            Indoor: this.state.indoor,
            Kitchen: this.state.kitchen,
            LaundryType: this.state.laundryType,
            Outdoor: this.state.outdoor,
            Parking: this.state.parking,
            Subsidy: this.state.subsidy,
            PropertyDetails: this.state.propertyDetails
        }
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    invokeDocuSignProcessHFA = async () => {
        var user = await this.getCurrentUser();
        var adminUser = user.attributes.email;
        this.state.propertyUnit.CreatedBy = adminUser;
        this.state.propertyUnit.ModifiedBy = adminUser;
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/requestdocusignholdingfee';
        var model = {
            PropertyUnit: this.state.propertyUnit,
            HeatType: this.state.heatType,
            CoolingType: this.state.coolingType,
            Exterior: this.state.exterior,
            Indoor: this.state.indoor,
            Kitchen: this.state.kitchen,
            LaundryType: this.state.laundryType,
            Outdoor: this.state.outdoor,
            Parking: this.state.parking,
            Subsidy: this.state.subsidy,
            PropertyDetails: this.state.propertyDetails
        }
        let myInit = {
            body: model,
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    countDecimals = function (value) {
        if (Math.floor(value) === value) return 0;
        return value.toString().split(".")[1].length || 0;
    }

    renderPropertyDropdown() {
        return this.state.propertyList && this.state.propertyList.map((dt, i) => {
            return (
                <option id={dt.PropertyID} value={dt.PropertyID}>{dt.PropertyName}</option>
            );
        });
    }

    renderPropertyOwnerOptions() {
        return this.state.centralContactPortalUsers && this.state.centralContactPortalUsers.map((dt, i) => {
            return (
                <option id={dt.Id} value={dt.Id}>{dt.title}</option>
            );
        });
    }

    signOut = async () => {
        try {
            await Auth.signOut()
            // signout success
            window.location.assign("/");
        }
        catch (e) {
            console.log(e);
        }
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            // this.setState({ isLoading: false });
            if (e === "not authenticated") {
                await this.signOut();
            }
        }
    }

    viewDoc = async () => {
        this.setState({ isLoading: true });
        // this.state.unitSubmissionFileName != null
        if (this.state.propertyUnit.EnvelopStatus == "Completed" || this.state.propertyUnit.MigratedFromAirtable == true || this.state.propertyUnit.USFStatus  == "Completed") {
            try {

                let link = document.createElement('a');
                link.href = this.state.submittedUnitDocumentS3Path;
                link.target = '_blank';
                link.download = this.state.submittedUnitDocumentS3Path;
                link.click();
            }
            catch (e) {
                console.log(e);
                this.setState({ snackbarMessage: 'Document is under review or yet to be singed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
            }
        }
        else {
            this.setState({ snackbarMessage: 'Document under review or yet to be singed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
        }

        this.setState({ isLoading: false });
    }
    viewHFADoc = async () => {
        this.setState({ isLoading: true });
        // this.state.hfaFileName != null
        if (this.state.propertyUnit.HFAStatus == "Signed" || this.state.propertyUnit.MigratedFromAirtable == true) {
            try {

                let link = document.createElement('a');
                link.href = this.state.submittedHFADocumentS3Path;
                link.target = '_blank';
                link.download = this.state.submittedHFADocumentS3Path;
                link.click();
            }
            catch (e) {
                console.log(e);
                this.setState({ snackbarMessage: 'Document is under review or yet to be singed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
            }
        }
        else {
            this.setState({ snackbarMessage: 'Document under review or yet to be singed', openSnackbar: true, snackbarMessageClass: 'Mui-filledError' });
        }

        this.setState({ isLoading: false });
    }

    loadFileToS3 = async (isInsert) => {
        this.setState({ isLoading: true });
        let parkingList = [];
        if (this.state.parking) {
            if (this.state.parking.Unassigned)
                parkingList.push("Unassigned");
            if (this.state.parking.Assigned)
                parkingList.push("Assigned");
            if (this.state.parking.Driveway)
                parkingList.push("Driveway");
            if (this.state.parking.Street)
                parkingList.push("Street");
            if (this.state.parking.None)
                parkingList.push("None");
            if (this.state.parking.OneCarCarport)
                parkingList.push("1 car carport");
            if (this.state.parking.TwoCarCarport)
                parkingList.push("2 car carport");
            if (this.state.parking.OneCarGarage)
                parkingList.push("1 car garage");
            if (this.state.parking.TwoCarGarage)
                parkingList.push("2 car garage");
            if (this.state.parking.ThreeCarGarage)
                parkingList.push("3 car garage");
        }
        let indoorList = [];
        if (this.state.indoor) {
            if (this.state.indoor.CeilingFans)
                indoorList.push("Ceiling fans");
            if (this.state.indoor.Fireplace)
                indoorList.push("Fireplace");
            if (this.state.indoor.Furnished)
                indoorList.push("Furnished");
            if (this.state.indoor.CableIncluded)
                indoorList.push("Cable included");
            if (this.state.indoor.BlindsCurtains)
                indoorList.push("Blinds/curtains");
            if (this.state.indoor.SecuritySystem)
                indoorList.push("Security system");
            if (this.state.indoor.NewCarpeting)
                indoorList.push("New carpet");
            if (this.state.indoor.RemodeledUnit)
                indoorList.push("Remodeled unit");
        }

        let outdoorList = [];
        if (this.state.outdoor) {
            if (this.state.outdoor.SwimmingPool)
                outdoorList.push("Pool");
            if (this.state.outdoor.Spa)
                outdoorList.push("Spa");
            if (this.state.outdoor.GatedCommunity)
                outdoorList.push("Gated");
            if (this.state.outdoor.LawnCareIncluded)
                outdoorList.push("Lawn care included");
            if (this.state.outdoor.DogPark)
                outdoorList.push("Dog Park");
            if (this.state.outdoor.FitnesCenter)
                outdoorList.push("Fitness Center");
            if (this.state.outdoor.FencedYard)
                outdoorList.push("Fenced yard");
            if (this.state.outdoor.Gardener)
                outdoorList.push("Gardener");
            if (this.state.outdoor.ClubHouse)
                outdoorList.push("Club house");
            if (this.state.outdoor.Playground)
                outdoorList.push("Playground");
            if (this.state.outdoor.PicnicArea)
                outdoorList.push("BBQ/Picnic Area");
            if (this.state.outdoor.CoWorkingSpace)
                outdoorList.push("Coworking Space");
            if (this.state.outdoor.ResidentLounge)
                outdoorList.push("Resident Lounge");
        }

        let exteriorList = [];
        if (this.state.exterior) {
            if (this.state.exterior.Balcony)
                exteriorList.push("Balcony");
            if (this.state.exterior.Deck)
                exteriorList.push("Deck");
            if (this.state.exterior.Patio)
                exteriorList.push("Patio");
            if (this.state.exterior.Porch)
                exteriorList.push("Porch");
        }

        let kitchenList = [];
        if (this.state.kitchen) {
            if (this.state.kitchen.Dishwasher)
                kitchenList.push("Dishwasher");
            if (this.state.kitchen.Stove)
                kitchenList.push("Stove");
            if (this.state.kitchen.GarbageDisposal)
                kitchenList.push("Garbage Disposal");
            if (this.state.kitchen.Refrigerator)
                kitchenList.push("Refrigerator");
            if (this.state.kitchen.Microwave)
                kitchenList.push("Microwave");
        }

        let laudryTypeList = [];
        if (this.state.laundryType) {
            if (this.state.laundryType.WasherDryerHookUps)
                laudryTypeList.push("Hook ups");
            if (this.state.laundryType.Washer)
                laudryTypeList.push("Washer provided");
            if (this.state.laundryType.Dryer)
                laudryTypeList.push("Dryer provided");
            if (this.state.laundryType.OnsiteLaundryFacilities)
                laudryTypeList.push("Onsite");
        }

        let adaList = [];
        if (this.state.propertyUnit.IsUnitHaveHandicapAccessibility === true) {
            adaList.push("Yes");
            if (this.state.propertyUnit.IsNoStepEntry)
                adaList.push("No step entry");
            if (this.state.propertyUnit.IsWidenedDoorFrames)
                adaList.push("Widened door frames");
            if (this.state.propertyUnit.IsHandicapRestroom)
                adaList.push("Handicap restroom");
            if (this.state.propertyUnit.IsRollBarrierShower)
                adaList.push("Roll-in/no barrier shower");
            if (this.state.propertyUnit.IsADACounterHeight)
                adaList.push("ADA counter height");
            if (this.state.propertyUnit.IsAccommodationsForBlind)
                adaList.push("Accommodations for the blind");
            if (this.state.propertyUnit.IsAccommodationsForDeaf)
                adaList.push("Accommodations for the deaf");
            if (this.state.propertyUnit.IsOtherAccessibility)
                adaList.push("Other");
        }

        let heatType = "";
        if (this.state.heatType) {
            if (this.state.heatType.Baseboard === true)
                heatType = "Baseboard";
            if (this.state.heatType.Boiler === true)
                heatType = "Boiler";
            if (this.state.heatType.Radiator === true)
                heatType = "Radiator";
            if (this.state.heatType.Wall === true)
                heatType = "Wall";
            if (this.state.heatType.Space === true)
                heatType = "Space heater";
            if (this.state.heatType.Central === true)
                heatType = "Central";
            if (this.state.heatType.None === true)
                heatType = "None"
        }

        let coolingType = "";
        if (this.state.coolingType) {
            if (this.state.coolingType.Central === true)
                coolingType = "Central"
            if (this.state.coolingType.WindowWall === true)
                coolingType = "Window/Wall"
            if (this.state.coolingType.None === true)
                coolingType = "None"
        }

        let heatingFuelType = "";
        if (this.state.propertyUnit.HeatingFuelType) {
            if (this.state.propertyUnit.HeatingFuelType === 'Natural gas') {
                heatingFuelType = "Natural Gas"
            }
            else if (this.state.propertyUnit.HeatingFuelType === 'Bottled gas') {
                heatingFuelType = "Bottled Gas"
            }
            else {
                heatingFuelType = this.state.propertyUnit.HeatingFuelType;
            }
        }

        let cookingFuelType = "";
        if (this.state.propertyUnit.CookingFuelType) {
            if (this.state.propertyUnit.CookingFuelType === 'Natural gas') {
                cookingFuelType = "Natural Gas"
            }
            else if (this.state.propertyUnit.CookingFuelType === 'Bottled gas') {
                cookingFuelType = "Bottled Gas"
            }
            else {
                cookingFuelType = this.state.propertyUnit.CookingFuelType;
            }
        }

        let waterHeatingFuelType = "";
        if (this.state.propertyUnit.WaterHeatingFuelType) {
            if (this.state.propertyUnit.WaterHeatingFuelType === 'Natural gas') {
                waterHeatingFuelType = "Natural Gas"
            }
            else if (this.state.propertyUnit.WaterHeatingFuelType === 'Bottled gas') {
                waterHeatingFuelType = "Bottled Gas"
            }
            else {
                waterHeatingFuelType = this.state.propertyUnit.WaterHeatingFuelType;
            }
        }

        // this.state.propertyUnit.WaterHeatingFuelType

        let subsidy = [];
        if (this.state.subsidy) {
            if (this.state.subsidy.Section202)
                subsidy.push("Section 202");
            if (this.state.subsidy.Section221)
                subsidy.push("Section 221");
            if (this.state.subsidy.TaxCredit)
                subsidy.push("Tax Credit");
            if (this.state.subsidy.HOME)
                subsidy.push("HOME");
            if (this.state.subsidy.Section236)
                subsidy.push("Section 236");
            if (this.state.subsidy.Section515)
                subsidy.push("Section 515");
            if (this.state.subsidy.Other)
                subsidy.push("Other");
            if (this.state.subsidy.SubsidyNone)
                subsidy.push("None");
        }

        let petDocumentList = [];

        if (this.state.propertyUnit.IsServiceAnimalCertification)
            petDocumentList.push("Service Animal Certification");
        if (this.state.propertyUnit.IsVaccinationRecords)
            petDocumentList.push("Vaccination Records");
        if (this.state.propertyUnit.IsRabiesRecords)
            petDocumentList.push("Rabies Records");
        // if(this.state.propertyUnit.IsOther)
        //     petDocumentList.push("Other");

        let leadBaseList = [];

        if (this.state.propertyUnit.LeadBasedPaintSection1)
            leadBaseList.push("Lead-based paint disclosure requirements do not apply because this property was built on or after 01-01-1978.");
        if (this.state.propertyUnit.LeadBasedPaintSection2)
            leadBaseList.push("The unit has been found to be lead-based paint free by a lead-based paint inspector certified under the Federal certification program or under a federally accredited State certification program.");
        if (this.state.propertyUnit.LeadBasedPaintSection3)
            leadBaseList.push("A completed statement is attached containing disclosure of known information on lead-based paint.");

        let cities = ['Aliso Viejo', 'Anaheim', 'Anaheim Hills', 'Brea', 'Buena Park', 'Costa Mesa', 'Cypress',
            'Dana Point', 'Foothill Ranch', 'Fountain Valley', 'Fullerton', 'Garden Grove', 'Huntington Beach',
            'Irvine', 'La Habra', 'La Palma', 'Ladera Ranch', 'Laguna Beach', 'Laguna Hills', 'Laguna Niguel',
            'Laguna Woods', 'Lake Forest', 'Los Alamitos', 'Midway City', 'Mission Viejo', 'Newport Beach',
            'Newport Coast', 'Orange', 'Placentia', 'Rancho Santa Margarita', 'San Clemente', 'San Juan Capistrano',
            'Santa Ana', 'Seal Beach', 'Stanton', 'Tustin', 'Villa Park', 'Westminster', 'Yorba Linda', 'Capistrano Beach',
            'Corona Del Mar', 'Coto de Caza', 'Diamond Bar', 'Silverado Canyon', 'Trabuco Canyon'];

        let isCityMatch = false;
        if (cities.indexOf(this.state.propertyUnit.City) !== -1) {
            isCityMatch = true;
        }
        else {
            isCityMatch = false;
        }

        let hfaDate = "";
        if (this.state.propertyUnit.HFACommencementDate && this.state.propertyUnit.HFACommencementDate !== "0001-01-01T00:00:00" && this.state.propertyUnit.HFACommencementDate !== null) {
            hfaDate = this.state.propertyUnit.HFACommencementDate.split('T')[0];
        }

        let status = "Inbound";
        if (this.state.propertyUnit.Status) {
            if (this.state.propertyUnit.Status == "Submitted") {
                status = "Inbound";
            }
            else if (this.state.propertyUnit.Status == "Under Rent Review") {
                status = "Rent Review";
            }
            else if (this.state.propertyUnit.Status == "Rent Discussion") {
                status = "Negotiation";
            }
            else if (this.state.propertyUnit.Status == "Holding Fee Agreement Needed") {
                status = "HFA Sent";
            }
            else if (this.state.propertyUnit.Status == "Active") {
                if (this.state.orignalStatus == "Active-Affiliate") {
                    status = "Active-Affiliate";
                }
                else {
                    status = "Active";
                }
            }
            else if (this.state.propertyUnit.Status == "Matched") {
                if (this.state.orignalStatus == "Matched-Affiliate") {
                    status = "Matched-Affiliate";
                }
                else {
                    status = "Matched";
                }
            }
            else if (this.state.propertyUnit.Status == "Leased") {
                if (this.state.orignalStatus == "Leased - Lite") {
                    status = "Leased - Lite";
                }
                else if (this.state.orignalStatus == "") {
                    status = "Leased-Affiliate";
                }
                else {
                    status = "Leased";
                }

            }
            else if (this.state.propertyUnit.Status == "Inactive") {
                if (this.state.orignalStatus == "Not Needed") {
                    status = "Not Needed";
                }
                else if (this.state.orignalStatus == "Released") {
                    status = "Released"
                }
                else if (this.state.orignalStatus == "Ineligible") {
                    status = "Ineligible"
                }
                else if(this.state.orignalStatus == "Released-Affiliate") {
                    status = "Released-Affiliate";
                }
                else if (this.state.orignalStatus == "Outside network") {
                    status = "Outside network"
                }
                else {
                    status = "Inactive";
                }
            }
            else if (this.state.propertyUnit.Status == "Not Ready") {
                status = "Not Yet Ready";
            }


        }

        var items = [
            {
                Address: this.state.propertyUnit.Address,
                UnitNo: this.state.propertyUnit.Unit,
                Zipcode: this.state.propertyUnit.Zipcode,
                NumberofBedrooms: this.state.propertyUnit.NumberofBedrooms,
                UnitLevel: this.state.propertyUnit.UnitLevel,
                AvailableFrom: this.state.propertyUnit.AvailableFrom == "0001-01-01T00:00:00" || this.state.propertyUnit.AvailableFrom == "0001-01-01" ? "" : this.state.propertyUnit.AvailableFrom,
                PropertyID: this.state.propertyUnit.PropertyID,
                SubmitedFrom: this.state.propertyUnit.SubmitedFrom == "0001-01-01T00:00:00" || this.state.propertyUnit.SubmitedFrom == "0001-01-01" ? "" : this.state.propertyUnit.SubmitedFrom,
                City: isCityMatch ? this.state.propertyUnit.City : "Other",
                Othercity: isCityMatch ? "" : this.state.propertyUnit.City,
                //YearConstructed: this.state.propertyUnit.YearConstructed,
                YearConstructed: !this.state.propertyUnit.YearConstructed || isNaN(this.state.propertyUnit.YearConstructed)  || this.state.propertyUnit.YearConstructed == 0 || this.state.propertyUnit.YearConstructed == "" || this.state.propertyUnit.YearConstructed == null? "null" : this.state.propertyUnit.YearConstructed,
                RequestedRent: !this.state.propertyUnit.RequestedRent || isNaN(this.state.propertyUnit.RequestedRent)  || this.state.propertyUnit.RequestedRent == 0 || this.state.propertyUnit.RequestedRent == "" || this.state.propertyUnit.RequestedRent == null ? "null" : this.state.propertyUnit.RequestedRent,
                ApprovedRent: !this.state.propertyUnit.ApprovedRent || isNaN(this.state.propertyUnit.ApprovedRent) || this.state.propertyUnit.ApprovedRent == 0 || this.state.propertyUnit.ApprovedRent == "" || this.state.propertyUnit.ApprovedRent == null ? "null" : this.state.propertyUnit.ApprovedRent,
                BathRoom: this.state.propertyUnit.BathRoom,
                SquireFoot: !this.state.propertyUnit.SquireFoot || isNaN(this.state.propertyUnit.SquireFoot)  || this.state.propertyUnit.SquireFoot == 0 || this.state.propertyUnit.SquireFoot == "" || this.state.propertyUnit.SquireFoot == null ? "null" : this.state.propertyUnit.SquireFoot,
                ApplicationFee: !this.state.propertyUnit.ApplicationFee || isNaN(this.state.propertyUnit.ApplicationFee)  || this.state.propertyUnit.ApplicationFee == null || this.state.propertyUnit.ApplicationFee == 0 || this.state.propertyUnit.ApplicationFee == "" ? "null" : this.state.propertyUnit.ApplicationFee,
                Smoking: this.state.propertyUnit.Smoking === true ? "Smoking" : "Non-smoking",
                IsUnitHaveElevator: this.state.propertyUnit.IsUnitHaveElevator == true ? "Yes" : "No",
                IsRentalInsuranceRequired: this.state.propertyUnit.IsRentersInsuranceRequired === true ? "Required" : "Optional",
                IsUnitHaveWaterHeater: this.state.propertyUnit.IsUnitHaveWaterHeater === true ? "Exclusive" : "Shared",
                PetsAllowed: this.state.propertyUnit.PetsAllowed === true ? "Yes" : "No",
                PetsDepositAmount: !this.state.propertyUnit.PetsDepositAmount || isNaN(this.state.propertyUnit.PetsDepositAmount)  || this.state.propertyUnit.PetsDepositAmount == 0 || this.state.propertyUnit.PetsDepositAmount == "" ? "null" : this.state.propertyUnit.PetsDepositAmount,
                PetsMonthlyAmount: !this.state.propertyUnit.PetsMonthlyAmount || isNaN(this.state.propertyUnit.PetsMonthlyAmount)  || this.state.propertyUnit.PetsMonthlyAmount == 0 || this.state.propertyUnit.PetsMonthlyAmount == "" ? "null" : this.state.propertyUnit.PetsMonthlyAmount,
                SalesforceCommunityId: this.state.propertyDetails.SalesforceCommunityId,
                Status: status,
                AWS_UnitID: this.state.propertyUnit.PropertyUnitID,
                AWS_UpdatedBy: "AWS",
                SalesforceUnitId: isInsert ? "" : this.state.propertyUnit.SalesforceUnitId,
                SalesforceCompanyId: this.state.propertyUnit.SalesforcePortalUserCompanyId, //this.state.propertyOwner.SalesforceCompanyId,//"0017j00000l0v2jAAA",
                OwnerCertification: "test",
                HFACommencementDate: hfaDate,
                Parking: parkingList.length > 0 ? parkingList.join(';') : "",
                Indoor: indoorList.length > 0 ? indoorList.join(';') : "",
                CommunityAmenities: outdoorList.length > 0 ? outdoorList.join(';') : "",
                Exterior: exteriorList.length > 0 ? exteriorList.join(';') : "",
                Kitchen: kitchenList.length > 0 ? kitchenList.join(';') : "",
                LaundryType: laudryTypeList.length > 0 ? laudryTypeList.join(';') : "",
                ADAAmenities: adaList.length > 0 ? adaList.join(';') : "",
                HeatType: heatType,
                CoolingType: coolingType,
                LeadBasedPaint: leadBaseList.length > 0 ? leadBaseList.join(';') : "",
                Subsidy: subsidy.length > 0 ? subsidy.join(';') : "",
                USFStatus: this.state.propertyUnit.USFStatus,
                HFAStatus: this.state.propertyUnit.HFAStatus,
                PetDocuments: petDocumentList.length > 0 ? petDocumentList.join(';') : "",
                ADANotes: this.state.propertyUnit.IsOtherAccessibility ? this.state.propertyUnit.HandicapeTitle : "",
                PetNotes: this.state.propertyUnit.IsOther ? this.state.propertyUnit.PetsOtherTitle : "",
                SubsidyTitle: this.state.subsidy.Other ? this.state.subsidy.OtherTitle : "",

                OCAddressOne: this.state.propertyUnit.OCAddressOne,
                OCUnitNoOne: this.state.propertyUnit.OCUnitNoOne,
                OCRentalAmountOne: isNaN(this.state.propertyUnit.OCRentalAmountOne)  || this.state.propertyUnit.OCRentalAmountOne == 0 || this.state.propertyUnit.OCRentalAmountOne == "" ? "null" : this.state.propertyUnit.OCRentalAmountOne,
                OCRentedDateOne: this.state.propertyUnit.OCRentedDateOne == "0001-01-01T00:00:00" ? "" : this.state.propertyUnit.OCRentedDateOne,

                OCAddressTwo: this.state.propertyUnit.OCAddressTwo,
                OCUnitNoTwo: this.state.propertyUnit.OCUnitNoTwo,
                OCRentalAmountTwo: isNaN(this.state.propertyUnit.OCRentalAmountTwo)  || this.state.propertyUnit.OCRentalAmountTwo == 0 || this.state.propertyUnit.OCRentalAmountTwo == "" ? "null" : this.state.propertyUnit.OCRentalAmountTwo,
                OCRentedDateTwo: this.state.propertyUnit.OCRentedDateTwo == "0001-01-01T00:00:00" ? "" : this.state.propertyUnit.OCRentedDateTwo,

                OCAddressThree: this.state.propertyUnit.OCAddressThree,
                OCUnitNoThree: this.state.propertyUnit.OCUnitNoThree,
                OCRentalAmountThree: isNaN(this.state.propertyUnit.OCRentalAmountThree)  || this.state.propertyUnit.OCRentalAmountThree == 0 || this.state.propertyUnit.OCRentalAmountThree == "" ? "null" : this.state.propertyUnit.OCRentalAmountThree,
                OCRentedDateThree: this.state.propertyUnit.OCRentedDateThree == "0001-01-01T00:00:00" ? "" : this.state.propertyUnit.OCRentedDateThree,

                HeatingFuelType: heatingFuelType,
                CookingFuelType: cookingFuelType,
                WaterHeatingFuelType: waterHeatingFuelType,

                HeatingPaidBy: this.state.propertyUnit.HeatingPaidBy,
                CookingPaidBy: this.state.propertyUnit.CookingPaidBy,
                WaterHeatingPaidBy: this.state.propertyUnit.WaterHeatingPaidBy,
                OtherElectricPaidBy: this.state.propertyUnit.OtherElectricPaidBy,
                WaterPaidBy: this.state.propertyUnit.WaterPaidBy,
                SewerPaidBy: this.state.propertyUnit.SewerPaidBy,
                TrashCollectionPaidBy: this.state.propertyUnit.TrashCollectionPaidBy,
                OtherPaidBy: this.state.propertyUnit.OtherPaidBy,
                RefrigeratorProvidedBy: this.state.propertyUnit.RefrigeratorProvidedBy,
                RangeMicrowaveProvidedBy: this.state.propertyUnit.RangeMicrowaveProvidedBy,
                MicrowaveProvidedBy: this.state.propertyUnit.MicrowaveProvidedBy,
                // SalesforceContactId: this.state.propertyUnit.AuthorizedSignatoryContact.SalesforceContactId
                SalesforceContactId: this.state.SalesforceContactId,

                OtherUtilityTitle: this.state.propertyUnit.OtherUtilityTitle
            },
        ];

        // Convert JSON to CSV & Display CSV
        var s = this.convertToCSV(items);
        try {
            Storage.configure({
                bucket: process.env.REACT_APP_S3WHOCAdminBucket,
                region: process.env.REACT_APP_Region,
                level: 'public',
            });
            var issueDateTime = new Date(
                new Date().toLocaleString("en-US", { timeZone: "US/Pacific" })
            );
            var todaysDate = [
                issueDateTime.getFullYear(),
                ("0" + (issueDateTime.getMonth() + 1)).slice(-2),
                ("0" + issueDateTime.getDate()).slice(-2),
            ].join("-");

            var todaysTime = [
                ("0" + issueDateTime.getHours()).slice(-2),
                ("0" + issueDateTime.getMinutes()).slice(-2),
                ("0" + issueDateTime.getSeconds()).slice(-2),
            ].join(":");
            let path = "";
            if (isInsert) {
                // path = 'Create-Unit';
                var fileName = "Unit_" + this.state.propertyUnit.PropertyUnitID + "_" + this.state.propertyDetails.PropertyID + "_" + todaysDate + "_" + todaysTime + ".csv";
                var result1 = await Storage.put(`Salesforce/Create-Unit/${fileName}`,
                    s,
                    { contentType: "csv" });
            }
            else {
                //  path = 'Update-Unit';
                var fileName = "Unit_" + this.state.propertyUnit.PropertyUnitID + "_" + this.state.propertyDetails.PropertyID + "_" + todaysDate + "_" + todaysTime + ".csv";
                var result1 = await Storage.put(`Salesforce/Update-Unit/${fileName}`,
                    s,
                    { contentType: "csv" });
            }
            this.setState({ isLoading: false });
        }
        catch (err) {
            console.log(err)
            this.setState({ isLoading: false });
            return;
        }
    }
    convertToCSV(arr) {
        const array = [Object.keys(arr[0])].concat(arr)

        return array.map(it => {
            return Object.values(it).toString()
        }).join('\n')
    }

    OnFormPo_FirstNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ signatory_FirstName: value });
    }
    OnFormPo_LastNameFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ signatory_LastName: value });
    }
    OnFormPo_EmailIDFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ signatory_EmailID: value, IsSignatoryEmailUpdated: true });
    }
    OnFormPo_PhoneFieldChanged(field, e) {
        const { value } = e.target;
        this.setState({ signatory_Phone: value });
    }
    getCentralContacts = async () => {
        this.setState({ isLoading: true });
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getcentralcontactlist';

        let myInit = { // OPTIONAL
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    getPeoplePropContactById = async (Id) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpeoplepropcontactbyid';

        let myInit = { // OPTIONAL
            body: Id.toString(),
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    clearPropertyOwner = async (Id) => {
        this.setState({
            signatory_FirstName: '',
            signatory_LastName: '',
            signatory_EmailID: '',
            signatory_Phone: ''
        });
    }
    updateAuthorizedSignatory = async (Id) => {
        this.setState({ isLoading: true });
        //$(this).removeAttr('disabled');
        //$('[id*=propOwner_]').attr('disabled','disabled');

        try {
            let res = await this.getPeoplePropContactById(Id);
            this.setState({ isLoading: false });
            this.setState({
                signatory_FirstName: res['FirstName'],
                signatory_LastName: res['LastName'],
                //  po_Address: res['Address'],
                //  po_City: res['City'],
                //  po_State: res['State'],
                //  po_Zip: res['Zip'],
                signatory_EmailID: res['EmailID'] == null ? "" : res['EmailID'],
                signatory_Phone: res['Phone'],
                SalesforceContactId: res['SalesforceContactId'],
                Role: res['Role']
            });
            setTimeout(() => {
                // $('[id*=signatory_]').attr('disabled', 'disabled');
                if (res['EmailID'] == "" || res['EmailID'] == null) {
                    document.getElementById('signatory_EmailID').disabled = false;
                }
                else {
                    document.getElementById('signatory_EmailID').disabled = true;
                }
                document.getElementById('signatory_FirstName').disabled = true;
                document.getElementById('signatory_LastName').disabled = true;
                document.getElementById('signatory_Phone').disabled = true;
            }, 100);
            //this.state.propertyDetails.propertyOwner = this.state.propertyOwner;
        }
        catch (e) {
            this.setState({ isLoading: false });
            this.setState({ openSnackbar: true });
            this.setState({ snackbarMessage: 'Something went wrong.' });
            this.setState({ snackbarMessageClass: 'Mui-filledError' });
            console.log(e)
        }
    }

    getUnitSalesforceId = async (id) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getsalesforceunitid';
        
        let myInit = {
            body: id.toString(),
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }

    render() {
        // if (this.state.redirectToUnitPage) {
        //     return <Redirect to={this.state.redirectToUnitPage} />
        // }
        // if (!this.state.propertyList) {
        //     return <div />
        // }
        if (this.showHTML === true) {
            return (
                <div className="property-detail-main">
                    <div className="data-section">
                        <div className="" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                                <form name="contactform" className="contactform whoc-radio" onSubmit={this.addUnit.bind(this)}>
                                    <div className="row mb-4">
                                        <div className="col-md-9">
                                            <div className="h-100 box-gray">
                                                <h2 class="profile-title clearfix"><i class="fas fa-building mr-2"></i><span>Unit Details</span>
                                                    <span className="property-grid-add">
                                                    { !this.state.isReadOnly ? (
                                                        <button type="button" disabled={this.state.isReadOnly} class="btn btn-primary btn-dark whoc-btn" onClick={this.openAddPropertyPopup.bind(this)}>
                                                            <i class="fas fa-plus-circle text-white mr-1"></i>Add Property
                                                        </button> ) : null }

                                                        {this.state.isReadOnly ? //disabled={this.state.propertyUnit.HFAStatus !== "Pending"}
                                                            <button type="button" class="btn btn-primary btn-dark whoc-btn ml-2" onClick={this.generateUSF.bind(this)}>
                                                                <i class="fas fa-file-contract text-white mr-1"></i>{this.state.propertyUnit.USFStatus !== "Pending" ? "Re-Generate USF" : "Generate USF"}
                                                            </button>
                                                            : null}

                                                        {this.state.isReadOnly ? //disabled={this.state.propertyUnit.HFAStatus !== "Pending"}
                                                            <button type="button" class="btn btn-primary btn-dark whoc-btn ml-2" onClick={this.generateHFA.bind(this)}>
                                                                <i class="fas fa-file-contract text-white mr-1"></i> {this.state.propertyUnit.HFAStatus !== "Pending" ? "Re-Generate HFA" : "Generate HFA"}
                                                            </button>
                                                            : null}
                                                    </span>
                                                </h2>
                                                <div className="box-details">
                                                    <div class="form-row search-area">
                                                        <div class="form-group col-md-2">
                                                            <label>Submitted Date</label>
                                                            <input id="date" disabled type="date" value={this.state.propertyUnit.SubmitedFrom} onChange={this.OnOtherFormFiledChanged.bind(this, "SubmitedFrom")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                            <span class="alert-small-text">{this.state.validationErrors["SubmitedFrom"]}</span>
                                                            {/* <input className="form-control" type="text" maxlength="100" placeholder="Property Name" value={this.state.propertyDetails.PropertyName}></input> */}
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Available Date </label>
                                                            <Tooltip title="Date unit is ready for move-in">
                                                                <i className="fa fa-question-circle ml-2"></i>
                                                            </Tooltip>
                                                            <input id="date" type="date" value={this.state.propertyUnit.AvailableFrom} onChange={this.OnOtherFormFiledChanged.bind(this, "AvailableFrom")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                            <span class="alert-small-text">{this.state.validationErrors["AvailableFrom"]}</span>
                                                            {/* <input className="form-control" type="text" maxlength="100" placeholder="Property Name" value={this.state.propertyDetails.PropertyName}></input> */}
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Property <i class="fas fa-asterisk asterisk"></i></label>
                                                            <Tooltip title="Select a previously enrolled property or add a new one with the 'Add Property' button (upper right)">
                                                                <i className="fa fa-question-circle ml-2"></i>
                                                            </Tooltip>
                                                            <select class="form-control" value={this.state.propertyUnit.PropertyID} name="cars" id="cars" onChange={this.OnPropertyChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                {this.renderPropertyDropdown()}
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["PropertyID"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <label>Property Management Company</label>
                                                            <input disabled className="form-control" type="text" value={this.state.propertyDetails && this.state.propertyDetails.ManagementCompany && this.state.propertyDetails.ManagementCompany.Title ? this.state.propertyDetails.ManagementCompany.Title : ""} maxlength="200"></input>
                                                        </div>
                                                        <div class="form-group col-md-3">
                                                            <fieldset>
                                                                <label>Address <i class="fas fa-asterisk asterisk"></i></label>
                                                                <input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "Address")} maxlength="100" value={this.state.propertyUnit.Address}></input>
                                                                <span class="alert-small-text">{this.state.validationErrors["Address"]}</span>
                                                            </fieldset>
                                                        </div>
                                                        <div class="form-group col-md-3 col-xl-2">
                                                            <label>Unit Number</label>
                                                            <input className="form-control" type="text" maxlength="40" onChange={this.OnOtherFormFiledChanged.bind(this, "Unit")} value={this.state.propertyUnit.Unit}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Unit"]}</span>
                                                        </div>

                                                        <div class="form-group col-md-2">
                                                            <label>City <i class="fas fa-asterisk asterisk"></i></label>
                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnOtherFormFiledChanged.bind(this, "City")} value={this.state.propertyUnit.City}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["City"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>State</label>
                                                            <select className="form-control" ref={(input) => this.stateSelector = input} onChange={this.OnOtherFormFiledChanged.bind(this, "State")} value={this.state.propertyUnit.State}>
                                                                <option value="">--Select--</option>
                                                                <option value="AA"> AA </option>
                                                                <option value="AE"> AE </option>
                                                                <option value="AK"> AK </option>
                                                                <option value="AL"> AL </option>
                                                                <option value="AP"> AP </option>
                                                                <option value="AR"> AR </option>
                                                                <option value="AS"> AS </option>
                                                                <option value="AZ"> AZ </option>
                                                                <option value="CA"> CA </option>
                                                                <option value="CO"> CO </option>
                                                                <option value="CT"> CT </option>
                                                                <option value="DC"> DC </option>
                                                                <option value="DE"> DE </option>
                                                                <option value="FL"> FL </option>
                                                                <option value="GA"> GA </option>
                                                                <option value="GU"> GU </option>
                                                                <option value="HI"> HI </option>
                                                                <option value="IA"> IA </option>
                                                                <option value="ID"> ID </option>
                                                                <option value="IL"> IL </option>
                                                                <option value="IN"> IN </option>
                                                                <option value="KS"> KS </option>
                                                                <option value="KY"> KY </option>
                                                                <option value="LA"> LA </option>
                                                                <option value="MA"> MA </option>
                                                                <option value="MD"> MD </option>
                                                                <option value="ME"> ME </option>
                                                                <option value="MI"> MI </option>
                                                                <option value="MN"> MN </option>
                                                                <option value="MO"> MO </option>
                                                                <option value="MP"> MP </option>
                                                                <option value="MS"> MS </option>
                                                                <option value="MT"> MT </option>
                                                                <option value="NC"> NC </option>
                                                                <option value="ND"> ND </option>
                                                                <option value="NE"> NE </option>
                                                                <option value="NH"> NH </option>
                                                                <option value="NJ"> NJ </option>
                                                                <option value="NM"> NM </option>
                                                                <option value="NV"> NV </option>
                                                                <option value="NY"> NY </option>
                                                                <option value="OH"> OH </option>
                                                                <option value="OK"> OK </option>
                                                                <option value="OR"> OR </option>
                                                                <option value="PA"> PA </option>
                                                                <option value="PR"> PR </option>
                                                                <option value="RI"> RI </option>
                                                                <option value="SC"> SC </option>
                                                                <option value="SD"> SD </option>
                                                                <option value="TN"> TN </option>
                                                                <option value="TX"> TX </option>
                                                                <option value="UT"> UT </option>
                                                                <option value="VA"> VA </option>
                                                                <option value="VI"> VI </option>
                                                                <option value="VT"> VT </option>
                                                                <option value="WA"> WA </option>
                                                                <option value="WI"> WI </option>
                                                                <option value="WV"> WV </option>
                                                                <option value="WY"> WY </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["State"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Zip</label>
                                                            <input className="form-control" type="text" maxlength="5" onChange={this.OnOtherFormFiledChanged.bind(this, "Zipcode")} value={this.state.propertyUnit.Zipcode}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["Zipcode"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Year Constructed</label>
                                                            <input className="form-control" type="text" maxlength="4" onChange={this.OnOtherFormFiledChanged.bind(this, "YearConstructed")} value={this.state.propertyUnit.YearConstructed}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["YearConstructed"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Proposed Rent $</label>
                                                            <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "RequestedRent")} value={this.state.propertyUnit.RequestedRent}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["RequestedRent"]}</span>
                                                        </div>
                                                        {/* <div class="form-group col-md-2">
                                                    <label>Number of Bedroom</label>
                                                    <input className="form-control" type="text" maxlength="4"  placeholder="Number of Bedroom" onChange={this.OnOtherFormFiledChanged.bind(this, "NumberofBedrooms")} value={this.state.propertyUnit.NumberofBedrooms}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["NumberofBedrooms"]}</span>
                                                </div> */}
                                                        <div class="form-group col-md-2">
                                                            {/* <input type="checkbox" class="custom-control-input" id="Paidby" value={this.state.coolingType.Paidby} onChange={this.OnFormFieldChanged.bind(this,'coolingType')} name="Paidby" /> */}
                                                            <label>Number of Bedrooms <i class="fas fa-asterisk asterisk"></i></label>
                                                            <select class="form-control" value={this.state.propertyUnit.NumberofBedrooms} name="NumberofBedrooms" id="NumberofBedrooms" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                <option value="SRO">SRO </option>
                                                                <option value="Studio">Studio </option>
                                                                <option value="1">1 </option>
                                                                <option value="2">2 </option>
                                                                <option value="3">3 </option>
                                                                <option value="4">4 </option>
                                                                <option value="5">5 </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["NumberofBedrooms"]}</span>
                                                        </div>
                                                        {/* <div class="form-group col-md-2">
                                                    <label>Number of Bathroom</label>
                                                    <input className="form-control" type="text"  maxlength="4" maxlength="4" placeholder="Number of Bathroom" onChange={this.OnOtherFormFiledChanged.bind(this, "BathRoom")} value={this.state.propertyUnit.BathRoom}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["BathRoom"]}</span>
                                                </div> */}
                                                        <div class="form-group col-md-2">
                                                            {/* <input type="checkbox" class="custom-control-input" id="Paidby" value={this.state.coolingType.Paidby} onChange={this.OnFormFieldChanged.bind(this,'CoolingType')} name="Paidby" /> */}
                                                            <label>Number of Bathrooms</label>
                                                            <select class="form-control" value={this.state.propertyUnit.BathRoom} name="BathRoom" id="BathRoom" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                <option value="1">1 </option>
                                                                <option value="1.5">1.5 </option>
                                                                <option value="2">2 </option>
                                                                <option value="2.5">2.5 </option>
                                                                <option value="3">3 </option>
                                                                <option value="3.5">3.5 </option>
                                                                <option value="4">4 </option>
                                                                <option value="4.5">4.5 </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["BathRoom"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Square Footage</label>
                                                            <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "SquireFoot")} value={this.state.propertyUnit.SquireFoot}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["SquireFoot"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Application Fees $</label>
                                                            <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "ApplicationFee")} value={this.state.propertyUnit.ApplicationFee}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["ApplicationFee"]}</span>
                                                        </div>
                                                        <div class="form-group col-md-2">
                                                            <label>Status <i class="fas fa-asterisk asterisk"></i></label>
                                                            <select className="form-control" value={this.state.propertyUnit.Status} onChange={this.OnOtherFormFiledChanged.bind(this, "Status")}>
                                                                <option value="">-- Select --</option>
                                                                <option value="Submitted">Submitted</option>
                                                                <option value="Under Rent Review">Under Rent Review</option>
                                                                <option value="Rent Discussion">Rent Discussion</option>
                                                                <option value="Holding Fee Agreement Needed">Holding Fee Agreement Needed</option>
                                                                <option value="Active">Active</option>
                                                                <option value="Matched">Matched</option>
                                                                <option value="Leased">Leased</option>
                                                                <option value="Inactive">Inactive</option>
                                                                <option value="Not Ready">Not Ready</option>
                                                                {/* <option value="Not Needed">Not Needed</option>
                                                                <option value="Negotiation">Negotiation</option>
                                                                <option value="In Review">In Review</option>
                                                                <option value="HFA Sent">HFA Sent</option> */}

                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["Status"]}</span>
                                                        </div>
                                                        {this.state.isReadOnly ? //disabled={this.state.propertyUnit.HFAStatus !== "Pending"}
                                                            <div class="form-group col-md-2">
                                                                <label>Approved Rent</label>
                                                                <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "ApprovedRent")} value={this.state.propertyUnit.ApprovedRent}></input>
                                                                <span class="alert-small-text">{this.state.validationErrors["ApprovedRent"]}</span>
                                                            </div>
                                                            : null}

                                                        {this.state.isReadOnly ?
                                                            <div class="form-group col-md-2">
                                                                <label>HFA (Holding Fee Agreement) status</label>
                                                                <select className="form-control" disabled value={this.state.propertyUnit.HFAStatus}>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Sent">Sent</option>
                                                                    <option value="Signed">Signed</option>
                                                                </select>
                                                            </div>
                                                            : null}
                                                        {this.state.isReadOnly ? // disabled={this.state.propertyUnit.HFAStatus !== "Pending"}
                                                            <div class="form-group col-md-2">
                                                                <label>HFA Commencement Date</label>
                                                                <input id="hfadate" type="date" value={this.state.propertyUnit.HFACommencementDate} onChange={this.OnOtherFormFiledChanged.bind(this, "HFACommencementDate")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                                <span class="alert-small-text">{this.state.validationErrors["HFACommencementDate"]}</span>
                                                                {/* <input className="form-control" type="text" maxlength="100" placeholder="Property Name" value={this.state.propertyDetails.PropertyName}></input> */}
                                                            </div>
                                                            : null}
                                                        {this.state.isReadOnly ?
                                                            <div class="form-group col-md-2">
                                                                <label>USF (Unit Submission Form) status</label>
                                                                <select className="form-control" disabled value={this.state.propertyUnit.USFStatus}>
                                                                    <option value="Pending">Pending</option>
                                                                    <option value="Sent for Review">Sent for Review</option>
                                                                    <option value="Completed">Completed</option>
                                                                </select>
                                                            </div>
                                                            : null}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 text-center">
                                            <div className="h-100 box-gray img-box">
                                                <img src={this.state.imageUploaded == true ? this.state.selectedFile : this.state.unitImagePath === '' ? homeBG : this.state.unitImagePath} width="150" height="150" className="rounded-circle" alt="home" loading="lazy" />
                                                <div className="custom-file-upload">
                                                    <div className="custom-upload-inner">
                                                        <label className="btn btn-primary">
                                                            Browse <input type="file" onChange={this.OnUploadImageChanged.bind(this)} />
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <p className="wh-note"><span>Note:</span> All the fields added in this form are required to be filled.</p> */}
                                    <div class="row mt-3 mb-2">
                                        <div class="col">
                                            <div class="wh-note">
                                                <span>Note:</span>
                                                {/* <li class="ml-4"> All the fields added in this form are required to be filled.</li> */}
                                                <li class="ml-4"> All of the provided information about the unit will be used to complete the WelcomeHomeOC Unit Submission Form and the US Department of Housing and Urban Development Request for Tenancy Approval form. These documents will be used to determine rent reasonableness with the public housing authority and then sent to you for your review and signature.</li>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        {/* <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Property Information</h2>
                                <button type="button" class="btn btn-primary" onClick = {this.openAddPropertyPopup.bind(this)}>Add Property</button> */}
                                        <h2 class="profile-title clearfix"><i class="fas fa-house-user mr-2"></i><span>Authorized Signatory</span>
                                            {/* <span className="property-grid-add">
                                            <button type="button" disabled={this.state.isReadOnly} class="btn btn-primary btn-dark whoc-btn" onClick={this.openAddPropertyPopup.bind(this)}>
                                                <i class="fas fa-plus-circle text-white mr-1"></i>Add Property
                                            </button>
                                        </span> */}
                                        </h2>
                                        <div className="box-details">

                                            {/* {this.state.isReadOnly === false && */}
                                            <div className="row">
                                                <div className="col-auto">
                                                    <label><b>Is the authorized signer someone other than you?</b></label>
                                                </div>
                                                <div className="col-auto">
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AuthorizedSignatoryDifferentTrue" name="IsAuthorizedSignatoryDifferent" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsAuthorizedSignatoryDifferent === true}
                                                            value="true" onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="AuthorizedSignatoryDifferentTrue">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline">
                                                        <input type="radio" id="AuthorizedSignatoryDifferentFalse" name="IsAuthorizedSignatoryDifferent" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsAuthorizedSignatoryDifferent === false}
                                                            value="false" onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="AuthorizedSignatoryDifferentFalse">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* } */}

                                            {this.state.propertyUnit.IsAuthorizedSignatoryDifferent === true ?
                                                (
                                                    // vishnu
                                                    <div class="form-row search-area">
                                                        <div class="form-group col-sm-3">
                                                            <label>Signatory</label>
                                                            <Autocomplete className="uw-autocomplete"
                                                                value={this.state.selectedAuthorizedSignatory}
                                                                onChange={(event, newValue1) => {
                                                                    this.setState({ IsAuthorizedSignatoryChanged: true });
                                                                    if (typeof newValue1 === 'string') {
                                                                        this.setState({ selectedAuthorizedSignatory: newValue1 });
                                                                    } else if (newValue1 && newValue1.inputValue) {
                                                                        this.state.centralContacts.push({ title: newValue1.inputValue, Id: "0" });
                                                                        this.setState({ selectedAuthorizedSignatory: { title: newValue1.inputValue, Id: "0" } });
                                                                        $('[id*=signatory_]').removeAttr('disabled');
                                                                        this.clearPropertyOwner();
                                                                    } else {
                                                                        this.setState({ selectedAuthorizedSignatory: newValue1 });
                                                                        if (newValue1) {
                                                                            this.updateAuthorizedSignatory(newValue1.Id.split('_')[1]);
                                                                        } else {
                                                                            $('[id*=signatory_]').removeAttr('disabled');
                                                                            this.clearPropertyOwner();
                                                                        }
                                                                    }
                                                                }}
                                                                filterOptions={(options, params) => {
                                                                    const filtered = filter(options, params);
                                                                    let isFound = false;
                                                                    filtered.forEach(e => {
                                                                        if (e.title === params.inputValue) {
                                                                            isFound = true;
                                                                        }
                                                                    });
                                                                    // Suggest the creation of a new value
                                                                    if (params.inputValue !== '' && !isFound) {
                                                                        filtered.push({
                                                                            //inputValue: params.inputValue,
                                                                            //title: `Add "${params.inputValue}"`,
                                                                            inputValue: `Please Add Info.`,
                                                                            //title: `No Match. Add New.`,
                                                                            title: `+ New Signatory "${params.inputValue}"`,
                                                                        });
                                                                    }
                                                                    return filtered;
                                                                }}
                                                                selectOnFocus
                                                                clearOnBlur
                                                                handleHomeEndKeys
                                                                //autoComplete={true}
                                                                autoSelect={true}
                                                                id="PropertyOwner"
                                                                options={this.state.centralContacts}
                                                                getOptionLabel={(option) => {
                                                                    // Value selected with enter, right from the input
                                                                    if (typeof option === 'string') {
                                                                        return option;
                                                                    }
                                                                    // Add "xyz" option created dynamically
                                                                    if (option.inputValue) {
                                                                        return option.inputValue;
                                                                    }
                                                                    // Regular option
                                                                    return option.title;
                                                                }}
                                                                renderOption={(option) => option.title}
                                                                // style={{ width: 300 }}
                                                                //freeSolo
                                                                renderInput={(params) => (
                                                                    <TextField {...params} className="form-control" />
                                                                )}
                                                            />
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>First Name</label>
                                                            <input className="form-control" type="text" maxlength="100" id="signatory_FirstName" value={this.state.signatory_FirstName} onChange={this.OnFormPo_FirstNameFieldChanged.bind(this, "signatory_FirstName")}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["signatory_FirstName"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Last Name</label>
                                                            <input className="form-control" type="text" maxlength="100" id="signatory_LastName" value={this.state.signatory_LastName} onChange={this.OnFormPo_LastNameFieldChanged.bind(this, "signatory_LastName")}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["signatory_LastName"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-3">
                                                            <label>Email</label>
                                                            <input className="form-control" type="text" maxlength="255" id="signatory_EmailID" value={this.state.signatory_EmailID} onChange={this.OnFormPo_EmailIDFieldChanged.bind(this, "signatory_EmailID")}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["signatory_EmailID"]}</span>
                                                        </div>
                                                        <div class="form-group col-sm-2">
                                                            <label>Phone</label>
                                                            {/* <input className="form-control" type="text" maxlength="14" value={(this.state.po_Phone === null || this.state.po_Phone === undefined) ? '' : this.state.po_Phone.replace('+1', '')} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "po_Phone")}></input> */}
                                                            <input className="form-control" type="text" maxlength="14" id="signatory_Phone" value={(this.state.signatory_Phone)} onChange={this.OnFormPo_PhoneFieldChanged.bind(this, "signatory_Phone")}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["signatory_Phone"]}</span>
                                                        </div>
                                                        {/* <div class="form-group col-md-3">
                                                        <label>Full Name</label>
                                                        <input className="form-control" type="text" value={this.state.propertyUnit.AuthorizedSignatoryFullName} onChange={this.OnOtherFormFiledChanged.bind(this, "AuthorizedSignatoryFullName")} maxlength="200"></input>
                                                        <span class="alert-small-text">{this.state.validationErrors["AuthorizedSignatoryFullName"]}</span>
                                                    </div>
                                                    <div class="form-group col-md-2">
                                                        <label>Email</label>
                                                        <input className="form-control" type="text" value={this.state.propertyUnit.AuthorizedSignatoryEmailID} onChange={this.OnOtherFormFiledChanged.bind(this, "AuthorizedSignatoryEmailID")} maxlength="200"></input>
                                                        <span class="alert-small-text">{this.state.validationErrors["AuthorizedSignatoryEmailID"]}</span>
                                                    </div>
                                                    <div class="form-group col-md-2">
                                                        <label>Phone</label>
                                                        <input className="form-control" type="text" value={this.state.propertyUnit.AuthorizedSignatoryPhone} onChange={this.OnOtherFormFiledChanged.bind(this, "AuthorizedSignatoryPhone")} maxlength="14"></input>
                                                        <span class="alert-small-text">{this.state.validationErrors["AuthorizedSignatoryPhone"]}</span>
                                                    </div> */}
                                                    </div>)
                                                :
                                                (<div class="form-row search-area">
                                                    {/* <div class="form-group col-md-2">
                                                <label>Property</label>
                                                <select class="form-control" disabled={this.state.isReadOnly} value={this.state.propertyUnit.PropertyID} name="cars" id="cars" onChange={this.OnPropertyChanged.bind(this)}>
                                                    <option>Please Select</option>
                                                    {this.renderPropertyDropdown()}
                                                </select>
                                                <span class="alert-small-text">{this.state.validationErrors["PropertyID"]}</span>
                                                
                                            </div>
                                            
                                            <div class="form-group col-md-3">
                                                <label>Property Management Company</label>
                                                <input disabled className="form-control" type="text" value={this.state.propertyDetails.ManagementCompany} maxlength="200"></input>
                                            </div> */}
                                                    <div class="form-group col-md-3">
                                                        <label>Full Name</label>
                                                        <input className="form-control" disabled type="text" value={(this.state.propertyOwner && this.state.propertyOwner.FirstName && this.state.propertyOwner.LastName) ? (this.state.propertyOwner.FirstName + " " + this.state.propertyOwner.LastName) : ""} maxlength="200"></input>
                                                    </div>
                                                    <div class="form-group col-md-2">
                                                        <label>Email</label>
                                                        <input className="form-control" disabled type="text" value={this.state.propertyOwner && this.state.propertyOwner.EmailID ? this.state.propertyOwner.EmailID : ""} maxlength="200"></input>
                                                    </div>
                                                    <div class="form-group col-md-2">
                                                        <label>Phone</label>
                                                        <input className="form-control" disabled type="text" value={(!this.state.propertyOwner || this.state.propertyOwner.Phone === null || this.state.propertyOwner.Phone === undefined) ? '' : this.state.propertyOwner.Phone.replace('+1', '').replace('(', '').replace(')', '').replace('-', '').replace(' ', '')} maxlength="200"></input>
                                                    </div>
                                                </div>)
                                            }

                                        </div>
                                    </div>
                                    {this.state.managers.length > 0 ? (
                                        <div className="box-gray mb-4">
                                            <h2 class="profile-title"><i class="fas fa-user-tie mr-2"></i>Property Manager<span className="property-grid-add"></span></h2>
                                            <div className="box-details">
                                                <div className='property-grid table-responsive'>
                                                    <table className={"table " + (this.state.managers.length > 3 ? "table-scroll" : "")}>
                                                        <thead>
                                                            <tr>
                                                                <th width="50%" scope="col">First Name</th>
                                                                <th width="50%" scope="col">Last Name</th>
                                                                <th width="50%" scope="col">Email</th>
                                                                <th width="50%" scope="col">Phone</th>
                                                                <th width="50%" scope="col">Extension</th>
                                                                <th width="50%" scope="col">Additional Phone</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {this.state.managers.map(item => (
                                                                <tr key={item}>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="10" value={item.FirstName}></input></td>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="10" value={item.LastName}></input></td>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="200" value={item.EmailID}></input></td>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="14" value={item.Phone}></input></td>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="10" value={item.PhoneExtension}></input></td>
                                                                    <td width="50%"><input className="form-control" disabled type="text" maxlength="14" value={item.AdditionalPhone}></input></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            {/* <div className="box-details">
                                    <div className='property-grid'>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-11">
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <label>First Name</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Last Name</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Email</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Phone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {this.state.managers.length > 0 && this.state.managers.filter(item => item.Title.trim() === 'Manager').map(function(item,i){
                                        return <div className="row" key={i}>
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-11">
                                                        <div className="row">
                                                             <div className="col-2">
                                                                <label>{item.FirstName}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                 <label>{item.LastName}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                 <label>{item.Emailid}</label>
                                                            </div>
                                                            <div className="col-2">
                                                               <label>{item.Phone}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>
                                            </div>
                                        </div>;
                                         })}
                                    </div>
                                </div> */}
                                        </div>
                                    ) : ""}
                                    {/* <div className="box-gray mb-4">
                                <h2 class="profile-title"><i class="fas fa-user mr-2"></i>Property Owner<span className="property-grid-add"></span></h2>
                                <div className="box-details">
                                    <div className='property-grid'>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-11">
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <label>First Name</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Last Name</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Email</label>
                                                            </div>
                                                            <div className="col-2">
                                                                <label>Phone</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <div className="row">
                                                    <div className="col-11">
                                                        <div className="row">
                                                            <div className="col-2">
                                                                <label>{this.state.propertyOwner && this.state.propertyOwner.FirstName}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                 <label>{this.state.propertyOwner && this.state.propertyOwner.LastName}</label>
                                                            </div>
                                                            <div className="col-2">
                                                                 <label>{this.state.propertyOwner && this.state.propertyOwner.EmailID}</label>
                                                            </div>
                                                            <div className="col-2">
                                                               <label>{this.state.propertyOwner && this.state.propertyOwner.Phone}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-1"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                                    <div className="box-gray mb-4 viewpage">
                                        <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Amenities and Accessibility</h2>
                                        <div className="box-details whoc-radio whoc-multi-fields">
                                            <div class="form-row search-area align-items-center">
                                                {/* <div class="form-group col-sm-3">
                                        <label># Bathrooms</label>
                                        <input  type="text" className="form-control"/>
                                    </div>
                                    <div class="form-group col-sm-3">
                                        <label>Square Footage</label>
                                        <input  type="text" className="form-control"/>
                                    </div> */}
                                                {/* <div class="form-group col-auto mt-2">
                                        <label>Unit Level</label>
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" />
                                            <label class="custom-control-label" for="customCheck1">Upstairs</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" class="custom-control-input" />
                                            <label class="custom-control-label" for="customCheck1">Downstairs</label>
                                        </div>
                                    </div> */}

                                            </div>
                                            <div className="form-row">
                                                <div class="col-md-2 border-right">
                                                    <label className="mr-2">Unit Smoking Policy</label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Smoking"]}</div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="Smoking" id="SmokingTrue" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.Smoking === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="SmokingTrue">Smoking</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="Smoking" value="false" class="custom-control-input" id="SmokingFalse"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.Smoking === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="SmokingFalse">Non-smoking</label>
                                                    </div>

                                                </div>
                                                <div class="col-md-2 border-right">
                                                    <label className="mr-2">Unit Level </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["UnitLevel"]}</div>
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="Upstairs" value="Upstairs" class="custom-control-input" name="UnitLevel" data-text="Upstairs"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.UnitLevel === "Upstairs"}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label for="Upstairs" class="custom-control-label">Upstairs</label>
                                                    </div>
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="Downstairs" class="custom-control-input" name="UnitLevel" data-text="Downstairs"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.UnitLevel === "Downstairs"}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label for="Downstairs" class="custom-control-label">Downstairs</label>
                                                    </div>
                                                    <div class="custom-control custom-radio">
                                                        <input type="radio" id="Multi-level" class="custom-control-input" name="UnitLevel" data-text="Multi-level"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.UnitLevel === "Multi-level"}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label for="Multi-level" class="custom-control-label">Multi-level</label>
                                                    </div>

                                                </div>

                                                <div className="col-md-2 border-right">
                                                    <label>Is the unit accessible by elevator?</label>
                                                    <div class="alert-small-text">{this.state.validationErrors["IsUnitHaveElevator"]}</div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsUnitHaveElevator" id="exampleRadios1" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveElevator === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="exampleRadios1">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsUnitHaveElevator" value="false" class="custom-control-input" id="exampleRadios2"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveElevator === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="exampleRadios2">No</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 border-right">
                                                    <label>Is Rental Insurance Required?</label>
                                                    <div class="alert-small-text">{this.state.validationErrors["IsRentersInsuranceRequired"]}</div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsRentersInsuranceRequired" id="IsRentersInsuranceRequiredTrue" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsRentersInsuranceRequired === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="IsRentersInsuranceRequiredTrue">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsRentersInsuranceRequired" value="false" class="custom-control-input" id="IsRentersInsuranceRequiredFalse"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsRentersInsuranceRequired === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="IsRentersInsuranceRequiredFalse">No</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <label>Does the unit have its own water heater?</label>
                                                    <div class="alert-small-text">{this.state.validationErrors["IsUnitHaveWaterHeater"]}</div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsUnitHaveWaterHeater" id="UnitHaveWaterHeaterTrue" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveWaterHeater === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="UnitHaveWaterHeaterTrue">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="IsUnitHaveWaterHeater" value="false" class="custom-control-input" id="UnitHaveWaterHeaterFalse"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveWaterHeater === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="UnitHaveWaterHeaterFalse">No</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-row wl-custom-col">
                                                <div className="col-md-2 border-right">
                                                    <label> Heat Type </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["HeatType"]}</div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Baseboard} id="Baseboard" value={this.state.heatType.Baseboard} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Baseboard" />
                                                        <label class="custom-control-label" for="Baseboard">BaseBoard</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="BaseboardTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Baseboard === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Baseboard')} />
                                                        <label class="custom-control-label" for="BaseboardTrue">Baseboard</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Boiler} id="Boiler" value={this.state.heatType.Boiler} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Boiler" />
                                                        <label class="custom-control-label" for="Boiler">Boiler</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="BoilerTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Boiler === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Boiler')} />
                                                        <label class="custom-control-label" for="BoilerTrue">Boiler</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Radiator} id="Radiator" value={this.state.heatType.Radiator} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Radiator" />
                                                        <label class="custom-control-label" for="Radiator">Radiator</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="RadiatorTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Radiator === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Radiator')} />
                                                        <label class="custom-control-label" for="RadiatorTrue">Radiator</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Wall} id="Wall" value={this.state.heatType.Wall} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Wall" />
                                                        <label class="custom-control-label" for="Wall">Wall</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="WallTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Wall === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Wall')} />
                                                        <label class="custom-control-label" for="WallTrue">Wall</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Space} id="Space" value={this.state.heatType.Space} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Space" />
                                                        <label class="custom-control-label" for="Space">Space</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="SpaceTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Space === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Space')} />
                                                        <label class="custom-control-label" for="SpaceTrue">Space heater</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.heatType.Central} id="Central" value={this.state.heatType.Central} onChange={this.OnFormFieldChanged.bind(this, 'HeatType')} name="Central" />
                                                        <label class="custom-control-label" for="Central">Central</label>
                                                    </div> */}
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="CentralTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.Central === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'Central')} />
                                                        <label class="custom-control-label" for="CentralTrue">Central</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="HeatType" id="NoneTrue" value="true" class="custom-control-input"
                                                            checked={this.state.heatType && this.state.heatType.None === true}
                                                            onChange={this.OnRadioButtonsChangedHeatType.bind(this, 'None')} />
                                                        <label class="custom-control-label" for="NoneTrue">None</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2 border-right">
                                                    <label> Cooling Type </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["CoolingType"]}</div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.coolingType.None || this.state.isReadOnly} defaultChecked={this.state.coolingType.Central} id="CentralCoolingType" value={this.state.coolingType.Central} onChange={this.OnFormFieldChanged.bind(this, 'CoolingType')} name="Central" />
                                                        <label class="custom-control-label" for="CentralCoolingType">Central</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.coolingType.None || this.state.isReadOnly} defaultChecked={this.state.coolingType.WindowWall} id="WindowWall" value={this.state.coolingType.WindowWall} onChange={this.OnFormFieldChanged.bind(this, 'CoolingType')} name="WindowWall" />
                                                        <label class="custom-control-label" for="WindowWall">Window/Wall</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.coolingType.Central || this.state.coolingType.WindowWall || this.state.isReadOnly} defaultChecked={this.state.coolingType.None} id="None" value={this.state.coolingType.None} onChange={this.OnFormFieldChanged.bind(this, 'CoolingType')} name="None" />
                                                        <label class="custom-control-label" for="None">None </label>
                                                    </div> */}

                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="CoolingType" id="CoolingTypeCentralTrue" value="true" class="custom-control-input"
                                                            checked={this.state.coolingType && this.state.coolingType.Central === true}
                                                            onChange={this.OnRadioButtonsChangedColingType.bind(this, 'Central')} />
                                                        <label class="custom-control-label" for="CoolingTypeCentralTrue">Central</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="CoolingType" id="WindowWallTrue" value="true" class="custom-control-input"
                                                            checked={this.state.coolingType && this.state.coolingType.WindowWall === true}
                                                            onChange={this.OnRadioButtonsChangedColingType.bind(this, 'WindowWall')} />
                                                        <label class="custom-control-label" for="WindowWallTrue">Window/Wall</label>
                                                    </div>
                                                    <div class="custom-control custom-radio whoc-radio">
                                                        <input type="radio" name="CoolingType" id="CoolingTypeNoneTrue" value="true" class="custom-control-input"
                                                            checked={this.state.coolingType && this.state.coolingType.None === true}
                                                            onChange={this.OnRadioButtonsChangedColingType.bind(this, 'None')} />
                                                        <label class="custom-control-label" for="CoolingTypeNoneTrue">None</label>
                                                    </div>
                                                    <div class="">
                                                        {/* <input type="checkbox" class="custom-control-input" id="Paidby" value={this.state.coolingType.Paidby} onChange={this.OnFormFieldChanged.bind(this,'CoolingType')} name="Paidby" /> */}
                                                        <label for="Paidby">Paid by</label>
                                                        <select class="form-control" value={this.state.coolingType.Paidby} name="Paidby" id="Paidby" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                            <option value="">Please Select</option>
                                                            <option value="Tenant">Tenant </option>
                                                            <option value="Owner">Owner </option>
                                                        </select>
                                                        <span class="alert-small-text">{this.state.validationErrors["Paidby"]}</span>
                                                    </div>
                                                </div>

                                                <div className="col-md-2 border-right">
                                                    <label>Laundry Type </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["LaundryType"]}</div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.laundryType.WasherDryerHookUps} id="WasherDryerHookUps" value={this.state.laundryType.WasherDryerHookUps} onChange={this.OnFormFieldChanged.bind(this, 'LaundryType')} name="WasherDryerHookUps" />
                                                        <label class="custom-control-label" for="WasherDryerHookUps">Washer/Dryer Hook Ups</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.laundryType.Washer} id="Washer" value={this.state.laundryType.Washer} onChange={this.OnFormFieldChanged.bind(this, 'LaundryType')} name="Washer" />
                                                        <label class="custom-control-label" for="Washer">Washer Provided</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.laundryType.Dryer} id="Dryer" value={this.state.laundryType.Dryer} onChange={this.OnFormFieldChanged.bind(this, 'LaundryType')} name="Dryer" />
                                                        <label class="custom-control-label" for="Dryer">Dryer Provided </label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.laundryType.OnsiteLaundryFacilities} id="OnsiteLaundryFacilities" value={this.state.laundryType.OnsiteLaundryFacilities} onChange={this.OnFormFieldChanged.bind(this, 'LaundryType')} name="OnsiteLaundryFacilities" />
                                                        <label class="custom-control-label" for="OnsiteLaundryFacilities">Onsite Laundry Facilities</label>
                                                    </div>
                                                    {/* <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" disabled={this.state.isReadOnly} defaultChecked={this.state.laundryType.WasherDryerProvidedKitchen} id="WasherDryerProvidedKitchen" value={this.state.laundryType.WasherDryerProvidedKitchen} onChange={this.OnFormFieldChanged.bind(this, 'LaundryType')} name="WasherDryerProvidedKitchen" />
                                                        <label class="custom-control-label" for="WasherDryerProvidedKitchen">Washer/Dryer Provided Kitchen</label>
                                                    </div> */}
                                                </div>
                                                <div className="col-md-2 border-right">
                                                    <label>Kitchen </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Kitchen"]}</div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.kitchen.Dishwasher} id="Dishwasher" value={this.state.kitchen.Dishwasher} onChange={this.OnFormFieldChanged.bind(this, 'Kitchen')} name="Dishwasher" />
                                                        <label class="custom-control-label" for="Dishwasher">Dishwasher</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.kitchen.Stove} id="Stove" value={this.state.kitchen.Stove} onChange={this.OnFormFieldChanged.bind(this, 'Kitchen')} name="Stove" />
                                                        <label class="custom-control-label" for="Stove">Stove</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.kitchen.GarbageDisposal} id="GarbageDisposal" value={this.state.kitchen.GarbageDisposal} onChange={this.OnFormFieldChanged.bind(this, 'Kitchen')} name="GarbageDisposal" />
                                                        <label class="custom-control-label" for="GarbageDisposal">Garbage Disposal </label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.kitchen.Refrigerator} id="Refrigerator" value={this.state.kitchen.Refrigerator} onChange={this.OnFormFieldChanged.bind(this, 'Kitchen')} name="Refrigerator" />
                                                        <label class="custom-control-label" for="Refrigerator">Refrigerator</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.kitchen.Microwave} id="Microwave" value={this.state.kitchen.Microwave} onChange={this.OnFormFieldChanged.bind(this, 'Kitchen')} name="Microwave" />
                                                        <label class="custom-control-label" for="Microwave">Microwave</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <label>Exterior </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Exterior"]}</div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.exterior.Balcony} id="Balcony" value={this.state.exterior.Balcony} onChange={this.OnFormFieldChanged.bind(this, 'Exterior')} name="Balcony" />
                                                        <label class="custom-control-label" for="Balcony">Balcony</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.exterior.Deck} id="Deck" value={this.state.exterior.Deck} onChange={this.OnFormFieldChanged.bind(this, 'Exterior')} name="Deck" />
                                                        <label class="custom-control-label" for="Deck">Deck</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.exterior.Patio} id="Patio" value={this.state.exterior.Patio} onChange={this.OnFormFieldChanged.bind(this, 'Exterior')} name="Patio" />
                                                        <label class="custom-control-label" for="Patio">Patio </label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.exterior.Porch} id="Porch" value={this.state.exterior.Porch} onChange={this.OnFormFieldChanged.bind(this, 'Exterior')} name="Porch" />
                                                        <label class="custom-control-label" for="Porch">Porch</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-row">
                                                <div className="col-md-4 border-right">
                                                    <label>Community Amenities </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Outdoor"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.SwimmingPool} id="SwimmingPool" value={this.state.outdoor.SwimmingPool} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="SwimmingPool" />
                                                                <label class="custom-control-label" for="SwimmingPool">Swimming Pool</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.Spa} id="Spa" value={this.state.outdoor.Spa} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="Spa" />
                                                                <label class="custom-control-label" for="Spa">Spa</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.GatedCommunity} id="GatedCommunity" value={this.state.outdoor.GatedCommunity} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="GatedCommunity" />
                                                                <label class="custom-control-label" for="GatedCommunity">Gated Community </label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.LawnCareIncluded} id="LawnCareIncluded" value={this.state.outdoor.LawnCareIncluded} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="LawnCareIncluded" />
                                                                <label class="custom-control-label" for="LawnCareIncluded">Lawn Care Included</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.DogPark} id="DogPark" value={this.state.outdoor.DogPark} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="DogPark" />
                                                                <label class="custom-control-label" for="DogPark">Dog Park</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.FitnesCenter} id="FitnesCenter" value={this.state.outdoor.FitnesCenter} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="FitnesCenter" />
                                                                <label class="custom-control-label" for="FitnesCenter">Fitness Center</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.FencedYard} id="FencedYard" value={this.state.outdoor.FencedYard} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="FencedYard" />
                                                                <label class="custom-control-label" for="FencedYard">Fenced Yard</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.Gardener} id="Gardener" value={this.state.outdoor.Gardener} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="Gardener" />
                                                                <label class="custom-control-label" for="Gardener">Gardener</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.ClubHouse} id="ClubHouse" value={this.state.outdoor.ClubHouse} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="ClubHouse" />
                                                                <label class="custom-control-label" for="ClubHouse">Club House</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.Playground} id="Playground" value={this.state.outdoor.Playground} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="Playground" />
                                                                <label class="custom-control-label" for="Playground">Playground</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.PicnicArea} id="PicnicArea" value={this.state.outdoor.PicnicArea} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="PicnicArea" />
                                                                <label class="custom-control-label" for="PicnicArea">BBQ/Picnic Area</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.CoWorkingSpace} id="CoWorkingSpace" value={this.state.outdoor.CoWorkingSpace} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="CoWorkingSpace" />
                                                                <label class="custom-control-label" for="CoWorkingSpace">Coworking Space</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.outdoor.ResidentLounge} id="ResidentLounge" value={this.state.outdoor.ResidentLounge} onChange={this.OnFormFieldChanged.bind(this, 'Outdoor')} name="ResidentLounge" />
                                                                <label class="custom-control-label" for="ResidentLounge">Resident Lounge</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 border-right">
                                                    <label>Indoor </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Indoor"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.CeilingFans} id="CeilingFans" value={this.state.indoor.CeilingFans} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="CeilingFans" />
                                                                <label class="custom-control-label" for="CeilingFans">Ceiling Fans</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.Fireplace} id="Fireplace" value={this.state.indoor.Fireplace} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="Fireplace" />
                                                                <label class="custom-control-label" for="Fireplace">Fireplace</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.Furnished} id="Furnished" value={this.state.indoor.Furnished} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="Furnished" />
                                                                <label class="custom-control-label" for="Furnished">Furnished </label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.CableIncluded} id="CableIncluded" value={this.state.indoor.CableIncluded} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="CableIncluded" />
                                                                <label class="custom-control-label" for="CableIncluded">Cable Included</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.BlindsCurtains} id="BlindsCurtains" value={this.state.indoor.BlindsCurtains} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="BlindsCurtains" />
                                                                <label class="custom-control-label" for="BlindsCurtains">Blinds/Curtains</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.SecuritySystem} id="SecuritySystem" value={this.state.indoor.SecuritySystem} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="SecuritySystem" />
                                                                <label class="custom-control-label" for="SecuritySystem">Security System</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.NewCarpeting} id="NewCarpeting" value={this.state.indoor.NewCarpeting} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="NewCarpeting" />
                                                                <label class="custom-control-label" for="NewCarpeting">New Carpeting</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" checked={this.state.indoor.RemodeledUnit} id="RemodeledUnit" value={this.state.indoor.RemodeledUnit} onChange={this.OnFormFieldChanged.bind(this, 'Indoor')} name="RemodeledUnit" />
                                                                <label class="custom-control-label" for="RemodeledUnit">Remodeled Unit</label>
                                                            </div>
                                                            {this.state.indoor.RemodeledUnit ? (
                                                                <div>
                                                                    <label>Update/upgrades made include</label>
                                                                    <input className="form-control" type="text" maxlength="100" onChange={this.OnOtherFormFiledChanged.bind(this, "UpdatesInclude")} value={this.state.indoor.UpdatesInclude}></input>
                                                                    {/* <span class="alert-small-text">{this.state.validationErrors["HandicapeTitle"]}</span> */}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Parking </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["Parking"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.OneCarCarport} id="OneCarCarport" value={this.state.parking.OneCarCarport} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="OneCarCarport" />
                                                                <label class="custom-control-label" for="OneCarCarport">1 Car Carport</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.TwoCarCarport} id="TwoCarCarport" value={this.state.parking.TwoCarCarport} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="TwoCarCarport" />
                                                                <label class="custom-control-label" for="TwoCarCarport">2 Car Carport</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.OneCarGarage} id="OneCarGarage" value={this.state.parking.OneCarGarage} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="OneCarGarage" />
                                                                <label class="custom-control-label" for="OneCarGarage">1 Car Garage </label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.TwoCarGarage} id="TwoCarGarage" value={this.state.parking.TwoCarGarage} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="TwoCarGarage" />
                                                                <label class="custom-control-label" for="TwoCarGarage">2 Car Garage</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.ThreeCarGarage} id="ThreeCarGarage" value={this.state.parking.ThreeCarGarage} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="ThreeCarGarage" />
                                                                <label class="custom-control-label" for="ThreeCarGarage">3 Car Garage</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.Unassigned} id="Unassigned" value={this.state.parking.Unassigned} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="Unassigned" />
                                                                <label class="custom-control-label" for="Unassigned">Unassigned</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.Assigned} id="Assigned" value={this.state.parking.Assigned} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="Assigned" />
                                                                <label class="custom-control-label" for="Assigned">Assigned</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.Driveway} id="Driveway" value={this.state.parking.Driveway} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="Driveway" />
                                                                <label class="custom-control-label" for="Driveway">Driveway</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.None} checked={this.state.parking.Street} id="Driveway" id="Street" value={this.state.parking.Street} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="Street" />
                                                                <label class="custom-control-label" for="Street">Street</label>
                                                            </div>
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" disabled={this.state.parking.OneCarCarport || this.state.parking.TwoCarCarport || this.state.parking.OneCarGarage || this.state.parking.TwoCarGarage || this.state.parking.Unassigned || this.state.parking.Assigned || this.state.parking.Driveway || this.state.parking.Street || this.state.parking.ThreeCarGarage} id="None" value={this.state.parking.None} onChange={this.OnFormFieldChanged.bind(this, 'Parking')} name="None" />
                                                                <label class="custom-control-label" for="None">None</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-row">
                                                <div className="col-auto">
                                                    <div class="alert-small-text">{this.state.validationErrors["IsUnitHaveHandicapAccessibility"]}</div>
                                                    <div class="alert-small-text">{this.state.validationErrors["HandicapeTitle"]}</div>
                                                    <label className="mr-2">Does the unit have Handicap accessibility?</label>
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="IsUnitHaveHandicapAccessibility" id="UnitHaveHandicapAccessibilityTrue" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveHandicapAccessibility === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="UnitHaveHandicapAccessibilityTrue">Yes</label>

                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="IsUnitHaveHandicapAccessibility" value="false" class="custom-control-input" id="UnitHaveHandicapAccessibilityFalse"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.IsUnitHaveHandicapAccessibility === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="UnitHaveHandicapAccessibilityFalse">No</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12">
                                                    {this.state.propertyUnit.IsUnitHaveHandicapAccessibility === true ? (
                                                        <div>
                                                            <label>Which accessibility modifications does it have?</label>
                                                            <div className="form-row">
                                                                <div className="col-md-12">
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsNoStepEntry} id="IsNoStepEntry" value={this.state.propertyUnit.IsNoStepEntry} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsNoStepEntry" />
                                                                        <label class="custom-control-label" for="IsNoStepEntry">No step entry</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsWidenedDoorFrames} id="IsWidenedDoorFrames" value={this.state.propertyUnit.IsWidenedDoorFrames} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsWidenedDoorFrames" />
                                                                        <label class="custom-control-label" for="IsWidenedDoorFrames">Widened door frames</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsHandicapRestroom} id="IsHandicapRestroom" value={this.state.propertyUnit.IsHandicapRestroom} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsHandicapRestroom" />
                                                                        <label class="custom-control-label" for="IsHandicapRestroom">Handicap restroom</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsRollBarrierShower} id="IsRollBarrierShower" value={this.state.propertyUnit.IsRollBarrierShower} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsRollBarrierShower" />
                                                                        <label class="custom-control-label" for="IsRollBarrierShower">Roll-in/no barrier shower</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsADACounterHeight} id="IsADACounterHeight" value={this.state.propertyUnit.IsADACounterHeight} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsADACounterHeight" />
                                                                        <label class="custom-control-label" for="IsADACounterHeight">ADA counter height</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsAccommodationsForBlind} id="IsAccommodationsForBlind" value={this.state.propertyUnit.IsAccommodationsForBlind} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsAccommodationsForBlind" />
                                                                        <label class="custom-control-label" for="IsAccommodationsForBlind">Accommodations for the blind</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsAccommodationsForDeaf} id="IsAccommodationsForDeaf" value={this.state.propertyUnit.IsAccommodationsForDeaf} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsAccommodationsForDeaf" />
                                                                        <label class="custom-control-label" for="IsAccommodationsForDeaf">Accommodations for the deaf</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsOtherAccessibility} id="IsOtherAccessibility" value={this.state.propertyUnit.IsOtherAccessibility} onChange={this.OnFormFieldChanged.bind(this, 'HandiCap')} name="IsOtherAccessibility" />
                                                                        <label class="custom-control-label" for="IsOtherAccessibility">Other</label>
                                                                    </div>
                                                                    {this.state.propertyUnit.IsOtherAccessibility ? (
                                                                        <div>
                                                                            {/* <label>Describe Other Subsidy, including any state or local subsidy</label> */}
                                                                            <input className="form-control" type="text" maxlength="100" onChange={this.OnOtherFormFiledChanged.bind(this, "HandicapeTitle")} value={this.state.propertyUnit.HandicapeTitle}></input>
                                                                            {/* <span class="alert-small-text">{this.state.validationErrors["HandicapeTitle"]}</span> */}
                                                                        </div>
                                                                    ) : null}
                                                                    {/* <div>
                                                                        <label>Other</label>
                                                                        <input className="form-control"  type="text" maxlength="100" onChange={this.OnOtherFormFiledChanged.bind(this, "HandicapeTitle")} value={this.state.propertyUnit.HandicapeTitle}></input>
                                                                    </div> */}
                                                                    {/* {this.state.propertyUnit.IsOther ? (
                                                                        <div>
                                                                           
                                                                            <input className="form-control"  type="text" name="PetsOtherTitle" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "PetsOtherTitle")} value={this.state.propertyUnit.PetsOtherTitle}></input>
                                                                            <span class="alert-small-text">{this.state.validationErrors["PetsOtherTitle"]}</span>
                                                                        </div>
                                                                    ) : null} */}
                                                                    {/* <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input"  defaultChecked={this.state.propertyUnit.IsAdditionalFeesforPets} id="IsAdditionalFeesforPets" value={this.state.propertyUnit.IsAdditionalFeesforPets} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsAdditionalFeesforPets" />
                                                                        <label class="custom-control-label" for="IsAdditionalFeesforPets">Additional Fees for Pets</label>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            {/* <input className="form-control"  type="text" maxlength="100" onChange={this.OnOtherFormFiledChanged.bind(this, "HandicapeTitle")} value={this.state.propertyUnit.HandicapeTitle}></input> */}

                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <hr />
                                            {/* <label> Pet Allowance Information </label> */}
                                            <div className="form-row">
                                                <div className="col-md-12">
                                                    <label className="mr-2">Pets Allowed? </label>
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="PetsAllowed" id="PetsAllowedTrue" value="true" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.PetsAllowed === true}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="PetsAllowedTrue">Yes</label>
                                                    </div>
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="PetsAllowed" value="false" class="custom-control-input" id="PetsAllowedFalse"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.PetsAllowed === false}
                                                            onChange={this.OnRadioButtonsChanged.bind(this)} />
                                                        <label class="custom-control-label" for="PetsAllowedFalse">No</label>
                                                    </div>
                                                    <span class="alert-small-text">{this.state.validationErrors["PetsAllowed"]}</span>
                                                </div>
                                                <div className="col-md-12">
                                                    {this.state.propertyUnit.PetsAllowed === true ? (
                                                        <div>
                                                            <label>If yes, what are the required documents? Please check all that </label>
                                                            <span class="alert-small-text pl-2">{this.state.validationErrors["PropertyUnit"]}</span>
                                                            <div className="form-row">
                                                                <div className="col-md-12">
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsServiceAnimalCertification} id="IsServiceAnimalCertification" value={this.state.propertyUnit.IsServiceAnimalCertification} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsServiceAnimalCertification" />
                                                                        <label class="custom-control-label" for="IsServiceAnimalCertification">Service Animal Certification</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsVaccinationRecords} id="IsVaccinationRecords" value={this.state.propertyUnit.IsVaccinationRecords} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsVaccinationRecords" />
                                                                        <label class="custom-control-label" for="IsVaccinationRecords">Vaccination Records</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsRabiesRecords} id="IsRabiesRecords" value={this.state.propertyUnit.IsRabiesRecords} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsRabiesRecords" />
                                                                        <label class="custom-control-label" for="IsRabiesRecords">Rabies Records</label>
                                                                    </div>
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsOther} id="IsOther" value={this.state.propertyUnit.IsOther} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsOther" />
                                                                        <label class="custom-control-label" for="IsOther">Other</label>
                                                                    </div>
                                                                    {this.state.propertyUnit.IsOther ? (
                                                                        <div>
                                                                            {/* <label>Describe Other Subsidy, including any state or local subsidy</label> */}
                                                                            <input className="form-control" type="text" name="PetsOtherTitle" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "PetsOtherTitle")} value={this.state.propertyUnit.PetsOtherTitle}></input>
                                                                            <span class="alert-small-text">{this.state.validationErrors["PetsOtherTitle"]}</span>
                                                                        </div>
                                                                    ) : null}
                                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.IsAdditionalFeesforPets} id="IsAdditionalFeesforPets" value={this.state.propertyUnit.IsAdditionalFeesforPets} onChange={this.OnFormFieldChanged.bind(this, 'PropertyUnit')} name="IsAdditionalFeesforPets" />
                                                                        <label class="custom-control-label" for="IsAdditionalFeesforPets">Additional Fees for Pets</label>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12">
                                                                    {this.state.propertyUnit.IsAdditionalFeesforPets === true ? (
                                                                        <div className="form-row">
                                                                            <div className="form-group col-md-6">
                                                                                <label>Deposit $</label>
                                                                                <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "PetsDepositAmount")} value={this.state.propertyUnit.PetsDepositAmount}></input>
                                                                                <span class="alert-small-text">{this.state.validationErrors["PetsDepositAmount"]}</span>
                                                                            </div>
                                                                            <div className="form-group col-md-6">
                                                                                <label>Monthly $</label>
                                                                                <input className="form-control" type="text" maxlength="10" onChange={this.OnOtherFormFiledChanged.bind(this, "PetsMonthlyAmount")} value={this.state.propertyUnit.PetsMonthlyAmount}></input>
                                                                                <span class="alert-small-text">{this.state.validationErrors["PetsMonthlyAmount"]}</span>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Utilities and Appliances</h2>
                                        <div className="box-details whoc-multi-fields">
                                            <div className="form-row">
                                                <div className="col-md-4 border-right">
                                                    <label>Heating </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["HeatingFuelType"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeNaturalgas" data-text="Natural gas" value="Natural gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Natural gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeNaturalgas">Natural gas</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeOil" data-text="Oil" value="Oil" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Oil'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeOil">Oil</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeOther" data-text="Other" value="Other" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Other'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeOther">Other</label>
                                                            </div>
                                                            {this.state.propertyUnit.HeatingFuelType === 'Other' ? (
                                                                <div>
                                                                    <input className="form-control" type="text" maxlength="20" onChange={this.OnOtherFormFiledChanged.bind(this, "HeatingFuelTypeOtherTitle")} value={this.state.propertyUnit.HeatingFuelTypeOtherTitle}></input>
                                                                    <span class="alert-small-text">{this.state.validationErrors["HeatingFuelTypeOtherTitle"]}</span>
                                                                </div>
                                                            ) : null}

                                                            <label className="paid-by">Paid By</label>
                                                            <select class="form-control" value={this.state.propertyUnit.HeatingPaidBy} name="HeatingPaidBy" id="HeatingPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                <option value="Tenant">Tenant </option>
                                                                <option value="Owner">Owner </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["HeatingPaidBy"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeBottledgas" data-text="Bottled gas" value="Bottled gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Bottled gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeBottledgas">Bottled gas</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeElectric" data-text="Electric" value="Electric" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Electric'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeElectric">Electric</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="HeatingFuelType" id="HeatingFuelTypeHeatPump" data-text="Heat Pump" value="Heat Pump" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.HeatingFuelType === 'Heat Pump'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="HeatingFuelTypeHeatPump">Heat Pump</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 border-right">
                                                    <label>Cooking </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["CookingFuelType"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="CookingFuelType" id="CookingFuelTypeNaturalgas" data-text="Natural gas" value="Natural gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Natural gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="CookingFuelTypeNaturalgas">Natural gas</label>
                                                            </div>
                                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                            <input type="radio" name="CookingFuelType" id="CookingFuelTypeOil" data-text="Oil" value="Oil" class="custom-control-input"
                                                                checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Oil'}
                                                                onChange={this.OnRadioButtonChanged.bind(this)} />
                                                            <label class="custom-control-label" for="CookingFuelTypeOil">Oil</label>
                                                        </div> */}
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="CookingFuelType" id="CookingFuelTypeOther" data-text="Other" value="Other" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Other'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="CookingFuelTypeOther">Other</label>
                                                            </div>
                                                            {this.state.propertyUnit.CookingFuelType === 'Other' ? (
                                                                <div>
                                                                    <input className="form-control" type="text" maxlength="20" onChange={this.OnOtherFormFiledChanged.bind(this, "CookingFuelTypeOtherTitle")} value={this.state.propertyUnit.CookingFuelTypeOtherTitle}></input>
                                                                    <span class="alert-small-text">{this.state.validationErrors["CookingFuelTypeOtherTitle"]}</span>
                                                                </div>
                                                            ) : null}


                                                            <label className="paid-by">Paid By</label>

                                                            <select class="form-control" value={this.state.propertyUnit.CookingPaidBy} name="CookingPaidBy" id="CookingPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                <option value="Tenant">Tenant </option>
                                                                <option value="Owner">Owner </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["CookingPaidBy"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="CookingFuelType" id="CookingFuelTypeBottledgas" data-text="Bottled gas" value="Bottled gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Bottled gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="CookingFuelTypeBottledgas">Bottled gas</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="CookingFuelType" id="CookingFuelTypeElectric" data-text="Electric" value="Electric" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Electric'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="CookingFuelTypeElectric">Electric</label>
                                                            </div>
                                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                            <input type="radio" name="CookingFuelType" id="CookingFuelTypeHeatPump" data-text="Heat Pump" value="Heat Pump" class="custom-control-input"
                                                                checked={this.state.propertyUnit && this.state.propertyUnit.CookingFuelType === 'Heat Pump'}
                                                                onChange={this.OnRadioButtonChanged.bind(this)} />
                                                            <label class="custom-control-label" for="CookingFuelTypeHeatPump">Heat Pump</label>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <label>Water Heating </label>
                                                    <div class="alert-small-text">{this.state.validationErrors["WaterHeatingFuelType"]}</div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeNaturalgas" data-text="Natural gas" value="Natural gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Natural gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="WaterHeatingFuelTypeNaturalgas">Natural gas</label>
                                                            </div>

                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeOil" data-text="Oil" value="Oil" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Oil'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="WaterHeatingFuelTypeOil">Oil</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeOther" data-text="Other" value="Other" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Other'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="WaterHeatingFuelTypeOther">Other</label>
                                                            </div>
                                                            {this.state.propertyUnit.WaterHeatingFuelType === 'Other' ? (
                                                                <div>
                                                                    <input className="form-control" type="text" maxlength="20" onChange={this.OnOtherFormFiledChanged.bind(this, "WaterHeatingFuelTypeOtherTitle")} value={this.state.propertyUnit.WaterHeatingFuelTypeOtherTitle}></input>
                                                                    <span class="alert-small-text">{this.state.validationErrors["WaterHeatingFuelTypeOtherTitle"]}</span>
                                                                </div>
                                                            ) : null}


                                                            <label className="paid-by">Paid By</label>
                                                            <select class="form-control" value={this.state.propertyUnit.WaterHeatingPaidBy} name="WaterHeatingPaidBy" id="WaterHeatingPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                                <option>Please Select</option>
                                                                <option value="Tenant">Tenant </option>
                                                                <option value="Owner">Owner </option>
                                                            </select>
                                                            <span class="alert-small-text">{this.state.validationErrors["WaterHeatingPaidBy"]}</span>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeBottledgas" data-text="Bottled gas" value="Bottled gas" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Bottled gas'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="WaterHeatingFuelTypeBottledgas">Bottled gas</label>
                                                            </div>
                                                            <div class="custom-control custom-radio whoc-radio">
                                                                <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeElectric" data-text="Electric" value="Electric" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Electric'}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="WaterHeatingFuelTypeElectric">Electric</label>
                                                            </div>
                                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                            <input type="radio" name="WaterHeatingFuelType" id="WaterHeatingFuelTypeHeatPump" data-text="Heat Pump" value="Heat Pump" class="custom-control-input"
                                                                checked={this.state.propertyUnit && this.state.propertyUnit.WaterHeatingFuelType === 'Heat Pump'}
                                                                onChange={this.OnRadioButtonChanged.bind(this)} />
                                                            <label class="custom-control-label" for="WaterHeatingFuelTypeHeatPump">Heat Pump</label>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="form-row">
                                                <div className="col-md-3 form-group">
                                                    <label>Other Electric </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.OtherElectricPaidBy} name="OtherElectricPaidBy" id="OtherElectricPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["OtherElectricPaidBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Water </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.WaterPaidBy} name="WaterPaidBy" id="WaterPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["WaterPaidBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Sewer </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.SewerPaidBy} name="SewerPaidBy" id="SewerPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["SewerPaidBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Trash Collection </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.TrashCollectionPaidBy} name="TrashCollectionPaidBy" id="TrashCollectionPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["TrashCollectionPaidBy"]}</span>
                                                </div>
                                                {/* <div className="col-md-3 form-group">
                                                    <label>Air Conditioning </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" disabled={this.state.isReadOnly} value={this.state.propertyUnit.AirConditioningPaidBy} name="AirConditioningPaidBy" id="AirConditioningPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["AirConditioningPaidBy"]}</span>
                                                </div> */}
                                                <div className="col-md-1 form-group">
                                                    <label>Other </label>
                                                    <label className="paid-by d-block">Specify</label>
                                                    <input className="form-control" type="text" maxlength="40" onChange={this.OnOtherFormFiledChanged.bind(this, "OtherUtilityTitle")} value={this.state.propertyUnit.OtherUtilityTitle}></input>
                                                    <span class="alert-small-text">{this.state.validationErrors["OtherUtilityTitle"]}</span>
                                                </div>
                                                <div className="col-md-2 form-group">
                                                    <label>Other </label>
                                                    <label className="paid-by d-block">Paid By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.OtherPaidBy} name="OtherPaidBy" id="OtherPaidBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["OtherPaidBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Refrigerator </label>
                                                    <label className="paid-by d-block">Provided By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.RefrigeratorProvidedBy} name="RefrigeratorProvidedBy" id="RefrigeratorProvidedBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["RefrigeratorProvidedBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Range </label>
                                                    <label className="paid-by d-block">Provided By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.RangeMicrowaveProvidedBy} name="RangeMicrowaveProvidedBy" id="RangeMicrowaveProvidedBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["RangeMicrowaveProvidedBy"]}</span>
                                                </div>
                                                <div className="col-md-3 form-group">
                                                    <label>Microwave </label>
                                                    <label className="paid-by d-block">Provided By</label>
                                                    <select class="form-control" value={this.state.propertyUnit.MicrowaveProvidedBy} name="MicrowaveProvidedBy" id="MicrowaveProvidedBy" onChange={this.OnDropdownValueChanged.bind(this)}>
                                                        <option>Please Select</option>
                                                        <option value="Tenant">Tenant </option>
                                                        <option value="Owner">Owner </option>
                                                    </select>
                                                    <span class="alert-small-text">{this.state.validationErrors["MicrowaveProvidedBy"]}</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="box-gray mb-4">
                                        <h2 class="profile-title"><i class="fas fa-house-user mr-2"></i>Other</h2>
                                        <div className="box-details whoc-multi-fields">
                                            <label>Structure Type </label>
                                            <div class="alert-small-text">{this.state.validationErrors["StructureType"]}</div>
                                            <div className="form-row">
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType1" data-text="one family under one roof" value="one family under one roof" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'one family under one roof'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType1">Single Family Detached (one family under one roof)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType2" data-text="duplex" value="duplex" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'duplex'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType2">Semi-Detached (duplex, attached on one side)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType3" data-text="Rowhouse" value="Rowhouse" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'Rowhouse'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType3">Rowhouse/Townhouse (attached on two sides)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType4" data-text="Low-rise" value="Low-rise" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'Low-rise'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType4">Low-rise apartment building (4 stories or fewer)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType5" data-text="High-rise" value="High-rise" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'High-rise'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType5">High-rise apartment building (5+ stories)</label>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType6" data-text="Manufactured" value="Manufactured" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'Manufactured'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType6">Manufactured Home (mobile home)</label>
                                                    </div>

                                                </div>
                                                <div className="col-md-4">
                                                    <div class="custom-control custom-radio custom-control-inline whoc-radio">
                                                        <input type="radio" name="StructureType" id="StructureType7" data-text="AccessoryDwelling" value="AccessoryDwelling" class="custom-control-input"
                                                            checked={this.state.propertyUnit && this.state.propertyUnit.StructureType === 'AccessoryDwelling'}
                                                            onChange={this.OnRadioButtonChanged.bind(this)} />
                                                        <label class="custom-control-label" for="StructureType7">Accessory Dwelling Unit</label>
                                                    </div>

                                                </div>
                                            </div>
                                            <hr />
                                            <label> Subsidy </label>
                                            <div class="alert-small-text">{this.state.validationErrors["Subsidy"]}</div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.Section202} disabled={this.state.subsidy.SubsidyNone} id="Section202" value={this.state.subsidy.Section202} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="Section202" />
                                                        <label class="custom-control-label" for="Section202">Section 202</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.Section221} disabled={this.state.subsidy.SubsidyNone} id="Section221" value={this.state.subsidy.Section221} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="Section221" />
                                                        <label class="custom-control-label" for="Section221">Section 221</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.TaxCredit} disabled={this.state.subsidy.SubsidyNone} id="TaxCredit" value={this.state.subsidy.TaxCredit} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="TaxCredit" />
                                                        <label class="custom-control-label" for="TaxCredit">Tax Credit</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.HOME} disabled={this.state.subsidy.SubsidyNone} id="HOME" value={this.state.subsidy.HOME} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="HOME" />
                                                        <label class="custom-control-label" for="HOME">HOME</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.Section236} disabled={this.state.subsidy.SubsidyNone} id="Section236" value={this.state.subsidy.Section236} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="Section236" />
                                                        <label class="custom-control-label" for="Section236">Section 236</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.Section515} disabled={this.state.subsidy.SubsidyNone} id="Section515" value={this.state.subsidy.Section515} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="Section515" />
                                                        <label class="custom-control-label" for="Section515">Section 515</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.Other} disabled={this.state.subsidy.SubsidyNone} id="Other" value={this.state.subsidy.Other} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="Other" />
                                                        <label class="custom-control-label" for="Other">Other</label>
                                                    </div>
                                                    <div class="custom-control custom-checkbox custom-control-inline">
                                                        <input type="checkbox" class="custom-control-input" checked={this.state.subsidy.SubsidyNone} disabled={this.state.subsidy.Section202 || this.state.subsidy.Section221 || this.state.subsidy.TaxCredit || this.state.subsidy.HOME || this.state.subsidy.Section236 || this.state.subsidy.Section515 || this.state.subsidy.Other} id="SubsidyNone" value={this.state.subsidy.SubsidyNone} onChange={this.OnFormFieldChanged.bind(this, 'Subsidy')} name="SubsidyNone" />
                                                        <label class="custom-control-label" for="SubsidyNone">None</label>
                                                    </div>
                                                    {this.state.subsidy.Other ? (
                                                        <div>
                                                            <label>Describe Other Subsidy, including any state or local subsidy</label>
                                                            <input className="form-control" type="text" name="OtherTitle" maxlength="50" onChange={this.OnSubsidyChanged.bind(this, "OtherTitle")} value={this.state.subsidy.OtherTitle}></input>
                                                            <span class="alert-small-text">{this.state.validationErrors["OtherTitle"]}</span>
                                                        </div>
                                                    ) : null}


                                                </div>
                                            </div>
                                            <hr />
                                            <label> Lead-based paint </label>
                                            <div class="alert-small-text">{this.state.validationErrors["LeadBasedPaint"]}</div>
                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                <input type="radio" disabled={this.state.isReadOnly} name="LeadBasedPaint" id="Section1" data-text="Section1" value="Section1" class="custom-control-input"
                                                    checked={this.state.propertyUnit && this.state.propertyUnit.LeadBasedPaint === 'Section1'}
                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                <label class="custom-control-label" for="Section1">Lead-based paint disclosure requirements do not apply because this property was built on or after January 1, 1978.</label>
                                            </div> */}
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.LeadBasedPaintSection1} id="LeadBasedPaintSection1" value={this.state.propertyUnit.LeadBasedPaintSection1} onChange={this.OnFormFieldChanged.bind(this, 'LeadBasedPaint')} name="LeadBasedPaintSection1" />
                                                <label class="custom-control-label" for="LeadBasedPaintSection1">Lead-based paint disclosure requirements do not apply because this property was built on or after January 1, 1978.</label>
                                            </div>
                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                <input type="radio" disabled={this.state.isReadOnly} name="LeadBasedPaint" id="Section2" data-text="Section2" value="Section2" class="custom-control-input"
                                                    checked={this.state.propertyUnit && this.state.propertyUnit.LeadBasedPaint === 'Section2'}
                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                <label class="custom-control-label" for="Section2">The unit, common areas servicing the unit, and exterior painted surfaces associated with such unit or common areas have been found to be lead-based paint free by a lead-based paint inspector certified under the Federal certification program or under a federally accredited State certification program</label>
                                            </div> */}
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.LeadBasedPaintSection2} id="LeadBasedPaintSection2" value={this.state.propertyUnit.LeadBasedPaintSection2} onChange={this.OnFormFieldChanged.bind(this, 'LeadBasedPaint')} name="LeadBasedPaintSection2" />
                                                <label class="custom-control-label" for="LeadBasedPaintSection2">The unit, common areas servicing the unit, and exterior painted surfaces associated with such unit or common areas have been found to be lead-based paint free by a lead-based paint inspector certified under the Federal certification program or under a federally accredited State certification program</label>
                                            </div>
                                            {/* <div class="custom-control custom-radio whoc-radio">
                                                <input type="radio" disabled={this.state.isReadOnly} name="LeadBasedPaint" id="Section3" data-text="Section3" value="Section3" class="custom-control-input"
                                                    checked={this.state.propertyUnit && this.state.propertyUnit.LeadBasedPaint === 'Section3'}
                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                <label class="custom-control-label" for="Section3">A completed statement is attached containing disclosure of known information on lead-based paint and/or lead-based paint hazards in the unit, common areas or exterior painted surfaces, including a statement that the owner has provided the lead hazard information pamphlet to the family.</label>

                                            </div> */}
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" class="custom-control-input" checked={this.state.propertyUnit.LeadBasedPaintSection3} id="LeadBasedPaintSection3" value={this.state.propertyUnit.LeadBasedPaintSection3} onChange={this.OnFormFieldChanged.bind(this, 'LeadBasedPaint')} name="LeadBasedPaintSection3" />
                                                <label class="custom-control-label" for="LeadBasedPaintSection3">A completed statement is attached containing disclosure of known information on lead-based paint and/or lead-based paint hazards in the unit, common areas or exterior painted surfaces, including a statement that the owner has provided the lead hazard information pamphlet to the family.</label>
                                            </div>

                                            <hr />
                                            <label>Owner’s Certifications </label>
                                            <Tooltip title="Owners of properties with more than 4 units must complete the following section for their most recently leased, comparable, unassisted units within the premises. If there are no unassisted units (market rate) within the premise, you may leave this blank.">
                                                <i className="fa fa-question-circle ml-1"></i>
                                            </Tooltip>
                                            <div className="property-grid table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th width="50%">Address</th>
                                                            <th width="50%">Unit No</th>
                                                            <th width="50%">Rental Amount $</th>
                                                            <th width="50%">Date Rented</th>
                                                            <th width="10%"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td width="50%">
                                                                <input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCAddressOne")} maxlength="100" value={this.state.propertyUnit.OCAddressOne}></input>
                                                            </td>
                                                            <td width="50%">
                                                                <input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCUnitNoOne")} maxlength="40" value={this.state.propertyUnit.OCUnitNoOne}></input>
                                                            </td>
                                                            <td width="50%">
                                                                <input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentalAmountOne")} maxlength="100" value={this.state.propertyUnit.OCRentalAmountOne}></input>
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentalAmountOne"]}</span>
                                                            </td>
                                                            <td width="50%">
                                                                <input id="date" type="date" value={this.state.propertyUnit.OCRentedDateOne} onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentedDateOne")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentedDateOne"]}</span>
                                                            </td>
                                                            <td width="10%"><a onClick={this.resetOwnerCertificationFields.bind(this, 1)}> <i class="fas fa-sync-alt"></i></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCAddressTwo")} maxlength="100" value={this.state.propertyUnit.OCAddressTwo}></input></td>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCUnitNoTwo")} maxlength="40" value={this.state.propertyUnit.OCUnitNoTwo}></input></td>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentalAmountTwo")} maxlength="100" value={this.state.propertyUnit.OCRentalAmountTwo}></input>
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentalAmountTwo"]}</span>
                                                            </td>
                                                            <td width="50%"><input id="date" type="date" value={this.state.propertyUnit.OCRentedDateTwo} onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentedDateTwo")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentedDateTwo"]}</span>
                                                            </td>
                                                            <td width="10%"><a onClick={this.resetOwnerCertificationFields.bind(this, 2)}> <i class="fas fa-sync-alt"></i></a></td>
                                                        </tr>
                                                        <tr>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCAddressThree")} maxlength="100" value={this.state.propertyUnit.OCAddressThree}></input></td>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCUnitNoThree")} maxlength="40" value={this.state.propertyUnit.OCUnitNoThree}></input></td>
                                                            <td width="50%"><input className="form-control" type="text" onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentalAmountThree")} maxlength="100" value={this.state.propertyUnit.OCRentalAmountThree}></input>
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentalAmountThree"]}</span>
                                                            </td>
                                                            <td width="50%"><input id="date" type="date" value={this.state.propertyUnit.OCRentedDateThree} onChange={this.OnOtherFormFiledChanged.bind(this, "OCRentedDateThree")} className="form-control" InputLabelProps={{ shrink: true, }} />
                                                                <span class="alert-small-text">{this.state.validationErrors["OCRentedDateThree"]}</span>
                                                            </td>
                                                            <td width="10%"><a onClick={this.resetOwnerCertificationFields.bind(this, 3)}> <i class="fas fa-sync-alt"></i></a></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                        </div>
                                    </div>
                                    {this.state.isReadOnly ?
                                        <div className="box-gray mb-4">
                                            <h2 class="profile-title"><i class="fas fa-file-alt mr-2"></i>Documents</h2>
                                            <div className="box-details">
                                                <table class="table table-bordered doc-table">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Document</th>
                                                            <th scope="col">File Name</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>


                                                        <tr>
                                                            <td>Unit Submission Form</td>
                                                            <td>{this.state.unitSubmissionFileName}</td>
                                                            <td>
                                                                {/* {this.state.propertyUnit.EnvelopStatus == "sent" ? "Sent for Review" : this.state.propertyUnit.EnvelopStatus} */}
                                                                {this.state.propertyUnit.USFStatus}
                                                            </td>
                                                            <td>
                                                                {/* <a className="red" href={this.state.submittedUnitDocumentS3Path} target="_blank" download>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a> */}
                                                                <a className="red" onClick={this.viewDoc.bind(this)}>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </td></tr>

                                                        <tr>
                                                            <td>Holding Fee Agreement</td>
                                                            <td>{this.state.hfaFileName}</td>
                                                            <td>{this.state.propertyUnit.HFAStatus}</td>
                                                            <td>

                                                                <a className="red" onClick={this.viewHFADoc.bind(this)}>
                                                                    <Tooltip title="Download" arrow>
                                                                        <IconButton class="fas fa-eye border-0 red"></IconButton>
                                                                    </Tooltip>
                                                                </a>
                                                            </td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        : null
                                    }

                                    {/* Address <input className="form-control" type="text" maxlength="10"  onChange={this.OnOtherFormFiledChanged.bind(this, "OtherTitle")} value={this.state.subsidy.OtherTitle}></input> */}
                                    {this.state.isReadOnly === false ?
                                        (
                                            <Tooltip title="Save" arrow>
                                                <button type="button" onClick={this.addUnit} className="btn btn-primary whoc-float-btn test">
                                                    <i class="fas fa-save" ></i>
                                                </button>
                                            </Tooltip>
                                        ) :
                                        (
                                            <Tooltip title="Save" arrow>
                                                <button type="button" className="btn btn-primary whoc-float-btn test" onClick={this.updateUnit}>
                                                    <i class="fas fa-save"></i>
                                                </button>
                                            </Tooltip>
                                        )
                                    }

                                    <Backdrop className="MuiBackdrop-root" open={this.state.isLoading}>
                                        <CircularProgress />
                                    </Backdrop>
                                    <Snackbar className="Mui-filledSuccess" 
                                        anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                                        open={this.state.openSnackbar}
                                        autoHideDuration={3000}
                                        severity="success"
                                        onClose={() => this.setState({ openSnackbar: false })}
                                        message={this.state.snackbarMessage}
                                        className={this.state.snackbarMessageClass}
                                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                                    />
                                    {/* <SnackbarContent message="I love candy. I love cookies. I love cupcakes." /> */}
                                </form>
                                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog modal-lg">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="modal-title" id="exampleModalLabel">Add Property</h5>
                                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.resetPropertyPopup}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="form-row">
                                                    <div class="form-group col-sm-6 col-lg-3">
                                                        <label>Property Name<i class="fas fa-asterisk asterisk"></i></label>
                                                        <input className="form-control" type="text" maxlength="50" onChange={this.handlePropertyChanges.bind(this, "PropertyName")} value={this.state.activeProperty.PropertyName}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropPropertyName"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-6 col-lg-6">
                                                        <label>Physical Property Address<i class="fas fa-asterisk asterisk"></i></label>
                                                        <input className="form-control" type="text" maxlength="100" onChange={this.handlePropertyChanges.bind(this, "Address")} value={this.state.activeProperty.Address}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropAddress"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-6 col-lg-3">
                                                        <label>Suite</label>
                                                        <input className="form-control" type="text" maxlength="8" onChange={this.handlePropertyChanges.bind(this, "Unit")} value={this.state.activeProperty.Unit}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropUnit"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-4 col-lg-3">
                                                        <label>City<i class="fas fa-asterisk asterisk"></i></label>
                                                        <input className="form-control" type="text" maxlength="50" onChange={this.handlePropertyChanges.bind(this, "City")} value={this.state.activeProperty.City}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropCity"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-4 col-lg-3">
                                                        <label className="text-muted small">State</label>
                                                        <select className="form-control" onChange={this.handlePropertyChanges.bind(this, "State")} value={this.state.activeProperty.State}>
                                                            <option value="">--Select--</option>
                                                            <option value="AA"> AA </option>
                                                            <option value="AE"> AE </option>
                                                            <option value="AK"> AK </option>
                                                            <option value="AL"> AL </option>
                                                            <option value="AP"> AP </option>
                                                            <option value="AR"> AR </option>
                                                            <option value="AS"> AS </option>
                                                            <option value="AZ"> AZ </option>
                                                            <option value="CA"> CA </option>
                                                            <option value="CO"> CO </option>
                                                            <option value="CT"> CT </option>
                                                            <option value="DC"> DC </option>
                                                            <option value="DE"> DE </option>
                                                            <option value="FL"> FL </option>
                                                            <option value="GA"> GA </option>
                                                            <option value="GU"> GU </option>
                                                            <option value="HI"> HI </option>
                                                            <option value="IA"> IA </option>
                                                            <option value="ID"> ID </option>
                                                            <option value="IL"> IL </option>
                                                            <option value="IN"> IN </option>
                                                            <option value="KS"> KS </option>
                                                            <option value="KY"> KY </option>
                                                            <option value="LA"> LA </option>
                                                            <option value="MA"> MA </option>
                                                            <option value="MD"> MD </option>
                                                            <option value="ME"> ME </option>
                                                            <option value="MI"> MI </option>
                                                            <option value="MN"> MN </option>
                                                            <option value="MO"> MO </option>
                                                            <option value="MP"> MP </option>
                                                            <option value="MS"> MS </option>
                                                            <option value="MT"> MT </option>
                                                            <option value="NC"> NC </option>
                                                            <option value="ND"> ND </option>
                                                            <option value="NE"> NE </option>
                                                            <option value="NH"> NH </option>
                                                            <option value="NJ"> NJ </option>
                                                            <option value="NM"> NM </option>
                                                            <option value="NV"> NV </option>
                                                            <option value="NY"> NY </option>
                                                            <option value="OH"> OH </option>
                                                            <option value="OK"> OK </option>
                                                            <option value="OR"> OR </option>
                                                            <option value="PA"> PA </option>
                                                            <option value="PR"> PR </option>
                                                            <option value="RI"> RI </option>
                                                            <option value="SC"> SC </option>
                                                            <option value="SD"> SD </option>
                                                            <option value="TN"> TN </option>
                                                            <option value="TX"> TX </option>
                                                            <option value="UT"> UT </option>
                                                            <option value="VA"> VA </option>
                                                            <option value="VI"> VI </option>
                                                            <option value="VT"> VT </option>
                                                            <option value="WA"> WA </option>
                                                            <option value="WI"> WI </option>
                                                            <option value="WV"> WV </option>
                                                            <option value="WY"> WY </option>
                                                        </select>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropState"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-4 col-lg-2">
                                                        <label>Zip</label>
                                                        <input className="form-control" type="text" maxlength="5" onChange={this.handlePropertyChanges.bind(this, "Zipcode")} value={this.state.activeProperty.Zipcode}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropZipcode"]}</span>
                                                    </div>
                                                    {/* <div class="form-group col-sm-6 col-lg-4">
                                                        <label>Email</label>
                                                        <input className="form-control" type="text" maxlength="255" onChange={this.handlePropertyChanges.bind(this, "Email")} value={this.state.activeProperty.Email}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropEmail"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-6 col-lg-3">
                                                        <label>Phone</label>
                                                        <input className="form-control" type="text" maxlength="14" onChange={this.handlePropertyChanges.bind(this, "Phone")} value={this.state.activeProperty.Phone}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropPhone"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-6 col-lg-3">
                                                        <label>Extension</label>
                                                        <input className="form-control" type="text" maxlength="8" onChange={this.handlePropertyChanges.bind(this, "PhoneExtension")} value={this.state.activeProperty.PhoneExtension}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropPhoneExtension"]}</span>
                                                    </div> */}
                                                    <div class="form-group col-sm-6 col-lg-4">
                                                        <label>Management Company</label>
                                                        {/* <input className="form-control" type="text" maxlength="50" onChange={this.handlePropertyChanges.bind(this, "ManagementCompany")} value={this.state.activeProperty.ManagementCompany}></input> */}
                                                        <Autocomplete className="uw-autocomplete"
                                                            value={this.state.selectedManagementCompany}
                                                            onChange={(event, newValue1) => {
                                                                if (typeof newValue1 === 'string') {
                                                                    this.setState({ selectedManagementCompany: newValue1 });
                                                                } else if (newValue1 && newValue1.inputValue) {
                                                                    this.state.managementCompanies.push({ title: newValue1.inputValue, Id: "0" });
                                                                    this.setState({ selectedManagementCompany: { title: newValue1.inputValue, Id: "0" } });
                                                                } else {
                                                                    this.setState({ selectedManagementCompany: newValue1 });
                                                                }
                                                            }}
                                                            filterOptions={(options, params) => {
                                                                const filtered = filter(options, params);
                                                                let isFound = false;
                                                                filtered.forEach(e => {
                                                                    if (e.title === params.inputValue) {
                                                                        isFound = true;
                                                                    }
                                                                });
                                                                // Suggest the creation of a new value
                                                                if (params.inputValue !== '' && !isFound) {
                                                                    filtered.push({
                                                                        inputValue: params.inputValue,
                                                                        //title: `Add "${params.inputValue}"`,
                                                                        title: `+ New Company "${params.inputValue}"`,
                                                                    });
                                                                }
                                                                return filtered;
                                                            }}
                                                            selectOnFocus
                                                            clearOnBlur
                                                            handleHomeEndKeys
                                                            //autoComplete={true}
                                                            autoSelect={true}
                                                            id="ManagementCompany"
                                                            options={this.state.managementCompanies}
                                                            getOptionLabel={(option) => {
                                                                // Value selected with enter, right from the input
                                                                if (typeof option === 'string') {
                                                                    return option;
                                                                }
                                                                // Add "xyz" option created dynamically
                                                                if (option.inputValue) {
                                                                    return option.inputValue;
                                                                }
                                                                // Regular option
                                                                return option.title;
                                                            }}
                                                            renderOption={(option) => option.title}
                                                            // style={{ width: 300 }}
                                                            //freeSolo
                                                            renderInput={(params) => (
                                                                <TextField {...params} className="form-control" />
                                                            )}
                                                        />
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropManagementCompany"]}</span>
                                                    </div>

                                                    <div className="col-sm-12 col-auto  mb-3">
                                                        <label>Public Housing Authority registered?</label>
                                                        <div className="whoc-multi-fields">
                                                            <div class="custom-control custom-control-inline custom-radio whoc-radio">
                                                                <input type="radio" name="PHAStatus" id="PHAStatus1" value="Registered" data-text="Registered" class="custom-control-input"
                                                                    checked={this.state.propertyUnit && this.state.activeProperty.PHAStatus === "Registered"}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="PHAStatus1">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-control-inline custom-radio whoc-radio">
                                                                <input type="radio" name="PHAStatus" value="Not Registered" data-text="Not Registered" class="custom-control-input" id="PHAStatus2"
                                                                    checked={this.state.propertyUnit && this.state.activeProperty.PHAStatus === "Not Registered"}
                                                                    onChange={this.OnRadioButtonChanged.bind(this)} />
                                                                <label class="custom-control-label" for="PHAStatus2">No</label>
                                                            </div>
                                                        </div>
                                                        <div class="alert-small-text">{this.state.validationPropertyErrors["PHAStatus"]}</div>
                                                    </div>

                                                    <div class="form-group col-sm-2 col-lg-3">
                                                        <label>Payee name</label>
                                                        <input className="form-control" type="text" maxlength="255" onChange={this.handlePropertyChanges.bind(this, "pyei_Name")} value={this.state.activeProperty.pyei_Name}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_Name"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-3 col-lg-6">
                                                        <label>Payee Address</label>
                                                        <input className="form-control" type="text" maxlength="100" onChange={this.handlePropertyChanges.bind(this, "pyei_Address")} value={this.state.activeProperty.pyei_Address}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_Address"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-2 col-lg-3">
                                                        <label>Payee Suite<i class=""></i></label>
                                                        <input className="form-control" type="text" maxlength="40" onChange={this.handlePropertyChanges.bind(this, "pyei_Unit")} value={this.state.activeProperty.pyei_Unit}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_Unit"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-2 col-lg-3">
                                                        <label>Payee City</label>
                                                        <input className="form-control" type="text" maxlength="100" onChange={this.handlePropertyChanges.bind(this, "pyei_City")} value={this.state.activeProperty.pyei_City}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_City"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-1 col-lg-3">
                                                        <label> Payee State</label>
                                                        <select className="form-control" ref={(input) => this.stateSelector = input} onChange={this.handlePropertyChanges.bind(this, "pyei_State")} value={this.state.activeProperty.pyei_State} >
                                                            <option value="">--Select--</option>
                                                            <option value="AA"> AA </option>
                                                            <option value="AE"> AE </option>
                                                            <option value="AK"> AK </option>
                                                            <option value="AL"> AL </option>
                                                            <option value="AP"> AP </option>
                                                            <option value="AR"> AR </option>
                                                            <option value="AS"> AS </option>
                                                            <option value="AZ"> AZ </option>
                                                            <option value="CA"> CA </option>
                                                            <option value="CO"> CO </option>
                                                            <option value="CT"> CT </option>
                                                            <option value="DC"> DC </option>
                                                            <option value="DE"> DE </option>
                                                            <option value="FL"> FL </option>
                                                            <option value="GA"> GA </option>
                                                            <option value="GU"> GU </option>
                                                            <option value="HI"> HI </option>
                                                            <option value="IA"> IA </option>
                                                            <option value="ID"> ID </option>
                                                            <option value="IL"> IL </option>
                                                            <option value="IN"> IN </option>
                                                            <option value="KS"> KS </option>
                                                            <option value="KY"> KY </option>
                                                            <option value="LA"> LA </option>
                                                            <option value="MA"> MA </option>
                                                            <option value="MD"> MD </option>
                                                            <option value="ME"> ME </option>
                                                            <option value="MI"> MI </option>
                                                            <option value="MN"> MN </option>
                                                            <option value="MO"> MO </option>
                                                            <option value="MP"> MP </option>
                                                            <option value="MS"> MS </option>
                                                            <option value="MT"> MT </option>
                                                            <option value="NC"> NC </option>
                                                            <option value="ND"> ND </option>
                                                            <option value="NE"> NE </option>
                                                            <option value="NH"> NH </option>
                                                            <option value="NJ"> NJ </option>
                                                            <option value="NM"> NM </option>
                                                            <option value="NV"> NV </option>
                                                            <option value="NY"> NY </option>
                                                            <option value="OH"> OH </option>
                                                            <option value="OK"> OK </option>
                                                            <option value="OR"> OR </option>
                                                            <option value="PA"> PA </option>
                                                            <option value="PR"> PR </option>
                                                            <option value="RI"> RI </option>
                                                            <option value="SC"> SC </option>
                                                            <option value="SD"> SD </option>
                                                            <option value="TN"> TN </option>
                                                            <option value="TX"> TX </option>
                                                            <option value="UT"> UT </option>
                                                            <option value="VA"> VA </option>
                                                            <option value="VI"> VI </option>
                                                            <option value="VT"> VT </option>
                                                            <option value="WA"> WA </option>
                                                            <option value="WI"> WI </option>
                                                            <option value="WV"> WV </option>
                                                            <option value="WY"> WY </option>
                                                        </select>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_State"]}</span>
                                                    </div>
                                                    <div class="form-group col-sm-2 col-lg-2">
                                                        <label>Payee Zip</label>
                                                        <input className="form-control" type="text" maxlength="5" onChange={this.handlePropertyChanges.bind(this, "pyei_Zip")} value={this.state.activeProperty.pyei_Zip}></input>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["pyei_Zip"]}</span>
                                                    </div>

                                                    <div class="form-group col-sm-6 col-lg-4">
                                                        <label>Portal User<i class="fas fa-asterisk asterisk"></i></label>
                                                        <select class="form-control" name="PortalUserCentralContactId" id="PortalUserCentralContactId" onChange={this.handlePropertyChanges.bind(this, "PortalUserCentralContactId")}>
                                                            <option value="">Please Select</option>
                                                            {this.renderPropertyOwnerOptions()}
                                                        </select>
                                                        <span class="alert-small-text">{this.state.validationPropertyErrors["PropPortalUserCentralContactId"]}</span>
                                                        {/* <Select labelId="demo-simple-select-readonly-label"  className="" id="demo-simple-select-readonly" inputProps={{  }}>
                                                    {this.renderDivisionOptions()}
                                            </Select> */}
                                                    </div>

                                                </div>
                                                <p class="wh-note mt-3"><span>Note:</span> To add additional information about this property, go to the Property record from the user dashboard.  </p>
                                            </div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary btn-light" data-dismiss="modal" onClick={this.resetPropertyPopup}>Close</button>
                                                <button type="button" class="btn btn-primary btn-dark" onClick={this.addProperty.bind(this)}>Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>
                </div>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }
    }
}

export default withRouter(AddUnit);