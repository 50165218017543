import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import RefreshIcon from '@material-ui/icons/Refresh';
import DownloadIcon from '@material-ui/icons/CloudDownload';
// import DownloadForOfflineIcon from '@material-ui/icons/DownloadForOffline';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import { withStyles } from "@material-ui/core/styles";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
// import ToggleOnIcon from '@material-ui/icons/ToggleOn';
// import ToggleOffIcon from '@material-ui/icons/ToggleOff';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const defaultToolbarStyles = {
    iconButton: {
    },
};

class CustomToolbar extends React.Component {

    showHideTitle = "";
    showHideTitleTooltip = "";
    constructor(props, context) {
        super(props, context);
        this.state = {
            clicked: true,
            case: "OPEN CASE",
        }
        if (this.props.isERAClaims == true) {
            this.showHideTitle = "Show Issued Claims"
            this.showHideTitleTooltip = "Show/Hide Issued Claims";
        }
        else {
            this.showHideTitle = "Show Approved Claims";
            this.showHideTitleTooltip = "Show/Hide Approved Claims";
        }
    }

    handleHideCompletedClick = async () => {
        let oldState = this.state.clicked;
        this.setState({ clicked: !oldState });
        //console.log("clicked on Hide Completed icon!");
        this.props.hideCompletedCallback();
    }

    handleRefreshClick = async () => {
        //console.log("clicked on refresh icon!");
        this.props.refreshCallback();
    }

    handleDownloadClick = async () => {
        //console.log("clicked on download icon!");
        this.props.downloadCallback();
    }

    handleDownloadAllClick = async () => {
        //console.log("clicked on download icon!");
        this.props.downloadAllCallback();
    }

    handleBulkUpdateClick = async () => {
        //console.log("clicked on download icon!");
        this.props.bulkUpdateCallback();
    }

    handleCaseClick = async (e, value) => {
        e.preventDefault();
        //console.log("clicked on download icon!");
        debugger
        this.setState({ case: value });
        this.props.handleCaseClickCallback(value);
    }

    render() {
        const { classes } = this.props;
        if (this.props.isERAClaims == true) {
            return (
                <React.Fragment>
                    <Paper square>
                        <Tabs
                            value={this.state.case}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={this.handleCaseClick.bind(this)}
                            aria-label="disabled tabs example"
                        >
                            <Tab label="OPEN CASES" value="OPEN CASE" />
                            <Tab label="CLOSE CASES" value="CLOSE CASE" />
                            <Tab label="ALL CASES" value="ALL CASE" />
                        </Tabs>
                    </Paper>

                    <Tooltip title={"Download CSV"}>
                        <IconButton className={classes.iconButton} onClick={this.handleDownloadClick}>
                            <DownloadIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Bulk Update"}>
                        <IconButton className={classes.iconButton} onClick={this.handleBulkUpdateClick}>
                            <ArrowUpward className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh"}>
                        <IconButton className={classes.iconButton} onClick={this.handleRefreshClick}>
                            <RefreshIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        }
        else if(this.props.isRec == true) {
            return (
                <React.Fragment>
                    <Tooltip title={"Download CSV"}>
                        <IconButton className={classes.iconButton} onClick={this.handleDownloadClick}>
                            <DownloadIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh"}>
                        <IconButton className={classes.iconButton} onClick={this.handleRefreshClick}>
                            <RefreshIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title={this.showHideTitleTooltip}>
                        <IconButton className={classes.iconButton} onClick={this.handleHideCompletedClick} style={{ background: 'transparent', color: '#005191' }} disableTouchRipple={true} >
                            {this.state.clicked ? <CheckBoxOutlineBlankIcon className={classes.deleteIcon} > </CheckBoxOutlineBlankIcon> : <CheckBoxIcon className={classes.deleteIcon} />}
                            <div className="toolbar-text"> {this.showHideTitle} </div>
                        </IconButton>
                    </Tooltip> */}
                </React.Fragment>
            );
        }
        else if(this.props.isTran == true) {
            return (
                <React.Fragment>
                    <Tooltip title={"Download Yet to be Paid Transaction"}>
                        <IconButton className={classes.iconButton} onClick={this.handleDownloadClick}>
                            <DownloadIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Download All Transaction"}>
                        <IconButton className={classes.iconButton} onClick={this.handleDownloadAllClick}>
                            <SystemUpdateAltIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh"}>
                        <IconButton className={classes.iconButton} onClick={this.handleRefreshClick}>
                            <RefreshIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip title={this.showHideTitleTooltip}>
                        <IconButton className={classes.iconButton} onClick={this.handleHideCompletedClick} style={{ background: 'transparent', color: '#005191' }} disableTouchRipple={true} >
                            {this.state.clicked ? <CheckBoxOutlineBlankIcon className={classes.deleteIcon} > </CheckBoxOutlineBlankIcon> : <CheckBoxIcon className={classes.deleteIcon} />}
                            <div className="toolbar-text"> {this.showHideTitle} </div>
                        </IconButton>
                    </Tooltip> */}
                </React.Fragment>
            );
        }
        else {
            return (
                <React.Fragment>
                    <Tooltip title={"Download CSV"}>
                        <IconButton className={classes.iconButton} onClick={this.handleDownloadClick}>
                            <DownloadIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={"Refresh"}>
                        <IconButton className={classes.iconButton} onClick={this.handleRefreshClick}>
                            <RefreshIcon className={classes.deleteIcon} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={this.showHideTitleTooltip}>
                        <IconButton className={classes.iconButton} onClick={this.handleHideCompletedClick} style={{ background: 'transparent', color: '#005191' }} disableTouchRipple={true} >
                            {this.state.clicked ? <CheckBoxOutlineBlankIcon className={classes.deleteIcon} > </CheckBoxOutlineBlankIcon> : <CheckBoxIcon className={classes.deleteIcon} />}
                            <div className="toolbar-text"> {this.showHideTitle} </div>
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            );
        }
    }

}

export default withStyles(defaultToolbarStyles, { name: "CustomToolbar" })(CustomToolbar);
