import React, { Component } from "react";
import { Helmet } from "react-helmet";
import imgLogo from './imgs/logo-united-way.png';
import Tooltip from "@material-ui/core/Tooltip";
import { Auth } from "aws-amplify";
import { NavLink } from "react-router-dom";

let UserName = '';
class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state={
      isPermissionsLoad: false,
    }
  }

  signOut = async () => {
    try {
      await Auth.signOut();
      window.location.assign("/");
    } catch (e) {
      console.log(e);
    }
  };

  logoClick = function () {
    window.location.assign("/");
  };

  getApplicationInfo = async (e) => {
    this.setState({ isLoading: true });
    var user = await Auth.currentAuthenticatedUser();
    console.log(user);
    UserName = user.attributes.name;
    localStorage.setItem("UserName", UserName);
  }

  componentDidUpdate() {
    if (this.props.authState === "signedIn" && this.state.isPermissionsLoad === false) {
        this.getApplicationInfo();
        this.setState({ isPermissionsLoad: true });
    }
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>UnitedWayOC</title>
        </Helmet>
        <header>
          <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
            <a className="navbar-brand"  onClick={() => this.logoClick()}>
              <img
                src={imgLogo}
                height="70"
                className="logo"
                alt="Assist OC logo"
                loading="lazy"
              />
            </a>
            <div className="ml-auto">
              {/* <span className="text-primary">Welcome {UserName}</span> */}
              <span className="header-pipeline">|</span>
              <button
                className="btn btn-link red pr-0 pl-1"
                onClick={this.signOut}
              >
                <i className="fas fa-power-off ml-1"></i>
              </button>
              {/* {<button className="btn btn-link text-secondary" onClick={this.getSessionInfo}>Get Session</button> } */}
            </div>
          </nav>
        </header>
        <main className="main pb-3">
          <div className="container-fluid">
          <div className="d-flex flex-column sticky-footer-wrapper">
              <div className="box-shadow details-page p-5 text-center">
                <h2 className="not-allowed" style={{fontSize: '20px', color: 'red'}}>Page not found</h2>
              </div>
            </div>
          </div>
        </main>
      </div>
    );
  }
}
export default PageNotFound;
