import React from "react";
class PageNotFound extends React.Component {
  render() {
    return (
      <div className="d-flex flex-column sticky-footer-wrapper">
        <div class="box-shadow details-page p-5 text-center">
          <h2 class="not-allowed">
            Page not found
          </h2>
        </div>
      </div>
    );
  }
}
export default PageNotFound;
