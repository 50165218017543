import React from "react";
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import $ from 'jquery';
import { API, Storage, Auth } from 'aws-amplify';

class PHARegistrationStep5 extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            AHASigner1Status: "",
            AHASigner2Status: "",
            OCHASigner1Status: "",
            OCHASigner2Status: "",
            SAHASigner1Status: "",
            SAHASigner2Status: "",

            SelectedAuthority: "",
            PHARemarksByAdmin: "",
        }

        this.propertyInfo = [];
    }

    async componentDidMount() {
        // console.log(+this.props.match.params.id);
        // console.log(+this.props.location.state?.ID);
        this.setState({ isLoading: true });
        var info = await this.getPropertyDetails();
        if (info.length > 0) {
            this.propertyInfo = info;
        }

        //console.log('step5');
        //console.log(this.propertyInfo);

        if (this.propertyInfo[0]["AHASigner1Status"] === "Completed") {
            if (this.propertyInfo[0]["AHASigner2Status"] === "Completed") {
                this.setState({ AHASigner2Status: "Completed" });
            }
            else {
                this.setState({ AHASigner2Status: "Pending" });
            }
            this.setState({ AHASigner1Status: "Completed" });

            document.getElementById("reviewNSignAHAId").disabled = true;
        }
        if (this.propertyInfo[0]["OCHASigner1Status"] === "Completed") {
            if (this.propertyInfo[0]["OCHASigner2Status"] === "Completed") {
                this.setState({ OCHASigner2Status: "Completed" });
            }
            else {
                this.setState({ OCHASigner2Status: "Pending" });
            }
            this.setState({ OCHASigner1Status: "Completed" });

            document.getElementById("reviewNSignOCHAId").disabled = true;
        }
        if (this.propertyInfo[0]["SAHASigner1Status"] === "Completed") {
            if (this.propertyInfo[0]["SAHASigner2Status"] === "Completed") {
                this.setState({ SAHASigner2Status: "Completed" });
            }
            else {
                this.setState({ SAHASigner2Status: "Pending" });
            }
            this.setState({ SAHASigner1Status: "Completed" });

            document.getElementById("reviewNSignSAHAId").disabled = true;
        }

        this.setState({ isLoading: false });

        //console.log(JSON.stringify(this.propertyInfo[0]));
    }

    getPropertyDetails = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/getpropertydetailsbypropertyid';
        let testId = this.props.location.state?.ID ;
        let formattedId = testId && testId.toString() || localStorage.getItem('url').split('/')[5].split('?')[0];
        let myInit = {
            body: { "PropertyID": +formattedId },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    requestEmbeddedSignatureForPHA = async () => {
        this.propertyInfo[0]["WhatToUpdate"] = "Step4";
        this.propertyInfo[0]["SelectedAuthority"] = this.state.SelectedAuthority;
        this.propertyInfo[0]["PhaRedirectURL"] = process.env.REACT_APP_PhaRedirectURL;

        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayNameWHOC;
        let path = '/requestembeddedsignatureforpha';
        let myInit = {
            body: this.propertyInfo[0],
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    dummyRequest = () => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    resolve();
                },
                2000
            );
        });
    }

    reviewNSignPHA = async () => {
        this.setState({ isLoading: true });

        //await this.dummyRequest();
        //console.log('requestEmbeddedSignatureForPHA');

        let redirectURL = '';
        for (var i = 0; i < 5; i++) {
            try {
                var res = await this.requestEmbeddedSignatureForPHA();
                if (res.message === "Endpoint request timed out") {
                    console.log("Error: Endpoint request timed out. Retrying attempt: " + i);
                    continue;
                }
                else {
                    console.log(res);
                    redirectURL = res.returnUrl;
                    break;
                }
            }
            catch (err) {
                console.log('Error Response');
                console.log(err.response);
                if (err.response && err.response.status === 504) {
                    console.log("Error: Endpoint request timed out. Retrying attempt: " + (i + 1));
                    continue;
                }
                console.log(err);
            }
        }

        this.setState({ isLoading: false });
        // window.location.replace(redirectURL);
        window.location.href = redirectURL;
    }

    reviewNSignAHA = async () => {
        this.setState({ SelectedAuthority: "AHA" });
    }

    reviewNSignOCHA = async () => {
        this.setState({ SelectedAuthority: "OCHA" });
    }

    reviewNSignSAHA = async () => {
        this.setState({ SelectedAuthority: "SAHA" });
    }

    showAdminRemarksAHA = async () => {
        this.setState({ PHARemarksByAdmin: this.propertyInfo[0]["AdminRemarksForAHADocument"] });
    }

    showAdminRemarksOCHA = async () => {
        this.setState({ PHARemarksByAdmin: this.propertyInfo[0]["AdminRemarksForOCHADocument"] });
    }

    showAdminRemarksSAHA = async () => {
        this.setState({ PHARemarksByAdmin: this.propertyInfo[0]["AdminRemarksForSAHADocument"] });
    }

    proceedWithSigningCeremony = async () => {
        $('#reviewNSignModel').modal('hide');
        this.reviewNSignPHA();
    }

    saveForm = async () => {
        // this.setState({ isLoading: true });

        // var res = await this.dummyRequest();

        // this.setState({ isLoading: false });
    }

    validateForm = async () => {
        return true;
    }

    render() {
        return (
            <div className="border p-3">
                <React.Fragment>
                    <input className="form-control" type="hidden" ></input>
                    <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Review and Sign Document(s)</h2><span className="file-allowed">Please review and sign following documents.</span>
                    <div className="table-responsive">
                        <table class="table table-bordered table-cgreen mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">Owner's signature status</th>
                                    <th scope="col">Manager's signature status</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Remarks</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Anaheim Housing Authority</td>
                                    <td>{this.state.AHASigner1Status === '' ? 'Pending' : this.state.AHASigner1Status}</td>
                                    <td>{this.state.AHASigner2Status === '' ? 'Pending' : this.state.AHASigner2Status}</td>
                                    <td>
                                        <button type="button" id="reviewNSignAHAId" class="btn btn-primary btn-red" onClick={this.reviewNSignAHA} data-toggle="modal" data-target="#reviewNSignModel">
                                            <i class="fas fa-file-alt mr-2"></i>
                                            Review & Sign
                                        </button>
                                    </td>
                                    <td>
                                        {this.propertyInfo.length > 0 && this.propertyInfo[0]["AdminRemarksForAHADocument"] !== "" &&
                                            <span onClick={this.showAdminRemarksAHA} data-toggle="modal" data-target="#adminRemarksModel"><i class="fas fa-eye mr-3"></i></span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Orange County Housing Authority</td>
                                    <td>{this.state.OCHASigner1Status === '' ? 'Pending' : this.state.OCHASigner1Status}</td>
                                    <td>{this.state.OCHASigner2Status === '' ? 'Pending' : this.state.OCHASigner2Status}</td>
                                    <td>
                                        <button type="button" id="reviewNSignOCHAId" class="btn btn-primary btn-red" onClick={this.reviewNSignOCHA} data-toggle="modal" data-target="#reviewNSignModel">
                                            <i class="fas fa-file-alt mr-2"></i>
                                            Review & Sign
                                        </button>
                                    </td>
                                    <td>
                                        {this.propertyInfo.length > 0 && this.propertyInfo[0]["AdminRemarksForOCHADocument"] !== "" &&
                                            <span onClick={this.showAdminRemarksOCHA} data-toggle="modal" data-target="#adminRemarksModel"><i class="fas fa-eye mr-3"></i></span>
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>Santa Ana Housing Authority</td>
                                    <td>{this.state.SAHASigner1Status === '' ? 'Pending' : this.state.SAHASigner1Status}</td>
                                    <td>{this.state.SAHASigner2Status === '' ? 'Pending' : this.state.SAHASigner2Status}</td>
                                    <td>
                                        <button type="button" id="reviewNSignSAHAId" class="btn btn-primary btn-red" onClick={this.reviewNSignSAHA} data-toggle="modal" data-target="#reviewNSignModel">
                                            <i class="fas fa-file-alt mr-2"></i>
                                            Review & Sign
                                        </button>
                                    </td>
                                    <td>
                                        {this.propertyInfo.length > 0 && this.propertyInfo[0]["AdminRemarksForSAHADocument"] !== "" &&
                                            <span onClick={this.showAdminRemarksSAHA} data-toggle="modal" data-target="#adminRemarksModel"><i class="fas fa-eye mr-3"></i></span>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* Review and Sign modal START*/}
                    <div class="modal fade" id="reviewNSignModel" tabindex="-1" aria-labelledby="reviewNSignModelLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="reviewNSignModelLabel"><i class="fas fa-file-alt mr-2"></i>Review And Sign Instructions</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <ul>
                                        <li>Please review each document carefully as it is to be submitted to public housing authorities.</li>
                                        <li>Please provide a digital signature on each highlighted section in document.</li>
                                        <li>In case, while reviewing document, there are any edits to be made please click on browser's Back button. You may click on the wizard step button to navigate to specific step for edits to be made.</li>
                                        <li>Once edits are rightly carried out, please complete the review and signature process.</li>
                                        <li>For any assistance, please reach out to our team on <b>KristineP@unitedwayoc.org</b>.</li>
                                    </ul>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary btn-black btn-sm" onClick={this.proceedWithSigningCeremony} >I have read</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Review and Sign modal END*/}

                    {/* Admin Remarks modal START*/}
                    <div class="modal fade" id="adminRemarksModel" tabindex="-1" aria-labelledby="adminRemarksModelLabel" aria-hidden="true">
                        <div class="modal-dialog modal-dialog-centered modal-lg">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="adminRemarksModelLabel"><i class="fas fa-file-alt mr-2"></i>Remarks by Admin</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <i class="fas fa-times"></i>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <textarea rows="5" className="form-control h-auto" value={this.state.PHARemarksByAdmin}></textarea>
                                </div>
                                <div class="modal-footer">
                                    <button type="button" class="btn btn-primary btn-black btn-sm" data-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Admin Remarks modal END*/}

                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            </div>
        );
    }
}

export default PHARegistrationStep5;
