import React from "react";
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { Auth, Storage } from 'aws-amplify';
import Snackbar from '@material-ui/core/Snackbar';

class PHARegistrationStep4 extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            isLoading: false,

            PropertyID: -1,

            proof_of_ownership: "",
            irs_letter_issuing_tin_ein: "",
            grant_deed: "",
            llc: "",
            management_agreement: "",
            hap_contract: "",
            rent_to_owner_form: "",
            tax_id_verification: "",
            property_tax_bill: "",
            property_management_agreement: "",
            recorded_grant_deed: "",
            remove_home_owner_exemption: "",
            statements_from_property_coowners: "",
            original_voided_check: "",
            final_escrow_statement: "",

            // Documents Uploaded
            proof_of_ownership_uploaded: false,
            irs_letter_issuing_tin_ein_uploaded: false,
            grant_deed_uploaded: false,
            llc_uploaded: false,
            management_agreement_uploaded: false,
            hap_contract_uploaded: false,
            rent_to_owner_form_uploaded: false,
            tax_id_verification_uploaded: false,
            property_tax_bill_uploaded: false,
            property_management_agreement_uploaded: false,
            recorded_grant_deed_uploaded: false,
            remove_home_owner_exemption_uploaded: false,
            statements_from_property_coowners_uploaded: false,
            original_voided_check_uploaded: false,
            final_escrow_statement_uploaded: false,


            openSnackbar: false,
            snackbarSeverity: "",
            snackbarVertical: 'top',
            snackbarHorizontal: 'right',
            snackbarMessage: "",
            snackbarMessageClass: '',
        }

        this.proof_of_ownership_upload = null;
        this.irs_letter_issuing_tin_ein_upload = null;
        this.grant_deed_upload = null;
        this.llc_upload = null;
        this.management_agreement_upload = null;
        this.hap_contract_upload = null;
        this.rent_to_owner_form_upload = null;
        this.tax_id_verification_upload = null;
        this.property_tax_bill_upload = null;
        this.property_management_agreement_upload = null;
        this.recorded_grant_deed_upload = null;
        this.remove_home_owner_exemption_upload = null;
        this.statements_from_property_coowners_upload = null;
        this.original_voided_check_upload = null;
        this.final_escrow_statement_upload = null;
    }

    async componentDidMount() {
        // console.log(+this.props.match.params.id);
        // console.log(+this.props.location.state?.ID);
        let testId = this.props.location.state?.ID ;
        let formattedId = testId.toString();

        this.setState({ PropertyID: +formattedId });

        await Auth.currentAuthenticatedUser();

        window.scrollTo(0, 0); // scroll to the top of the window

        var res = await this.loadUploadedDocuments();
        //console.log(res);
    }

    UploadPHADocuments = async () => {

        if (this.state.PropertyID === -1) {
            // early return
            return;
        }

        this.proof_of_ownership_upload = document.getElementById("proof_of_ownership_upload_id");
        this.irs_letter_issuing_tin_ein_upload = document.getElementById("irs_letter_issuing_tin_ein_upload_id");
        this.grant_deed_upload = document.getElementById("grant_deed_upload_id");
        this.llc_upload = document.getElementById("llc_upload_id");
        this.management_agreement_upload = document.getElementById("management_agreement_upload_id");
        this.hap_contract_upload = document.getElementById("hap_contract_upload_id");
        this.rent_to_owner_form_upload = document.getElementById("rent_to_owner_form_upload_id");
        this.tax_id_verification_upload = document.getElementById("tax_id_verification_upload_id");
        this.property_tax_bill_upload = document.getElementById("property_tax_bill_upload_id");
        this.property_management_agreement_upload = document.getElementById("property_management_agreement_upload_id");
        this.recorded_grant_deed_upload = document.getElementById("recorded_grant_deed_upload_id");
        this.remove_home_owner_exemption_upload = document.getElementById("remove_home_owner_exemption_upload_id");
        this.statements_from_property_coowners_upload = document.getElementById("statements_from_property_coowners_upload_id");
        this.original_voided_check_upload = document.getElementById("original_voided_check_upload_id");
        this.final_escrow_statement_upload = document.getElementById("final_escrow_statement_upload_id");


        Storage.configure({
            bucket: process.env.REACT_APP_S3WHOCAdminBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //// Remove the earlier files for the section for which the new files to be uploaded
        // try {
        //     var key = `Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/`;
        //     var files = await Storage.list(key);

        //     if (this.proof_of_ownership_upload !== null && this.proof_of_ownership_upload.files !== null && this.proof_of_ownership_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("proof_of_ownership/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.irs_letter_issuing_tin_ein_upload !== null && this.irs_letter_issuing_tin_ein_upload.files !== null && this.irs_letter_issuing_tin_ein_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("irs_letter_issuing_tin_ein/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.grant_deed_upload !== null && this.grant_deed_upload.files !== null && this.grant_deed_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("grant_deed/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.llc_upload !== null && this.llc_upload.files !== null && this.llc_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("llc/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.management_agreement_upload !== null && this.management_agreement_upload.files !== null && this.management_agreement_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("management_agreement/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.hap_contract_upload !== null && this.hap_contract_upload.files !== null && this.hap_contract_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("hap_contract/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.rent_to_owner_form_upload !== null && this.rent_to_owner_form_upload.files !== null && this.rent_to_owner_form_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("rent_to_owner_form/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.tax_id_verification_upload !== null && this.tax_id_verification_upload.files !== null && this.tax_id_verification_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("tax_id_verification/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.property_tax_bill_upload !== null && this.property_tax_bill_upload.files !== null && this.property_tax_bill_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("property_tax_bill/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.property_management_agreement_upload !== null && this.property_management_agreement_upload.files !== null && this.property_management_agreement_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("property_management_agreement/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.recorded_grant_deed_upload !== null && this.recorded_grant_deed_upload.files !== null && this.recorded_grant_deed_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("recorded_grant_deed/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.remove_home_owner_exemption_upload !== null && this.remove_home_owner_exemption_upload.files !== null && this.remove_home_owner_exemption_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("remove_home_owner_exemption/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.statements_from_property_coowners_upload !== null && this.statements_from_property_coowners_upload.files !== null && this.statements_from_property_coowners_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("statements_from_property_coowners/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.original_voided_check_upload !== null && this.original_voided_check_upload.files !== null && this.original_voided_check_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("original_voided_check/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        //     if (this.final_escrow_statement_upload !== null && this.final_escrow_statement_upload.files !== null && this.final_escrow_statement_upload.files.length > 0) {
        //         for (var i = 0; i < files.length; i++) {
        //             if (files[i].key.includes("final_escrow_statement/")) {
        //                 var key1 = files[i].key;
        //                 var delres = await Storage.remove(key1);
        //                 console.log(delres);
        //                 break;
        //             }
        //         }
        //     }
        // }
        // catch (err) {
        //     console.log(err);
        //     return;
        // }


        // Upload the new documents to be uploaded `Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/`
        if (this.proof_of_ownership_upload !== null && this.proof_of_ownership_upload.files !== null && this.proof_of_ownership_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/proof_of_ownership/${this.proof_of_ownership_upload.files[0].name}`,
                    this.proof_of_ownership_upload.files[0],
                    { contentType: this.proof_of_ownership_upload.files[0].type });
                //console.log(result);
                this.proof_of_ownership_upload = null;

                this.setState({ proof_of_ownership_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.irs_letter_issuing_tin_ein_upload !== null && this.irs_letter_issuing_tin_ein_upload.files !== null && this.irs_letter_issuing_tin_ein_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/irs_letter_issuing_tin_ein/${this.irs_letter_issuing_tin_ein_upload.files[0].name}`,
                    this.irs_letter_issuing_tin_ein_upload.files[0],
                    { contentType: this.irs_letter_issuing_tin_ein_upload.files[0].type });
                //console.log(result);
                this.irs_letter_issuing_tin_ein_upload = null;

                this.setState({ irs_letter_issuing_tin_ein_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.grant_deed_upload !== null && this.grant_deed_upload.files !== null && this.grant_deed_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/grant_deed/${this.grant_deed_upload.files[0].name}`,
                    this.grant_deed_upload.files[0],
                    { contentType: this.grant_deed_upload.files[0].type });
                //console.log(result);
                this.grant_deed_upload = null;

                this.setState({ grant_deed_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.llc_upload !== null && this.llc_upload.files !== null && this.llc_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/llc/${this.llc_upload.files[0].name}`,
                    this.llc_upload.files[0],
                    { contentType: this.llc_upload.files[0].type });
                //console.log(result);
                this.llc_upload = null;

                this.setState({ llc_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.management_agreement_upload !== null && this.management_agreement_upload.files !== null && this.management_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/management_agreement/${this.management_agreement_upload.files[0].name}`,
                    this.management_agreement_upload.files[0],
                    { contentType: this.management_agreement_upload.files[0].type });
                //console.log(result);
                this.management_agreement_upload = null;

                this.setState({ management_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.hap_contract_upload !== null && this.hap_contract_upload.files !== null && this.hap_contract_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/hap_contract/${this.hap_contract_upload.files[0].name}`,
                    this.hap_contract_upload.files[0],
                    { contentType: this.hap_contract_upload.files[0].type });
                //console.log(result);
                this.hap_contract_upload = null;

                this.setState({ hap_contract_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.rent_to_owner_form_upload !== null && this.rent_to_owner_form_upload.files !== null && this.rent_to_owner_form_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/rent_to_owner_form/${this.rent_to_owner_form_upload.files[0].name}`,
                    this.rent_to_owner_form_upload.files[0],
                    { contentType: this.rent_to_owner_form_upload.files[0].type });
                //console.log(result);
                this.rent_to_owner_form_upload = null;

                this.setState({ rent_to_owner_form_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.tax_id_verification_upload !== null && this.tax_id_verification_upload.files !== null && this.tax_id_verification_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/tax_id_verification/${this.tax_id_verification_upload.files[0].name}`,
                    this.tax_id_verification_upload.files[0],
                    { contentType: this.tax_id_verification_upload.files[0].type });
                //console.log(result);
                this.tax_id_verification_upload = null;

                this.setState({ tax_id_verification_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.property_tax_bill_upload !== null && this.property_tax_bill_upload.files !== null && this.property_tax_bill_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/property_tax_bill/${this.property_tax_bill_upload.files[0].name}`,
                    this.property_tax_bill_upload.files[0],
                    { contentType: this.property_tax_bill_upload.files[0].type });
                //console.log(result);
                this.property_tax_bill_upload = null;

                this.setState({ property_tax_bill_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.property_management_agreement_upload !== null && this.property_management_agreement_upload.files !== null && this.property_management_agreement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/property_management_agreement/${this.property_management_agreement_upload.files[0].name}`,
                    this.property_management_agreement_upload.files[0],
                    { contentType: this.property_management_agreement_upload.files[0].type });
                //console.log(result);
                this.property_management_agreement_upload = null;

                this.setState({ property_management_agreement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.recorded_grant_deed_upload !== null && this.recorded_grant_deed_upload.files !== null && this.recorded_grant_deed_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/recorded_grant_deed/${this.recorded_grant_deed_upload.files[0].name}`,
                    this.recorded_grant_deed_upload.files[0],
                    { contentType: this.recorded_grant_deed_upload.files[0].type });
                //console.log(result);
                this.recorded_grant_deed_upload = null;

                this.setState({ recorded_grant_deed_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.remove_home_owner_exemption_upload !== null && this.remove_home_owner_exemption_upload.files !== null && this.remove_home_owner_exemption_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/remove_home_owner_exemption/${this.remove_home_owner_exemption_upload.files[0].name}`,
                    this.remove_home_owner_exemption_upload.files[0],
                    { contentType: this.remove_home_owner_exemption_upload.files[0].type });
                //console.log(result);
                this.remove_home_owner_exemption_upload = null;

                this.setState({ remove_home_owner_exemption_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.statements_from_property_coowners_upload !== null && this.statements_from_property_coowners_upload.files !== null && this.statements_from_property_coowners_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/statements_from_property_coowners/${this.statements_from_property_coowners_upload.files[0].name}`,
                    this.statements_from_property_coowners_upload.files[0],
                    { contentType: this.statements_from_property_coowners_upload.files[0].type });
                //console.log(result);
                this.statements_from_property_coowners_upload = null;

                this.setState({ statements_from_property_coowners_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.original_voided_check_upload !== null && this.original_voided_check_upload.files !== null && this.original_voided_check_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/original_voided_check/${this.original_voided_check_upload.files[0].name}`,
                    this.original_voided_check_upload.files[0],
                    { contentType: this.original_voided_check_upload.files[0].type });
                //console.log(result);
                this.original_voided_check_upload = null;

                this.setState({ original_voided_check_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }

        if (this.final_escrow_statement_upload !== null && this.final_escrow_statement_upload.files !== null && this.final_escrow_statement_upload.files.length > 0) {
            try {
                var result = await Storage.put(`Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/final_escrow_statement/${this.final_escrow_statement_upload.files[0].name}`,
                    this.final_escrow_statement_upload.files[0],
                    { contentType: this.final_escrow_statement_upload.files[0].type });
                //console.log(result);
                this.final_escrow_statement_upload = null;

                this.setState({ final_escrow_statement_uploaded: true });
            }
            catch (err) {
                console.log(err)
                return;
            }
        }
    }

    dummyRequest = (url) => {
        return new Promise((resolve, reject) => {
            window.setTimeout(
                () => {
                    console.log('dummy work completed');
                    resolve();
                },
                2000
            );
        });
    }

    saveForm = async () => {
        this.setState({ isLoading: true });

        var res = await this.UploadPHADocuments();
        //console.log(res);
        res = await this.loadUploadedDocuments();
        //console.log(res);

        this.setState({ isLoading: false });
        this.setState({ openSnackbar: true, snackbarMessage: "The updates are been saved",snackbarMessageClass: 'Mui-filledSuccess', hideDuration: 3000 });
    }

    validateForm = async () => {
        // Hide validation error messages
        document.getElementById('proof_of_ownership_validation_id').style.display = "none";
        document.getElementById('irs_letter_issuing_tin_ein_validation_id').style.display = "none";
        document.getElementById('grant_deed_validation_id').style.display = "none";
        document.getElementById('llc_validation_id').style.display = "none";
        document.getElementById('management_agreement_validation_id').style.display = "none";
        document.getElementById('hap_contract_validation_id').style.display = "none";
        document.getElementById('rent_to_owner_form_validation_id').style.display = "none";
        document.getElementById('tax_id_verification_validation_id').style.display = "none";
        document.getElementById('property_tax_bill_validation_id').style.display = "none";
        document.getElementById('recorded_grant_deed_validation_id').style.display = "none";
        document.getElementById('original_voided_check_validation_id').style.display = "none";
        document.getElementById('final_escrow_statement_validation_id').style.display = "none";

        this.setState({ isLoading: true });

        //var res = await this.dummyRequest('');
        var res = await this.UploadPHADocuments();
        //console.log(res);

        var validationError = false;
        if (this.state.proof_of_ownership_uploaded === false) {
            document.getElementById('proof_of_ownership_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.irs_letter_issuing_tin_ein_uploaded === false) {
            document.getElementById('irs_letter_issuing_tin_ein_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.grant_deed_uploaded === false) {
            document.getElementById('grant_deed_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.llc_uploaded === false) {
            document.getElementById('llc_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.management_agreement_uploaded === false) {
            document.getElementById('management_agreement_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.hap_contract_uploaded === false) {
            document.getElementById('hap_contract_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.rent_to_owner_form_uploaded === false) {
            document.getElementById('rent_to_owner_form_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.tax_id_verification_uploaded === false) {
            document.getElementById('tax_id_verification_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.property_tax_bill_uploaded === false) {
            document.getElementById('property_tax_bill_validation_id').style.display = "block";
            validationError = true;
        }
        // if (this.state.property_management_agreement_uploaded === false) {
        //     validationError = true;
        // }
        if (this.state.recorded_grant_deed_uploaded === false) {
            document.getElementById('recorded_grant_deed_validation_id').style.display = "block";
            validationError = true;
        }
        // if (this.state.remove_home_owner_exemption_uploaded === false) {
        //     validationError = true;
        // }
        // if (this.state.statements_from_property_coowners_uploaded === false) {
        //     validationError = true;
        // }
        if (this.state.original_voided_check_uploaded === false) {
            document.getElementById('original_voided_check_validation_id').style.display = "block";
            validationError = true;
        }
        if (this.state.final_escrow_statement_uploaded === false) {
            document.getElementById('final_escrow_statement_validation_id').style.display = "block";
            validationError = true;
        }

        if (validationError === true) {
            window.scrollTo(0, 0); // scroll to the top of the window
            this.setState({ isLoading: false });
            return false;
        }

        this.setState({ isLoading: false });
        return true;
    }

    validateUploadFileType(e) {
        if (e.target.files.length > 0 && !e.target.files[0].type.match('image/jp.*') && !e.target.files[0].type.match('.pdf')) {
            e.target.value = ''; 
            this.setState({snackbarMessageClass: 'Mui-filledError', snackbarMessage: "Please upload only Jpeg or PDF file.", openSnackbar: true});
        }
    }

    loadUploadedDocuments = async () => {
        this.setState({ isLoading: true });

        document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <i class="fas fa-asterisk asterisk"></i><div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_ownership_upload_id" /></td>`;
        document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <i class="fas fa-asterisk asterisk"></i><div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="irs_letter_issuing_tin_ein_upload_id" /></td>`;
        document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="grant_deed_upload_id" /></td>`;
        document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <i class="fas fa-asterisk asterisk"></i><div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="llc_upload_id" /></td>`;
        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <i class="fas fa-asterisk asterisk"></i><div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
        document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <i class="fas fa-asterisk asterisk"></i><div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="hap_contract_upload_id" /></td>`;
        document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <i class="fas fa-asterisk asterisk"></i><div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rent_to_owner_form_upload_id" /></td>`;
        document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <i class="fas fa-asterisk asterisk"></i><div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tax_id_verification_upload_id" /></td>`;
        document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_tax_bill_upload_id" /></td>`;
        document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_management_agreement_upload_id" /></td>`;
        document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="recorded_grant_deed_upload_id" /></td>`;
        document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption<td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="remove_home_owner_exemption_upload_id" /></td>`;
        document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="statements_from_property_coowners_upload_id" /></td>`;
        document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <i class="fas fa-asterisk asterisk"></i><div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="original_voided_check_upload_id" /></td>`;
        document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="final_escrow_statement_upload_id" /></td>`;

        document.getElementById('proof_of_ownership_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('irs_letter_issuing_tin_ein_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('grant_deed_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('llc_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('management_agreement_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('hap_contract_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('rent_to_owner_form_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('tax_id_verification_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('property_tax_bill_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('property_management_agreement_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('recorded_grant_deed_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('remove_home_owner_exemption_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('statements_from_property_coowners_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('original_voided_check_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })
        document.getElementById('final_escrow_statement_id').addEventListener("change", (e) => { this.validateUploadFileType(e); })

        // Reset all the flags
        this.setState({
            proof_of_ownership_uploaded: false,
            irs_letter_issuing_tin_ein_uploaded: false,
            grant_deed_uploaded: false,
            llc_uploaded: false,
            management_agreement_uploaded: false,
            hap_contract_uploaded: false,
            rent_to_owner_form_uploaded: false,
            tax_id_verification_uploaded: false,
            property_tax_bill_uploaded: false,
            property_management_agreement_uploaded: false,
            recorded_grant_deed_uploaded: false,
            remove_home_owner_exemption_uploaded: false,
            statements_from_property_coowners_uploaded: false,
            original_voided_check_uploaded: false,
            final_escrow_statement_uploaded: false,
        });

        Storage.configure({
            bucket: process.env.REACT_APP_S3WHOCAdminBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //get file names for s3 keys
        try {
            var key = `Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/`;
            var files = await Storage.list(key);
            //console.log(files);
        }
        catch (err) {
            console.log("Error Files - " + err);
            this.setState({ isLoading: false });
            return 1;
        }

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("proof_of_ownership/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ proof_of_ownership: file1 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <i class="fas fa-asterisk asterisk"></i><div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.proof_of_ownership}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File1"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag1 = document.getElementById('deleteS3File1');
                    if (aTag1 !== null && aTag1 !== undefined) {
                        if (aTag1.addEventListener) {
                            aTag1.addEventListener("click", () => { this.deleteS3File(1) });
                        }
                        else if (aTag1.attachEvent) {
                            aTag1.attachEvent("click", () => { this.deleteS3File(1) });
                        }
                    }

                    this.setState({ proof_of_ownership_uploaded: true });
                }
                catch (err) {
                    console.log("Error proof_of_ownership - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <i class="fas fa-asterisk asterisk"></i><div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_ownership_upload_id" /></td>`;
                    return 1;
                }
            }
            else if (files[i].key.includes("irs_letter_issuing_tin_ein/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ irs_letter_issuing_tin_ein: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <i class="fas fa-asterisk asterisk"></i><div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.irs_letter_issuing_tin_ein}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File2"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File2');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(2) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(2) });
                        }
                    }

                    this.setState({ irs_letter_issuing_tin_ein_uploaded: true });
                }
                catch (err) {
                    console.log("Error irs_letter_issuing_tin_ein - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <i class="fas fa-asterisk asterisk"></i><div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="irs_letter_issuing_tin_ein_upload_id" /></td>`;
                    return 2;
                }
            }
            else if (files[i].key.includes("/grant_deed/")) {
                try {
                    var file31 = await Storage.get(files[i].key);
                    this.setState({ grant_deed: file31 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.grant_deed}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File3"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag31 = document.getElementById('deleteS3File3');
                    if (aTag31 !== null && aTag31 !== undefined) {
                        if (aTag31.addEventListener) {
                            aTag31.addEventListener("click", () => { this.deleteS3File(3) });
                        }
                        else if (aTag31.attachEvent) {
                            aTag31.attachEvent("click", () => { this.deleteS3File(3) });
                        }
                    }

                    this.setState({ grant_deed_uploaded: true });
                }
                catch (err) {
                    console.log("Error grant_deed - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="grant_deed_upload_id" /></td>`;
                    return 3;
                }
            }
            else if (files[i].key.includes("llc/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ llc: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <i class="fas fa-asterisk asterisk"></i><div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.llc}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File4"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File4');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(4) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(4) });
                        }
                    }

                    this.setState({ llc_uploaded: true });
                }
                catch (err) {
                    console.log("Error llc - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <i class="fas fa-asterisk asterisk"></i><div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="llc_upload_id" /></td>`;
                    return 4;
                }
            }
            else if (files[i].key.includes("/management_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ management_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <i class="fas fa-asterisk asterisk"></i><div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.management_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File5"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File5');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(5) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(5) });
                        }
                    }

                    this.setState({ management_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error management_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <i class="fas fa-asterisk asterisk"></i><div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
                    return 5;
                }
            }
            else if (files[i].key.includes("hap_contract/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ hap_contract: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <i class="fas fa-asterisk asterisk"></i><div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.hap_contract}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File6"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File6');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(6) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(6) });
                        }
                    }

                    this.setState({ hap_contract_uploaded: true });
                }
                catch (err) {
                    console.log("Error hap_contract - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <i class="fas fa-asterisk asterisk"></i><div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="hap_contract_upload_id" /></td>`;
                    return 6;
                }
            }
            else if (files[i].key.includes("rent_to_owner_form/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ rent_to_owner_form: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <i class="fas fa-asterisk asterisk"></i><div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.rent_to_owner_form}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File7"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File7');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(7) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(7) });
                        }
                    }

                    this.setState({ rent_to_owner_form_uploaded: true });
                }
                catch (err) {
                    console.log("Error rent_to_owner_form - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <i class="fas fa-asterisk asterisk"></i><div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rent_to_owner_form_upload_id" /></td>`;
                    return 7;
                }
            }
            else if (files[i].key.includes("tax_id_verification/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ tax_id_verification: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <i class="fas fa-asterisk asterisk"></i><div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.tax_id_verification}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File8"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File8');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(8) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(8) });
                        }
                    }

                    this.setState({ tax_id_verification_uploaded: true });
                }
                catch (err) {
                    console.log("Error tax_id_verification - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <i class="fas fa-asterisk asterisk"></i><div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tax_id_verification_upload_id" /></td>`;
                    return 8;
                }
            }
            else if (files[i].key.includes("property_tax_bill/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ property_tax_bill: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.property_tax_bill}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File9"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File9');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(9) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(9) });
                        }
                    }

                    this.setState({ property_tax_bill_uploaded: true });
                }
                catch (err) {
                    console.log("Error property_tax_bill - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_tax_bill_upload_id" /></td>`;
                    return 9;
                }
            }
            else if (files[i].key.includes("property_management_agreement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ property_management_agreement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement </td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.property_management_agreement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File10"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File10');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(10) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(10) });
                        }
                    }

                    this.setState({ property_management_agreement_uploaded: true });
                }
                catch (err) {
                    console.log("Error property_management_agreement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_management_agreement_upload_id" /></td>`;
                    return 10;
                }
            }
            else if (files[i].key.includes("recorded_grant_deed/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ recorded_grant_deed: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.recorded_grant_deed}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File11"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File11');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(11) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(11) });
                        }
                    }

                    this.setState({ recorded_grant_deed_uploaded: true });
                }
                catch (err) {
                    console.log("Error recorded_grant_deed - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="recorded_grant_deed_upload_id" /></td>`;
                    return 11;
                }
            }
            else if (files[i].key.includes("remove_home_owner_exemption/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ remove_home_owner_exemption: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption <td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.remove_home_owner_exemption}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File12"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File12');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(12) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(12) });
                        }
                    }

                    this.setState({ remove_home_owner_exemption_uploaded: true });
                }
                catch (err) {
                    console.log("Error remove_home_owner_exemption - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption<td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="remove_home_owner_exemption_upload_id" /></td>`;
                    return 12;
                }
            }
            else if (files[i].key.includes("statements_from_property_coowners/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ statements_from_property_coowners: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.statements_from_property_coowners}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File13"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File13');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(13) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(13) });
                        }
                    }

                    this.setState({ statements_from_property_coowners_uploaded: true });
                }
                catch (err) {
                    console.log("Error statements_from_property_coowners - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="statements_from_property_coowners_upload_id" /></td>`;
                    return 13;
                }
            }
            else if (files[i].key.includes("original_voided_check/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ original_voided_check: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <i class="fas fa-asterisk asterisk"></i><div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.original_voided_check}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File14"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File14');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(14) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(14) });
                        }
                    }

                    this.setState({ original_voided_check_uploaded: true });
                }
                catch (err) {
                    console.log("Error original_voided_check - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <i class="fas fa-asterisk asterisk"></i><div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="original_voided_check_upload_id" /></td>`;
                    return 14;
                }
            }
            else if (files[i].key.includes("final_escrow_statement/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ final_escrow_statement: file2 });

                    var shortFileName = files[i].key.replace(/^.*[\\\/]/, '').split('.')[0];;
                    var fileExtension = files[i].key.replace(/^.*[\\\/]/, '').split('.').pop();
                    if (shortFileName.length > 35) {
                        shortFileName = shortFileName.substring(0, 35) + '...';
                    }
                    shortFileName = shortFileName + '.' + fileExtension;

                    var lastModified = `${files[i].lastModified}`;
                    if (lastModified !== null && lastModified !== undefined && lastModified.indexOf(" (") !== -1) {
                        lastModified = lastModified.substring(0, lastModified.indexOf(" ("));
                    }

                    document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td><span title='${files[i].key.replace(/^.*[\\\/]/, '')}'><i class='${this.getFileIconType(files[i].key)} mr-2 position-static'></i> ${shortFileName}</span></td><td>${lastModified}</td><td><a target="_blank" href=${this.state.final_escrow_statement}><i class="fas fa-eye mr-3"></i></a><a href="javascript:void(0)" id="deleteS3File15"><i class="fas fa-trash-alt red"></i></a></td>`;
                    var aTag2 = document.getElementById('deleteS3File15');
                    if (aTag2 !== null && aTag2 !== undefined) {
                        if (aTag2.addEventListener) {
                            aTag2.addEventListener("click", () => { this.deleteS3File(15) });
                        }
                        else if (aTag2.attachEvent) {
                            aTag2.attachEvent("click", () => { this.deleteS3File(15) });
                        }
                    }

                    this.setState({ final_escrow_statement_uploaded: true });
                }
                catch (err) {
                    console.log("Error final_escrow_statement - " + err);
                    this.setState({ isLoading: false });
                    document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="final_escrow_statement_upload_id" /></td>`;
                    return 15;
                }
            }
        }

        this.setState({ isLoading: false });

        return 0;
    }

    deleteS3File = async (fileToDelete) => {
        var result = window.confirm("Are you sure you want to delete selected document?");
        if (result === false) {
            return;
        }

        this.setState({ isLoading: true });
        try {

            var key = `Owner-${this.props.propertyInfo[0]["PropertyOwnerID"]}/Properties/Property-${this.state.PropertyID}/Documents/PHA/`;
            var files = await Storage.list(key);
            //console.log(files);

            // Remove the earlier files for the section for which the new files to be uploaded

            switch (fileToDelete) {
                case 1:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("proof_of_ownership/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('proof_of_ownership_id').innerHTML = `<td>Proof of Ownership <i class="fas fa-asterisk asterisk"></i><div id="proof_of_ownership_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_ownership_upload_id" /></td>`;
                        this.setState({ proof_of_ownership: "" });
                    }
                    break;
                case 2:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("irs_letter_issuing_tin_ein/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('irs_letter_issuing_tin_ein_id').innerHTML = `<td>IRS letter issuing TIN/EIN <i class="fas fa-asterisk asterisk"></i><div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="irs_letter_issuing_tin_ein_upload_id" /></td>`;
                        this.setState({ irs_letter_issuing_tin_ein: "" });
                    }
                    break;
                case 3:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("/grant_deed/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('grant_deed_id').innerHTML = `<td>Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="grant_deed_upload_id" /></td>`;
                        this.setState({ grant_deed: "" });
                    }
                    break;
                case 4:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("llc/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('llc_id').innerHTML = `<td>LLC or LP Operating Agreement <i class="fas fa-asterisk asterisk"></i><div id="llc_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="llc_upload_id" /></td>`;
                        this.setState({ llc: "" });
                    }
                    break;
                case 5:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("/management_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('management_agreement_id').innerHTML = `<td>Management Agreement <i class="fas fa-asterisk asterisk"></i><div id="management_agreement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>`;
                        this.setState({ management_agreement: "" });
                    }
                    break;
                case 6:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("hap_contract/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('hap_contract_id').innerHTML = `<td>HAP Contract <i class="fas fa-asterisk asterisk"></i><div id="hap_contract_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="hap_contract_upload_id" /></td>`;
                        this.setState({ hap_contract: "" });
                    }
                    break;
                case 7:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("rent_to_owner_form/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('rent_to_owner_form_id').innerHTML = `<td>Rent To Owner Form <i class="fas fa-asterisk asterisk"></i><div id="rent_to_owner_form_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="rent_to_owner_form_upload_id" /></td>`;
                        this.setState({ rent_to_owner_form: "" });
                    }
                    break;
                case 8:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("tax_id_verification/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('tax_id_verification_id').innerHTML = `<td>Tax ID Verification <i class="fas fa-asterisk asterisk"></i><div id="tax_id_verification_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="tax_id_verification_upload_id" /></td>`;
                        this.setState({ tax_id_verification: "" });
                    }
                    break;
                case 9:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("property_tax_bill/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('property_tax_bill_id').innerHTML = `<td>Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="property_tax_bill_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_tax_bill_upload_id" /></td>`;
                        this.setState({ property_tax_bill: "" });
                    }
                    break;
                case 10:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("property_management_agreement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('property_management_agreement_id').innerHTML = `<td>Property Management Agreement</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="property_management_agreement_upload_id" /></td>`;
                        this.setState({ property_management_agreement: "" });
                    }
                    break;
                case 11:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("recorded_grant_deed/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('recorded_grant_deed_id').innerHTML = `<td>Recorded Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="recorded_grant_deed_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="recorded_grant_deed_upload_id" /></td>`;
                        this.setState({ recorded_grant_deed: "" });
                    }
                    break;
                case 12:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("remove_home_owner_exemption/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('remove_home_owner_exemption_id').innerHTML = `<td>Remove Home-Owner Exemption<td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="remove_home_owner_exemption_upload_id" /></td>`;
                        this.setState({ remove_home_owner_exemption: "" });
                    }
                    break;
                case 13:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("statements_from_property_coowners/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('statements_from_property_coowners_id').innerHTML = `<td>Statements from property co-owners</td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="statements_from_property_coowners_upload_id" /></td>`;
                        this.setState({ statements_from_property_coowners: "" });
                    }
                    break;
                case 14:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("original_voided_check/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('original_voided_check_id').innerHTML = `<td>Original Voided Check <i class="fas fa-asterisk asterisk"></i><div id="original_voided_check_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="original_voided_check_upload_id" /></td>`;
                        this.setState({ original_voided_check: "" });
                    }
                    break;
                case 15:
                    {
                        for (var i = 0; i < files.length; i++) {
                            if (files[i].key.includes("final_escrow_statement/")) {
                                var key1 = files[i].key;
                                var res = await Storage.remove(key1);
                                //console.log(res);
                            }
                        }
                        document.getElementById('final_escrow_statement_id').innerHTML = `<td>Final Escrow statement; or Current Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="final_escrow_statement_validation_id" class="alert-small-text" style="display:none" >Document is required</div></td><td></td><td></td><td><input class="form-control-sm form-control-file pl-0" type="file" id="final_escrow_statement_upload_id" /></td>`;
                        this.setState({ final_escrow_statement: "" });
                    }
                    break;
                default:
                    break;
            }
        }
        catch (err) {
            console.log(err);
            this.setState({ msg: "Failed to delete file" });
            this.setState({ isLoading: false });
            return;
        }

        this.setState({ isLoading: false });

        var safeload = await this.loadUploadedDocuments();
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    render() {
        return (
            <div className="border p-3">
                <React.Fragment>
                    <input className="form-control" type="hidden" ></input>
                    <h2 className="profile-title d-inline-block"><i class="fas fa-file-alt mr-2"></i>Documents</h2><span className="file-allowed">Note: Only 1 file allowed to upload</span>
                    <div className="table-responsive">
                        <table class="table table-bordered table-cgreen mt-3">
                            <thead>
                                <tr>
                                    <th scope="col">Document</th>
                                    <th scope="col">File Name</th>
                                    <th scope="col">Uploaded Date Time</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr id="proof_of_ownership_id">
                                    <td>Proof of Ownership <i class="fas fa-asterisk asterisk"></i><div id="proof_of_ownership_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="proof_of_ownership_upload_id" /></td>
                                </tr>
                                <tr id="irs_letter_issuing_tin_ein_id">
                                    <td>IRS letter issuing TIN/EIN <i class="fas fa-asterisk asterisk"></i><div id="irs_letter_issuing_tin_ein_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="irs_letter_issuing_tin_ein_upload_id" /></td>
                                </tr>
                                <tr id="grant_deed_id">
                                    <td>Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="grant_deed_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="grant_deed_upload_id" /></td>
                                </tr>
                                <tr id="llc_id">
                                    <td>LLC or LP Operating Agreement <i class="fas fa-asterisk asterisk"></i><div id="llc_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="llc_upload_id" /></td>
                                </tr>
                                <tr id="management_agreement_id">
                                    <td>Management Agreement <i class="fas fa-asterisk asterisk"></i><div id="management_agreement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="management_agreement_upload_id" /></td>
                                </tr>
                                <tr id="hap_contract_id">
                                    <td>HAP Contract <i class="fas fa-asterisk asterisk"></i><div id="hap_contract_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="hap_contract_upload_id" /></td>
                                </tr>
                                <tr id="rent_to_owner_form_id">
                                    <td>Rent To Owner Form <i class="fas fa-asterisk asterisk"></i><div id="rent_to_owner_form_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="rent_to_owner_form_upload_id" /></td>
                                </tr>
                                <tr id="tax_id_verification_id">
                                    <td>Tax ID Verification <i class="fas fa-asterisk asterisk"></i><div id="tax_id_verification_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="tax_id_verification_upload_id" /></td>
                                </tr>
                                <tr id="property_tax_bill_id">
                                    <td>Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="property_tax_bill_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="property_tax_bill_upload_id" /></td>
                                </tr>
                                <tr id="property_management_agreement_id">
                                    <td>Property Management Agreement</td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="property_management_agreement_upload_id" /></td>
                                </tr>
                                <tr id="recorded_grant_deed_id">
                                    <td>Recorded Grant Deed <i class="fas fa-asterisk asterisk"></i><div id="recorded_grant_deed_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="recorded_grant_deed_upload_id" /></td>
                                </tr>
                                <tr id="remove_home_owner_exemption_id">
                                    <td>Remove Home-Owner Exemption</td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="remove_home_owner_exemption_upload_id" /></td>
                                </tr>
                                <tr id="statements_from_property_coowners_id">
                                    <td>Statements from property co-owners</td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="statements_from_property_coowners_upload_id" /></td>
                                </tr>
                                <tr id="original_voided_check_id">
                                    <td>Original Voided Check <i class="fas fa-asterisk asterisk"></i><div id="original_voided_check_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="original_voided_check_upload_id" /></td>
                                </tr>
                                <tr id="final_escrow_statement_id">
                                    <td>Final Escrow statement; or Current Property Tax Bill <i class="fas fa-asterisk asterisk"></i><div id="final_escrow_statement_validation_id" class="alert-small-text" style={{ display: 'none' }} >Document is required</div></td>
                                    <td></td>
                                    <td></td>
                                    <td><input class="form-control-sm form-control-file pl-0" type="file" id="final_escrow_statement_upload_id" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    {/* className={this.state.classes.backdrop} */}
                    <Backdrop open={this.state.isLoading} onClick={this.handleClose}>
                        <CircularProgress color="inherit" />
                    </Backdrop>

                    <Snackbar anchorOrigin={{ vertical: this.state.snackbarVertical, horizontal: this.state.snackbarHorizontal }}
                        open={this.state.openSnackbar}
                        autoHideDuration={3000}
                        severity={this.state.snackbarSeverity}
                        onClose={() => this.setState({ openSnackbar: false })}
                        message={this.state.snackbarMessage}
                        className={this.state.snackbarMessageClass}
                        key={this.state.snackbarVertical + this.state.snackbarHorizontal}
                    />

                </React.Fragment>
            </div>
        );
    }
}

export default PHARegistrationStep4;
