import React from "react";
import { CircularProgress } from '@material-ui/core';
import imgLogo from '../imgs/logo-assist-oc.png';
import imgSearchclaim from '../imgs/search-claims.svg';
import {
    Route,
    Router,
    NavLink,
    Switch
} from "react-router-dom";
import { API, Auth } from 'aws-amplify';
import LandingPage from './landingPage';
import AddUser from "./addUser";
import SearchClaims from "./searchClaims";
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import Tooltip from '@material-ui/core/Tooltip';
import CeppClaims from "./ceppClaims";
import IrvineClaims from "./irvineClaims";
import CeppClaimDetails from "./ceppClaimDetails";
import PermissionNotFound from './forbidden';
import IrvineClaimDetails from "./irvineClaimDetails";
import SantaAnaClaims from "./santaAnaClaims";
import EditSantaAnaUser from './santaAnaClaimDetail'
import SantaAnaReconciliation from "./santaAnaReconciliation";
import CeppClaimsReconciliation from "./ceppClaimsReconciliation";
import IrvineReconciliation from "./irvineReconciliation";
import SantaAnaApplicant from "./santaAnaApplicant";
import EditSantaAnaApplicant from "./santaAnaApplicantDetail";
import SantaAnaApplicantReconciliation from "./santaAnaApplicantReconciliation";
import SantaAnaApplicantTransaction from "./santaAnaApplicantTransaction";
import PageNotFound from "./pageNotFound";
import Backdrop from '@material-ui/core/Backdrop';


// import { createBrowserHistory } from "history";

let UserName = '';
let showBkBtn = 'block';

class AssistOCLayout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            showLandingPage: true,
            pagetitle: '',
            pageicon: '',
            pageimg: '',
            permissions: [],
            isLoading: true,
        }
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        try{
            this.getCurrentUserPermissions();
        }
        catch(error){
            console.log(error);
            if (error === "not authenticated") {
              await this.signOut();
            }
        };
        // this.setState({ isLoading: false });
    }

    getPermissions = async (e) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getuserpermissions';
        let myInit = {
            body: {
                "info": {
                    "groupName": e,
                }
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getCurrentUser = async () => {
        try {
          var user = await Auth.currentAuthenticatedUser();
          return user;
        }
        catch(e){
          this.setState({ isLoading: false });
          if(e === "not authenticated") {
            await this.signOut();
          }
         // return null;
        }
    }

    getCurrentUserPermissions= async ()=>{
        await this.getCurrentUser();
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
         if (cognitogroups.length === 1) {
           showBkBtn = "none";
         }
        var res = await this.getPermissions(cognitogroups);
        this.setState({ permissions: res.permissions , isLoading: false});
    }

    callbackFunction = (title, icon, img) => {
        if (title === "" && icon === "" && img === "") {
            this.setState({ showLandingPage: true });
        }
        else {
            this.setState({ pagetitle: title });
            this.setState({ pageicon: icon })
            this.setState({ pageimg: img })

            this.setState({ showLandingPage: false })
        }
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('permissions');
            window.location.assign("/");

            setTimeout(
                () => this.setState({ permissions: [] }),
                3000
            );
        }
        catch (e) {
            console.log(e)
        }
    }

    backMethod = function () {
        this.props.history.goBack();
    }

    render() {
        const {isLoading} = this.state;
        if(!isLoading){
            return (
                <div className="d-flex flex-column sticky-footer-wrapper assistoc-program">
                    <Helmet>
                        <title>AssistOC</title>
                    </Helmet>

                    {/* header starts */}
                    {this.state.showLandingPage ?
                        (
                            <header>
                                <nav className="navbar navbar-light navbar-inner py-3" id="headerBg">
                                    <a className="navbar-brand" href="#">
                                        <img src={imgLogo} height="38" className="logo" alt="Assist OC logo" loading="lazy" />
                                    </a>
                                    <div className="ml-auto">
                                        <button className="btn btn-link text-secondary" onClick={this.signOut}>Sign Out <i className="fas fa-power-off ml-1"></i></button>
                                    </div>
                                </nav>
                            </header>
                        ) :
                        (
                            <header>
                                <nav className="navbar shadow-sm fixed-top navbar-light navbar-inner bg-light py-1" id="headerBg">
                                    <a className="mr-2 navbar-brand" href="#">
                                        <img src={imgLogo} height="25" className="logo" alt="Assist OC logo" loading="lazy" />
                                    </a>
                                    <h3 className="font-weight-bold h5 text-uppercase text-primary border-left py-1 pl-4 d-none d-sm-inline-block ml-3 mb-0" style={{ whiteSpace: 'nowrap' }}>
                                        {this.state.pageimg && <img className="search-img mr-1" height="24" src={this.state.pageimg} alt="{this.state.pagetitle}" loading="lazy" />}
                                        {this.state.pageicon && <i className={this.state.pageicon}></i>}
                                        <span className="header-title">{this.state.pagetitle}</span>
                                    </h3>
                                    <div className="ml-auto">
                                        <Tooltip title="Back">
                                            <button className="btn btn-light mr-2  d-inline-block" onClick={() => this.backMethod()} type="button"><i className="fas fa-reply"></i> </button>
                                        </Tooltip>
                                        {/* <NavLink to="/"><button className="btn btn-light d-none d-sm-inline-block" style={{ display: showBkBtn }} type="button"><i className="fas fa-reply"></i></button></NavLink> */}
                                        <div className="btn-group uw-dropdown">
                                            <Tooltip title="Menu">
                                                <button type="button" className="btn btn-light d-inline-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i className="fas fa-bars"></i>
                                                </button>
                                            </Tooltip>
                                            {/* <button type="button" className="btn btn-light d-none d-sm-inline-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i className="fas fa-bars"></i>
                                            </button> */}
                                            <div className="dropdown-menu dropdown-menu-right">
                                                {/* {this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('HPPClaim') ?
                                                    <button className="dropdown-item" type="button"><span><i class="fas fa-user-plus"></i></span><NavLink to="/assistoc/registeruser">Register Users</NavLink></button>
                                                    : null
                                                }
                                                {this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('HPPClaim') ?
                                                    <button className="dropdown-item" type="button"><span><img src={imgSearchclaim} height="18" alt="Search Claim" loading="lazy" /></span><NavLink to="/assistoc/searchclaim">Search  Claim HPP</NavLink></button>
                                                    : null
                                                } */}
                                                {this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('CEPPClaim') || this.state.permissions.includes('CEPPClaimUser')) ?
                                                    <button className="dropdown-item" type="button"><span><img src={imgSearchclaim} height="18" alt="Search Claim" loading="lazy" /></span><NavLink to="/assistoc/ceppclaim">Search Claim ERA-OC</NavLink></button>
                                                    : null
                                                }
                                                {this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('IrvineClaim') || this.state.permissions.includes('IrvineClaimUser')) ?
                                                    <button className="dropdown-item" type="button"><span><img src={imgSearchclaim} height="18" alt="Search Claim" loading="lazy" /></span><NavLink to="/assistoc/irvineclaim">Search Claim IRVINE</NavLink></button>
                                                    : null
                                                }
                                                {this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ?
                                                    <button className="dropdown-item" type="button"><span><img src={imgSearchclaim} height="18" alt="Search Claim" loading="lazy" /></span><NavLink to="/assistoc/santaanaclaim">Search Claim Santa Ana</NavLink></button>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <Tooltip title="Signout">
                                            <button className="btn btn-link text-secondary pr-0 pl-1" onClick={this.signOut}><i className="fas fa-power-off ml-1"></i></button>
                                        </Tooltip>
                                    </div>
                                </nav>
                            </header>
                        )
                    }
                    {/* header ends */}

                    {/* main content starts */}
                    <main className="main flex-fill">
                        <div className="container-fluid">
                            {/* <Router history={history}> */}
                            <Switch>
                            <Route exact path="/assistoc" render={props => this.state.permissions && this.state.permissions.length > 0 ? <LandingPage {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
    
                            {/* <Route path="/assistoc/registeruser" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('HPPClaim') ? <AddUser {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route path="/assistoc/searchclaim" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('HPPClaim') ? <SearchClaims {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} /> */}
                            
                            <Route exact path="/assistoc/ceppclaim" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('CEPPClaim') || this.state.permissions.includes('CEPPClaimUser')) ? <CeppClaims {...props} permissions={this.state.permissions} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/ceppclaim/edit/:id" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('CEPPClaim') || this.state.permissions.includes('CEPPClaimUser')) ? <CeppClaimDetails  permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/irvineclaim" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('IrvineClaim') || this.state.permissions.includes('IrvineClaimUser')) ? <IrvineClaims permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/irvineclaim/edit/:id" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('IrvineClaim') || this.state.permissions.includes('IrvineClaimUser')) ? <IrvineClaimDetails permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanaclaim" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ? <SantaAnaApplicant permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanaclaim/edit/:id" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ? <EditSantaAnaApplicant permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanareconciliation" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaReconciliation')) ? <SantaAnaApplicantReconciliation permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanatransaction" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaTransaction')) ? <SantaAnaApplicantTransaction permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/ceppclaimsreconciliation" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('CEPPReconciliation')) ? <CeppClaimsReconciliation permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/irvinereconciliation" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('IrvineReconciliation')) ? <IrvineReconciliation permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                          
                            <Route exact path="/assistoc/santaanaclaimtest" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ? <SantaAnaClaims permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanaclaimtest/edit/:id" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaClaim') || this.state.permissions.includes('SantaAnaClaimUser')) ? <EditSantaAnaUser permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            <Route exact path="/assistoc/santaanaapplicantreconciliationtest" render={props => this.state.permissions && this.state.permissions.length > 0 && (this.state.permissions.includes('SantaAnaReconciliation')) ? <SantaAnaReconciliation permissions={this.state.permissions} {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                            
                            {/* keep your routes above this route */}
                            <Route path="**" component={PageNotFound} />
                            </Switch>
                        </div>
                    </main>
                    {/* main content ends */}

                    {/* footer starts */}
                    {this.state.showLandingPage === false &&
                        <footer className="foot bg-primary text-white text-center p-2">
                            <p className="mb-1">Powered by Orange County United Way</p>
                        </footer>
                    }
                    {/* footer ends */}

                </div >
            )
        }
        else{
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }

    }
}

export default AssistOCLayout;