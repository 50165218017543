import { Button, CircularProgress } from '@material-ui/core';
import React from "react";
import MUIDataTable from "mui-datatables";
import { API, Auth, Storage } from 'aws-amplify';
import { isMobile } from 'react-device-detect';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import CustomToolbar from "../customToolbar";
import Tooltip from '@material-ui/core/Tooltip';
import * as cloneDeep from 'lodash/cloneDeep';
import * as find from 'lodash/find';
import imgLogo from '../imgs/logo-assist-oc.png';
import {
  FormGroup,
  FormLabel,
    FormControl,
    ListItemText,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
    Select,
    InputLabel,
    MenuItem
} from '@material-ui/core';
import TableViewCol from "../TableViewCol";
import TableSearch from "../TableSearch";
import AddUser from './addUser';
import CeppClaims from './ceppClaims';
import imgSearchclaim from '../imgs/search-claims.svg';
import Backdrop from '@material-ui/core/Backdrop';

const headers = [
    "",    // 1st column header
    "firstName",
    "lastName",
    "partnerFirstName",
    "partnerLastName",
    "addressLine1",
    "addressLine2",
    "city",
    "state",
    "country",
    "zipcode",
    "clientDOB",
    "email",
    "phone",
    "legallyAbleToWork",
    "eligibleForStimulusFunds",
    "veteran",
    "monthlyIncomeBeforeCovid19",
    "workIndustry",
    "employmentStatus",
    "effectOfCovid19Financially",
    "householdNumber",
    "age0to5",
    "age6to17",
    "age18to59",
    "age60andUp",
    "cardValue",
    "participateInSurvey",
    "claimStatus",
    "dateUserCreated",
    "timeUserCreated",
    "dateClaimFiled",
    "timeClaimFiled",
    "dateClaimModified",
    "timeClaimModified",
    "notes",
    "adminUsername",
    "originator"
];

let UserName = '';
let showBkBtn = 'block';

class SearchClaims extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
      showCeppClaims: "none",
      showIrvineClaims: "none",
      showAddUser: "none",
      showSubmitClaim: "none",
      showLogin: "none",
            columns: [
                {
                    name: "loadClaim",  // 1st column
                    label: "Action",  // 1st column
                    options: {
                        download: false,
                        display: true,
                        filter: false,
                        customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                                <Tooltip title={'Load Claim'} arrow placement="left">
                                    <div style={{
                                        width: "15px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        //textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        <a href="#" onClick={this.testLoad}><i class="fas fa-file-invoice-dollar text-primary"></i></a>
                                    </div>
                                </Tooltip>
                            );
                        },
                    },
                },
                {
                    name: "firstName",
                    label: "First",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "lastName",
                    label: "Last",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "partnerFirstName",
                    label: "Partner First",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "partnerLastName",
                    label: "Partner Last",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "addressLine1",
                    label: "Address1",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "addressLine2",
                    label: "Address2",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "city",
                    label: "City",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "state",
                    label: "State",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['AL'], ['AK'], ['AR'], ['AZ'], ['CA'], ['CO'], ['CT'], ['DC'], ['DE'], ['FL'], ['GA'], ['HI'], ['IA'], ['ID'], ['IL'], ['IN'], ['KS'], ['KY'], ['LA'], ['MA'], ['MD'], ['ME'], ['MI'], ['MN'], ['MO'], ['MS'], ['MT'], ['NC'], ['NE'], ['NH'], ['NJ'], ['NM'], ['NV'], ['NY'], ['ND'], ['OH'], ['OK'], ['OR'], ['PA'], ['RI'], ['SC'], ['SD'], ['TN'], ['TX'], ['UT'], ['VT'], ['VA'], ['WA'], ['WI'], ['WV'], ['WY']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            State
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "country",
                    label: "Country",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "zipcode",
                    label: "Zip",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "50px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "50px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "clientDOB",
                    label: "Client DOB",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "email",
                    label: "Email",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "250px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "250px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "phone",
                    label: "Phone",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value) {
                                if (value.length > 15) {
                                    return (
                                        <Tooltip title={value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')} arrow placement="left">
                                            <div style={{
                                                width: "120px",
                                                overflow: "hidden",
                                                whiteSpace: 'nowrap',
                                                textOverflow: 'ellipsis',
                                                position: 'relative',
                                            }}>
                                                <span>{value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')}</span>
                                            </div>
                                        </Tooltip>
                                    );
                                }
                                else {
                                    return (
                                        <div style={{
                                            width: "120px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            <span>{value.replace('+1', '').replace(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/, '($1) $2-$3')}</span>
                                        </div>
                                    );
                                }
                            }
                            else
                                return (
                                    <div style={{
                                        width: "120px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "legallyAbleToWork",
                    label: "Are you eligible to legally work in the US?",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['no'], ['yes']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Eligible to legally work in the US?
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "eligibleForStimulusFunds",
                    label: "Eligible federal stimulus",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['no'], ['yes']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Eligible federal stimulus
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "veteran",
                    label: "US Veteran",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['no'], ['yes']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            US Veteran
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "monthlyIncomeBeforeCovid19",
                    label: "Monthly Household Income before COVID-19",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "workIndustry",
                    label: "Work Industry",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "employmentStatus",
                    label: "Employment/Income Status",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['employed'], ['unemployed']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Employment/Income Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "220px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "220px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "effectOfCovid19Financially",
                    label: "Effect of COVID-19 Financially",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "householdNumber",
                    label: "Household Number",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "age0to5",
                    label: "Ages 0-5",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "age6to17",
                    label: "Ages 6-17",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "age18to59",
                    label: "Ages 18-59",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "age60andUp",
                    label: "Ages 60+",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "100px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "100px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "cardValue",
                    label: "Debit Card Value",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "participateInSurvey",
                    label: "Participate in Survey",
                    options: {
                        download: true,
                        display: false,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['no'], ['yes']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Participate in Survey
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "claimStatus",
                    label: "Claim Status",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'custom',
                        filterOptions: {
                            display: (filterList, onChange, index, column) => {
                                const optionValues = [['Approved'], ['Denied'], ['Field'], ['In Final Review'], ['In-Review'], [''], ['Processing']];
                                return (
                                    <FormControl>
                                        <InputLabel>
                                            Claim Status
                                        </InputLabel>
                                        <Select
                                            value={filterList[index]}
                                            renderValue={selected => selected}
                                            onChange={event => {
                                                filterList[index] = event.target.value;
                                                onChange(filterList[index], index, column);
                                            }}
                                        >
                                            {optionValues.map(item => (
                                                <MenuItem key={item} value={item}>
                                                    <ListItemText primary={item} />
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                );
                            }
                        },
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "120px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "120px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "dateUserCreated",
                    label: "Created Date",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "timeUserCreated",
                    label: "Created Time",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "dateClaimFiled",
                    label: "Date Claim Filed",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "timeClaimFiled",
                    label: "Time Claim Filed",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "150px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "150px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "dateClaimModified",
                    label: "Date Claim Modified",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "timeClaimModified",
                    label: "Time Claim Modified",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "180px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "180px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "notes",
                    label: "Notes",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "250px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "250px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "adminUsername",
                    label: "Admin Username",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
                {
                    name: "originator",
                    label: "Originator",
                    options: {
                        download: true,
                        display: true,
                        filterType: 'textField',
                        customBodyRender: (value, tableMeta, updateValue) => {
                            if (value && value.length > 15) {
                                return (
                                    <Tooltip title={value} arrow placement="left">
                                        <div style={{
                                            width: "200px",
                                            overflow: "hidden",
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            position: 'relative',
                                        }}>
                                            {value}
                                        </div>
                                    </Tooltip>
                                );
                            }
                            else
                                return (
                                    <div style={{
                                        width: "200px",
                                        overflow: "hidden",
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        position: 'relative',
                                    }}>
                                        {value}
                                    </div>
                                );
                        },
                    },
                },
            ],
            hideCompleted: 1,
            msg: "",
            email: null,
            showDetails: "none",
            showAlert: "none",
            dataheight: null,
            notes: "",
            files: null,
            file1: null,
            file2: null,
            cardValue: "500.00",
            file31: null,
            file32: null,
            file41: null,
            file42: null,
            rowSelected: [],
            data: [["Loading Data..."]],

            isLoading: false,
            page: 0,
            count: 1,
            rowsPerPage: 50,
            sortOrder: {
                name: 'dateUserCreated',                  // default sort by column dateUserCreated in desc order
                direction: 'desc'
            },
            filters: {},
            searchText: '',
            searchTimerID: 0,
            handleSearch: 0,
            downloadFile: 0,
            selectedRowFirstName: '',
            selectedRowLastName: '',
            options: {
                download: false,
                downloadOptions: {
                    filename: 'tableDownload.csv',
                    separator: ',',
                    filterOptions: {
                        useDisplayedColumnsOnly: true,          // downloads only columns that are visible (e.g. after filtering)
                        useDisplayedRowsOnly: false              // downloads only rows that are visible (e.g. after filtering)
                    }                                         // default false - downloads all data
                },
                selectableRows: false,
                selectableRowsOnClick: false,
                filter: true,
                sort: true,
                responsive: 'standard', //'scroll', //'scrollMaxHeight',
                serverSide: true,

                // makes it so filters have to be "confirmed" before being applied to the 
                // table's internal filterList
                confirmFilters: true,

                // Calling the applyNewFilters parameter applies the selected filters to the table 
                customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
                    //console.log('customFilterDialogFooter');
                    return (
                        <div style={{ marginTop: '40px' }}>
                            <Button variant="contained" onClick={() => this.handleFilterSubmit(applyNewFilters)}>Apply Filters</Button>
                        </div>
                    );
                },

                // callback that gets executed when filters are confirmed
                onFilterConfirm: (filterList) => {
                    //console.log('onFilterConfirm');
                    console.dir("filterList: " + filterList);
                },

                onFilterDialogOpen: () => {
                    //console.log('filter dialog opened');
                },
                onFilterDialogClose: () => {
                    //console.log('filter dialog closed');
                },
                onFilterChange: (column, filterList, type) => {
                    //console.log('onFilterChange');
                    //console.log("column: " + column);
                    //console.log("filterList: " + filterList);
                    //console.log("type: " + type);
                    this.setState({ filters: filterList });
                    if (type === 'chip') {
                        var newFilters = () => (filterList);
                        //console.log('updating filters via chip');
                        this.handleFilterSubmit(newFilters);
                    }
                },

                pagination: true,
                count: 1,
                rowsPerPage: 50,
        rowsPerPageOptions: [10, 20, 50],
                sortOrder: {
                    name: 'dateUserCreated',                  // default sort by column dateUserCreated in desc order
                    direction: 'desc'
                },

                onTableChange: (action, tableState) => {

                    //console.log(action, tableState);

                    // a developer could react to change on an action basis or
                    // examine the state as a whole and do whatever they want

                    switch (action) {
                        case 'changePage':
                            this.changePage(tableState.page, tableState.sortOrder);
                            break;
                        case 'sort':
                            this.sort(tableState.page, tableState.sortOrder);
                            break;
                        case 'changeRowsPerPage':
                            this.changeRowsPerPage(tableState.page, tableState.sortOrder, tableState.rowsPerPage);
                            break;
                        case 'search':
                            this.OnSearchTextChange(tableState.searchText);
                            break;
                        default:
                        //console.log('action not handled - ' + action);
                    }
                },
                onViewColumnsChange: (column, action) => {
                    //console.log(column, action);
                    if (column !== null) {
                        this.state.columns.filter(c => c.name === column)[0].options.display = (action === 'add') ? true : false;
                    }
                },
                onRowClick: (rowData, rowState) => {
                    //console.log(rowData, rowState);
                    //console.log(rowData[11]);

                    this.closeDetails();
                    // email
                    if (rowData[12] !== null && rowData[12].props !== undefined) {
                        if (rowData[12].props["title"] !== undefined) {
                            this.setState({
                                email: rowData[12].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                email: rowData[12].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            email: rowData[12]
                        });
                    }

                    // notes
                    if (rowData[35] !== null && rowData[35].props !== undefined) {
                        if (rowData[35].props["title"] !== undefined) {
                            this.setState({
                                notes: rowData[35].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                notes: rowData[35].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            notes: rowData[35]
                        });
                    }

                    document.getElementById("notesTextArea").value = this.state.notes;

                    // selectedRowFirstName
                    if (rowData[1] !== null && rowData[1].props !== undefined) {
                        if (rowData[1].props["title"] !== undefined) {
                            this.setState({
                                selectedRowFirstName: rowData[1].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                selectedRowFirstName: rowData[1].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            selectedRowFirstName: rowData[1]
                        });
                    }

                    // selectedRowLastName
                    if (rowData[2] !== null && rowData[2].props !== undefined) {
                        if (rowData[2].props["title"] !== undefined) {
                            this.setState({
                                selectedRowLastName: rowData[2].props["title"]
                            });
                        }
                        else {
                            this.setState({
                                selectedRowLastName: rowData[2].props["children"]
                            });
                        }
                    }
                    else {
                        this.setState({
                            selectedRowLastName: rowData[2]
                        });
                    }

                    document.body.classList.add('modal-open');

                },
                fixedHeader: true,
                fixedSelectColumn: true,
                tableBodyHeight: 'calc(100vh - 242px)',
                print: false,
                customToolbar: () => {
                    return (
                        <CustomToolbar refreshCallback={this.OnRefreshClick.bind(this)} downloadCallback={this.OnDownloadClick.bind(this)} hideCompletedCallback={this.OnHideCompletedClick.bind(this)} />
                    );
                },
                searchText: '',
                // searchProps: {
                //   onBlur: (e) => {
                //     //console.log('onBlur!');
                //     this.OnSearchIdleTimeout(this.state.searchText);
                //   },
                //   onKeyUp: (e) => {
                //     //console.log('onKeyUp!');
                //     if (e.keyCode === 13) { // Enter pressed
                //       this.OnSearchIdleTimeout(this.state.searchText);
                //     }
                //   },
                // },
                searchPlaceholder: 'Search Text',
                onSearchClose: () => {
                    //console.log('onSearchClose - ' + this.state.searchTimerID);
                    clearTimeout(this.state.searchTimerID);

                    this.setState({
                        isLoading: false,
                        handleSearch: 0,
                        searchText: '',
                    });

                    this.OnRefreshClick();
                },
                customSearchRender: (searchText, handleSearch, hideSearch, options) => {
                    return (
                        <TableSearch
                            searchText={searchText}
                            onSearch={handleSearch}
                            onHide={hideSearch}
                            options={options}
                            onSearchClick={this.OnSearchClick}
                        />
                    );
                },
            }
        }
    }

    getMuiTheme = () => createMuiTheme({
        overrides: {
            MUIDataTableToolbar: {
                actions: {
                    display: "flex",
                    flex: "initial",
                    alignItems: "center",
                    // move all icons to the right
                    "& > span, & > button": {
                        order: 99
                    },
                    // target the custom toolbar icon
                    "& > span:last-child, & > button:last-child": {
                        order: 1
                    },
                    // // target any icon
                    // "& > span:nth-child(4), & > button:nth-child(4)": {
                    //   order: 2
                    // }
                }
            },
            MuiIconButton: {
                root: {
                    zIndex: "10",
                }
            },
            MuiTooltip: {
                tooltip: {
                    fontSize: "0.85em",
                }
            },
            MuiTableCell: {
                head: {
                    //width:'190px',
                }
            },
            MUIDataTableHeadCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    fontWeight: "bold",
                }
            },
            MUIDataTableBodyCell: {
                root: {
                    // overflow: "hidden",
                    // whiteSpace: 'nowrap',
                    // textOverflow: 'ellipsis',
                    // position: 'relative',
                    //width:'200px'
                }
            },
            MUIDataTableBody: {
                root: {
                }
            },
            MUIDataTableColumn: {
                row: {
                }
            },
            MUIDataTablePagination: {
                root: {
                },
                caption: {
                }
            }
        }
    })

    getData = async () => {
        this.setState({ isLoading: true });
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getallrows';

        var filterdict = {}; // create an object

        for (var ii = 0; ii < headers.length; ii++) {
            if (this.state.filters[ii] && this.state.filters[ii].length > 0) {
                filterdict[`${headers[ii]}`] = `${this.state.filters[ii]}`;
            }
        }

        //console.log("JSON filterdict: " + JSON.stringify(filterdict));

        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "page": this.state.page,
                    "sortField": this.state.sortOrder.name,
                    "sortDir": this.state.sortOrder.direction,
                    "rowsPerPage": this.state.rowsPerPage,
                    "downloadFile": this.state.downloadFile,
                    "filters": JSON.stringify(filterdict),
                    "handleSearch": this.state.handleSearch,
                    "searchText": this.state.searchText,
                    "hideCompleted": this.state.hideCompleted
                }
            }, // replace this with attributes you need
            headers: {
                Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
            }
        }
        return await API.post(apiName, path, myInit);
    }
    postData = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getclaim';
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "adminUsername": user.attributes.email
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    componentDidMount = async () => {
        this.props.parentCallback("Search Claim HPP", "", imgSearchclaim);
        var user = await Auth.currentAuthenticatedUser();
        UserName = user.attributes.name;
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups.length === 1) {
            showBkBtn = "none";
        }
        var height;
        if (isMobile) {
            height = "60vh";
        }
        else {
            height = "70vh"
        }
        var res = await this.getData();
        //console.log(res);
        this.setState({ data: res.data, isLoading: false, page: res.page, count: res.total });
        //this.setState({
        //  data:res,
        //  dataheight:height
        //})
    }

    sort = (page, sortOrder) => {
        this.setState({ isLoading: true, sortOrder: sortOrder });
        this.getData().then(res => {
            this.setState({
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
                isLoading: false,
            });
        });
    }

    changePage = (page, sortOrder) => {
        this.setState({
            isLoading: true,
            page: page,
            sortOrder: sortOrder,
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
                sortOrder: sortOrder,
            });
        });
    }

    changeRowsPerPage = (page, sortOrder, rowsPerPage) => {
        this.setState({
            isLoading: true,
            page: 0,
            rowsPerPage: rowsPerPage,
            sortOrder: sortOrder
        });
        this.getData().then(res => {
            this.setState({
                isLoading: false,
                sortOrder: sortOrder,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    }

    gets3Files = async () => {

        //reset load claim dialog contents
        document.getElementById('PhotoID').innerHTML = "";
        document.getElementById('AddressProof').innerHTML = "";
        document.getElementById('IncomeProof1').innerHTML = "";
        document.getElementById('IncomeProof2').style.display = "none";
        document.getElementById('IncomeLossProof1').innerHTML = "";
        document.getElementById('IncomeLossProof2').style.display = "none";

        Storage.configure({
            bucket: process.env.REACT_APP_S3FrontendBucket,
            region: process.env.REACT_APP_Region,
            level: 'public',
        });

        //get file names for s3 keys
        try {
            var key = `HPP/${this.state.email}/`;
            var files = await Storage.list(key);
            //console.log("Files - ");
            //console.log(files);
            this.setState({ files: files });
        }
        catch (err) {
            console.log("Error Files - " + err);
            return 1;
        }

        var loadedIncomeProof1 = false;
        var loadedIncomeLossProof1 = false;

        for (var i = 0; i < files.length; i++) {
            if (files[i].key.includes("photoID/")) {
                try {
                    var file1 = await Storage.get(files[i].key);
                    this.setState({ file1: file1 });
                    document.getElementById('PhotoID').innerHTML = `<a target="_blank" href=${this.state.file1} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                }
                catch (err) {
                    console.log("Error photoID - " + err);
                    return 1;
                }
            }
            else if (files[i].key.includes("proof_of_address/")) {
                try {
                    var file2 = await Storage.get(files[i].key);
                    this.setState({ file2: file2 });
                    document.getElementById('AddressProof').innerHTML = `<a target="_blank" href=${this.state.file2} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                }
                catch (err) {
                    console.log("Error proof_of_address - " + err);
                    return 2;
                }
            }
            else if (files[i].key.includes("proof_of_income/")) {
                try {
                    if (!loadedIncomeProof1) {
                        var file31 = await Storage.get(files[i].key);
                        this.setState({ file31: file31 });
                        document.getElementById('IncomeProof1').innerHTML = `<a target="_blank" href=${this.state.file31} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                        loadedIncomeProof1 = true;
                    }
                    else {
                        var file32 = await Storage.get(files[i].key);
                        this.setState({ file32: file32 });
                        document.getElementById('IncomeProof2').style.display = "block";
                        document.getElementById('IncomeProof2').innerHTML = `<a target="_blank" href=${this.state.file32} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                    }
                }
                catch (err) {
                    console.log("Error proof_of_address - " + err);
                    return 3;
                }
            }
            else if (files[i].key.includes("proof_of_income_loss/")) {
                try {
                    if (!loadedIncomeLossProof1) {
                        var file41 = await Storage.get(files[i].key);
                        this.setState({ file41: file41 });
                        document.getElementById('IncomeLossProof1').innerHTML = `<a target="_blank" href=${this.state.file41} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                        loadedIncomeLossProof1 = true;
                    }
                    else {
                        var file42 = await Storage.get(files[i].key);
                        this.setState({ file42: file42 });
                        document.getElementById('IncomeLossProof2').style.display = "block";
                        document.getElementById('IncomeLossProof2').innerHTML = `<a target="_blank" href=${this.state.file42} class="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3"><span class="text-primary"><i class='${this.getFileIconType(files[i].key)} mr-2'></i> ${files[i].key.replace(/^.*[\\\/]/, '')}</span><i class="fas fa-eye text-primary"></i></a>`;
                    }
                }
                catch (err) {
                    console.log("Error proof_of_income_loss - " + err);
                    return 4;
                }
            }
        }

        return 0;
    }

    testLoad = async () => {
        document.body.classList.add('modal-open');
        try {
            this.setState({ isLoading: true, });  // reset notes  notes: "" 

            var res = await this.postData();
            //console.log(res);
            //console.log("Notes: ",res[0][34]);
            if (res[0][34] === null || res[0][34] === "undefined") {
                this.setState({ notes: "" });
            }
            else {
                this.setState({
                    notes: res[0][34]        // notes
                });
            }

            document.getElementById("notesTextArea").value = this.state.notes;

            this.setState({
                cardValue: res[0][25]      // cardValue
            });
            this.result.value = res[0][27];   // claimStatus
        }
        catch (err) {
            console.log(err);
            this.setState({
                msg: "ERROR: Invalid email.",
                showAlert: "block",
            });
            return;
        }
        var safeload = await this.gets3Files();
        if (safeload === 1) {
            this.setState({
                msg: "Warning: Missing Proof of ID.",
                showAlert: "block",
                file1: null,
                file2: null,
                file31: null,
                file32: null,
                file41: null,
                file42: null
            });
        }
        if (safeload === 2) {
            this.setState({
                msg: "Warning: Missing Proof of Address.",
                showAlert: "block",
                file1: null,
                file2: null,
                file31: null,
                file32: null,
                file41: null,
                file42: null
            });
        }
        if (safeload === 3) {
            this.setState({
                msg: "Warning: Missing Proof of Income.",
                showAlert: "block",
                file1: null,
                file2: null,
                file31: null,
                file32: null,
                file41: null,
                file42: null
            });
        }
        if (safeload === 4) {
            this.setState({
                msg: "Warning: Missing Proof of Income Loss.",
                showAlert: "block",
                file1: null,
                file2: null,
                file31: null,
                file32: null,
                file41: null,
                file42: null
            });
        }
        if (isMobile) {
            this.setState({ showDetails: "block", dataheight: "35vh" });
        }
        else {
            this.setState({ showDetails: "block" }); //,dataheight:"50vh"
        }
        this.setState({ isLoading: false });
    }

    updateEmail = (evt) => {
        var name = evt.target.value;
        this.setState({ email: name });
    }

    updateCardValue = (evt) => {
        var name = evt.target.value;
        this.setState({ cardValue: name });
    }

    close = () => {
        //this.props.updateFunction(2);
        this.setState({ email: "" });
        this.closeDetails();
        window.location.assign("/");
    }

    closeDetails = () => {
        document.body.classList.remove('modal-open');
        if (isMobile) {
            this.setState({ showDetails: "none", dataheight: "60vh", email: "", selectedRowFirstName: "", selectedRowLastName: "" });
        }
        else {
            this.setState({ showDetails: "none", email: "", selectedRowFirstName: "", selectedRowLastName: "" }); //,dataheight:"70vh"
        }
    }

    closeMsg = () => {
        this.setState({ showAlert: "none" });
    }

    postData2 = async () => {
        var user = await Auth.currentAuthenticatedUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/modifyclaim';
        let claimStatus = this.result.value;
        if (this.result.value === '' || this.result.value === null
            || this.result.value === "undefined" || this.result.value === undefined) {
            claimStatus = 'Processing';
        }
        let myInit = { // OPTIONAL
            body: {
                "info": {
                    "email": this.state.email,
                    "status": claimStatus,
                    "notes": this.state.notes,
                    "cardValue": this.state.cardValue
                }
            }, // replace this with attributes you need
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    submitResult = async () => {
        this.setState({
            isLoading: true,
        });

        this.setState({ notes: document.getElementById("notesTextArea").value });

        try {
            var res = await this.postData2();
            //console.log(res);
        }
        catch (err) {
            console.log(err);
            this.setState({
                msg: "ERROR: Could not update.",
                showAlert: "block",
            });
            return;
        }
        this.closeDetails();
        this.setState({
            msg: "Update Complete",
            showAlert: "block",
            notes: ""
        });
        document.getElementById("notesTextArea").value = "";

        await this.refresh();
    }

    OnSearchClick = async () => {
        //console.log("OnSearchClick!!! - " + this.state.options.searchText);
        //console.log("OnSearchClick!!! - " + this.state.searchText);
        this.OnSearchIdleTimeout(this.state.searchText)
    }

    refresh = async () => {
        //console.log("Refresh!!!");
        var res = await this.getData();
        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        })
    }

    OnRefreshClick = async () => {
        //console.log("OnRefreshClick!!!");
        await this.refresh();
    }

    componentDidUpdate() {
    }

    handleFilterSubmit = applyFilters => {
        let filterList = applyFilters();
        //console.log('handleFilterSubmit');
        //console.log('filterList: ' + filterList);
        this.setState({ isLoading: true, filters: filterList });

        this.getData().then(res => {
            this.setState({
                isLoading: false,
                data: res.data,
                page: res.page,
                count: res.total,
            });
        });
    };

    OnSearchTextChange(searchQuery) {
        //console.log('OnSearchTextChange - ' + searchQuery);

        this.setState({ searchText: searchQuery });

        // if (searchQuery !== null && searchQuery !== 'undefined') {
        //   clearTimeout(this.state.searchTimerID);
        //   this.setState({ searchTimerID: setTimeout(this.OnSearchIdleTimeout, 2000, searchQuery) });
        // }
        // else {
        //   this.setState({ searchText: '' });
        // }
    }

    OnSearchIdleTimeout = async (searchQuery) => {
        if (searchQuery !== null && searchQuery !== 'undefined') {
            //console.log('OnSearchIdleTimeout - ' + searchQuery);

            this.setState({ searchText: searchQuery });

            this.setState({
                isLoading: true,
                handleSearch: 1,
                searchText: searchQuery,
            });
            this.state.options.searchText = searchQuery;

            var res = await this.getData();

            //console.log('SearchResult');
            //console.log(res.data);

            this.setState({
                isLoading: false,
                //handleSearch: 0,
                //searchText: '',
                data: res.data,
                page: res.page,
                count: res.total,
            });
        }
    }

    escapeDangerousCSVCharacters(data) {
        if (typeof data === 'string') {
            // Places single quote before the appearance of dangerous characters if they
            // are the first in the data string.
            return data.replace(/^\+|^\-|^\=|^\@/g, "'$&");
        }

        return data;
    }

    buildCSV(columns, data, options) {
        const replaceDoubleQuoteInString = columnData =>
            typeof columnData === 'string' ? columnData.replace(/\"/g, '""') : columnData;

        const buildHead = bColumns => {
            return (
                bColumns
                    .reduce(
                        (soFar, column) =>
                            column.options.download
                                ? soFar +
                                '"' +
                                this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(column.label || column.name)) +
                                '"' +
                                options.downloadOptions.separator
                                : soFar,
                        '',
                    )
                    .slice(0, -1) + '\r\n'
            );
        };
        const CSVHead = buildHead(columns);

        //console.log('buildCSV');
        //console.log(columns);

        const buildBody = bData => {
            if (!bData.length) return '';
            return bData
                .reduce(
                    (soFar, row) =>
                        soFar +
                        '"' +
                        row.data
                            .filter((_, index) => columns[index].options.download)
                            .map(columnData => this.escapeDangerousCSVCharacters(replaceDoubleQuoteInString(columnData)))
                            .join('"' + options.downloadOptions.separator + '"') +
                        '"\r\n',
                    '',
                )
                .trim();
        };
        const CSVBody = buildBody(data);

        // const csv = options.onDownload
        //   ? options.onDownload(buildHead, buildBody, columns, data)
        //   : `${CSVHead}${CSVBody}`.trim();

        const csv = `${CSVHead}${CSVBody}`.trim();

        //console.log('csv');
        //console.log(csv);
        return csv;
    }

    downloadCSV(csv, filename) {
        const blob = new Blob([csv], { type: 'text/csv' });

        /* taken from react-csv */
        if (navigator && navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const dataURI = `data:text/csv;charset=utf-8,${csv}`;

            const URL = window.URL || window.webkitURL;
            const downloadURI = typeof URL.createObjectURL === 'undefined' ? dataURI : URL.createObjectURL(blob);

            let link = document.createElement('a');
            link.setAttribute('href', downloadURI);
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    createCSVDownload(columns, data, options) {
        const csv = this.buildCSV(columns, data, options);

        // if (options.onDownload && csv === false) {
        //   return;
        // }

        this.downloadCSV(csv, options.downloadOptions.filename);
    }

    OnHideCompletedClick = async () => {

        let newState = 1;
        if (this.state.hideCompleted === 1) {
            newState = 0;
        }
        else {
            newState = 1;
        }

        this.setState({ hideCompleted: newState });

        this.setState({
            isLoading: true,
        });

        var res = await this.getData();

        this.setState({
            isLoading: false,
            data: res.data,
            page: res.page,
            count: res.total,
        });
    }

    OnDownloadClick = async () => {
        //console.log("OnDownloadClick!!! - " + this.state.page);

        const { data, columns, options } = this.state;
        let displayData = this.state.data;


        this.setState({
            isLoading: true,
            downloadFile: 1,
        });

        var res = await this.getData();
        this.setState({
            isLoading: false,
            downloadFile: 0,
        });

        let dataDictionary = [];
        res.data.forEach((el, index) => dataDictionary.push({ index: index, data: el }));

        let dataToDownload = cloneDeep(dataDictionary);
        let columnsToDownload = columns;

        if (options.downloadOptions && options.downloadOptions.filterOptions) {
            // check rows first:
            if (options.downloadOptions.filterOptions.useDisplayedRowsOnly) {
                dataToDownload = displayData.map((row, index) => {
                    let i = -1;

                    // Help to preserve sort order in custom render columns
                    row.index = index;

                    return {
                        data: row.data.map(column => {
                            i += 1;

                            // if we have a custom render, which will appear as a react element, we must grab the actual value from data
                            // that matches the dataIndex and column
                            // TODO: Create a utility function for checking whether or not something is a react object
                            return typeof column === 'object' && column !== null && !Array.isArray(column)
                                ? find(data, d => d.index === row.dataIndex).data[i]
                                : column;
                        }),
                    };
                });
            }

            // now, check columns:
            if (options.downloadOptions.filterOptions.useDisplayedColumnsOnly) {
                columnsToDownload = columns.filter((_, index) => _.options.display === true);

                dataToDownload = dataToDownload.map(row => {
                    row.data = row.data.filter((_, index) => columns[index].options.display === true);
                    return row;
                });
            }
        }
        this.createCSVDownload(columnsToDownload, dataToDownload, options);
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    getFileIconType(filename) {
        let extension = /(?:\.([^.]+))?$/.exec(filename)[1];

        switch (extension) {
            case 'pdf':
                return 'fas fa-file-pdf';
            case 'jpeg':
            case 'jpg':
            case 'png':
            case 'gif':
            case 'bmp':
                return 'fas fa-file-image';
            case 'txt':
                return 'fas fa-file-alt';
            case 'doc':
            case 'docx':
                return 'fas fa-file-word';
            case 'xls':
            case 'xlsx':
                return 'fas fa-file-excel';
            case 'ppt':
            case 'pptx':
                return 'fas fa-file-powerpoint';
            default: // any other file
                return 'fas fa-file';
        }
    }

    render() {
        this.state.options.page = this.state.page;
        this.state.options.sortOrder = this.state.sortOrder;
        this.state.options.count = this.state.count;
        this.state.options.rowsPerPage = this.state.rowsPerPage;
        this.state.options.searchText = this.state.searchText;
        //console.log('COLUMNS');
        //console.dir(JSON.parse(JSON.stringify(this.state.columns)));
        document.body.classList.remove('dashboard');
        return (

            <div className="d-flex flex-column sticky-footer-wrapper">

                {/* <div className="container-fluid"> */}


                {/* content area starts */}
                <div className="search-claims-form w-100 h-100 p-0">
              <h4 className="font-weight-bold text-uppercase text-primary py-2 d-block d-sm-none" style={{ whiteSpace: 'nowrap' }}>
                <svg className="icon-title" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x={0} y={0} viewBox="0 0 45.6 48" xmlSpace="preserve"><style dangerouslySetInnerHTML={{ __html: ".st0{fill:#005191}" }} /><path className="st0" d="M6.1 11.6h3.8v3.8H6.1zM31.3 6.5h-5.9V.6z" /><path className="st0" d="M24.4 8.5c-.6 0-1-.5-1-1V0H1C.4 0 0 .5 0 1v39.1c0 .5.4 1 1 1h19.3l-.3-.3c-2.5-2.5-3.8-5.7-3.8-9.2 0-3.5 1.3-6.7 3.8-9.2 2.5-2.5 5.7-3.8 9.2-3.8.9 0 1.7.1 2.6.3V8.5h-7.4zM4.1 10.6c0-.6.4-1 1-1h5.8c.6 0 1 .4 1 1v5.8c0 .5-.4 1-1 1H5.1c-.6 0-1-.5-1-1v-5.8zm8.8 21.6H5.1c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1 0 .5-.4 1-1 1zm0-3.8H5.1c-.6 0-1-.5-1-1s.4-1 1-1h7.8c.6 0 1 .5 1 1s-.4 1-1 1zm0-3.7H5.1c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.8c.6 0 1 .4 1 1s-.4 1-1 1zm8.6-4.3h-7.6c-.6 0-1-.5-1-1 0-.6.4-1 1-1h7.6c.6 0 1 .4 1 1s-.5 1-1 1zm0-4.4h-7.6c-.6 0-1-.4-1-1 0-.5.4-1 1-1h7.6c.6 0 1 .5 1 1 0 .6-.5 1-1 1z" /><path className="st0" d="M44.7 42.5L39 36.8c.8-1.6 1.3-3.3 1.3-5.2 0-2.9-1.2-5.7-3.2-7.8-2.1-2.1-4.8-3.2-7.8-3.2-2.9 0-5.7 1.2-7.8 3.2-2.1 2.1-3.2 4.8-3.2 7.8 0 2.9 1.1 5.7 3.2 7.8 2.2 2.1 5 3.2 7.8 3.2 1.8 0 3.5-.4 5.1-1.3l5.7 5.7c.6.6 1.5.9 2.3.9.8 0 1.7-.3 2.3-1 1.3-1.1 1.3-3.1 0-4.4zM22.9 38c-1.7-1.7-2.6-4-2.6-6.4 0-2.4.9-4.7 2.6-6.4 1.7-1.7 4-2.6 6.4-2.6 2.4 0 4.7.9 6.4 2.6s2.6 4 2.6 6.4c0 2.4-.9 4.7-2.6 6.4-3.6 3.5-9.3 3.5-12.8 0z" /></svg>
                    Search Claims HPP
              </h4>

                    <div className="data-section">
                        <div className="data-table" style={{ height: this.state.dataheight }}>
                            <React.Fragment>
                    {/* {this.state.isLoading && (
                                    <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                                        <CircularProgress />
                                    </div>
                    )} */}
                                <div className="mui-table-border">
                                    <MuiThemeProvider theme={this.getMuiTheme()}>
                                        <MUIDataTable data={this.state.data} columns={this.state.columns} options={this.state.options} components={{ TableViewCol: TableViewCol }} />
                                    </MuiThemeProvider>
                                </div>
                            </React.Fragment>
                        </div>
                    </div>

                    <div className="process-section">
                        <div className="approve-section bg-white p-3 box-shadow text-left" style={
                            { display: this.state.showDetails }
                        }>
                            <div className="modal-backdrop-static"></div>
                            <div className="modal d-block" tabindex="-1">
                                <div className="modal-dialog modal-xl modal-dialog-centered">
                                    <div className="modal-content border-0" style={{ minHeight: 'calc(100vh - 55px)' }}>
                                        <div className="modal-header bg-primary">
                                            <h5 className="modal-title text-white"><i class="fas fa-file-invoice-dollar big"></i> Load Claim</h5>
                                            <button type="button" className="close text-white op-1" data-dismiss="modal" aria-label="Close" onClick={this.closeDetails}>
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div className="modal-body">

                                            <div className="d-flex">
                                                <div className="form-group mr-5">
                                                    <label className="small mb-1 text-muted">Name</label>
                                                    <p>{`${this.state.selectedRowFirstName} ${this.state.selectedRowLastName}`}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label className="small mb-1 text-muted">Email</label>
                                                    <p>{this.state.email}</p>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <h5>Download Files</h5>
                                                    <div className="box-shadow p-3 mb-3">
                                                        <label>Photo IDentification</label>
                                                        <div className="list-group mb-3" id="PhotoID">
                                                            <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                <i className="fas fa-eye text-primary"></i>
                                                            </a>
                                                        </div>

                                                        <label>Proof of Address</label>
                                                        <div className="list-group mb-3" id="AddressProof">
                                                            <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                <i className="fas fa-eye text-primary"></i>
                                                            </a>
                                                        </div>

                                                        <label>Proof of Income</label>
                                                        <div className="list-group mb-3">
                                                            <div id="IncomeProof1">
                                                                <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                    <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                    <i className="fas fa-eye text-primary"></i>
                                                                </a>
                                                            </div>
                                                            <div id="IncomeProof2" style={{ display: 'none' }}>
                                                                <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                    <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                    <i className="fas fa-eye text-primary"></i>
                                                                </a>
                                                            </div>
                                                        </div>

                                                        <label>Proof of Income loss</label>
                                                        <div className="list-group mb-3">
                                                            <div id="IncomeLossProof1">
                                                                <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                    <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                    <i className="fas fa-eye text-primary"></i>
                                                                </a>
                                                            </div>
                                                            <div id="IncomeLossProof2" style={{ display: 'none' }}>
                                                                <a href="#" className="list-group-item list-group-item-action d-flex justify-content-between align-items-center bg-light border-0 py-2 px-3">
                                                                    <span className="text-primary"><i className="fas fa-file mr-2"></i> </span>
                                                                    <i className="fas fa-eye text-primary"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <h5>Result</h5>
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <div className="form-group">
                                                                <label className="small text-muted mb-0">Card Value:</label>
                                                                <input type="text" className="form-control border-top-0 border-left-0 border-right-0 px-0" onChange={this.updateCardValue} value={this.state.cardValue} placeholder="Card Value"></input>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7">
                                                            <label className="small text-muted mb-0">Status:</label>
                                                            <div className="form-group form-group-md-select w-100">
                                                                <select className="form-control" ref={(input) => this.result = input}>
                                                                    <option value="In Final Review">In Final Review</option>
                                                                    <option value="Denied">Deny</option>
                                                                    <option value="In-Review">In-Review</option>
                                                                    <option value="Approved">Approve</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="form-group">
                                                        <label className="mb-0">Notes</label>
                                                        <textarea maxlength="2000" rows="10" id="notesTextArea" className="form-control" defaultValue={this.state.notes}></textarea>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="text-right">
                                                <button type="button" className="btn btn-primary" onClick={this.submitResult}><i class="fas fa-check mr-1"></i> Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="alert-msg-2 alert-fixed" style={{ display: this.state.showAlert }}>
                        <button className="close-panel2" onClick={this.closeMsg}>X</button>
                        <div className="alert-text">
                            {
                                this.state.msg
                            }
                        </div>
                    </div>

                </div>
                {/* content area ends */}

          <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
            <CircularProgress />
          </Backdrop>


            </div>
        );
    }
};

export default SearchClaims;