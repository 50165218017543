import React from "react";
import { CircularProgress } from '@material-ui/core';
import imgLogo from '../imgs/welcomhome-oc-horizontal.png';
import {
    Route,
    NavLink,
    Switch,
    Redirect
} from "react-router-dom";
import PropertyListing from "./propertyListing";
import PropertyOwnerListing from "./propertyOwnerListing";
import PropertyOwnerProfile from "./propertyOwnerProfile";
import { Auth, API } from 'aws-amplify';
import EditProperty from "./editProperty";
import PropertyUnitListing from "./propertyUnitListing";
import { Helmet } from 'react-helmet';
import AddUnit from './addUnit';
import AddProperty from './addProperty';
import PHARegistration from './pha/phaRegistration';
import Tooltip from '@material-ui/core/Tooltip';
import PermissionNotFound from '../assistoc/forbidden';
import PageNotFound from "../assistoc/pageNotFound";
import Backdrop from '@material-ui/core/Backdrop';
import { withRouter } from "react-router";

let UserName = '';
let showBkBtn = 'block';

class WelcomeHomeOCLayout extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            ID: '',
            pagetitle: '',
            pageicon: '',
            pageimg: '',
            isLoading: true,
        }
    }

    callbackFunction = (title, icon, img) => {
        this.setState({ pagetitle: title });
        this.setState({ pageicon: icon })
        this.setState({ pageimg: img })
    }

    componentDidMount = async () => {
        this.setState({ isLoading: true });
        try {
            this.getCurrentUserPermissions();
        }
        catch (error) {
            console.log(error);
            if (error === "not authenticated") {
                await this.signOut();
            }
        };
        // this.setState({ isLoading: false });
    }

    getPermissions = async (e) => {
        var user = await this.getCurrentUser();
        let apiName = process.env.REACT_APP_APIGatewayName;
        let path = '/getuserpermissions';
        let myInit = {
            body: {
                "info": {
                    "groupName": e,
                }
            },
            headers: { Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}` }
        }
        return await API.post(apiName, path, myInit);
    }

    getCurrentUser = async () => {
        try {
            var user = await Auth.currentAuthenticatedUser();
            return user;
        }
        catch (e) {
            this.setState({ isLoading: false });
            // if(e === "not authenticated") {
            await this.signOut();
            // }
        }
    }

    getCurrentUserPermissions = async () => {
        await this.getCurrentUser();
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups.length === 1) {
            showBkBtn = "none";
        }
        var res = await this.getPermissions(cognitogroups);
        this.setState({ permissions: res.permissions, isLoading: false });
    }

    signOut = async () => {
        try {
            await Auth.signOut();
            localStorage.removeItem('permissions');
            window.location.assign("/");
            // signout success
        }
        catch (e) {
            console.log(e)
        }
    }

    backMethod = function () {
        this.props.history.goBack();
    }

    logoClick = function () {
        window.location.assign("/welcomehomeoc/propertyowners");
    }

    render() {
        if (window.location && window.location.search === '?event=signing_complete' && window.location.href.indexOf("/pharegistration") > -1) {
            localStorage.setItem('url', window.location.href)
            var testingId = localStorage.getItem('url').split('/')[3]
            return <Redirect to='/welcomehomeoc/pharegistration' state={{ ID: parseInt(testingId) }} />
        }
        const { isLoading } = this.state;
        if (!isLoading) {
            return (
                // <HashRouter>
                <div className="d-flex flex-column sticky-footer-wrapper welcomeoc-program">
                    <Helmet>
                        <title>WelcomeHomeOC</title>
                    </Helmet>
                    {/* header starts */}
                    <header>
                        <nav className="navbar shadow-sm fixed-top navbar-light navbar-inner bg-light py-1" id="headerBg">
                            <a className="mr-2 navbar-brand pt-0" onClick={() => this.logoClick()}>
                                <img src={imgLogo} height="22" className="logo" alt="WelcomeHome OC logo" loading="lazy" />
                            </a>
                            <h3 className="font-weight-bold text-uppercase text-primary border-left py-1 pl-4 d-none d-sm-inline-block ml-3 mb-0" style={{ whiteSpace: 'nowrap' }}>
                                {/* {this.state.pageimg && <img src={this.state.pageimg} alt="{this.state.pagetitle}" loading="lazy" />}
                                {this.state.pageicon && <i className={this.state.pageicon}></i>}
                                <span className="header-title">{this.state.pagetitle}</span> */}
                            </h3>
                            <div className="ml-auto">
                                <span className="text-primary">{localStorage.getItem('UserName')}</span>
                                {/* <NavLink to="/welcomehomeoc/propertyowners"><button className="btn btn-light d-none d-sm-inline-block" style={{ display: showBkBtn }} type="button"><i className="fas fa-reply"></i></button></NavLink> */}
                                <Tooltip title="Back">
                                    <button className="btn btn-light d-none d-sm-inline-block" onClick={() => this.backMethod()} style={{ display: showBkBtn }} type="button"><i className="fas fa-reply"></i> </button>
                                </Tooltip>
                                <div className="btn-group uw-dropdown  whoc-dropdown">
                                    <button type="button" className="btn btn-light d-none d-sm-inline-block" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i className="fas fa-bars"></i>
                                    </button>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <button className="dropdown-item" type="button"><span><i class="fas fa-house-user"></i></span><NavLink to="/welcomehomeoc/propertyowners">Portal Users</NavLink></button>
                                        <button className="dropdown-item" type="button"><span><i class="fas fa-home"></i></span><NavLink to="/welcomehomeoc/properties">Properties</NavLink></button>
                                        <button className="dropdown-item" type="button"><span><i class="fas fa-building"></i></span><NavLink to="/welcomehomeoc/units">Units</NavLink></button>
                                    </div>
                                </div>
                                <Tooltip title="Logout">
                                    <button className="btn btn-link red pr-0 pl-1" onClick={this.signOut}><i className="fas fa-power-off ml-1"></i></button>
                                </Tooltip>
                            </div>
                        </nav>
                    </header>
                    {/* header ends */}
                    {/* main content starts */}
                    <main className="main flex-fill ">
                        <div className="container-fluid">
                            <Switch>
                                <Route exact path="/welcomehomeoc" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PropertyOwnerListing {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />}></Route>
                                {/* <Route exact path="/welcomehomeoc" render={props => <PropertyOwnerListing {...props} parentCallback={this.callbackFunction} />}></Route> */}

                                <Route path="/welcomehomeoc/pharegistration/:id?event=signing_complete" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PHARegistration {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />

                                <Route exact path="/welcomehomeoc/pharegistration" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PHARegistration {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/propertyowners" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PropertyOwnerListing {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/propertyownerProfile" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PropertyOwnerProfile {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/properties" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PropertyListing {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/property/add" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <AddProperty {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/property/edit" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <EditProperty {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/units" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <PropertyUnitListing {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/unit/add" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <AddUnit {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />
                                <Route exact path="/welcomehomeoc/unit/view" render={props => this.state.permissions && this.state.permissions.length > 0 && this.state.permissions.includes('WelcomeHomeOC') ? <AddUnit {...props} parentCallback={this.callbackFunction} /> : <PermissionNotFound />} />

                                {/*keep you route above this route*/}
                                <div style={{ marginTop: '8rem' }}>
                                    <Route path="**" component={PageNotFound} />
                                </div>
                            </Switch>
                        </div>
                    </main>
                    {/* main content ends */}
                    {/* footer starts */}
                    <footer className="foot bg-dark text-white text-center mt-3 p-2">
                        <p className="mb-1">Powered by Orange County United Way</p>
                        {/* <Link target="_blank" to="/faqs" className="text-white">Read our FAQs</Link> */}
                    </footer>
                    {/* footer ends */}
                </div >
            )
        } else {
            return (
                <React.Fragment>
                    <Backdrop open={this.state.isLoading}>
                        <CircularProgress />
                    </Backdrop>
                </React.Fragment>
            );
        }

    }
}
export default WelcomeHomeOCLayout;