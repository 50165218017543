import React from "react";
import { SignIn } from "aws-amplify-react";
import img from './imgs/Assist-OC_1200px.jpg';
import imgLogo from './imgs/logo-assist-oc.png';
import { CircularProgress } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

export class CustomSignIn extends SignIn {
    constructor(props) {
        super(props);
    this.state = {
      isLoading: false,
    }
        this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    }

  handleLoginClick = async () => {
    this.setState({ isLoading: true, });
    await super.signIn();
    this.setState({ isLoading: false, });
  }

    showComponent(theme) {
        document.body.classList.add('login');
        return (
            <div>
                <img className="my-4 pt-2 d-block d-md-none" src={imgLogo} height="38" alt="ASSOSTOC"></img>
                <div className="login-wrapper">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-5 p-0">
                <React.Fragment>
                                <div className="login-box">
                                    <h2 className="mb-4 text-center text-md-left font-weight-bold">Sign In | Admin</h2>
                                    <div className="form-group form-group-md">
                                        <label htmlFor="username">Username</label>
                                        <input type="text" className="form-control" id="username" key="username" name="username" onChange={this.handleInputChange} placeholder="Enter Username" />
                                    </div>
                                    <div className="form-group form-group-md mb-0">
                                        <label htmlFor="password">Password</label>
                                        <input type="password" className="form-control" id="password" key="password" name="password" onChange={this.handleInputChange} placeholder="Enter Password" />
                                    </div>
                                    <div className="text-right mb-4 mt-2">
                                        <a href="#!" className="text-dark" onClick={() => {
                                            super.changeState("forgotPassword");
                                            document.body.classList.add('login-fp');
                                        }}
                                        >Forgot Password?</a>
                                    </div>
                    <button type="button" onClick={this.handleLoginClick} className="btn btn-primary btn-block text-white">Login <i className="fas fa-lock ml-2"></i></button>
                                </div>

                  <Backdrop className="MuiBackdrop-root" open={this.state.isLoading} >
                    <CircularProgress />
                  </Backdrop>
                </React.Fragment>

                            </div>
                            <div className="col-md-7 order-md-first bg-white p-0">
                                <div className="p-3 p-md-5">
                                    <img className="logo-login d-none d-md-block" src={imgLogo} alt="ASSOSTOC"></img>
                                    <h3 className="title mb-3 text-primary">United Way OC Programs</h3>
                                    <p>Thank you for being a part of the solution.  Your skills, energy and involvement in United Way’s programs means that - every day - we are better able to serve Orange County’s vulnerable populations. </p>
                                    <p className="mb-1">Please log-in to access the following programs:</p>
                                    <ul>
                                        <li>AssistOC Emergency Funds Distribution</li>
                                        <li>WelcomeHomeOC</li>
                                    </ul>
                                    <p className="note">Note:<span>Permission to access programs will be provided by your manager and determined by your role.</span></p>
                                    {/* <hr className="my-4"></hr>
                  <h3 className="title mb-3 text-primary">How do I apply?</h3> */}
                                    <ul className="list-unstyled">
                                        {/* <li><Link target="_blank" to="/faqs"><i className="fas fa-chevron-right mr-2"></i> Read the FAQs to learn how to apply and more details</Link></li>
                    <li><Link target="_blank" to="/eligibility"><i className="fas fa-chevron-right mr-2"></i> Learn more about who is eligible for assistance</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}