import React from 'react';
import { Link } from 'react-router-dom';

class PermissionNotFound extends React.Component{
    render(){
        return (

            <div className="d-flex flex-column sticky-footer-wrapper">

                {/* main content starts */}
                {/* <main className="main flex-fill mt-2 mt-md-3"> */}
                    {/* <div className="container-fluid"> */}

                        {/* content area starts */}
                        <div class="box-shadow details-page p-5 text-center">
                            <h2 class="not-allowed"><i class="fas fa-ban pr-2"></i>You dont have permision to view this page.</h2>
                        </div>
                        {/* content area ends */}

                    {/* </div> */}
                {/* </main> */}
                {/* main content ends */}


            </div>
        );
        // return <div>
        //     <h1>Not permited</h1>
        //     {/* <img src={PageNotFound}  />
        //     <p style={{textAlign:"center"}}>
        //       <Link to="/">Go to Home </Link>
        //     </p> */}
        //   </div>;
    }
}
export default PermissionNotFound;