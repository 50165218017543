import { useState } from 'react';

// Utility function to remove custom padding (if needed)
function removeCustomPadding(buffer, blockSize) {
  let paddingLength = buffer[buffer.length - 1];
  if (paddingLength > 0 && paddingLength <= blockSize) {
    return buffer.slice(0, buffer.length - paddingLength);
  }
  return buffer;
}

async function decryptValue(encryptedText) {
    const predefinedKey = new TextEncoder().encode('fC4L2d5Em6XZ8g9g'); // 16-byte key
    const predefinedIV = new TextEncoder().encode('1234567890123456'); // 16-byte IV
  
    // Decode Base64 string to bytes
    const encryptedBytes = Uint8Array.from(atob(encryptedText), c => c.charCodeAt(0));
  
    // Extract the IV from the beginning of the encrypted data
    const iv = encryptedBytes.slice(0, 16);
  
    // Extract the ciphertext (the rest of the data)
    const ciphertext = encryptedBytes.slice(16);
  
    // Import the key for AES decryption
    const key = await crypto.subtle.importKey(
      'raw',
      predefinedKey,
      { name: 'AES-CBC' },
      false,
      ['decrypt']
    );
  
    // Perform decryption
    const decryptedBuffer = await crypto.subtle.decrypt(
      { name: 'AES-CBC', iv: iv },
      key,
      ciphertext
    );
  
    // Convert decrypted buffer to byte array
    let decryptedBytes = new Uint8Array(decryptedBuffer);
  
    // Remove custom padding if any
    decryptedBytes = removeCustomPadding(decryptedBytes, 16);
  
    // Convert bytes to UTF-8 string
    const decoder = new TextDecoder();
    return decoder.decode(decryptedBytes);
  }



export { decryptValue };
