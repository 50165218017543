import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import history from "./utils/history";

class Home extends React.Component {

    render() {
        return (
            <Router history={history}>
                <Routes />
            </Router>
        );
    }
}

export default Home;
