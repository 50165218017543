import React from "react";
import AppWithAuth from "./AppWithAuth";
import AssistOCLayout from './assistoc/layout';
import WelcomeHomeOCLayout from './welcomehomeoc/layout';
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import PageNotFound from './pageNotFound'

function Routes() {
    const { pathname } = useLocation();
    return (
        <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            <Route exact path="/" component={AppWithAuth}></Route>
            <Route path="/assistoc" component={AssistOCLayout}></Route>
            <Route path="/welcomehomeoc" component={WelcomeHomeOCLayout} ></Route>
            <Route path="**" component={PageNotFound} />
        </Switch>
    );
}

export default Routes;
